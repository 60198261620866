import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import { generateDuotone } from '@smack/core/utils/ColorUtils';
import type React from 'react';

interface LeftIconContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: IconField;
  color: string;
  iconFile?: string;
}

export const LeftIconContainer: React.FC<LeftIconContainerProps> = ({
  color,
  icon,
  iconFile,
  ...htmlProps
}) => {
  const [iconColor, backgroundColor] = generateDuotone(color);

  return (
    <div
      {...htmlProps}
      style={{ backgroundColor: backgroundColor }}
      className="min-h-[35px] min-w-[35px]  flex rounded-full items-center justify-center relative"
    >
      <Icon
        icon={icon}
        iconFile={iconFile}
        color={iconColor}
        className="text-sm"
      />
    </div>
  );
};
