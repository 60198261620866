import type { IPagination } from '@smack/core/api/clients/rest/RESTClient';
import {
  LinkGroup,
  PopulationType,
} from '@smack/core/api/models/categories/LinkGroup';
import { BaseObjectGroup } from '@smack/core/api/models/objects/BaseObjectGroup';
import {
  type ILink,
  Link,
  type LinkDisplayforLinksGroups,
} from '@smack/core/api/models/objects/Link/Link';
import type { ViewElement } from '@smack/core/api/models/views/ViewElement/ViewElement';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { NoContentMessage } from '@smack/core/components/DataDisplay/NoContentMessage';
import {
  type ICellType,
  Table,
} from '@smack/core/components/DataDisplay/Table/Table';
import { Tooltip } from '@smack/core/components/DataDisplay/Tooltip';
import type { ViewElementRendererProps } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/type';
import { setModuleStore } from '@smack/core/store/app/actions';
import { LoaderSkeleton } from '@smack/core/utils/Loader';
import type { IAddLinkFormOutput } from '@smack/core/views/oldViewsToSort/Layouts/Forms/AddLinks/AddLinkForm/AddLinkForm';
import { AddLinks } from '@smack/core/views/oldViewsToSort/Layouts/Forms/AddLinks/AddLinks';
import { TypeRecurrenceUpdateChoice } from '@smack/core/views/oldViewsToSort/Layouts/Modal/RecurrenceUpdateChoiceObjectModal';
import { getColumns } from '@smack/core/views/oldViewsToSort/Views/Objects/ObjectDataTable/utils';
import type { ColumnDef } from '@tanstack/react-table';
import React, { type FC, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface LinksGroupsTableContent {
  columns: ColumnDef<ICellType>[] | undefined;
  total: number | undefined;
  data: ICellType[];
  linkGroup: LinkGroup;
}

const extractViewElements = (
  data: IPagination<ILink<LinkDisplayforLinksGroups>>,
) => {
  return data.results.map((result) => {
    return result.display.viewSections.reduce(
      (acc, section) => {
        section.viewElements.forEach((viewElement: ViewElement) => {
          acc[viewElement.id] = viewElement;
        });

        acc.baseObjectId = result?.baseobject?.id;
        acc.scheduleId = result?.baseobject?.scheduleId;
        acc.frontEndpoint = result?.baseobject?.frontEndpoint ?? undefined;
        acc.categoryId = result?.baseobject?.category?.id ?? undefined;

        return acc;
      },
      {} as Record<string | number, ViewElement | string | number | undefined>,
    );
  });
};

export const LinkGroupsTableRenderer: FC<ViewElementRendererProps> = ({
  viewElement,
  props,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState<LinksGroupsTableContent>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isAdding, setIsAdding] = useState<boolean>();

  const { view } = viewElement;
  const { baseObject } = props;
  const linkGroupId = view?.linkGroupId;
  const viewId = view?.id;

  const fetchTableData = async (baseObject, linkGroupId, viewId) => {
    try {
      const linkGroup = await LinkGroup.getLinkGroup(linkGroupId);
      const linksGroups = await Link.getLinksFromLinkGroupWithViews(
        baseObject?.id,
        linkGroupId,
        viewId,
        6,
        0,
      );

      const viewDefinition = await LinkGroup.getLinkGroupViewDefinitionById(
        linkGroupId,
        viewId,
      );

      const columns =
        getColumns(viewDefinition.viewSections, {}, true, true, 225) ?? [];
      const data = extractViewElements(linksGroups?.data);

      setTableData({
        columns,
        data,
        total: linksGroups?.data?.count,
        linkGroup,
      });
    } catch (error) {
      toast.error(t('process.action.error'));
    }
  };

  React.useEffect(() => {
    if (!view || !baseObject || !linkGroupId || !viewId) return;

    fetchTableData(baseObject, linkGroupId, viewId);
  }, [viewElement, props?.baseObject]);

  const handleAddLink = async (
    data: IAddLinkFormOutput,
    linkGroup: LinkGroup,
  ): Promise<void> => {
    try {
      const isGroupLink =
        baseObject?.baseobjectGroupId &&
        data.baseObjectGroupChoice !== TypeRecurrenceUpdateChoice.THIS;

      if (isGroupLink) {
        await BaseObjectGroup.addLink(baseObject, linkGroup.id, data);
      } else {
        await baseObject?.addLink(data);
      }

      await fetchTableData(baseObject, linkGroupId, viewId);
    } catch {
      toast('errorMessage.linkCreationFail');
    } finally {
      setIsAdding(false);
    }
  };

  const handleRowClicking = (event: React.MouseEvent, data): void => {
    event.stopPropagation();

    if (!data?.original) return;

    const { baseObjectId, scheduleId } = data.original;

    try {
      dispatch(
        setModuleStore({
          activeRightPanel: 'ElementPreview',
          activePreviewObjectId: baseObjectId,
          activeScheduleId: scheduleId,
          disableSubNavRouting: true,
        }),
      );
    } catch (error) {
      toast(t('errorMessage.unableToDisplayPreview'));
    }
  };

  const getAddButton = (linkGroup: LinkGroup): JSX.Element => {
    if (
      linkGroup.populationType === PopulationType.EXPLICIT_LINK ||
      linkGroup.populationType === PopulationType.ROOM
    ) {
      let title = t('links.createLink');
      if (linkGroup.populationType === PopulationType.ROOM) {
        title = t('links.createRoom');
      }
      return (
        <Tooltip title={title} offset={2}>
          <Icon
            icon={{ name: 'plus-circle' }}
            className="text-gray-400 text-xl hover:text-gray-500 cursor-pointer"
            onClick={(e): void => {
              e.stopPropagation();
              setIsAdding(true);
            }}
          />
        </Tooltip>
      );
    }
    return <></>;
  };

  const loadMoreRows = async (viewToReplace): Promise<void> => {
    if (!view || !baseObject || !linkGroupId || !viewId) return;

    let { data } = tableData as { data: ICellType[] };

    setIsFetching(true);

    const addedLinksGroups = await Link.getLinksFromLinkGroupWithViews(
      baseObject.id,
      linkGroupId,
      viewId,
      6,
      data.length,
    );
    const newLinks = addedLinksGroups?.data
      ? extractViewElements(addedLinksGroups?.data)
      : [];

    if (addedLinksGroups?.data?.results) {
      data = [...data, ...newLinks];
    }

    const newTableData = {
      ...tableData,
      data,
    } as LinksGroupsTableContent;

    setTableData(newTableData);
    setIsFetching(false);
  };

  return tableData ? (
    <div className="w-full flex-grow align-middle md:px-6 md:pb-3 lg:px-8 lg:pb-2 mt-4">
      <div className="text-m font-medium truncate flex items-center text-gray-600 dark:text-gray-300">
        <span className="mt-1">
          <Icon
            inline
            className="fa-fw pr-1"
            icon={tableData?.linkGroup?.icon}
          />
        </span>
        <span>{view?.label}</span>
        <span className="mx-2 flex shrink-0 items-center justify-center w-5 h-5 rounded-full bg-[#f6f6f6] dark:bg-[#3c3c3c] text-xs">
          {tableData?.total}
        </span>
        <span className="ml-auto">
          {baseObject?.isWritable && getAddButton(tableData?.linkGroup)}
        </span>
      </div>
      <div className="w-full h-[330px] relative">
        <div
          data-testid={`data-table-${view?.id}`}
          className="absolute inset-0 w-full my-2 h-[330px] overflow-y-auto"
        >
          {isAdding && (
            <AddLinks
              onQuit={(): void => setIsAdding(false)}
              sourceBaseObjectId={baseObject?.id}
              scheduleId={baseObject?.scheduleId}
              linkGroup={tableData.linkGroup}
              onSubmit={(data) => handleAddLink(data, tableData.linkGroup)}
              title={`${t('addLinkForm.title')} - ${baseObject?.title ?? ''}`}
              noClickOutside={true}
              open={isAdding}
              onClose={setIsAdding}
            />
          )}
          <Table
            columns={tableData?.columns ?? []}
            data={tableData?.data}
            loadMore={() => loadMoreRows(viewElement)}
            isNextComplete={tableData?.data?.length === tableData?.total}
            isFetching={isFetching}
            noContent={
              tableData?.total === 0 ? (
                <div
                  className="w-full h-full flex items-center justify-center"
                  style={{ marginTop: '4rem' }}
                >
                  <NoContentMessage
                    icon={{ name: 'face-monocle' }}
                    label={t('objectTable.noResultMessage')}
                  />
                </div>
              ) : null
            }
            onRowClick={handleRowClicking}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="w-full flex-grow align-middle md:px-6 md:pb-3 lg:px-8 lg:pb-2">
      <LoaderSkeleton height={380} width="100%">
        <rect x="0" y="10" width="25%" height="45" rx="8" />
        <rect x="0" y="65" width="100%" height="45" rx="8" />
        {Array.from({ length: 6 }, (_, i) => i).map((rowIndex) => (
          <React.Fragment key={rowIndex}>
            {Array.from({ length: 6 }, (_, i) => i).map((columnIndex) => (
              <rect
                key={columnIndex}
                x={10 + columnIndex * 200}
                y={140 + rowIndex * 40}
                height="15"
                width="160"
                rx="8"
              />
            ))}
          </React.Fragment>
        ))}
      </LoaderSkeleton>
    </div>
  );
};
