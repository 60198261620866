import type { ITemplateSkeletonComponent } from '.';

export const BaseObjectSkeleton: ITemplateSkeletonComponent = ({
  offset = 0,
}) => (
  <>
    <rect x={0} y={offset} height={80} width={45} />
    {/* Triangle! */}
    <path d={`M 44,${offset} 60,${offset + 40} 44,${offset + 80}`} />
    <rect x={70} y={offset + 15} width={150} height={15} rx={7} />
    <rect x={70} y={offset + 40} width={150} height={10} rx={5} />
    <rect x={70} y={offset + 55} width={150} height={10} rx={5} />
    <rect
      x={'calc(100% - 100px)'}
      y={offset + 30}
      width={80}
      height={20}
      rx={10}
    />
    <rect x={0} y={offset + 80} width="100%" height={1} />
  </>
);

BaseObjectSkeleton.itemOffset = 80;
