import { ListHeader } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/List/ListHeader';
import type { Task } from 'gantt-task-react';
import type React from 'react';

export const TaskListTable: React.FC<{
  rowHeight: number;
  rowWidth: string;
  tasks: Task[];
  onExpanderClick: (task: Task) => void;
}> = ({ rowHeight, rowWidth, tasks, onExpanderClick }) => {
  return (
    <div className="table border-b border-l border-r">
      {tasks.map((task) => (
        <div
          className="table-row truncate odd:bg-white even:bg-neutral-100"
          style={{ height: rowHeight }}
          key={`${task.id}row`}
        >
          <div
            className="table-cell"
            style={{
              minWidth: rowWidth,
              maxWidth: rowWidth,
            }}
            title={task.name}
          >
            {task.type === 'project' ? (
              <ListHeader
                icon={{}}
                label={
                  <p className="truncate text-gray-600 uppercase">
                    {task.name}
                  </p>
                }
                className="px-0 mr-3"
                onClick={() => onExpanderClick(task)}
                isActive={!task.hideChildren}
              />
            ) : (
              <div className="truncate mt-4 ml-3 text-neutral-600">
                {task.name}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
