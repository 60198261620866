interface AttributeValueData<T> {
  id: number;
  value: T;
}

export interface AttributeValueApiOutput<T = string> {
  data: AttributeValueData<T>;
  type: string;
}

export class AttributeValue<T = string> {
  data: AttributeValueData<T>;

  type: string;

  constructor(data: AttributeValueApiOutput<T>) {
    this.data = data.data;
    this.type = data.type;
  }
}
