import type { IconName } from '@fortawesome/fontawesome-common-types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { MessageRecipientIndication } from '@smack/core/api/models/notifications/MessageRecipient';
import type { TaskRule } from '@smack/core/api/models/tasks/TaskRule';

import { MessageTemplateType } from '@smack/core/api/models/notifications/MessageTemplate';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { ActivateReminder } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/Rules/RulesFeed/Components/ActivateReminder';
import { StatusUpdate } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/Rules/RulesFeed/Components/StatusUpdate';

interface IProps {
  rule: TaskRule;
}

/**
 *  Rules Feed Components
 * @export
 * @return {*}  {JSX.Element}
 */
export const RulesFeed = (props: IProps): JSX.Element => {
  const [t] = useTranslation();
  const { rule } = props;
  const [isRecipientsModalOpen, setIsRecipientsModalOpen] =
    React.useState(false);

  const getMailRecipients = (
    indication: MessageRecipientIndication,
  ): JSX.Element => {
    if (!rule.taskMessage) return <></>;
    const recipients =
      rule.taskMessage.messageTemplate?.getAllRecipientsEmailByIndication(
        indication,
      ) || [];
    if (!recipients.length) return <></>;
    let stringType: string;
    switch (indication) {
      case MessageRecipientIndication.TO:
        stringType = 'tasks.rulesDescription.indication.to';
        break;
      case MessageRecipientIndication.BCC:
        stringType = 'tasks.rulesDescription.indication.bcc';
        break;
      case MessageRecipientIndication.CC:
        stringType = 'tasks.rulesDescription.indication.cc';
        break;
    }
    return (
      <div className="mt-4">
        <p className="font-medium">{t(stringType)}</p>
        <ul>
          {recipients.map((v) => (
            <li key={v}>{v}</li>
          ))}
        </ul>
      </div>
    );
  };

  const getSmsRecipients = (): JSX.Element => {
    if (!rule.taskMessage) return <></>;
    const recipients =
      rule.taskMessage?.messageTemplate?.getAllRecipientsPhoneNumbers() || [];
    if (!recipients.length) return <></>;
    return (
      <div className="mt-4">
        <p className="font-medium">
          {t('tasks.rulesDescription.indication.to')}
        </p>
        <ul>
          {recipients.map((v) => (
            <li key={v}>{v}</li>
          ))}
        </ul>
      </div>
    );
  };

  const modal = rule.taskMessage ? (
    <Modal
      icon={{
        name:
          rule.taskMessage.messageTemplate?.type === MessageTemplateType.SMS
            ? 'sms'
            : 'envelope',
      }}
      title={
        rule.taskMessage.label ?? rule.taskMessage.messageTemplate?.subject
      }
      open={isRecipientsModalOpen}
      onClose={setIsRecipientsModalOpen}
      color="#EFF6FF"
      iconColor="#3886F1"
    >
      <div>
        {rule.taskMessage.messageTemplate?.subject ? (
          <p className="mt-3 dark:text-gray-300">
            <span className="font-medium">{t('reminders.form.subject')} :</span>{' '}
            {rule.taskMessage?.messageTemplate.subject}
          </p>
        ) : null}
        {rule.taskMessage.messageTemplate?.type ===
        MessageTemplateType.EMAIL ? (
          <>
            {getMailRecipients(MessageRecipientIndication.TO)}
            {getMailRecipients(MessageRecipientIndication.CC)}
            {getMailRecipients(MessageRecipientIndication.BCC)}
          </>
        ) : null}
        {rule.taskMessage.messageTemplate?.type === MessageTemplateType.SMS
          ? getSmsRecipients()
          : null}
      </div>
    </Modal>
  ) : null;

  let icon: IconName = 'question';

  const from = (
    <>
      {/* biome-ignore lint/complexity/noUselessFragments: Dirty way to prevent i18next
                                                           from replacing children */}
      <></>
      <StatusUpdate status={rule.fromStatus} task={rule.fromTask} />
    </>
  );

  const to: React.ReactNode[] = [];

  if (rule.toStatus) {
    icon = 'tags';
    to.push(
      <StatusUpdate key="toStatus" status={rule.toStatus} task={rule.toTask} />,
    );
  }

  if (rule.taskMessage) {
    icon = 'paper-plane';
    to.push(
      <Trans
        key="taskMessage"
        t={t}
        i18nKey="tasks.rulesDescription.sendMessage"
        components={{
          details: (
            <span
              onClick={(): void => setIsRecipientsModalOpen(true)}
              className="text-blue-500 font-medium hover:text-blue-600 cursor-pointer"
            />
          ),
        }}
      />,
    );
  }

  if (rule.taskReminderRule) {
    icon = 'bell';
    to.push(
      <ActivateReminder
        key="taskReminder"
        taskReminderRule={rule.taskReminderRule}
      />,
    );
  }

  if (rule.taskAttributeModification) {
    icon = 'edit';
    to.push(
      <Trans
        key="attributeModification"
        t={t}
        i18nKey="tasks.rulesDescription.executeAttributeModification"
        values={{
          label: rule.taskAttributeModification.label,
        }}
        components={{
          bold: <span className="font-bold" />,
        }}
      />,
    );
  }

  if (rule.taskBaseobjectCloner) {
    icon = 'file-plus';
    to.push(
      <Trans
        key="taskBaseObjectCloner"
        t={t}
        i18nKey="tasks.rulesDescription.createNewBaseobject"
        values={{
          categoryLabel: rule.taskBaseobjectCloner.category?.label,
        }}
        components={{
          bold: <span className="font-bold" />,
        }}
      />,
    );
  }

  if (!to.length) {
    to.push(t('tasks.rulesDescription.unknownRuleAction'));
  }

  return (
    <div className="relative group">
      {modal}
      <div className="relative flex items-start space-x-2 border-b group-last:border-b-0 py-2 mr-2">
        <>
          <div className="m-2">
            <div className="relative px-1">
              <div className="h-8 w-8 bg-gray-200 rounded-full flex items-center justify-center">
                <Icon
                  icon={{ name: icon }}
                  className="h-4 w-4 text-gray-500"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
          <div className="min-w-0 flex-1 py-1.5">
            <div className="text-sm text-gray-800 dark:text-gray-200">
              <Trans
                t={t}
                i18nKey="tasks.rulesDescription.rule"
                components={{
                  from,
                  to: <>{to}</>,
                }}
              />
            </div>
          </div>
        </>
      </div>
    </div>
  );
};
