import { Anchor } from '@smack/core/components/DataDisplay/MarkdownBlock/Components/Anchor';
import {
  FifthHeading,
  FirstHeading,
  FourthHeading,
  SecondHeading,
  SixthHeading,
  ThirdHeading,
} from '@smack/core/components/DataDisplay/MarkdownBlock/Components/Headings';
import type React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface IMarkdownProps {
  children: string;
}

export const MarkdownBlock: React.FC<IMarkdownProps> = ({ children }) => {
  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      skipHtml
      components={{
        a: Anchor,
        h1: FirstHeading,
        h2: SecondHeading,
        h3: ThirdHeading,
        h4: FourthHeading,
        h5: FifthHeading,
        h6: SixthHeading,
      }}
    >
      {children}
    </Markdown>
  );
};
