import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import { type VariantProps, cva } from 'class-variance-authority';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Select, { type SelectProps } from './components/Select';
import type { Option, Options } from './components/type';

export interface ISelectOption {
  value: string | number;
  label: string | number;
  color?: string;
  icon?: IconField;
  image?: string;
  error?: string;
}

interface IBaseSelectInputInterface
  extends Omit<SelectProps, 'value' | 'onChange'> {
  className?: string;
  disabled?: boolean;
  label?: string | React.ReactNode; // label of the input
  error?: string; // error message to show
  isLoading?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  width?: VariantProps<typeof selectInputStyles>['width'];
}

export interface ISelectInputInterface extends IBaseSelectInputInterface {
  multiple?: false;
  value?: Option | null; // value of the select
  onChange?: (value: Option | undefined | null) => void; // onChange method when the user change the value
}

export interface ISelectMultipleInputInterface
  extends IBaseSelectInputInterface {
  multiple: true;
  value?: Option[] | null; // value of the select
  onChange?: (value: Option[] | undefined | null) => void; // onChange method when the user change the value
}

export type SelectInputProps =
  | ISelectInputInterface
  | ISelectMultipleInputInterface;

const selectInputStyles = cva('relative flex rounded shadow-sm', {
  variants: {
    error: {
      true: 'border-red-500',
      false: 'border-gray-300',
    },
    width: {
      xSmall: ['w-32'],
      small: ['w-48'],
      medium: ['w-60'],
      large: ['w-80'],
      fluid: ['w-full'],
    },
  },
  defaultVariants: {
    error: false,
    width: 'large',
  },
});

/**
 *  Select input components
 *
 * @export
 * @param {ISelectInputInterface | ISelectMultipleInputInterface} props
 * @return {*}  {JSX.Element}
 */
export const SelectInput = React.forwardRef(function SelectInput(
  props: SelectInputProps,
  ref: React.Ref<HTMLDivElement>,
) {
  const {
    options,
    value,
    onChange,
    onMenuOpen,
    label,
    onSearch,
    onLastOptionVisible,
    id,
    error,
    placeholder,
    noOptionsBeforeSearchMessage,
    className,
    disabled,
    noOptionsMessage,
    multiple = false,
    needFilter = true,
    isLoading = false,
    isSearchable = true,
    isClearable = true,
    prefix,
    count,
    suffix,
    width = 'large',
  } = props;

  const [t] = useTranslation();
  const availableOptions = React.useMemo<Options>(() => {
    return options || [];
  }, [options, value]);

  const handleChange = (val: Option | Option[] | null | undefined): void => {
    if (!onChange) return;
    if (multiple && Array.isArray(val)) {
      onChange(val as never);
      return;
    }
    if (!Array.isArray(val)) {
      onChange(val as never);
    }
  };

  const handleSearch = (val: string): void => {
    if (onSearch) onSearch(val);
  };

  return (
    <div ref={ref} className={width === 'fluid' ? 'w-full' : ''}>
      {label && (
        <span
          className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1"
          id={id}
        >
          {label}
        </span>
      )}
      <div
        className={
          selectInputStyles({
            error: !!error,
            width,
          }) + (className ? ` ${className}` : '')
        }
      >
        <Select
          primaryColor="blue"
          value={value || null}
          id={id}
          isSearchable={isSearchable}
          onSearch={handleSearch}
          onLastOptionVisible={onLastOptionVisible}
          isDisabled={disabled}
          isClearable={isClearable}
          isMultiple={multiple}
          noOptionsMessage={noOptionsMessage ?? t('noResults')}
          isLoaded={!isLoading}
          noOptionsBeforeSearchMessage={
            noOptionsBeforeSearchMessage ?? t('noResultsBeforeSearch')
          }
          searchInputPlaceholder={t('search')}
          placeholder={placeholder || '-'}
          onChange={handleChange}
          loading={isLoading}
          options={availableOptions}
          needFilter={needFilter}
          prefix={prefix}
          onMenuOpen={onMenuOpen}
          suffix={suffix}
          count={count}
        />
      </div>

      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </div>
  );
});
