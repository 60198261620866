import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import {
  Category,
  CategoryLevel,
  type DefaultGeoJSON,
  type ICategory,
} from '@smack/core/api/models/categories/Category/index';
import type { ViewName } from '@smack/core/hooks/views/types';

export interface IParentCategory extends ICategory {
  hasCategory?: boolean;
  hasSubcategoryMobileAvailable?: boolean;
  hasSubcategoryMapFilterAvailable?: boolean;
  hasSubcategoryCalendarFilterAvailable?: boolean;
  hasSubcategoryDisplayMap?: boolean;
  hasSubcategoryDisplayTable?: boolean;
  hasSubcategoryDisplayCalendar?: boolean;
  hasSubcategoryDisplayKanban?: boolean;
  hasSubcategoryDisplayObjectCalendar?: boolean;
  hasSubcategoryDisplayObjectRoomCalendar?: boolean;
  hasSubcategoryDisplayObjectFunctional?: boolean;
  displayMapShapeDefault?: DefaultGeoJSON;
  displayViewDefault?: ViewName;
}

export class ParentCategory extends Category {
  hasCategory?: boolean;

  hasSubcategoryMobileAvailable?: boolean;

  hasSubcategoryMapFilterAvailable?: boolean;

  hasSubcategoryCalendarFilterAvailable?: boolean;

  hasSubcategoryDisplayMap?: boolean;

  hasSubcategoryDisplayTable?: boolean;

  hasSubcategoryDisplayCalendar?: boolean;

  hasSubcategoryDisplayKanban?: boolean;

  hasSubcategoryDisplayObjectCalendar?: boolean;

  hasSubcategoryDisplayObjectRoomCalendar?: boolean;

  hasSubcategoryDisplayObjectFunctional?: boolean;

  displayMapShapeDefault?: DefaultGeoJSON;

  displayViewDefault?: ViewName;

  constructor(data: IParentCategory) {
    super(data);
    this.hasCategory = data.hasCategory;
    this.hasSubcategoryMobileAvailable = data.hasSubcategoryMobileAvailable;
    this.hasSubcategoryMapFilterAvailable =
      data.hasSubcategoryMapFilterAvailable;
    this.hasSubcategoryCalendarFilterAvailable =
      data.hasSubcategoryCalendarFilterAvailable;
    this.hasSubcategoryDisplayMap = data.hasSubcategoryDisplayMap;
    this.hasSubcategoryDisplayTable = data.hasSubcategoryDisplayTable;
    this.hasSubcategoryDisplayCalendar = data.hasSubcategoryDisplayCalendar;
    this.hasSubcategoryDisplayKanban = data.hasSubcategoryDisplayKanban;
    this.hasSubcategoryDisplayObjectCalendar =
      data.hasSubcategoryDisplayObjectCalendar;
    this.hasSubcategoryDisplayObjectRoomCalendar =
      data.hasSubcategoryDisplayObjectRoomCalendar;
    this.hasSubcategoryDisplayObjectFunctional =
      data.hasSubcategoryDisplayObjectFunctional;
    this.displayMapShapeDefault = data.displayMapShapeDefault;
    this.displayViewDefault = data.displayViewDefault;
  }

  static getRootCategories(): Promise<ParentCategory[]> {
    return RESTClient.get<{ data: { results: IParentCategory[] } }>(
      `/categories/categories?level=${CategoryLevel.Module}`,
    ).then((res) => {
      return res.data.results.map((cat) => new ParentCategory(cat));
    });
  }

  static getChildCategories(
    parentCategoryId: number | string,
  ): Promise<ParentCategory[]> {
    return RESTClient.get<{ data: { results: IParentCategory[] } }>(
      `/categories/categories?parent-category=${parentCategoryId}`,
    ).then((res) => {
      return res.data.results.map((cat) => new ParentCategory(cat));
    });
  }
}
