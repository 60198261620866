import {
  FloatingFocusManager,
  type Placement,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import React from 'react';

interface IProps<T extends HTMLElement = HTMLElement> {
  children: (
    ref: React.Ref<T>,
    providedProps: Record<string, unknown>,
  ) => React.ReactNode;
  renderConfirmNode: (closeChip: () => void) => React.ReactNode;
  placement: Placement;
}

export const ConfirmationChip = <T extends HTMLElement>({
  children,
  renderConfirmNode,
  placement,
}: IProps<T>): React.ReactElement => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(5), flip(), shift()],
    whileElementsMounted: autoUpdate,
    placement,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  return (
    <>
      {children(refs.setReference, getReferenceProps())}
      {isOpen ? (
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={refs.setFloating}
            className="bg-primary z-50 p-2 shadow rounded border border-border"
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {renderConfirmNode(() => setIsOpen(false))}
          </div>
        </FloatingFocusManager>
      ) : null}
    </>
  );
};
