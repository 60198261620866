import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import {
  ChecklistItem,
  type IChecklistItem,
} from '@smack/core/api/models/tasks/ChecklistItem';
import type { AxiosResponse } from 'axios';

export interface IChecklist {
  id: number;
  label?: string;
  progress?: number;
  items?: IChecklistItem[];
}

export type IChecklistPatchData = Partial<Pick<IChecklist, 'label'>>;

export class Checklist {
  id: number;

  label: string;

  progress: number;

  items: ChecklistItem[];

  constructor(data: IChecklist) {
    this.id = data.id;
    this.label = data.label || '';
    this.progress = data.progress || 0;
    this.items = data.items?.map((item) => new ChecklistItem(item)) || [];
  }

  static create(taskId: number, label: string): Promise<AxiosResponse<void>> {
    return RESTClient.post({ label, task: taskId }, '/tasks/checklists');
  }

  updateProgress(): void {
    this.progress = this.items.length
      ? Math.floor(
          (this.items.filter((item) => item.isChecked).length /
            this.items.length) *
            100,
        )
      : 0;
  }

  patch(data: IChecklistPatchData): Promise<AxiosResponse> {
    return RESTClient.patch(data, `/tasks/checklists/${this.id}`);
  }

  delete(): Promise<void> {
    return RESTClient.delete(`/tasks/checklists/${this.id}`);
  }

  addItem(label: string): Promise<AxiosResponse<void>> {
    return RESTClient.post(
      { label, checklist: this.id },
      '/tasks/checklist-items',
    );
  }
}
