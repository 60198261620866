import { useTranslation } from 'react-i18next';
import { BaseAlert, type IBaseAlertProps } from '../BaseAlert';
/**
 * Danger Alerts from BaseAlert to use for an error
 * @param props IBaseAlertProps
 * @returns JSX.Element
 */
export const DangerAlert = (props: IBaseAlertProps): JSX.Element => {
  const [t] = useTranslation();
  const { children, title, className } = props;
  return (
    <BaseAlert
      {...props}
      className={className}
      title={title ?? t('dataDisplayActions.error')}
      color={'#FEF2F2'}
      textColor={'#991E1B'}
    >
      {children}
    </BaseAlert>
  );
};
