import type {
  Attribute,
  IAttributeValue,
} from '@smack/core/api/models/categories/Attribute/Attribute';
import type {
  FieldProps,
  ViewElement,
} from '@smack/core/api/models/views/ViewElement/ViewElement';
import type { DisplayedFieldType } from '@smack/core/api/models/views/ViewElement/enums';
import type React from 'react';
import type { ValidateResult } from 'react-hook-form';

// we don't know the type of value here
// biome-ignore lint/suspicious/noExplicitAny:
export type ValueToAttributeValue = (value?: any) => unknown[];

export enum DetailSubmissionType {
  Button = 'BUTTON',
  OnChange = 'OnChange',
  None = 'None', // Read-only fields
}

// we don't know the type of value here
// biome-ignore lint/suspicious/noExplicitAny:
export type ValidationRules<T = any> = (
  value: T,
  viewElement: ViewElement,
) => ValidateResult;

export interface IAttributeFields {
  type: DisplayedFieldType;
  defaultValue?: unknown;
  valueToAttributeValue: ValueToAttributeValue;
  detailSubmissionType: DetailSubmissionType;
  validationRules?: ValidationRules;
  getValueForFormField: (
    attribute: Attribute,
    attributeValues: IAttributeValue[] | undefined,
  ) => unknown;
  isAReadOnlyField: boolean;
  render: (
    attribute: Attribute,
    attributeValues: IAttributeValue[] | undefined,
    props?: FieldProps,
  ) => React.ReactNode;
}

export interface IProgressBarParams {
  greaterThan?: number;
  hexColorIfGreaterThan?: string;
  equalOrGreaterThan?: number;
  hexColorIfEqualOrGreaterThan?: string;
  lessThan?: number;
  hexColorIfLessThan?: string;
  equalOrLessThan?: number;
  hexColorIfEqualOrLessThan?: string;
}
