import type { FeatureCollection, Point } from 'geojson';

type AddressFeatureCollection = FeatureCollection<Point, { label: string }>;
class AddressService {
  constructor(
    readonly url: string,
    readonly abortSignal?: AbortSignal,
  ) {
    this.url = url;
    this.abortSignal = abortSignal;
  }

  public searchAddress(
    value: string,
  ): Promise<{ label: string; point: Point }[]> {
    return this.searchAddressImpl(`q=${value}`);
  }

  public searchAddressAroundLatLong(
    value: string,
    lat: number,
    lon: number,
  ): Promise<{ label: string; point: Point }[]> {
    return this.searchAddressImpl(`q=${value}&lat=${lat}&lon=${lon}`);
  }

  private async searchAddressImpl(
    queryString: string,
  ): Promise<{ label: string; point: Point }[]> {
    const init = this.abortSignal ? { signal: this.abortSignal } : {};

    const response = await fetch(`${this.url}/search/?${queryString}`, init);
    const collection = (await response.json()) as AddressFeatureCollection;

    return (
      collection.features.map((f) => ({
        label: f.properties.label,
        point: f.geometry,
      })) || []
    );
  }

  public async getAddressFromLatLng(lat: number, lon: number): Promise<string> {
    const response = await fetch(`${this.url}/reverse/?lat=${lat}&lon=${lon}`);
    const collection = (await response.json()) as AddressFeatureCollection;

    return collection.features?.[0].properties.label || '';
  }
}

export default AddressService;
