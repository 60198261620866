import { axiosRejectErrorCatcher } from '@smack/core/api/clients/rest/RESTClient';
import { Category } from '@smack/core/api/models/categories/Category';
import { LinkGroup } from '@smack/core/api/models/categories/LinkGroup';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { ListElementBaseObject } from '@smack/core/api/models/objects/NewBaseObject/ListElementBaseObject';
import { DisplayedFieldType } from '@smack/core/api/models/views/ViewElement/enums';
import {
  type IModalProps,
  Modal,
} from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { ModalWrapper } from '@smack/core/components/DataDisplay/Modals/ModalWrapper/ModalWrapper';
import type { DateTimeRangeInputValue } from '@smack/core/components/DataInput/DateRangeInput/DateTimeRangeInput';
import type { RRuleInputValue } from '@smack/core/components/DataInput/RRuleInput';
import type { BaseObjectFormOutputValues } from '@smack/core/components/ViewRenderer/interfaces';
import {
  AddLinkForm,
  type AddLinkFormProps,
} from '@smack/core/views/oldViewsToSort/Layouts/Forms/AddLinks/AddLinkForm/AddLinkForm';
import { LinkGroupSelector } from '@smack/core/views/oldViewsToSort/Layouts/Forms/AddLinks/LinkGroupSelector/LinkGroupSelector';
import { CategorySelector } from '@smack/core/views/oldViewsToSort/Layouts/Forms/CategorySelector';
import { CreationForm } from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/CreationForm/CreationForm';
import type { DefaultValuesFromFieldType } from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/common/assignDefaultValueFormBaseobjectView';
import type { AxiosError } from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps
  extends Omit<IModalProps, 'children'>,
    Omit<AddLinkFormProps, 'linkGroup' | 'category'> {
  onlyTemporalLinkGroup?: boolean;
  linkGroup?: LinkGroup;
}

export const AddLinks: React.FC<IProps> = ({
  linkGroup: linkGroupProps,
  scheduleId,
  onSubmit,
  onQuit,
  onlyTemporalLinkGroup,
  initialBaseObjectsValue = [],
  initialDateRange,
  sourceBaseObjectId,
  filterParameters,
  ...modalProps
}) => {
  const [linkGroup, setLinkGroup] = React.useState<LinkGroup | undefined>(
    linkGroupProps,
  );
  const [dateRangeValue, setDateRangeValue] = React.useState<
    DateTimeRangeInputValue | undefined
  >(initialDateRange);

  const [baseObjectsValue, setBaseObjectsValue] = React.useState<BaseObject[]>(
    initialBaseObjectsValue,
  );

  const [baseObjectCreationForm, setBaseObjectCreationForm] =
    React.useState<boolean>();

  const [linkGroupToSelect, setLinkGroupToSelect] =
    React.useState<LinkGroup[]>();
  const [t] = useTranslation();

  const [category, setCategory] = React.useState<Category>();

  React.useEffect(() => {
    setLinkGroup(linkGroupProps);
  }, [linkGroupProps]);

  React.useEffect(() => {
    if (
      linkGroup?.populationCategory?.isCategory &&
      category?.id !== linkGroup?.populationCategory.id
    ) {
      Category.getCategory(linkGroup?.populationCategory.id)
        .then((res) => {
          setCategory(res);
        })
        .catch((err: AxiosError) =>
          axiosRejectErrorCatcher(err, () => setCategory(undefined)),
        );
    }
  }, [linkGroup]);

  const categoryId = React.useMemo(() => {
    if (linkGroup?.populationCategory?.isCategory) {
      return linkGroup?.populationCategory.id;
    }
    return category?.id;
  }, [category]);

  React.useEffect(() => {
    if (linkGroup) {
      if (linkGroup.isDirectObjectCreatable) {
        setBaseObjectCreationForm(true);
      }
      return;
    }
    if (!sourceBaseObjectId) return;
    LinkGroup.getChildrenExplicitLinkGroupsFromObjectId(
      sourceBaseObjectId,
    ).then((linkGroups) => {
      const groups: LinkGroup[] = linkGroups.filter((group) => {
        if (onlyTemporalLinkGroup) return group.isTimeSensitive;
        return true;
      });
      const firstGroup = groups.at(0);
      if (groups.length === 1 && firstGroup) {
        if (firstGroup.isDirectObjectCreatable) {
          setBaseObjectCreationForm(true);
        }
        setLinkGroup(firstGroup);
      }
      setLinkGroupToSelect(groups);
    });
  }, [linkGroup]);

  const onObjectCreated = (data?: {
    id: number;
    rrule?: RRuleInputValue;
  }): void => {
    if (!linkGroup) return;
    if (!data?.id) return;
    ListElementBaseObject.getListElementBaseObject(data.id).then((obj) => {
      setBaseObjectsValue([...baseObjectsValue, obj] as BaseObject[]);
      if (linkGroup.isTimeSensitive) {
        if (data.rrule?.dtstart && data.rrule.dtend) {
          setDateRangeValue({
            endDate: new Date(data.rrule.dtend),
            startDate: new Date(data.rrule?.dtstart),
          });
          if (linkGroup.isDirectObjectCreatable) {
            onSubmit({
              linkGroup: linkGroup,
              baseObjects: [...baseObjectsValue, obj] as BaseObject[],
              datetimeRange: {
                dtstart: data.rrule?.dtstart,
                dtend: data.rrule.dtend,
              },
            });
          }
        }
      } else {
        setBaseObjectsValue([...baseObjectsValue, obj] as BaseObject[]);
        if (linkGroup.isDirectObjectCreatable) {
          onSubmit({
            linkGroup: linkGroup,
            baseObjects: [...baseObjectsValue, obj] as BaseObject[],
          });
        }
      }
    });
  };

  const onClickCreateBaseObject = () => {
    setBaseObjectCreationForm(true);
  };

  const getCreationDefaultDateRange = (): DefaultValuesFromFieldType => {
    if (!dateRangeValue?.startDate || !dateRangeValue.endDate) return {};
    const value: BaseObjectFormOutputValues = {
      dtstart: dateRangeValue.startDate.toISOString(),
      dtend: dateRangeValue.endDate.toISOString(),
    };

    return {
      [DisplayedFieldType.DATE_RANGE]: value,
      [DisplayedFieldType.RECURRENCE]: value,
      [DisplayedFieldType.RECURRENCE_SIMPLIFIED]: value,
    };
  };

  if (!modalProps.open) return null;

  if (!linkGroup) {
    if (!linkGroupToSelect) return;
    return (
      <Modal
        {...modalProps}
        icon={{ name: 'link' }}
        title={t('addLinkForm.title')}
        open={!!linkGroupToSelect}
      >
        <div>
          <LinkGroupSelector
            linkGroups={linkGroupToSelect ?? []}
            onLinkGroupClicked={(group) => {
              if (group.isDirectObjectCreatable) {
                setBaseObjectCreationForm(true);
              }
              setLinkGroup(group);
            }}
          />
        </div>
      </Modal>
    );
  }

  const onCloseCreationForm = () => {
    setBaseObjectCreationForm(false);
  };

  if (baseObjectCreationForm) {
    return (
      <ModalWrapper
        noClickOutside
        key={'link'}
        open={baseObjectCreationForm}
        onClose={onCloseCreationForm}
        className={
          'md:min-w-[80vw] md:w-[80vw] max-h-[80vh] min-h-[80vh] overflow-hidden  !p-0 flex flex-col'
        }
      >
        <div className={'overflow-hidden flex-grow flex'}>
          <CreationForm
            initialCategoryId={category?.id}
            onSuccessfulFormSubmission={onObjectCreated}
            defaultValuesFormFieldType={getCreationDefaultDateRange()}
            onClose={onCloseCreationForm}
          />
        </div>
      </ModalWrapper>
    );
  }

  return (
    <Modal
      icon={{ name: 'link' }}
      title={t('addLinkForm.title')}
      {...modalProps}
    >
      {categoryId ? (
        <AddLinkForm
          key={baseObjectsValue?.length}
          linkGroup={linkGroup}
          category={category ?? categoryId}
          sourceBaseObjectId={sourceBaseObjectId}
          onSubmit={onSubmit}
          initialBaseObjectsValue={baseObjectsValue}
          initialDateRange={dateRangeValue}
          onDateRangeChange={setDateRangeValue}
          onQuit={onQuit}
          onClickCreateBaseObject={onClickCreateBaseObject}
          scheduleId={scheduleId}
          filterParameters={filterParameters}
        />
      ) : (
        <div className={'md:min-w-[50vw]'}>
          <CategorySelector
            onCategoryClicked={setCategory}
            startCategoryId={linkGroup.populationCategory?.id}
          />
        </div>
      )}
    </Modal>
  );
};
