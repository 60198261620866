import React from 'react';

import type MapLibreDraw from '@hyvilo/maplibre-gl-draw';
import type { Map as MapLibreMap } from 'maplibre-gl';
import { createContext, useContext } from 'react';

interface IMapContext {
  mapLibreHandle?: MapLibreMap;
  drawHandle?: MapLibreDraw;
}

const MapContext = createContext<IMapContext>({});

interface IMapContextProviderProps {
  children?: React.ReactNode;
  mapLibreHandle?: MapLibreMap;
  drawHandle?: MapLibreDraw;
}

export const MapContextProvider: React.FC<IMapContextProviderProps> = ({
  children,
  mapLibreHandle,
  drawHandle,
}) => {
  const contextValue = React.useMemo(
    () => ({
      mapLibreHandle,
      drawHandle,
    }),
    [mapLibreHandle, drawHandle],
  );

  return (
    <MapContext.Provider value={contextValue}>{children}</MapContext.Provider>
  );
};

export const useMapContext = (): IMapContext => useContext(MapContext);
