import { env } from '@smack/core/env';
import store from '@smack/core/store';
import { SET_ONLINE } from '@smack/core/store/app/types';
import axios, {
  type AxiosError,
  type AxiosInstance,
  type AxiosProgressEvent,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
  type ResponseType,
} from 'axios';

export interface IPagination<T> {
  next?: string;
  previous?: string;
  count?: number;
  results: T[];
}

/**
 * The base API query response with only one result.
 *
 * @template TResult - OPTIONAL (default: `unknown`) - The type of the result.
 */
export interface ISingleResultBaseQueryResponse<TResult = unknown> {
  results: TResult;
}

export interface AxiosErrorCallback {
  id: string;
  callBack: (response: AxiosError) => void;
}

// Axios Client abstractions to make all api requests
class BaseRESTClient extends EventTarget {
  isHoldingRequests: boolean;

  private axiosInstance: AxiosInstance;

  private callbackTimeout: NodeJS.Timeout | null = null;

  private axiosErrorCallback: AxiosErrorCallback[] = [];

  callAllErrorsCallback(error: AxiosError): void {
    if (this.callbackTimeout) {
      clearTimeout(this.callbackTimeout);
    }
    this.callbackTimeout = setTimeout(() => {
      this.axiosErrorCallback.forEach((c) => c.callBack(error));
    }, 500);
  }

  public addErrorsCallback(callBack: AxiosErrorCallback): void {
    this.axiosErrorCallback = [
      ...this.axiosErrorCallback.filter((c) => c.id !== callBack.id),
      callBack,
    ];
  }

  public removeErrorsCallback(id: string): void {
    this.axiosErrorCallback = this.axiosErrorCallback.filter(
      (c) => c.id !== id,
    );
  }

  private setupInterceptor(): void {
    // Mark the app as offline when a network errors occurs
    // Request releasing will be managed by <NoInternet />
    this.axiosInstance.interceptors.response.use(
      (successfulResponse) => {
        return successfulResponse;
      },
      (axiosError: AxiosError) => {
        this.callAllErrorsCallback(axiosError);
        if (
          // Assert error is an Axios one, otherwise throw the error
          !axios.isAxiosError(axiosError) ||
          // Ignore canceled requests
          axiosError.code === 'ERR_CANCELED' ||
          // Ignore 4xx,5xx codes, no response means the request couldn't go through
          axiosError.response ||
          // Non-configured requests cannot be retried
          !axiosError.config
        )
          return Promise.reject(axiosError);

        // TODO: use setOnline function after fix circular import
        store.dispatch({
          type: SET_ONLINE,
          payload: false,
        });
        this.holdRequests();
        // Return a retry request that will be held until reconnection
        return this.request(axiosError.config);
      },
      {
        // Ignore requests made to Weather/Map services
        runWhen: (config) => {
          return config.url?.startsWith(env.VITE_API_URL) ?? false;
        },
      },
    );
    // Add Authorization header to requests made to backend
    this.axiosInstance.interceptors.request.use(
      (config) => {
        config.headers.set(
          'Authorization',
          this.getAuthorizationBearerString(),
          true,
        );
        return config;
      },
      (reason) => Promise.reject(reason),
      {
        // Ignore requests made to Weather/Map services
        runWhen: (config) => {
          return config.url?.startsWith(env.VITE_API_URL) ?? false;
        },
      },
    );
  }

  constructor() {
    super();
    this.isHoldingRequests = false;
    this.axiosInstance = axios.create();
    this.setupInterceptor();
  }

  getApiEntrypointPath(): string {
    return '/api/v1';
  }

  getApiUrlEntryPoint(): string {
    return `${env.VITE_API_URL}${this.getApiEntrypointPath()}`;
  }

  getAuthorizationBearerString(): string {
    return this.isTokenAvailable
      ? `Bearer ${localStorage.getItem('access')}`
      : '';
  }

  get isTokenAvailable(): boolean {
    return !!localStorage.getItem('access');
  }

  holdRequests(): void {
    this.isHoldingRequests = true;
    this.dispatchEvent(new CustomEvent('hold'));
  }

  releaseRequests(): void {
    this.isHoldingRequests = false;
    this.dispatchEvent(new CustomEvent('release'));
  }

  waitForRequestRelease(): Promise<void> {
    if (!this.isHoldingRequests) return Promise.resolve();
    return new Promise((resolve) => {
      this.addEventListener(
        'release',
        () => {
          resolve();
        },
        { once: true },
      );
    });
  }

  /**
   * Send a get request to the api
   * @param url Relative path from API base
   * @param parameters Query parameters of the request
   * @param isAbsoluteUrl Pass true to make requests
   * @param signal
   * @param responseType
   * @returns a promise on the result of the request
   */
  async get<T>(
    url: string,
    parameters?: Record<string | number, unknown>,
    isAbsoluteUrl?: boolean,
    signal?: AbortSignal,
    responseType: ResponseType = 'json',
  ): Promise<T> {
    await this.waitForRequestRelease();
    return this.axiosInstance.get(
      `${isAbsoluteUrl ? '' : this.getApiUrlEntryPoint()}${url}`,
      {
        signal,
        responseType,
        params: parameters,
      },
    );
  }

  async getIteratePages<T>(
    url: string,
    parameters: Record<string | number, unknown> = {},
    signal?: AbortSignal,
  ): Promise<T> {
    const accumulatedResults: T[] = [];
    let nextPageUrl: string | null = url;
    let firstCall = true;

    while (nextPageUrl && !signal?.aborted) {
      const response = await this.get<{
        data: {
          count: number;
          next: string;
          previous: string;
          results: T[];
        };
      }>(
        nextPageUrl,
        firstCall ? parameters : undefined,
        nextPageUrl.startsWith('http'),
        signal,
      );
      firstCall = false;
      accumulatedResults.push(...response.data.results);
      nextPageUrl = response.data.next;
    }

    return { data: { results: accumulatedResults } } as T;
  }

  async patch<T>(
    data,
    url: string,
    parameters?: Record<string, unknown>,
    signal?: AbortSignal,
  ): Promise<AxiosResponse<T>> {
    await this.waitForRequestRelease();
    return this.axiosInstance.patch(
      `${this.getApiUrlEntryPoint()}${url}`,
      data,
      {
        signal,
        params: parameters,
      },
    );
  }

  async put<T>(
    data,
    url: string,
    parameters?: Record<string, unknown>,
    signal?: AbortSignal,
  ): Promise<T> {
    await this.waitForRequestRelease();
    return this.axiosInstance.put(`${this.getApiUrlEntryPoint()}${url}`, data, {
      signal,
      params: parameters,
    });
  }

  async post<T>(
    data,
    url: string,
    parameters?: Record<string, unknown>,
    onUploadProgress?: (event: AxiosProgressEvent) => void,
    signal?: AbortSignal,
    responseType: ResponseType = 'json',
  ): Promise<AxiosResponse<T>> {
    await this.waitForRequestRelease();
    return this.axiosInstance.post(
      `${this.getApiUrlEntryPoint()}${url}`,
      data,
      {
        params: parameters,
        onUploadProgress,
        signal,
        responseType,
      },
    );
  }

  async delete<T>(
    url: string,
    parameters?: Record<string, unknown>,
    signal?: AbortSignal,
  ): Promise<T> {
    await this.waitForRequestRelease();
    return this.axiosInstance.delete(`${this.getApiUrlEntryPoint()}${url}`, {
      params: parameters,
      signal,
    });
  }

  async request(config: InternalAxiosRequestConfig): Promise<unknown> {
    await this.waitForRequestRelease();
    return this.axiosInstance.request(config);
  }
}

export const RESTClient = new BaseRESTClient();

export const axiosRejectErrorCatcher = (
  err: AxiosError,
  callback?: (err: AxiosError) => void,
): Promise<AxiosError> | undefined => {
  if (err.code === 'ERR_CANCELED') {
    // Do nothing
    return;
  }

  if (callback) {
    callback(err);
  } else {
    return Promise.reject(err);
  }
};
