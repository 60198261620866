import type { Category } from '@smack/core/api/models/categories/Category';
import type { LinkGroup } from '@smack/core/api/models/categories/LinkGroup';
import type { InputLink } from '@smack/core/api/models/objects/Link/Link';
import { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import {
  CancelButton,
  SaveButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { LinkGroupBaseObjectSelector } from '@smack/core/views/oldViewsToSort/Layouts/Forms/AddLinks/LinkGroupBaseObjectSelector/LinkGroupBaseObjectSelector';
import { CategorySelector } from '@smack/core/views/oldViewsToSort/Layouts/Forms/CategorySelector';
import type React from 'react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useAsyncMemo } from 'use-async-memo';

interface LinkBaseObjectInputProps {
  isWritable?: boolean;
  onChange: (inputLink: Partial<InputLink>) => Promise<void>;
  link: InputLink;
  linkGroup: LinkGroup;
}

export const LinkBaseObjectInput: React.FC<LinkBaseObjectInputProps> = ({
  link,
  linkGroup,
  isWritable = true,
  onChange,
}) => {
  const [loading, setLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [value, setValue] = useState<number>(link.targetBaseobjectId);
  const [category, setCategory] = useState<Category>();
  const [t] = useTranslation();

  const baseObjectValue: BaseObject | undefined = useAsyncMemo(
    () => {
      if (!value) return undefined;
      return BaseObject.getBaseObject(value);
    },
    [value],
    undefined,
  );

  const categoryId = category?.id ?? linkGroup.populationCategory?.id;

  const onSave = () => {
    setLoading(true);

    onChange({
      linkGroupId: linkGroup.id,
      targetBaseobjectId: value,
      targetBaseObjectTitle: baseObjectValue?.title,
    })
      .then(() => setIsUpdating(false))
      .catch(() => toast.error(t('linkModal.errors.updateField')))
      .finally(() => setLoading(false));
  };

  return (
    <div className={'flex items-center justify-center gap-3 '}>
      <p className="">{baseObjectValue?.title}</p>
      {isWritable ? (
        <Icon
          onClick={() => setIsUpdating(true)}
          icon={{ name: 'pen', familyStyle: 'fal' }}
          className={'text-xs text-gray-500 hover:text-gray-600 cursor-pointer'}
        />
      ) : null}

      <Modal
        icon={{ name: 'link' }}
        title={t('linkModal.objectName')}
        open={isUpdating}
        onClose={setIsUpdating}
      >
        {categoryId ? (
          <div className={'w-[400px]'}>
            <LinkGroupBaseObjectSelector
              linkGroup={linkGroup}
              values={baseObjectValue}
              category={categoryId}
              multiple={false}
              onChange={(object) => {
                if (!object?.id) return;
                setValue(object.id);
              }}
            />
          </div>
        ) : (
          <div className={'md:min-w-[50vw]'}>
            <CategorySelector
              onCategoryClicked={setCategory}
              startCategoryId={linkGroup.populationCategory?.id}
            />
          </div>
        )}
        <div className={'flex items-center justify-end gap-3 mt-4'}>
          <CancelButton onClick={() => setIsUpdating(false)} />
          <SaveButton isLoading={loading} onClick={onSave} />
        </div>
      </Modal>
    </div>
  );
};
