import type { ITemplateSkeletonComponent } from '.';

export const FeaturePropertiesSkeleton: ITemplateSkeletonComponent = ({
  offset = 0,
}) => (
  <>
    <rect x={10} y={offset} width={40} height={10} rx={5} />
    <rect x={80} y={offset} width={300} height={10} rx={5} />
  </>
);

FeaturePropertiesSkeleton.itemOffset = 30;
