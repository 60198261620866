import React, { createContext, useContext } from 'react';

export interface IBaseObjectViewContext {
  areActionButtonsReady: boolean;
  setAreActionButtonsReady: (data: boolean) => void;
}
export const BaseObjectViewContext = createContext<IBaseObjectViewContext>({
  areActionButtonsReady: true,
  setAreActionButtonsReady: () => {},
});
export const useBaseObjectViewContextContext = (): IBaseObjectViewContext =>
  useContext(BaseObjectViewContext);

interface IBaseObjectViewContextProviderProps {
  children?: React.ReactNode;
}

export const BaseObjectViewContextProvider: React.FC<
  IBaseObjectViewContextProviderProps
> = ({ children }) => {
  const [areActionButtonsReady, setAreActionButtonsReady] =
    React.useState<boolean>(true);

  const value: IBaseObjectViewContext = React.useMemo(() => {
    return {
      areActionButtonsReady,
      setAreActionButtonsReady,
    };
  }, [areActionButtonsReady]);

  return (
    <BaseObjectViewContext.Provider value={value}>
      {children}
    </BaseObjectViewContext.Provider>
  );
};
