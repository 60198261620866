import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import type { IChecklist } from '@smack/core/api/models/tasks/Checklist';
import type { ChecklistItemLog } from '@smack/core/api/models/tasks/ChecklistItemLog';
import { ChecklistLog } from '@smack/core/api/models/tasks/ChecklistLog';
import { type ILog, Log, LogAction } from '../../logs/Log';
import type { IStatus } from '../Status';
import type { ITask, Task } from '../Task';
import type { ITaskMessage } from '../TaskMessage';

/**
 *  interface of ITask log content
 *
 * @export
 * @interface ITaskLogContent
 */
export interface ITaskLogContent {
  id: number;
  task?: ITask;
  oldTaskStatus?: IStatus;
  newTaskStatus?: IStatus;
  taskField?: string;
  taskFieldOldValue?: string;
  taskFieldNewValue?: string;
  taskMessage?: ITaskMessage;
  createdChecklist?: IChecklist;
  createdChecklistLabel?: string;
  deletedChecklistLabel?: string;
}

/**
 * interface Of itasklog
 *
 * @export
 * @interface ITaskLog
 * @extends {ILog}
 */
export interface ITaskLog extends ILog {
  content?: ITaskLogContent;
}

/**
 *  class Task log
 *
 * @export
 * @class TaskLog
 * @extends {Log}
 */
export class TaskLog extends Log {
  content?: ITaskLogContent;

  /**
   * // Creates an instance of TaskLog.
   * @param {ITaskLog} data
   * @memberof TaskLog
   */
  constructor(data: ITaskLog) {
    super(data);
    this.content = data.content;
  }

  /**
   *
    Get the task logs for a task id.
   * @static
   * @param {number} taskId
   * @return {*}  {Promise<TaskLog[]>}
   * @memberof TaskLog
   */
  static getTaskLogs(taskId: number): Promise<TaskLog[]> {
    return RESTClient.get<{ data: { results: ITaskLog[] } }>(
      `/tasks/task-logs?tasks=${taskId}`,
    ).then((res) => res?.data?.results.map((r) => new TaskLog(r)));
  }

  static async getTaskLogsRecursively(
    task: Task,
  ): Promise<(TaskLog | ChecklistLog | ChecklistItemLog)[]> {
    const logs = await TaskLog.getTaskLogs(task.id);
    const checklistLogs = (
      await Promise.all(
        task.checklists.map((checklist) =>
          ChecklistLog.getChecklistLogsRecursively(checklist.id),
        ),
      )
    )
      .flat()
      .filter((log) => log.action !== LogAction.CREATE);
    return [...logs, ...checklistLogs];
  }
}
