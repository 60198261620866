import { QuitOrSaveConfirmAlert } from '@smack/core/components/DataDisplay/Alerts/ConfirmAlert';
import type { BaseObjectFormViewRenderForwardedRef } from '@smack/core/components/ViewRenderer/interfaces';
import { spawnModal } from '@smack/core/utils/modal';

export const onQuit = (
  formRef: BaseObjectFormViewRenderForwardedRef | null,
  onClose?: () => void,
  onSubmit?: () => void,
): void => {
  if (
    !formRef?.useForm?.formState ||
    !Object.keys(formRef.useForm?.formState.dirtyFields).length
  ) {
    onClose?.();
    return;
  }
  spawnModal({
    render: ({ onClose: onCloseModal }) => {
      return (
        <QuitOrSaveConfirmAlert
          onCloseModal={onCloseModal}
          onCloseButton={(): void => {
            onClose?.();
            onCloseModal();
          }}
          onOk={(): void => {
            onSubmit?.();
            onCloseModal();
          }}
        />
      );
    },
  });
};
