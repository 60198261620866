import type { LegacyMapModes } from '@smack/core/store/maps/types';
import {
  baseobjectVectorClustersSourceLabel,
  baseobjectVectorPointsSourceLabel,
} from '@smack/core/views/oldViewsToSort/Views/Objects/ObjectMap/utils';
import type { FeatureCollection } from 'geojson';
import type {
  GeoJSONSourceSpecification,
  LayerSpecification,
  SourceSpecification,
} from 'maplibre-gl';

type ISourceOutput = Record<LegacyMapModes, SourceSpecification>;

type ILayerOutput = Record<LegacyMapModes, LayerSpecification[]>;

interface ISourceLayer {
  sources: ISourceOutput;
  layers: ILayerOutput;
}

const getLegacySources = (ClusterProperties: {
  [key: string]: number;
}): ISourceOutput => {
  const points: FeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  };

  const legacyClusterSource: GeoJSONSourceSpecification = {
    type: 'geojson',
    data: points,
    cluster: true,
    clusterProperties: ClusterProperties,
    clusterMaxZoom: 30,
  };

  const normalSource: GeoJSONSourceSpecification = {
    type: 'geojson',
    data: points,
  };

  return {
    legacyCluster: legacyClusterSource,
    legacyPoint: normalSource,
    legacyHeatmap: normalSource,
  };
};

const getLegacyLayers = (): ILayerOutput => {
  const legacyPoint = (source: LegacyMapModes): LayerSpecification[] => {
    return [
      {
        id: `point-${source}`,
        type: 'circle',
        source,
        paint: {
          'circle-radius': [
            'interpolate',
            ['exponential', 14],
            ['zoom'],
            15.4,
            4,
            15.6,
            14,
          ],
          'circle-color': [
            'case',
            ['boolean', ['feature-state', 'focused'], false],
            'red',
            ['get', 'color'],
          ],
          'circle-stroke-width': [
            'interpolate',
            ['exponential', 3],
            ['zoom'],
            15.4,
            0.857,
            15.6,
            3,
          ],
          'circle-stroke-color': [
            'case',
            ['boolean', ['feature-state', 'linked'], false],
            'red',
            'white',
          ],
        },
        filter: ['==', '$type', 'Point'],
      },
      {
        id: `symbol-${source}`,
        type: 'symbol',
        source,
        filter: ['all', ['!=', 'cluster', true], ['==', '$type', 'Point']],
        layout: {
          'text-field': [
            'case',
            ['>', ['length', ['get', 'description']], 50],
            ['concat', ['slice', ['get', 'description'], 0, 50], '…'],
            ['get', 'description'],
          ],
          'text-size': 10,
          'text-font': ['Open Sans Regular'],
          'text-justify': 'center',
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-radial-offset': 2,
          'icon-image': ['get', 'icon'],
          'icon-ignore-placement': true,
          'icon-size': [
            'interpolate',
            ['exponential', 0.5],
            ['zoom'],
            15.4,
            0,
            15.6,
            0.5,
          ],
        },
        paint: {
          'icon-color': '#FFFFFF',
          'text-halo-width': 4,
          'text-color': 'black',
          'text-halo-color': 'white',
          'text-opacity': [
            'interpolate',
            ['exponential', 1],
            ['zoom'],
            15.4,
            0,
            15.6,
            1,
          ],
        },
      },
    ];
  };

  const legacyHeatmap: LayerSpecification[] = [
    {
      id: 'legacyHeatmap',
      type: 'heatmap',
      source: 'legacyHeatmap',
      maxzoom: 19,
      paint: {
        'heatmap-weight': {
          property: 'dbh',
          type: 'exponential',
          stops: [
            [1, 0],
            [62, 1],
          ],
        },
        'heatmap-intensity': {
          type: 'interval',
          stops: [
            [11, 1],
            [15, 3],
          ],
        },
        'heatmap-color': [
          'interpolate',
          ['linear'],
          ['heatmap-density'],
          0,
          'rgba(236,222,239,0)',
          0.2,
          'rgb(208,209,230)',
          0.4,
          'rgb(166,189,219)',
          0.6,
          'rgb(103,169,207)',
          0.8,
          'rgb(28,144,153)',
        ],
        'heatmap-radius': {
          type: 'interval',
          stops: [
            [11, 15],
            [15, 20],
          ],
        },
        'heatmap-opacity': {
          type: 'interval',
          stops: [
            [14, 1],
            [18, 0],
          ],
        },
      },
    },
  ];

  return {
    legacyCluster: legacyPoint('legacyCluster'),
    legacyPoint: legacyPoint('legacyPoint'),
    legacyHeatmap,
  };
};
export const buildLegacySourcesAndLayers = (
  ClusterProperties: { [key: string]: number } = {},
): ISourceLayer => {
  return {
    sources: getLegacySources(ClusterProperties),
    layers: getLegacyLayers(),
  };
};

export const setFeatureState = (
  featureId: string | number | undefined,
): void => {
  const sources = [
    baseobjectVectorPointsSourceLabel,
    baseobjectVectorClustersSourceLabel,
    'legacyPoint',
    'legacyCluster',
  ];
  const { MainMap } = window.Hyvilo.Utils;
  if (!MainMap) {
    setTimeout(() => setFeatureState(featureId), 250);
    return;
  }
  if (!MainMap?.isStyleLoaded()) {
    MainMap.once('idle', () => setFeatureState(featureId));
    return;
  }
  sources.forEach((source) => {
    if (MainMap.getSource(source)) {
      const features = MainMap.querySourceFeatures(source, {
        sourceLayer: 'default',
      });
      features.forEach((f) => {
        if (f.id === undefined) return;
        if (featureId === undefined || f.id !== featureId) {
          MainMap.setFeatureState(
            {
              id: f.id,
              source,
              sourceLayer: 'default',
            },
            {
              focused: false,
              linked: false,
            },
          );
        }
      });
      if (featureId) {
        MainMap.setFeatureState(
          {
            id: featureId,
            source,
            sourceLayer: 'default',
          },
          {
            focused: true,
          },
        );
      }
    }
  });
};
