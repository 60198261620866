import { Loader } from '@smack/core/components/Actions/Loader';
import React, { lazy, useMemo } from 'react';

import type {
  DateSelectArg,
  DatesSetArg,
  EventHoveringArg,
} from '@fullcalendar/core';
import type FullCalendar from '@fullcalendar/react';
import { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { AvailableView } from '@smack/core/components/DataDisplay/CalendarTimeLine/enums';
import { NoContentMessage } from '@smack/core/components/DataDisplay/NoContentMessage';
import { env } from '@smack/core/env';
import { useCustomerPreferences } from '@smack/core/hooks/preferences/useCustomerPreferences/useCustomerPreferences';
import { useNavigation } from '@smack/core/hooks/useNavigation/useNavigation';
import type { IViewProps } from '@smack/core/hooks/views/types';
import type { AppState } from '@smack/core/store';
import {
  CalendarTooltip,
  type EventSetter,
  type IEventHoveringArgWithExtraProps,
} from '@smack/core/views/oldViewsToSort/Views/Objects/Calendar/Components/CalendarTooltip';
import {
  LinkCalendarType,
  getManagerByType,
} from '@smack/core/views/oldViewsToSort/Views/Objects/ObjectLinkCalendar/ModeManagers/LinkCalendarType';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAsyncMemo } from 'use-async-memo';

const CalendarTimeline = lazy(() =>
  import(
    '@smack/core/components/DataDisplay/CalendarTimeLine/CalendarTimeLine'
  ).then((module) => ({ default: module.CalendarTimeline })),
);

interface IProps extends IViewProps {
  mode?: LinkCalendarType;
}

export const ObjectLinkCalendar: React.FC<IProps> = ({
  mobile,
  mode = LinkCalendarType.Temporal,
}) => {
  const navigate = useNavigation();
  const { settings } = useSelector((app: AppState) => app.App);
  const { object } = useParams();
  const { selectedCalendarLinkGroups } = useSelector(
    (state: AppState) => state.Objects,
  );
  const { t } = useTranslation();
  const tooltipRef = React.useRef<EventSetter>();
  const [dateSetArg, setDateSetArg] = React.useState<DatesSetArg>();
  const calendar = React.useRef<FullCalendar>(null);
  const [reload, setReload] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const [customerPreferences, setCustomerPreferences] =
    useCustomerPreferences();

  const currentBaseObject = useAsyncMemo((): Promise<
    BaseObject | undefined
  > => {
    if (!object) return Promise.resolve(undefined);
    return BaseObject.getBaseObject(Number.parseInt(object));
  }, [object]);

  const manager = useMemo(() => getManagerByType(mode), [object, reload]);
  const [calendarView, setCalendarView] = React.useState<AvailableView>(
    manager.initialView,
  );

  const setDateArg = (arg: DatesSetArg): void => {
    if (!arg) return;
    if (!dateSetArg) setDateSetArg(arg);
    if (
      arg.startStr !== dateSetArg?.startStr ||
      arg.endStr !== dateSetArg?.endStr
    ) {
      setDateSetArg(arg);
    }
  };

  const { events, resources } = useAsyncMemo(
    async () => {
      if (!currentBaseObject || !dateSetArg?.end || !dateSetArg?.start) {
        return {
          events: [],
          resources: [],
        };
      }
      setLoading(true);
      try {
        const dateRange = {
          dtstart: dateSetArg.start.toISOString(),
          dtend: dateSetArg.end.toISOString(),
        };
        const resources = await manager.getResources(
          currentBaseObject,
          dateRange,
          selectedCalendarLinkGroups?.[currentBaseObject.id],
          settings?.headerColor,
        );
        const events = await manager.getEvents(
          currentBaseObject,
          dateRange,
          calendarView,
          resources,
          selectedCalendarLinkGroups?.[currentBaseObject.id],
          settings?.headerColor,
        );
        return { events: events, resources: resources };
      } finally {
        setLoading(false);
      }
    },
    [
      currentBaseObject,
      dateSetArg,
      calendarView,
      selectedCalendarLinkGroups,
      reload,
    ],
    {
      events: [],
      resources: [],
    },
  );

  const onMouseEnter = React.useCallback((ev: EventHoveringArg) => {
    if (tooltipRef.current)
      tooltipRef.current(ev as IEventHoveringArgWithExtraProps);
  }, []);

  const onMouseLeave = React.useCallback(() => {
    if (tooltipRef.current) tooltipRef.current(null);
  }, []);

  const handleClickEmpty = (arg: DateSelectArg): void => {
    if (!currentBaseObject) return;
    manager.onCalendarEmptyClick(arg, currentBaseObject, () => {
      setReload(new Date().toISOString());
    });
  };

  if (!object) {
    return (
      <NoContentMessage
        label={t('calendarTimeline.noObjectSelected')}
        icon={{ name: 'warning' }}
      />
    );
  }

  return (
    <div
      className={
        mobile
          ? 'h-full bg-view text-text max-w-full overflow-scroll'
          : 'p-10 w-full h-full bg-view text-text'
      }
      aria-label="object-linked-calendar"
      id={'calendar-wrapper'}
    >
      <Loader isDataLoaded={!loading}>
        <CalendarTooltip setEventRef={tooltipRef} />
        <div className={mobile ? 'h-full w-[1000px] pt-28' : 'h-full w-full'}>
          <CalendarTimeline
            ref={calendar}
            resources={resources}
            events={events}
            initialViewType={manager.initialView}
            availableViews={manager.availableViews}
            isPrintable={true}
            datesSet={setDateArg}
            preferences={customerPreferences?.objectLinkedCalendar}
            setPreferences={(calendarPreferences) => {
              setCustomerPreferences({
                ...customerPreferences,
                objectLinkedCalendar: calendarPreferences,
              });
            }}
            onViewChange={setCalendarView}
            select={handleClickEmpty}
            eventChange={(arg): Promise<void> =>
              manager.onCalendarChangeDate(arg, () =>
                setReload(new Date().toISOString()),
              )
            }
            eventContent={(args) => {
              if (
                env.VITE_DISPLAY_WEIGHT_ON_SCHEDULE_SLOT &&
                args.event.extendedProps?.linkGroup.isWeighted
              ) {
                return (
                  <div className="fc-event-title-container">
                    <div className="fc-event-title fc-sticky">
                      {args.event.extendedProps.link.weight}
                    </div>
                  </div>
                );
              }
              // default rendering
              return (
                <div className="fc-event-main-frame">
                  {args.timeText && (
                    <div className="fc-event-time">{args.timeText}</div>
                  )}
                  <div className="fc-event-title-container">
                    <div className="fc-event-title fc-sticky">
                      {args.event.title || ' '}
                    </div>
                  </div>
                </div>
              );
            }}
            eventClick={(e) =>
              manager?.onClickEvent(
                e,
                () => setReload(new Date().toISOString()),
                navigate,
              )
            }
            eventMouseEnter={onMouseEnter}
            eventMouseLeave={onMouseLeave}
            {...manager?.getCalendarOptions(currentBaseObject, () => {
              setReload(new Date().toISOString());
            })}
          />
        </div>
      </Loader>
    </div>
  );
};
