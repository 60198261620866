import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import { downloadResponseBlob } from '@smack/core/utils/Download';
import type { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';
import { toast } from 'react-hot-toast';
import type { IActionLog } from '../ActionLog';

/**
 * interface for LinkGroup model
 *
 * @export
 * @interface ILinkGroup
 */
export interface IAction {
  id: number;
  label: string;
  handlerType: string;
  logs: IActionLog[];
}

// Errors from these actions are handled by the action executor
const silentErrorHandlerTypes = [
  'GENERATE_SUPERSET_GUEST_TOKEN',
  'GENERATE_OBJECT_RESTRICTED_SUPERSET_GUEST_TOKEN',
];

// These actions return files and not JSON standard output
const fileGenerationHandlerTypes = [
  'DOC_GENERATOR',
  'FILL_PDF',
  'FILL_PDF_DTDICT',
  'GENERATE_ICAL_INVITATION',
];

/**
 * class for the LinkGroup Model
 *
 * @export
 * @class LinkGroup
 */
export class Action implements IAction {
  id: number;

  label: string;

  handlerType: string;

  logs: IActionLog[];

  constructor(data: IAction) {
    this.id = data.id;
    this.label = data.label;
    this.handlerType = data.handlerType;
    this.logs = data.logs;
  }

  static getActions(
    categoryId: number | string,
    baseobjectId: number | string,
  ): Promise<Action[]> {
    return RESTClient.get<{ data: { results: IAction[] } }>(
      `/actions/actions?category=${categoryId}&baseobject=${baseobjectId}`,
    ).then((res) => res.data?.results.map((l) => new Action(l)));
  }

  triggerActionFromBaseObject(
    baseobjectId: number | string,
    requestFile = true,
  ): Promise<unknown> {
    return this.triggerAction(
      `/objects/baseobjects/${baseobjectId}/actions/${this.id}/executions`,
      requestFile,
    );
  }

  triggerActionFromTask(
    taskId: number | string,
    requestFile = true,
  ): Promise<unknown> {
    return this.triggerAction(
      `/tasks/tasks/${taskId}/actions/${this.id}/executions`,
      requestFile,
    );
  }

  async triggerAction(uri: string, requestFile = true): Promise<unknown> {
    const response = await RESTClient.post<unknown>(
      undefined,
      uri,
      undefined,
      undefined,
      undefined,
      requestFile ? 'blob' : 'json',
    ).catch((err: AxiosError) => {
      if (
        err.response?.status !== 200 &&
        !silentErrorHandlerTypes.includes(this.handlerType)
      ) {
        toast.error(t('process.action.error'));
      }
    });

    if (!response) return;

    if (fileGenerationHandlerTypes.includes(this.handlerType) && requestFile) {
      toast.success(t('process.action.filesGenerated'));
      return downloadResponseBlob(response as AxiosResponse<Blob>);
    }

    return response?.data;
  }
}
