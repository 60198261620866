import type {
  BaseObjectFormOutput,
  BaseObjectFormOutputValues,
  BaseObjectSubmitAttribute,
} from '@smack/core/components/ViewRenderer/interfaces';

export const formOutputToAttribute = (
  formOutput: BaseObjectFormOutput,
): BaseObjectSubmitAttribute[] => {
  return Object.entries(formOutput).map(([keyId, val]) => {
    const id = Number.parseInt(keyId);
    const values: BaseObjectFormOutputValues[] = [];

    if (Array.isArray(val)) {
      for (const v of val) {
        if (typeof v === 'object' && 'value' in v) {
          values.push(v.value);
        } else {
          values.push(v);
        }
      }
    } else if (val && typeof val === 'object' && 'value' in val) {
      values.push(val.value);
    } else {
      values.push(val);
    }

    return { id, values };
  });
};
