import { Checklist } from '@smack/core/api/models/tasks/Checklist';
import type { Task } from '@smack/core/api/models/tasks/Task';
import { SaveButton } from '@smack/core/components/Actions/Buttons/Button';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { Input } from '@smack/core/components/DataInput/Input/Input';
import React from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface ICreateProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  checklist?: never;
  task: Task;
  reloadProject: () => Promise<void>;
}

interface IUpdateProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  checklist: Checklist;
  task?: Task;
  reloadProject: () => Promise<void>;
}

type IProps = ICreateProps | IUpdateProps;

interface IFormValues {
  tasks: { id?: number; label: string };
  label: string;
}

export const ChecklistCreateEditModal: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  checklist,
  task,
  reloadProject,
}) => {
  const isEdit = !!checklist;

  const [t] = useTranslation();
  const { register, handleSubmit, formState, reset } = useForm<IFormValues>();

  const resetForm = (): void => {
    reset({
      label: checklist?.label,
    });
  };

  React.useEffect(() => {
    resetForm();
  }, [checklist]);

  const id = checklist?.id || 'create';

  const onSubmit = React.useCallback<SubmitHandler<IFormValues>>(
    async (values) => {
      if (checklist) {
        await checklist.patch({ label: values.label });
      } else {
        await Checklist.create(task.id, values.label);
      }
      setIsOpen(false);
      reloadProject();
    },
    [],
  );

  return (
    <Modal
      open={isOpen}
      onClose={setIsOpen}
      icon={{ name: isEdit ? 'pen' : 'plus' }}
      title={t(isEdit ? 'edit.checklist' : 'create.checklist')}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3.5">
        <Input
          native
          id={`checklist-${id}`}
          label={t('formInputLabels.name')}
          error={formState.errors.label?.message}
          {...register('label', {
            required: {
              value: true,
              message: t('formValidation.required'),
            },
          })}
        />
        <div className="mt-2 flex items-center justify-end">
          <SaveButton type="submit" />
        </div>
      </form>
    </Modal>
  );
};
