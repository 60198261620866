import { DangerAlert } from '@smack/core/components/DataDisplay/Alerts/Alerts';
import type { ViewLayoutComponent } from '@smack/core/components/ViewRenderer/interfaces';
import { PreviewSection } from '@smack/core/components/ViewRenderer/layouts/preview/PreviewSection';
import { useTranslation } from 'react-i18next';

export const BaseObjectPreviewLayout: ViewLayoutComponent = ({
  renderedSections,
}) => {
  return (
    <div className="flex-grow flex w-full flex-col p-2 overflow-y-auto">
      {renderedSections.map(([section, elements]) => (
        <PreviewSection
          key={section.id}
          section={section}
          elements={elements}
        />
      ))}
    </div>
  );
};

BaseObjectPreviewLayout.Error = () => {
  const [t] = useTranslation();

  return (
    <div className="h-full w-full">
      <DangerAlert title={t('viewErrors.viewNotAvailable')}>
        {t('viewErrors.viewNotAvailableDescription')}
      </DangerAlert>
    </div>
  );
};
