import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import { generateDuotone } from '@smack/core/utils/ColorUtils';
import type React from 'react';

interface IProps {
  children?: string;
  color?: string;
  className?: string;
  icon?: IconField;
  containerProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  noDefaultIcon?: boolean;
  link?: string;
  textClassName?: string;
  maxWidth?: string;
}

/**
 * Generic Badge Element
 * @param props
 * @returns JSX.Elements
 */
export const Badge = (props: IProps): JSX.Element => {
  const {
    color,
    children,
    className,
    containerProps,
    icon,
    link,
    textClassName,
    noDefaultIcon = false,
    maxWidth = '42ch',
  } = props;

  const [iconColor, backgroundColor] = generateDuotone(color);

  const defaultIcon = noDefaultIcon ? null : (
    <span
      className="w-4 h-4 mr-1 rounded-full"
      data-testid="badge-default-icon"
      style={{ backgroundColor: iconColor }}
    />
  );

  return (
    <div data-testid={'Badge'} {...containerProps}>
      <span
        title={children}
        className={`py-1 truncate px-2 text-[10px] rounded-full min-w-[90px] w-fit text-center flex items-center justify-center text-text ${
          className || ''
        }`}
        style={{
          backgroundColor: backgroundColor,
        }}
      >
        {icon ? (
          <Icon
            color={iconColor}
            icon={icon?.name ? icon : { name: 'circle' }}
            className={'text-base mr-1'}
          />
        ) : (
          defaultIcon
        )}
        <a
          className={`overflow-hidden whitespace-nowrap text-ellipsis grow ${
            textClassName || ''
          }`}
          style={{ maxWidth }}
          href={link}
          onClick={(e): void => e.stopPropagation()}
        >
          {children?.toUpperCase()}
        </a>
      </span>
    </div>
  );
};
