import { captureException } from '@sentry/react';
import type { IPermissions } from '@smack/core/api/models/categories/Category';
import {
  defaultShowIfAllowedContext,
  useShowIfAllowedContext,
} from '@smack/core/components/DataDisplay/ShowIfAllowed/Context';
import React from 'react';

interface IProps {
  permissions?: (keyof IPermissions)[];
}

export const ShowIfAllowed: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  permissions: requiredPermissions = ['isWritable'],
}) => {
  const permissions = useShowIfAllowedContext();
  React.useEffect(() => {
    // If no context, warn and log to Sentry
    if (Object.is(permissions, defaultShowIfAllowedContext)) {
      const err = new Error('<ShowIfAllowed> was instanced without context.');
      if (import.meta.env.DEV && typeof vitest === 'undefined') {
        console.warn(err);
      }
      captureException(err, { level: 'info' });
    }
  }, []);
  return requiredPermissions.some(
    (requiredPermission) => !!permissions[requiredPermission],
  ) ? (
    <>{children}</>
  ) : null;
};
