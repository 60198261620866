import type React from 'react';

import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import { Tooltip } from '@smack/core/components/DataDisplay/Tooltip';
import { formatLongString } from '@smack/core/utils';

export interface IPageHeaderPropsAction {
  icon: IconField;
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

export interface IPageHeaderProps {
  title: string;
  actions?: IPageHeaderPropsAction[];
  actionNode?: React.ReactNode;
}

export const PageHeader = (props: IPageHeaderProps): JSX.Element => {
  const { title, actions, actionNode } = props;

  return (
    <div className="bg-[#3ea6ed] h-11 flex-shrink-0 w-full flex justify-between items-center text-white">
      <h3 className="uppercase px-3 text-xl leading-none">
        {formatLongString(title, 24)}
      </h3>
      <div className="flex h-full">
        {actions?.map((a) => {
          return (
            <Tooltip key={a.label} title={a.label}>
              <div
                className={`cursor-pointer h-full w-12 text-xl border-l border-border flex items-center justify-center ${
                  a.disabled ? 'text-secondary' : ' text-primary'
                }`}
                onClick={a.disabled ? (): void => {} : a.onClick}
                aria-label={a.label}
              >
                <Icon icon={a.icon} />
              </div>
            </Tooltip>
          );
        }) || null}
        {actionNode}
      </div>
    </div>
  );
};
