import { BaseAlert, type IBaseAlertProps } from '../BaseAlert';

/**
 * Warning Alerts from BaseAlert to use for show an Warning
 * @param props IBaseAlertProps
 * @returns JSX.Element
 */
export const WarningAlert = (props: IBaseAlertProps): JSX.Element => {
  const { children, title, className } = props;
  return (
    <BaseAlert
      className={className}
      icon={{ name: 'circle-exclamation' }}
      title={title || 'Attention'}
      color={'#fefce8'}
      textColor={'#ccb129'}
    >
      {children}
    </BaseAlert>
  );
};
