import {
  type IModalProps,
  Modal,
} from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import type React from 'react';

import {
  BarLoader,
  type BarLoaderProps,
} from '@smack/core/components/Actions/BarLoader/BarLoader';

export interface IModalBarLoader extends Omit<IModalProps, 'children'> {
  text: string;
  progressBarProps?: BarLoaderProps;
}

export const ModalBarLoader: React.FC<IModalBarLoader> = ({
  open,
  onClose,
  text,
  progressBarProps,
  ...modalProps
}): JSX.Element => {
  return (
    <Modal open={open} onClose={onClose} {...modalProps}>
      <div className={'flex flex-col gap-3 w-[300px] justify-center p-4'}>
        <p className={'text-center  text-gray-500'}>{text}</p>
        <BarLoader {...progressBarProps} />
      </div>
    </Modal>
  );
};
