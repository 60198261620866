import type { IconName } from '@fortawesome/fontawesome-common-types';
import type { BaseObjectDisplayAttributes } from '@smack/core/api/models/objects/NewBaseObject';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import type { IListElementAction } from '@smack/core/components/DataDisplay/Lists/ListElements/BaseListElement';
import {
  BaseListElement,
  type IBaseListElementProps,
} from '@smack/core/components/DataDisplay/Lists/ListElements/BaseListElement';
import { ListElementContent } from '@smack/core/components/DataDisplay/Lists/ListElements/Components/ListElementContent/ListElementContent';
import type { Moment } from 'moment';
import type React from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { setModuleStore } from '@smack/core/store/app/actions';
import { useDispatch } from 'react-redux';

export interface BaseObjectListElementProps
  extends BaseObjectDisplayAttributes,
    IBaseListElementProps {
  id: number;
  address?: string;
  date?: Moment;
  icon?: IconField;
  color?: string;
  isUnavailable?: boolean;
  isForbidden?: boolean;
  isLoader?: boolean;
  noOverlayActions?: boolean;
  overlayIcons?: IListElementAction[];
  accessActionOnly?: boolean;
}

export const BaseObjectListElement: React.FC<BaseObjectListElementProps> = ({
  color,
  icon,
  address,
  date,
  bottomLeft,
  bottomRight,
  title,
  subtitle,
  onClick,
  badge,
  isUnavailable,
  isForbidden,
  noOverlayActions = false,
  accessActionOnly = false,
  ...props
}) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (!bottomLeft && address) {
    bottomLeft = { icon: { name: 'map-marker-alt' }, value: address };
  }
  if (!bottomRight && date) {
    bottomRight = {
      icon: { name: 'clock' },
      value: date.format('DD/MM/YYYY HH[h]mm'),
    };
  }

  const getOverlayIcons = (): IListElementAction[] => {
    //@TODO upon list element unification, handle this correctly
    const activeScheduleId =
      Number.parseInt(props?.link?.match(/schedule=(\d+)/)?.[1] ?? '') ||
      undefined;

    const overlayIcons = [] as IListElementAction[];
    if (onClick || props.link) {
      overlayIcons.push({
        icon: {
          name: 'arrow-up-right-from-square' as IconName,
          familyStyle: 'far',
        },
        label: t('baseObjectPanel.mainMenu.access'),
        onClick: (e) => {
          dispatch(
            setModuleStore({
              activePreviewObjectId: undefined,
              activeScheduleId: undefined,
              disableSubNavRouting: false,
            }),
          );
          if (onClick) {
            onClick();
          } else {
            navigate(props?.link ?? '');
          }
        },
      });
    }

    if (!accessActionOnly) {
      overlayIcons.push({
        icon: {
          name: 'magnifying-glass-plus' as IconName,
          familyStyle: 'far',
        },
        label: t('baseObjectPanel.mainMenu.preview'),
        onClick: (e) => {
          e.stopPropagation();
          dispatch(
            setModuleStore({
              activeRightPanel: 'ElementPreview',
              activePreviewObjectId: props.id,
              activeScheduleId: activeScheduleId,
              disableSubNavRouting: true,
            }),
          );
        },
      });
    }

    return overlayIcons;
  };

  return (
    <BaseListElement
      onClick={() => {
        if (onClick) {
          onClick();
          return;
        }
        if (isUnavailable) {
          toast.error(t('errorMessage.baseObjectIsUnavailable'));
          return;
        }
        if (isForbidden) {
          toast.error(t('errorMessage.cannotOpenBaseObject'));
        }
      }}
      {...props}
      noOverlayActions={noOverlayActions}
    >
      <ListElementContent
        title={title ?? ''}
        subtitle={subtitle}
        color={color}
        icon={icon}
        badge={badge}
        bottomLeft={bottomLeft}
        bottomRight={bottomRight}
        overlayIcons={noOverlayActions ? [] : getOverlayIcons()}
      />
    </BaseListElement>
  );
};
