import type { IconName } from '@fortawesome/fontawesome-common-types';
import {
  LinkGroup,
  PopulationType,
} from '@smack/core/api/models/categories/LinkGroup';
import { BaseObjectGroup } from '@smack/core/api/models/objects/BaseObjectGroup';
import type { Link } from '@smack/core/api/models/objects/Link/Link';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import {
  CancelButton,
  ValidateButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { UnlinkConfirmAlert } from '@smack/core/components/DataDisplay/Alerts/ConfirmAlert/Components/UnlinkAlert';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { DnDList } from '@smack/core/components/DataDisplay/Lists/DnDList';
import type { IListElementAction } from '@smack/core/components/DataDisplay/Lists/ListElements/BaseListElement';
import { VirtualizedList } from '@smack/core/components/DataDisplay/Lists/VirtualizedList';
import { NoContentMessage } from '@smack/core/components/DataDisplay/NoContentMessage';
import { ListBlockElementLayout } from '@smack/core/components/ViewRenderer/layouts/ListBlockElementLayout';
import { env } from '@smack/core/env';
import { useLinkLayerManager } from '@smack/core/hooks/useLinkLayerManager/useLinkLayerManager';
import { setModuleStore } from '@smack/core/store/app/actions';
import { reorderArray } from '@smack/core/utils/Array';
import {
  LinkGroupSkeleton,
  ListSkeleton,
  LoaderSkeleton,
} from '@smack/core/utils/Loader';
import { useNonInitialEffect } from '@smack/core/utils/NonInitialEffect';
import { spawnModal } from '@smack/core/utils/modal';
import { EmitterLoading } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Links/Components/EmitterLoading';
import { GroupHeader } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Links/Components/GroupHeader/GroupHeader';
import { linkStorageReducer } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Links/Utils';
import {
  RecurrenceUpdateChoiceObjectModal,
  TypeRecurrenceUpdateChoice,
} from '@smack/core/views/oldViewsToSort/Layouts/Modal/RecurrenceUpdateChoiceObjectModal';
import React from 'react';
import type {
  DraggableLocation,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { IndexRange } from 'react-virtualized';

export interface LinkGroupWithCount {
  type: 'LinkGroupWithCount';
  count: number;
  linkGroup: LinkGroup;
}

interface LinkElement {
  type: 'LinkElement';
  link: Link;
  linkGroup: LinkGroup;
}

interface NoLinks {
  type: 'NoLinks';
}

interface IEmitterLoading {
  type: 'EmitterLoading';
  linkGroupId: number;
}

type LinksListElement =
  | LinkGroupWithCount
  | LinkElement
  | NoLinks
  | IEmitterLoading;

interface IProps {
  isReordering?: boolean;
  object: BaseObject;
  height?: string | number;
  groups: LinkGroup[];
  showOnMap?: boolean;
  setIsReordering?: (val: boolean) => void;
  reload?: () => void;
  populationType?: LinkGroup['populationType'];
  isHidingEmptyGroups?: boolean;
  setIsHidingEmptyGroups?: (isHidingEmptyGroups: boolean) => void;
  autoOpen?: boolean;
  invisible?: boolean;
  noOverlayActions?: boolean;
  accessActionOnly?: boolean;
}

export const LinksGroupContent: React.FC<IProps> = ({
  isReordering,
  isHidingEmptyGroups,
  object,
  groups,
  reload,
  height,
  showOnMap = false,
  populationType,
  setIsHidingEmptyGroups,
  autoOpen = true,
  invisible = false,
  noOverlayActions = false,
  accessActionOnly = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const [openGroups, setOpenGroups] = React.useState<number[]>([]);
  // linkStorage caches all the fetched links to avoid re-requesting them.
  // It's cached and resets whenever new link groups are added (tab change or new floor)
  const [linkStorage, dispatchLinkStorage] = React.useReducer(
    linkStorageReducer,
    {},
  );
  // Every time a group list is passed as props, compute LinkGroupWithCount (to display count badges)
  const [computedGroups, setComputedGroups] = React.useState<
    LinkGroupWithCount[]
  >([]);
  // Elements are computed each time new links are fetched or when a group is opened/closed
  // It is passed to the virtual list to handle the various components
  // This approach avoids the hassle of handling multiple virtual lists in a same container
  const [forcedShowOnMap, setForcedShowOnMap] = React.useState<
    false | undefined
  >();

  const [isInitializing, setIsInitializing] = React.useState(true);

  // LinkLayerManager will handle showing links on the map when in map view.
  const [refreshVectorLayer, hoveredId] = useLinkLayerManager(
    forcedShowOnMap ?? showOnMap,
    object,
    openGroups,
    isInitializing,
  );

  const elements = React.useMemo(() => {
    if (isInitializing || !Object.keys(linkStorage).length) return [];

    const elements: LinksListElement[] = [];
    for (const lg of computedGroups) {
      if (!lg.count && isHidingEmptyGroups) continue;

      elements.push(lg);

      // Add content of active groups in the same VList
      if (!openGroups.includes(lg.linkGroup.id)) continue;
      if (lg.count) {
        if (linkStorage[lg.linkGroup.id].length) {
          elements.push(
            ...linkStorage[lg.linkGroup.id].map<LinkElement>((link) => ({
              type: 'LinkElement',
              link: link,
              linkGroup: lg.linkGroup,
            })),
          );
        }
        if (linkStorage[lg.linkGroup.id].length < lg.count) {
          // Push a special EmitterLoading that'll load remaining links when needed
          elements.push({
            type: 'EmitterLoading',
            linkGroupId: lg.linkGroup.id,
          });
        }
      } else {
        elements.push({ type: 'NoLinks' });
      }
    }

    return elements;
  }, [
    computedGroups,
    openGroups,
    linkStorage,
    isInitializing,
    isHidingEmptyGroups,
  ]);

  const computeGroups = (): Promise<LinkGroupWithCount[]> => {
    return Promise.all(
      groups.map<Promise<LinkGroupWithCount>>(async (g) => {
        const count = await g.getCountForBaseObject(object.id);
        return {
          type: 'LinkGroupWithCount',
          linkGroup: g,
          count,
        };
      }),
    );
  };

  const fetchLinks = (linkGroupId: number, data: IndexRange): void => {
    const limit = data.stopIndex - data.startIndex;
    object
      .getLinksFromLinkGroup(linkGroupId, limit, data.startIndex)
      .then((res) => {
        dispatchLinkStorage({
          type: 'fetch',
          payload: {
            linkGroupId,
            elements: res.data.results,
            startIndex: data.startIndex,
          },
        });
        if (data.startIndex === 0) {
          refreshVectorLayer(linkGroupId);
        }
      });
  };

  const refetchLinks = (linkGroupId: number): void => {
    dispatchLinkStorage({
      type: 'prune',
      payload: {
        linkGroupId,
      },
    });
    computeGroups().then(setComputedGroups);
    fetchLinks(linkGroupId, { startIndex: 0, stopIndex: 5 });
  };

  const loadMoreLinks = (linkGroupId: number): void => {
    fetchLinks(linkGroupId, {
      startIndex: linkStorage[linkGroupId].length,
      stopIndex: linkStorage[linkGroupId].length + 5,
    });
  };

  React.useEffect(() => {
    const utils = window.Hyvilo.Utils;
    setIsInitializing(true);
    let timeout: NodeJS.Timeout;

    computeGroups().then((res) => {
      dispatchLinkStorage({
        type: 'reset',
        payload: {
          linkGroupIds: res.map((g) => g.linkGroup.id),
        },
      });
      if (res.length === 1 && autoOpen) {
        setOpenGroups([res[0].linkGroup.id]);
      } else {
        setOpenGroups([]);
      }
      setComputedGroups(res);
      const ready = (): void => {
        setIsInitializing(false);
      };
      utils.MainMap?.once('styledata', ready);
      // To show on map, the style needs to be loaded first
      // If we don't need to show on map, we can be ready right ahead
      if ((utils.MainMap?.isStyleLoaded() && showOnMap) || !showOnMap) {
        ready();
      } else {
        // If the style won't load, the links will load anyway but will not be shown in the map
        timeout = setTimeout(() => {
          utils.MainMap?.off('styledata', ready);
          if (!utils.MainMap?.isStyleLoaded()) setForcedShowOnMap(false);
          ready();
        }, 1500);
      }
    });
    return () => clearTimeout(timeout);
  }, []);

  useNonInitialEffect(() => {
    // Reset forcedShowOnMap if the style is loaded
    // We do this on user interaction
    const utils = window.Hyvilo.Utils;
    if (utils.MainMap?.isStyleLoaded()) setForcedShowOnMap(undefined);
  }, [openGroups]);

  useNonInitialEffect(() => {
    if (
      !computedGroups.every(
        (value, index) => value.linkGroup?.id === groups[index]?.id,
      )
    )
      computeGroups().then(setComputedGroups);
  }, [groups]);

  const confirmDelete = (
    element: LinkElement,
    recurrenceType?: TypeRecurrenceUpdateChoice,
  ): void => {
    spawnModal({
      render: ({ onClose: close }) => {
        return (
          <UnlinkConfirmAlert
            onCloseModal={close}
            onCloseButton={(): void => {
              close();
            }}
            onOk={(): void => {
              if (element.link.id) {
                if (
                  recurrenceType &&
                  recurrenceType !== TypeRecurrenceUpdateChoice.THIS &&
                  object.baseobjectGroupId
                ) {
                  BaseObjectGroup.removeLink(
                    object,
                    element.link.linkGroup.id,
                    recurrenceType,
                    element.link.baseobject.id,
                  )
                    .then(() => {
                      refetchLinks(element.linkGroup.id);
                    })
                    .catch(() => {
                      toast(t('errorMessage.linkDeleteFail'));
                    })
                    .finally(() => close());
                } else {
                  element.link.baseobject
                    .removeLink(element.link.id)
                    .then(() => {
                      refetchLinks(element.linkGroup.id);
                    })
                    .catch(() => {
                      toast(t('errorMessage.linkDeleteFail'));
                    })
                    .finally(() => close());
                }
              }
            }}
          />
        );
      },
    });
  };

  const getListElementAction = (
    element: LinkElement,
  ): IListElementAction | undefined => {
    if (element.link.id && element.link.baseobject.isWritable) {
      return {
        icon: { name: 'link-slash' },
        label: t('links.deleteLink'),
        onClick: (e): void => {
          e.preventDefault();
          e.stopPropagation();
          if (object.baseobjectGroupId) {
            spawnModal({
              render: ({ onClose }): JSX.Element => {
                return (
                  <RecurrenceUpdateChoiceObjectModal
                    title={t(
                      'recurrenceChoiceObjectModal.removeLinkObjectTitle',
                    )}
                    open={true}
                    color={'#fc5959'}
                    customButtons={(
                      handleCancel: () => void,
                      handleSave: () => void,
                    ): React.ReactNode => {
                      return [
                        <ValidateButton
                          key={'validate'}
                          onClick={handleSave}
                        />,
                        <CancelButton key={'cancel'} onClick={handleCancel} />,
                      ];
                    }}
                    icon={{ name: 'link-slash' }}
                    onClose={onClose}
                    description={t(
                      'recurrenceChoiceObjectModal.ObjectDescription',
                      {
                        action: t(
                          'recurrenceChoiceObjectModal.ObjectDescriptionRemoveLinkAction',
                        ),
                      },
                    )}
                    onSave={(val): void => {
                      confirmDelete(element, val);
                    }}
                  />
                );
              },
            });
          } else {
            confirmDelete(element);
          }
        },
      };
    }
  };

  const dragGroup: OnDragEndResponder = (result) => {
    // The floor has been dragged outside the Links panel
    if (!result.destination) return;
    // The floor stayed where it was
    if (result.destination.index === result.source.index) return;
    setComputedGroups((cg) =>
      reorderArray(
        cg,
        result.source.index,
        // We can assert result.destination is not null because the object will not mutate
        // when the state setter will be called.
        (result.destination as DraggableLocation).index,
      ),
    );
  };

  const onDragSave = (): void => {
    LinkGroup.orderLinkGroups(
      computedGroups.map((cg) => cg.linkGroup.id),
    ).catch(reload); // Reload the original order if the request fails
  };

  useNonInitialEffect(() => {
    if (!isReordering) onDragSave();
  }, [isReordering]);

  const getOverlayIcons = (element): IListElementAction[] => {
    //@TODO upon list element unification, handle this correctly
    const activeScheduleId =
      Number.parseInt(
        element?.link?.baseobject?.frontEndpoint?.match(
          /schedule=(\d+)/,
        )?.[1] ?? '',
      ) || undefined;
    const elementAction = getListElementAction(element);
    const overlayIcons: IListElementAction[] = [
      {
        icon: {
          name: 'arrow-up-right-from-square' as IconName,
          familyStyle: 'far',
        },
        label: t('baseObjectPanel.mainMenu.access'),
        onClick: (e) => {
          dispatch(
            setModuleStore({
              activeRightPanel: undefined,
              activePreviewObjectId: undefined,
              activeScheduleId: undefined,
              disableSubNavRouting: false,
            }),
          );
          navigate(element?.link?.baseobject?.frontEndpoint ?? '');
        },
      },
    ];

    if (!accessActionOnly) {
      overlayIcons.push({
        icon: { name: 'magnifying-glass-plus' as IconName, familyStyle: 'far' },
        label: t('baseObjectPanel.mainMenu.preview'),
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();
          dispatch(
            setModuleStore({
              activeRightPanel: 'ElementPreview',
              activePreviewObjectId: element.link.baseobject.id,
              activeScheduleId: activeScheduleId,
              disableSubNavRouting: true,
            }),
          );
        },
      });
    }

    if (elementAction) {
      overlayIcons.push({
        icon: { name: 'link-slash' as IconName, familyStyle: 'far' },
        label: t('baseObjectPanel.mainMenu.unlink'),
        onClick: elementAction.onClick ?? ((e) => {}),
      });
    }

    return overlayIcons;
  };

  if (!isInitializing && elements.length === 0) {
    if (invisible) return null;
    if (isHidingEmptyGroups && groups.length) {
      return (
        <NoContentMessage
          label={t('links.showHiddenLinksGroup')}
          icon={{ name: 'link' }}
          onClick={(): void => setIsHidingEmptyGroups?.(false)}
        />
      );
    }
    return (
      <NoContentMessage
        className={'!h-[80px]'}
        label={t(
          populationType === PopulationType.FLOOR
            ? 'links.noFloors'
            : 'links.noSubLinksGroup',
        )}
        icon={{
          name: populationType === PopulationType.FLOOR ? 'stairs' : 'link',
        }}
      />
    );
  }

  if (isInitializing) {
    return (
      <LoaderSkeleton width="100%" height={240}>
        <ListSkeleton iterations={5} component={LinkGroupSkeleton} />
      </LoaderSkeleton>
    );
  }

  return (
    <div
      data-testid={'LinkGroupContent'}
      style={{ height: height ?? 'auto' }}
      className="w-full flex-grow"
    >
      {isReordering ? (
        <DnDList
          listElements={computedGroups.map((group) => ({
            key: group.linkGroup.id.toString(),
            Element: GroupHeader,
            props: {
              active: false,
              baseObject: object,
              group,
              onClick: (): void => {},
              refetchBaseObjects: (): void => {},
              reload,
            },
          }))}
          onDrag={dragGroup}
        />
      ) : (
        <VirtualizedList
          objects={elements}
          startIndex={0}
          disabledHeight={!height}
          renderListItem={(
            element: LinksListElement | undefined,
            index: number,
          ) => {
            if (!element) return undefined;
            switch (element.type) {
              case 'LinkGroupWithCount':
                return (
                  <GroupHeader
                    active={openGroups.includes(element.linkGroup.id)}
                    onClick={(): void => {
                      setOpenGroups((ag) => {
                        return ag.includes(element.linkGroup.id)
                          ? ag.filter((g) => element.linkGroup.id !== g)
                          : [...ag, element.linkGroup.id];
                      });
                    }}
                    group={element}
                    key={element.linkGroup.id}
                    baseObject={object}
                    refetchBaseObjects={(): void =>
                      refetchLinks(element.linkGroup.id)
                    }
                    reload={reload}
                  />
                );
              case 'LinkElement':
                return (
                  <ListBlockElementLayout
                    //@TODO as part of removing VITE_DISPLAY_OVERLAY_ICONS_ON_LINKS env var remove the link and associated logic
                    link={
                      env.VITE_DISPLAY_OVERLAY_ICONS_ON_LINKS
                        ? undefined
                        : element.link.baseobject.frontEndpoint
                    }
                    arrowIcon={element.link.baseobject.category?.icon}
                    arrowColor={element.link.baseobject.color}
                    listBlock={element.link.display}
                    action={getListElementAction(element)}
                    parameters={{
                      link: element.link,
                    }}
                    overlayIcons={
                      noOverlayActions ? [] : getOverlayIcons(element)
                    }
                  />
                );
              case 'EmitterLoading':
                // If an EmitterLoading is rendered, it means the user is near a zone that has not been loaded yet
                return (
                  <EmitterLoading
                    onScreen={(): void => {
                      loadMoreLinks(element.linkGroupId);
                    }}
                  />
                );
              case 'NoLinks':
                return (
                  <div className="h-full w-full flex flex-col items-center justify-center text-gray-500 hover:text-gray-700 border-b border-border">
                    <div className="flex flex-col items-center justify-center cursor-pointer text-gray-500 hover:text-gray-600">
                      <Icon icon={{ name: 'link' }} className="text-2xl" />
                      <p className="uppercase mt-2 text-lg">
                        {t('links.noLink')}
                      </p>
                    </div>
                  </div>
                );
            }
          }}
          rowHeight={(obj: LinksListElement | undefined): number =>
            // Only the group header is smaller than the other ones
            obj?.type === 'LinkGroupWithCount' ? 48 : 80
          }
        />
      )}
    </div>
  );
};
