import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import type { IChecklist } from '@smack/core/api/models/tasks/Checklist';
import { ChecklistItemLog } from '@smack/core/api/models/tasks/ChecklistItemLog';
import { type ILog, Log, LogAction } from '../../logs/Log';

export interface IChecklistLogContent {
  id: number;
  checklist?: IChecklist;
  createdChecklistItemLabel?: string;
  deletedChecklistItemLabel?: string;
  checklistField?: string;
  checklistFieldOldValue?: string;
  checklistFieldNewValue?: string;
}

export interface IChecklistLog extends ILog {
  content?: IChecklistLogContent;
}

export class ChecklistLog extends Log {
  content?: IChecklistLogContent;

  constructor(data: IChecklistLog) {
    super(data);
    this.content = data.content;
  }

  static getChecklistLogs(checklistId: number): Promise<ChecklistLog[]> {
    return RESTClient.get<{ data: { results: IChecklistLog[] } }>(
      '/tasks/checklist-logs',
      { 'checklist-in[]': checklistId },
    ).then((res) => res?.data?.results.map((r) => new ChecklistLog(r)));
  }

  static async getChecklistLogsRecursively(
    checklistId: number,
  ): Promise<(ChecklistLog | ChecklistItemLog)[]> {
    const logs = await ChecklistLog.getChecklistLogs(checklistId);
    const itemLogs = await ChecklistItemLog.getChecklistItemLogs(
      undefined,
      checklistId,
    );
    return [
      ...logs,
      ...itemLogs.filter((log) => log.action !== LogAction.CREATE),
    ];
  }
}
