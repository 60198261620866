import { LinkCustom } from '@smack/core/components/Actions/Link';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import { formatLongString } from '@smack/core/utils';
import { LeftIconContainer } from './Components/LeftIconContainer';

export interface IListElementProps {
  id?: number;
  title?: string;
  isFullTitle?: boolean;
  color?: string;
  icon?: IconField;
  nested?: boolean;
  link?: string;
  active?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
}

/**
 * return ListElementArrayView Component
 * @param props IListElementProps
 * @returns JSX.Element
 */
export const ListElementArrayView = (props: IListElementProps): JSX.Element => {
  const {
    title,
    isFullTitle,
    color = '',
    onClick,
    icon,
    link = '',
    active,
    nested,
    children,
  } = props;

  return (
    <div
      className={'h-[50px]'}
      onClick={(): void => {
        if (onClick) onClick();
      }}
    >
      <LinkCustom active={!!link} to={link}>
        <div
          className={`cursor-pointer flex items-center h-full  px-3 ${
            onClick || link ? 'hover:bg-gray-100 dark:hover:bg-gray-700 ' : ''
          } border-b border-neutral-300 dark:border-neutral-600 ${
            active ? 'bg-gray-100 dark:bg-gray-700' : ''
          } ${nested ? 'pl-5' : ''}`}
        >
          {nested && (
            <div className="mr-2 mb-2 content-[''] rounded-bl min-w-[14px] min-h-[14px] border-l-[2px] border-b-[2px] border-neutral-300 dark:border-neutral-600" />
          )}
          <LeftIconContainer color={color} icon={icon} />
          <div className="flex flex-col w-full pl-3">
            <div className="text-text">
              <div className={'text-sm text-gray-600 dark:text-gray-400 '}>
                {isFullTitle ? title : formatLongString(title, 27)}
              </div>
            </div>
          </div>
          {children}
        </div>
      </LinkCustom>
    </div>
  );
};

ListElementArrayView.defaultProps = {
  icon: undefined,
  active: false,
  color: undefined,
};
