/**
 *
 * Basemap Model
 */

import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import { env } from '@smack/core/env';
import type { ITheme } from '@smack/core/store/app/types';

/**
 * Basemap intreface
 *
 * @export
 * @interface IBasemap
 */
export interface IBasemap {
  id: number;
  label: string;
  picture?: string;
  basemap: string;
  overlays?: string[];
  defaultOfTheme?: ITheme;
  isDefault?: boolean;
}

/**
 * basemap class Model
 *
 * @export
 * @class Basemap
 */
export class Basemap {
  id: number;

  label: string;

  picture?: string;

  basemap: string;

  overlays?: string[];

  defaultOfTheme?: ITheme;

  isDefault?: boolean;

  isUserPreference?: boolean;

  constructor(data: IBasemap) {
    this.id = data.id;
    this.label = data.label;
    this.picture = data.picture;
    this.basemap = data.basemap;
    this.overlays = data.overlays;
    this.isDefault = data.isDefault;
    this.defaultOfTheme = data.defaultOfTheme;
  }

  /**
   * get basemap from api
   *
   * @static
   * @return {*}  {Promise<Basemap[]>}
   * @memberof Basemap
   */
  static getBasemap(): Promise<Basemap[]> {
    return RESTClient.get<{ data: { results: IBasemap[] } }>('/maps/basemaps', {
      customer_key_for_default_basemap: env.VITE_API_PUBLIC_KEY || undefined,
    }).then((res) => res.data.results.map((r) => new Basemap(r)));
  }
}
