import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import type React from 'react';

export interface IBaseAlertProps {
  icon?: IconField;
  color?: string;
  textColor?: string;
  children?: React.ReactNode;
  className?: string;
  title?: string;
}

export const BaseAlert = (props: IBaseAlertProps): JSX.Element => {
  const { color, icon, title, children, textColor, className } = props;

  return (
    <div
      style={{ backgroundColor: color || '' }}
      className={`rounded-md p-4 ${className || ''}`}
      role="status"
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon
            icon={icon || { name: 'warning' }}
            color={textColor}
            className="h-5 w-5 "
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 style={{ color: textColor }} className="text-sm font-medium ">
            {title || ''}
          </h3>
          <div style={{ color: textColor }} className="mt-2 text-sm ">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
