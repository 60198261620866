import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import { type ILayer, Layer } from '@smack/core/api/models/maps/Layer';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';

/**
 * DisplayedAttributeChoice interface
 *
 * @export
 * @interface IDisplayedAttributeChoice
 */
export interface IDisplayedAttributeChoice {
  id: number;
  label: string;
  color: string;
}

/**
 * DisplayedAttributeFilterEntry interface
 *
 * @export
 * @interface IDisplayedAttributeFilterEntry
 */
export interface IDisplayedAttributeFilterEntry {
  id: number;
  attributeId: number;
  label: string;
  icon?: IconField;
  choices: IDisplayedAttributeChoice[];
  position?: number;
}

/**
 * DisplayedSpatialFilterEntry interface
 *
 * @export
 * @interface IDisplayedSpatialFilterEntry
 */
export interface IDisplayedSpatialFilterEntry {
  id: number;
  label: string;
  layer: Layer;
}

/**
 * DisplayedAttributeFilter interface
 *
 * @export
 * @interface IDisplayedAttributeFilter
 */
export interface IDisplayedAttributeFilter {
  id: number;
  label?: string;
  icon?: IconField;
  quickFilters?: IDisplayedAttributeFilterEntry[];
  listFilters?: IDisplayedAttributeFilterEntry[];
  spatialFilters?: IDisplayedSpatialFilterEntry[];
}

/**
 * DisplayedAttributeFilter model
 *
 * @export
 * @class DisplayedAttributeFilter
 */
export class DisplayedAttributeFilter implements IDisplayedAttributeFilter {
  id: number;

  label?: string;

  icon?: IconField;

  quickFilters?: IDisplayedAttributeFilterEntry[];

  listFilters?: IDisplayedAttributeFilterEntry[];

  spatialFilters?: IDisplayedSpatialFilterEntry[];

  constructor(data: IDisplayedAttributeFilter) {
    this.id = data.id;
    this.label = data.label;
    this.icon = data.icon;
    this.quickFilters = data.quickFilters;
    this.listFilters = data.listFilters;
    this.spatialFilters =
      data.spatialFilters?.map(
        (filter) =>
          ({
            ...filter,
            layer: filter.layer ? new Layer(filter.layer as ILayer) : undefined,
          }) as IDisplayedSpatialFilterEntry,
      ) || [];
  }

  static getDisplayedAttributeFilter(
    setCategoryId: number | string,
  ): Promise<DisplayedAttributeFilter[]> {
    return RESTClient.get<{ data: { results: IDisplayedAttributeFilter[] } }>(
      `/categories/categories/${setCategoryId}/children-displayed-attribute-filters`,
    ).then((res) =>
      res.data.results.map((filter) => new DisplayedAttributeFilter(filter)),
    );
  }
}
