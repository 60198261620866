import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import type React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  label: string;
  icon: IconField;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  collapsable?: boolean;
  openState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  children?: React.ReactNode;
}

/**
 * the header of table element
 * @param props IProps
 * @returns JSX.ELEMENT
 */
export const TableHeader: React.FC<IProps> = ({
  label,
  icon,
  children,
  onClose,
  collapsable,
  openState: [isOpen, setIsOpen] = [],
}): JSX.Element => {
  const { t } = useTranslation();

  const toggleIsOpen: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (!collapsable || !setIsOpen) return;
    if (Object.is(e.currentTarget, e.target)) return setIsOpen(!isOpen);
    const target = e.target as Element;
    if (target.closest('[data-toggle="collapse"]')) return setIsOpen(!isOpen);
  };

  return (
    <div
      className={`flex flex-row items-center w-full px-3 py-3 text-left text-sm font-normal text-text ${
        collapsable ? 'cursor-pointer' : ''
      }`}
      onClick={toggleIsOpen}
    >
      <div
        className="inline-flex items-center select-none"
        data-toggle="collapse"
      >
        <Icon icon={icon} className="fa-fw pr-2 text-base" aria-hidden="true" />
        {label}
      </div>
      {children}
      {onClose ? (
        <button
          type="button"
          className="last:ml-auto rounded-md text-gray-400 hover:text-gray-500 "
          onClick={onClose}
        >
          <span className="sr-only">Close</span>
          <Icon
            icon={{ name: 'times' }}
            className="text-lg dark:text-gray-300"
          />
        </button>
      ) : null}
      {collapsable && setIsOpen ? (
        <button
          type="button"
          className="last:ml-auto rounded-md text-gray-400 hover:text-gray-500"
          data-toggle="collapse"
        >
          <span className="sr-only">{t('tasks.toggleTab')}</span>
          <Icon
            icon={{ name: isOpen ? 'chevron-down' : 'chevron-right' }}
            className="text-lg fa-fw dark:text-gray-300"
          />
        </button>
      ) : null}
    </div>
  );
};
