import {
  Category,
  type ICategory,
} from '@smack/core/api/models/categories/Category';
import {
  type ILinkGroup,
  LinkGroup,
} from '@smack/core/api/models/categories/LinkGroup';

export interface ITaskBaseObjectCloner {
  label: string;
  category?: ICategory;
  linkGroup?: ILinkGroup;
}

export class TaskBaseObjectCloner {
  label: string;

  category?: Category;

  linkGroup?: LinkGroup;

  constructor(data: ITaskBaseObjectCloner) {
    this.label = data.label;
    if (data.category) {
      this.category = new Category(data.category);
    }
    if (data.linkGroup) {
      this.linkGroup = new LinkGroup(data.linkGroup);
    }
  }
}
