import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import {
  determineTextColor,
  generateDuotone,
} from '@smack/core/utils/ColorUtils';
import type React from 'react';

interface IProps {
  color: string;
  title: React.ReactNode;
  icon?: IconField;
  actions?: React.ReactNode;
}

export const DataDisplayHeader: React.FC<IProps> = ({
  color,
  title,
  icon,
  actions,
}): JSX.Element => {
  const [iconColor, backgroundColor] = generateDuotone(color);

  return (
    <div
      style={{
        backgroundColor,
      }}
      className="w-full py-5 md:py-0 md:h-20 md:min-h-[80px] px-2 md:px-9 flex flex-col md:flex-row items-center md:justify-between"
    >
      <h3
        className="uppercase flex items-center text-lg font-normal mb-2 md:mb-0"
        style={{ color: determineTextColor(backgroundColor || '#fff') }}
      >
        {icon ? (
          <Icon icon={icon} color={iconColor} containerClassName="mr-2" />
        ) : null}
        {title}
      </h3>
      <div className="flex items-center flex-wrap gap-1">{actions}</div>
    </div>
  );
};
