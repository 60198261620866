import {
  type IPagination,
  RESTClient,
} from '@smack/core/api/clients/rest/RESTClient';
import {
  type ILinkGroup,
  LinkGroup,
} from '@smack/core/api/models/categories/LinkGroup';
import {
  LinkFormFields,
  LinkReadOnlyFields,
} from '@smack/core/api/models/objects/Link/LinkFields/LinkFields';
import {
  type IListElementBaseObject,
  ListElementBaseObject,
} from '@smack/core/api/models/objects/NewBaseObject/ListElementBaseObject';
import type { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import {
  ListBlockDisplay,
  type ListBlockDisplayApiOutput,
} from '@smack/core/api/models/views/ListBlockDisplay/ListBlockDisplay';
import type { ViewElement } from '@smack/core/api/models/views/ViewElement/ViewElement';
import { FormFieldSubmission } from '@smack/core/components/DataInput/FormFieldSubmission/FormFieldSubmission';
import type { BaseObjectSubmitAttribute } from '@smack/core/components/ViewRenderer/interfaces';
import { DetailSubmissionType } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeFieldsByType/type';
import type { AxiosResponse } from 'axios';
import type { ReactNode } from 'react';
import type { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { getI18n } from 'react-i18next';
import type { ViewSection } from '../../views/ViewSection/ViewSection';

export interface ILinkDateRange {
  dtstart: string;
  dtend: string;
}

export interface ILink<T = ListBlockDisplayApiOutput> {
  id: number;
  baseobject: IListElementBaseObject;
  attributes?: BaseObjectSubmitAttribute[];
  linkGroup: ILinkGroup;
  display: T;
  datetimeRange: ILinkDateRange | null;
  weight: number | null;
}

export interface LinkDisplayforLinksGroups {
  accessorCategoryId: number;
  allowsObjectCreation: boolean;
  categoryId: number;
  id: number;
  label: string;
  linkGroupId: number;
  usage: string;
  viewElementId: number;
  viewSections: ViewSection[];
}

export type InputLink = {
  id?: number;
  linkGroupId: number;
  targetBaseobjectId: number;
  targetBaseObjectTitle?: string; //the title is for display purpose only
  attributes?: BaseObjectSubmitAttribute[];
  datetimeRange?: ILinkDateRange;
  weight?: number;
  objectDependantLinkGroupId?: number;
};

export type InputPatchLink = {
  targetBaseobjectId?: number;
  linkGroupId?: number;
  datetimeRange?: ILinkDateRange;
  weight?: number;
};

export class Link {
  id: number;

  baseobject: ListElementBaseObject;

  linkGroup: LinkGroup;

  datetimeRange: ILinkDateRange | null;

  weight: number | null;

  display: ListBlockDisplay;

  constructor(data: ILink) {
    this.id = data.id;
    this.baseobject = new ListElementBaseObject(data.baseobject);
    this.linkGroup = new LinkGroup(data.linkGroup);
    this.datetimeRange = data.datetimeRange;
    this.weight = data.weight;
    this.display = new ListBlockDisplay(data.display);
  }

  static getLinksFromBaseObjectId = async (
    baseObjectId: number,
    dateRange?: ILinkDateRange,
    linkGroups?: number[],
    viewUsage?: ViewUsage,
  ): Promise<Link[]> => {
    const params: Record<string, unknown> = {
      display: viewUsage ?? 'LIST_BLOCK',
    };
    if (dateRange?.dtstart && dateRange?.dtend) {
      params['date-start'] = dateRange.dtstart;
      params['date-end'] = dateRange.dtend;
    }
    if (linkGroups?.length) {
      params['link-group-in'] = linkGroups;
    }
    return RESTClient.get<{
      data: {
        results: ILink[];
      };
    }>(`/objects/baseobjects/${baseObjectId}/links`, params).then((res) => {
      return res.data.results.map((link) => new Link(link));
    });
  };

  /**
   * Get links for a particular type of view
   *
   * @param {number} baseObjectId
   * @param {number} linkGroupId
   * @param {number} viewId
   * @param {number} limit
   * @param {number} offset
   * @return {*}  {Promise<{
   *     data: {
   *       count: number;
   *       next: string;
   *       previous: string;
   *       results: IBaseObject[];
   *     };
   *   }>}
   * @memberof CommonBaseObject
   */
  static getLinksFromLinkGroupWithViews(
    baseObjectId: number,
    linkGroupId: number,
    viewId: number,
    limit: number,
    offset: number,
  ): Promise<{
    data: IPagination<ILink<LinkDisplayforLinksGroups>>;
  }> {
    return RESTClient.get<{
      data: IPagination<ILink<LinkDisplayforLinksGroups>>;
    }>(
      `/objects/baseobjects/${baseObjectId}/link-groups/${linkGroupId}/links?display=VIEW-${viewId}`,
      {
        limit,
        offset,
        'with-badge': true,
      },
    );
  }

  static patchLink(
    linkId: number,
    data: InputPatchLink,
  ): Promise<AxiosResponse> {
    return RESTClient.patch(data, `/objects/links/${linkId}`);
  }

  static removeLink(linkId: number): Promise<void> {
    return RESTClient.delete(`/objects/links/${linkId}`);
  }

  renderLinkField(viewElement: ViewElement): ReactNode {
    const readonlyFieldType = LinkReadOnlyFields.find(
      (linkField) => linkField.type === viewElement.fieldType,
    );

    if (readonlyFieldType) {
      return readonlyFieldType.render({ viewElement: viewElement });
    }

    const formFieldType = LinkFormFields.find(
      (linkField) => linkField.type === viewElement.fieldType,
    );

    if (!formFieldType) {
      return null;
    }
    return (
      <FormFieldSubmission
        type={DetailSubmissionType.OnChange}
        value={this.weight}
        defaultValue={0}
        onSubmit={(data) => {
          return Link.patchLink(this.id, { weight: (data as number) || 0 })
            .then((res) => {
              if (res.status !== 204) throw new Error();
              this.weight = data as number;
            })
            .catch(() => {
              toast(getI18n().t('linkListElement.errors.patchWeight'));
            });
        }}
        fieldProps={{
          viewElement,
        }}
      >
        {(controlProps) =>
          formFieldType.render({
            viewElement: viewElement,
            controllerProps: {
              name: controlProps.name,
              value: controlProps.value,
              onChange: controlProps.onChange,
            } as ControllerRenderProps<FieldValues, string>,
          })
        }
      </FormFieldSubmission>
    );
  }
}
