import {
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { env } from '@smack/core/env';
import { t } from 'i18next';
import React, { type SyntheticEvent } from 'react';

interface ISearchBarClassNames {
  container?: string;
  input?: string;
}

export interface ISearchBarProps {
  onSearch: (value: string) => void;
  onClear: () => void;
  value?: string;
  placeholder?: string;
  className?: ISearchBarClassNames;
}

/**
 * Return Search Bar Component
 * @param props ISearchBarProps
 * @returns JSX.Element
 */
export const SearchBar = (props: ISearchBarProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const nodeId = useFloatingNodeId();
  const [values, setValues] = React.useState('');
  const { onSearch, onClear, value, placeholder, className } = props;
  const { x, y, refs, strategy, context } = useFloating({
    strategy: 'absolute',
    open: open,
    onOpenChange: () => setOpen(false),
    nodeId,
    placement: 'bottom-end',
    middleware: [flip(), shift(), offset({ mainAxis: 10, alignmentAxis: 0 })],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useRole(context, { role: 'menu' }),
    useDismiss(context),
  ]);

  React.useEffect(() => {
    if (value !== undefined && value !== values) {
      setValues(value);
    }
  }, [value]);

  const onSubmit = (): void => {
    if (
      (values?.length &&
        values?.length >= env.VITE_MINIMUM_SEARCH_CHARACTERS) ||
      !values
    ) {
      onSearch(values || '');
    } else {
      setOpen(true);
    }
  };

  const onClearhandle = (): void => {
    onClear();
    setValues('');
  };

  const onChangeHandle = (val: string): void => {
    setValues(val || '');
    if (!val) onClearhandle();
  };

  return (
    <div
      className={`relative w-full h-11 min-h-[44px] flex items-center  border border-white overflow-hidden dark:border-neutral-500 rounded ${
        className?.container ?? ''
      }`}
    >
      <input
        data-testid="search-bar-text-input"
        onChange={(e: SyntheticEvent<HTMLInputElement>): void =>
          onChangeHandle(e.currentTarget.value)
        }
        value={values}
        className={`bg-primary focus:!outline-none grow text-text h-full border-none !ring-0 ${
          className?.input ?? ''
        }`}
        onKeyDown={(e): void => {
          if (e.key === 'Enter') {
            onSubmit();
          }
        }}
        placeholder={placeholder}
        type="text"
        size={1}
      />
      <FloatingTree>
        {!value && (
          <FloatingNode id={nodeId}>
            <div
              aria-label="search-bar-submit-button"
              key={nodeId}
              ref={refs.setReference}
              {...getReferenceProps({
                onClick() {
                  onSubmit();
                },
              })}
            >
              <Icon
                className="text-text absolute right-3 cursor-pointer"
                icon={{ name: 'search' }}
              />
            </div>
            <FloatingPortal>
              {open && (
                <div
                  ref={refs.setFloating}
                  className="bg-primary  select-none p-3 rounded-md shadow-[#63636333_0px_2px_8px_0px]"
                  style={{
                    position: strategy,
                    zIndex: 100,
                    top: y ?? 0,
                    left: x ?? 0,
                    width: 'max-content',
                  }}
                  {...getFloatingProps()}
                >
                  <p data-testid="minimuCharactersContainer">
                    {t('searchBar.minimumCharacters', {
                      count: env.VITE_MINIMUM_SEARCH_CHARACTERS,
                    })}
                  </p>
                </div>
              )}
            </FloatingPortal>
          </FloatingNode>
        )}
      </FloatingTree>
      {value && (
        <div aria-label="search-bar-clear-button">
          <Icon
            className="text-text dark:text-gray-200 absolute top-[1/2] right-3 cursor-pointer"
            onClick={onClearhandle}
            icon={{ name: 'times' }}
          />
        </div>
      )}
    </div>
  );
};

SearchBar.defaultProps = {
  placeholder: t('search'),
};
