import { captureException } from '@sentry/react';
import { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import { DevAlert } from '@smack/core/components/DataDisplay/Alerts/Alerts/Components/DevAlert/DevAlert';
import type { BaseObjectDisplayViewLayoutProps } from '@smack/core/components/ViewRenderer/interfaces';
import { useEffect } from 'react';

export const BaseObjectFallbackLayout: React.FC<
  BaseObjectDisplayViewLayoutProps
> = ({ renderedSections, view }) => {
  useEffect(() => {
    captureException(
      new Error(
        '<BaseObjectFallbackLayout/> was rendered. This should not happen.',
      ),
    );
  }, []);

  return (
    <div>
      <DevAlert title="Fallback Layout">
        No layout was defined for {ViewUsage[view.usage]}. This degraded view
        will show instead.
      </DevAlert>
      {renderedSections.map(([viewSection, viewElements]) => (
        <div key={viewSection.id}>
          {viewSection.label}
          {viewElements.map(([viewElement, node]) => (
            <div key={viewElement.id}>
              {viewElement.label}
              {node}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
