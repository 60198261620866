import { Badge } from '@smack/core/components/DataDisplay/Badges/Badge/Badge';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import type { IListElementAction } from '@smack/core/components/DataDisplay/Lists/ListElements/BaseListElement';
import { ListElementLayout } from '@smack/core/components/DataDisplay/Lists/ListElements/Components/ListElementLayout/ListElementLayout';
import { formatLongString } from '@smack/core/utils';
import type React from 'react';
import type { ReactNode } from 'react';
import { isValidElement } from 'react';
import { IconLabel } from '../../../../Labels/IconLabel';

export const ListElementContent: React.FC<{
  title: string;
  subtitle?: string | ReactNode;
  color?: string;
  icon?: IconField;
  badge?: {
    color?: string;
    value?: string;
    icon?: IconField;
  };
  bottomLeft?: {
    value?: string;
    icon?: IconField;
  };
  bottomRight?: {
    value?: string;
    icon?: IconField;
    rightIcon?: IconField;
    tooltip?: string;
  };
  overlayIcons?: IListElementAction[];
}> = ({
  title,
  subtitle,
  color,
  icon,
  badge,
  bottomLeft,
  bottomRight,
  overlayIcons,
}) => {
  const titleEl = formatLongString(title);

  const subtitleEl = isValidElement(subtitle)
    ? subtitle
    : formatLongString(subtitle as string, 32);

  const badgeEl = badge?.value ? (
    <Badge icon={badge?.icon} color={badge?.color ?? ''}>
      {badge.value}
    </Badge>
  ) : null;

  const bottomLeftEl = bottomLeft ? (
    <IconLabel
      icon={bottomLeft.icon}
      label={bottomLeft.value}
      maxLengthLabel={20}
    />
  ) : null;

  const bottomRightEl = bottomRight ? (
    <IconLabel
      icon={bottomRight.rightIcon ? undefined : bottomRight.icon}
      label={bottomRight.value}
      maxLengthLabel={20}
      rightIcon={bottomRight.rightIcon}
      tooltip={bottomRight.tooltip}
    />
  ) : null;

  return (
    <ListElementLayout
      arrowColor={color}
      arrowIcon={icon}
      title={titleEl}
      subtitle={subtitleEl}
      badge={badgeEl}
      bottomLeft={bottomLeftEl}
      bottomRight={bottomRightEl}
      overlayIcons={overlayIcons}
    />
  );
};
