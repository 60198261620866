import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import type { IAttributeValue } from '@smack/core/api/models/categories/Attribute/Attribute';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import {
  type FileApiInput,
  type FileApiOutput,
  File as MediaFile,
} from './File';
import {
  type FolderApiInput,
  type FolderApiOutput,
  Folder as MediaFolder,
} from './Folder';
import type { MediaCategory } from './MediaCategory';
import type { Mimetype } from './Mimetype';

export interface IMediaAttributeValueApiOutputData
  extends IAttributeValue<FileApiOutput> {}

export interface IUploadedFileInputData {
  file?: string;
  filename?: string;
  temporaryFileId?: number;
}

export interface IMediaAttributeValueInputData extends IUploadedFileInputData {
  media?: number;
  mediaFile?: MediaFile;
}

export class MediasManager {
  instanceAPIPath: string;

  uploadEndpoint = '/medias/files';

  constructor(instanceAPIPath: string) {
    this.instanceAPIPath = instanceAPIPath;
  }

  /**
   * Full tree of files/folders
   * @returns
   */
  getMediasTreeRepresentation(
    search?: string,
    favoritesOnly = false,
    categories?: MediaCategory[],
    mimetypes?: Mimetype[],
  ): Promise<{ data: { results: FolderApiOutput } }> {
    const queryParams: Record<string, unknown> = {};
    if (search) queryParams.search = search;
    if (favoritesOnly) queryParams['favorites-only'] = 1;
    if (categories) queryParams.categories = categories.map((c) => c.id);
    if (mimetypes) queryParams.mimetypes = mimetypes.map((c) => c.id);
    return RESTClient.get(
      `${this.instanceAPIPath}/medias/tree-representation`,
      queryParams,
      undefined,
      undefined,
    );
  }

  /**
   * List files
   * @returns
   */
  listFiles(
    search?: string,
    favorite?: boolean,
    type?: 'DOCUMENT' | 'IMAGE',
    categories?: MediaCategory[],
    mimetypes?: Mimetype[],
  ): Promise<{ data: { results: FileApiOutput[] } }> {
    return RESTClient.get(
      `${this.instanceAPIPath}/medias/files`,
      {
        search,
        favorite,
        type,
        categories: categories ? categories.map((c) => c.id) : undefined,
        mimetypes: mimetypes ? mimetypes.map((c) => c.id) : undefined,
      },
      undefined,
      undefined,
    );
  }

  /**
   * List folders
   * @returns
   */
  listFolders(
    search?: string,
  ): Promise<{ data: { results: FolderApiOutput[] } }> {
    return RESTClient.get(
      `${this.instanceAPIPath}/medias/folders`,
      {
        search,
      },
      undefined,
      undefined,
    );
  }

  /**
   * Upload a file to the managed object
   * @param files files to upload
   * @param folderId destination folder
   * @param mediaCategoryId media category
   * @returns
   */
  uploadFile(
    file: File,
    sourcePath?: string,
    folderId?: number,
    mediaCategoryId?: number,
  ): Promise<AxiosResponse<{ results: { id: number } }>> {
    return RESTClient.post(
      axios.toFormData({
        file,
        sourcePath,
        folderId,
        mediaCategoryId,
      }),
      `${this.instanceAPIPath}${this.uploadEndpoint}`,
      undefined,
    );
  }

  /**
   * Create method for linked folders. If parent_folder_id is undefined then
   * the root folder will be used (and created if doesn't yet exist)
   * @param label new folder label
   * @param parentFolderId parent folder
   * @returns
   */
  createFolder(
    label: string,
    parentFolderId?: number,
  ): Promise<AxiosResponse<{ results: { id: number }[] }>> {
    return RESTClient.post(
      { label: label, parent_folder_id: parentFolderId },
      `${this.instanceAPIPath}/medias/folders`,
    );
  }

  /**
   * Update method for linked folders
   * @param folderId edited folder
   * @param label new label
   * @param parentFolderId new parent
   * @returns
   */
  static partialUpdateFolder(
    folderId: number,
    data: FolderApiInput,
  ): Promise<AxiosResponse<{ results: { id: number }[] }>> {
    return RESTClient.patch(data, `/medias/folders/${folderId}`);
  }

  static getFolder(folderIdOrUUID: number | string): Promise<MediaFolder> {
    return RESTClient.get<{ data: { results: FolderApiOutput } }>(
      `/medias/folders/${folderIdOrUUID}`,
    ).then((res) => new MediaFolder(res.data?.results));
  }

  /**
   * Delete method for linked folders
   * @param folderId
   * @returns
   */
  static deleteFolder(
    folderId: number,
  ): Promise<{ results: { id: number }[] }> {
    return RESTClient.delete(`/medias/folders/${folderId}`);
  }

  /**
   * Reindex method
   * @returns
   */
  static reindex(folderId: number): Promise<AxiosResponse<void>> {
    return RESTClient.post(null, `/medias/folders/${folderId}/reindex`);
  }

  static getFile(fileIdOrUUID: number | string): Promise<MediaFile> {
    return RESTClient.get<{ data: { results: FileApiOutput } }>(
      `/medias/files/${fileIdOrUUID}`,
    ).then((res) => new MediaFile(res.data?.results));
  }

  /**
   * Delete method for linked files
   * @param fileId
   * @returns
   */
  static deleteFile(fileId: number): Promise<{ results: { id: number }[] }> {
    return RESTClient.delete(`/medias/files/${fileId}`);
  }

  static deleteBatch(uuids: string[]): Promise<void> {
    return RESTClient.delete('/medias', { 'uuid[]': uuids });
  }

  /**
   * Update method for linked files
   * @param fileId file to edit
   * @param label new label
   * @returns
   */
  static partialUpdateFile(
    fileId: number,
    data: FileApiInput,
  ): Promise<AxiosResponse<{ results: { id: number }[] }>> {
    return RESTClient.patch(data, `/medias/files/${fileId}`);
  }
}
