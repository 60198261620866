import { Badge } from '@smack/core/components/DataDisplay/Badges/Badge/Badge';

interface IProps {
  isTrue: boolean | undefined;
}

export const BooleanBadge = (props: IProps): JSX.Element => {
  const { isTrue } = props;

  return (
    <Badge className="uppercase" color={isTrue ? '#22c55e' : '#ef4444'}>
      {isTrue ? 'Oui' : 'Non'}
    </Badge>
  );
};
