import { env } from '@smack/core/env';
import type { Reducer } from 'redux';
import {
  FETCH_BASEMAPS,
  FETCH_LAYERS,
  type MapsActionType,
  type MapsState,
  SET_ACTIVE_BASEMAP,
  SET_ACTIVE_LAYERS,
  SET_LAYERS_ERROR,
  SET_POINTMODE,
} from './types';

// initial state
const initialState: MapsState = {
  basemaps: [],
  layers: [],
  activeBasemap: undefined,
  pointMode: env.VITE_ENABLE_SERVER_CLUSTERING ? 'cluster' : 'legacyCluster',
  activeLayers: [],
  loading: false,
  layersError: {},
};

// Object Reducer

export const mapsReducer: Reducer<MapsState, MapsActionType> = (
  state: MapsState | undefined,
  action: MapsActionType,
): MapsState => {
  if (!state) return initialState;
  switch (action.type) {
    case FETCH_BASEMAPS:
      return {
        ...state,
        basemaps: action.payload,
      };
    case SET_ACTIVE_BASEMAP:
      return {
        ...state,
        activeBasemap: action.payload,
      };
    case FETCH_LAYERS:
      return {
        ...state,
        layers: action.payload,
      };
    case SET_ACTIVE_LAYERS:
      return {
        ...state,
        activeLayers: action.payload,
      };
    case SET_POINTMODE:
      return {
        ...state,
        pointMode: action.payload,
      };
    case SET_LAYERS_ERROR:
      return {
        ...state,
        layersError: {
          ...state.layersError,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};
