import type { Attribute } from '@smack/core/api/models/categories/Attribute/Attribute';
import { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { FieldProps } from '@smack/core/api/models/views/ViewElement/ViewElement';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import {
  DetailSubmissionType,
  type ValueToAttributeValue,
} from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeFieldsByType/type';
import { ViewRulesContext } from '@smack/core/components/ViewRenderer/rules/ViewRulesContext/ViewRulesContext';
import { ViewRulesController } from '@smack/core/components/ViewRenderer/rules/ViewRulesController/ViewRulesController';
import { fetchFocusedBaseObject } from '@smack/core/store/app/actions';
import React, { useContext } from 'react';
import {
  type ControllerRenderProps,
  type FieldValues,
  useForm,
} from 'react-hook-form';
import { useDispatch } from 'react-redux';

interface IProps {
  type: DetailSubmissionType;
  children: (
    props: ControllerRenderProps<FieldValues, 'attribute-value'>,
  ) => React.ReactElement;
  value: unknown;
  valueToAttributeValue: ValueToAttributeValue;
  attribute: Attribute;
  baseobjectId: number;
  scheduleId?: number;
  fieldProps: FieldProps;
  onSubmission?: () => void;
}

type FormOutput = { 'attribute-value'?: unknown };

export const AttributeSubmissionByType: React.FC<IProps> = ({
  children,
  value,
  attribute,
  valueToAttributeValue,
  baseobjectId,
  scheduleId,
  type,
  onSubmission,
  fieldProps,
}) => {
  const [loading, setLoading] = React.useState(false);
  const { control, formState, reset, handleSubmit } = useForm<FormOutput>();
  const dispatch = useDispatch();
  const { event } = useContext(ViewRulesContext);
  React.useEffect(() => {
    reset({ 'attribute-value': value });
  }, [value]);

  const reloadBaseobject = (): void => {
    dispatch(fetchFocusedBaseObject(baseobjectId, scheduleId));
  };

  const onSubmit = (val: FormOutput): void => {
    setLoading(true);
    const attributes = [
      {
        id: attribute.id,
        values: valueToAttributeValue(val['attribute-value']),
      },
    ];
    BaseObject.patchBaseObject(baseobjectId, { attributes }).then(() => {
      setLoading(false);
      onSubmission?.();
      reset({ 'attribute-value': val['attribute-value'] });
      reloadBaseobject();
    });
  };

  const showButton = (): boolean => {
    return !!(
      type === DetailSubmissionType.Button &&
      Object.keys(formState.dirtyFields).length
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex items-center flex-grow"
    >
      <ViewRulesController
        control={control}
        name={'attribute-value'}
        defaultValue={fieldProps.viewElement?.getFieldTypeDefaultValue()}
        viewElement={fieldProps.viewElement}
        render={({ field }): React.ReactElement => children(field)}
      />
      {showButton() ? (
        <button
          type={'submit'}
          className={
            'text-blue-500 cursor-pointer h-full flex items-center justify-center ml-3'
          }
        >
          <Icon
            icon={{ name: loading ? 'spinner-third' : 'check' }}
            animation={loading ? 'spin' : undefined}
          />
        </button>
      ) : null}
    </form>
  );
};
