import { cx } from 'class-variance-authority';
import { Trans, useTranslation } from 'react-i18next';

export function SearchLabel({
  i18nKey,
  resultsCount,
  isLoading,
  isFailing,
}: {
  i18nKey: string;
  resultsCount: number;
  isLoading: boolean;
  isFailing: boolean;
}) {
  const [t] = useTranslation();

  let resultsLabel = resultsCount.toString();

  if (isLoading) resultsLabel = '...';
  else if (isFailing) resultsLabel = '!';
  else if (resultsCount === 1000) resultsLabel += '+';

  return (
    <Trans
      t={t}
      i18nKey={i18nKey}
      values={{
        results: resultsLabel,
      }}
      components={{
        muted: (
          <span
            className={cx(
              'text-xs px-1 rounded',
              !isLoading && isFailing
                ? 'bg-red-600/10 text-red-600'
                : 'bg-faded-current',
            )}
          />
        ),
      }}
    />
  );
}
