import { IconButton } from '@smack/core/components/Actions/Buttons/Button';
import {
  ContextualMenu,
  type IContextualProps,
} from '@smack/core/views/oldViewsToSort/Layouts/Objects/MenuFilter/Components/Contextual';
import { QuickFilterTrigger } from '@smack/core/views/oldViewsToSort/Layouts/Objects/MenuFilter/Components/FilterBar/Components';
import type React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  filterOptions: IContextualProps;
  className?: string;
}

export const QuickFilters: React.FC<IProps> = ({
  filterOptions,
  className,
}) => {
  const [t] = useTranslation();
  return (
    <div
      data-testid="quick-filters"
      className={`h-12 w-full flex border border-border ${className ?? ''}`}
    >
      <IconButton
        className="self-start h-full pl-1 pr-0 rounded-none border-y-0 border-l-0"
        iconClassName="mr-[4px]"
        icon={{ name: 'chevron-left' }}
        onClick={(event): void => {
          event.currentTarget.nextElementSibling?.scrollBy({
            left: -300,
            behavior: 'smooth',
          });
        }}
        title={t('filters.scroll')}
      />
      <div className="flex grow items-center overflow-x-scroll no-scrollbar">
        {filterOptions.menuItems.items
          .filter((item) => item.isQuick && item.subMenu?.items?.length)
          .map((item) => (
            <ContextualMenu
              menuItems={{
                items: item.subMenu?.items ?? [],
                multiselection: item?.subMenu?.multiselection,
              }}
              key={item.id}
              side="bottom-start"
              filters={filterOptions.filters}
              setFilters={filterOptions.setFilters}
            >
              {({
                active: isOpen,
                htmlRef,
                ...htmlProps
              }): React.ReactElement => (
                <QuickFilterTrigger
                  {...htmlProps}
                  ref={htmlRef}
                  item={item}
                  isOpen={isOpen}
                />
              )}
            </ContextualMenu>
          ))}
      </div>
      <IconButton
        className="self-end h-full pl-1 pr-0 rounded-none border-y-0 border-r-0"
        icon={{ name: 'chevron-right' }}
        iconClassName="mr-[4px]"
        onClick={(event): void => {
          event.currentTarget.previousElementSibling?.scrollBy({
            left: 300,
            behavior: 'smooth',
          });
        }}
        title={t('filters.scroll')}
      />
    </div>
  );
};
