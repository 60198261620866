import { type VariantProps, cva } from 'class-variance-authority';
import type { FC, ReactNode } from 'react';

interface AffixChipProps {
  children: ReactNode;
  type: VariantProps<typeof affixChipVariants>['type'];
  padding?: VariantProps<typeof affixChipVariants>['padding'];
}

export const affixChipVariants = cva(
  [
    'inline-flex',
    'items-center',
    'border',
    'border-gray-300',
    'bg-gray-50',
    'dark:bg-gray-700',
    'text-gray-500',
    'sm:text-sm',
    'whitespace-nowrap',
  ],
  {
    variants: {
      type: {
        prefix: ['rounded-l-md', 'border-r-0'],
        suffix: ['rounded-r-md', 'border-l-0'],
      },
      padding: {
        medium: 'px-3',
        small: 'px-2',
      },
    },
  },
);

export const AffixChip: FC<AffixChipProps> = ({
  children,
  type,
  padding = 'medium',
}) => <span className={affixChipVariants({ type, padding })}>{children}</span>;
