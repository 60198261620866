import {
  type Batch,
  FILE_STATES,
  type PreSendData,
  UPLOADER_EVENTS,
} from '@rpldy/uploady';
import type { MediasManager } from '@smack/core/api/models/medias';
import type { MediaCategory } from '@smack/core/api/models/medias/MediaCategory';
import { ValidateButton } from '@smack/core/components/Actions/Buttons/Button';
import { MediaSource } from '@smack/core/components/Actions/MediaSource';
import { UploadProgressBar } from '@smack/core/components/Actions/UploadProvider/UploadProgressBar/UploadProgressBar';
import {
  type IUploadProviderProps,
  UploadDestinationType,
  UploadProvider,
} from '@smack/core/components/Actions/UploadProvider/UploadProvider';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { CheckboxInput } from '@smack/core/components/DataInput/CheckboxInput';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import { t } from 'i18next';
import React from 'react';
import toast from 'react-hot-toast';
import { Trans } from 'react-i18next';

import type { INode } from '@smack/core/components/DataDisplay/Medias/IMediaNode';

interface IProps {
  parentObject: MediasManager;
  parentFolder?: INode;
  mediaCategories?: MediaCategory[];
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  onUploadSubmit: () => void;
  onFolderCreate: (name: string | undefined) => void;
  uploadProviderProps?: IUploadProviderProps;
}

export const MediaUploadModal: React.FC<IProps> = ({
  parentObject,
  parentFolder,
  mediaCategories,
  open,
  setOpen,
  onUploadSubmit,
  onFolderCreate,
  uploadProviderProps,
}) => {
  const [newFolderNameForm, setNewFolderNameForm] = React.useState<string>();
  const [mediaCategoryId, setMediaCategoryId] = React.useState<
    number | undefined
  >();
  const [isImageResizeEnabled, setIsImageResizeEnabled] = React.useState(false);

  React.useEffect(() => {
    setNewFolderNameForm(undefined);
    setMediaCategoryId(undefined);
    setIsImageResizeEnabled(false);
  }, [open]);
  const mediaCategoryOptions = React.useMemo(() => {
    return mediaCategories?.map((category) => category.toOption());
  }, [mediaCategories]);

  const requestPreSendHandler = ({ items, options }: PreSendData) => {
    const overrides = {
      params: {
        mediaCategoryId: mediaCategoryId,
        folderId: parentFolder?.id,
      },
    };
    return { options: overrides };
  };

  const batchFinishHandler = (batch: Batch) => {
    if (batch.items.some((item) => item.state === FILE_STATES.ERROR)) {
      toast.error(t('medias.uploadFilesError'));
    } else {
      toast.success(t('medias.uploadFilesSuccess'));
      onUploadSubmit();
    }
  };

  return (
    <Modal
      icon={{ name: 'paperclip' }}
      title={t('medias.addFilesAndFolders')}
      color={'#EFF6FF'}
      iconColor={'#3886F1'}
      open={open}
      onClose={setOpen}
    >
      <div>
        <div className="flex flex-col gap-3 mt-6">
          <div className="flex flex-col gap-1 font-medium text-gray-700 dark:text-gray-400">
            <p>
              {t('medias.createFolder')}
              {parentFolder?.label && parentFolder.label !== 'root' && (
                <Trans
                  t={t}
                  i18nKey="medias.createIn"
                  values={{
                    label: parentFolder?.label,
                  }}
                />
              )}
            </p>

            <form
              onSubmit={(event): void => {
                event.preventDefault();
                onFolderCreate(newFolderNameForm);
              }}
              className="flex items-center gap-2"
            >
              <input
                type={'text'}
                value={newFolderNameForm}
                onChange={(event): void =>
                  setNewFolderNameForm(event.target.value)
                }
                placeholder={t('formInputLabels.name')}
                className="rounded border border-gray-300 p-1 w-full bg-view text-text"
              />
              <ValidateButton type="submit" />
            </form>
          </div>
          <div className="flex flex-col gap-2 font-medium text-gray-700 dark:text-gray-400">
            <p>
              {t('medias.importFiles')}
              {parentFolder?.label && parentFolder.label !== 'root' && (
                <Trans
                  t={t}
                  i18nKey="medias.createIn"
                  values={{
                    label: parentFolder?.label,
                  }}
                />
              )}
            </p>
            <SelectInput
              value={mediaCategoryOptions?.find(
                (option) => option.value === mediaCategoryId,
              )}
              options={mediaCategoryOptions}
              onChange={(option) => setMediaCategoryId(option?.value as number)}
              placeholder={t('medias.categoryToApply')}
              noOptionsBeforeSearchMessage={t('medias.categoryNoOptionMessage')}
            />
            <UploadProvider
              multiple
              uploadDestinationType={UploadDestinationType.MEDIA_MANAGER}
              mediaManager={parentObject}
              resizeImageOptions={{
                resizeImage: isImageResizeEnabled,
              }}
              listeners={{
                [UPLOADER_EVENTS.REQUEST_PRE_SEND]: requestPreSendHandler,
                [UPLOADER_EVENTS.BATCH_FINISH]: batchFinishHandler,
              }}
              {...uploadProviderProps}
            >
              <MediaSource
                showSwitchButton
                dropzoneProps={{
                  multiple: true,
                  className: 'h-[30vh]',
                }}
                snapshotCameraProps={{
                  filename: 'file',
                }}
              />
              <CheckboxInput
                label={t('medias.upload.resizeImage')}
                value={isImageResizeEnabled}
                onChange={setIsImageResizeEnabled}
              />
              <UploadProgressBar unmountOnFinished />
            </UploadProvider>
          </div>
        </div>
      </div>
    </Modal>
  );
};
