import React from 'react';

import {
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  type Placement,
  type ReferenceType,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import type { IDisplayedSpatialFilterEntry } from '@smack/core/api/models/categories/DisplayedAttributeFilter';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import type { IFilters } from '@smack/core/utils/Filters';
import type { MapEventUtils } from '@smack/core/utils/MapEventUtils';
import { Item } from '@smack/core/views/oldViewsToSort/Layouts/Objects/MenuFilter/Components/Contextual/Components';

export enum MenuSection {
  FILTER = 'Filtrer par',
  VISUALIZE = 'Visualiser par',
  GEOMETRY = 'Emprise géo. sur la carte',
  RESTORE = 'Restaurer',
}

export type IMenuItemType = 'filter' | 'visualize' | 'sort' | 'action';

export interface IMenuItem {
  id: string;
  iconFile?: string;
  type: IMenuItemType;
  icon?: IconField;
  label: string;
  color?: string;
  section?: MenuSection;
  isQuick?: boolean;
  isDefault?: boolean;
  onClick?: () => void;
  onReset?: () => void;
  subMenu?: IMenuItems;
  filteringLayer?: IDisplayedSpatialFilterEntry;
  mapEvents?: MapEventUtils;
  position?: number;
}

interface IContextualTriggerProps extends React.HTMLProps<HTMLElement> {
  active: boolean;
  htmlRef: (node: ReferenceType | null) => void;
}

export interface IMenuItems {
  items: IMenuItem[];
  multiselection?: boolean;
}

export interface IContextualProps {
  children?: React.FC<IContextualTriggerProps>;
  side?: Placement;
  menuItems: IMenuItems;
  filters: IFilters;
  setFilters: (filters: IFilters) => void;
  isOpen?: boolean;
}

const ContextualMenuRender: React.FC<IContextualProps> = ({
  menuItems,
  side = 'right-start',
  children: Trigger,
  filters,
  setFilters,
  isOpen,
}) => {
  // Set display name for debugging in React devtools
  if (Trigger) Trigger.displayName = 'Trigger';

  const [open, setOpen] = React.useState(isOpen ?? false);
  const nodeId = useFloatingNodeId();
  const parentId = useFloatingParentNodeId();
  const nested = parentId !== null;

  const { x, y, refs, strategy, context } = useFloating({
    strategy: 'absolute',
    open: open,
    onOpenChange: setOpen,
    nodeId,
    placement: side,
    middleware: [
      flip(),
      shift(),
      offset({ mainAxis: 10, alignmentAxis: nested ? -8 : 0 }),
    ],
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useClick(context, {
        event: 'mousedown',
        toggle: !nested,
      }),
      useRole(context, { role: 'menu' }),
      useDismiss(context),
    ],
  );

  const renderItem = (
    item: IMenuItem,
    itemSide: Placement = 'right-start',
  ): React.ReactElement | null => {
    if (item.subMenu)
      return item.subMenu?.items.length > 0 ? (
        <ContextualMenuRender
          menuItems={item.subMenu}
          key={`${item.label}${item.isQuick ? '-quick' : ''}`}
          side={itemSide}
          filters={filters}
          setFilters={setFilters}
        >
          {({
            active: isActive,
            htmlRef,
            ...htmlItemProps
          }): React.ReactElement => (
            <Item
              key={item.id}
              ref={htmlRef}
              item={item}
              hovered={isActive}
              filters={filters}
              setFilters={setFilters}
              {...getItemProps(htmlItemProps)}
            />
          )}
        </ContextualMenuRender>
      ) : null;
    return (
      <Item
        filters={filters}
        setFilters={setFilters}
        key={item.id}
        item={item}
        {...getItemProps()}
      />
    );
  };

  const hasChildren = (items: IMenuItems, section: MenuSection): boolean => {
    return !!items.items.find(
      (item) => item.section === section && item.subMenu?.items.length,
    );
  };

  return (
    <FloatingNode id={nodeId}>
      {Trigger && (
        <Trigger
          key={nodeId}
          active={open}
          htmlRef={refs.setReference}
          {...getReferenceProps({
            onClick(e) {
              e.stopPropagation();
            },
            ...(nested && {
              role: 'menuitem',
            }),
          })}
        />
      )}
      <FloatingPortal>
        {open && (
          <div
            ref={refs.setFloating}
            className="bg-primary select-none p-2 rounded-md shadow-[#63636333_0px_2px_8px_0px] z-[1000]"
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              width: 'max-content',
            }}
            {...getFloatingProps()}
          >
            {menuItems.items
              .filter((item) => item.section === undefined)
              .map((item) => renderItem(item, side ?? 'right-start'))}
            {Object.values(MenuSection)
              .filter(
                (section) =>
                  menuItems.items.filter((item) => item.section === section)
                    .length > 0,
              )
              .map(
                (section): JSX.Element => (
                  <div key={section}>
                    {section && hasChildren(menuItems, section) && (
                      <p className="box-border border-b-[1px] border-border mx-2 mt-2 text-text text-sm">
                        {section}
                      </p>
                    )}
                    {menuItems.items
                      .filter((item) => item.section === section)
                      .map((item) => renderItem(item, side ?? 'right-start'))}
                  </div>
                ),
              )}
          </div>
        )}
      </FloatingPortal>
    </FloatingNode>
  );
};

ContextualMenuRender.displayName = 'ContextualMenu';

export const ContextualMenuMemo: React.FC<IContextualProps> = (props) => {
  const parentId = useFloatingParentNodeId();

  if (parentId === null) {
    return (
      <FloatingTree>
        <ContextualMenuRender {...props} />
      </FloatingTree>
    );
  }
  return <ContextualMenuRender {...props} />;
};

ContextualMenuMemo.displayName = 'ContextualRoot';

/**
 * A full-featured floating contextual menu. Nesting is supported.
 * The child should be a function returning a component that provides a reference to floating-ui
 * and pass the HTML props (ARIA tags and event listeners) provided
 *
 * **Warning!** For performance and display reasons, this component is memoized using `@wry/equality`,
 * this means that some changes may not be reflected in the component, such as function contexts.
 *
 * @param menuItems Menu items and specifying if multiselect is allowed.
 * @param side floating-ui placement (If it can't be applied, it will flip on the other side)
 * @param children A Function returning a Trigger Component (button) with the correct provided props
 * @copyright {@link https://codesandbox.io/s/admiring-lamport-5wt3yg?file=/src/DropdownMenu.tsx floating-ui example}
 */
export const ContextualMenu = React.memo(ContextualMenuMemo);
