import type { ITemplateSkeletonComponent } from '.';

export const LogSkeleton: ITemplateSkeletonComponent = ({
  offset = 0,
  index = 0,
  iterations = 1,
}) => (
  <>
    {index !== 0 ? <rect x={30} y={offset} width={1} height={35} /> : null}
    {index !== iterations - 1 ? (
      <rect x={30} y={offset + 35} width={1} height={35} />
    ) : null}
    <circle cx={30} cy={offset + 35} r={16} />
    <rect
      x={60}
      y={offset + 30}
      rx={2.5}
      width="calc(100% - 80px)"
      height={10}
    />
    <rect
      x={60}
      y={offset + 45}
      rx={2.5}
      width="calc(100% - 80px)"
      height={10}
    />
  </>
);

LogSkeleton.itemOffset = 70;
