import type { ITemplateSkeletonComponent } from '.';

export const AttributePreviewSkeleton: ITemplateSkeletonComponent = ({
  offset = 0,
}) => (
  <>
    <circle cx={30} cy={offset + 15} r={8} />
    <rect x={45} y={offset + 5} width={60} height={5} rx={2.5} />
    <rect x={45} y={offset + 15} width={160} height={10} rx={5} />
  </>
);

AttributePreviewSkeleton.itemOffset = 40;
