import type { AxiosResponse } from 'axios';
import { RESTClient } from '../../../clients/rest/RESTClient';

export class SubscribeManager {
  instanceAPIPath: string;

  isSubscribed?: boolean;

  constructor(instanceAPIPath: string, isSubscribed?: boolean) {
    this.instanceAPIPath = instanceAPIPath;
    this.isSubscribed = isSubscribed;
  }

  subscribe(): Promise<AxiosResponse<void>> {
    return RESTClient.post(undefined, `${this.instanceAPIPath}/subscribe`);
  }

  unsubscribe(): Promise<AxiosResponse<void>> {
    return RESTClient.post(undefined, `${this.instanceAPIPath}/unsubscribe`);
  }

  async toggleIsSubscribed(): Promise<void> {
    await (this.isSubscribed ? this.unsubscribe() : this.subscribe());
    this.isSubscribed = !this.isSubscribed;
  }
}
