import {
  DataSectionNavigation,
  type IDataSectionNavigationProps,
} from '@smack/core/components/Actions/Navigation/DataSectionNavigation/DataSectionNavigation';
import { DangerAlert } from '@smack/core/components/DataDisplay/Alerts/Alerts';
import { DataFieldWrapper } from '@smack/core/components/DataDisplay/DataFieldWrapper';
import { DataSectionTitle } from '@smack/core/components/DataDisplay/DataSectionTitle';
import type {
  RenderedBaseObjectViewSections,
  ViewLayoutComponent,
} from '@smack/core/components/ViewRenderer/interfaces';
import { useScrollNavigationSection } from '@smack/core/hooks/useScrollNavigationSection/useScrollNavigationSection';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const BaseObjectDetailsWithNavigationLayout: ViewLayoutComponent = ({
  renderedSections,
}) => {
  const [t] = useTranslation();

  const sectionsContainerRef = React.useRef(null);
  const [hiddenSections, setHiddenSections] = React.useState<string[]>([]);
  const [activeSection, handleScroll] = useScrollNavigationSection(
    'section',
    sectionsContainerRef.current ?? undefined,
  );
  const navigationSection: IDataSectionNavigationProps['navigationItems'] =
    React.useMemo(() => {
      return renderedSections.map(([viewSection]) => ({
        id: viewSection.id.toString(),
        icon: viewSection.icon,
        label: viewSection.label,
      }));
    }, [renderedSections]);

  const handleClickHideSection = (id: string | number) => {
    if (hiddenSections.includes(id.toString())) {
      setHiddenSections([...hiddenSections.filter((s) => s !== id.toString())]);
    } else {
      setHiddenSections([...hiddenSections, id.toString()]);
    }
  };

  const filteredSections = React.useMemo((): RenderedBaseObjectViewSections => {
    return renderedSections.filter(
      ([section]) => !hiddenSections.includes(section.id.toString()),
    );
  }, [renderedSections, hiddenSections]);

  return (
    <div className="flex flex-grow gap-3 max-h-full">
      <div className={'max-w-[350px] min-w-[200px] print:hidden '}>
        <DataSectionNavigation
          navigationItems={navigationSection}
          currentId={activeSection}
          hiddenItems={hiddenSections}
          onClickHide={handleClickHideSection}
          optionalText={t('viewLayouts.requiredFields')}
        />
      </div>
      <div
        ref={sectionsContainerRef}
        className="overflow-y-auto flex flex-col gap-3 scroll-smooth flex-grow print:gap-1"
        onScroll={handleScroll}
      >
        {filteredSections.map(([viewSection, viewElements]) => (
          <section
            key={viewSection.id}
            id={viewSection.id.toString()}
            className="px-3 bg-primary rounded print:px-0"
          >
            <DataSectionTitle
              title={viewSection.label}
              icon={viewSection.icon}
              className={viewSection.isHeadless ? 'sr-only' : undefined}
            />
            <div className={'print:grid print:grid-cols-2'}>
              {viewElements.map(([viewElement, node]) => (
                <DataFieldWrapper
                  label={viewElement.attribute?.label ?? ''}
                  key={viewElement.id}
                >
                  {node}
                </DataFieldWrapper>
              ))}
            </div>
          </section>
        ))}
      </div>
    </div>
  );
};

BaseObjectDetailsWithNavigationLayout.Error = ({ viewUsage }) => {
  const [t] = useTranslation();

  return (
    <div className="h-full w-full">
      <DangerAlert title={t('viewErrors.viewNotAvailable')}>
        {t('viewErrors.viewNotAvailableDescription')}
      </DangerAlert>
    </div>
  );
};
