import { LinkGroup } from '@smack/core/api/models/categories/LinkGroup';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import {
  LinkGroupSkeleton,
  ListSkeleton,
  LoaderSkeleton,
} from '@smack/core/utils/Loader';
import { LinksGroupContent } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Links/Components/LinksGroupContent/LinksGroupContent';
import { type FC, useEffect, useState } from 'react';

interface AutoLinkGroupsProps {
  baseObject: BaseObject;
  task?: boolean;
}

export const AutoLinkGroups: FC<AutoLinkGroupsProps> = ({
  baseObject,
  task = false,
}) => {
  const [fetchedLinkGroups, setFetchedLinkGroups] = useState<LinkGroup[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    if (baseObject) {
      (task
        ? LinkGroup.getLinkGroupsFromBaseObjectIdForTask(baseObject.id)
        : LinkGroup.getLinkGroupsFromBaseObjectIdForPanel(baseObject.id)
      )
        .then((fetchedGroups) => {
          setFetchedLinkGroups(fetchedGroups);
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    }
  }, [baseObject]);

  if (isLoading)
    return (
      <LoaderSkeleton width="100%" height={240}>
        <ListSkeleton iterations={5} component={LinkGroupSkeleton} />
      </LoaderSkeleton>
    );

  return (
    <LinksGroupContent
      object={baseObject}
      groups={fetchedLinkGroups}
      autoOpen={task}
      isHidingEmptyGroups
      invisible
      noOverlayActions={true}
    />
  );
};
