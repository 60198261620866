import type {
  IMessageRecipient,
  MessageRecipientIndication,
} from '@smack/core/api/models/notifications/MessageRecipient';

export enum MessageTemplateType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  WEBAPP_PUSH = 'WEBAPP_PUSH',
  APP_NOTIFICATION = 'APP_NOTIFICATION',
}

export interface IMessageTemplate {
  id: number;
  type: MessageTemplateType;
  subject?: string;
  body?: string;
  recipients?: IMessageRecipient[];
  created_at?: string;
  modified_at?: string;
}

export class MessageTemplate {
  id: number;

  type: MessageTemplateType;

  subject?: string;

  body?: string;

  recipients: IMessageRecipient[];

  created_at?: Date;

  modified_at?: Date;

  constructor(data: IMessageTemplate) {
    this.id = data.id;
    this.type = data.type;
    this.subject = data.subject;
    this.body = data.body;
    this.recipients = data.recipients || [];
    if (data.created_at) this.created_at = new Date(data.created_at);
    if (data.modified_at) this.modified_at = new Date(data.modified_at);
  }

  getAllRecipientsEmailByIndication(
    type: MessageRecipientIndication,
  ): string[] {
    if (this?.type !== MessageTemplateType.EMAIL) return [];
    return (
      this?.recipients
        ?.map((r) => {
          if (r.indication === type) return r.user?.email || r.email;
        })
        .filter((s): s is string => typeof s === 'string') || []
    );
  }

  getAllRecipientsPhoneNumbers(): string[] {
    if (this?.type !== MessageTemplateType.SMS) return [];
    return (
      this?.recipients
        ?.map((r) => {
          return r.phone;
        })
        .filter((s): s is string => typeof s === 'string') || []
    );
  }
}
