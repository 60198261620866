import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import { createReduxEnhancer } from '@sentry/react';
import { objectsReducer } from '@smack/core/store/objects/reducer';
import type { ObjectsState } from '@smack/core/store/objects/types';
import {
  type CombinedState,
  type Store,
  type StoreEnhancer,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from 'redux';
import thunk from 'redux-thunk';
import { appReducer } from './app/reducer';
import type { BaseAppState } from './app/types';
import { categoriesReducer } from './categories/reducer';
import type { ICategoriesState } from './categories/types';
import { mapsReducer } from './maps/reducer';
import type { MapsState } from './maps/types';
import { modalReducer } from './modal/reducer';
import type { ModalState } from './modal/types';
import { projectsReducer } from './projects/reducer';
import type { ProjectsState } from './projects/types';
import { userReducer } from './users/reducer';
import type { UserState } from './users/types';

export interface AppState {
  User: UserState;
  Maps: MapsState;
  App: BaseAppState;
  Categories: ICategoriesState;
  Modal: ModalState;
  Objects: ObjectsState;
  Projects: ProjectsState;
}

/* Create root reducer, containing all features of the application */
export const reducers = {
  User: userReducer,
  Maps: mapsReducer,
  App: appReducer,
  Categories: categoriesReducer,
  Modal: modalReducer,
  Objects: objectsReducer,
  Projects: projectsReducer,
};
export const rootReducer = combineReducers(reducers);

const sentryReduxEnhancer = createReduxEnhancer({
  actionTransformer: (action) => {
    // here we can disable logging specific store actions
    return action;
  },
  stateTransformer: (state: CombinedState<AppState>) => {
    // here we can replace store values to omit them
    // from the sentry report
    return state;
  },
}) as StoreEnhancer;

export type RootState = ReturnType<typeof rootReducer>;
export type RootAction = Parameters<typeof rootReducer>[1];
export type AppStore = Store<RootState, RootAction>;

const composeEnhancers = composeWithDevToolsDevelopmentOnly({
  serialize: false, // if set to false, will not serialize  complex datasets for redux-devtools.
  // Ignoring typing the the state on purpose, so we can later
  // add extra store item and their non matching types we want to ignore in the devtools.
  stateSanitizer: (state) => {
    return {
      ...state,
      Categories: {
        // @ts-ignore
        ...state.Categories,
        notificationCounters: '<<Not Displayed>>',
      },
    };
  },
});

export const setupStore = (): AppStore => {
  return createStore(
    rootReducer,
    compose(sentryReduxEnhancer, composeEnhancers(applyMiddleware(thunk))),
  );
};

const store = setupStore();
export type AppDispatch = typeof store.dispatch;
export default store;
