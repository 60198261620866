// object State

import type { Basemap } from '@smack/core/api/models/maps/Basemap';
import type { Layer } from '@smack/core/api/models/maps/Layer';

export type LegacyMapModes = 'legacyCluster' | 'legacyPoint' | 'legacyHeatmap';
export type MapModes = 'cluster' | 'point' | 'heatmap';

export type MapMode = LegacyMapModes | MapModes;

export interface MapsState {
  basemaps: Basemap[];
  activeBasemap?: number;
  layers: Layer[];
  activeLayers: Layer[];
  layersError: { [key: number]: Error | null };
  pointMode: MapMode;
  loading: boolean;
}

// Actions Constants
export const FETCH_BASEMAPS = 'MAPS::FETCH_BASEMAPS';
export const FETCH_LAYERS = 'MAPS::FETCH_LAYERS';
export const SET_ACTIVE_BASEMAP = 'MAPS::SET_ACTIVE_BASEMAP';
export const SET_ACTIVE_LAYERS = 'MAPS::SET_ACTIVE_LAYERS';
export const SET_LAYERS_ERROR = 'MAPS::SET_LAYERS_ERROR';
export const SET_POINTMODE = 'MAPS::APP_SET_POINTMODE';
// Actions Définition

export interface ISetBasemap {
  type: typeof FETCH_BASEMAPS;
  payload: Basemap[];
}

export interface ISetLayer {
  type: typeof FETCH_LAYERS;
  payload: Layer[];
}

export interface ISetActiveBaseMap {
  type: typeof SET_ACTIVE_BASEMAP;
  payload: number;
}

export interface ISetPointMode {
  type: typeof SET_POINTMODE;
  payload: MapMode;
}

export interface ISetActiveLayers {
  type: typeof SET_ACTIVE_LAYERS;
  payload: Layer[];
}

export interface ISetLayersErrors {
  type: typeof SET_LAYERS_ERROR;
  payload: MapsState['layersError'];
}

export type MapsActionType =
  | ISetPointMode
  | ISetBasemap
  | ISetActiveBaseMap
  | ISetLayer
  | ISetActiveLayers
  | ISetLayersErrors;
