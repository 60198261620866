import { IconRoundedButton } from '@smack/core/components/Actions/Buttons/IconRoundedButton/IconRoundedButton';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { LoaderSkeleton } from '@smack/core/utils/Loader';
import { ArticleSkeleton } from '@smack/core/utils/Loader/Skeletons/ArticleSkeleton';
import DOMPurify from 'dompurify';
import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface IRichTextModalProps {
  title: string;
  previewValue: string;
  getFullValue: () => Promise<string>;
}

export const RichTextModal: FC<IRichTextModalProps> = ({
  title,
  previewValue,
  getFullValue,
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [sanitizedHTML, setSanitizedHTML] = useState('');

  useEffect(() => {
    if (open && !sanitizedHTML) {
      setIsLoading(true);
      getFullValue()?.then((res) => {
        const cleanValue = DOMPurify.sanitize(res);
        setSanitizedHTML(cleanValue);
        setIsLoading(false);
      });
    }
  }, [open]);

  const getPreview = (): string => {
    return DOMPurify.sanitize(previewValue, { USE_PROFILES: { html: false } });
  };

  return (
    <>
      <div
        data-testid={'RichTextModal'}
        className="flex w-full h-full items-center gap-2 max-w-md"
      >
        <p className={'truncate text-sm text-text'}>{getPreview()}</p>
        <IconRoundedButton
          title={t('richText.read')}
          icon={{ name: 'book-open', familyStyle: 'far' }}
          onClick={(e): void => {
            e.stopPropagation();
            setOpen(true);
          }}
        />
      </div>

      <Modal
        icon={{ name: 'book-open', familyStyle: 'far' }}
        title={title}
        open={open}
        onClose={(): void => {
          setOpen(false);
        }}
      >
        {isLoading ? (
          <LoaderSkeleton
            className={
              'min-w-[100vw] min-h-[80vh] md:min-w-[40vw] md:min-h-[40vh]'
            }
          >
            <ArticleSkeleton lineNumber={20} />
          </LoaderSkeleton>
        ) : (
          <article
            className="prose"
            // biome-ignore lint/security/noDangerouslySetInnerHtml: Content is sanitized
            dangerouslySetInnerHTML={{ __html: sanitizedHTML || '' }}
          />
        )}
      </Modal>
    </>
  );
};
