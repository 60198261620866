import { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import type { ViewElement } from '@smack/core/api/models/views/ViewElement/ViewElement';
import {
  DataSectionNavigation,
  type IDataSectionNavigationProps,
} from '@smack/core/components/Actions/Navigation/DataSectionNavigation/DataSectionNavigation';
import { InfoAlert } from '@smack/core/components/DataDisplay/Alerts/Alerts';
import { DataFieldWrapper } from '@smack/core/components/DataDisplay/DataFieldWrapper';
import { DataSectionTitle } from '@smack/core/components/DataDisplay/DataSectionTitle';
import { NoContentMessage } from '@smack/core/components/DataDisplay/NoContentMessage';
import { CheckboxInput } from '@smack/core/components/DataInput/CheckboxInput';
import { MutedInput } from '@smack/core/components/DataInput/MutedInput/MutedInput';
import type { ViewLayoutComponent } from '@smack/core/components/ViewRenderer/interfaces';
import { BaseObjectFormContext } from '@smack/core/components/ViewRenderer/renderers/BaseObjectFormViewRenderer/context/BaseObjectFormContext';
import { useScrollNavigationSection } from '@smack/core/hooks/useScrollNavigationSection/useScrollNavigationSection';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FormRow = ({ viewElement }) => {};

export const BaseObjectFormWithNavigationLayout: ViewLayoutComponent = ({
  renderedSections,
}) => {
  const [t] = useTranslation();

  const {
    controlledFormElements,
    setControlledFormElements,
    isFormControllerToggleable,
  } = React.useContext(BaseObjectFormContext);

  const sectionsContainerRef = React.useRef(null);

  const [activeSection, handleScroll] = useScrollNavigationSection(
    'section',
    sectionsContainerRef.current ?? undefined,
  );
  const navigationSection: IDataSectionNavigationProps['navigationItems'] =
    React.useMemo(() => {
      return renderedSections.map(([viewSection]) => ({
        id: viewSection.id.toString(),
        icon: viewSection.icon,
        label: viewSection.label,
      }));
    }, [renderedSections]);

  const onChangeControllerToggle = (
    value: boolean,
    viewElement: ViewElement,
  ) => {
    if (!controlledFormElements || !setControlledFormElements) return;
    if (value) {
      setControlledFormElements?.([
        ...(controlledFormElements ?? []),
        viewElement,
      ]);
    } else {
      setControlledFormElements?.(
        controlledFormElements.filter((v) => v.id !== viewElement.id),
      );
    }
  };

  return (
    <div className="flex flex-grow gap-3 max-h-full">
      <div className={'max-w-[350px] min-w-[200px] hidden md:block'}>
        <DataSectionNavigation
          navigationItems={navigationSection}
          currentId={activeSection}
          optionalText={t('viewLayouts.requiredFields')}
        />
      </div>
      <div
        ref={sectionsContainerRef}
        className="overflow-y-auto flex flex-col gap-3 scroll-smooth flex-grow "
        onScroll={handleScroll}
      >
        {isFormControllerToggleable ? (
          <InfoAlert title={t('batchUpdateForm.helpTitle')}>
            {t('batchUpdateForm.helpDescription')}
          </InfoAlert>
        ) : null}
        {renderedSections.map(([viewSection, viewElements]) => (
          <section
            key={viewSection.id}
            id={viewSection.id.toString()}
            className="px-3 bg-primary rounded"
          >
            <DataSectionTitle
              title={viewSection.label}
              icon={viewSection.icon}
              className={viewSection.isHeadless ? 'sr-only' : undefined}
            />
            <div>
              {viewElements.map(([viewElement, node]) => {
                const isFieldEnabled =
                  controlledFormElements
                    ?.map((v) => v.id)
                    ?.includes(viewElement.id) ?? false;
                const isFieldDisabled =
                  isFormControllerToggleable && !isFieldEnabled;
                return (
                  <DataFieldWrapper
                    label={
                      <>
                        {isFormControllerToggleable ? (
                          <CheckboxInput
                            value={isFieldEnabled}
                            onChange={(val) =>
                              onChangeControllerToggle(val, viewElement)
                            }
                          />
                        ) : null}
                        {viewElement.label ?? ''}
                        {viewElement.required ? (
                          <span className="text-red-700">*</span>
                        ) : null}
                      </>
                    }
                    key={viewElement.id}
                    muted={isFieldDisabled}
                    helpTooltip={viewElement.helpText}
                    autoFocus={isFieldEnabled}
                  >
                    {isFieldDisabled ? (
                      <MutedInput
                        onClick={() =>
                          onChangeControllerToggle(true, viewElement)
                        }
                      >
                        {t('formInputLabels.unchangedValue')}
                      </MutedInput>
                    ) : (
                      node
                    )}
                  </DataFieldWrapper>
                );
              })}
            </div>
          </section>
        ))}
      </div>
    </div>
  );
};

BaseObjectFormWithNavigationLayout.Error = ({ viewUsage }) => {
  const [t] = useTranslation();

  return (
    <div className={'p-5 pb-6'}>
      <NoContentMessage
        icon={{ name: 'list-dropdown' }}
        label={t(
          viewUsage === ViewUsage.QUICK_FORM
            ? 'formErrors.noQuickFormAvailable'
            : 'formErrors.noFormAvailable',
        )}
      />
    </div>
  );
};
