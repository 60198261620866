import {
  type QueryParams,
  cleanSearchParams,
} from '@smack/core/hooks/useNavigation/useNavigation';
import { bulkRemoveSearchParams } from '@smack/core/utils/URLSearchParams';
import { addSearchParams } from '@smack/core/utils/URLs';
import { NavLink, useSearchParams } from 'react-router-dom';

interface LinkCustomProps {
  active?: boolean;
  to: string;
  children: React.ReactNode;
  excludeParams?: QueryParams[];
  className?: string;
}

export const LinkCustom = ({
  active = true,
  to,
  children,
  excludeParams = [],
  className,
}: LinkCustomProps): JSX.Element => {
  const [originalSearchParams] = useSearchParams();

  const searchParams = bulkRemoveSearchParams(
    cleanSearchParams(originalSearchParams),
    excludeParams,
  );

  return active ? (
    <NavLink to={addSearchParams(to, searchParams)} className={className}>
      {children}
    </NavLink>
  ) : (
    <>{children}</>
  );
};
