import { checkBackendHealth } from '@smack/core/api/availability';
import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import { Banner } from '@smack/core/components/DataDisplay/Banner';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import type { AppState } from '@smack/core/store';
import { setOnline } from '@smack/core/store/app/actions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

export const NoInternet: React.FC = () => {
  const isOnline = useSelector((state: AppState) => state.App.appOnline);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [timer, setTimer] = React.useState(5);
  const [isCheckingAvailability, setIsCheckingAvailability] =
    React.useState(false);
  const tries = React.useRef(1);

  const checkOnLine = React.useCallback(async (): Promise<void> => {
    setIsCheckingAvailability(true);

    const time = Date.now();
    const isBackendHealthy = await checkBackendHealth();
    dispatch(setOnline(isBackendHealthy));
    if (isBackendHealthy) {
      RESTClient.releaseRequests();
    }
    const elapsedTime = Date.now() - time;

    // Show is checking availability message for at least 2 seconds
    setTimeout(
      () => {
        setIsCheckingAvailability(false);
        setTimer(5 * tries.current);
        tries.current = isBackendHealthy ? 0 : Math.min(tries.current + 1, 6);
      },
      Math.max(2000 - elapsedTime, 0),
    );
  }, []);

  React.useEffect(() => {
    const offlineListener = (): void => {
      dispatch(setOnline(false));
    };
    window.addEventListener('offline', offlineListener);
    window.addEventListener('online', checkOnLine);
    checkOnLine().catch(() => {});
    return () => {
      window.removeEventListener('offline', offlineListener);
      window.removeEventListener('online', checkOnLine);
    };
  }, []);

  React.useEffect(() => {
    if (!isOnline && !isCheckingAvailability) {
      const interval = setInterval(() => {
        setTimer((currentTimerValue) => {
          const newValue = Math.max(0, currentTimerValue - 1);
          if (newValue === 0) {
            void checkOnLine();
          }
          return newValue;
        });
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [isOnline, isCheckingAvailability]);

  if (isOnline) return null;

  return (
    <Banner icon={{ name: 'wifi-slash' }}>
      {isCheckingAvailability ? (
        t('noInternet.attemptingReconnect')
      ) : (
        <>
          {t('noInternet.connectionLost', { count: timer })}
          <button
            type="button"
            onClick={checkOnLine}
            className="font-bold flex items-center"
          >
            <Icon icon={{ name: 'arrow-rotate-right' }} />
            &nbsp;
            <span className="underline">
              {t('noInternet.attemptReconnect')}
            </span>
          </button>
        </>
      )}
    </Banner>
  );
};
