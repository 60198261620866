import { merge } from '@rpldy/shared';
import Uploady, { UPLOADER_EVENTS } from '@rpldy/uploady';
import type {
  Batch,
  BatchItem,
  CreateOptions,
  Destination,
  PreSendData,
  PreSendResponse,
  UploadyProps,
} from '@rpldy/uploady';
import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import type { MediasManager } from '@smack/core/api/models/medias';
import { TemporaryFile } from '@smack/core/api/models/medias/TemporaryFile/TemporaryFile';
import { t } from 'i18next';
import mime from 'mime';
import React from 'react';
import toast from 'react-hot-toast';
import { resizeImage as resizeImageBlob } from './utils';

export const UPLOAD_MAX_NUMBER_FILES = 100;

export enum UploadDestinationType {
  MEDIA_MANAGER = 'MEDIA_MANAGER',
  TEMPORARY_FILE = 'TEMPORARY_FILE',
}

export interface IResizeImageOptions {
  resizeImage?: boolean;
  resizeImageThresholdSize?: {
    width: number;
    height: number;
  };
  resizeImageTargetSize?: () => Promise<
    | {
        width: number;
        height: number;
      }
    | undefined
  >;
}

export interface IUploadProviderProps extends UploadyProps {
  uploadDestinationType?: UploadDestinationType;
  mediaManager?: MediasManager;
  resizeImageOptions?: IResizeImageOptions;
}

export const UploadProvider: React.FC<IUploadProviderProps> = ({
  uploadDestinationType = UploadDestinationType.TEMPORARY_FILE,
  mediaManager,
  resizeImageOptions,
  listeners,
  multiple = false,
  autoUpload = true,
  sendWithFormData = true,
  formDataAllowUndefined = true,
  children,
  ...extraProps
}): JSX.Element => {
  const toUnixPath = (clientOSPath: string) => {
    return clientOSPath.replace(/[\\/]+/g, '/');
  };

  const dirname = (unixPath: string) => {
    return unixPath.split('/').slice(0, -1).join('/');
  };

  const getDestination = (): Destination | undefined => {
    if (
      uploadDestinationType === UploadDestinationType.MEDIA_MANAGER &&
      mediaManager
    ) {
      return {
        method: 'POST',
        url: `${RESTClient.getApiUrlEntryPoint()}${
          mediaManager.instanceAPIPath
        }${mediaManager.uploadEndpoint}`,
        headers: {
          Authorization: RESTClient.getAuthorizationBearerString(),
        },
      };
    }
    if (uploadDestinationType === UploadDestinationType.TEMPORARY_FILE) {
      return {
        method: 'POST',
        url: `${RESTClient.getApiUrlEntryPoint()}${
          TemporaryFile.uploadEndpoint
        }`,
        headers: {
          Authorization: RESTClient.getAuthorizationBearerString(),
        },
      };
    }
  };

  const fileFilter = (
    file: File | string,
    index: number,
    files: File[] | string[],
  ): boolean => {
    // implement single file option
    // for uploads that don't use <input>, eg. DnD
    return multiple || index === 0;
  };

  const batchAddHandler = (batch: Batch, _options: CreateOptions) => {
    if (batch.items.length > UPLOAD_MAX_NUMBER_FILES) {
      toast.error(
        t('medias.tooManyFilesSent', { maxNumber: UPLOAD_MAX_NUMBER_FILES }),
      );
      return false; // cancel
    }
  };

  const enableResizeImage = React.useMemo(() => {
    return resizeImageOptions?.resizeImage ?? true;
  }, [resizeImageOptions]);

  const resizeImageThresholdSize = React.useMemo(() => {
    return (
      resizeImageOptions?.resizeImageThresholdSize ?? {
        width: 2560,
        height: 1920,
      }
    );
  }, [resizeImageOptions]);

  const getResizeImageTargetSize = React.useCallback(async () => {
    let targetSize: { width: number; height: number } | undefined;
    if (resizeImageOptions?.resizeImageTargetSize) {
      targetSize = await resizeImageOptions?.resizeImageTargetSize();
    }
    return (
      targetSize ?? {
        width: 2560,
        height: 1920,
      }
    );
  }, [resizeImageOptions]);

  const preProcessItem = async (item: BatchItem) => {
    // guess mimetype if the browser didn't give us the info,
    // for instance when dropping a folder on Firefox
    if (!item.file.type) {
      const guessedType = mime.getType(item.file.name);
      if (guessedType) {
        item.file = new File([item.file as File], item.file.name, {
          type: guessedType,
        });
      }
    }
    // try to resize the file if we are sure it's an image
    if (
      enableResizeImage &&
      item.file.type.match(/image.*/) &&
      item.file.type !== 'image/gif'
    ) {
      try {
        // read the file to a temporary bitmap to obtain metadata
        const originalBitmap = await createImageBitmap(item.file as File, {
          colorSpaceConversion: 'none',
          premultiplyAlpha: 'none',
        });
        if (
          originalBitmap.width > resizeImageThresholdSize.width ||
          originalBitmap.height > resizeImageThresholdSize.height
        ) {
          const resizeImageTargetSize = await getResizeImageTargetSize();
          if (
            resizeImageTargetSize.width === 0 ||
            resizeImageTargetSize.height === 0 ||
            (resizeImageTargetSize.width >= originalBitmap.width &&
              resizeImageTargetSize.height >= originalBitmap.height)
          ) {
            // user decided to keep original quality,
            // or use a greater resolution (don't upscale)
            return;
          }
          // faster to resize the original blob as it avoids decoding the bitmap
          const resizedBlob = await resizeImageBlob(
            item.file as File,
            { width: originalBitmap.width, height: originalBitmap.height },
            resizeImageTargetSize,
          );
          if (resizedBlob !== null)
            item.file = new File([resizedBlob], item.file.name, {
              type: resizedBlob.type,
            });
        }
      } catch {
        // not an image or unsupported format, keep original file
      }
    }
  };

  const requestPreSendHandler = ({
    items,
    options,
  }: PreSendData): Promise<PreSendResponse> => {
    const overrides: CreateOptions = {};

    if (items && !options.grouped) {
      // item always has a single file when `grouped` is false
      const file = items[0].file as File;
      overrides.params = {
        sourcePath: dirname(toUnixPath(file.webkitRelativePath || file.name)),
      };
    }
    return Promise.all(items.map(preProcessItem)).then(() => {
      overrides.destination = getDestination();
      return {
        items,
        options: overrides,
      };
    });
  };

  return (
    <Uploady
      fileFilter={fileFilter}
      listeners={{
        ...listeners,
        [UPLOADER_EVENTS.BATCH_ADD]: (batch, options) => {
          if (listeners && UPLOADER_EVENTS.BATCH_ADD in listeners) {
            return merge(
              {},
              listeners[UPLOADER_EVENTS.BATCH_ADD](batch, options),
              batchAddHandler(batch, options),
            );
          }
          return batchAddHandler(batch, options);
        },
        [UPLOADER_EVENTS.REQUEST_PRE_SEND]: async (data) => {
          if (listeners && UPLOADER_EVENTS.REQUEST_PRE_SEND in listeners) {
            return merge(
              {},
              listeners[UPLOADER_EVENTS.REQUEST_PRE_SEND](data),
              await requestPreSendHandler(data),
            );
          }
          return requestPreSendHandler(data);
        },
      }}
      multiple={multiple}
      autoUpload={autoUpload}
      sendWithFormData={sendWithFormData}
      formDataAllowUndefined={formDataAllowUndefined}
      {...extraProps}
      grouped={false}
      concurrent={false}
    >
      {children}
    </Uploady>
  );
};
