import type { Category } from '@smack/core/api/models/categories/Category';
import {
  type LinkGroup,
  LinkableObjectsDisplay,
  PopulationType,
} from '@smack/core/api/models/categories/LinkGroup';
import type { ILinkDateRange } from '@smack/core/api/models/objects/Link/Link';
import { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import {
  CancelButton,
  ContinueButton,
  IconButton,
  SaveButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import {
  DateTimeRangeInput,
  type DateTimeRangeInputValue,
} from '@smack/core/components/DataInput/DateRangeInput/DateTimeRangeInput';
import { NumberInput } from '@smack/core/components/DataInput/NumberInput/NumberInput';
import { AutoBaseObjectView } from '@smack/core/components/ViewRenderer/AutoBaseObjectView';
import type {
  BaseObjectFormOnSubmit,
  BaseObjectFormViewRenderForwardedRef,
  BaseObjectSubmitAttribute,
} from '@smack/core/components/ViewRenderer/interfaces';
import { BaseObjectFormLayout } from '@smack/core/components/ViewRenderer/layouts/BaseObjectFormLayout';
import { spawnModal } from '@smack/core/utils/modal';
import { FloorForm } from '@smack/core/views/oldViewsToSort/Layouts/Forms/AddLinks/Floor/Floor';
import { LinkCalendar } from '@smack/core/views/oldViewsToSort/Layouts/Forms/AddLinks/LinkCalendar';
import { LinkGroupBaseObjectSelector } from '@smack/core/views/oldViewsToSort/Layouts/Forms/AddLinks/LinkGroupBaseObjectSelector/LinkGroupBaseObjectSelector';
import {
  RecurrenceUpdateChoiceObjectModal,
  type TypeRecurrenceUpdateChoice,
} from '@smack/core/views/oldViewsToSort/Layouts/Modal/RecurrenceUpdateChoiceObjectModal';
import { type FC, useId, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncMemo } from 'use-async-memo';

export interface IAddLinkFormOutput {
  baseObjects: BaseObject[];
  objectDependantLinkGroup?: number;
  baseObjectGroupChoice?: TypeRecurrenceUpdateChoice;
  linkGroup: LinkGroup;
  weight?: number;
  attributes?: BaseObjectSubmitAttribute[];
  datetimeRange?: {
    dtstart: string;
    dtend: string;
  };
}

export interface AddLinkFormProps {
  sourceBaseObjectId?: number;
  scheduleId?: number;
  initialDateRange?: DateTimeRangeInputValue;
  category: Category | number;
  linkGroup: LinkGroup;
  initialBaseObjectsValue?: BaseObject[];
  onDateRangeChange?: (range: DateTimeRangeInputValue) => void;
  onClickCreateBaseObject?: () => void;
  onSubmit: (data: IAddLinkFormOutput) => void;
  filterParameters?: Record<string, unknown>;
  onQuit?: () => void;
}

export const AddLinkForm: FC<AddLinkFormProps> = ({
  sourceBaseObjectId,
  linkGroup,
  category,
  scheduleId,
  onSubmit,
  onQuit,
  initialBaseObjectsValue = [],
  onClickCreateBaseObject,
  onDateRangeChange,
  initialDateRange,
  filterParameters,
}) => {
  const [t] = useTranslation();

  const [baseObjectsValue, setBaseObjectsValue] = useState<BaseObject[]>(
    initialBaseObjectsValue,
  );
  const [floorValue, setFloorValue] = useState<number>();

  const attributesFormRef = useRef<BaseObjectFormViewRenderForwardedRef>(null);
  const [dateRangeValue, setDateRangeValue] = useState<
    DateTimeRangeInputValue | undefined
  >(initialDateRange);
  const [floorForm, setFloorForm] = useState<boolean>(false);
  const [weight, setWeight] = useState<number>(0);
  const id = useId();

  const sourceBaseObject = useAsyncMemo(
    async () => {
      if (!sourceBaseObjectId) return;
      const baseObject = await BaseObject.getBaseObject(
        sourceBaseObjectId,
        scheduleId,
      );
      if (baseObject?.startAt && baseObject?.endAt) {
        setDateRangeValue({
          endDate: baseObject.endAt.toDate(),
          startDate: baseObject.startAt.toDate(),
        });
      }
      return baseObject;
    },
    [sourceBaseObjectId],
    undefined,
  );

  const isValidatable: boolean = (() => {
    if (!linkGroup) return false;
    if (!baseObjectsValue.length) return false;

    if (!floorValue && linkGroup?.sourcePopulationType === PopulationType.FLOOR)
      return false;
    return !(linkGroup.isTimeSensitive && !dateRangeValue);
  })();

  const onValidate = (attributes?: BaseObjectSubmitAttribute[]): void => {
    if (linkGroup && baseObjectsValue.length) {
      const data: IAddLinkFormOutput = {
        baseObjects: baseObjectsValue,
        attributes,
        linkGroup,
      };

      if (linkGroup.isWeighted) {
        data.weight = weight;
      }

      if (
        dateRangeValue?.endDate &&
        dateRangeValue?.startDate &&
        linkGroup.isTimeSensitive
      ) {
        data.datetimeRange = {
          dtstart: dateRangeValue.startDate?.toISOString(),
          dtend: dateRangeValue.endDate?.toISOString(),
        };
      }
      if (floorValue) {
        data.objectDependantLinkGroup = floorValue;
      }
      if (sourceBaseObject?.baseobjectGroupId) {
        spawnModal({
          render: ({ onClose }): JSX.Element => {
            return (
              <RecurrenceUpdateChoiceObjectModal
                title={t('recurrenceChoiceObjectModal.addLinkObjectTitle')}
                open={true}
                icon={{ name: 'link' }}
                onClose={onClose}
                noClickOutside={true}
                description={t(
                  'recurrenceChoiceObjectModal.ObjectDescription',
                  {
                    action: t(
                      'recurrenceChoiceObjectModal.ObjectDescriptionAddLinkAction',
                    ),
                  },
                )}
                onSave={(type): void => {
                  data.baseObjectGroupChoice = type;
                  onSubmit(data);
                  onClose();
                }}
              />
            );
          },
        });
      } else {
        onSubmit(data);
      }
    }
  };

  const handleSubmitForm: BaseObjectFormOnSubmit = (attributes) => {
    onValidate(attributes);
    return Promise.resolve();
  };

  const validateButton = useMemo(() => {
    const onClick = () => {
      if (attributesFormRef?.current?.formRef) {
        attributesFormRef.current?.formRef?.requestSubmit();
      } else {
        onValidate();
      }
    };
    return sourceBaseObject?.baseobjectGroupId ? (
      <ContinueButton onClick={onClick} />
    ) : (
      <SaveButton onClick={onClick} data-testid="addLinkFormSaveButton" />
    );
  }, [sourceBaseObject, onValidate]);

  const onSelectFloor = (floor?: number): void => {
    if (!floor) {
      setBaseObjectsValue([]);
    }
    setFloorValue(floor);
    setFloorForm(false);
  };

  const handleCancelFloorForm = () => {
    setFloorForm(false);
    setBaseObjectsValue([]);
  };

  const handleCalendarChangeRange = (
    data: ILinkDateRange,
    reset = false,
  ): void => {
    if (!data) return;
    if (!dateRangeValue || reset) {
      setDateRangeValue({
        endDate: new Date(data.dtend),
        startDate: new Date(data.dtstart),
      });
    }
  };

  return (
    <div data-testid="add-link-form">
      <Modal
        open={floorForm}
        icon={{ name: 'stairs' }}
        onClose={handleCancelFloorForm}
        title={t('addLinkForm.selectFloor')}
        customClassContent="min-w-[250px] "
      >
        {baseObjectsValue.length > 0 && floorForm && (
          <FloorForm
            onSubmit={onSelectFloor}
            parentObject={baseObjectsValue[0]}
            linkGroup={linkGroup}
            onCancel={handleCancelFloorForm}
          />
        )}
      </Modal>
      <div className=" max-w-[90vw] flex overflow-y-auto gap-3 px-1 mt-5">
        <div className={'min-w-[400px]  w-full '}>
          <h3 className="block  font-medium text-gray-800 dark:text-gray-200 mb-4">
            {t('addLinkForm.whatIstheObject')}
          </h3>
          <div className="max-w-[500px]">
            <LinkGroupBaseObjectSelector
              category={category}
              sourceBaseObjectId={sourceBaseObjectId}
              linkGroup={linkGroup}
              filterParameters={filterParameters}
              values={baseObjectsValue}
              multiple={true}
              onChange={(objects) => {
                setBaseObjectsValue(objects);

                if (
                  !dateRangeValue &&
                  sourceBaseObject?.startAt &&
                  sourceBaseObject?.endAt &&
                  linkGroup?.isTimeSensitive
                ) {
                  setDateRangeValue({
                    endDate: sourceBaseObject.endAt.toDate(),
                    startDate: sourceBaseObject.startAt.toDate(),
                  });
                }
                if (
                  linkGroup?.sourceLinkGroup &&
                  linkGroup.sourcePopulationType === PopulationType.FLOOR
                ) {
                  setFloorForm(true);
                }
              }}
            />
          </div>
          {linkGroup.isTimeSensitive ? (
            <>
              <p className="block mt-5 mb-3  font-medium text-gray-800 dark:text-gray-200">
                {t('addLinkForm.whenTheObjectIsAssigned')}
              </p>
              <DateTimeRangeInput
                value={dateRangeValue}
                onChange={(range) => {
                  setDateRangeValue(range);
                  onDateRangeChange?.(range);
                }}
              />
            </>
          ) : null}
          {linkGroup.isWeighted ? (
            <div className="mb-2">
              <label
                htmlFor={`${id}-weight`}
                className="block mt-5 mb-3  font-medium text-gray-800 dark:text-gray-200"
              >
                {linkGroup.weightLabel || t('addLinkForm.whatIsTheWeight')}
              </label>
              <NumberInput
                data-testid={'AddLinkForm.NumberInput'}
                native
                id={`${id}-weight`}
                min={0}
                step="any"
                value={weight}
                onChange={(event): void => {
                  if (Number.isNaN(event.currentTarget.valueAsNumber)) return;
                  setWeight(event.currentTarget.valueAsNumber);
                }}
              />
            </div>
          ) : null}
        </div>
        {linkGroup.linkableObjectsDisplay ===
          LinkableObjectsDisplay.LIST_WITH_CALENDAR && (
          <div className="flex flex-col flex-grow">
            <div className={'min-w-[600px] flex-grow max-w-[600px]'}>
              <LinkCalendar
                sourceBaseObject={sourceBaseObject}
                baseObject={baseObjectsValue[0]}
                dateRange={
                  dateRangeValue?.endDate && dateRangeValue.startDate
                    ? {
                        dtstart: dateRangeValue.startDate?.toISOString(),
                        dtend: dateRangeValue.endDate?.toISOString(),
                      }
                    : undefined
                }
                onSelectRange={(range): void =>
                  handleCalendarChangeRange(range)
                }
                onEventChangeDate={(range): void =>
                  handleCalendarChangeRange(range, true)
                }
              />
            </div>
          </div>
        )}
      </div>
      {isValidatable && linkGroup.descriptorCategory && (
        <div className={'mt-3'}>
          <AutoBaseObjectView
            ref={attributesFormRef}
            categoryId={linkGroup.descriptorCategory?.id}
            LayoutComponent={BaseObjectFormLayout}
            viewUsage={ViewUsage.FORM}
            onSubmit={handleSubmitForm}
          />
        </div>
      )}
      <div
        className={`flex items-center ${
          linkGroup.linkableObjectsDisplay ===
          LinkableObjectsDisplay.LIST_WITH_CALENDAR
            ? 'justify-between'
            : 'justify-end'
        } mt-4`}
      >
        <IconButton
          className="mr-2"
          onClick={(): void => {
            onClickCreateBaseObject?.();
          }}
          icon={{ name: 'plus' }}
        >
          {t('links.createObjectInline')}
        </IconButton>
        <div className="flex items-center gap-2">
          {onQuit && (
            <CancelButton
              onClick={onQuit}
              data-testid="AddLinkFormCancelButton"
            />
          )}
          {isValidatable ? validateButton : null}
        </div>
      </div>
    </div>
  );
};
