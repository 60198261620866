import { diffWords } from 'diff';

interface IProps {
  oldValue: string;
  newValue: string;
  addedClassNames?: string;
  removedClassNames?: string;
  unchangedClassNames?: string;
}

export const DiffWords = (props: IProps): JSX.Element => {
  const {
    oldValue,
    newValue,
    addedClassNames,
    removedClassNames,
    unchangedClassNames,
  } = props;
  const added = addedClassNames || 'bg-green-300 dark:bg-green-600 rounded';
  const removed = removedClassNames || 'bg-red-300 dark:bg-red-700 rounded';
  const unchanged = unchangedClassNames || '';

  const diffValue = diffWords(oldValue, newValue);
  const diffElement = diffValue.map((part, index) => {
    const classNames = part.added ? added : part.removed ? removed : unchanged;
    return (
      // biome-ignore lint/suspicious/noArrayIndexKey: FIXME
      <span key={index} className={classNames}>
        {part.value}
      </span>
    );
  });
  return <p className="whitespace-pre-line">{diffElement}</p>;
};
