import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import { Tooltip } from '@smack/core/components/DataDisplay/Tooltip';
import React from 'react';
import { Button, type IButtonProps } from '../Button';

export interface IIconButtonProps extends IButtonProps {
  icon?: IconField;
  isLoading?: boolean;
  side?: 'left' | 'right';
  iconClassName?: string;
  tooltipClass?: string;
}

export const IconButton = React.forwardRef<HTMLButtonElement, IIconButtonProps>(
  function IconButton(
    {
      icon,
      isLoading = false,
      iconClassName,
      tooltipClass,
      children,
      side = 'left',
      ...buttonProps
    },
    ref,
  ) {
    const iconComponents = icon?.name ? (
      <Icon
        fixedWidth={true}
        key={isLoading ? 1 : 0}
        icon={isLoading ? { name: 'spinner-third', familyStyle: 'fad' } : icon}
        animation={isLoading ? 'spin' : undefined}
        className={iconClassName}
      />
    ) : null;

    return (
      <Tooltip
        title={buttonProps?.title}
        containerProps={{ className: tooltipClass ?? 'flex' }}
      >
        <Button
          data-testid={buttonProps?.title ?? icon?.name}
          ref={ref}
          {...buttonProps}
          title={undefined}
        >
          {side === 'left' && iconComponents}
          {children}
          {side === 'right' && iconComponents}
        </Button>
      </Tooltip>
    );
  },
);

/**
 * Create an icon button component with a defined icon.
 * The consumer of this component will not be able to change the icon.
 *
 * @param icon The FontAwesome icon name
 * @param defaultText A default text that will be shown if not provided
 * @return A React component that can be consumed directly, without any prop.
 */
export const createAliasIconButton: (
  icon: IconField,
  defaultText?: string,
) => React.FC<Omit<IIconButtonProps, 'icon'>> = (icon, defaultText) => {
  const AliasedIconComponent: React.FC<IButtonProps> = ({
    children,
    ...props
  }) => (
    <IconButton icon={icon} {...props}>
      {children || defaultText}
    </IconButton>
  );
  AliasedIconComponent.displayName = `IconButton(${
    icon.familyStyle || 'fas'
  }.${icon})`;
  return AliasedIconComponent;
};
