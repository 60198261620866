import { MediasManager } from '@smack/core/api/models/medias';
import { MediaCategory } from '@smack/core/api/models/medias/MediaCategory';
import {
  DeleteButton,
  SaveButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { DeleteConfirmAlert } from '@smack/core/components/DataDisplay/Alerts/ConfirmAlert';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { CheckboxInput } from '@smack/core/components/DataInput/CheckboxInput';
import { Input } from '@smack/core/components/DataInput/Input/Input';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import { useActiveCategories } from '@smack/core/utils/ActiveCategories';
import { spawnModal } from '@smack/core/utils/modal';
import { t } from 'i18next';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Trans } from 'react-i18next';

import type { INode } from '@smack/core/components/DataDisplay/Medias/IMediaNode';
import { Tooltip } from '../../../Tooltip';

interface IProps {
  target?: INode;
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  canEdit?: boolean;
  onSubmit: (data?: UpdateFormOutput) => void;
}

export interface UpdateFormOutput {
  label?: string;
  isExportable?: boolean;
  isThumbnail?: boolean;
  category?: Option | null;
}

export const MediaUpdateModal: React.FC<IProps> = ({
  target,
  open,
  setOpen,
  canEdit,
  onSubmit,
}) => {
  const [, , category] = useActiveCategories();
  const [mediaCategories, setMediaCategories] =
    React.useState<MediaCategory[]>();

  const { control, formState, reset, handleSubmit } = useForm<UpdateFormOutput>(
    { defaultValues: target },
  );

  React.useEffect(() => {
    if (target) reset(target);
  }, [target]);

  React.useEffect(() => {
    if (!category) return;
    MediaCategory.getMediaCategories(category?.id).then(setMediaCategories);
  }, [category]);

  const fileDeleteConfirmAlert = (): void => {
    if (!target) return;

    spawnModal({
      render: ({ onClose: onAlertClose }) => {
        return (
          <DeleteConfirmAlert
            onCloseModal={onAlertClose}
            onCloseButton={onAlertClose}
            onOk={(): void => {
              if (target.id) {
                MediasManager.deleteFile(target.id)
                  .then(() => {
                    toast.success(t('medias.deleteFileSuccess'));
                    onSubmit();
                  })
                  .catch(() => toast.error(t('medias.deleteFileError')));
              }
            }}
          />
        );
      },
    });
  };

  const folderDeleteConfirmAlert = (): void => {
    if (!target) return;

    let modalTitle: JSX.Element;
    let modalText: JSX.Element;
    if (target.children && target.children.length > 0) {
      modalTitle = <>{t('medias.deleteFolderModalTitle')}&nbsp;?</>;
      modalText = (
        <Trans
          t={t}
          i18nKey="medias.deleteFolderModalText"
          components={{
            primary: <p />,
          }}
        />
      );
    }
    spawnModal({
      render: ({ onClose: onAlertClose }) => {
        return (
          <DeleteConfirmAlert
            title={modalTitle}
            text={modalText}
            onCloseModal={onAlertClose}
            onCloseButton={onAlertClose}
            securityText={target.label}
            onOk={(): void => {
              if (!target?.id) return;
              MediasManager.deleteFolder(target.id)
                .then(() => {
                  toast.success(t('medias.deleteFolderSuccess'));
                  onSubmit();
                })
                .catch(() => toast.error(t('medias.deleteFolderError')));
            }}
          />
        );
      },
    });
  };
  const onDelete = (): void => {
    if (!target) return;
    if (target.isFile) {
      fileDeleteConfirmAlert();
    } else {
      folderDeleteConfirmAlert();
    }
  };

  return (
    <Modal
      icon={{ name: 'paperclip' }}
      title={t('medias.patchModalTitle', {
        type: target?.isFile
          ? t('medias.file', { count: 1 })
          : t('medias.folder', { count: 1 }),
      })}
      color={'#EFF6FF'}
      iconColor={'#3886F1'}
      open={open}
      onClose={setOpen}
    >
      <div className="flex flex-col">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="py-2 flex flex-col gap-3.5 min-w-[300px] max-w-[500px]"
        >
          <Controller
            control={control}
            name={'label'}
            rules={{
              required: {
                value: true,
                message: t('formValidation.required'),
              },
              validate: {
                validFilename: (v?: string): boolean | string | undefined => {
                  if (!target?.isFile) return true;
                  return (
                    (v &&
                      v.split('.').filter((part) => part !== '').length >= 2) ||
                    t('medias.invalidFilename').toString()
                  );
                },
              },
            }}
            render={({ field }): JSX.Element => (
              <Input
                {...field}
                id="label"
                label={t('formInputLabels.name')}
                readOnly={!canEdit}
                error={formState.errors.label?.message}
              />
            )}
          />
          {target?.isFile && (
            <>
              <Controller
                control={control}
                name={'category'}
                rules={{ required: false }}
                render={({ field }): JSX.Element => (
                  <SelectInput
                    {...field}
                    multiple={false}
                    disabled={!canEdit}
                    options={
                      mediaCategories?.map((category) => category.toOption()) ??
                      []
                    }
                    label={t('medias.category') ?? undefined}
                    noOptionsBeforeSearchMessage={
                      t('medias.categoryNoOptionMessage') ?? ''
                    }
                  />
                )}
              />
              <Controller
                control={control}
                name={'isExportable'}
                rules={{ required: false }}
                render={({ field }): JSX.Element => (
                  <Tooltip title={t('medias.exportableTooltip')}>
                    <CheckboxInput
                      {...field}
                      disabled={!canEdit}
                      className={{
                        container: 'items-center',
                        label: 'text-xs',
                      }}
                      label={t('medias.exportable')}
                    />
                  </Tooltip>
                )}
              />
              <Controller
                control={control}
                name={'isThumbnail'}
                rules={{ required: false }}
                render={({ field }): JSX.Element => (
                  <Tooltip title={t('medias.thumbnailTooltip')}>
                    <CheckboxInput
                      {...field}
                      disabled={!canEdit}
                      className={{
                        container: 'items-center',
                        label: 'text-xs',
                      }}
                      label={t('medias.thumbnail')}
                    />
                  </Tooltip>
                )}
              />
            </>
          )}
          {canEdit && (
            <div className="my-2 flex items-center justify-between gap-2">
              <DeleteButton onClick={onDelete} />
              <SaveButton type="submit" />
            </div>
          )}
        </form>
      </div>
    </Modal>
  );
};
