import { Editor } from '@tinymce/tinymce-react';
import type React from 'react';
import { useState } from 'react';

export interface IRichTextProps {
  value?: string;
}
export const RichText: React.FC<IRichTextProps> = ({ value }) => {
  const [sanitizedHTML, setSanitizedHTML] = useState<string>('');

  return (
    <>
      <div className="hidden">
        <Editor
          value={value || ''}
          tinymceScriptSrc="/tinymce/tinymce.min.js"
          init={{
            skin: false,
            setup: (editor): void => {
              editor.on('init', () => {
                setSanitizedHTML(editor.getContent());
              });
            },
          }}
          onEditorChange={(a, editor): void => {
            setSanitizedHTML(editor.getContent());
          }}
        />
      </div>
      <article
        className="prose prose-sm dark:prose-invert"
        // biome-ignore lint/security/noDangerouslySetInnerHtml: Content is sanitized through TinyMCE
        dangerouslySetInnerHTML={{ __html: sanitizedHTML || '' }}
      />
    </>
  );
};
