import {
  type IPagination,
  RESTClient,
} from '@smack/core/api/clients/rest/RESTClient';
import { ApiError } from '@smack/core/api/errors/ApiError/ApiError';
import { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import {
  type IViewSection,
  ViewSection,
} from '@smack/core/api/models/views/ViewSection/ViewSection';
import type { IViewRules } from '@smack/core/components/ViewRenderer/rules/ViewRulesContext/ViewRulesContext';

export type UniqueViewUsage =
  (typeof BaseObjectView)['uniqueViewUsages'][number];

export interface IObjectViewListElement {
  id: number;
  label: string;
  usage: ViewUsage;
  allowsObjectCreation: boolean;
  viewRules: IViewRules[];
}

export interface IObjectViewDetails extends IObjectViewListElement {
  viewSections?: IViewSection[];
}

export class BaseObjectView {
  id: number;

  label: string;

  usage: ViewUsage;

  allowsObjectCreation: boolean;

  viewSections: ViewSection[];

  viewRules: IViewRules[];

  static uniqueViewUsages = [
    ViewUsage.FORM,
    ViewUsage.QUICK_FORM,
    ViewUsage.DETAILS,
    ViewUsage.PREVIEW,
    ViewUsage.MAP_TOOLTIP,
    ViewUsage.SHEET,
    ViewUsage.TABLE,
    ViewUsage.FULLSCREEN,
  ] as const;

  static isViewUsageUnique(viewUsage: ViewUsage): viewUsage is UniqueViewUsage {
    return BaseObjectView.uniqueViewUsages.includes(
      viewUsage as UniqueViewUsage,
    );
  }

  constructor(data: IObjectViewDetails) {
    this.id = data.id;
    this.label = data.label;
    this.usage = data.usage;
    this.allowsObjectCreation = data.allowsObjectCreation;
    this.viewRules = data.viewRules;
    this.viewSections = (data.viewSections ?? []).map(
      (viewSectionApiOutput) => new ViewSection(viewSectionApiOutput),
    );
  }

  get isFormOrQuickForm(): boolean {
    return this.usage === ViewUsage.FORM || this.usage === ViewUsage.QUICK_FORM;
  }

  static async getBaseObjectViewsByObjectId(
    objectId: number,
    scheduleId?: number,
  ): Promise<BaseObjectView[]> {
    const { data } = await RESTClient.get<{
      data: IPagination<IObjectViewListElement>;
    }>(`/objects/baseobjects/${objectId}/views`, {
      schedule: scheduleId,
    }).catch(ApiError.toastAllErrorsOnCatchAxiosErrors);

    return data.results.map(
      (baseObjectView) => new BaseObjectView(baseObjectView),
    );
  }

  static async getBaseObjectViewsByCategoryId(
    categoryId: number,
  ): Promise<BaseObjectView[]> {
    const { data } = await RESTClient.get<{
      data: IPagination<IObjectViewListElement>;
    }>(`/categories/categories/${categoryId}/views`);

    return data.results.map(
      (baseObjectView) => new BaseObjectView(baseObjectView),
    );
  }

  static async getBaseObjectView(
    viewId: number,
    objectId?: number,
    categoryId?: number,
    scheduleId?: number,
  ): Promise<BaseObjectView> {
    let data: { results: IObjectViewDetails };
    if (objectId) {
      ({ data } = await RESTClient.get<{
        data: { results: IObjectViewDetails };
      }>(`/objects/baseobjects/${objectId}/views/${viewId}`, {
        schedule: scheduleId,
      }).catch(ApiError.toastAllErrorsOnCatchAxiosErrors));
    } else if (categoryId) {
      ({ data } = await RESTClient.get<{
        data: { results: IObjectViewDetails };
      }>(`/categories/categories/${categoryId}/views/${viewId}`).catch(
        ApiError.toastAllErrorsOnCatchAxiosErrors,
      ));
    } else {
      throw new TypeError(
        'Either an object id or category id has to be specified.',
      );
    }

    return new BaseObjectView(data.results);
  }

  static async getBaseObjectViewByViewType(
    viewType: UniqueViewUsage,
    objectId?: number,
    categoryId?: number,
    scheduleId?: number,
  ): Promise<BaseObjectView> {
    let data: { results: IObjectViewDetails };

    // TODO: REMOVE THIS CONDITION WHEN DESK READY TO DUPLICATE
    let fakeViewType = viewType;
    if (viewType === ViewUsage.SHEET) fakeViewType = ViewUsage.DETAILS;

    const viewUsageName = ViewUsage[fakeViewType];

    if (objectId) {
      ({ data } = await RESTClient.get<{
        data: { results: IObjectViewDetails };
      }>(`/objects/baseobjects/${objectId}/views/${viewUsageName}`, {
        schedule: scheduleId,
      }).catch(ApiError.toastAllErrorsOnCatchAxiosErrors));
    } else if (categoryId) {
      ({ data } = await RESTClient.get<{
        data: { results: IObjectViewDetails };
      }>(`/categories/categories/${categoryId}/views/${viewUsageName}`).catch(
        ApiError.toastAllErrorsOnCatchAxiosErrors,
      ));
    } else {
      throw new TypeError(
        'Either an object id or category id has to be specified.',
      );
    }

    data.results.usage = viewType; // TODO: REMOVE THIS CONDITION WHEN DESK READY TO DUPLICATE

    return new BaseObjectView(data.results);
  }
}
