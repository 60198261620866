import {
  FloatingPortal,
  type Placement,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { Transition } from '@headlessui/react';
import React from 'react';

interface IDropDownProps {
  menuItems: React.ReactNode;
  placement?: Placement;
  children: React.ReactNode;
  childrenContainerClassName?: string;
}

export const DropDown: React.FC<IDropDownProps> = ({
  children,
  menuItems,
  placement = 'bottom-end',
  childrenContainerClassName,
}) => {
  const nodeId = useFloatingNodeId();
  const [open, setOpen] = React.useState(false);

  const { x, y, refs, strategy, context } = useFloating({
    strategy: 'absolute',
    open: open,
    onOpenChange: setOpen,
    nodeId,
    placement,
    middleware: [flip(), shift(), offset({ mainAxis: 10, alignmentAxis: 0 })],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, {
      event: 'mousedown',
    }),
    useRole(context, { role: 'menu' }),
    useDismiss(context),
  ]);

  return (
    <>
      <div
        key={nodeId}
        className={childrenContainerClassName}
        ref={refs.setReference}
        {...getReferenceProps({
          onClick(e) {
            e.stopPropagation();
          },
        })}
      >
        {children}
      </div>
      <FloatingPortal>
        <Transition
          show={open}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            width: 'max-content',
          }}
          {...getFloatingProps()}
          className="z-[60] max-h-96 overflow-y-auto w-56 rounded-md shadow-lg bg-primary ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div className="py-1">{menuItems}</div>
        </Transition>
      </FloatingPortal>
    </>
  );
};
