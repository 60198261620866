import React from 'react';

import { getCoordinatesForPopup } from '@smack/core/utils/GeometryUtils';
import { useNonInitialEffect } from '@smack/core/utils/NonInitialEffect';
import type { Feature } from 'geojson';
import { type Map as MaplibreMap, type Offset, Popup } from 'maplibre-gl';
import { createPortal } from 'react-dom';

export interface FeaturePopupProps {
  map: MaplibreMap;
  feat: Feature;
  closeButton?: boolean;
  children: React.ReactNode;
}

const FeaturePopupRender: React.ForwardRefRenderFunction<
  Popup,
  FeaturePopupProps
> = ({ map, feat, closeButton = true, children }, ref) => {
  const container = React.useMemo<HTMLDivElement>(
    () => document.createElement('div'),
    [],
  );
  const popup = React.useMemo<Popup>(() => {
    return new Popup({
      closeButton,
      closeOnClick: false,
      closeOnMove: false,
      offset: feat.properties?.offset as Offset,
    })
      .setLngLat(getCoordinatesForPopup(feat.geometry))
      .setDOMContent(container);
  }, []);

  React.useLayoutEffect(() => {
    // We don't add the popup to the map when created in the useMemo hook
    // Otherwise, React is triggering errors due to DOM mutations during
    // render phase.
    popup.addTo(map);
    return (): void => {
      popup.remove();
    };
  }, []);

  useNonInitialEffect(() => {
    // The same feat (=> same feature id) can change geometry in mapbox draw
    popup.setLngLat(getCoordinatesForPopup(feat.geometry));
  }, [feat.geometry]);

  React.useEffect(() => {
    if (typeof ref === 'function') {
      ref(popup);
    } else if (typeof ref?.current !== 'undefined') {
      ref.current = popup;
    }
  }, []);

  return createPortal(children, container);
};

FeaturePopupRender.displayName = 'FeaturePopup';

export const FeaturePopup = React.forwardRef(FeaturePopupRender);
