import React from 'react';

import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { Task } from '@smack/core/api/models/tasks/Task';
import { BaseObjectView } from '@smack/core/api/models/views/BaseObjectView/BaseObjectView';
import { BaseObjectViewRenderer } from '@smack/core/components/ViewRenderer/BaseObjectViewRenderer';
import { TableBlock } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/TaskTable/Components/TableBlock';

interface TaskFormViewBlockProps {
  task: Task;
  baseObject: BaseObject;
}

export const TaskFormViewBlock: React.FC<TaskFormViewBlockProps> = ({
  task,
  baseObject,
}) => {
  const [loadedFormView, setLoadedFormView] = React.useState<BaseObjectView>();

  // Fetch view
  React.useEffect(() => {
    if (!task.taskFormViewId) return;
    BaseObjectView.getBaseObjectView(task.taskFormViewId, baseObject.id).then(
      setLoadedFormView,
    );
  }, [task]);

  if (!loadedFormView) return null;

  return (
    <TableBlock title={loadedFormView.label} icon={{ name: 'edit' }}>
      <BaseObjectViewRenderer view={loadedFormView} baseObject={baseObject} />
    </TableBlock>
  );
};
