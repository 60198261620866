import debounce from 'lodash.debounce';
import React from 'react';
import type { IPagination } from '../../api/clients/rest/RESTClient';

export const noOpMapper = <T>(obj: T): T => obj;

export function useAutocompleteSearch<T, R>(
  search: string,
  onSearch: (search: string, abort: AbortSignal) => Promise<T[]>,
  mapItem: (item: T) => R,
  debounceTime = 250,
  searchWhenEmpty = true,
  dependencies?: unknown[],
): [R[], boolean, boolean] {
  const [options, setOptions] = React.useState<R[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isFailing, setIsFailing] = React.useState(false);
  const abortControl = React.useRef<AbortController>();

  const triggerSearch = React.useMemo(() => {
    const sendSearch = (searchString: string): void => {
      if (!searchString && !searchWhenEmpty) {
        setOptions([]);
        setIsLoading(false);
        setIsFailing(false);
        return;
      }
      setIsLoading(true);
      abortControl.current = new AbortController();
      const signal = abortControl.current.signal;
      onSearch(searchString, signal).then(
        (items) => {
          setIsLoading(false);
          setIsFailing(false);
          setOptions(items.map(mapItem));
        },
        () => {
          // The request crashed!
          if (signal.aborted) return;
          setIsLoading(false);
          setIsFailing(true);
        },
      );
    };
    if (!debounceTime) return sendSearch;
    return debounce(sendSearch, debounceTime, {
      leading: true,
      maxWait: debounceTime * 1.5,
    });
  }, [onSearch, mapItem, debounceTime]);

  React.useEffect(() => {
    if (abortControl.current) {
      abortControl.current.abort();
    }
    triggerSearch(search);
  }, [search, ...(dependencies ?? [])]);

  return [options, isLoading, isFailing];
}

export function useAutocompletePaginatedSearch<T, R>(
  search: string,
  onSearch: (
    search: string,
    abort: AbortSignal,
    limit?: number,
    offset?: number,
  ) => Promise<IPagination<T>>,
  mapItem: (item: T) => R,
  limit: number,
  debounceTime = 250,
  searchWhenEmpty = true,
  dependencies?: unknown[],
): [R[], boolean, boolean, number, () => void] {
  const [options, setOptions] = React.useState<R[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isFailing, setIsFailing] = React.useState(false);
  const [estimatedCount, setEstimatedCount] = React.useState<number>(0);
  const [offset, setOffset] = React.useState<number>(0);

  const onPaginatedSearch = React.useCallback(
    async (search: string, abort: AbortSignal) => {
      const data = await onSearch(search, abort, limit, offset);
      setEstimatedCount(data.count ?? 0);
      return data.results;
    },
    [onSearch, limit, offset],
  );

  const [page, isPageLoading, isPageFailing] = useAutocompleteSearch(
    search,
    onPaginatedSearch,
    mapItem,
    debounceTime,
    searchWhenEmpty,
    [onPaginatedSearch, ...(dependencies || [])],
  );

  React.useEffect(() => {
    setOptions([]);
    setEstimatedCount(0);
    setOffset(0);
  }, [search]);

  React.useEffect(() => {
    setIsLoading(isPageLoading);
    setIsFailing(isPageFailing);

    if (isPageLoading || isPageFailing) return;
    setOptions([...options, ...page]);
  }, [isPageLoading, isPageFailing]);

  const loadMoreRows = () => {
    setOffset(options.length);
  };

  return [options, isLoading, isFailing, estimatedCount, loadMoreRows];
}
