import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import type { ILink } from '@smack/core/api/models/objects/Link/Link';
import { Link } from '@smack/core/api/models/objects/Link/Link';
import { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { MapBaseObject } from '@smack/core/api/models/objects/NewBaseObject/MapBaseObject';
import { buildLegacySourcesAndLayers } from '@smack/core/views/oldViewsToSort/Views/Map/Utils/Objectlayers';
import { GetFeatures } from '@smack/core/views/oldViewsToSort/Views/Objects/ObjectMap/utils';
import type { FeatureCollection } from 'geojson';
import type { Map as MaplibreMap, SymbolLayerSpecification } from 'maplibre-gl';

export const linkSourceGeoJSONLabel = (lg: number | string): string =>
  `link_geojson_source_${lg}`;
export const linkSourceLabel = (lg: number | string): string =>
  `link_source_${lg}`;

export const linkPolygonLayerLabel = (lg: number | string): string =>
  `link_polygon_layer_${lg}`;
export const linkLineLayerLabel = (lg: number | string): string =>
  `link_line_layer_${lg}`;
export const linkPointLayerLabels = (lg: number | string): string[] => {
  const { legacyPoint: layers } = buildLegacySourcesAndLayers().layers;
  return layers.map((layer) => `links-${lg}-${layer.id}`);
};

export const linkSourceRegexp = new RegExp(linkSourceLabel('(\\d+)'));

export const linkPolygonLayerRegexp = new RegExp(
  linkPolygonLayerLabel('(\\d+)'),
);
export const linkLineLayerRegexp = new RegExp(linkLineLayerLabel('(\\d+)'));
export const linkPointLayerRegexps = (): RegExp[] => {
  const { legacyPoint: layers } = buildLegacySourcesAndLayers().layers;
  return layers.map((layer) => new RegExp(`links-\\d+-${layer.id}`));
};

type LinkStorage = Record<number, Link[]>;
interface LinkStorageFetchAction {
  type: 'fetch';
  payload: {
    linkGroupId: number;
    startIndex: number;
    elements: ILink[];
  };
}
interface LinkStoragePruneAction {
  type: 'prune';
  payload: {
    linkGroupId: number;
  };
}
interface LinkStorageResetAction {
  type: 'reset';
  payload: {
    linkGroupIds: number[];
  };
}
type LinkStorageAction =
  | LinkStorageFetchAction
  | LinkStoragePruneAction
  | LinkStorageResetAction;

export function linkStorageReducer(
  state: LinkStorage,
  action: LinkStorageAction,
): LinkStorage {
  // Always return a new object reference to trigger effects based on the BaseObjectStorage dependency
  if (action.type === 'fetch') {
    const linkArray = [...state[action.payload.linkGroupId]];
    action.payload.elements.every((link, i) => {
      linkArray[action.payload.startIndex + i] = new Link(link);
      return true;
    });
    state[action.payload.linkGroupId] = linkArray;
    return { ...state };
  }
  if (action.type === 'prune') {
    const newState = { ...state };
    newState[action.payload.linkGroupId] = [];
    return newState;
  }
  return Object.fromEntries(
    action.payload.linkGroupIds.map<[number, []]>((id) => [id, []]),
  );
}

/**
 * Convert a MapBaseObject array
 * @param objects Array of MapBaseObjects to be converted
 * @returns A GeoJSON compliant collection, for usage in map views.
 */
export const getFeaturesCollection = (
  objects: MapBaseObject[],
): FeatureCollection => {
  return GetFeatures(objects);
};

/**
 * Create all the needed layers (points, labels, etc.) to show points on link group map representation
 * @param linkGroupId Link group ID, used to build layer IDs
 * @param sourceId Source ID used in the layers, usually generated with `linkSourceGeoJSONLabel`
 */
export const addLinkPointsLayer = (
  linkGroupId: number | string,
  sourceId: string,
): void => {
  const map = window.Hyvilo.Utils.MainMap;
  if (!map) return;
  const { legacyPoint: layers } = buildLegacySourcesAndLayers().layers;
  layers.forEach((layer) => {
    const l = {
      ...layer,
      id: `links-${linkGroupId}-${layer.id}`,
      source: sourceId,
    };
    map.addLayer(l as SymbolLayerSpecification);
  });
};

export const getActiveLinkLayers = (map: MaplibreMap): string[] => {
  const layers = map.getStyle().layers;
  const regexArray = [
    ...linkPointLayerRegexps(),
    linkPolygonLayerRegexp,
    linkLineLayerRegexp,
  ];
  return layers
    .filter((layer) => {
      return regexArray.some((regex) => regex.test(layer.id));
    })
    .map((layer) => layer.id);
};

/**
 * Generate an array of tile sources for a link group tile layer
 * @param linkGroupId ID of the link group
 * @param sourceObjectId ID of the base object that the link group refers to
 * @returns TileJSON compliant array of tile sources
 */
export const buildTilesSource = (
  linkGroupId: number,
  sourceObjectId: number,
): string[] => [
  `${RESTClient.getApiUrlEntryPoint()}/objects/baseobjects/vector-tiles/{z}/{x}/{y}.pbf?${BaseObject.getStringifiedQueryParamsFromFilter(
    'geometriesVector',
    undefined,
    undefined,
    undefined,
    { linkGroupId, sourceBaseObjectId: sourceObjectId },
  )}`,
];

export const addVectorTilesSource = (
  linkGroupId: number,
  sourceObjectId: number,
): void => {
  const map = window.Hyvilo.Utils.MainMap;
  map?.addSource(linkSourceLabel(linkGroupId), {
    type: 'vector',
    tiles: buildTilesSource(linkGroupId, sourceObjectId),
    promoteId: 'id',
  });
};
