import bbox from '@turf/bbox';
import type { FeatureCollection } from 'geojson';
import type { Map as MaplibreMap } from 'maplibre-gl';

export function fitBoundingBoxOfGeometryCollection(
  map: MaplibreMap,
  geometries: FeatureCollection,
): void {
  try {
    const boundingBox = bbox(geometries);
    map.fitBounds(
      [
        boundingBox.slice(0, 2) as [number, number],
        boundingBox.slice(2, 4) as [number, number],
      ],
      { padding: 30 },
    );
  } catch {
    // no-op
  }
}
