import type { FC, FocusEventHandler, ReactNode } from 'react';
import './index.scss';
import { enGB, fr } from 'date-fns/locale';
import DatePicker, {
  type ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AffixChip } from '@smack/core/components/DataInput/AffixChip/AffixChip';
import { cva } from 'class-variance-authority';
import { t } from 'i18next';
import moment from 'moment';

export interface DateInputProps {
  id?: string;
  label?: string;
  error?: string;
  value?: string;
  hidetime?: boolean;
  placeholder?: string;
  onChange?: (data?: string) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  datepickerProps?: Partial<ReactDatePickerProps>;
  classNames?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
}

const innerDateInputVariants = cva(
  [
    'w-full',
    'sm:max-w-xs',
    'pr-10',
    'py-2',
    'rounded',
    'text-sm',
    'text-text',
    '!bg-primary',
    'dark:!bg-view',
    'focus:ring-blue-400',
  ],
  {
    variants: {
      status: {
        error: ['border-red-600', 'hacky-error-icon'],
        neutral: [
          'border-gray-300',
          'dark:border-neutral-500',
          'hacky-calendar-icon',
        ],
      },
      leftBorder: {
        rounded: [],
        sharp: ['rounded-l-none'],
      },
      rightBorder: {
        rounded: [],
        sharp: ['rounded-r-none'],
      },
    },
    defaultVariants: {
      status: 'neutral',
      leftBorder: 'rounded',
      rightBorder: 'rounded',
    },
  },
);

export const DateInput: FC<DateInputProps> = ({
  id,
  label,
  value,
  onChange,
  onBlur,
  error,
  placeholder,
  hidetime,
  datepickerProps,
  classNames,
  prefix,
  suffix,
}) => {
  registerLocale('fr', fr);
  registerLocale('en', enGB);
  const getValue = (): Date | undefined => {
    if (value) {
      return moment(value).toDate();
    }
  };

  const handleChange = (date: Date): void => {
    if (onChange) {
      if (!date) {
        onChange(date);
        return;
      }
      if (hidetime) {
        onChange(moment(date).format('YYYY-MM-DD'));
      } else {
        onChange(date?.toISOString());
      }
    }
  };

  return (
    <div className="w-full">
      {label && (
        <label
          className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1"
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <div
        data-testid="date-picker"
        className="max-w-lg sm:max-w-xs flex shadow-sm"
      >
        {prefix ? <AffixChip type="prefix">{prefix}</AffixChip> : null}
        <DatePicker
          className={innerDateInputVariants({
            status: error ? 'error' : 'neutral',
            leftBorder: prefix ? 'sharp' : 'rounded',
            rightBorder: suffix ? 'sharp' : 'rounded',
            className: classNames,
          })}
          popperClassName="!z-20"
          selected={getValue()}
          showTimeSelect={!hidetime}
          timeIntervals={10}
          timeCaption={t('datepicker.timeCaption')}
          placeholderText={placeholder || ''}
          locale={navigator.language.includes('fr') ? 'fr' : 'en'}
          dateFormat={hidetime ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm'}
          onChange={handleChange}
          onBlur={onBlur}
          id={id}
          {...datepickerProps}
        />
        {suffix ? <AffixChip type="suffix">{suffix}</AffixChip> : null}
      </div>
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </div>
  );
};
