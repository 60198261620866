import type { ViewElementRendererProps } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/type';
import { type FC, useMemo } from 'react';

export const LinkFieldViewElementRenderer: FC<ViewElementRendererProps> = ({
  viewElement,
  props,
}) => {
  const link = useMemo(() => props.link, [props]);

  if (!link) return null;

  return link.renderLinkField(viewElement);
};
