import type { IconName } from '@fortawesome/fontawesome-common-types';
import type { MapBaseObject } from '@smack/core/api/models/objects/NewBaseObject/MapBaseObject';
import type { IUseNavigationOutput } from '@smack/core/hooks/useNavigation/useNavigation';
import type { MapMode } from '@smack/core/store/maps/types';
import { getActiveLinkLayers } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Links/Utils';
import {
  type AbstractMapEvent,
  initMakersLayers,
} from '@smack/core/views/oldViewsToSort/Views/Map/Utils/InitPrintMarker';
import type { FeatureCollection } from 'geojson';
import type {
  FilterSpecification,
  MapGeoJSONFeature,
  MapLayerEventType,
  Map as MapLibreMap,
} from 'maplibre-gl';

/* Maplibre layers and sources names */
export const baseobjectVectorClustersSourceLabel =
  'baseobject_vector_clusters_source';
export const baseobjectVectorPointsSourceLabel =
  'baseobject_vector_points_source';
export const baseobjectSourceLabel = 'baseobject_source';
export const baseobjectPolygonLayerLabel = 'baseobject_polygon_layer';
export const baseobjectLineLayerLabel = 'baseobject_line_layer';
export const baseobjectVectorClustersLayerLabel =
  'baseobject_vector_cluster_layer';
export const baseobjectVectorUnclusteredPointsCircleLayerLabel =
  'baseobject_vector_unclustered_points_circle_layer';
export const baseobjectVectorUnclusteredPointsSymbolLayerLabel =
  'baseobject_vector_unclustered_points_symbol_layer';
export const baseobjectVectorPointsCircleLayerLabel =
  'baseobject_vector_points_circle_layer';
export const baseobjectVectorPointsSymbolLayerLabel =
  'baseobject_vector_points_symbol_layer';
export const baseobjectVectorHeatmapLayerLabel =
  'baseobject_vector_heatmap_layer';

export const modeToLayerMapping: Readonly<Partial<Record<MapMode, string[]>>> =
  {
    cluster: [
      baseobjectVectorClustersLayerLabel,
      baseobjectVectorUnclusteredPointsSymbolLayerLabel,
      baseobjectVectorUnclusteredPointsCircleLayerLabel,
    ],
    point: [
      baseobjectVectorPointsSymbolLayerLabel,
      baseobjectVectorPointsCircleLayerLabel,
    ],
    heatmap: [baseobjectVectorHeatmapLayerLabel],
  };

/**
 * Handle click event layer by layer, stoping at the first relevant one
 * @param e the click event that occurred
 * @param map the map
 */
export const handleUserInputWithoutConflictingLayer = (
  e: MapLayerEventType['click' | 'touchstart'],
  map: MapLibreMap,
  history: IUseNavigationOutput,
): void => {
  let f: MapGeoJSONFeature[] = [];
  if (map.getLayer('point-legacyCluster')) {
    f = map.queryRenderedFeatures(e.point, {
      layers: [baseobjectVectorClustersLayerLabel, 'point-legacyCluster'],
    });
    if (f.length) {
      return;
    }
  }
  if (map.getLayer('heatmap')) {
    f = map.queryRenderedFeatures(e.point, {
      layers: [baseobjectVectorHeatmapLayerLabel, 'legacyHeatmap'],
    });
    if (f.length) {
      return;
    }
  }
  // link layer in priority because of overlap
  const clickableLayers = [
    ...getActiveLinkLayers(map),
    baseobjectPolygonLayerLabel,
    baseobjectLineLayerLabel,
  ];
  const clickedLayer: string | undefined = clickableLayers.find(
    (vectorTileLayer) => {
      if (!map.getLayer(vectorTileLayer)) return false;
      const features: maplibregl.MapGeoJSONFeature[] =
        map.queryRenderedFeatures(e.point, {
          layers: [vectorTileLayer],
        });
      if (features.length) {
        if (features[0].properties?.frontEndpoint) {
          history(features[0].properties.frontEndpoint as string);
        }
        return true;
      }
      return false;
    },
  );
  if (clickedLayer) {
    return;
  }
};

/**
 * Build filters to discriminate geometries based on their type
 * @param geometryTypes the types of geometry to include in this filter
 * @returns the maplibre filter to build a layer including only the given geometry type
 */
const getVectorTilesLayersFilters = (
  geometryTypes: string[],
): FilterSpecification => {
  const mapBoxFilter: FilterSpecification = ['any'];
  geometryTypes.forEach((geometryType) => {
    mapBoxFilter.push(['==', ['get', 'geometry_type'], geometryType] as never);
  });
  return mapBoxFilter;
};

export const addVectorTilesLineLayer = (
  map: maplibregl.Map | null,
  layerLabel: string,
  sourceLabel: string,
  vectorTilesLabel: string,
  beforeId?: string,
): void => {
  /* Layer to display lines */
  if (!map?.getLayer(layerLabel))
    map?.addLayer(
      {
        id: layerLabel,
        type: 'line',
        'source-layer': vectorTilesLabel,
        source: sourceLabel,
        paint: {
          'line-color': ['get', 'color'],
          'line-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            1,
            0.5,
          ],
          'line-width': 4,
        },
        filter: getVectorTilesLayersFilters(['LINESTRING', 'MULTILINESTRING']),
      },
      beforeId,
    );
};

export const addVectorTilesPolygonLayer = (
  map: maplibregl.Map | null,
  layerLabel: string,
  sourceLabel: string,
  vectorTilesLabel: string,
  beforeId?: string,
): void => {
  map?.addLayer(
    {
      id: layerLabel,
      type: 'fill',
      'source-layer': vectorTilesLabel,
      source: sourceLabel,
      paint: {
        'fill-color': ['get', 'color'],
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          1,
          0.5,
        ],
      },
      filter: getVectorTilesLayersFilters(['POLYGON', 'MULTIPOLYGON']),
    },
    beforeId,
  );
};

export const addVectorTilesPointLayer = (
  map: maplibregl.Map | null,
  symbolLayerLabel: string,
  circleLayerLabel: string,
  sourceLabel: string,
  vectorTilesLabel: string,
  beforeId?: string,
  filter: FilterSpecification = ['boolean', true],
): void => {
  map
    ?.addLayer(
      {
        id: symbolLayerLabel,
        type: 'symbol',
        source: sourceLabel,
        'source-layer': vectorTilesLabel,
        filter,
        layout: {
          'text-field': [
            'case',
            ['>', ['length', ['get', 'code']], 50],
            ['concat', ['slice', ['get', 'code'], 0, 50], '…'],
            ['get', 'code'],
          ],
          'text-size': 10,
          'text-font': ['Open Sans Regular'],
          'text-justify': 'center',
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-radial-offset': 2,
          'icon-image': [
            'concat',
            '/images/FontAwesomeIcons/',
            [
              'case',
              ['==', ['get', 'iconFamilyStyle'], 'fas'],
              'solid',
              ['==', ['get', 'iconFamilyStyle'], 'far'],
              'regular',
              ['==', ['get', 'iconFamilyStyle'], 'fal'],
              'light',
              ['==', ['get', 'iconFamilyStyle'], 'fat'],
              'thin',
              ['==', ['get', 'iconFamilyStyle'], 'fad'],
              'duotone',
              ['==', ['get', 'iconFamilyStyle'], 'fab'],
              'brands',
              ['==', ['get', 'iconFamilyStyle'], 'fass'],
              'sharp-solid',
              'solid',
            ],
            '/',
            ['get', 'iconName'],
            '.svg',
          ],
          'icon-ignore-placement': true,
          'icon-size': [
            'interpolate',
            ['exponential', 0.5],
            ['zoom'],
            15.4,
            0,
            15.6,
            0.5,
          ],
        },
        paint: {
          'icon-color': '#FFFFFF',
          'text-halo-width': 4,
          'text-color': 'black',
          'text-halo-color': 'white',
          'text-opacity': [
            'interpolate',
            ['exponential', 1],
            ['zoom'],
            15.4,
            0,
            15.6,
            1,
          ],
        },
      },
      beforeId,
    )
    ?.addLayer(
      {
        id: circleLayerLabel,
        type: 'circle',
        source: sourceLabel,
        'source-layer': vectorTilesLabel,
        filter,
        paint: {
          'circle-radius': [
            'interpolate',
            ['exponential', 14],
            ['zoom'],
            15.4,
            4,
            15.6,
            14,
          ],
          'circle-color': [
            'case',
            ['boolean', ['feature-state', 'focused'], false],
            'red',
            ['get', 'color'],
          ],
          'circle-stroke-width': [
            'interpolate',
            ['exponential', 3],
            ['zoom'],
            15.4,
            0.857,
            15.6,
            3,
          ],
          'circle-stroke-color': [
            'case',
            ['boolean', ['feature-state', 'linked'], false],
            'red',
            'white',
          ],
        },
      },
      symbolLayerLabel,
    );
};

export const addVectorTilesClusterLayer = (
  map: maplibregl.Map | null,
  clusterLayerLabel: string,
  symbolLayerLabel: string,
  circleLayerLabel: string,
  sourceLabel: string,
  vectorTilesLabel: string,
  beforeId?: string,
): void => {
  map?.addLayer(
    {
      id: clusterLayerLabel,
      type: 'circle',
      source: sourceLabel,
      'source-layer': vectorTilesLabel,
      paint: {
        'circle-opacity': 0,
        'circle-radius': 0,
      },
      filter: ['get', 'cluster'],
    },
    beforeId,
  );
  addVectorTilesPointLayer(
    map,
    symbolLayerLabel,
    circleLayerLabel,
    sourceLabel,
    vectorTilesLabel,
    clusterLayerLabel,
    ['!has', 'cluster'],
  );
};

export const addVectorTilesHeatmapLayer = (
  map: maplibregl.Map | null,
  layerLabel: string,
  sourceLabel: string,
  vectorTilesLabel: string,
  beforeId?: string,
): void => {
  map?.addLayer(
    {
      id: layerLabel,
      type: 'heatmap',
      source: sourceLabel,
      'source-layer': vectorTilesLabel,
      maxzoom: 19,
      paint: {
        'heatmap-weight': {
          property: 'dbh',
          type: 'exponential',
          stops: [
            [1, 0],
            [62, 1],
          ],
        },
        'heatmap-intensity': {
          type: 'interval',
          stops: [
            [11, 1],
            [15, 3],
          ],
        },
        'heatmap-color': [
          'interpolate',
          ['linear'],
          ['heatmap-density'],
          0,
          'rgba(236,222,239,0)',
          0.2,
          'rgb(208,209,230)',
          0.4,
          'rgb(166,189,219)',
          0.6,
          'rgb(103,169,207)',
          0.8,
          'rgb(28,144,153)',
        ],
        'heatmap-radius': {
          type: 'interval',
          stops: [
            [11, 15],
            [15, 20],
          ],
        },
        'heatmap-opacity': {
          type: 'interval',
          stops: [
            [14, 1],
            [18, 0],
          ],
        },
      },
    },
    beforeId,
  );
};

const fontAwesomeUrlFromIconName = (iconName?: IconName): string => {
  if (!iconName) return '';
  return `/images/FontAwesomeIcons/solid/${iconName}.svg`;
};

export const GetFeatures = (objects: MapBaseObject[]): FeatureCollection => {
  const output: FeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  };
  objects.forEach((object) => {
    if (object.location) {
      const location = {
        ...object.location,
        properties: {
          ...object.location.properties,
          icon: fontAwesomeUrlFromIconName(
            object.location.properties?.icon as IconName,
          ),
        },
      };
      output.features = output.features.concat(location);
    }
  });
  return output;
};

export const GetClusterConfig = (
  colorList: string[],
): {
  counters: { [key: string]: number };
  colorList: string[];
} => {
  const counters = {};

  // For each color, create a counter that will tracker how many element sport this color
  colorList.forEach((color) => {
    counters[color] = ['+', ['case', ['==', ['get', 'color'], color], 1, 0]];
  });
  return {
    counters,
    colorList,
  };
};

export const addPointClusteringLayer = (
  colors: Set<string>,
  history: IUseNavigationOutput,
  hover: (e: AbstractMapEvent) => void,
): void => {
  initMakersLayers(
    GetClusterConfig([...colors]),
    (link) => history(link),
    hover,
  );
};
