import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { DEFAULT_THEME } from '../constants';

import { useOnScreen } from '@smack/core/hooks/useOnScreen';
import { useTranslation } from 'react-i18next';
import DisabledItem from './DisabledItem';
import GroupItem from './GroupItem';
import Item from './Item';
import { SelectContext } from './SelectProvider';
import type { Options as ListOption, Option } from './type';

interface OptionsProps {
  list: ListOption;
  loading?: boolean;
  noOptionsMessage: string;
  noOptionsBeforeSearchMessage?: string;
  text: string;
  isMultiple: boolean;
  value: Option | Option[] | null;
  primaryColor: string;
  onLastOptionVisible?: () => void;
  needFilter?: boolean;
  isLoaded?: boolean;
  count?: number;
}

const Options: React.FC<OptionsProps> = ({
  list,
  loading = false,
  isLoaded = false,
  count,
  noOptionsMessage = '',
  noOptionsBeforeSearchMessage = '',
  text,
  isMultiple,
  value,
  primaryColor = DEFAULT_THEME,
  onLastOptionVisible,
  needFilter = true,
}) => {
  const [t] = useTranslation();
  const { classNames } = useContext(SelectContext);
  const filterByText = useCallback(() => {
    const filterItem = (item: Option): boolean => {
      if (!needFilter) return true;
      return (
        item.label?.toString().toLowerCase().indexOf(text.toLowerCase()) > -1
      );
    };

    let result = list.map((item) => {
      if ('options' in item) {
        return {
          label: item.label,
          options: item.options.filter(filterItem),
        };
      }
      return item;
    });

    result = result.filter((item) => {
      if ('options' in item) {
        return item.options.length > 0;
      }
      return filterItem(item);
    });

    return result;
  }, [text, list]);

  const removeValues = useCallback(
    (array: ListOption) => {
      if (!isMultiple) {
        return array;
      }

      if (Array.isArray(value)) {
        const valueId = value.map((item) => item.value);

        const filterItem = (item: Option): boolean =>
          !valueId.includes(item.value);

        let newArray = array.map((item) => {
          if ('options' in item) {
            return {
              label: item.label,
              options: item.options.filter(filterItem),
            };
          }
          return item;
        });

        newArray = newArray.filter((item) => {
          if ('options' in item) {
            return item.options.length > 0;
          }
          return filterItem(item);
        });

        return newArray;
      }
      return array;
    },
    [isMultiple, value],
  );

  const filterResult = useMemo(() => {
    return removeValues(filterByText());
  }, [filterByText, removeValues]);

  const loadingMessageRef = useRef<HTMLDivElement>(null);
  const isLastOptionVisible = useOnScreen(loadingMessageRef);

  useEffect(() => {
    if (isLastOptionVisible && onLastOptionVisible) onLastOptionVisible();
  }, [isLastOptionVisible]);

  return (
    <div className="px-2.5">
      {filterResult.length !== 0 && count && (
        <div className={'text-xs italic text-gray-500 p-1.5'}>
          <p>{t('countResults', { count })}</p>
        </div>
      )}
      <div
        className={
          classNames?.list ? classNames.list : 'max-h-56 overflow-y-auto'
        }
      >
        {filterResult.map((item, index) => (
          <React.Fragment key={`${item.label}-${index}`}>
            {'options' in item ? (
              <>
                <GroupItem
                  primaryColor={primaryColor || DEFAULT_THEME}
                  item={item}
                />

                {index + 1 < filterResult.length && <hr className="my-1" />}
              </>
            ) : (
              <Item primaryColor={primaryColor || DEFAULT_THEME} item={item} />
            )}
          </React.Fragment>
        ))}

        {filterResult.length === 0 && !loading && (
          <DisabledItem>
            {isLoaded ? noOptionsMessage : noOptionsBeforeSearchMessage}
          </DisabledItem>
        )}
        <div ref={loadingMessageRef}>
          {loading && <DisabledItem>{t('loading')}</DisabledItem>}
        </div>
      </div>
    </div>
  );
};

export default Options;
