import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { type Options, RRule } from 'rrule';
import { getRecurenceTypeDays } from '../../Utils';

interface IProps {
  value?: RRule;
  onChange: (value: RRule) => void;
}

export const WeeklyRRule = (props: IProps): JSX.Element => {
  const [t] = useTranslation();
  const { value, onChange } = props;

  const defaultValue: Partial<Options> = {
    freq: RRule.WEEKLY,
    interval: 1,
    byweekday: [],
  };

  React.useEffect(() => {
    if (value?.options.freq !== RRule.WEEKLY) {
      onChange(new RRule(defaultValue));
    }
  }, [value]);

  const getListNumber = (
    num: number[] | number | undefined,
  ): number[] | undefined => {
    if (num && typeof num === 'number') {
      return [num];
    }
    if (typeof num === 'string') {
      return num;
    }
    if (num && typeof num === 'object') {
      try {
        if (typeof num[0] === 'number') {
          return num;
        }
        return num[0];
      } catch {
        return undefined;
      }
    }
  };

  const getValue = (): Option[] => {
    const output: Option[] = [];
    getListNumber(value?.options.byweekday)?.forEach((v) => {
      const toadd = getRecurenceTypeDays().find((r) => r.value === v);
      if (toadd) output.push(toadd);
    });
    return output;
  };

  const handleChange = (val: Option): void => {
    const newVal = getListNumber(value?.options.byweekday) || [];
    const valValue: number = val.value as number;
    if (newVal.includes(valValue)) {
      newVal.splice(newVal.indexOf(valValue), 1);
    } else {
      newVal.push(valValue);
    }
    onChange(new RRule({ ...value?.origOptions, byweekday: newVal }));
  };

  return (
    <div>
      <p className="text-sm">{t('recurrence.repeatOn')}</p>
      <div className=" flex items-center mt-2 gap-2">
        {getRecurenceTypeDays().map((v) => (
          <div
            key={v.label}
            onClick={(): void => handleChange(v)}
            className={`text-xs rounded-full h-6 w-6   flex items-center justify-center ${
              getValue().find((val) => val.value === v.value)
                ? 'bg-blue-500 hover:bg-blue-600 text-white'
                : 'bg-gray-200 hover:bg-gray-300 text-gray-700'
            } cursor-pointer`}
          >
            {<p className="text-xs">{v.label.toString().slice(0, 2)}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};
