// Copied from https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-react/src/utils/focus-management.ts

const focusableSelector = [
  '[contentEditable=true]',
  '[tabindex]',
  'a[href]',
  'area[href]',
  'button:not([disabled])',
  'iframe',
  'input:not([disabled])',
  'select:not([disabled])',
  'textarea:not([disabled])',
]
  .map(
    typeof vitest === 'object'
      ? // TODO: Remove this once JSDOM fixes the issue where an element that is
        // "hidden" can be the document.activeElement, because this is not possible
        // in real browsers.
        (selector) =>
          `${selector}:not([tabindex='-1']):not([style*='display: none'])`
      : /* v8 ignore next */
        (selector) => `${selector}:not([tabindex='-1'])`,
  )
  .join(',');

function getFocusableElements(container: HTMLElement | null = document.body) {
  if (container == null) return [];
  return Array.from(
    container.querySelectorAll<HTMLElement>(focusableSelector),
  ).sort(
    // We want to move `tabIndex={0}` to the end of the list, this is what the browser does as well.
    (a, z) =>
      Math.sign(
        (a.tabIndex || Number.MAX_SAFE_INTEGER) -
          (z.tabIndex || Number.MAX_SAFE_INTEGER),
      ),
  );
}

export function focusIn(container: HTMLElement = document.body) {
  const ownerDocument = container.ownerDocument;

  const focusableElements = getFocusableElements(container);

  for (const element of focusableElements) {
    element.focus();
    if (ownerDocument.activeElement === element) break;
  }
}
