import { Category } from '@smack/core/api/models/categories/Category';
import type { DisplayedButton } from '@smack/core/api/models/categories/DisplayedButton';
import { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import { ActionsHeader } from '@smack/core/components/DataDisplay/Headers/ActionsHeader/ActionsHeader';
import { AutoBaseObjectView } from '@smack/core/components/ViewRenderer/AutoBaseObjectView';
import { ViewName } from '@smack/core/hooks/views/types';
import { useActiveView } from '@smack/core/hooks/views/useActiveView/useActiveView';
import { spawnModal } from '@smack/core/utils/modal';
import { CreateTimeSeriesBaseObjectModal } from '@smack/core/views/oldViewsToSort/Layouts/Modal/CreateTimeSeriesBaseObjectModal/CreateTimeSeriesBaseObjectModal';
import { DuplicateBaseObjectModal } from '@smack/core/views/oldViewsToSort/Layouts/Modal/DuplicateBaseObjectModal/DuplicateBaseObjectModal';
import {
  ActionsButtonViewType,
  getActionsButtons,
} from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/common/getActionsButtons';
import { onClickDelete } from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/common/onClickDelete';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAsyncMemo } from 'use-async-memo';

export interface DetailsSheetProps {
  baseObjectId?: number;
  scheduleId?: number;
  onClose?: () => void;
}

export const DetailsSheet: React.FC<DetailsSheetProps> = ({
  baseObjectId,
  onClose,
  scheduleId,
}) => {
  const [baseobject, setBaseobject] = React.useState<BaseObject>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, setView] = useActiveView();
  const printRef = React.useRef<HTMLDivElement>(null);
  const fetchBaseObject = () => {
    if (!baseObjectId) return;
    BaseObject.getBaseObject(baseObjectId, scheduleId).then(setBaseobject);
  };

  React.useEffect(() => {
    fetchBaseObject();
  }, [baseObjectId]);

  const category = useAsyncMemo(() => {
    if (baseobject?.category?.id) {
      return Category.getCategory(baseobject?.category?.id);
    }
  }, [baseobject]);

  const onClickShowDashboard = (baseObject, button: DisplayedButton): void => {
    if (!baseObject?.id || !button.action) return;
    setView({
      view: ViewName.Dashboard,
      params: {
        dashboardDisplayAction: button.action,
        baseobjectId: baseObject.id,
      },
    });
  };

  return (
    <div
      data-testid={'details-sheet'}
      className="flex flex-col flex-grow bg-view"
    >
      <ActionsHeader
        actions={[
          ...(category
            ? getActionsButtons(
                category,
                {
                  printRef: printRef,
                  onClickShowDashboard: (displayedButton) =>
                    onClickShowDashboard(baseobject, displayedButton),
                  onClickDelete: baseobject?.isWritable
                    ? () => onClickDelete(baseobject, () => navigate('.'))
                    : undefined,
                  onClickDuplicate: baseobject
                    ? (): void => {
                        spawnModal({
                          render: ({ onClose }): JSX.Element => {
                            return (
                              <DuplicateBaseObjectModal
                                open={true}
                                setOpen={onClose}
                                baseObject={baseobject}
                              />
                            );
                          },
                        });
                      }
                    : undefined,
                  onClickCreateTimeSeries: baseobject
                    ? (): void => {
                        spawnModal({
                          render: ({ onClose }): JSX.Element => {
                            return (
                              <CreateTimeSeriesBaseObjectModal
                                open={true}
                                setOpen={onClose}
                                baseObject={baseobject}
                                onValidate={fetchBaseObject}
                              />
                            );
                          },
                        });
                      }
                    : undefined,
                },
                ActionsButtonViewType.Detail,
              )
            : []),
        ]}
      />
      <div
        ref={printRef}
        className={'flex flex-grow pl-6 pr-6 pt-0 pb-3 overflow-y-auto'}
      >
        <AutoBaseObjectView
          viewUsage={ViewUsage.SHEET}
          categoryId={baseobject?.category?.id}
          baseObject={baseobject}
          scheduleId={scheduleId}
        />
      </div>
    </div>
  );
};
