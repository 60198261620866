import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import type { AxiosResponse } from 'axios';

export enum ExportCreationStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  ERRORED = 'ERRORED',
  FINISHED = 'FINISHED',
}

export enum ExportAvailabilityStatus {
  UNAVAILABLE = 'UNAVAILABLE',
  AVAILABLE = 'AVAILABLE',
  EXPIRED = 'EXPIRED',
  RETRIEVED = 'RETRIEVED',
}

export interface ExportApiOutput {
  id: number;
  creationStatus: ExportCreationStatus;
  availabilityStatus: ExportAvailabilityStatus;
  filesTotal?: number;
  filesExported?: number;
  uuids: string[];
  streamUrl?: string;
}

export class MediaExport implements ExportApiOutput {
  id: number;

  creationStatus: ExportCreationStatus;

  availabilityStatus: ExportAvailabilityStatus;

  filesTotal?: number;

  filesExported?: number;

  uuids: string[];

  streamUrl?: string;

  constructor(data: ExportApiOutput) {
    this.id = data.id;
    this.creationStatus = data.creationStatus;
    this.availabilityStatus = data.availabilityStatus;
    this.filesTotal = data.filesTotal;
    this.filesExported = data.filesExported;
    this.uuids = data.uuids;
    this.streamUrl = data.streamUrl;
  }

  static create(
    uuids: string[],
  ): Promise<AxiosResponse<{ results: ExportApiOutput }>> {
    return RESTClient.post({ uuids }, '/medias/exports');
  }

  static get(id: number): Promise<AxiosResponse<{ results: ExportApiOutput }>> {
    return RESTClient.get(`/medias/exports/${id}`);
  }

  stream() {
    const link = document.createElement('a');
    link.href = this.streamUrl ?? '#';
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }
}
