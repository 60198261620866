import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { cva } from 'class-variance-authority';
import type React from 'react';
import { useCallback, useMemo } from 'react';

import { COLORS, DEFAULT_THEME, THEME_DATA } from '../constants';

import DisabledItem from './DisabledItem';
import { useSelectContext } from './SelectProvider';
import type { Option } from './type';

interface ItemProps {
  item: Option;
  primaryColor: string;
}

const Item: React.FC<ItemProps> = ({ item, primaryColor }) => {
  const { classNames, value, handleValueChange, formatOptionLabel } =
    useSelectContext();

  const isSelected = useMemo(() => {
    return (
      value !== null && !Array.isArray(value) && value.value === item.value
    );
  }, [item.value, value]);

  const textHoverColor = useMemo<string>(() => {
    if (COLORS.includes(primaryColor)) {
      return THEME_DATA.textHover[primaryColor] as string;
    }
    return THEME_DATA.textHover[DEFAULT_THEME];
  }, [primaryColor]);

  const bgColor = useMemo<string>(() => {
    if (COLORS.includes(primaryColor)) {
      return THEME_DATA.bg[primaryColor] as string;
    }
    return THEME_DATA.bg[DEFAULT_THEME];
  }, [primaryColor]);

  const bgHoverColor = useMemo<string>(() => {
    if (COLORS.includes(primaryColor)) {
      return THEME_DATA.bgHover[primaryColor] as string;
    }
    return THEME_DATA.bgHover[DEFAULT_THEME];
  }, [primaryColor]);

  const itemClass = cva(
    'block flex items-center transition duration-200 px-2 py-2 cursor-pointer select-none rounded',
    {
      variants: {
        isSelected: {
          true: `text-white ${bgColor}`,
          false: `text-gray-500 dark:text-gray-300 ${bgHoverColor} ${textHoverColor}`,
        },
      },
      defaultVariants: {
        isSelected: false,
      },
    },
  );

  const getItemClass = useCallback(() => {
    return classNames?.listItem
      ? classNames.listItem({ isSelected })
      : itemClass({ isSelected });
  }, [bgColor, bgHoverColor, classNames, isSelected, textHoverColor]);

  return (
    <>
      {formatOptionLabel ? (
        <div onClick={(): void => handleValueChange(item)}>
          {formatOptionLabel({ ...item, isSelected })}
        </div>
      ) : (
        <>
          {item.disabled ? (
            <DisabledItem>{item.label.toString()}</DisabledItem>
          ) : (
            <div
              tabIndex={-1}
              aria-selected={isSelected}
              role={'option'}
              onClick={(): void => handleValueChange(item)}
              className={getItemClass()}
            >
              {item.icon?.name && (
                <Icon
                  icon={item.icon}
                  color={item.color || '#000'}
                  className={'mr-2 fa-fw'}
                />
              )}
              {!item.icon?.name && item.color && (
                <div
                  style={{ backgroundColor: item.color }}
                  className={
                    ' w-4 h-4 rounded-full mr-2 flex items-center justify-center'
                  }
                />
              )}
              {item.label}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Item;
