import type { Category } from '@smack/core/api/models/categories/Category';
import type { LinkGroup } from '@smack/core/api/models/categories/LinkGroup';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import type { RRuleInputValue } from '@smack/core/components/DataInput/RRuleInput';
import { FormEventCalendar } from '@smack/core/views/oldViewsToSort/Layouts/Forms/FormEventCalendar/FormEventCalendar';
import { useTranslation } from 'react-i18next';

interface IProps {
  addModalOpened: boolean;
  setAddModalOpened: (value: boolean) => void;
  initialRRule?: RRuleInputValue;
  category?: Category;
  onObjectCreated?: (data: { id: number; linkGroup?: LinkGroup }) => void;
  defaultValues?: Record<string, number>;
  parentCategoryId?: number;
  linkId?: number;
}

export const ModalBaseObjectQuickForm = (props: IProps): JSX.Element => {
  const {
    addModalOpened,
    setAddModalOpened,
    initialRRule,
    linkId,
    category,
    onObjectCreated,
    defaultValues,
    parentCategoryId,
  } = props;
  const [t] = useTranslation();
  return (
    <Modal
      icon={{ name: 'plus' }}
      color="#DBEAFE"
      iconColor="#60A5FA"
      title={t('baseobjectQuickForm.title')}
      open={addModalOpened}
      onClose={setAddModalOpened}
      noClickOutside={true}
    >
      <FormEventCalendar
        onClose={(): void => setAddModalOpened(false)}
        linkId={linkId}
        initialRRule={initialRRule}
        category={category}
        defaultValues={defaultValues}
        onObjectCreated={onObjectCreated}
        parentCategoryId={parentCategoryId}
      />
    </Modal>
  );
};
