import type MapLibreDraw from '@hyvilo/maplibre-gl-draw';
import type { IDisplayedSpatialFilterEntry } from '@smack/core/api/models/categories/DisplayedAttributeFilter';
import { Layer } from '@smack/core/api/models/maps/Layer';
import {
  getDefaultSort,
  toggleAttributeFilter,
  toggleVisualizationFilter,
} from '@smack/core/store/app/actions';
import type { IFilters } from '@smack/core/utils/Filters';
import { MapEventUtils } from '@smack/core/utils/MapEventUtils';
import type { Sort } from '@smack/core/utils/config/SortOptions';
import type { IMenuItem } from '@smack/core/views/oldViewsToSort/Layouts/Objects/MenuFilter/Components/Contextual';
import type { Feature, Position } from 'geojson';

export function isActive(itemId?: string, filters?: IFilters): boolean {
  if (!itemId || !filters) return false;
  if (!itemId.includes('=')) return false;
  const [type, value] = itemId.split('=');
  switch (type) {
    case 'attribute-filter': {
      const [category, attribute, choice] = value
        .split('-')
        .map((id) => Number.parseInt(id));
      return (
        filters.filteredAttributes?.[category]?.[attribute]?.includes(
          choice?.toString(),
        ) ?? false
      );
    }
    case 'show-geom':
      return filters.showGeom ?? false;
    case 'report-state':
      return filters.reportState ?? false;
    case 'notification-viewed':
      return filters.viewedNotifications ?? false;
    case 'period':
      return filters.period === value;
    case 'category':
      return filters.categories?.includes(value) ?? false;
    case 'visualize-category':
      return Object.keys(filters?.visualizedAttributes || {}).length === 0;
    case 'visualize-attribute': {
      const [categoryId, attributeId] = value
        .split('-')
        .map((id) => Number.parseInt(id));
      if (!categoryId || !attributeId) return false;
      return (
        filters.visualizedAttributes?.[categoryId] === attributeId.toString()
      );
    }
    case 'zone':
      if (value === 'draw') {
        return !!filters.draw;
      }
      if (value === 'screen') {
        return filters.screen ?? false;
      }
      return filters.filteringLayer === value;
    case 'sort':
      return value === filters.sort;
    default:
      return false;
  }
}

const toggleOnList = (
  active: boolean,
  list: string[] | undefined,
  id: string,
): string[] => {
  if (!list) return [];
  if (!active) {
    return list.filter((l) => l.toString() !== id.toString());
  }
  return [...list, id];
};

const registeredDrawEventListeners: ((
  draw:
    | MapLibreDraw.DrawUpdateEvent
    | MapLibreDraw.DrawCreateEvent
    | MapLibreDraw.DrawDeleteEvent,
) => void)[] = [];

const stopDrawing = (): void => {
  // Clear draws
  const { MainMap, DrawMode } = window.Hyvilo.Utils;
  if (DrawMode) {
    DrawMode?.deleteAll();
  }
  // Off callback
  registeredDrawEventListeners.splice(0).forEach((eventListener) => {
    MainMap?.off('draw.create', eventListener);
    MainMap?.off('draw.update', eventListener);
    MainMap?.off('draw.delete', eventListener);
  });
};

const startDrawing = (
  setFilter: (
    draw:
      | MapLibreDraw.DrawUpdateEvent
      | MapLibreDraw.DrawCreateEvent
      | MapLibreDraw.DrawDeleteEvent,
  ) => void,
): void => {
  const { MainMap, DrawMode } = window.Hyvilo.Utils;
  if (!DrawMode) return;
  // On draw
  // CLEAR OLD DRAW
  DrawMode.deleteAll();

  // Active drag draw mode
  DrawMode.changeMode('draw_polygon');
  MainMap?.on('draw.create', setFilter);
  MainMap?.on('draw.update', setFilter);
  MainMap?.on('draw.delete', setFilter);
  registeredDrawEventListeners.push(setFilter);
};

const selectFeatureForFiltering = (
  e: maplibregl.MapLayerEventType['click' | 'touchstart'],
  source: string,
  setFilters: (filters: IFilters) => void,
  filters: IFilters,
  sourceLayer?: string,
): void => {
  const featureId = MapEventUtils.selectFeature(e, source, sourceLayer);
  if (featureId) {
    setFilters({
      ...filters,
      containingFeatures: filters?.containingFeatures?.includes(
        featureId.toString(),
      )
        ? filters?.containingFeatures?.filter(
            (feat) => featureId.toString() !== feat.toString(),
          )
        : [...(filters?.containingFeatures || []), featureId.toString()],
    });
  }
};

export const showFilteringLayer = (
  active: boolean,
  setFilters: (filters: IFilters) => void,
  filters: IFilters,
  filteringLayer?: IDisplayedSpatialFilterEntry,
  activatedFeatures?: (number | string)[],
): void => {
  const { MainMap } = window.Hyvilo.Utils;
  if (!MainMap) return;
  const layerName = 'filteringlayer-filter';
  if (!active) {
    Layer.disableLayer(layerName);
  } else {
    Layer.disableLayer(layerName);
    if (filteringLayer?.layer) {
      filteringLayer?.layer.buildFeature(
        MainMap,
        layerName,
        (e) =>
          selectFeatureForFiltering(e, `${layerName}`, setFilters, filters),
        'filters',
      );
    }
    if (activatedFeatures?.length) {
      activatedFeatures.forEach((featureId) => {
        window.Hyvilo.Utils.MainMap?.setFeatureState(
          {
            source: `${layerName}`,
            id: featureId,
          },
          { selected: true },
        );
      });
    }
  }
};

/**
 * Update the map with the filtering layers
 * @param active state of the button after the user input
 * @param dispatch dispatch funtion to update the store
 * @param filteringLayer Layer to use as filter
 * @param hoveredId reference and setter for the hovered feature on the map
 */
const handleSpatialFilter = (
  active: boolean,
  filters: IFilters,
  setFilters: (filters: IFilters) => void,
  filteringLayer: IDisplayedSpatialFilterEntry,
): void => {
  if (!active) {
    /* Update Filters in the store */
    setFilters({
      filteringLayer: undefined,
      containingFeatures: [],
    });
  } else {
    /* Add the layer to filtersr in the store */
    setFilters({
      ...filters,
      filteringLayer: filteringLayer.id?.toString(),
      containingFeatures: [],
    });
  }
};

export function toggleFilter(
  item: IMenuItem,
  filters: IFilters,
  setFilters: (filters: IFilters) => void,
  payload?: boolean,
): void {
  const [type, value] = item.id.split('=');
  const shouldActivate = payload ?? !isActive(item.id, filters);
  switch (type) {
    case 'attribute-filter': {
      const [category, attribute, choice] = value
        .split('-')
        .map((id) => Number.parseInt(id));
      setFilters({
        ...filters,
        filteredAttributes: toggleAttributeFilter(
          filters.filteredAttributes,
          category,
          attribute,
          choice?.toString(),
        ),
      });
      break;
    }
    case 'show-geom':
      setFilters({ ...filters, showGeom: shouldActivate });
      break;
    case 'report-state':
      setFilters({ ...filters, reportState: shouldActivate });
      break;
    case 'notification-viewed':
      setFilters({ ...filters, viewedNotifications: shouldActivate });
      break;
    case 'period':
      if (!['TODAY', 'THIS_WEEK', 'THIS_MONTH', 'THIS_WEEKEND'].includes(value))
        return;
      if (shouldActivate) {
        setFilters({
          ...filters,
          period: value as IFilters['period'],
        });
      } else {
        setFilters({
          ...filters,
          period: undefined,
        });
      }
      break;
    case 'category':
      setFilters({
        ...filters,
        categories: toggleOnList(
          shouldActivate,
          filters.categories ?? [],
          value,
        ),
      });

      break;
    case 'visualize-category':
      setFilters({
        ...filters,
        visualizedAttributes: {},
      });
      break;
    case 'visualize-attribute': {
      const [categoryId, attributeId] = value
        .split('-')
        .map((id) => Number.parseInt(id));
      setFilters({
        ...filters,
        visualizedAttributes: toggleVisualizationFilter(
          filters?.visualizedAttributes,
          categoryId,
          attributeId,
        ),
      });
      break;
    }
    case 'zone':
      if (value === 'draw') {
        if (shouldActivate) {
          setFilters({
            ...filters,
            draw: true,
          });

          startDrawing((f) => {
            if (!f) return;

            const coordinates = f.features.map((feature: Feature) => {
              if (feature.geometry.type === 'Polygon') {
                return feature.geometry.coordinates;
              }
            });
            if (!coordinates) return;

            setFilters({
              ...filters,
              draw: {
                type: 'MultiPolygon',
                coordinates: coordinates as Position[][][],
              },
            });
          });
        } else {
          stopDrawing();
          setFilters({ ...filters, draw: false });
        }
      } else if (value === 'screen') {
        setFilters({
          ...filters,
          screen: shouldActivate,
        });
      } else {
        if (!item.filteringLayer) return;
        handleSpatialFilter(
          shouldActivate,
          filters,
          setFilters,
          item.filteringLayer,
        );
      }
      break;
    case 'sort':
      setFilters({
        ...filters,
        sort: shouldActivate ? (value as Sort) : getDefaultSort().sort,
      });
  }
}
