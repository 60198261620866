import type React from 'react';

interface IProps {
  actions?: React.ReactNode;
}

export const ActionsHeader: React.FC<IProps> = ({ actions }): JSX.Element => {
  return (
    <div className="w-full py-5 md:py-0 md:h-20 md:min-h-[80px] px-2 md:px-9 flex items-center justify-end flex-wrap gap-1">
      {actions}
    </div>
  );
};
