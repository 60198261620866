import './index.scss';

/**
 * Loading components whith a circle
 * @returns JSX.Element
 */
export const Loading = (): JSX.Element => {
  return (
    <div className="map-loading-active">
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};
