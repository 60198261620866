/**
 *
 * Layers Model
 */

import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import type WsCustomAction from '@smack/core/utils/WsCustomAction';
import type { Feature } from '@turf/helpers';
import type { GeoJsonProperties, Geometry } from 'geojson';
import type { GeoJSONSource, LayerSpecification } from 'maplibre-gl';
import {
  type ILayerConfiguration,
  LayerConfiguration,
} from '../LayerConfiguration';
import { type ILayerLegend, LayerLegend } from '../LayerLegend';
import { type ILayerOption, LayerOption } from '../LayerOption';

/**
 * Layer interface
 *
 * @export
 * @interface ILayer
 */
export interface ILayer {
  id: number;
  label: string;
  layerConfiguration: ILayerConfiguration;
  layerOptions?: ILayerOption[];
  layerLegends?: ILayerLegend[];
  isToggleable?: boolean;
  childrenLayers?: ILayer[];
}

/**
 * Layer class model
 *
 * @export
 * @class Layer
 */
export class Layer {
  id: number;

  label: string;

  layerConfiguration?: LayerConfiguration;

  layerOptions: LayerOption[] = [];

  layerLegends: LayerLegend[] = [];

  isToggleable?: boolean;

  childrenLayers: Layer[] = [];

  nextPage?: string;

  hoveredId?: string | number;

  constructor(data: ILayer) {
    this.id = data.id;
    this.label = data.label;
    this.isToggleable = data.isToggleable;
    if (data.layerConfiguration) {
      this.layerConfiguration = new LayerConfiguration(data.layerConfiguration);
    }

    if (data.layerLegends) {
      this.layerLegends = data.layerLegends.map((l) => new LayerLegend(l));
    }

    if (data.layerOptions) {
      this.layerOptions = data.layerOptions.map((l) => new LayerOption(l));
    }

    if (data.childrenLayers) {
      this.childrenLayers = data.childrenLayers.map(
        (layer) => new Layer(layer),
      );
    }
  }

  /**
   * return all children in a list
   *
   * @return {*}  {Layer[]}
   * @memberof Layer
   */
  getFlatFullChildren(): Layer[] {
    const layers: Layer[] = [this];
    let currentChild: Layer[] = [this];

    while (currentChild.length) {
      const newChilds: Layer[] = [];
      currentChild.forEach((layer) => {
        newChilds.push(...layer.childrenLayers);
      });
      layers.push(...newChilds);
      currentChild = newChilds;
    }
    return layers;
  }

  /**
   * get Layers from api
   *
   * @static
   * @return {*}  {Promise<Layer[]>}
   * @memberof Layer
   */
  static getLayers(): Promise<Layer[]> {
    return RESTClient.get<{ data: { results: ILayer[] } }>('/maps/layers').then(
      (res) => res.data.results.map((r) => new Layer(r)),
    );
  }

  buildFeature(
    defaultMap?: maplibregl.Map,
    sourceId: string = this.id.toString(),
    onClickFeature?: (
      feature: maplibregl.MapLayerEventType['click' | 'touchstart'],
    ) => void,
    availability: 'filters' | 'panel' = 'panel',
  ): void {
    const { MainMap } = window.Hyvilo.Utils;
    const map = defaultMap || MainMap;
    if (!map) return;

    const type = this.layerConfiguration?.dataType.toLowerCase();
    if (map?.getSource(sourceId)) return;
    const FC: GeoJSON.FeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };
    if (type === 'geojson') {
      map?.addSource(sourceId, { type, data: FC });
    } else if (type === 'raster' && this.layerConfiguration?.tileServer) {
      map?.addSource(sourceId, {
        type: type,
        tiles: [this.layerConfiguration?.tileServer],
        tileSize: 256,
      });
    } else if (type === 'vector' && this.layerConfiguration?.tileServer) {
      map?.addSource(sourceId, {
        type: type,
        tiles: [this.layerConfiguration?.tileServer],
      });
    }
    const layerOptions =
      this.layerOptions?.filter((option) => {
        if (availability === 'panel' && option.isAvailableInPanel) {
          return true;
        }
        if (availability === 'filters' && option.isAvailableInFilters) {
          return true;
        }
        return false;
      }) || [];
    if (!layerOptions.length) {
      layerOptions.push(...LayerOption.getDefaultLayerOption());
    }
    layerOptions.forEach((option) => {
      const newlayer = option.data;

      const lay: LayerSpecification & { source: string } = {
        ...newlayer,
        id: `${option.id}-${sourceId}-hyviloLayer`,
        source: sourceId,
      };
      map?.addLayer(lay, 'root-layers');
      window.Hyvilo.Utils.MainMap?.on(
        'click',
        `${option.id}-${sourceId}-hyviloLayer`,
        (e) => {
          if (onClickFeature) onClickFeature(e);
        },
      );
      window.Hyvilo.Utils.MainMap?.on(
        'touchstart',
        `${option.id}-${sourceId}-hyviloLayer`,
        (e) => {
          if (onClickFeature) onClickFeature(e);
        },
      );
      window.Hyvilo.Utils.MainMap?.on(
        'mousemove',
        `${option.id}-${sourceId}-hyviloLayer`,
        (e) => {
          if (!e.features?.length && !this.hoveredId) return;
          if (!e.features?.length && this.hoveredId) {
            MainMap?.setFeatureState(
              { source: sourceId, id: this.hoveredId },
              { hover: false },
            );
            this.hoveredId = undefined;
            return;
          }
          if (!e.features?.length) return;
          if (e.features[0].id !== this.hoveredId) {
            if (this.hoveredId) {
              MainMap?.setFeatureState(
                { source: sourceId, id: this.hoveredId },
                { hover: false },
              );
            }
            this.hoveredId = e.features[0].id;
            MainMap?.setFeatureState(
              { source: sourceId, id: this.hoveredId },
              { hover: true },
            );
          }
        },
      );
      window.Hyvilo.Utils.MainMap?.on(
        'mouseleave',
        `${option.id}-${sourceId}-hyviloLayer`,
        (e) => {
          if (!e.features?.length && this.hoveredId) {
            MainMap?.setFeatureState(
              { source: sourceId, id: this.hoveredId },
              { hover: false },
            );
          }
        },
      );
    });

    if (type === 'geojson') this.insertLayer(this.id, map, sourceId);

    /**
     * if layer is a real time update data
     */
    if (
      this.layerConfiguration?.wsUrl &&
      this.layerConfiguration?.wsCustomAction
    ) {
      import(
        `../../../../utils/WsCustomAction/${this.layerConfiguration.wsCustomAction}/index.tsx`
      ).then((res: { default: typeof WsCustomAction }) => {
        const customAction = new res.default(this, map);
        customAction.init();
      });
    }
  }

  async insertLayer(
    layerId: number,
    defaultmap?: maplibregl.Map,
    sourceId?: string,
  ): Promise<void> {
    this.nextPage = `${RESTClient.getApiUrlEntryPoint()}/maps/layer-features?layer=${layerId}&limit=500`;
    const { MainMap } = window.Hyvilo.Utils;
    const map = defaultmap || MainMap;
    const source = map?.getSource(`${sourceId || layerId}`) as GeoJSONSource;
    if (!source) return;
    const FC: GeoJSON.FeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };
    do {
      map?.fire('dataloading');
      await RESTClient.get<{ data: { results: Feature[]; next: string } }>(
        this.nextPage,
        undefined,
        true,
      ).then((res) => {
        res.data.results.forEach((feature) =>
          FC.features.push(feature as Feature<Geometry, GeoJsonProperties>),
        );
        this.nextPage = res.data.next;
        source.setData(FC);
      });
    } while (this.nextPage);
  }

  static disableLayer(sourceId: string, defaultMap?: maplibregl.Map): void {
    const { MainMap } = window.Hyvilo.Utils;
    const map = defaultMap || MainMap;
    const source = map?.getSource(sourceId) as GeoJSONSource;
    if (source) {
      const layers = map?.getStyle().layers as { source: string; id: string }[];
      layers?.forEach((layer) => {
        if (layer.source && layer.source === sourceId)
          map?.removeLayer(layer.id);
      });
      map?.removeSource(sourceId);
    }
  }
}
