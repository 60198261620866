import { User } from '@smack/core/api/models/users/User';
import type { IUser } from '@smack/core/store/users/types';

/**
 * Interface for notification state model
 *
 * @export
 * @interface INotificationState
 */
export interface INotificationState {
  id: number;
  description?: string;
  state: 'ACCEPTED' | 'REJECTED';
  createdAt: string;
  modifiedAt: string;
  user?: IUser;
}

/**
 * class for the notification state model
 *
 * @export
 * @class NotificationState
 */
export class NotificationState {
  id: number;

  description?: string;

  state: 'ACCEPTED' | 'REJECTED';

  user?: User;

  createdAt: Date;
  modifiedAt: Date;

  constructor(data: INotificationState) {
    this.id = data.id;
    this.description = data.description;
    this.state = data.state;
    this.createdAt = new Date(data.createdAt);
    this.modifiedAt = new Date(data.modifiedAt);
    if (data.user) {
      this.user = new User(data.user);
    }
  }
}
