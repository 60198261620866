import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import { Comment, type ICommentDataInput } from './Comment';
import type { IComment } from './Comment/type';

export class CommentsManager<I = ICommentDataInput> {
  instanceAPIPath: string;

  constructor(instanceAPIPath: string) {
    this.instanceAPIPath = instanceAPIPath;
  }

  /**
   * Fetch all Comments From task Id
   * @param id number
   * @returns Promise<Comment[]>
   */
  getComments(): Promise<Comment[]> {
    return RESTClient.get<{ data: { results: IComment[] } }>(
      `${this.instanceAPIPath}/comments`,
    ).then((res) => res?.data?.results.map((r) => new Comment(r)));
  }

  /**
   * create a comment for a tasks and/or project
   * @param data {
   *  comment: string (the content of the comment),
   *  project-link?: boolean, if the comment should be linked to the project
   * }
   * @returns Promise<Comment>
   */
  createComment(data: I): Promise<Comment> {
    return RESTClient.post<{ results: IComment }>(
      data,
      `${this.instanceAPIPath}/comments`,
    ).then((res) => new Comment(res.data.results));
  }
}
