import type { CustomersPreferences } from '@smack/core/api/models/users/User/UserPreferences/UserPreferences';
import { usePreferencesManager } from '@smack/core/hooks/preferences/usePreferencesManager/usePreferencesManager';
import React, { useCallback } from 'react';

type OutputUseCustomersPreferences = [
  CustomersPreferences | undefined,
  (pref: CustomersPreferences) => void,
];

export const useCustomerPreferences = (): OutputUseCustomersPreferences => {
  const preferencesManager = usePreferencesManager();
  const [, rerender] = React.useState({});

  const customerPreferences = preferencesManager?.getCustomersPreferences();

  const setPreferences = useCallback(
    (pref: CustomersPreferences) => {
      preferencesManager?.setCustomersPreferences(pref);
      rerender({});
    },

    [preferencesManager],
  );

  return [customerPreferences, setPreferences];
};
