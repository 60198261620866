import useResizeObserver from '@react-hook/resize-observer';
import {
  type MutableRefObject,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

interface ElementSize {
  width: number;
  height: number;
}

export function useElementSize<T extends HTMLElement>(): [
  MutableRefObject<T | null>,
  ElementSize,
] {
  const target = useRef<T | null>(null);
  const [size, setSize] = useState<ElementSize>({
    width: 0,
    height: 0,
  });

  useLayoutEffect(() => {
    if (!target.current) return;
    setSize(target.current.getBoundingClientRect());
  }, [target.current]);

  useResizeObserver(target, (entry) => {
    const { inlineSize: width, blockSize: height } = entry.contentBoxSize[0];
    setSize({ width, height });
  });

  return [target, size];
}
