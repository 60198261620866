import './index.scss';

import type { IconName } from '@fortawesome/fontawesome-common-types';
import { Transition } from '@headlessui/react';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import i18next from 'i18next';
import React from 'react';
import { Toaster, resolveValue, toast, useToasterStore } from 'react-hot-toast';

export const CustomToaster: React.FC<Record<never, never>> = () => {
  const store = useToasterStore();

  return (
    <Toaster
      position="top-right"
      toastOptions={{
        duration: 5000, // 5 seconds
      }}
    >
      {(t): JSX.Element => {
        const resolvedValue = resolveValue(t.message, t);
        let icon: IconName | undefined = undefined;
        if (t.icon && typeof t.icon === 'string') {
          icon = t.icon as IconName; // Toast icons strings should be a valid FontAwesome name!
        } else if (t.type === 'success') {
          icon = 'check-circle';
        } else if (t.type === 'error') {
          icon = 'times-circle';
        }
        return (
          <Transition
            appear
            show={t.visible}
            as={React.Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={`pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 ${
                t.className || ''
              }`}
              {...t.ariaProps}
              style={t.style}
            >
              <div className="p-4">
                <div className="flex items-start text-gray-700">
                  {icon || t.icon ? (
                    <div className="flex-shrink-0 self-center">
                      {icon ? (
                        <Icon
                          icon={{ name: icon }}
                          color={t.iconTheme?.primary || undefined}
                        />
                      ) : (
                        t.icon
                      )}
                    </div>
                  ) : null}
                  <div className="ml-3 w-0 flex-1">
                    {typeof resolvedValue === 'string' ? (
                      <p className="text-sm">{resolvedValue}</p>
                    ) : (
                      resolvedValue
                    )}
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-transparent text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={(): void => toast.dismiss(t.id)}
                      style={{ color: t.iconTheme?.secondary || undefined }}
                    >
                      <span className="sr-only">
                        {i18next.t('notification.close')}
                      </span>
                      <Icon icon={{ name: 'times-circle' }} />
                    </button>
                  </div>
                </div>
              </div>
              {t.duration ? (
                <div className="w-full h-2">
                  <div
                    className="bg-menu-active w-0 h-full"
                    style={{
                      animation: `contract linear ${t.duration / 1000 || 2}s`,
                      animationPlayState: store.pausedAt ? 'paused' : 'running',
                    }}
                  />
                </div>
              ) : null}
            </div>
          </Transition>
        );
      }}
    </Toaster>
  );
};
