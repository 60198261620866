export interface ObjectsState {
  selectedCalendarLinkGroups?: { [baseobjectId: number]: number[] };
}

// Actions Constants
export const TOGGLE_CALENDAR_LINK_GROUPS =
  'OBJECTS::TOGGLE_CALENDAR_LINK_GROUPS';
export const SET_CALENDAR_LINK_GROUPS = 'OBJECTS::SET_CALENDAR_LINK_GROUPS';

// Actions Definitions
export interface IToggleCalendarLinkGroups {
  type: typeof TOGGLE_CALENDAR_LINK_GROUPS;
  payload: { baseobjectId: number; linkGroupId: number };
}

export interface ISetCalendarLinkGroups {
  type: typeof SET_CALENDAR_LINK_GROUPS;
  payload: { baseobjectId: number; linkGroupIds: number[] };
}

export type ObjectsActionsType =
  | ISetCalendarLinkGroups
  | IToggleCalendarLinkGroups;
