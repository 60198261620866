import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import React from 'react';

export interface ITextAreaInputProps
  extends Omit<React.HTMLProps<HTMLTextAreaElement>, 'onChange'> {
  native?: never;
  value?: string;
  label?: string;
  rows?: number;
  cols?: number;
  onChange?: (value: string) => void;
  className?: string;
  error?: string;
}

export interface INativeTextAreaInputProps
  extends Omit<ITextAreaInputProps, 'onChange' | 'native'> {
  native: true;
  onChange: React.HTMLProps<HTMLTextAreaElement>['onChange'];
}

const TextAreaInputRender: React.ForwardRefRenderFunction<
  HTMLTextAreaElement,
  ITextAreaInputProps | INativeTextAreaInputProps
> = (
  {
    native,
    value,
    onChange,
    label,
    rows,
    cols,
    error,
    className,
    ...htmlProps
  },
  ref,
): JSX.Element => {
  const handleChange = React.useCallback<
    React.ChangeEventHandler<HTMLTextAreaElement>
  >(
    (e) => {
      if (onChange) {
        if (native) onChange(e);
        else onChange(e.target.value);
      }
    },
    [onChange, native],
  );

  return (
    <div>
      {label && (
        <label
          htmlFor={htmlProps.id}
          className="block text-sm font-medium text-gray-700 dark:text-gray-200"
        >
          {label}
        </label>
      )}
      <div className={`mt-1 relative w-full sm:max-w-md ${className || ''}`}>
        <textarea
          ref={ref}
          rows={rows ?? 5}
          cols={cols ?? 40}
          onChange={handleChange}
          value={value}
          name="comment"
          className={`shadow-sm resize focus:ring-blue-400 focus:border-blue-400 bg-primary dark:bg-view text-text block w-full max-w-full sm:text-sm ${
            error ? 'border-red-600' : 'border-gray-300 dark:border-neutral-500'
          } rounded-md`}
          {...htmlProps}
        />
        {/* show the error */}
        {error && (
          <Icon
            className="text-red-600 absolute top-3 right-4 "
            icon={{ name: 'circle-exclamation' }}
          />
        )}
        {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
      </div>
    </div>
  );
};

TextAreaInputRender.displayName = 'TextAreaInput';

export const TextAreaInput = React.forwardRef(TextAreaInputRender);
