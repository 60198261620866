import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import type { IChecklistItemWithChecklist } from '@smack/core/api/models/tasks/ChecklistItem';
import { type ILog, Log } from '../../logs/Log';

export interface IChecklistItemLogContent {
  id: number;
  checklistItem?: IChecklistItemWithChecklist;
  checklistItemField?: string;
  checklistItemFieldOldValue?: string;
  checklistItemFieldNewValue?: string;
}

export interface IChecklistItemLog extends ILog {
  content?: IChecklistItemLogContent;
}

export class ChecklistItemLog extends Log {
  content?: IChecklistItemLogContent;

  constructor(data: IChecklistItemLog) {
    super(data);
    this.content = data.content;
  }

  static getChecklistItemLogs(
    checklistItemId?: number,
    checklistId?: number,
  ): Promise<ChecklistItemLog[]> {
    return RESTClient.get<{ data: { results: IChecklistItemLog[] } }>(
      '/tasks/checklist-item-logs',
      {
        'checklist-item-in[]': checklistItemId
          ? checklistItemId.toString()
          : undefined,
        'checklist-in[]': checklistId ? checklistId.toString() : undefined,
      },
    ).then((res) => res?.data?.results.map((r) => new ChecklistItemLog(r)));
  }
}
