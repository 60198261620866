import type { ViewSection } from '@smack/core/api/models/views/ViewSection/ViewSection';
import type { ReactNode } from 'react';

export interface INavigationTabs<T = unknown> {
  label: string;
  id: number;
  icon?: React.FC;
  views: T[];
}

interface IProps {
  navigation: ViewSection[];
  activeTab: ViewSection | undefined;
  setActiveTab: (item: ViewSection) => void;
  children: ReactNode;
}

export const Navigation = (props: IProps): JSX.Element => {
  const { navigation, activeTab, setActiveTab, children } = props;

  return (
    <div className="w-full h-full flex flex-col">
      <div
        className="w-full h-12 flex items-center border-b border-border dark:border-neutral-500"
        style={{ minHeight: '45px' }}
      >
        {navigation.map((nav: ViewSection) => (
          <div
            data-testid={`navigation-tab-${nav.id}`}
            key={nav.id}
            onClick={() => {
              setActiveTab(nav);
            }}
            className={`cursor-pointer mx-3 text-[14px] border-b-2 ${
              activeTab?.id === nav.id
                ? 'text-blue-400 font-medium border-blue-400'
                : 'text-gray-500 font-medium border-transparent'
            }`}
          >
            {nav.label}
          </div>
        ))}
      </div>
      <div
        data-testid={`section-content-${activeTab?.id}`}
        className="w-full h-full flex-grow flex flex-col"
      >
        {children}
      </div>
    </div>
  );
};
