import type { Group } from '@smack/core/api/models/tasks/Group';
import type { IStatus } from '@smack/core/api/models/tasks/Status';
import { Task } from '@smack/core/api/models/tasks/Task';
import { SaveButton } from '@smack/core/components/Actions/Buttons/Button';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { CheckboxInput } from '@smack/core/components/DataInput/CheckboxInput';
import { DateInput } from '@smack/core/components/DataInput/DateInput';
import { Input } from '@smack/core/components/DataInput/Input/Input';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import { TextAreaInput } from '@smack/core/components/DataInput/TextAreaInput';
import { useNonInitialEffect } from '@smack/core/utils/NonInitialEffect';
import { useTasksContext } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Context';
import React from 'react';
import {
  type FieldError,
  type SubmitHandler,
  useController,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<boolean>;
  group: Group;
  task?: Task;
  reloadProject: () => void;
}

interface IFormValues {
  label: string;
  description: string;
  deadlineAt: string | null;
  isMilestone: boolean;
  position?: Option | null;
  status?: Option | null;
}

const statusToOption = (mappedStatus: IStatus): Option => ({
  value: mappedStatus.id,
  label: mappedStatus.label || mappedStatus.id,
  color: mappedStatus.color,
  icon: mappedStatus.icon,
});

export const TaskCreateEditModal: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  group,
  task,
  reloadProject,
}) => {
  const isEdit = !!task;

  const { t } = useTranslation();

  const { register, handleSubmit, formState, reset, control } =
    useForm<IFormValues>();

  const resetForm = (): void => {
    reset({
      label: task?.label || '',
      description: task?.description || '',
      status: task?.status ? statusToOption(task?.status) : undefined,
      deadlineAt: task?.deadlineAt?.toISOString() || '',
      position: undefined,
      isMilestone: task?.isMilestone || false,
    });
  };

  const { field: statusField } = useController({
    control,
    name: 'status',
    defaultValue: null,
    rules: {
      required: {
        value: !isEdit,
        message: t('formValidation.required'),
      },
    },
  });

  const { field: deadlineField } = useController({
    control,
    defaultValue: null,
    name: 'deadlineAt',
  });

  const { field: positionField } = useController({
    name: 'position',
    defaultValue: null,
    control,
  });

  const { field: isMilestoneField } = useController({
    name: 'isMilestone',
    defaultValue: false,
    control,
  });

  const { statuses = [] } = useTasksContext();

  const statusOptions = React.useMemo(
    () => statuses.map(statusToOption),
    [statuses],
  );

  const positionOptions = React.useMemo(
    () => [
      {
        value: -1,
        label: t('formInputLabels.firstPlace'),
      },
      ...(group.tasks
        ?.filter((filteredTask) => filteredTask.id !== task?.id)
        .map<Option>((mappedTask) => ({
          value: mappedTask.position || 1,
          label: mappedTask.label || mappedTask.id,
        })) || []),
    ],
    [group],
  );

  const onSubmit = React.useCallback<SubmitHandler<IFormValues>>(
    async (values) => {
      const position = values.position
        ? (values.position.value as number) + 1
        : undefined;
      const data = {
        ...values,
        deadlineAt: values.deadlineAt || undefined,
        position,
        status: values.status?.value || undefined,
      };

      if (isEdit) {
        await task?.patch(data);
      } else {
        if (typeof data.status !== 'number') return;
        await Task.createTask(
          group.id,
          data.status,
          data.label,
          data.position,
          data.isMilestone,
          data.description,
          data.deadlineAt,
        );
        resetForm();
      }

      reloadProject();
      setIsOpen(false);
    },
    [],
  );

  React.useEffect(() => {
    resetForm();
  }, [task]);

  useNonInitialEffect(() => {
    if (isOpen) reset();
  }, [isOpen]);

  const id = task?.id || 'create';

  return (
    <Modal
      open={isOpen}
      onClose={setIsOpen}
      icon={{ name: isEdit ? 'pen' : 'plus' }}
      title={t(isEdit ? 'edit.task' : 'create.task')}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3.5">
        <Input
          native
          id={`task-${id}`}
          label={t('formInputLabels.name')}
          error={formState.errors.label?.message}
          {...register('label', {
            required: {
              value: true,
              message: t('formValidation.required'),
            },
          })}
        />
        {statuses.length ? (
          <SelectInput
            id={`task-status-${id}`}
            label={t('formInputLabels.status')}
            options={statusOptions}
            onChange={statusField.onChange}
            value={statusField.value}
            error={(formState.errors.status as FieldError | undefined)?.message}
          />
        ) : null}
        <DateInput
          id={`task-deadline-${id}`}
          label={t('formInputLabels.deadline')}
          onChange={deadlineField.onChange}
          onBlur={deadlineField.onBlur}
          value={deadlineField.value ?? undefined}
        />
        <TextAreaInput
          native
          id={`task-description-${id}`}
          label={t('formInputLabels.description')}
          error={formState.errors.description?.message}
          {...register('description')}
        />
        {positionOptions.length ? (
          <SelectInput
            id={`task-position-${id}`}
            label={t('formInputLabels.placeAfter')}
            options={positionOptions}
            onChange={positionField.onChange}
            value={positionField.value}
          />
        ) : null}
        <CheckboxInput
          id={`task-is-milestone-${id}`}
          label={t('formInputLabels.milestone')}
          onChange={isMilestoneField.onChange}
          value={isMilestoneField.value}
        />
        <div className="flex items-center justify-end">
          <SaveButton type="submit" data-testid="TaskEditModalCreateButton" />
        </div>
      </form>
    </Modal>
  );
};
