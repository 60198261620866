import {
  LinkGroup,
  PopulationType,
} from '@smack/core/api/models/categories/LinkGroup';
import { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { IconRoundedButton } from '@smack/core/components/Actions/Buttons/IconRoundedButton/IconRoundedButton';
import { ListElementArrayView } from '@smack/core/components/DataDisplay/Lists/ListElementsArrayView';
import { ParentListElementsArrayView } from '@smack/core/components/DataDisplay/Lists/ParentListElementsArrayView/ParentListElementsArrayView';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { ModalBarLoader } from '@smack/core/components/DataDisplay/Modals/ModalBarLoader/ModalBarLoader';
import type { IFilters } from '@smack/core/utils/Filters';
import { ListSkeleton, LoaderSkeleton } from '@smack/core/utils/Loader';
import { ListElementArrayViewSkeleton } from '@smack/core/utils/Loader/Skeletons/ListElementArrayViewSkeleton';
import type { IAddLinkFormOutput } from '@smack/core/views/oldViewsToSort/Layouts/Forms/AddLinks/AddLinkForm/AddLinkForm';
import { AddLinks } from '@smack/core/views/oldViewsToSort/Layouts/Forms/AddLinks/AddLinks';
import { UnlinkBaseObjectList } from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/BatchLinkForm/Components/UnlinkBaseObjectsList/UnlinkBaseObjectList';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface BatchLinkFormProps {
  categoryId: number;
  filters?: IFilters;
}

export const BatchLinkForm: React.FC<BatchLinkFormProps> = ({
  categoryId,
  filters,
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [linkGroups, setLinkGroups] = React.useState<LinkGroup[]>([]);
  const [hiddenGroups, setHiddenGroups] = React.useState<number[]>([]);
  const [addLinkGroup, setAddLinkGroup] = React.useState<LinkGroup>();
  const [removeLinkGroup, setRemoveLinkGroup] = React.useState<LinkGroup>();
  const [isSubmissionLoading, setSubmissionLoading] = React.useState(false);
  const [isProgressOpen, setProgressOpen] = React.useState(false);
  const [t] = useTranslation();

  const onSubmitAddLink = (addLinkOutput: IAddLinkFormOutput) => {
    setSubmissionLoading(true);
    setProgressOpen(true);
    const addLinks: Record<string, unknown>[] = [];

    for (const object of addLinkOutput.baseObjects) {
      addLinks.push({
        linkGroupId: addLinkGroup?.id,
        datetimeRange: addLinkOutput.datetimeRange,
        weight: addLinkOutput.weight,
        targetBaseobjectId: object.id,
      });
    }
    BaseObject.patchBaseObjectBatch(
      categoryId,
      {
        addLinks: addLinks,
      },
      filters,
    ).then(() => {
      setSubmissionLoading(false);
      setAddLinkGroup(undefined);
      setTimeout(() => {
        setProgressOpen(false);
      }, 3000);
    });
  };

  const onSubmitRemoveLink = (object: BaseObject) => {
    setSubmissionLoading(true);
    setProgressOpen(true);
    BaseObject.patchBaseObjectBatch(
      categoryId,
      {
        removeLinks: [
          {
            linkGroupId: removeLinkGroup?.id,
            targetBaseobjectId: object.id,
          },
        ],
      },
      filters,
    ).then(() => {
      setSubmissionLoading(false);
      setRemoveLinkGroup(undefined);
      setTimeout(() => {
        setProgressOpen(false);
      }, 3000);
    });
  };

  React.useEffect(() => {
    setLoading(true);
    LinkGroup.getLinkGroupsFromCategoryId(categoryId)
      .then((groups) =>
        setLinkGroups(
          groups.filter((group) => {
            if (group.populationType === PopulationType.FLOOR) return false;
            return (
              group.children?.length &&
              group.children.some(
                (sub) => sub.populationType === PopulationType.EXPLICIT_LINK,
              )
            );
          }),
        ),
      )
      .finally(() => setLoading(false));
  }, [categoryId]);

  return (
    <div data-testid="batch-link-form" className="flex flex-grow flex-col ">
      <ModalBarLoader
        icon={{ name: 'check' }}
        title={t('batchUpdateForm.progressTitle')}
        text={
          isSubmissionLoading
            ? t('batchUpdateForm.loading')
            : t('batchUpdateForm.successfulSubmission')
        }
        progressBarProps={{
          color: isSubmissionLoading ? undefined : 'green',
          animated: isSubmissionLoading,
        }}
        open={isProgressOpen}
        onClose={setProgressOpen}
      />

      {addLinkGroup && (
        <AddLinks
          open={!!addLinkGroup}
          onClose={() => setAddLinkGroup(undefined)}
          linkGroup={addLinkGroup}
          onSubmit={onSubmitAddLink}
        />
      )}

      <Modal
        icon={{ name: 'link-slash' }}
        title={t('batchLinkForm.removeLink.title')}
        open={!!removeLinkGroup}
        onClose={() => setRemoveLinkGroup(undefined)}
      >
        {!!removeLinkGroup && (
          <UnlinkBaseObjectList
            linkGroup={removeLinkGroup}
            categoryId={categoryId}
            onSubmit={onSubmitRemoveLink}
            onQuit={() => setRemoveLinkGroup(undefined)}
            filters={filters}
          />
        )}
      </Modal>
      {loading ? (
        <LoaderSkeleton width="500" style={{ width: '30%' }}>
          <ListSkeleton
            iterations={20}
            component={ListElementArrayViewSkeleton}
          />
        </LoaderSkeleton>
      ) : (
        linkGroups.map((linkGroup) => {
          return (
            <div key={linkGroup.id} className={' transition-all ease-linear '}>
              <ParentListElementsArrayView
                onClick={(): void => {
                  if (hiddenGroups.includes(linkGroup.id)) {
                    setHiddenGroups([
                      ...hiddenGroups.filter(
                        (groupId) => groupId !== linkGroup.id,
                      ),
                    ]);
                  } else {
                    setHiddenGroups([...hiddenGroups, linkGroup.id]);
                  }
                }}
                icon={linkGroup.icon}
                title={linkGroup.label ?? ''}
                open={!hiddenGroups.includes(linkGroup.id)}
              />

              <div
                className={`transition-height ease-linear overflow-hidden  ${
                  !hiddenGroups.includes(linkGroup.id) ? 'h-auto' : 'h-0'
                }`}
              >
                {linkGroup.children
                  ?.filter(
                    (g) => g.populationType === PopulationType.EXPLICIT_LINK,
                  )
                  ?.map((subLinkGroup) => {
                    return (
                      <ListElementArrayView
                        id={subLinkGroup.id}
                        key={subLinkGroup.id}
                        icon={subLinkGroup.icon}
                        title={subLinkGroup.label}
                        color={subLinkGroup.color}
                        nested
                      >
                        <div
                          className={
                            'flex-grow w-full h-full flex justify-between items-center'
                          }
                        >
                          <div className={'flex  items-center gap-3'}>
                            <IconRoundedButton
                              onClick={() => {
                                setRemoveLinkGroup(subLinkGroup);
                              }}
                              icon={{ name: 'link-slash' }}
                              title={t('batchLinkForm.removeLink.title')}
                            />
                            <IconRoundedButton
                              onClick={() => {
                                setAddLinkGroup(subLinkGroup);
                              }}
                              icon={{ name: 'plus' }}
                              title={t('addLinkForm.title')}
                            />
                          </div>
                        </div>
                      </ListElementArrayView>
                    );
                  })}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};
