import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import type React from 'react';

interface IProps {
  open?: boolean;
  onClick?: () => void;
  icon?: IconField;
  title: string;
}

export const ParentListElementsArrayView: React.FC<IProps> = ({
  title,
  open,
  icon,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className="h-16 flex items-center   px-4 justify-between cursor-pointer border-b border-neutral-300 dark:border-neutral-600"
    >
      <div className="flex items-center">
        <Icon icon={icon} className="text-lg dark:text-white" />
        <p className=" ml-3 dark:text-white">{title}</p>
      </div>
      <Icon
        icon={{ name: 'chevron-down' }}
        className={`transition-transform ease-linear ${
          !open ? 'rotate-180' : ''
        }`}
      />
    </div>
  );
};
