import { attributeFieldsByType } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeFieldsByType/AttributeFieldsByType';
import { AttributeSubmissionByType } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeFieldsByType/AttributeSubmissionByType';
import { DetailSubmissionType } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeFieldsByType/type';
import type { ViewElementRendererProps } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/type';
import type React from 'react';
import { type FC, useMemo } from 'react';

export const AttributeViewElementRenderer: FC<ViewElementRendererProps> = ({
  viewElement,
  props,
}) => {
  const attribute = useMemo(() => viewElement.attribute, [viewElement]);

  const fieldType = useMemo(() => {
    return attributeFieldsByType.find((a) => a.type === viewElement.fieldType);
  }, [viewElement]);

  if (!attribute || !fieldType) return null;

  const render = () => {
    if (
      props.standaloneField &&
      props.baseObject &&
      fieldType.detailSubmissionType !== DetailSubmissionType.None
    ) {
      return (
        <AttributeSubmissionByType
          attribute={attribute}
          baseobjectId={props.baseObject.id}
          type={fieldType.detailSubmissionType}
          valueToAttributeValue={fieldType.valueToAttributeValue}
          fieldProps={props}
          value={fieldType.getValueForFormField(attribute, viewElement.values)}
          onSubmission={props.standaloneField?.onSubmission}
        >
          {(control): React.ReactElement =>
            fieldType.render(attribute, viewElement.values, {
              ...props,
              controllerProps: {
                value: control.value,
                name: control.name,
                onChange: control.onChange,
              },
            }) as React.ReactElement
          }
        </AttributeSubmissionByType>
      );
    }

    return fieldType.render(attribute, viewElement.values, props);
  };

  return render();
};
