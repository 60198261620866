import type { ViewElementRendererProps } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/type';
import { ObjectProjectsCard } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Links/Components/ObjectProjectsCard/ObjectProjectsCard';
import { type FC, useMemo } from 'react';

export const ObjectProjectsViewElementRenderer: FC<
  ViewElementRendererProps
> = ({ viewElement, props }) => {
  const tplProjectId = useMemo(() => viewElement.tplProjectId, [viewElement]);
  const baseobject = useMemo(() => props.baseObject, [props]);

  if (!tplProjectId || !baseobject) return null;

  return (
    <ObjectProjectsCard baseObject={baseobject} tplProjectId={tplProjectId} />
  );
};
