import { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import { DataFieldWrapper } from '@smack/core/components/DataDisplay/DataFieldWrapper';
import { DataSectionTitle } from '@smack/core/components/DataDisplay/DataSectionTitle';
import { NoContentMessage } from '@smack/core/components/DataDisplay/NoContentMessage';
import type { ViewLayoutComponent } from '@smack/core/components/ViewRenderer/interfaces';
import { useTranslation } from 'react-i18next';

export const BaseObjectFormLayout: ViewLayoutComponent = ({
  renderedSections,
}) => {
  return (
    <div className="flex flex-col gap-3 flex-grow">
      {renderedSections.map(([viewSection, viewElements]) => (
        <section key={viewSection.id} className="px-3">
          <DataSectionTitle
            title={viewSection.label}
            icon={viewSection.icon}
            className={viewSection.isHeadless ? 'sr-only' : undefined}
          />
          <div>
            {viewElements.map(([viewElement, node]) => (
              <DataFieldWrapper
                label={
                  <>
                    {viewElement.label ?? ''}
                    {viewElement.required ? (
                      <span className="text-red-700"> *</span>
                    ) : null}
                  </>
                }
                key={viewElement.id}
                helpTooltip={viewElement.helpText}
              >
                {node}
              </DataFieldWrapper>
            ))}
          </div>
        </section>
      ))}
    </div>
  );
};

BaseObjectFormLayout.Error = ({ viewUsage }) => {
  const [t] = useTranslation();

  return (
    <div className={'p-5 pb-6 w-full'}>
      <NoContentMessage
        icon={{ name: 'list-dropdown' }}
        label={t(
          viewUsage === ViewUsage.QUICK_FORM
            ? 'formErrors.noQuickFormAvailable'
            : 'formErrors.noFormAvailable',
        )}
      />
    </div>
  );
};
