import type { Task } from '@smack/core/api/models/tasks/Task';
import { TaskLog } from '@smack/core/api/models/tasks/TaskLog';
import { AddButton } from '@smack/core/components/Actions/Buttons/Button';
import { IconRoundedButton } from '@smack/core/components/Actions/Buttons/IconRoundedButton/IconRoundedButton';
import { DropDown } from '@smack/core/components/DataDisplay/DropDowns/DropDown';
import { CheckboxInput } from '@smack/core/components/DataInput/CheckboxInput';
import { CommentForm } from '@smack/core/views/oldViewsToSort/Layouts/Forms/CommentForm/CommentForm';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { ICommentDataInput } from '@smack/core/api/models/comments/Comment';
import { LogsDispatcher } from '@smack/core/components/DataDisplay/Feeds/LogsFeed/LogsDispatcher/LogsDispatcher';
import { ShowIfAllowed } from '@smack/core/components/DataDisplay/ShowIfAllowed';
import { useCommentsLogs } from '@smack/core/hooks/useCommentsLogs/useCommentsLogs';
import { useIsMobile } from '@smack/core/utils';
import { TableBlock } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/TaskTable/Components/TableBlock';
import { useTasksContext } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Context';

interface IProps {
  task: Task;
}

/**
 * Comments components for tasks table
 * @param props IProps
 * @returns JSX.Element
 */
export const Comments = (props: IProps): JSX.Element => {
  const { project } = useTasksContext();
  const { task } = props;
  const [t] = useTranslation();
  const [creation, setCreation] = React.useState(false);
  const [showComments, setShowComments] = React.useState(
    project?.isDisplayingCommentsByDefault ?? true,
  );
  const [showLogs, setShowLogs] = React.useState(
    project?.isDisplayingLogsByDefault ?? true,
  );
  const collapseCommentsByDefault =
    project?.isDisplayingCommentsByDefault === false &&
    project?.isDisplayingLogsByDefault === false;

  const toggleCreation = React.useCallback(
    () => setCreation((creationState) => !creationState),
    [],
  );

  /**
   * fetch all comment from the task id
   */
  const fetchComments = React.useCallback(
    () => task.commentsManager.getComments(),
    [task.id],
  );

  /**
   * Fetches the task logs for the current task.
   */
  const fetchTaskLogs = React.useCallback(
    () => TaskLog.getTaskLogsRecursively(task),
    [task],
  );

  const [nodes, , reload] = useCommentsLogs({
    getComments: fetchComments,
    getLogs: fetchTaskLogs,
    showComments,
    setShowComments,
    showLogs,
    setShowLogs,
    LogsComponent: LogsDispatcher,
  });

  const onCommentSubmit = React.useCallback(
    (data: ICommentDataInput): Promise<void> =>
      task.commentsManager.createComment(data).then(() => {
        reload({ comments: true, logs: false });
        setShowComments(true);
      }),
    [reload],
  );

  const commentButtonContainerRef = React.useRef<HTMLDivElement>(null);

  const isMobile = useIsMobile();

  return (
    <TableBlock
      title={t('feed.processAndComments')}
      icon={{ name: 'comments' }}
      collapsable
      defaultOpenState={collapseCommentsByDefault || !isMobile}
      actionButton={
        <DropDown
          menuItems={[
            <CheckboxInput
              key="comments"
              label={t('feed.showComments')}
              name={`task-${task.id}-show-comments`}
              className={{ container: 'px-2 py-1' }}
              value={showComments}
              onChange={setShowComments}
            />,
            <CheckboxInput
              key="logs"
              label={t('feed.showLogs')}
              name={`task-${task.id}-show-logs`}
              className={{ container: 'px-2 py-1' }}
              value={showLogs}
              onChange={setShowLogs}
            />,
          ]}
          placement="bottom-end"
        >
          <IconRoundedButton
            icon={{ name: 'filter' }}
            title={t('feed.filter')}
            onClick={(): void => {}}
            position="bottom"
            className="ml-3"
          />
        </DropDown>
      }
    >
      <div className="w-full h-full flex flex-col">
        <div className="w-full flex-shrink">
          <div className="flex h-fit max-h-full flex-col-reverse overflow-y-scroll">
            {nodes}
          </div>
        </div>
        <div className="w-full rounded-lg mx-auto my-0 py-2">
          <ShowIfAllowed>
            <div
              ref={commentButtonContainerRef}
              className="flex justify-center gap-1"
            >
              <AddButton onClick={toggleCreation}>
                {t('comments.add')}
              </AddButton>
              <CommentForm
                onQuit={toggleCreation}
                onSubmit={onCommentSubmit}
                open={creation}
                floatingReference={
                  commentButtonContainerRef?.current ?? undefined
                }
                style="float"
              />
            </div>
          </ShowIfAllowed>
        </div>
      </div>
    </TableBlock>
  );
};
