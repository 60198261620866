import { useOnScreen } from '@smack/core/hooks/useOnScreen';
import { BaseObjectSkeleton, LoaderSkeleton } from '@smack/core/utils/Loader';
import React from 'react';

export const EmitterLoading = (props: {
  onScreen: () => void;
}): JSX.Element => {
  const { onScreen } = props;
  const [hascall, setHasCall] = React.useState<boolean>(false);
  const ref = React.useRef<HTMLDivElement | null>(null);
  const isOnScreen = useOnScreen(ref);
  React.useEffect(() => {
    if (isOnScreen && !hascall) {
      onScreen();
      setHasCall(true);
    }
  }, [isOnScreen]);
  if (hascall) {
    return (
      <div className="h-full w-full flex flex-col items-center justify-center text-gray-500 hover:text-gray-700">
        <LoaderSkeleton height={80} width="100%">
          <BaseObjectSkeleton />
        </LoaderSkeleton>
      </div>
    );
  }
  return <div ref={ref} />;
};
