import {
  type IViewElement,
  ViewElement,
} from '@smack/core/api/models/views/ViewElement/ViewElement';

export type ListBlockDisplayApiOutput = {
  titleViewElement?: IViewElement;
  subtitleViewElement?: IViewElement;
  badgeViewElement?: IViewElement;
  bottomLeftViewElement?: IViewElement;
  bottomRightViewElement?: IViewElement;
};

export class ListBlockDisplay {
  titleViewElement?: ViewElement;
  subtitleViewElement?: ViewElement;
  badgeViewElement?: ViewElement;
  bottomLeftViewElement?: ViewElement;
  bottomRightViewElement?: ViewElement;

  constructor(data: ListBlockDisplayApiOutput) {
    this.titleViewElement = this.assignViewElement(data.titleViewElement);
    this.subtitleViewElement = this.assignViewElement(data.subtitleViewElement);
    this.badgeViewElement = this.assignViewElement(data.badgeViewElement);
    this.bottomLeftViewElement = this.assignViewElement(
      data.bottomLeftViewElement,
    );
    this.bottomRightViewElement = this.assignViewElement(
      data.bottomRightViewElement,
    );
  }

  assignViewElement(element?: IViewElement): ViewElement | undefined {
    return element ? new ViewElement(element) : undefined;
  }
}
