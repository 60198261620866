import { LinkGroup } from '@smack/core/api/models/categories/LinkGroup';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { ElementListCard } from '@smack/core/components/DataDisplay/ElementListCard/ElementListCard';
import {
  LinkGroupSkeleton,
  ListSkeleton,
  LoaderSkeleton,
} from '@smack/core/utils/Loader';
import { LinksGroupContent } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Links/Components/LinksGroupContent/LinksGroupContent';
import { type FC, useEffect, useState } from 'react';

interface LinksGroupContentProps {
  baseObject: BaseObject;
  rootLinkGroupId: number;
}

export const ObjectLinkGroupsCard: FC<LinksGroupContentProps> = ({
  baseObject,
  rootLinkGroupId,
}) => {
  const [rootLinkGroup, setRootLinkGroup] = useState<LinkGroup>();
  const [fetchedLinkGroups, setFetchedLinkGroups] = useState<LinkGroup[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    Promise.allSettled([
      LinkGroup.getChildrenLinkGroupsFromLinkGroupIdAndBaseObjectId(
        baseObject.id,
        rootLinkGroupId,
      ).then(setFetchedLinkGroups),
      LinkGroup.getLinkGroup(rootLinkGroupId).then(setRootLinkGroup),
    ]).finally(() => setIsLoading(false));
  }, [rootLinkGroupId]);

  if (isLoading)
    return (
      <LoaderSkeleton width="100%" height={240}>
        <ListSkeleton iterations={5} component={LinkGroupSkeleton} />
      </LoaderSkeleton>
    );

  return (
    <ElementListCard
      title={rootLinkGroup?.label ?? ''}
      icon={rootLinkGroup?.icon}
    >
      <LinksGroupContent
        object={baseObject}
        groups={fetchedLinkGroups}
        populationType={rootLinkGroup?.populationType}
      />
    </ElementListCard>
  );
};
