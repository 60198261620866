import {
  type ViewRulesFunctionType,
  ViewRulesFunctions,
} from '@smack/core/components/ViewRenderer/rules/ViewRulesFunctions/ViewRulesFunctions';
import React, { useCallback, useState } from 'react';

export enum ViewRulesEvent {
  OnChange = 'ON_CHANGE',
}

export interface IViewRulesParams<T = unknown> {
  targetViewElementId?: number;
  targetAttributeFilter?: string;
  targetAttributeId?: number;
  eventValue?: T;
  message?: string;
}

export interface IViewRules {
  listenedViewElementId: number;
  listenedEvent: ViewRulesEvent;
  targetFunction: ViewRulesFunctionType;
  parameters: IViewRulesParams;
}

export interface EventSubscription {
  event: ViewRulesEvent;
  listenedViewElementId: number;
  onTrigger: (value?: unknown) => void;
}

export interface ViewRulesContextProps {
  viewRules: IViewRules[];
  event: (event: ViewRulesEvent, viewElementId: number, value: unknown) => void;
  subscribe: (eventSubscription: EventSubscription) => void;
}

interface IProps {
  viewRules: IViewRules[];
  children: React.ReactNode;
}

export const ViewRulesContext = React.createContext<ViewRulesContextProps>(
  {} as ViewRulesContextProps,
);

export const ProvideViewRules: React.FC<IProps> = ({ children, viewRules }) => {
  const NoElementViewRulesSubscriptions: EventSubscription[] =
    React.useMemo(() => {
      return viewRules
        .filter((v) => !v.parameters.targetViewElementId)
        .map((rule) => {
          return {
            listenedViewElementId: rule.listenedViewElementId,
            event: rule.listenedEvent,
            onTrigger: (value): void => {
              rule.parameters.eventValue = value;
              const targetFunction = ViewRulesFunctions.find(
                (v) => v.type === rule.targetFunction,
              );
              if (targetFunction) {
                targetFunction.call(rule.parameters);
              }
            },
          };
        });
    }, [viewRules]);

  const [eventSubscription, setEventSubscription] = useState<
    EventSubscription[]
  >(NoElementViewRulesSubscriptions);

  const handleEvent = useCallback(
    (event: ViewRulesEvent, viewElementId: number, value: unknown) => {
      eventSubscription.forEach((t) => {
        if (t.event === event && viewElementId === t.listenedViewElementId) {
          t.onTrigger(value);
        }
      });
    },
    [eventSubscription],
  );

  const handleSubscribe = useCallback(
    (data: EventSubscription) => {
      setEventSubscription((previousState) => [...previousState, data]);
    },
    [eventSubscription, setEventSubscription],
  );

  const contextValue = React.useMemo(() => {
    return {
      viewRules,
      event: handleEvent,
      subscribe: handleSubscribe,
    };
  }, [eventSubscription, viewRules]);

  return (
    <ViewRulesContext.Provider value={contextValue}>
      {children}
    </ViewRulesContext.Provider>
  );
};
