import { IconRoundedButton } from '@smack/core/components/Actions/Buttons/IconRoundedButton/IconRoundedButton';
import { LinkCustom } from '@smack/core/components/Actions/Link';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import { env } from '@smack/core/env';
import type React from 'react';
import type { ReactNode } from 'react';

export interface IListElementAction {
  icon: IconField;
  label: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export interface IBaseListElementProps {
  key?: React.Attributes['key'];
  link?: string;
  active?: boolean;
  action?: IListElementAction;
  onClick?: () => void;
  noOverlayActions?: boolean;
}

export const BaseListElement: React.FC<
  IBaseListElementProps & { children: ReactNode }
> = ({
  onClick,
  link,
  active = false,
  action,

  noOverlayActions = false,
  children,
}) => {
  return (
    <div
      data-testid="BaseListElement"
      onClick={onClick}
      className={`h-[80px]${
        onClick && !env.VITE_DISPLAY_OVERLAY_ICONS_ON_LINKS
          ? ' cursor-pointer'
          : ''
      } border-b border-border`}
    >
      <LinkCustom
        active={!!link && !env.VITE_DISPLAY_OVERLAY_ICONS_ON_LINKS}
        to={link ?? ''}
        excludeParams={['schedule']}
      >
        <div
          className={`relative flex gap-1 h-full pr-3 dark:border-neutral-700 ${
            active ? 'bg-gray-100 dark:bg-gray-700' : ''
            //@TODO when removing the env var VITE_DISPLAY_OVERLAY_ICONS_ON_LINKS, see for adapting the logic
            // with the hover below (and the one for the dark mode) - Basically remove it.
          } ${
            !env.VITE_DISPLAY_OVERLAY_ICONS_ON_LINKS || noOverlayActions
              ? ' hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer'
              : ''
          }`}
        >
          {children}

          {action &&
          (!env.VITE_DISPLAY_OVERLAY_ICONS_ON_LINKS ||
            !action.onClick ||
            (env.VITE_DISPLAY_OVERLAY_ICONS_ON_LINKS && noOverlayActions)) ? (
            <IconRoundedButton
              title={action.label}
              icon={action.icon}
              onClick={action.onClick} // This will be undefined, which should be handled in the IconRoundedButton
              className="self-center"
            />
          ) : null}
        </div>
      </LinkCustom>
    </div>
  );
};
