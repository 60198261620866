import type { FormValues } from '@smack/core/api/models/categories/Attribute/Attribute';
import {
  type ISelectOption,
  SelectInput,
} from '@smack/core/components/DataInput/SelectInput/SelectInput';
import React, { useId } from 'react';
import { clamp, generateSteps, generateTime } from './Utils';

export interface ITimeSelectInputProps {
  value?: string;
  onChange?: (value: string) => void;
  label?: string; // label of the input
  error?: string; // error message to show
  minHour?: number;
  maxHour?: number;
  minMinute?: number;
  maxMinute?: number;
  stepHour?: number;
  stepMinute?: number;
}

const TimeSelectInputRender: React.ForwardRefRenderFunction<
  HTMLDivElement,
  ITimeSelectInputProps
> = (
  {
    value,
    onChange,
    label,
    error,
    minHour,
    maxHour,
    minMinute,
    maxMinute,
    stepHour,
    stepMinute,
  },
  ref,
) => {
  const [hour, setHour] = React.useState<number>();
  const [minute, setMinute] = React.useState<number>();
  const uuid = useId();

  React.useLayoutEffect(() => {
    if (!value) return;
    const results = /(\d{1,2}):(\d{1,2})/.exec(value);
    if (results) {
      setHour(Number.parseInt(results[1]));
      setMinute(Number.parseInt(results[2]));
    } else {
      setHour(undefined);
      setMinute(undefined);
    }
  }, [value]);

  // When isNaN is true, it means that the user has cleared the select
  // On the minute, it will be set to 0
  // On the hour, it will clear the input
  const triggerUpdate = (type: 'hour' | 'minute', val: number): void => {
    const sanitizedValue = Number.isNaN(val) ? undefined : Number(val);
    const newHour = type === 'hour' ? sanitizedValue : hour;
    const newMinute = type === 'minute' ? sanitizedValue : minute;
    onChange?.(generateTime(newHour, newMinute));
  };

  const handleHourChange = React.useCallback(
    (val?: FormValues) => triggerUpdate('hour', Number(val)),
    [minute],
  );

  const handleMinuteChange = React.useCallback(
    (val?: FormValues) => triggerUpdate('minute', Number(val)),
    [hour],
  );

  // Memoize both hour and minute options to avoid computations on each render cycle
  const hourOptions = React.useMemo<ISelectOption[]>(() => {
    const step = stepHour ?? 1;
    const min = clamp(minHour ?? 0, 0, 23);
    const max = clamp(maxHour ?? 23, 0, 23);
    return generateSteps(min, max, step);
  }, [minHour, maxHour, stepHour]);

  const minuteOptions = React.useMemo<ISelectOption[]>(() => {
    const step = stepMinute ?? 1;
    const min = clamp(minMinute ?? 0, 0, 59);
    const max = clamp(maxMinute ?? 59, 0, 59);
    return generateSteps(min, max, step);
  }, [minMinute, maxMinute, stepMinute]);

  return (
    <div ref={ref}>
      {label && (
        <label
          htmlFor={uuid}
          className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1"
        >
          {label}
        </label>
      )}
      <div className="relative max-w-lg sm:max-w-xs w-full flex items-center">
        <SelectInput
          id={uuid}
          options={hourOptions}
          value={hourOptions.find((o) => o.value === hour)}
          onChange={(val): void => handleHourChange(val?.value)}
        />
        <span className="px-1">:</span>
        <SelectInput
          options={minuteOptions}
          value={minuteOptions.find((o) => o.value === minute)}
          onChange={(val): void => handleMinuteChange(val?.value)}
        />
      </div>
      {/* Show the error if needed */}
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </div>
  );
};

TimeSelectInputRender.displayName = 'TimeSelectInput';

export const TimeSelectInput = React.forwardRef(TimeSelectInputRender);
