import store from '@smack/core/store';
import { clearModal, setModal } from '@smack/core/store/modal/actions';
import type { ReactNode } from 'react';

function resetModal(): void {
  store.dispatch(clearModal());
}

export function spawnModal(modal: {
  render: (props: { onClose: () => void }) => ReactNode;
}): void {
  store.dispatch(
    setModal(
      modal.render({
        onClose: () => {
          resetModal();
        },
      }),
    ),
  );
}
