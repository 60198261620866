import type { CalendarApi } from '@fullcalendar/core';
import type { Action } from '@smack/core/api/models/actions/Action/Action';
import type { ParentCategory } from '@smack/core/api/models/categories/Category/ParentCategory';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { ViewName } from '@smack/core/hooks/views/types';

export type ITheme = 'dark' | 'light';

export interface IAppSettings {
  label?: string;
  displayedLabel?: string;

  loginLogo?: string;
  color?: string;
  loginBackgroundImage?: string;
  headerColor?: string;
  headerLogoLight?: string;
  headerLogoDark?: string;
  calendarCurrentDayColor?: string;
  insee?: string;
  mapZoomMin?: number;
  mapZoomMax?: number;
  mapInitialZoom?: number;
  defaultBasemapId?: number;
  mapLocation?: GeoJSON.Point;
  isBaseobjectOnOpenCenter?: boolean;
  wikiUrl?: string;
  mobileQuickMenuCategory?: ParentCategory;
  isRedirectWeb?: boolean;
  isRedirectDesktop?: boolean;
  isUserConnectionTrustedAvailable?: boolean;
  isSearchActivated?: boolean;
  addressDatabaseUrl?: string;
  changeViewLevel?: ChangeViewLevel;
}

export interface BaseAppState {
  appLoading: boolean;
  appOnline: boolean;
  settings?: IAppSettings;
  theme: ITheme;
  module: IModuleStore;
  isMenuCollapsed: boolean;
  currentView: CurrentViewStore;
  isAutoOpeningFile: boolean;
}

export interface ILegendEntry {
  label: string;
  value: string;
}

export interface ILegend {
  id: number;
  title: string;
  values: ILegendEntry[];
}

export interface IBaseObjectDashboardDisplayProps {
  dashboardDisplayAction: Action;
  baseobjectId: number;
}

export enum ChangeViewLevel {
  Module = 'MODULE',
  Set = 'SET',
}

export interface IModuleStore {
  focusedObject?: BaseObject;
  activeModule?: string;
  activeMenu?: string;
  activeRightPanel?: string;
  activePreviewObjectId?: number | undefined;
  activeScheduleId?: number | undefined;
  disableSubNavRouting?: boolean;
  legend?: ILegend[];
  mobileObjectPanel?: boolean;
  recommendation?: {
    id: number;
    baseObjectId: number;
  };
  calendarApi?: CalendarApi;
  hideSetOnMap?: boolean;
}

export interface ViewActive<T> {
  view: ViewName;
  params: T;
}

export interface CurrentViewStore<T = unknown> {
  current?: ViewActive<T>;
  viewHistory?: ViewActive<T>[];
}

// actions constant

export const SET_FOCUSED_BASEOBJECT = 'APP::SET_FOCUSED_BASEOBJECT';
export const FETCH_ACTIVEMODULES = 'APP::FETCH_ACTIVEMODULES';
export const SET_IS_MENU_COLLAPSED = 'APP::SET_IS_MENU_COLLAPSED';
export const FETCH_LOADING = 'APP::FETCH_LOADING';
export const SET_ONLINE = 'APP::SET_ONLINE';
export const FETCH_MENUFILTEREXTEND = 'APP::FETCH_MENUFILTEREXTEND';
export const FETCH_THEME = 'APP::FETCH_THEME';
export const FETCH_APPLOADING = 'APP::APPLOADING';
export const FETCH_SETTINGS = 'APP::FETCH_SETTINGS';
export const FETCH_LEGEND = 'APP::FETCH_LEGEND';
export const SET_MODULE_STORE = 'APP::SET_MODULE_STORE';
export const SET_CURRENT_VIEW = 'APP::SET_CURRENT_VIEW';
export const SET_LAST_VIEW_AS_CURRENT = 'APP::SET_LAST_VIEW_AS_CURRENT';
export const SET_IS_AUTO_OPENING_FILE = 'APP::SET_IS_AUTO_OPENING_FILE';

// action definition
export interface ISetFocusedBaseobject {
  type: typeof SET_FOCUSED_BASEOBJECT;
  payload: BaseObject | undefined;
}

export interface ISetIsMenuCollapsed {
  type: typeof SET_IS_MENU_COLLAPSED;
  payload: { isMenuCollapsed: boolean };
}

export interface ISetLegend {
  type: typeof FETCH_LEGEND;
  payload: ILegend[] | undefined;
}

export interface ISetActiveModule {
  type: typeof FETCH_ACTIVEMODULES;
  payload: string | undefined;
}
export interface ISetLoading {
  type: typeof FETCH_LOADING;
  payload: boolean;
}

export interface ISetMenuFilterExtend {
  type: typeof FETCH_MENUFILTEREXTEND;
  payload: boolean;
}

export interface ISetTheme {
  type: typeof FETCH_THEME;
  payload: ITheme;
}

export interface ISetAppLoading {
  type: typeof FETCH_APPLOADING;
  payload: boolean;
}

export interface ISetSettings {
  type: typeof FETCH_SETTINGS;
  payload?: IAppSettings;
}

export interface ISetModuleStore {
  type: typeof SET_MODULE_STORE;
  payload?: Partial<IModuleStore>;
}

export interface ISetAppOnline {
  type: typeof SET_ONLINE;
  payload: boolean;
}

export interface ISetCurrentView {
  type: typeof SET_CURRENT_VIEW;
  payload: ViewActive<unknown>;
}

export interface ISetLastViewAsCurrent {
  type: typeof SET_LAST_VIEW_AS_CURRENT;
}

export interface ISetIsAutoOpeningFile {
  type: typeof SET_IS_AUTO_OPENING_FILE;
  payload: boolean;
}

export type BaseAppActionType =
  | ISetActiveModule
  | ISetLoading
  | ISetMenuFilterExtend
  | ISetTheme
  | ISetSettings
  | ISetAppLoading
  | ISetModuleStore
  | ISetLegend
  | ISetIsMenuCollapsed
  | ISetAppOnline
  | ISetFocusedBaseobject
  | ISetCurrentView
  | ISetLastViewAsCurrent
  | ISetIsAutoOpeningFile;
