import type { ChecklistItemLog } from '@smack/core/api/models/tasks/ChecklistItemLog';
import { Trans, useTranslation } from 'react-i18next';
import { type ILogsFeedProps, LogFeed } from '../../LogsFeed';

export const ChecklistItemLogCheckedItemFeed = (
  props: ILogsFeedProps<ChecklistItemLog>,
): JSX.Element => {
  const [t] = useTranslation();

  return (
    <LogFeed {...props} icon={{ name: 'check-square' }}>
      <span className="text-sm font-normal">
        {' '}
        <Trans
          t={t}
          i18nKey="logs.action.checkedChecklistItem"
          values={{
            checklist: props.logs.content?.checklistItem?.checklist?.label,
            checklistItem: props.logs.content?.checklistItem?.label,
          }}
        />
      </span>
    </LogFeed>
  );
};
