import { Trans, useTranslation } from 'react-i18next';
import { ConfirmBaseAlert, type IConfirmBaseProps } from '../ConfirmBaseAlert';

export const UnlinkConfirmAlert = (props: IConfirmBaseProps): JSX.Element => {
  const [t] = useTranslation();
  const { text, title, securityText } = props;

  const getTitle = (): JSX.Element => {
    return (
      <p>
        <Trans t={t} i18nKey="links.deleteLinkWarning" />
      </p>
    );
  };

  return (
    <ConfirmBaseAlert
      {...props}
      icon={{ name: 'link-slash' }}
      title={title || t('links.aboutToDeleteAlink')}
      text={text || getTitle()}
      securityText={securityText}
    />
  );
};
