import MapboxDraw from '@hyvilo/maplibre-gl-draw';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import '@hyvilo/maplibre-gl-draw/dist/maplibre-gl-draw.css';

import { getHelpTextKey } from '@smack/core/components/DataInput/LocationInput/Components/Map/Utils';
import { useMapsPreferences } from '@smack/core/hooks/preferences/useMapsPreferences/useMapsPreferences';
import type { AppState } from '@smack/core/store';
import { fitBoundingBoxOfGeometryCollection } from '@smack/core/utils/MapUtils';
import { ConfigurationPanel } from '@smack/core/views/oldViewsToSort/Layouts/RightPanel/MapConfigurator/Components/ConfiguratorPanel';
import { MapButton } from '@smack/core/views/oldViewsToSort/Views/Map/Components/MapButton/MapButton';
import { MapControl } from '@smack/core/views/oldViewsToSort/Views/Map/Components/MapControl/MapControl';
import { MapContextProvider } from '@smack/core/views/oldViewsToSort/Views/Map/Context/MapContext';
import { buildMapStyle } from '@smack/core/views/oldViewsToSort/Views/Map/Utils/mapinit';
import centerOfMass from '@turf/center-of-mass';
import { cx } from 'class-variance-authority';
import type { Feature, FeatureCollection, Point } from 'geojson';
import {
  FullscreenControl,
  GeolocateControl,
  Map as MaplibreMap,
} from 'maplibre-gl';
import { useTranslation } from 'react-i18next';
import { PolygonInputPopup } from './Components';

export interface IMapOutput {
  location?: GeoJSON.Feature<Point> | null;
  geometry?: GeoJSON.FeatureCollection | null;
}

interface ISettings {
  zoom_min: number;
  zoom_max: number;
  zoom: number;
  center: [number, number];
}

interface IProps {
  value?: IMapOutput;
  onChange?: (value: IMapOutput) => void;
}

const emptyFeatureCollection: FeatureCollection = {
  type: 'FeatureCollection',
  features: [],
};

export const MapInput = (props: IProps): JSX.Element => {
  const { value, onChange } = props;
  const { basemaps } = useSelector((state: AppState) => state.Maps);
  const { settings } = useSelector((state: AppState) => state.App);
  const map = useRef<MaplibreMap>();
  const mapContainer = useRef<HTMLDivElement>(null);
  const mapdraw = useRef<MapboxDraw | null>(null);
  const values = useRef<IMapOutput>();
  const [, rerender] = useState({});
  const [selectedFeatures, setSelectedFeatures] = useState<Feature[]>([]);
  const [isConfigurationPanelOpen, setIsConfigurationPanelOpen] =
    useState(false);
  const [activeMode, setActiveMode] = useState<MapboxDraw.DrawMode>(
    value?.location ? 'simple_select' : 'draw_point',
  );
  const [hasUserStartedDrawing, setHasUserStartedDrawing] = useState(false);
  const [t] = useTranslation();

  const [preferences] = useMapsPreferences();

  const GetSettings = (): ISettings => {
    const defaultedSettings: ISettings = {
      zoom_min: 1,
      zoom_max: 18,
      zoom: 15,
      center: [2.7450921314582715, 46.98344582546212],
    };
    if (settings) {
      if (settings.mapInitialZoom)
        defaultedSettings.zoom = settings.mapInitialZoom;
      if (settings.mapZoomMin) defaultedSettings.zoom_min = settings.mapZoomMin;
      if (settings.mapZoomMax) defaultedSettings.zoom_max = settings.mapZoomMax;
      defaultedSettings.center = [
        settings?.mapLocation?.coordinates[0] || defaultedSettings.center[0],
        settings?.mapLocation?.coordinates[1] || defaultedSettings.center[1],
      ];
    }
    const userZoom = preferences?.zoom;
    if (userZoom) defaultedSettings.zoom = userZoom;
    const userCenter = preferences?.center;
    if (userCenter) defaultedSettings.center = [userCenter.lng, userCenter.lat];

    if (values.current?.location?.geometry) {
      defaultedSettings.center = [
        values.current?.location.geometry.coordinates[0],
        values.current?.location.geometry.coordinates[1],
      ];
    }
    return defaultedSettings;
  };

  const easeTo = (mode: 'point' | 'bounds'): void => {
    if (!values.current?.location || !map.current) return;
    if (!map.current || !map.current.isStyleLoaded()) return;

    if (mode === 'bounds') {
      if (!values.current?.geometry) easeTo('point');
      else {
        fitBoundingBoxOfGeometryCollection(
          map.current,
          values.current.geometry,
        );
      }
      return;
    }

    if (mode === 'point' && values.current?.location) {
      try {
        map.current.easeTo({
          center: [...values.current.location.geometry.coordinates] as [
            number,
            number,
          ],
        });
      } catch {
        // not a valid location
      }
    }
  };

  const delayedEaseToPoint = (): NodeJS.Timeout =>
    setTimeout(easeTo, 150, 'point');

  const flipScreen = (): void => {
    if (mapdraw.current && values.current) {
      const geometry: FeatureCollection = values.current.geometry
        ? { ...values.current.geometry }
        : { ...emptyFeatureCollection };
      if (values.current.location) {
        if (!values.current.location.id) {
          // Mapbox Draw is flaky with ID-less Features, so we add features IDs
          // to the point if it doesn't have one
          values.current.location = {
            ...values.current.location,
            id: 'original-location',
          };
        }
        // Do not mutate original features array
        geometry.features = [...geometry.features, values.current.location];
      }
      mapdraw.current.set(geometry);
    }
  };

  const setPoint = (point?: Feature<Point>): void => {
    if (!values.current) return;
    values.current.location = point;
    if (onChange) onChange(values.current);
  };

  const onFeatureChange = (
    data: MapboxDraw.DrawCreateEvent | MapboxDraw.DrawUpdateEvent,
  ): void => {
    if (data.features.length > 0) {
      const addedIDs = data.features.map((f) => f.id);
      const addedGeometries = data.features.filter(
        (f) => f.geometry.type !== 'Point',
      );
      data.features.forEach((feat) => {
        if (feat.geometry.type === 'Point') {
          setPoint(feat as Feature<Point>);
        }
      });
      const valtoSave: FeatureCollection = {
        type: 'FeatureCollection',
        features: [
          ...(values.current?.geometry?.features?.filter(
            (i) => !addedIDs.includes(i.id),
          ) || []),
          ...addedGeometries,
        ],
      };
      if (values?.current) {
        values.current.geometry = valtoSave;
        if (onChange) onChange(values.current);
      }
      if (addedGeometries.length > 0) {
        setTimeout(() => {
          setPoint(centerOfMass(valtoSave));
        }, 0);
      }
      delayedEaseToPoint();
      // draw.create is followed by a draw.selectionchange event
      // so we don't need to update here the selected features array
      if (data.type === 'draw.update') {
        setSelectedFeatures(data.features);
      }
    }
  };

  const onFeatureDelete = (
    data: Omit<MapboxDraw.DrawDeleteEvent, 'target'>,
  ): void => {
    if (data.features) {
      const deletedIDs = data.features.map((f) => f.id);
      const val: FeatureCollection = {
        type: 'FeatureCollection',
        features: [
          ...(values.current?.geometry?.features?.filter(
            (i) => !deletedIDs.includes(i.id),
          ) || []),
        ],
      };
      data.features.forEach((feat) => {
        if (feat.geometry.type === 'Point') {
          setPoint(); // Remove the point
        }
      });
      if (values?.current) {
        values.current.geometry = val;
        if (onChange) onChange(values?.current);
      }
      // Remove deleted features from selected features array
      // to delete their popups
      setSelectedFeatures((selected) => {
        return selected.filter((sf) => !deletedIDs.includes(sf.id));
      });
    }
  };

  const onFeaturesSelection = (
    data: MapboxDraw.DrawSelectionChangeEvent,
  ): void => {
    setSelectedFeatures(data.features || []);
  };

  const onModeChange = (event: { mode: MapboxDraw.DrawMode }) => {
    setActiveMode(event.mode);
    setHasUserStartedDrawing(false);
  };

  const changeMode = (mode: MapboxDraw.DrawMode) => {
    mapdraw.current?.changeMode(mode as string);
    onModeChange({ mode });
  };

  const callTrash = () => {
    return mapdraw.current?.trash();
  };

  const onMapClick = () => {
    setHasUserStartedDrawing(true);
  };

  const trashFeature = React.useCallback((feat: Feature): void => {
    if (map.current && feat.id) {
      mapdraw.current?.delete(feat.id.toString());
      // Since calling delete does not trigger the delete event, we do it ourselves
      onFeatureDelete({
        type: 'draw.delete',
        features: [feat],
      });
    }
  }, []);

  React.useEffect(() => {
    if (!map.current && basemaps.length && settings && mapContainer.current) {
      map.current = new MaplibreMap({
        container: mapContainer.current,
        style: buildMapStyle(basemaps),
        minZoom: GetSettings().zoom_min,
        maxZoom: 17.9,
        zoom: GetSettings().zoom,
        center: GetSettings().center,
        interactive: true,
      });

      map.current.on('load', () => {
        easeTo('bounds');
      });
      map.current.on('click', onMapClick);
      map.current.on('draw.create', onFeatureChange);
      map.current.on('draw.update', onFeatureChange);
      map.current.on('draw.delete', onFeatureDelete);
      map.current.on('draw.selectionchange', onFeaturesSelection);
      map.current.on('draw.modechange', onModeChange);
      mapdraw.current = new MapboxDraw({
        displayControlsDefault: false,
        defaultMode: activeMode,
      });
      map.current.addControl(mapdraw.current);
      map.current.addControl(new FullscreenControl({}), 'bottom-right');
      map.current.addControl(
        new GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          showAccuracyCircle: false,
          trackUserLocation: true,
        }),
        'bottom-right',
      );
      // MapLibre cannot create a map instance without providing the container,
      // so we cannot use a memo like we do in map popups for example.
      // We have to rerender to update the context provider values
      rerender({});
      return (): void => {
        if (map.current?.isStyleLoaded()) map.current?.remove();
      };
    }
  }, [basemaps, settings]);

  React.useEffect(() => {
    if (value) {
      values.current = value;
      delayedEaseToPoint();
      mapdraw.current?.changeMode('simple_select');
      flipScreen();
    } else {
      values.current = {};
    }
  }, [value]);

  return (
    <>
      <div className="relative w-[80vw] max-w-[600px] h-[300px] border border-gray-300 rounded">
        <div className={'w-full h-full'} ref={mapContainer} />
        <MapContextProvider
          mapLibreHandle={map.current}
          drawHandle={mapdraw.current ?? undefined}
        >
          <MapControl
            className="maplibregl-ctrl-group !mt-0"
            placement="top-right"
          >
            <MapButton
              className={cx(
                'bg-no-repeat bg-center maplibre-gl-draw_line',
                activeMode === 'draw_line_string' ? '!bg-black/5' : '',
              )}
              onClick={() => changeMode('draw_line_string')}
              tooltip={{
                text: t('locationInputModal.drawModesTooltips.line'),
                placement: 'left',
              }}
            />
            <MapButton
              className={activeMode === 'draw_polygon' ? '!bg-black/5' : ''}
              icon={{ name: 'draw-polygon' }}
              onClick={() => changeMode('draw_polygon')}
              tooltip={{
                text: t('locationInputModal.drawModesTooltips.polygon'),
                placement: 'left',
              }}
            />
            <MapButton
              className={activeMode === 'draw_point' ? '!bg-black/5' : ''}
              icon={{ name: 'location-pen' }}
              onClick={() => changeMode('draw_point')}
              tooltip={{
                text: t('locationInputModal.drawModesTooltips.point', {
                  context: value?.location ? 'replace' : 'new',
                }),
                placement: 'left',
              }}
            />
            <MapButton
              icon={{ name: 'trash-alt' }}
              onClick={callTrash}
              tooltip={{
                text: t('locationInputModal.drawModesTooltips.delete'),
                placement: 'left',
              }}
            />
          </MapControl>
          <MapControl
            className="maplibregl-ctrl-group"
            placement="bottom-right"
          >
            <MapButton
              icon={{ name: 'layer-group' }}
              onClick={() => setIsConfigurationPanelOpen(true)}
            />
          </MapControl>
        </MapContextProvider>

        {isConfigurationPanelOpen ? (
          <div className="absolute rounded inset-0 shadow z-50 md:left-1/2 ">
            <ConfigurationPanel
              hidePointMode
              map={map.current}
              usecustomBasemapAndLayer
              onClose={(): void => setIsConfigurationPanelOpen(false)}
            />
          </div>
        ) : null}
      </div>
      <p className="w-[80vw] max-w-[600px] text-xs text-gray-500 dark:text-gray-100">
        {t(
          `locationInputModal.drawHelpTexts.${getHelpTextKey(
            activeMode,
            hasUserStartedDrawing,
            !!value?.location,
          )}`,
        )}
      </p>
      {selectedFeatures.map(
        (sf) =>
          map.current && (
            <PolygonInputPopup
              key={sf.id}
              feat={sf}
              map={map.current}
              onDelete={trashFeature}
            />
          ),
      )}
    </>
  );
};
