import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { Group } from '@smack/core/api/models/tasks/Group';
import type { Project } from '@smack/core/api/models/tasks/Project/Project';
import { useSearchParams } from '@smack/core/hooks/useSearchParams';
import { GroupTitle } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/GroupTitle/GroupTitle';
import { TaskFeed } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/TaskFeed';
import { useStatefulAnimation } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Hooks/useAnimation';
import React from 'react';

interface IProps {
  group: Group;
  project: Project;
  baseObject: BaseObject;
  reloadProject: () => Promise<void>;
}

/**
 * Group Component for Task view
 */
export const TaskGroup: React.FC<IProps> = ({
  group,
  project,
  reloadProject,
  baseObject,
}) => {
  const [{ task: autoOpenTaskId }] = useSearchParams();

  const [open, setOpen] = React.useState(
    group.tasks?.some((task) => task.id === autoOpenTaskId) ||
      group.status?.shouldUnfoldAutomatically ||
      false,
  );

  const switchGroup = React.useCallback(
    () => setOpen((openState) => !openState),
    [],
  );

  const disclosureRef = React.useRef<HTMLDivElement>(null);

  const [hasAppeared, hasDisappeared] = useStatefulAnimation(
    group.status,
    disclosureRef,
  );

  if (!hasDisappeared && group.status?.isHidden) return null;

  return (
    <div
      ref={disclosureRef}
      className={`border bg-primary rounded-sm my-2 first:mt-0 ${
        hasAppeared ? 'animate-enter-fade' : ''
      } ${hasDisappeared ? 'animate-exit-fade' : ''}`}
    >
      <GroupTitle
        open={open}
        onOpen={switchGroup}
        group={group}
        project={project}
        reloadProject={reloadProject}
      />
      {open &&
        group.tasks?.map((task) => (
          <TaskFeed
            key={task.id}
            task={task}
            group={group}
            baseObject={baseObject}
            projectId={project.id}
            reloadProject={reloadProject}
          />
        ))}
    </div>
  );
};
