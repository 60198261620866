import type { ViewElement } from '@smack/core/api/models/views/ViewElement/ViewElement';
import { BaseObjectFormContext } from '@smack/core/components/ViewRenderer/renderers/BaseObjectFormViewRenderer/context/BaseObjectFormContext';
import { attributeFieldsByType } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeFieldsByType/AttributeFieldsByType';
import {
  ViewRulesContext,
  ViewRulesEvent,
} from '@smack/core/components/ViewRenderer/rules/ViewRulesContext/ViewRulesContext';
import React from 'react';
import {
  Controller,
  type ControllerProps,
  type FieldValues,
} from 'react-hook-form';

interface ViewRulesControllerProps<T extends FieldValues = FieldValues>
  extends ControllerProps<T> {
  viewElement?: ViewElement;
}

export const ViewRulesController = <T extends FieldValues = FieldValues>({
  viewElement,
  ...controllerProps
}: ViewRulesControllerProps<T>): React.ReactElement => {
  const { event } = React.useContext(ViewRulesContext);
  const {
    isFormControllerToggleable,
    controlledFormElements,
    setControlledFormElements,
  } = React.useContext(BaseObjectFormContext);

  const valueToAttributeValue = (value: unknown): unknown[] | undefined => {
    const formField = attributeFieldsByType.find(
      (a) => a.type === viewElement?.fieldType,
    );
    return formField?.valueToAttributeValue(value);
  };

  const controlFieldOnChange = (): void => {
    if (
      !isFormControllerToggleable ||
      !setControlledFormElements ||
      !viewElement
    )
      return;
    if (
      !controlledFormElements
        ?.map((f) => f.id.toString())
        .includes(viewElement?.id.toString())
    ) {
      setControlledFormElements?.([
        ...(controlledFormElements ?? []),
        viewElement,
      ]);
    }
  };

  return (
    <Controller
      {...controllerProps}
      render={({ field, fieldState, formState }) => {
        return controllerProps.render({
          field: {
            ...field,
            onChange: (data) => {
              field.onChange(data);
              if (viewElement?.id) {
                controlFieldOnChange();
                event?.(
                  ViewRulesEvent.OnChange,
                  viewElement.id,
                  valueToAttributeValue(data)?.at(0),
                );
              }
            },
          },
          fieldState,
          formState,
        });
      }}
    />
  );
};
