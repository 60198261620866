import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import React, { useRef, useState } from 'react';

interface IProps {
  className?: string;
  children: JSX.Element[];
  paginate?: boolean;
  indexactive?: number;
}

const tabwidth = 44;

export const NavigationContainer = (props: IProps): JSX.Element => {
  const { className, children, indexactive, paginate } = props;
  const list = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState<number>(0);

  const changepagebutton = (type: 'next' | 'back'): JSX.Element => {
    return (
      <button
        type="button"
        tabIndex={-1}
        key={type}
        onClick={(): void => {
          if (type === 'next') {
            setPage(page + 1);
          } else {
            setPage(page - 1);
          }
        }}
        className={`h-11 max-w-[55px] full grow flex items-center justify-center bg-gray-100 cursor-pointer
      } ${className ?? ''}`}
      >
        <Icon
          icon={{ name: type === 'next' ? 'chevron-right' : 'chevron-left' }}
          className={`text-md  dark:invert'
        }`}
        />
      </button>
    );
  };

  React.useEffect(() => {
    const width = list.current?.offsetWidth;
    if (indexactive && width) {
      let newpage = 0;
      const chunkSize = Math.floor((width - 46) / tabwidth);
      for (let i = 0; i < children.length; i += chunkSize) {
        if (indexactive >= i && indexactive <= i + chunkSize - 1) {
          if (newpage !== page) setPage(newpage);
        }
        newpage++;
      }
    }
  }, [indexactive]);

  const getElements = (): JSX.Element[] => {
    const width = list.current?.offsetWidth;
    if (!width || !paginate) return children;
    const output: JSX.Element[][] = [];
    const chunkSize = Math.floor((width - 46) / tabwidth);
    // Avoids infinite loops when the component width is too small
    // TODO: find a way to keep responsive design
    if (chunkSize === 0) return children;
    for (let i = 0; i < children.length; i += chunkSize) {
      const chunk = children.slice(i, i + chunkSize);
      output.push(chunk);
    }
    if (output.length > 1) {
      const newoutput = output[page];
      if (page === 0) {
        newoutput?.push(changepagebutton('next'));
      } else {
        newoutput?.unshift(changepagebutton('back'));
        if (output[page + 1]) {
          newoutput?.push(changepagebutton('next'));
        }
      }
      return newoutput;
    }
    return [...children];
  };

  return (
    <div ref={list} className={`flex w-full ${className || ''}`}>
      {getElements()}
    </div>
  );
};
