import type MapboxDraw from '@hyvilo/maplibre-gl-draw';
import { isAppleDevice } from '@smack/core/utils/PlatformUtils';
import area from '@turf/area';
import { convertLength } from '@turf/helpers';
import length from '@turf/length';
import type { Feature } from 'geojson';

export function getMetricForGeometry(
  feat: Feature,
): [number, 'surface' | 'length'] {
  switch (feat.geometry.type) {
    case 'Point':
    case 'MultiPoint':
      return [0, 'length'];
    case 'Polygon':
    case 'MultiPolygon':
    case 'GeometryCollection':
      return [Math.floor(area(feat)), 'surface'];
    case 'LineString':
    case 'MultiLineString':
      return [
        Math.floor(convertLength(length(feat), 'kilometers', 'meters')),
        'length',
      ];
  }
}

export function getHelpTextKey(
  activeMode: MapboxDraw.DrawMode,
  hasUserStartedDrawing = false,
  isPointPlaced = false,
) {
  switch (activeMode) {
    case 'simple_select':
      if (isAppleDevice()) {
        return 'selectWithArrow';
      }
      return 'selectWithShift';
    case 'direct_select':
      if (isAppleDevice()) {
        return 'selectVertexWithArrow';
      }
      return 'selectVertexWithShift';
    case 'draw_line_string':
      if (hasUserStartedDrawing) {
        return 'continueDrawingLine';
      }
      return 'startDrawingLine';
    case 'draw_polygon':
      if (hasUserStartedDrawing) {
        return 'continueDrawingPolygon';
      }
      return 'startDrawingPolygon';
    case 'draw_point':
      if (isPointPlaced) {
        return 'replacePoint';
      }
      return 'newPoint';
    default:
      return 'selectTool';
  }
}
