import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import { t } from 'i18next';
import type React from 'react';

interface IProps {
  setRRuleGenerator(display: boolean): void;
  onChange(value?: Option | null): void;
  value?: Option | null;
  dtStart?: Date;
}

export const staticQuickRRules: Option[] = [
  { label: t('recurrence.once'), value: 'NORRULE' },
  {
    label: `${t('recurrence.every')} ${t('recurrence.days')}`,
    value: 'RRULE:FREQ=DAILY',
  },
  {
    label: `${t('recurrence.every')} ${t('recurrence.week')}`,
    value: 'RRULE:FREQ=WEEKLY',
  },
  {
    label: `${t('recurrence.every')} ${t('recurrence.month')}`,
    value: 'RRULE:FREQ=MONTHLY',
  },
  {
    label: `${t('recurrence.every')} ${t('recurrence.days')} ${t('recurrence.fromMondayToFriday')}`,
    value: 'RRULE:FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR',
  },
  { label: `${t('recurrence.custom')}...`, value: 'CUSTOM' },
];

/**
 * Quick RRule select
 */
export const RRuleSelect: React.FC<IProps> = ({
  setRRuleGenerator,
  onChange: dispatchChangeEvent,
  value,
}) => {
  const onChange = (newValue?: Option | Option[] | null): void => {
    if (Array.isArray(newValue)) return;
    setRRuleGenerator(newValue?.value === 'CUSTOM');
    dispatchChangeEvent(newValue);
  };

  return (
    <SelectInput
      width={'fluid'}
      options={staticQuickRRules}
      value={value || null}
      onChange={onChange}
      isMultiple={false}
      isClearable={false}
    />
  );
};
