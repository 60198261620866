import { captureException } from '@sentry/react';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { BaseObjectView } from '@smack/core/api/models/views/BaseObjectView/BaseObjectView';
import type { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import { DevAlert } from '@smack/core/components/DataDisplay/Alerts/Alerts/Components/DevAlert/DevAlert';
import { BaseObjectViewRenderer } from '@smack/core/components/ViewRenderer/BaseObjectViewRenderer';
import type {
  BaseObjectFormOnSubmit,
  BaseObjectFormViewRenderForwardedRef,
  ViewLayoutComponent,
} from '@smack/core/components/ViewRenderer/interfaces';
import { layoutTypeMap } from '@smack/core/components/ViewRenderer/layoutTypeMap';
import { BaseObjectViewLoader } from '@smack/core/components/ViewRenderer/loaders/BaseObjectViewLoader';
import type { IFilters } from '@smack/core/utils/Filters';
import type { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';

interface AutoBaseObjectViewProps {
  baseObject?: BaseObject;
  categoryId?: number;
  scheduleId?: number;
  toggleableFields?: boolean;
  filters?: IFilters;
  LayoutComponent?: ViewLayoutComponent;
  viewUsage?: ViewUsage;
  viewId?: number;
  onSubmit?: BaseObjectFormOnSubmit;
}

const AutoBaseObjectViewRender: React.ForwardRefRenderFunction<
  BaseObjectFormViewRenderForwardedRef,
  AutoBaseObjectViewProps
> = (
  {
    baseObject,
    categoryId,
    filters,
    scheduleId,
    toggleableFields,
    viewUsage,
    viewId,
    LayoutComponent = viewUsage ? layoutTypeMap[viewUsage] : undefined,
    onSubmit,
  },
  ref,
) => {
  const [view, setView] = useState<BaseObjectView>();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState<AxiosError>();
  const isViewUsageUnique =
    viewUsage !== undefined && BaseObjectView.isViewUsageUnique(viewUsage);
  const arePropsValid = !!(viewId || isViewUsageUnique);

  useEffect(() => {
    if (fetchError) {
      captureException(fetchError, {
        extra: {
          props: {
            baseObject,
            viewUsage,
            viewId,
          },
        },
        level: 'warning',
      });
    }
  }, [fetchError]);

  useEffect(() => {
    if (!baseObject && !categoryId) {
      captureException(
        new TypeError('No baseObject or categoryId was provided'),
      );
      return;
    }
    setIsLoading(true);
    if (viewId) {
      BaseObjectView.getBaseObjectView(
        viewId,
        baseObject?.id,
        undefined,
        scheduleId,
      )
        .then(setView)
        .catch(setFetchError)
        .finally(() => setIsLoading(false));
    } else if (isViewUsageUnique) {
      BaseObjectView.getBaseObjectViewByViewType(
        viewUsage,
        baseObject?.id,
        categoryId,
        scheduleId,
      )
        .then(setView)
        .catch(setFetchError)
        .finally(() => setIsLoading(false));
    } else {
      captureException(
        new TypeError('No view id or unique view usage was provided'),
      );
    }
  }, [baseObject, viewUsage, viewId]);

  if (!arePropsValid) {
    return (
      <DevAlert title="<AutoBaseObjectView />">
        No view id or unique view usage was provided
      </DevAlert>
    );
  }

  if (isLoading && viewUsage) {
    return <BaseObjectViewLoader viewUsage={viewUsage} />;
  }

  if (view) {
    return (
      <BaseObjectViewRenderer
        ref={ref}
        view={view}
        filters={filters}
        LayoutComponent={LayoutComponent}
        baseObject={baseObject}
        toggleableFields={toggleableFields}
        scheduleId={scheduleId}
        categoryId={categoryId}
        onSubmit={onSubmit}
      />
    );
  }

  if (fetchError && viewUsage) {
    if (!LayoutComponent) return null;
    return <LayoutComponent.Error viewUsage={viewUsage} />;
  }

  return null;
};

export const AutoBaseObjectView = React.forwardRef(AutoBaseObjectViewRender);
