import type { Task } from '@smack/core/api/models/tasks/Task';
import { DropDown } from '@smack/core/components/DataDisplay/DropDowns/DropDown';
import { useChangeStatus } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Hooks/useChangeStatus';
import type React from 'react';

export const ChangeStatus: React.FC<{
  task: Task;
  reloadProject: () => Promise<void>;
  children: (isChangingStatus: boolean) => React.ReactNode;
}> = ({ children, task, reloadProject }) => {
  const [menuItems, , isChangingStatus] = useChangeStatus(task, reloadProject);

  return (
    <DropDown menuItems={menuItems ?? []} placement="bottom-start">
      {children(isChangingStatus)}
    </DropDown>
  );
};
