import {
  type Placement,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import { type FC, useState } from 'react';

interface MapButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  icon?: IconField;
  tooltip?: { text: string; placement: Placement };
}

const getMapButton = (
  icon?: IconField,
  buttonProps?: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
) => {
  return (
    <button type="button" {...buttonProps}>
      {icon ? <Icon icon={icon} /> : null}
    </button>
  );
};

const FloatingMapButton: FC<MapButtonProps> = ({
  icon,
  tooltip: { text, placement } = {},
  ...buttonProps
}) => {
  const [active, setActive] = useState<boolean>(false);
  const { floatingStyles, refs, context } = useFloating({
    strategy: 'fixed',
    open: active,
    onOpenChange: setActive,
    placement,
    middleware: [shift(), offset(10), flip()],
  });

  const hover = useHover(context);
  const dismiss = useDismiss(context, {
    ancestorScroll: true,
  });
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    dismiss,
    role,
  ]);

  return (
    <>
      {active ? (
        <div
          style={floatingStyles}
          className="w-max py-1 px-1.5 bg-white shadow-maplibre rounded text-sm"
          {...getFloatingProps()}
          ref={refs.setFloating}
        >
          {text}
        </div>
      ) : null}
      {getMapButton(icon, {
        ref: refs.setReference,
        ...getReferenceProps(buttonProps),
      })}
    </>
  );
};

export const MapButton: FC<MapButtonProps> = ({
  icon,
  tooltip,
  ...buttonProps
}) => {
  if (tooltip)
    return <FloatingMapButton icon={icon} tooltip={tooltip} {...buttonProps} />;

  return getMapButton(icon, buttonProps);
};
