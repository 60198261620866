import type { Geometry } from 'geojson';

export function getCoordinatesForPopup(geometry: Geometry): [number, number] {
  switch (geometry.type) {
    case 'Point':
      return geometry.coordinates as [number, number];
    case 'MultiPoint':
    case 'LineString':
      return geometry.coordinates[0] as [number, number];
    case 'Polygon':
    case 'MultiLineString':
      return geometry.coordinates[0][0] as [number, number];
    case 'MultiPolygon':
      return geometry.coordinates[0][0][0] as [number, number];
    case 'GeometryCollection':
      return getCoordinatesForPopup(geometry.geometries[0]);
  }
}
