import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import { cva } from 'class-variance-authority';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { COLORS, DEFAULT_THEME, THEME_DATA } from '../constants';

import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { Transition } from '@headlessui/react';
import { ChevronIcon, CloseIcon } from './Icons';
import Options from './Options';
import SearchInput from './SearchInput';
import SelectProvider from './SelectProvider';
import type { GroupOption, Options as ListOption, Option } from './type';

export interface SelectProps {
  id?: string;
  count?: number;
  options?: ListOption;
  value: Option | Option[] | null;
  onChange: (value?: Option | Option[] | null) => void;
  placeholder?: string;
  isMultiple?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  onMenuOpen?: () => void;
  isDisabled?: boolean;
  loading?: boolean;
  menuIsOpen?: boolean;
  searchInputPlaceholder?: string;
  noOptionsMessage?: string;
  noOptionsBeforeSearchMessage?: string;
  primaryColor?: string;
  needFilter?: boolean;
  formatGroupLabel?: ((data: GroupOption) => JSX.Element) | null;
  formatOptionLabel?: ((data: Option) => JSX.Element) | null;
  onSearch?: (value: string) => void;
  onLastOptionVisible?: () => void;
  isLoaded?: boolean;
  rightContainerIcon?: IconField;
  onClick?: () => void;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  miniInput?: boolean;
  classNames?: {
    menuButton?: ({ isDisabled }: { isDisabled: boolean }) => string;
    menu?: string;
    tagItem?: ({ isDisabled }: { isDisabled: boolean }) => string;
    tagItemText?: string;
    tagItemIconContainer?: string;
    tagItemIcon?: string;
    list?: string;
    listGroupLabel?: string;
    listItem?: ({ isSelected }: { isSelected: boolean }) => string;
    listDisabledItem?: string;
    ChevronIcon?: string;
    searchContainer?: string;
    searchBox?: string;
    searchIcon?: string;
    closeIcon?: string;
    container?: string;
  };
}

const Select: React.FC<SelectProps> = ({
  options = [],
  value = null,
  onChange,
  id,
  placeholder = 'Select...',
  searchInputPlaceholder = 'Search...',
  isMultiple = false,
  isClearable = false,
  isSearchable = false,
  isDisabled = false,
  isLoaded = false,
  noOptionsBeforeSearchMessage = '',
  loading = false,
  count,
  menuIsOpen = false,
  onMenuOpen,
  needFilter = true,
  noOptionsMessage = 'No options found',
  primaryColor = DEFAULT_THEME,
  formatGroupLabel = null,
  formatOptionLabel = null,
  rightContainerIcon,
  onClick,
  onSearch,
  onLastOptionVisible,
  prefix,
  suffix,
  miniInput = false,
  classNames,
}) => {
  const [list, setList] = useState<ListOption>(options);
  const [inputValue, setInputValue] = useState<string>('');

  const nodeId = useFloatingNodeId();
  const [open, setOpen] = useState(false);

  const { x, y, refs, strategy, context } = useFloating({
    strategy: 'absolute',
    open: open,
    onOpenChange: (val) => {
      if (!isDisabled && !onClick) {
        if (val) onMenuOpen?.();
        setOpen(val);
        onMenuOpen?.();
      }
    },
    nodeId,
    placement: 'bottom-end',
    whileElementsMounted(referenceEl, floatingEl, update) {
      return autoUpdate(referenceEl, floatingEl, update, {
        elementResize: true,
      });
    },
    middleware: [flip(), shift(), offset({ mainAxis: 0, alignmentAxis: 0 })],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context),
  ]);

  const toggle = useCallback(() => {
    if (!isDisabled) {
      if (!open) onMenuOpen?.();
      setOpen(!open);
    }
  }, [isDisabled, open]);

  const closeDropDown = useCallback(() => {
    if (open) setOpen(false);
  }, [open]);

  useEffect(() => {
    const formatItem = (item: Option): Option => {
      if ('disabled' in item) return item;
      return {
        ...item,
        disabled: false,
      };
    };

    setList(
      options.map((item) => {
        if ('options' in item) {
          return {
            label: item.label,
            options: item.options.map(formatItem),
          };
        }
        return formatItem(item);
      }),
    );
  }, [options]);

  const onPressEnterOrSpace = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      e.preventDefault();
      if ((e.code === 'Enter' || e.code === 'Space') && !isDisabled) {
        toggle();
      }
    },
    [isDisabled, toggle],
  );

  const handleValueChange = useCallback(
    (selected: Option) => {
      function update(): void {
        if (!isMultiple && !Array.isArray(value)) {
          closeDropDown();
          onChange(selected);
        }

        if (isMultiple && (Array.isArray(value) || value === null)) {
          onChange(value === null ? [selected] : [...value, selected]);
        }

        if (selected.closeDropdownOnClick) {
          closeDropDown();
        }
      }

      if (selected !== value) {
        update();
      }
    },
    [closeDropDown, isMultiple, onChange, value],
  );

  const clearValue = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onChange(null);
    },
    [onChange],
  );

  const removeItem = useCallback(
    (e: React.MouseEvent<HTMLDivElement>, item: Option) => {
      if (isMultiple && Array.isArray(value) && value.length) {
        e.stopPropagation();
        const result = value.filter((current) => item.value !== current.value);
        onChange(result.length ? result : null);
      }
    },
    [isMultiple, onChange, value],
  );

  const getSelectClass = useCallback(() => {
    let ringColor = THEME_DATA.ring[DEFAULT_THEME];
    if (COLORS.includes(primaryColor)) {
      ringColor = THEME_DATA.ring[primaryColor] as string;
    }

    let borderFocus = THEME_DATA.borderFocus[DEFAULT_THEME];
    if (COLORS.includes(primaryColor)) {
      borderFocus = THEME_DATA.borderFocus[primaryColor] as string;
    }

    const classVariants = cva(
      'flex text-sm text-gray-500 border border-gray-300 dark:border-neutral-500 rounded shadow-sm transition-all duration-300 focus:outline-none',
      {
        variants: {
          disabled: {
            true: 'bg-gray-200',
            false: `bg-white hover:border-gray-400 ${borderFocus} focus:ring ${ringColor}`,
          },
        },
        defaultVariants: {
          disabled: true,
        },
      },
    );

    return classNames?.menuButton
      ? classNames.menuButton({ isDisabled })
      : classVariants({ disabled: isDisabled });
  }, [classNames, isDisabled, primaryColor]);

  const getTagItemClass = useCallback(() => {
    const classVariants = cva(
      'bg-gray-200 border  dark:border-gray-600 rounded-sm flex space-x-1 items-center',
      {
        variants: {
          disabled: {
            true: 'border-gray-500  px-1',
            false: 'pl-1',
          },
        },
        defaultVariants: {
          disabled: true,
        },
      },
    );
    return classNames?.tagItem
      ? classNames.tagItem({ isDisabled })
      : classVariants({ disabled: isDisabled });
  }, [classNames, isDisabled]);

  const placeholderNode = <span className="text-gray-400">{placeholder}</span>;

  return (
    <SelectProvider
      otherData={{
        formatGroupLabel,
        formatOptionLabel,
        classNames,
      }}
      value={value}
      handleValueChange={handleValueChange}
    >
      <div className={classNames?.container ?? 'relative w-full'}>
        <div
          tabIndex={0}
          role="listbox"
          aria-labelledby={id}
          aria-expanded={open}
          onKeyDown={onPressEnterOrSpace}
          className={getSelectClass()}
          key={nodeId}
          ref={refs.setReference}
          {...getReferenceProps({
            onClick(e) {
              onClick?.();
              e.stopPropagation();
            },
          })}
        >
          {prefix ? (
            <div className="border-r flex items-center px-3 bg-gray-50 dark:bg-gray-700 text-gray-500 sm:text-sm whitespace-nowrap">
              {prefix}
            </div>
          ) : null}
          {!miniInput ? (
            <div className="min-w-0 grow pl-2.5 py-2 pr-2 flex flex-wrap gap-1 bg-primary dark:bg-view">
              {!isMultiple ? (
                <div className="min-w-0 flex items-center">
                  {!Array.isArray(value) && value?.icon?.name && (
                    <Icon
                      icon={value.icon}
                      color={value.color || '#000'}
                      className={'mr-2'}
                    />
                  )}
                  {!Array.isArray(value) &&
                    !value?.icon?.name &&
                    value?.color && (
                      <div
                        style={{ backgroundColor: value?.color }}
                        className={
                          ' w-4 h-4 rounded-full mr-2 flex items-center justify-center'
                        }
                      />
                    )}
                  <p
                    title={
                      value && !Array.isArray(value)
                        ? value.label.toString()
                        : ''
                    }
                    className="text-text truncate cursor-default select-none"
                  >
                    {value && !Array.isArray(value)
                      ? value.label
                      : placeholderNode}
                  </p>
                </div>
              ) : (
                <>
                  {value === null && placeholderNode}

                  {Array.isArray(value) &&
                    value.map((item, index) => (
                      // biome-ignore lint/suspicious/noArrayIndexKey: FIXME
                      <div className={getTagItemClass()} key={index}>
                        {item.icon?.name && (
                          <Icon
                            icon={item.icon}
                            color={item.color || '#000'}
                            className={'mr-2'}
                          />
                        )}
                        {!item.icon?.name && item.color && (
                          <div
                            style={{ backgroundColor: item.color }}
                            className={
                              ' w-4 h-4 rounded-full mr-2 flex items-center justify-center'
                            }
                          />
                        )}

                        <p
                          className={
                            classNames?.tagItemText
                              ? classNames.tagItemText
                              : 'text-text truncate cursor-default select-none whitespace-normal'
                          }
                        >
                          {item.label}
                        </p>
                        {!isDisabled && (
                          <div
                            onClick={(e): void => removeItem(e, item)}
                            className={
                              classNames?.tagItemIconContainer
                                ? classNames.tagItemIconContainer
                                : 'flex h-full items-center px-1 cursor-pointer rounded-r-sm hover:bg-red-200 hover:text-red-600'
                            }
                          >
                            <CloseIcon
                              className={
                                classNames?.tagItemIcon
                                  ? classNames.tagItemIcon
                                  : 'w-3 h-3 mt-0.5'
                              }
                            />
                          </div>
                        )}
                      </div>
                    ))}
                </>
              )}
            </div>
          ) : null}
          <div className="flex flex-none items-center py-1.5 bg-primary dark:bg-view">
            {!isMultiple && isClearable && !isDisabled && value !== null && (
              <div className="px-1.5 cursor-pointer" onClick={clearValue}>
                <CloseIcon
                  className={
                    classNames?.closeIcon
                      ? classNames.closeIcon
                      : 'w-5 h-5 p-0.5 dark:text-gray-400'
                  }
                />
              </div>
            )}

            {!miniInput ? (
              <div className="h-full">
                <span className="w-px h-full inline-block text-white bg-gray-300 text-opacity-0" />
              </div>
            ) : null}

            <div className="px-1.5">
              {rightContainerIcon ? (
                <div className={'w-6 h-6 flex items-center justify-center'}>
                  <Icon
                    icon={rightContainerIcon}
                    className={'p-0.5 text-gray-500'}
                  />
                </div>
              ) : (
                <ChevronIcon
                  className={`transition duration-300 w-6 h-6 p-0.5${
                    open
                      ? ' transform rotate-90 text-gray-500'
                      : ' text-gray-300 '
                  } ${classNames?.ChevronIcon || ''}`}
                />
              )}
            </div>
          </div>
          {suffix ? (
            <div className="border-l flex items-center px-3 bg-gray-50 dark:bg-gray-700 text-gray-500 sm:text-sm whitespace-nowrap">
              {suffix}
            </div>
          ) : null}
        </div>
        {open && !isDisabled && (
          <Transition
            as={'div'}
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            tabIndex={-1}
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              width: 'max-content',
            }}
            {...getFloatingProps()}
            className={
              classNames?.menu
                ? classNames.menu
                : 'z-[1000] w-full min-w-[200px] bg-white dark:bg-neutral-700 shadow-lg border dark:border-neutral-500  py-1  text-sm text-gray-700'
            }
          >
            {isSearchable && (
              <SearchInput
                isLoading={!isLoaded}
                value={inputValue}
                placeholder={searchInputPlaceholder}
                onChange={(e): void => {
                  onSearch?.(e.target.value);
                  setInputValue(e.target.value);
                }}
              />
            )}

            <Options
              list={list}
              loading={loading}
              noOptionsMessage={noOptionsMessage}
              noOptionsBeforeSearchMessage={
                isSearchable ? noOptionsBeforeSearchMessage : undefined
              }
              text={inputValue}
              count={count}
              isLoaded={isLoaded}
              isMultiple={isMultiple}
              value={value}
              onLastOptionVisible={onLastOptionVisible}
              primaryColor={primaryColor || DEFAULT_THEME}
              needFilter={needFilter}
            />
          </Transition>
        )}
      </div>
    </SelectProvider>
  );
};

export default Select;
