import {
  type IMessageTemplate,
  MessageTemplate,
} from '../../notifications/MessageTemplate';

export interface ITaskMessage {
  id: number;
  label?: string;
  messageTemplate?: IMessageTemplate;
  task?: number;
}

export class TaskMessage {
  id: number;

  label?: string;

  messageTemplate?: MessageTemplate;

  task?: number;

  constructor(data: ITaskMessage) {
    this.id = data.id;
    this.label = data.label;
    if (data.messageTemplate) {
      this.messageTemplate = new MessageTemplate(data.messageTemplate);
    }
    this.task = data.task;
  }
}
