import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { BaseObjectView } from '@smack/core/api/models/views/BaseObjectView/BaseObjectView';
import type { ViewElement } from '@smack/core/api/models/views/ViewElement/ViewElement';
import type { ViewSection } from '@smack/core/api/models/views/ViewSection/ViewSection';
import { ErrorHandlerForInput } from '@smack/core/components/DataInput/ErrorHandlerForInput';
import type { RenderedBaseObjectViewSections } from '@smack/core/components/ViewRenderer/interfaces';
import { ViewElementRenderer } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRenderer';
import { defaultValidationRules } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeFieldsByType/ValidationRules/ValidationRules';
import type { ViewElementRendererProps } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/type';
import { ViewRulesController } from '@smack/core/components/ViewRenderer/rules/ViewRulesController/ViewRulesController';
import { ViewName } from '@smack/core/hooks/views/types';
import store from '@smack/core/store';
import { setCurrentView } from '@smack/core/store/app/actions';
import type React from 'react';
import type {
  Control,
  ControllerRenderProps,
  FieldError,
} from 'react-hook-form';
import { getI18n } from 'react-i18next';

interface RenderSectionsParameters {
  view: BaseObjectView;
  baseObject?: BaseObject;
  categoryId?: number;
  control?: Control;
  additionalProps?: ViewElementRendererProps['props'];
}

export function renderSections({
  view,
  baseObject,
  control,
  categoryId,
  additionalProps,
}: RenderSectionsParameters): RenderedBaseObjectViewSections {
  const renderField = (
    viewElement: ViewElement,
    viewSection: ViewSection,
    controllerProps?: Partial<ControllerRenderProps>,
    errors?: FieldError,
  ) => {
    return (
      <ErrorHandlerForInput
        title={getI18n().t('formErrors.errorTitle')}
        text={getI18n().t('formErrors.dataTypeOnFieldError')}
      >
        <ViewElementRenderer
          viewElement={viewElement}
          props={{
            viewElement,
            viewSection,
            standaloneField: view.isFormOrQuickForm ? undefined : {},
            baseObject,
            categoryId: categoryId,
            error: errors,
            controllerProps: controllerProps,
            viewUsage: view.usage,
            onClickShowDashboard(displayedButton) {
              if (!baseObject || !displayedButton.action) return;
              store.dispatch(
                setCurrentView({
                  view: ViewName.Dashboard,
                  params: {
                    dashboardDisplayAction: displayedButton.action,
                    baseobjectId: baseObject.id,
                  },
                }),
              );
            },
            ...additionalProps,
          }}
        />
      </ErrorHandlerForInput>
    );
  };

  const controlField = (
    viewElement: ViewElement,
    viewSection: ViewSection,
  ): React.ReactNode => {
    if (!view.isFormOrQuickForm || !viewElement.fieldTypeIsFormField())
      return renderField(viewElement, viewSection);
    const attributeId = viewElement.attribute?.id;
    return (
      <ViewRulesController
        control={control}
        viewElement={viewElement}
        defaultValue={viewElement.getFieldTypeDefaultValue()}
        render={({ field, formState }) => {
          return renderField(
            viewElement,
            viewSection,
            {
              name: field.name,
              value: field.value,
              onChange: field.onChange,
            },
            formState.errors[attributeId ?? ''] as FieldError,
          ) as React.ReactElement;
        }}
        rules={{
          validate: (value) => {
            const validator = viewElement.getFieldTypeValidationRules();
            if (!validator) return defaultValidationRules(value, viewElement);
            return validator(value, viewElement);
          },
        }}
        name={attributeId?.toString() ?? ''}
      />
    );
  };

  return view.viewSections.map((viewSection) => {
    return [
      viewSection,
      viewSection.viewElements.map((viewElement) => {
        return [viewElement, controlField(viewElement, viewSection)] as const;
      }),
    ] as const;
  });
}
