import type {
  IconPack,
  IconPrefix,
} from '@fortawesome/fontawesome-common-types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { type FC, useEffect, useState } from 'react';

interface FontAwesomeIconLoaderProps {
  iconPrefix: IconPrefix;
  children: React.ReactNode;
}

const fontAwesomeImportMap = {
  fad: async () => (await import('@fortawesome/pro-duotone-svg-icons')).fad,
  fal: async () => (await import('@fortawesome/pro-light-svg-icons')).fal,
  far: async () => (await import('@fortawesome/pro-regular-svg-icons')).far,
  fas: async () => (await import('@fortawesome/pro-solid-svg-icons')).fas,
  fat: async () => (await import('@fortawesome/pro-thin-svg-icons')).fat,
  fasl: async () => (await import('@fortawesome/sharp-light-svg-icons')).fasl,
  fasr: async () => (await import('@fortawesome/sharp-regular-svg-icons')).fasr,
  fass: async () => (await import('@fortawesome/sharp-solid-svg-icons')).fass,
  fast: async () => (await import('@fortawesome/sharp-thin-svg-icons')).fast,
  fab: async () => (await import('@fortawesome/free-brands-svg-icons')).fab,
  fak: async () => ({}),
} satisfies { [prefix in IconPrefix]: () => Promise<IconPack> };

const loadedFamilies: IconPrefix[] = [];
const pendingFamilies = new Map<IconPrefix, Promise<void>>();

async function fetchFamilyAndAddToLibrary(familyStyle: IconPrefix) {
  const iconPack = await fontAwesomeImportMap[familyStyle]();
  library.add(iconPack);
  loadedFamilies.push(familyStyle);
}

function loadFamily(familyStyle: IconPrefix): Promise<void> {
  if (loadedFamilies.includes(familyStyle)) return Promise.resolve();
  if (pendingFamilies.has(familyStyle))
    // biome-ignore lint/style/noNonNullAssertion: has() check is done just before
    return pendingFamilies.get(familyStyle)!;
  const promise = fetchFamilyAndAddToLibrary(familyStyle);
  pendingFamilies.set(familyStyle, promise);
  promise.finally(() => pendingFamilies.delete(familyStyle));
  return promise;
}

export const FontAwesomeFamilyLoader: FC<FontAwesomeIconLoaderProps> = ({
  iconPrefix,
  children,
}) => {
  const [isFamilyLoaded, setIsFamilyLoaded] = useState(
    loadedFamilies.includes(iconPrefix),
  );

  useEffect(() => {
    let ignore = false;
    loadFamily(iconPrefix).then(() => {
      // Do not set state for outdated iconPrefix value
      // This also avoid referencing DOM APIs in tests teardown
      if (!ignore) setIsFamilyLoaded(true);
    });
    return () => {
      ignore = true;
    };
  }, [iconPrefix]);

  // Take the space of an average FA icon does to avoid layout shift
  if (!isFamilyLoaded) return <span className="w-4 h-4 mr-1" />;

  return <>{children}</>;
};
