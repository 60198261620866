import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import {
  Action,
  type IAction,
} from '@smack/core/api/models/actions/Action/Action';
import { CommentsManager } from '@smack/core/api/models/comments';
import { MediasManager } from '@smack/core/api/models/medias';
import {
  Checklist,
  type IChecklist,
} from '@smack/core/api/models/tasks/Checklist';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import type { AxiosResponse } from 'axios';
import moment, { type Moment } from 'moment';
import { type IStatus, Status } from '../Status';
import { SubscribeManager } from '../SubscribeManager';
import { TaskRule } from '../TaskRule';
/**
  TASK MODEL
*/

export interface ITask {
  id: number;
  label?: string;
  description?: string;
  icon?: IconField;
  color?: string;
  startAt?: string;
  estimatedStartAt?: string;
  deadlineAt?: string;
  endAt?: string;
  position?: number;
  status?: IStatus;
  duration?: string;
  taskActions?: IAction[];
  isConflictCheck?: boolean;
  isSubscribed?: boolean;
  isBinaryChoice?: boolean;
  isMilestone?: boolean;
  checklists?: IChecklist[];
  taskFormViewId?: number;
}

interface ITaskPatchData
  extends Omit<
    Partial<ITask>,
    'status' | 'id' | 'taskActions' | 'users' | 'isSubscribed'
  > {
  status?: number;
}

export class Task {
  mediasManager: MediasManager;

  commentsManager: CommentsManager;

  subscribeManager: SubscribeManager;

  id: number;

  label?: string;

  description?: string;

  icon?: IconField;

  color?: string;

  startAt?: Moment;

  estimatedStartAt?: Moment;

  deadlineAt?: Moment;

  endAt?: Moment;

  position?: number;

  status?: Status;

  duration?: string;

  taskActions?: Action[];

  isConflictCheck?: boolean;

  isBinaryChoice?: boolean;

  isMilestone?: boolean;

  checklists: Checklist[];

  taskFormViewId?: number;

  constructor(data: ITask) {
    const instanceAPIPath = `/tasks/tasks/${data.id || ''}`;
    this.mediasManager = new MediasManager(instanceAPIPath);
    this.commentsManager = new CommentsManager(instanceAPIPath);
    this.subscribeManager = new SubscribeManager(
      instanceAPIPath,
      data.isSubscribed,
    );

    this.id = data.id;
    this.label = data.label;
    this.description = data.description;
    this.icon = data.icon;
    this.color = data.color;
    this.duration = data.duration;
    this.isConflictCheck = data.isConflictCheck;
    this.position = data.position;
    this.isBinaryChoice = data.isBinaryChoice;
    this.isMilestone = data.isMilestone;
    this.taskFormViewId = data.taskFormViewId;

    if (data.status) this.status = new Status(data.status);

    if (data.taskActions)
      this.taskActions = data.taskActions.map(
        (taskAction) => new Action(taskAction),
      );

    this.checklists =
      data.checklists?.map((checklist) => new Checklist(checklist)) || [];

    if (data.startAt) {
      this.startAt = moment(data.startAt);
    }
    if (data.estimatedStartAt) {
      this.startAt = moment(data.estimatedStartAt);
    }
    if (data.deadlineAt) {
      this.deadlineAt = moment(data.deadlineAt);
    }
    if (data.endAt) {
      this.endAt = moment(data.endAt);
    }
  }

  get isSubscribed(): boolean {
    return this.subscribeManager.isSubscribed || false;
  }

  /**
   * Patch method for Task
   * @param data {[key string]: string | number}
   * @returns Promise<void>
   */
  patch(
    data: {
      [key in keyof ITaskPatchData]: string | number | boolean;
    },
  ): Promise<AxiosResponse> {
    return RESTClient.patch(data, `/tasks/tasks/${this.id}`);
  }

  /**
   * Get task rules for a given task
   */
  getRules(): Promise<TaskRule[]> {
    return TaskRule.getRulesFromTaskId(this.id);
  }

  static createTask(
    groupId: number,
    statusId: number,
    label: string,
    position = 0,
    isMilestone = false,
    description?: string,
    deadlineAt?: string,
  ): Promise<AxiosResponse<void>> {
    return RESTClient.post(
      {
        group: groupId,
        status: statusId,
        label,
        position,
        description,
        deadlineAt,
        isMilestone,
      },
      '/tasks/tasks',
    );
  }
}
