import i18next from '@smack/core/utils/i18n/i18n';
import { format } from 'date-fns-tz';
import humanizeDuration from 'humanize-duration';
import moment, { type Duration, type Moment } from 'moment';

/**
 DATE UTILITY CLASSE
 */

export class DateUtils {
  /**
   * Get the actual dateTime
   * @returns Moment
   */
  static getNow(): Moment {
    return moment();
  }

  static getDateTimeString(date?: Moment): string {
    if (!date) return '';
    return moment(date)?.format('DD/MM/YYYY HH:mm');
  }

  static getDateTimeStringFull(date?: Moment): string {
    if (!date) return '';
    return moment(date)?.format(i18next.t('dateTime.fullFormat'));
  }

  static getDateString(date?: Moment): string {
    if (!date) return '';
    return moment(date)?.format('DD/MM/YYYY');
  }

  static toISOStringWithTimezone(date = new Date()): string {
    return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
  }

  /**
   * Get An humanize date from now (ex: en cours depuis un jour)
   * @param date Moment
   * @param status string
   * @param prefix boolean (if we want the moment prefix )
   * @param pastOnly boolean (if we only want past values)
   * @param firstMinuteRename string (if we want to replace the result for the first minute)
   * @returns string
   */
  static getFromNowText(
    date?: Moment,
    status?: string,
    prefix = false,
    pastOnly = false,
    firstMinuteRename = '',
  ): string {
    if (!date) return '';
    if (pastOnly) date = moment.min(date, DateUtils.getNow());
    let fromNow = date.fromNow(prefix);
    if (firstMinuteRename) {
      const minDiff = DateUtils.getNow().diff(date, 'm');
      if (minDiff >= 0 && minDiff < 1) fromNow = firstMinuteRename;
    }
    return `${status || ''} ${fromNow}`;
  }

  /**
   * Parse the DB duration (ex: "265 20:23:22")
   * @param duration string
   * @returns Duration | undefined
   */
  static parseDuration(duration?: string): Duration | undefined {
    if (duration) {
      return moment.duration(duration);
    }
  }

  static getDateRangeLocalString(
    start: Date,
    stop: Date,
    prefix = true,
    withTime = true,
  ): string {
    const local = i18next.t('dateTime.dateLocale');
    const translationData = {
      startDate: start.toLocaleDateString(local, { dateStyle: 'long' }),
      endDate: stop.toLocaleDateString(local, { dateStyle: 'long' }),
      startTime: withTime
        ? start.toLocaleTimeString(local, { timeStyle: 'short' })
        : '',
      endTime: withTime
        ? stop.toLocaleTimeString(local, { timeStyle: 'short' })
        : '',
    };
    const isSameDay = translationData.startDate === translationData.endDate;
    const translationContext = [
      prefix ? 'prefix' : 'noPrefix',
      withTime ? 'time' : 'noTime',
      isSameDay ? 'sameDate' : 'notSameDate',
    ].join('|');
    return i18next.t('dateTime.range', {
      ...translationData,
      context: translationContext,
    });
  }

  /**
   * get Humanized Duration (ex: 1 jour, 2 heures)
   * @param ms number
   * @returns string
   */
  static getHumanizeDurationFromMilliseconds(ms: number): string {
    return humanizeDuration(ms, {
      language: i18next.resolvedLanguage,
      units: ['y', 'mo', 'w', 'd', 'h', 'm'],
      round: true,
    });
  }

  static getDuration(start: Date, stop: Date): Duration | undefined {
    if (!start || !stop) return;
    const diff = moment(stop).diff(moment(start));
    return moment.duration(diff);
  }

  /**
   *  get Humanized Duration (ex: 1 jour, 2 heures)
   * @param duration string from db (ex: "265 20:23:22")
   * @returns string
   */
  static getHumanizeDuration(duration?: string): string | undefined {
    if (!duration) return;
    const parsedDuration = DateUtils.parseDuration(duration);
    if (!parsedDuration?.asMilliseconds()) return;
    return DateUtils.getHumanizeDurationFromMilliseconds(
      parsedDuration.asMilliseconds(),
    );
  }
}
