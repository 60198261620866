import {
  type IPagination,
  RESTClient,
} from '@smack/core/api/clients/rest/RESTClient';
import type { ParentObjectDisplayCardApiOutput } from '@smack/core/api/models/medias/types';
import { File, type FileApiOutput } from '../File';

/**
 * FOLDER MODEL
 */

export interface FolderApiOutput {
  id: number;
  uuid: string;
  label?: string;
  files?: FileApiOutput[];
  folders?: FolderApiOutput[];
  externalFilesystemId?: string | number;
  path?: string;
  virtualPath?: string;
  createdAt?: Date;
  modifiedAt?: Date;
  parentObject?: ParentObjectDisplayCardApiOutput;
  frontEndpoint?: string;
}

export interface FolderApiInput extends Pick<FolderApiOutput, 'label'> {
  parentFolderId?: number;
}

export class Folder {
  id: number;

  uuid: string;

  label?: string;

  files?: File[];

  folders?: Folder[];

  externalFilesystemId?: string | number;

  path?: string;

  virtualPath?: string;

  createdAt?: Date;

  modifiedAt?: Date;

  parentObject?: ParentObjectDisplayCardApiOutput;

  frontEndpoint?: string;

  constructor(data: FolderApiOutput) {
    this.id = data.id;
    this.uuid = data.uuid;
    this.label = data.label;
    this.files = data.files?.map((file) => new File(file));
    this.folders = data.folders?.map((folder) => new Folder(folder));
    this.externalFilesystemId = data.externalFilesystemId;
    this.path = data.path;
    this.virtualPath = data.virtualPath;
    this.createdAt = data.createdAt ? new Date(data.createdAt) : undefined;
    this.modifiedAt = data.modifiedAt ? new Date(data.modifiedAt) : undefined;
    this.parentObject = data.parentObject;
    this.frontEndpoint = data.frontEndpoint;
  }

  static async searchFolders(
    search: string,
    abortSignal?: AbortSignal,
    limit?: number,
    offset?: number,
  ): Promise<IPagination<Folder>> {
    const res = await RESTClient.post<IPagination<FolderApiOutput>>(
      {
        limit,
        offset,
        q: search,
      },
      '/medias/folders/search',
      undefined,
      undefined,
      abortSignal,
    );
    return {
      ...res.data,
      results: res.data.results.map((r) => new Folder(r)),
    };
  }
}
