import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import { getCountries, getCountryCallingCode } from 'libphonenumber-js';
import type { CountryCode } from 'libphonenumber-js/types';

const regionNames = new Intl.DisplayNames(undefined, { type: 'region' });

// The definition for European countries used here is "the countries that have their entire territory in Europe"
// as defined in https://fr.wikipedia.org/wiki/Europe#Politique
const europeanCountries: CountryCode[] = [
  'AL',
  'DE',
  'AD',
  'AT',
  'BE',
  'BY',
  'BA',
  'BG',
  'HR',
  'DK',
  'ES',
  'EE',
  'FI',
  'FR',
  'GR',
  'HU',
  'IE',
  'IS',
  'IT',
  'XK',
  'LV',
  'LI',
  'LT',
  'LU',
  'MK',
  'MT',
  'MD',
  'MC',
  'ME',
  'NO',
  'NL',
  'PL',
  'PT',
  'CZ',
  'RO',
  'GB',
  'SM',
  'RS',
  'SK',
  'SI',
  'SE',
  'CH',
  'UA',
  'VA',
];

export const phoneCountryToOption = (
  countryCode: CountryCode,
): Option<string> => ({
  value: countryCode,
  label: `${
    regionNames.of(countryCode.toUpperCase()) ?? countryCode
  } (+${getCountryCallingCode(countryCode)})`,
});

export const phoneCountriesAsOptions: Option[] =
  getCountries().map(phoneCountryToOption);

export const europeanPhoneCountriesAsOptions: Option[] =
  phoneCountriesAsOptions.filter((countryOption) =>
    europeanCountries.includes(countryOption.value as CountryCode),
  );

export const getPhoneCountriesAsOptions = (
  defaultCountryCode,
  useAllCountries = false,
): Option[] => {
  const phoneCountriesOptions = [
    ...(useAllCountries
      ? phoneCountriesAsOptions
      : europeanPhoneCountriesAsOptions),
  ];
  const [defaultCountry] = phoneCountriesOptions.splice(
    phoneCountriesOptions.findIndex((opt) => opt.value === defaultCountryCode),
    1,
  );
  phoneCountriesOptions.unshift(defaultCountry);
  return phoneCountriesOptions;
};

export const determinePhoneRegion = () => {
  for (const languageTag of navigator.languages) {
    const locale = new Intl.Locale(languageTag);
    if (locale.region) return locale.region;
  }
};
