import type { ViewElement } from '@smack/core/api/models/views/ViewElement/ViewElement';
import type { RRuleInputValue } from '@smack/core/components/DataInput/RRuleInput';
import React, { createContext, useContext } from 'react';
export interface IBaseObjectFormContext {
  formRRuleInputValue?: RRuleInputValue;
  setFormRRuleInputValue?: (data: RRuleInputValue) => void;
  isFormControllerToggleable?: boolean;
  controlledFormElements?: ViewElement[];
  setControlledFormElements?: (data: ViewElement[]) => void;
}
export const BaseObjectFormContext = createContext<IBaseObjectFormContext>({
  formRRuleInputValue: undefined,
  isFormControllerToggleable: false,
  setFormRRuleInputValue: () => {},
});
export const useBaseObjectFormContextContext = (): IBaseObjectFormContext =>
  useContext(BaseObjectFormContext);

interface IBaseObjectFormContextProviderProps {
  children?: React.ReactNode;
  isFormControllerToggleable?: boolean;
  formRRuleInputValue?: RRuleInputValue;
  setFormRRuleInputValue?: (data: RRuleInputValue) => void;
  controlledFormElements?: ViewElement[];
  setControlledFormElements?: (data: ViewElement[]) => void;
}

export const BaseObjectFormContextProvider: React.FC<
  IBaseObjectFormContextProviderProps
> = ({
  children,
  isFormControllerToggleable,
  setControlledFormElements,
  controlledFormElements,
  setFormRRuleInputValue,
  formRRuleInputValue,
}) => {
  const formContext: IBaseObjectFormContext = React.useMemo(() => {
    const output = {
      formRRuleInputValue,
      setFormRRuleInputValue,
    };

    if (isFormControllerToggleable) {
      Object.assign(output, {
        controlledFormElements,
        isFormControllerToggleable: true,
        setControlledFormElements,
      });
    }

    return output;
  }, [formRRuleInputValue, controlledFormElements]);

  return (
    <BaseObjectFormContext.Provider value={formContext}>
      {children}
    </BaseObjectFormContext.Provider>
  );
};
