import { env } from '@smack/core/env';

export const checkBackendHealth = async (): Promise<boolean> => {
  if (!navigator.onLine) return false;
  try {
    // Bypass locks of RESTClient
    const testResponse = await fetch(`${env.VITE_API_URL}/api/version/`);
    if (!testResponse.ok) return false;
  } catch (e) {
    return false;
  }
  return true;
};
