import {
  ApiError,
  type ApiErrorOutput,
  type AttributeErrorsFormat,
  type ErrorsFormat,
} from '@smack/core/api/errors/ApiError/ApiError';
import type { Attribute } from '@smack/core/api/models/categories/Attribute/Attribute';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { BaseObjectView } from '@smack/core/api/models/views/BaseObjectView/BaseObjectView';
import type { ViewElement } from '@smack/core/api/models/views/ViewElement/ViewElement';
import { Loader } from '@smack/core/components/Actions/Loader';
import { DangerAlert } from '@smack/core/components/DataDisplay/Alerts/Alerts';
import {
  ErrorsAlert,
  type ErrorsAlertProps,
} from '@smack/core/components/DataDisplay/Alerts/ErrorsAlert/ErrorsAlert';
import type { RRuleInputValue } from '@smack/core/components/DataInput/RRuleInput';
import type {
  BaseObjectFormOnSubmit,
  BaseObjectFormOutput,
  BaseObjectFormViewRenderForwardedRef,
  ViewLayoutComponent,
} from '@smack/core/components/ViewRenderer/interfaces';
import { layoutTypeMap } from '@smack/core/components/ViewRenderer/layoutTypeMap';
import { BaseObjectFallbackLayout } from '@smack/core/components/ViewRenderer/layouts/BaseObjectFallbackLayout';
import { renderSections } from '@smack/core/components/ViewRenderer/renderSections';
import { handleSubmitCheckRecurrence } from '@smack/core/components/ViewRenderer/renderers/BaseObjectFormViewRenderer/common/checkReccurenceOnSubmit';
import { formOutputToAttribute } from '@smack/core/components/ViewRenderer/renderers/BaseObjectFormViewRenderer/common/formOutputToAttribute';
import { getValuesFromViewAttributes } from '@smack/core/components/ViewRenderer/renderers/BaseObjectFormViewRenderer/common/getValuesFromViewAttributes';
import { BaseObjectFormContextProvider } from '@smack/core/components/ViewRenderer/renderers/BaseObjectFormViewRenderer/context/BaseObjectFormContext';
import { AttributeType } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeValueFromType/AttributeValueFromType';
import { assignRef } from '@smack/core/utils';
import type { IFilters } from '@smack/core/utils/Filters';
import type { TypeRecurrenceUpdateChoice } from '@smack/core/views/oldViewsToSort/Layouts/Modal/RecurrenceUpdateChoiceObjectModal';
import type { AxiosError } from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface BaseObjectDisplayViewRendererProps {
  view: BaseObjectView;
  filters?: IFilters;
  baseObject?: BaseObject;
  scheduleId?: number;
  LayoutComponent?: ViewLayoutComponent;
  categoryId?: number;
  toggleableFields?: boolean;
  onSubmit?: BaseObjectFormOnSubmit;
}

const BaseObjectFormViewRendererRender: React.ForwardRefRenderFunction<
  BaseObjectFormViewRenderForwardedRef,
  BaseObjectDisplayViewRendererProps
> = (
  {
    view,
    baseObject,
    scheduleId,
    categoryId,
    toggleableFields,
    filters,
    onSubmit,
    LayoutComponent = layoutTypeMap[view.usage] ?? BaseObjectFallbackLayout,
  },
  forwardRef,
) => {
  const form = useForm<BaseObjectFormOutput>();
  const [isLoading, setIsLoading] = React.useState<boolean>();
  const [formRRuleInputValue, setFormRRuleInputValue] =
    React.useState<RRuleInputValue>();
  const [controlledFormElements, setControlledFormElements] = React.useState<
    ViewElement[]
  >([]);
  const [attributeErrors, setAttributeErrors] =
    React.useState<AttributeErrorsFormat>();
  const [t] = useTranslation();

  const sections = React.useMemo(
    () =>
      renderSections({
        view,
        baseObject,
        categoryId,
        control: form.control,
      }),
    [view],
  );

  const getRecurrenceAttribute = (): Attribute | undefined => {
    let attribute: Attribute | undefined = undefined;
    for (const section of view.viewSections) {
      const recurrenceView = section.viewElements.find(
        (e) => e.attribute?.type === AttributeType.RECURRENCE,
      );
      if (recurrenceView) {
        attribute = recurrenceView.attribute;
      }
    }
    return attribute;
  };

  const handleProceedRequest = (
    data: BaseObjectFormOutput,
    recurrenceType?: TypeRecurrenceUpdateChoice,
  ): void => {
    setIsLoading(true);
    const attributes = formOutputToAttribute(data);
    onSubmit?.(attributes, recurrenceType, formRRuleInputValue)
      .catch((err: AxiosError<ApiErrorOutput>) => {
        if (!err.response) return;
        const apiError = new ApiError(err.response.data);
        setAttributeErrors(
          apiError.getErrorsByAttributesIdFromBaseObjectPayload({
            attributes: attributes,
          }),
        );
      })
      .finally(() => setIsLoading(false));
  };

  const filterFormOutput = (
    formOutput: BaseObjectFormOutput,
  ): BaseObjectFormOutput => {
    if (!toggleableFields) return formOutput;
    const controlledFields = controlledFormElements?.map((f) =>
      f.attribute?.id.toString(),
    );
    for (const key of Object.keys(formOutput)) {
      if (!controlledFields?.includes(key.toString())) {
        delete formOutput[key];
      }
    }
    return formOutput;
  };

  const handleOnSubmit = (formOutput: BaseObjectFormOutput) => {
    filterFormOutput(formOutput);
    if (baseObject) {
      handleSubmitCheckRecurrence(
        formOutput,
        baseObject,
        getRecurrenceAttribute(),
        (data, recurrenceType) => {
          handleProceedRequest(data, recurrenceType);
        },
      );
    } else {
      handleProceedRequest(formOutput);
    }
  };

  React.useEffect(() => {
    form.reset(getValuesFromViewAttributes(view));
  }, [view]);

  const getErrorsForAlert = (): ErrorsAlertProps['errors'] => {
    const output: ErrorsAlertProps['errors'] = {};
    if (!attributeErrors) return output;
    const attributes: Attribute[] = [];
    for (const section of view.viewSections) {
      for (const viewElement of section.viewElements) {
        if (!viewElement.attribute) continue;
        attributes.push(viewElement.attribute);
      }
    }

    for (const [key, values] of Object.entries(attributeErrors)) {
      const attribute = attributes.find(
        (att) => att.id?.toString() === key.toString(),
      );
      output[attribute?.label ?? key] = (values as ErrorsFormat[]).map(
        (value) => value.detail,
      );
    }

    return output;
  };

  return (
    <BaseObjectFormContextProvider
      formRRuleInputValue={formRRuleInputValue}
      setFormRRuleInputValue={setFormRRuleInputValue}
      controlledFormElements={controlledFormElements}
      setControlledFormElements={setControlledFormElements}
      isFormControllerToggleable={toggleableFields}
    >
      <div className="flex flex-grow flex-col">
        {Object.keys(form.formState.errors)?.length > 0 && (
          <DangerAlert title={t('formValidation.formNoValid')}>
            <p>{t('formValidation.requiredFields')}</p>
          </DangerAlert>
        )}
        {attributeErrors && <ErrorsAlert errors={getErrorsForAlert()} />}
        <Loader isDataLoaded={!isLoading} className={'flex flex-grow '}>
          <form
            data-testid={'BaseObjectFormViewRenderer.form'}
            onSubmit={(e) => {
              e.stopPropagation();
              form.handleSubmit(handleOnSubmit)(e);
            }}
            className={'flex flex-col flex-grow '}
            ref={(r) =>
              assignRef(forwardRef, {
                formRef: r ?? undefined,
                useForm: form,
                view: view,
              })
            }
          >
            <LayoutComponent renderedSections={sections} view={view} />
          </form>
        </Loader>
      </div>
    </BaseObjectFormContextProvider>
  );
};

export const BaseObjectFormViewRenderer = React.forwardRef(
  BaseObjectFormViewRendererRender,
);
