import React from 'react';

import { bulkRemoveSearchParams } from '@smack/core/utils/URLSearchParams';
import { addSearchParams } from '@smack/core/utils/URLs';
import { useNavigate, useSearchParams } from 'react-router-dom';

export type QueryParams = string;

const unwantedParameters = ['file', 'folder', 'task', 'next'] as const;
export function cleanSearchParams(params: URLSearchParams) {
  return bulkRemoveSearchParams(params, unwantedParameters);
}

export type IUseNavigationOutput = (
  link: string | number,
  options?: {
    replace?: boolean;
    removeParams?: string[];
    addParams?: Record<string, string>;
  },
) => void;

export const useNavigation = (): IUseNavigationOutput => {
  const navigation = useNavigate();
  const [originalSearchParams] = useSearchParams();

  return React.useCallback<IUseNavigationOutput>(
    (
      link,
      { replace = false, removeParams = [], addParams = {} } = {},
    ): void => {
      if (typeof link === 'number') {
        navigation(link);
        return;
      }
      let searchParams = originalSearchParams;
      searchParams = bulkRemoveSearchParams(
        cleanSearchParams(searchParams),
        removeParams,
      );
      const parametersToAdd = Object.entries(addParams);
      if (parametersToAdd.length) {
        parametersToAdd.forEach(([name, value]) => {
          searchParams.set(name, value);
        });
      }
      const newLink = addSearchParams(link, searchParams);
      navigation(newLink, { replace });
    },
    [navigation, originalSearchParams],
  );
};
