import type { IPermissions } from '@smack/core/api/models/categories/Category';
import { createContext, useContext } from 'react';

export const defaultShowIfAllowedContext = Object.freeze({
  isReadable: false,
  isWritable: false,
  isPreviewable: false,
  isProcessable: false,
  isShareable: false,
  isManageable: false,
});

export const ShowIfAllowedContext = createContext<IPermissions>(
  defaultShowIfAllowedContext,
);

export const useShowIfAllowedContext = (): IPermissions =>
  useContext(ShowIfAllowedContext);
