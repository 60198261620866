import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import {
  type INumberInputProps,
  NumberInput,
} from '@smack/core/components/DataInput/NumberInput/NumberInput';
import React from 'react';

export interface INumberIconInputProps extends INumberInputProps {
  value?: number;
  side?: 'left' | 'right';
  iconClassName?: string;
  spinners?: boolean;
}

export const NumberIconInput = React.forwardRef<
  HTMLInputElement,
  INumberIconInputProps
>(function NumberIconInput(
  {
    value,
    onChange,
    iconClassName,
    side = 'right',
    spinners = true,
    contentTextSize,
    ...NumberInputProps
  },
  ref,
) {
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [editedValue, setEditedValue] = React.useState<number>(value ?? 0);

  const editIcon = (
    <Icon
      icon={{ name: 'pen', familyStyle: 'fal' }}
      onClick={() => setEditMode(true)}
      className={`mt-0.5 text-gray-400 dark:text-gray-100 ${
        iconClassName || ''
      }`}
    />
  );
  const validateIcon = (
    <Icon
      icon={{ name: 'check' }}
      onClick={() => {
        if (onChange) onChange(editedValue);
        setEditMode(false);
      }}
      className={iconClassName}
    />
  );

  return (
    <div data-testid="number-icon-input">
      {editMode ? (
        <NumberInput
          value={editedValue}
          onChange={setEditedValue}
          style={{
            padding: '5% 10%',
            margin: '0',
            WebkitAppearance: spinners ? undefined : 'textfield',
          }}
          prefix={side === 'left' && validateIcon}
          suffix={side === 'right' && validateIcon}
          contentTextSize={contentTextSize}
          lang="en"
          {...NumberInputProps}
        />
      ) : (
        <span
          className={`flex place-content-end text-right gap-2 ${
            contentTextSize === 'xs' ? 'text-xs' : 'text-sm'
          }`}
        >
          {side === 'left' && editIcon}
          <span
            onDoubleClick={() => setEditMode(true)}
            data-testid="number-display"
          >
            {value}
          </span>
          {side === 'right' && editIcon}
        </span>
      )}
    </div>
  );
});
