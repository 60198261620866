import type { MapMode } from '@smack/core/store/maps/types';
import { keysOf } from '@smack/core/utils';
import { MapCluster } from '@smack/core/views/oldViewsToSort/Views/Map/Utils/Cluster';
import { buildLegacySourcesAndLayers } from '@smack/core/views/oldViewsToSort/Views/Map/Utils/Objectlayers';
import { modeToLayerMapping } from '@smack/core/views/oldViewsToSort/Views/Objects/ObjectMap/utils';
import type { Feature, FeatureCollection } from 'geojson';
import type { GeoJSONSource, MapLayerEventType } from 'maplibre-gl';

export const removeLayer = (): void => {
  const { MainMap } = window.Hyvilo.Utils;
  if (!MainMap) return;
  const { sources, layers } = buildLegacySourcesAndLayers();
  window.Hyvilo.Utils.Cluster?.disable();
  keysOf(sources).forEach((source) => {
    if (layers[source]) {
      layers[source].forEach((layer) => {
        if (MainMap.getLayer(layer.id)) MainMap.removeLayer(layer.id);
      });
    }
    if (MainMap.getSource(source)) MainMap.removeSource(source);
  });
};

export interface AbstractMapEvent {
  type: keyof MapLayerEventType;
  originalEvent: MouseEvent;
  features?: Feature[];
}

export const addLayer = (
  ClusterProperties: {
    counters: { [key: string]: number };
    colorList: string[];
  },
  onClick: (id: string) => void,
  hover: (e: AbstractMapEvent) => void,
): void => {
  // NOSONAR
  const { MainMap, Cluster } = window.Hyvilo.Utils;
  if (!MainMap) return;
  const { sources, layers } = buildLegacySourcesAndLayers(
    ClusterProperties.counters,
  );
  Cluster?.disable();

  window.Hyvilo.Utils.Cluster = new MapCluster({
    source_name: 'legacyCluster',
    spiderfy_layer: 'point-legacyCluster',
    onSpiderifyObjectClicked: (id: string): void => onClick(id),
    onSpiderLegHover: (feat, originalEvent): void => {
      hover({
        originalEvent,
        type: originalEvent.type === 'mouseenter' ? 'mousemove' : 'mouseleave',
        features: [feat],
      });
    },
    colorList: ClusterProperties.colorList,
  });
  window.Hyvilo.Utils.Cluster.init();

  keysOf(sources).forEach((source) => {
    if (!MainMap.getSource(source)) MainMap.addSource(source, sources[source]);
    if (layers[source]) {
      layers[source].forEach((layer) => {
        MainMap.addLayer(layer);
        // Hover effect
        MainMap.on('mousemove', layer.id, hover);
        MainMap.on('mouseleave', layer.id, hover);
        /* Desktop input */
        MainMap.on('click', layer.id, (e) => {
          const feature = e.features ? e.features[0] : undefined;
          if (feature?.properties?.frontEndpoint)
            onClick(feature?.properties?.frontEndpoint as string);
        });
        /* Mobile input */
        MainMap.on('touchstart', layer.id, (e) => {
          const feature = e.features ? e.features[0] : undefined;
          if (feature?.properties?.frontEndpoint)
            onClick(feature?.properties?.frontEndpoint as string);
        });
      });
    }
  });
};

// add source and layer to map;

export const initMakersLayers = (
  ClusterProperties: {
    counters: { [key: string]: number };
    colorList: string[];
  },
  onClick: (id: string) => void,
  hover: (e: AbstractMapEvent) => void,
): void => {
  const { MainMap } = window.Hyvilo.Utils;
  if (!MainMap) return;
  removeLayer();
  addLayer(ClusterProperties, onClick, hover);
};

// Print Markers and geometries

export const printMarkers = (
  data: FeatureCollection | Feature,
  mode: MapMode,
): void => {
  const { MainMap: MapInstance } = window.Hyvilo.Utils;
  if (!MapInstance) return;
  // Reconfigure legacy sources
  ['legacyCluster', 'legacyPoint', 'legacyHeatmap'].forEach((loopingMode) => {
    const otherSource = MapInstance.getSource(loopingMode) as GeoJSONSource;
    if (!otherSource) return;
    otherSource.setData(
      loopingMode === mode ? data : { type: 'FeatureCollection', features: [] },
    );
  });
  // Update new layers
  Object.entries(modeToLayerMapping).forEach(([loopingMode, layers]) => {
    layers.forEach((layerName) => {
      const layer = MapInstance.getLayer(layerName);
      if (!layer) return;
      MapInstance.setLayoutProperty(
        layerName,
        'visibility',
        loopingMode === mode ? 'visible' : 'none',
      );
    });
  });
};
