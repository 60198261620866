import type { ITemplateSkeletonComponent } from '.';

export const ListElementArrayViewSkeleton: ITemplateSkeletonComponent = ({
  offset = 0,
}) => (
  <>
    <circle cx="25" cy={25 + offset} r="12" />
    <rect x="50" y={20 + offset} rx="4" ry="4" height="10" width="100%" />
  </>
);

ListElementArrayViewSkeleton.itemOffset = 40;
