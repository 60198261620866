import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
/**
  STATUS MODEL
  */

export enum StatusType {
  UNDETERMINED = 'UNDETERMINED',
  PENDING = 'PENDING',
  ONGOING = 'ONGOING',
  FINISHED = 'FINISHED',
  CANCELED = 'CANCELED',
  ACCEPTED = 'ACCEPTED',
  DENIED = 'DENIED',
  HIDDEN = 'HIDDEN',
}

export interface IStatus {
  id: number;
  label?: string;
  description?: string;
  icon?: IconField;
  color?: string;
  isDefault?: boolean;
  statusType?: keyof typeof StatusType;
  duration?: number;
}

export class Status implements IStatus {
  id: number;

  label?: string;

  description?: string;

  icon?: IconField;

  color?: string;

  isDefault?: boolean;

  statusType?: StatusType;

  duration?: number;

  // Statuses that marks a binary choice as done
  static binaryChoiceStatuses: readonly Status['statusType'][] = [
    StatusType.ACCEPTED,
    StatusType.DENIED,
  ] as const;

  // Statuses that marks a task as completed
  // in the progress bar or other UI elements
  static completedStatuses: readonly Status['statusType'][] = [
    ...Status.binaryChoiceStatuses,
    StatusType.FINISHED,
    StatusType.CANCELED,
  ] as const;

  constructor(data: IStatus) {
    this.id = data.id;
    this.label = data.label;
    this.description = data.description;
    this.icon = data.icon;
    this.color = data.color;
    this.isDefault = data.isDefault;
    if (data.statusType) this.statusType = StatusType[data.statusType];
    this.duration = data.duration;
  }

  /**
   * Get All status from a project
   * @param projectId number
   * @returns Promise<Status[]>
   */
  static getStatusByProjectId(projectId: number): Promise<Status[]> {
    return RESTClient.get<{ data: { results?: IStatus[] } }>(
      `/tasks/projects/${projectId}/statuses`,
    ).then((res) => {
      return res.data?.results?.map((status) => new Status(status)) || [];
    });
  }

  get shouldUnfoldAutomatically(): boolean {
    return this.statusType === StatusType.ONGOING;
  }

  get isHidden(): boolean {
    return this.statusType === StatusType.HIDDEN;
  }

  get isBinaryOption(): boolean {
    return Status.binaryChoiceStatuses.includes(this.statusType);
  }

  get isCompleted(): boolean {
    return Status.completedStatuses.includes(this.statusType);
  }
}
