import {
  type IIconProps,
  Icon,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import React from 'react';

interface IInputIconProps {
  iconProps?: IIconProps;
  side?: 'left' | 'right';
  className?: string;
  error?: boolean;
  children: (className: string) => React.ReactNode;
}

const errorProps = Object.freeze({
  className: 'text-red-600',
  icon: { name: 'circle-exclamation' as const },
});

export const InputIcon: React.FC<IInputIconProps> = ({
  iconProps,
  side,
  className,
  error,
  children,
}) => {
  const computedIconProps = React.useMemo<IIconProps>(
    () => ({
      ...(error ? errorProps : iconProps),
      className: `absolute text-gray-400 top-3 ${
        side === 'right' ? 'right-3' : 'left-3'
      } ${error ? errorProps.className : iconProps?.className || ''}`,
    }),
    [iconProps, error, side],
  );

  if (!computedIconProps?.icon)
    return (
      <div className={`${className || ''} flex-grow relative`}>
        {children('')}
      </div>
    );

  return (
    <div className={`${className || ''} flex-grow relative`}>
      {children(side === 'right' ? 'pr-8' : 'pl-8')}
      <Icon {...computedIconProps} />
    </div>
  );
};

InputIcon.defaultProps = {
  side: 'right',
};
