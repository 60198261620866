import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import type { FC, ReactNode } from 'react';

interface ElementListCardProps {
  icon?: IconField;
  title: string;
  children: ReactNode;
}

export const ElementListCard: FC<ElementListCardProps> = ({
  icon,
  title,
  children,
}) => (
  <div className="w-full border border-border rounded-lg my-1">
    <div className="h-12 flex items-center px-3 bg-gray-50 dark:bg-neutral-700 rounded-t-lg">
      <Icon icon={icon} className="mr-2  text-base text-text" />
      <p className="text-base text-text">{title}</p>
    </div>
    {children}
  </div>
);
