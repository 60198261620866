import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import type React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  icon?: IconField;
  exit?: () => void; // if present, user can erase the banner
  dimmer?: boolean;
  children: React.ReactNode;
}

/**
 * Warning banner on top of screen. It covers essential UI elements, use carefully
 */
export const Banner: React.FC<IProps> = ({
  exit,
  icon = { name: 'megaphone' },
  dimmer = false,
  children,
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      {dimmer ? (
        <div className="fixed inset-0 w-full h-full bg-black/5 z-[999]" />
      ) : null}
      <div className="absolute top-0 z-[1000] bg-yellow-50 text-yellow-800 shadow-xl w-full">
        <div className="mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="pr-16 sm:text-center sm:px-16">
            <p className="font-medium flex items-center justify-center flex-col md:flex-row gap-1">
              <Icon icon={icon} />
              {children}
            </p>
          </div>
          {exit ? (
            <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
              <button
                type="button"
                onClick={exit}
                className="flex flex-col items-center p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
              >
                <span className="sr-only">{t('close')}</span>
                <Icon
                  icon={{ name: 'xmark' }}
                  className="icon h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
