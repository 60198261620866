import { FiltersSerializer } from '@smack/core/utils/Filters';
import { z } from 'zod';

const mapPreferencesSerializer = {
  map: z
    .object({
      basemap: z.number().optional(),
      zoom: z.number().optional(),
      center: z
        .object({
          lng: z.number(),
          lat: z.number(),
        })
        .optional(),
    })
    .optional(),
};

const customersPreferencesSerializer = {
  customer: z
    .object({
      theme: z.string().optional(),
      menuCollapsed: z.boolean().optional(),
      homeListCollapsed: z.record(z.boolean().optional()).optional(),
      leftCategoriesListCollapsed: z.record(z.boolean().optional()).optional(),
      objectLinkedCalendar: z
        .object({
          view: z.string().optional(),
          viewType: z.record(z.string().optional()).optional(),
          zoom: z.record(z.number()).optional(),
        })
        .optional(),
    })
    .optional(),
};

const categoriesPreferencesSerializer = {
  category: z
    .record(
      z
        .object({
          currentView: z.string().optional(),
          views: z
            .record(
              z
                .record(
                  z
                    .object({
                      default: z.boolean().optional(),
                      filters: FiltersSerializer.optional(),
                      hiddenColumns: z.array(z.string()).optional(),
                      columnOrder: z.array(z.string()).optional(),
                      tableInitialOffset: z.number().optional(),
                      columnWidth: z.record(z.number()).optional(),
                      calendarView: z.string().optional(),
                      leftPanel: z.string().optional(),
                      tableMenuCollapse: z.boolean().optional(),
                      tableShowSectionHeaders: z.boolean().optional(),
                      statsOpen: z.boolean().optional(),
                      columnPinning: z
                        .object({
                          left: z.array(z.string()),
                          right: z.array(z.string()),
                        })
                        .optional(),
                    })
                    .optional(),
                )
                .optional(),
            )
            .optional(),
        })
        .optional(),
    )
    .optional(),
};

export const UserPreferencesSerializerSchema = z.object({
  maps: z.object(mapPreferencesSerializer).optional(),
  categories: z.object(categoriesPreferencesSerializer).optional(),
  customers: z.object(customersPreferencesSerializer).optional(),
});
