import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { AffixChip } from '@smack/core/components/DataInput/AffixChip/AffixChip';
import { cx } from 'class-variance-authority';
import React from 'react';
import { RadioInput } from '../RadioInput';

interface IClearableRadioInputChoice {
  label: React.ReactNode;
  value: unknown;
}

interface IClearableRadioInputProps {
  children?: never;
  choices: IClearableRadioInputChoice[];
  onChange?: (val: unknown) => void;
  onBlur?: () => void;
  name?: string;
  value?: unknown;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
}

const ClearableRadioInputRender: React.ForwardRefRenderFunction<
  HTMLInputElement,
  IClearableRadioInputProps
> = ({ name, onChange, onBlur, value, choices, prefix, suffix }, ref) => {
  const onRadioChange = React.useCallback(
    (checked: boolean, newValue: unknown) => {
      if (checked) onChange?.(newValue);
    },
    [onChange],
  );

  const clearRadio = React.useCallback(() => onChange?.(undefined), [onChange]);

  return (
    <div className="flex items-stretch">
      {prefix ? <AffixChip type="prefix">{prefix}</AffixChip> : null}
      <div
        className={cx(
          'flex gap-2',
          prefix || suffix ? 'p-2 border border-gray-300' : null,
        )}
        onBlur={onBlur}
      >
        {choices.map((choice, i) => (
          <RadioInput
            key={String(choice.value)}
            name={name}
            checked={choice.value === value}
            ref={i === 0 ? ref : undefined}
            value={choice.value}
            label={choice.label}
            onChange={onRadioChange}
          />
        ))}
        {value !== undefined ? (
          <div
            onClick={clearRadio}
            className="rounded-full h-5 w-5 bg-gray-100 dark:bg-neutral-600 flex items-center justify-center cursor-pointer hover:bg-gray-200"
          >
            <Icon
              icon={{ name: 'times' }}
              className="text-gray-600 dark:text-gray-500"
            />
          </div>
        ) : null}
      </div>
      {suffix ? <AffixChip type="suffix">{suffix}</AffixChip> : null}
    </div>
  );
};

ClearableRadioInputRender.displayName = 'ClearableRadioInput';

export const ClearableRadioInput = React.forwardRef(ClearableRadioInputRender);
