import React, { type ForwardedRef } from 'react';
import type { Index, IndexRange, ListRowRenderer } from 'react-virtualized';

import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import VList from 'react-virtualized/dist/commonjs/List';

interface VirtualizedListProps<T> {
  objects: T[];
  renderListItem: (object: T | undefined, index: number) => React.ReactNode;
  rowCount?: number;
  startIndex: number;
  rowHeight?: number | ((object: T | undefined, index?: number) => number);
  disabledHeight?: boolean;
  onRowsRendered?: (params: IndexRange) => void;
}

const VirtualizedList = <T,>(
  {
    objects,
    renderListItem,
    rowCount = objects.length,
    startIndex,
    rowHeight = 81,
    disabledHeight,
    onRowsRendered,
  }: VirtualizedListProps<T>,
  ref: ForwardedRef<VList>,
) => {
  const [scrollTop, setScrollTop] = React.useState(0);

  // to scroll when props coming
  React.useEffect(() => {
    if (startIndex && startIndex !== scrollTop) {
      setScrollTop(startIndex);
    }
  }, [startIndex]);

  const renderItem: ListRowRenderer = ({ index, style, key }): JSX.Element => {
    const item = objects[index];
    return (
      <div key={key} style={style}>
        {renderListItem(item, index)}
      </div>
    );
  };

  // when manual scroll
  const handleScroll = ({ scrollTop }): void => {
    // Store the current scroll offset
    setScrollTop(scrollTop as number);
  };

  const computeHeight = (params: Index): number => {
    if (typeof rowHeight === 'number') {
      return rowHeight;
    }
    if (typeof rowHeight === 'function') {
      return rowHeight(objects[params.index], params.index);
    }
    return 0;
  };
  const computeVListHeight = (): number => {
    let height = 0;
    for (const index of objects.keys()) {
      height += computeHeight({ index } as Index);
    }
    return height;
  };

  return (
    <AutoSizer disableHeight={disabledHeight}>
      {({ width, height }): JSX.Element => (
        <VList
          data={objects}
          height={disabledHeight ? computeVListHeight() : height}
          width={width}
          rowCount={rowCount}
          rowHeight={computeHeight}
          onScroll={handleScroll}
          onRowsRendered={onRowsRendered}
          scrollTop={scrollTop}
          scrollToAlignment="start"
          rowRenderer={renderItem}
          ref={ref}
        />
      )}
    </AutoSizer>
  );
};

const forwardedVirtualizedList = React.forwardRef(VirtualizedList) as <T>(
  props: VirtualizedListProps<T> & { ref?: React.ForwardedRef<VList> },
) => ReturnType<typeof VirtualizedList>;

export { forwardedVirtualizedList as VirtualizedList };
