import type { Placement } from '@floating-ui/react';
import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import { Tooltip } from '@smack/core/components/DataDisplay/Tooltip';
import type React from 'react';

interface IProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  icon?: IconField;
  color?: string;
  title?: string;
  label?: string;
  className?: string;
  position?: Placement;
  disabled?: boolean;
  isLoading?: boolean;
}

/**
 * Button rounded with an icon inside
 * @param props IProps
 * @returns JSX.Element
 */
export const IconRoundedButton: React.FC<IProps> = ({
  onClick,
  color,
  icon,
  className,
  title,
  label,
  position = 'left',
  disabled,
  isLoading,
}) => {
  return (
    <Tooltip
      title={title ?? null}
      offset={2}
      position={position}
      containerProps={{
        className,
      }}
    >
      <div className="flex flex-col items-center">
        <button
          style={color ? { backgroundColor: color } : {}}
          className="w-7 flex-shrink-0 aspect-square flex items-center justify-center rounded-full bg-gray-200 hover:bg-gray-300 disabled:hover:bg-gray-200 shadow disabled:opacity-50"
          onClick={onClick}
          aria-label={title}
          disabled={isLoading || disabled}
          type="button"
        >
          {isLoading ? (
            <Icon
              className="fa-fw text-sm text-gray-600"
              icon={{ name: 'spinner-third', familyStyle: 'fad' }}
              animation="spin"
            />
          ) : (
            <Icon className="fa-fw text-sm text-gray-600" icon={icon} />
          )}
        </button>
        {label ? (
          <span
            onClick={onClick}
            className="cursor-pointer text-xs text-gray-600 dark:text-gray-200 mt-1 text-center uppercase"
          >
            {label}
          </span>
        ) : null}
      </div>
    </Tooltip>
  );
};
