import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import {
  type INode,
  openFileNode,
} from '@smack/core/components/DataDisplay/Medias/IMediaNode';
import { CheckboxInput } from '@smack/core/components/DataInput/CheckboxInput';
import {
  isElectronAvailable,
  openFileInExplorer,
  openFolderInExplorer,
} from '@smack/core/utils/ElectronUtils';
import { cx } from 'class-variance-authority';
import { t } from 'i18next';
import React from 'react';
import type { NodeRendererProps, RowRendererProps } from 'react-arborist';
import { toast } from 'react-hot-toast';
import { MediasTreeContext } from '../../MediasTreeContext';

export const TreeRow = ({
  innerRef,
  attrs,
  children,
}: RowRendererProps<INode>): React.ReactElement => {
  return (
    <div
      {...attrs}
      ref={innerRef}
      className="border-b border-gray-100 group outline-none"
    >
      {children}
    </div>
  );
};

/**
 * Return TreeNode Components
 * @param props IProps
 * @returns JSX.Elements
 */
export const TreeNode = ({
  node,
  tree,
  style,
  dragHandle,
}: NodeRendererProps<INode>): React.ReactElement => {
  const {
    onChildCreateOpen,
    onChildUpdateOpen,
    onChildDetailOpen,
    onFavorite,
    canEdit,
  } = React.useContext(MediasTreeContext);

  return (
    <div style={style} ref={dragHandle}>
      <div
        className={cx(
          'flex gap-2 text-text pl-2 py-2 items-center dark:hover:bg-neutral-700 cursor-grab',
        )}
        onClick={(event) => {
          node.toggle();
          event.stopPropagation();
        }}
      >
        {node.data.isFolder ? (
          <Icon
            icon={{ name: 'folder' }}
            className="text-gray-400 dark:text-gray-200"
          />
        ) : node.data.isFile ? (
          <Icon
            icon={
              node.data.mimetype?.icon?.name
                ? node.data.mimetype.icon
                : { name: 'file' }
            }
            color={node.data.mimetype?.color ?? 'rgb(156, 163, 175)'}
          />
        ) : null}
        {node.data.isFile || node.data.isFolder ? (
          <CheckboxInput
            value={node.state.isSelected}
            onClick={(event) => {
              if (event.shiftKey) {
                node.selectContiguous();
              } else {
                if (node.isSelected) {
                  node.deselect();
                  // deselect currently doesn't trigger onSelect event
                  tree.props.onSelect?.(tree.selectedNodes);
                } else {
                  node.selectMulti();
                }
              }
              event.stopPropagation();
            }}
          />
        ) : null}
        <span
          title={node.data.label}
          className="flex cursor-pointer items-center gap-2 overflow-hidden whitespace-nowrap"
        >
          {canEdit &&
          onFavorite &&
          node.data.isFile &&
          node.data.mimetype?.isImage ? (
            <Icon
              icon={{
                name: 'star',
                familyStyle: node.data.isFavorite ? 'fas' : 'fal',
              }}
              color="#FFCA28"
              onClick={(event): void => {
                onFavorite(node);
                event.stopPropagation();
              }}
              className="cursor-pointer"
            />
          ) : null}
          {node.data.isFile ? (
            <button type="button" onClick={() => openFileNode(node.data)}>
              {node.data.label}
            </button>
          ) : node.data.isFolder ? (
            node.data.label
          ) : (
            <span className="text-secondary">{node.data.label}</span>
          )}
        </span>
        <div className="ml-auto mr-2 pl-4 flex items-center gap-1 transition-opacity ease-linear duration-100 opacity-0 group-hover:opacity-100">
          {(node.data.isFolder || node.data.isFile) && onChildDetailOpen ? (
            <div
              className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer"
              onClick={(event): void => {
                onChildDetailOpen(node);
                event.stopPropagation();
              }}
              title={t('medias.informations') ?? ''}
            >
              <Icon
                icon={{ name: 'info' }}
                className="h-2.5 w-2.5"
                aria-hidden="true"
              />
            </div>
          ) : null}
          {(node.data.isFolder || node.data.isFile) && onChildUpdateOpen ? (
            <div
              className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer"
              onClick={(event): void => {
                onChildUpdateOpen(node);
                event.stopPropagation();
              }}
              title={t('medias.patch') ?? ''}
            >
              <Icon
                icon={{ name: 'pencil' }}
                className="h-2.5 w-2.5"
                aria-hidden="true"
              />
            </div>
          ) : null}
          {node.data.href && node.data.externalFilesystemId ? (
            <div
              className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer"
              onClick={(event): void => {
                event.stopPropagation();
                if (!node.data.href) return;
                if (node.data.isFolder) {
                  if (!isElectronAvailable()) {
                    toast.error(t('medias.unableToOpenFolderFromBrowser'));
                    return;
                  }
                  openFolderInExplorer(node.data.href);
                } else if (node.data.isFile) {
                  if (!isElectronAvailable()) {
                    toast.error(t('medias.openLocalMediasError'));
                    return;
                  }
                  openFileInExplorer(node.data.href);
                }
              }}
              title={t('medias.actions.openInExplorer')}
            >
              <Icon
                icon={{ name: 'folder-open' }}
                className="h-2.5 w-2.5"
                aria-hidden="true"
              />
            </div>
          ) : null}
          {node.data.isFolder ? (
            <>
              {onChildCreateOpen && (
                <div
                  className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer"
                  onClick={(event): void => {
                    onChildCreateOpen(node);
                    event.stopPropagation();
                  }}
                  title={t('medias.new')}
                >
                  <Icon
                    icon={{ name: 'plus' }}
                    className="h-2.5 w-2.5"
                    aria-hidden="true"
                  />
                </div>
              )}
              {node.state.isOpen && (
                <Icon
                  icon={{ name: 'chevron-down' }}
                  className="text-gray-400 dark:text-gray-200"
                />
              )}
              {!node.state.isOpen && (
                <Icon
                  icon={{ name: 'chevron-right' }}
                  className="text-gray-400 dark:text-gray-200"
                />
              )}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
