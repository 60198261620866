import React from 'react';

import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import { Tooltip } from '@smack/core/components/DataDisplay/Tooltip';

export interface HeaderLabel {
  icon: IconField;
  label?: string;
  trigger?: 'changeDeadline';
  textColor?: React.CSSProperties['color'];
  tooltip?: string;
  rightIcon?: IconField;
  children?: React.ReactNode;
}

interface IProps extends React.HTMLProps<HTMLDivElement>, Partial<HeaderLabel> {
  maxLengthLabel?: number;
  className?: string;
}

const IconLabelRender: React.ForwardRefRenderFunction<
  HTMLDivElement,
  IProps
> = (
  {
    icon,
    label = '',
    maxLengthLabel,
    textColor,
    className,
    rightIcon,
    tooltip,
    children,
    ...props
  },
  ref,
): JSX.Element => (
  <Tooltip title={tooltip}>
    <div
      data-testid={'IconLabel'}
      {...props}
      className={'flex gap-1 text-xs items-center truncate'}
      ref={ref}
      style={{ color: textColor }}
    >
      {icon ? <Icon icon={icon} /> : null}
      {children ?? label}
      {rightIcon ? <Icon icon={rightIcon} /> : null}
    </div>
  </Tooltip>
);

IconLabelRender.displayName = 'IconLabel';

export const IconLabel = React.forwardRef(IconLabelRender);
