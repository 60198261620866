/**
 * interface for the Option Card components
 *
 * @interface IProps
 */
interface IProps {
  picture?: string;
  title: string;
  onClick: () => void;
  active: boolean;
}

export const OptionCard = (props: IProps): JSX.Element => {
  const { active, onClick, picture, title } = props;

  return (
    <div
      onClick={onClick}
      className={`cursor-pointer bg-neutral-200 dark:bg-neutral-600 h-28 rounded-lg overflow-hidden shadow  flex flex-col box-border ${
        active ? 'border-2 border-blue-500' : 'border border-border'
      }`}
    >
      <div
        style={{ backgroundImage: `url(${picture || ''})` }}
        className={'flex-grow  bg-center bg-cover'}
      />
      <div
        className={
          'flex items-center text-sm px-2 py-1.5 font-medium text-gray-600 dark:text-gray-300'
        }
      >
        {title}
      </div>
    </div>
  );
};
