import type { File } from '@smack/core/api/models/medias/File';
import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import { useTranslation } from 'react-i18next';

export interface IFileItem {
  label?: string;
  icon?: IconField;
  onClick?: () => void;
}

export const fileAsFileItem = (file: File): IFileItem => {
  return {
    label: file.label,
    icon: file.mimetype?.icon,
    onClick: file.open.bind(file),
  };
};

/**
 * Input properties of the file list component.
 */
interface IProps {
  /** The list of the files. */
  files: IFileItem[];
  /** OPTIONAL - The event callback fired when the user deletes a file. */
  onDelete?: (value: number) => void;
}

/**
 * Creates a `FileList` component that lists the provided files.
 *
 * @param props - The input properties of the component.
 * @returns The created `FileList` component.
 */
export const FileList = ({ files, onDelete }: IProps): JSX.Element => {
  const [t] = useTranslation();

  return (
    <ul className="w-full border-dashed rounded border-2 p-4 bg-view mb-3">
      {files.map((media, index) => {
        return (
          // biome-ignore lint/suspicious/noArrayIndexKey: FIXME
          <li key={index} className="flex gap-3 items-center my-1">
            <Icon
              icon={media.icon ?? { name: 'file' }}
              className="flex-shrink-0 text-gray-500 dark:text-gray-200"
            />
            {media.onClick ? (
              <button
                type="button"
                title={media.label}
                onClick={media.onClick}
                className="flex-grow w-0 text-start truncate text-blue-500"
              >
                {media.label}
              </button>
            ) : (
              <span title={media.label} className="flex-grow w-0 truncate">
                {media.label}
              </span>
            )}
            {onDelete ? (
              <Icon
                onClick={(): void => onDelete(index)}
                icon={{ name: 'trash-can' }}
                title={t('delete.delete')}
                className="flex-shrink-0 text-red-600 text-sm cursor-pointer"
              />
            ) : null}
          </li>
        );
      })}
    </ul>
  );
};
