import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import type React from 'react';
import './PlusMinusInput.scss';
import { useTranslation } from 'react-i18next';

export interface IPlusMinusInputProps
  extends Omit<React.ComponentProps<'input'>, 'onChange'> {
  label?: string;
  value?: string | number;
  onChange?: (data: number) => void;
  error?: string;
  helpText?: string;
}

export const PlusMinusInput: React.FC<IPlusMinusInputProps> = ({
  label,
  value,
  onChange,
  helpText,
  error,
  step = 'any',
  className,
  ...inputProps
}) => {
  const [t] = useTranslation();
  const parsedValue = Number.parseFloat(value?.toString() ?? '0');

  const addStep: number =
    step === 'any' ? 1 : Number.parseFloat(step.toString());

  const handleChange = (data: string): void => {
    if (onChange) onChange(Number.parseFloat(data.toString()));
  };

  const handleMinusClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();

    if (
      inputProps.min !== undefined &&
      parsedValue <= Number.parseFloat(inputProps.min.toString())
    )
      return;
    onChange?.(parsedValue - addStep);
  };

  const handlePlusClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();

    if (
      inputProps.max !== undefined &&
      parsedValue >= Number.parseFloat(inputProps.max.toString())
    )
      return;
    onChange?.(parsedValue + addStep);
  };

  return (
    <div className="h-full">
      {label && (
        <label
          htmlFor={inputProps.id}
          className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1"
        >
          {label}
        </label>
      )}
      <div className="h-full flex justify-center shadow-sm">
        <div
          title={t('plusminusinput.decrease')}
          onClick={handleMinusClick}
          className={
            'cursor-pointer bg-gray-100 dark:bg-neutral-600 text-text h-full aspect-square flex items-center justify-center rounded-l'
          }
        >
          <Icon icon={{ name: 'minus' }} />
        </div>
        <input
          data-testid={'plusminusinput.input'}
          type="number"
          value={value}
          step={step}
          onChange={(e): void => handleChange(e.target.value)}
          className={`plusminusinput min-w-0 bg-view text-text  block text-center focus:ring-0 focus:border-none  sm:text-sm ${
            error ? 'border-red-600' : 'border-none'
          } ${className ?? ''}`}
          {...inputProps}
        />
        <div
          title={t('plusminusinput.increase')}
          onClick={handlePlusClick}
          className={
            'cursor-pointer dark:bg-neutral-600 text-text bg-gray-100 h-full aspect-square flex items-center justify-center rounded-r'
          }
        >
          <Icon icon={{ name: 'plus' }} />
        </div>
      </div>
      {helpText && (
        <p className="mt-2 text-sm text-gray-500 dark:text-gray-200">
          {helpText}
        </p>
      )}
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </div>
  );
};
