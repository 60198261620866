export interface ProjectsState {
  refetchProjectsStatuses?: boolean;
}

// Actions Constants
export const REFETCH_PROJECTS_STATUSES = 'PROJECTS::REFETCH_PROJECTS_STATUSES';

// Actions Definitions
export interface IToggleRefetchProjectsStatuses {
  type: typeof REFETCH_PROJECTS_STATUSES;
  payload: { refetch: boolean };
}

export type ProjectsActionsType = IToggleRefetchProjectsStatuses;
