import type { IPagination } from '@smack/core/api/clients/rest/RESTClient';
import type { LinkGroup } from '@smack/core/api/models/categories/LinkGroup';
import type { InputLink } from '@smack/core/api/models/objects/Link/Link';
import {
  BaseObject,
  type IBaseObject,
} from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { AutoSelectWithPagination } from '@smack/core/components/DataInput/AutoSelectWithPagination/AutoSelectWithPagination';
import {
  DateTimeRangeInput,
  type DateTimeRangeInputValue,
} from '@smack/core/components/DataInput/DateRangeInput/DateTimeRangeInput';
import { NumberInput } from '@smack/core/components/DataInput/NumberInput/NumberInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import { useBaseObjectFormContextContext } from '@smack/core/components/ViewRenderer/renderers/BaseObjectFormViewRenderer/context/BaseObjectFormContext';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface BaseLinkSelectProps {
  label?: string;
  filterAvailable?: boolean;
  linkGroup: LinkGroup;
}

interface UniqueLinkSelectProps extends BaseLinkSelectProps {
  multiple?: false;
  value?: InputLink | null;
  onChange?: (value: InputLink | null) => void;
}

interface MultipleLinkSelectProps extends BaseLinkSelectProps {
  multiple: true;
  value?: InputLink[] | null;
  onChange?: (value: InputLink[] | null) => void;
}

export type LinkSelectProps = UniqueLinkSelectProps | MultipleLinkSelectProps;

export const LinkSelect: React.FC<LinkSelectProps> = ({
  value,
  label,
  multiple,
  filterAvailable,
  onChange,
  linkGroup,
}) => {
  const { formRRuleInputValue } = useBaseObjectFormContextContext();
  const [t] = useTranslation();
  const [baseObjectValue, setBaseObjectValue] = React.useState<
    Option | Option[] | null
  >();
  const [weightValue, setWeightValue] = React.useState<number>();
  const [dateRangeValue, setDateRangeValue] =
    React.useState<DateTimeRangeInputValue>();

  const getFiltersFromFormContext = (): Record<string, unknown> => {
    let output = {};
    if (
      filterAvailable &&
      formRRuleInputValue?.dtstart &&
      formRRuleInputValue?.dtend
    ) {
      output = {
        'source-dates[dtstart]': formRRuleInputValue.dtstart,
        'source-dates[dtend]': formRRuleInputValue.dtend,
      };
      if (formRRuleInputValue.rrule)
        output['source-dates[rrule]'] = formRRuleInputValue.rrule;
    }

    return output;
  };

  const queryLinkable = (search: string): Promise<IPagination<IBaseObject>> => {
    return BaseObject.getLinkableBaseObjects(
      linkGroup.id,
      { search },
      getFiltersFromFormContext(),
    );
  };

  const mapToOption = (data: BaseObject): Option => ({
    label: data.title,
    value: data.id,
  });

  const selectSize =
    linkGroup.isWeighted && linkGroup.isTimeSensitive ? 'small' : undefined;

  React.useEffect(() => {
    if (baseObjectValue) return;
    if (!multiple && value?.targetBaseobjectId) {
      setBaseObjectValue({
        value: value.targetBaseobjectId,
        label: value.targetBaseObjectTitle ?? value.targetBaseobjectId,
      });
      if (value.weight) setWeightValue(value.weight);
      if (value.datetimeRange) {
        setDateRangeValue({
          endDate: new Date(value.datetimeRange.dtend),
          startDate: new Date(value.datetimeRange.dtstart),
        });
      }
    } else if (multiple && Array.isArray(value)) {
      setBaseObjectValue(
        value.map((item) => ({
          value: item.targetBaseobjectId,
          label: item.targetBaseObjectTitle ?? item.targetBaseobjectId,
        })),
      );
    }
  }, [value, multiple]);

  const handleChange = () => {
    if (multiple) {
      if (!Array.isArray(baseObjectValue)) return;
      const newValue = baseObjectValue.map((baseObject) => ({
        targetBaseobjectId: baseObject.value as number,
        targetBaseObjectTitle: baseObject.label as string,
        linkGroupId: linkGroup.id,
      }));
      onChange?.(newValue as InputLink[]);
    } else {
      if (Array.isArray(baseObjectValue) || !baseObjectValue) return;
      const newValue = {
        ...(value ?? {}),
        targetBaseobjectId: baseObjectValue.value as number,
        targetBaseObjectTitle: baseObjectValue.label as string,
        linkGroupId: linkGroup.id,
        weight: weightValue,
        datetimeRange:
          dateRangeValue?.startDate && dateRangeValue?.endDate
            ? {
                dtstart: dateRangeValue.startDate.toISOString(),
                dtend: dateRangeValue.endDate.toISOString(),
              }
            : undefined,
      };
      onChange?.(newValue as InputLink);
    }
  };

  const setObjects = (options?: Option[] | null) => {
    setBaseObjectValue(options);
  };

  const setObject = (option?: Option | null) => {
    setBaseObjectValue(option);
  };

  React.useEffect(
    () => handleChange(),
    [baseObjectValue, dateRangeValue, weightValue],
  );

  if (multiple) {
    return (
      <AutoSelectWithPagination
        label={label}
        onChange={setObjects}
        value={baseObjectValue as Option[]}
        query={queryLinkable}
        transformer={(data: IBaseObject) => new BaseObject(data)}
        multiple={true}
        width={selectSize}
        mapToOption={mapToOption}
      />
    );
  }

  return (
    <div className={'flex items-center gap-2 '}>
      <AutoSelectWithPagination
        label={label}
        onChange={setObject}
        value={baseObjectValue as Option}
        query={queryLinkable}
        transformer={(data: IBaseObject) => new BaseObject(data)}
        width={selectSize}
        mapToOption={mapToOption}
      />
      {linkGroup.isWeighted ? (
        <NumberInput
          data-testid={'LinkSelect.NumberInput'}
          value={weightValue ?? ''}
          size={linkGroup.isTimeSensitive ? 'xSmall' : 'small'}
          onChange={setWeightValue}
          placeholder={
            linkGroup.weightLabel || t('linkSelect.weight.placeholder')
          }
        />
      ) : null}
      {linkGroup.isTimeSensitive ? (
        <DateTimeRangeInput
          minimal
          value={dateRangeValue}
          onChange={setDateRangeValue}
        />
      ) : null}
    </div>
  );
};
