import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { DaySelector } from '@smack/core/components/DataInput/DateRangeInput/DaySelector';
import { TimeSelector } from '@smack/core/components/DataInput/DateRangeInput/TimeSelector/TimeSelector';
import React from 'react';
import { useTranslation } from 'react-i18next';
export type DateTimeRangeInputValue = {
  startDate?: Date;
  endDate?: Date;
};

export interface IDateTimeRangeInputProps {
  value?: DateTimeRangeInputValue;
  onChange?: (data: DateTimeRangeInputValue) => void;
  minimal?: boolean;
  placeholder?: string;
  label?: string;
}

const END_INITIAL_GAP = 15;

export const getRoundedDate = (date: Date, addGap = 0): Date => {
  const newDate = new Date(date);
  newDate.setMinutes((Math.round(date.getMinutes()) % 60) + addGap);

  return newDate;
};

export const DateTimeRangeInput: React.FC<IDateTimeRangeInputProps> = ({
  value,
  onChange,
  minimal = false,
  placeholder,
  label,
}) => {
  const [startOpen, setStartOpen] = React.useState<boolean>(false);
  const [endOpen, setEndOpen] = React.useState<boolean>(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const [t] = useTranslation();
  const onStartChange = React.useCallback(
    (date: Date) => {
      if (!value?.startDate) {
        date.setHours(new Date().getHours());
        date.setMinutes(new Date().getMinutes());
        date = getRoundedDate(date);
      }
      date.setSeconds(0);
      date.setMilliseconds(0);

      let dateEnd = value?.endDate;
      if (!dateEnd || dateEnd < date) {
        dateEnd = getRoundedDate(date, END_INITIAL_GAP);
      }
      onChange?.({ ...value, startDate: date, endDate: dateEnd });
      setStartOpen(false);
    },
    [value],
  );

  const onEndChange = React.useCallback(
    (date: Date) => {
      date.setSeconds(0);
      date.setMilliseconds(0);
      onChange?.({ ...value, endDate: date });
      setEndOpen(false);
    },
    [value],
  );

  const getToLocaleDateString = (date?: Date): string => {
    return (
      date?.toLocaleDateString(
        navigator.language,
        minimal
          ? {
              month: 'numeric',
              day: 'numeric',
              year: '2-digit',
            }
          : {
              weekday: 'long',
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            },
      ) ?? ''
    );
  };

  const StartAndStopIsSameDay = (): boolean => {
    if (!value?.startDate || !value?.endDate) return false;
    return (
      getToLocaleDateString(value?.startDate) ===
      getToLocaleDateString(value?.endDate)
    );
  };

  const getMinDate = (): Date | undefined => {
    if (value?.startDate) {
      const start = new Date(value.startDate);
      start.setMinutes(start.getMinutes());
      return start;
    }
  };

  return (
    <div data-testid="date-range-input" ref={ref}>
      {label && (
        <p className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
          {label}
        </p>
      )}
      <div className={'flex items-center'}>
        {!minimal && (
          <Icon
            icon={{ name: 'clock', familyStyle: 'fal' }}
            className={'mr-1 text-gray-500 text-lg'}
          />
        )}

        {!value?.startDate ? (
          <DaySelector
            value={value?.startDate}
            onChange={onStartChange}
            open={startOpen}
            setOpen={setStartOpen}
          >
            <div
              className={`text-sm text-text capitalize py-1 hover:bg-gray-100 cursor-pointer  ${
                startOpen
                  ? 'bg-gray-100 border-blue-300 border-b-[3px] bor'
                  : ''
              }`}
            >
              {placeholder || t('recurrence.selectStartDate')}
            </div>
          </DaySelector>
        ) : (
          <>
            <DaySelector
              value={value?.startDate}
              onChange={onStartChange}
              open={startOpen}
              setOpen={setStartOpen}
            >
              <div
                className={`capitalize text-sm text-text py-1 hover:bg-gray-100 cursor-pointer ${
                  startOpen ? 'bg-gray-100 border-blue-300 border-b-[3px]' : ''
                }`}
              >
                {getToLocaleDateString(value?.startDate)}
              </div>
            </DaySelector>
            <TimeSelector value={value?.startDate} onChange={onStartChange} />
            {value?.startDate && !value.endDate ? (
              <DaySelector
                value={value?.endDate}
                onChange={onEndChange}
                minDate={value?.startDate}
                open={endOpen}
                setOpen={setEndOpen}
              >
                <div
                  className={`text-sm text-text capitalize py-1 hover:bg-gray-100 cursor-pointer ${
                    endOpen ? 'bg-gray-100 border-blue-300 border-b-[3px]' : ''
                  }`}
                >
                  {t('recurrence.selectEndDate')}
                </div>
              </DaySelector>
            ) : (
              <>
                {' - '}
                <TimeSelector
                  value={value?.endDate}
                  onChange={onEndChange}
                  minDate={getMinDate()}
                />
                <DaySelector
                  value={value?.endDate}
                  onChange={onEndChange}
                  open={endOpen}
                  minDate={value?.startDate}
                  setOpen={setEndOpen}
                >
                  <div
                    className={` py-1 text-sm text-text hover:bg-gray-100 cursor-pointer ${
                      endOpen
                        ? 'bg-gray-100 border-blue-300 border-b-[3px]'
                        : ''
                    } ${StartAndStopIsSameDay() ? '' : 'capitalize'}`}
                  >
                    {StartAndStopIsSameDay()
                      ? `(${t('recurrence.sameDay')})`
                      : getToLocaleDateString(value?.endDate)}
                  </div>
                </DaySelector>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
