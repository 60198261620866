import React from 'react';
/**
  MAP EVENT UTILITY CLASSE
*/

export type OptionalMapLibreId = string | number | undefined;

export class MapEventUtils {
  hoveredIdRef?: React.MutableRefObject<OptionalMapLibreId>;

  setHoveredIdThroughRef?: (input: OptionalMapLibreId) => void;

  constructor(hoveredId?: {
    reference: React.MutableRefObject<OptionalMapLibreId>;
    setter: (input: OptionalMapLibreId) => void;
  }) {
    this.hoveredIdRef = hoveredId?.reference;
    this.setHoveredIdThroughRef = hoveredId?.setter;
  }

  /**
   * Select feature event handler
   * @param e maplibre event
   * @param source the source to search of the feature of
   * @param sourceLayer the source layer to search of the feature of (for vector tiles)
   * @returns the id of the selected feature
   */
  static selectFeature(
    e: maplibregl.MapLayerEventType['click' | 'touchstart'],
    source: string,
    sourceLayer?: string,
  ): OptionalMapLibreId {
    if (!e.features?.length || !e.features[0].id) return;

    const featureIdentifier = {
      source,
      id: e.features[0].id,
      sourceLayer,
    };
    const featureState = window.Hyvilo.Utils.MainMap?.getFeatureState(
      featureIdentifier,
    ) as { selected: boolean };

    window.Hyvilo.Utils.MainMap?.setFeatureState(featureIdentifier, {
      selected:
        featureState && 'selected' in featureState
          ? !featureState.selected
          : true,
    });
    return e.features[0].id;
  }

  /**
   * Hover feature event handler
   * @param e maplibre event
   * @param source the source to search of the feature of
   * @param sourceLayer the source layer to search of the feature of (for vector tiles)
   */
  hoverFeature(
    e: maplibregl.MapLayerEventType['mousemove'],
    source: string,
    sourceLayer?: string,
  ): void {
    if (
      !e.features?.length ||
      !e.features[0].id ||
      !this.setHoveredIdThroughRef
    )
      return;

    const featureIdentifier = {
      source,
      id: this.hoveredIdRef?.current,
      sourceLayer,
    };
    if (this.hoveredIdRef?.current) {
      window.Hyvilo.Utils.MainMap?.setFeatureState(featureIdentifier, {
        hover: false,
      });
    }
    this.setHoveredIdThroughRef(e.features[0].id);
    featureIdentifier.id = e.features[0].id;
    window.Hyvilo.Utils.MainMap?.setFeatureState(featureIdentifier, {
      hover: true,
    });
  }

  /**
   * remove hovering effect when mouse leaves the layer
   * @param source the source to search of the feature of
   * @param sourceLayer the source layer to search of the feature of (for vector tiles)
   */
  leaveHoveringLayer(source: string, sourceLayer?: string): void {
    if (this.hoveredIdRef?.current && this.setHoveredIdThroughRef) {
      window.Hyvilo.Utils.MainMap?.setFeatureState(
        {
          source,
          sourceLayer,
          id: this.hoveredIdRef.current,
        },
        { hover: false },
      );
      this.setHoveredIdThroughRef(undefined);
    }
  }
}

/**
 * React hook to create a map hover handler
 * @returns The MapEventUtils instance and the hovered id stateful value.
 */
export function useMapHoverHandler(): [MapEventUtils, OptionalMapLibreId] {
  const [hoveredId, setHoveredId] =
    React.useState<OptionalMapLibreId>(undefined);
  const hoveredIdRef = React.useRef(hoveredId);
  /**
   * Setter for hoveredId that mapbox can use
   * @param input the value to set hoveredId to
   */
  const setHoveredIdThroughRef = (input: OptionalMapLibreId): void => {
    hoveredIdRef.current = input;
    setHoveredId(input);
  };

  const mapEvents = React.useMemo(
    () =>
      new MapEventUtils({
        reference: hoveredIdRef,
        setter: setHoveredIdThroughRef,
      }),
    [],
  );

  return [mapEvents, hoveredId];
}
