/**
 *
 * LayerOption Model
 */

import type { LayerSpecification } from 'maplibre-gl';

/**
 * LayerOption interface
 */
export interface ILayerOption {
  id: number;
  data: LayerSpecification;
  isAvailableInFilters?: boolean;
  isAvailableInPanel?: boolean;
}

/**
 * LayerOption class Model
 *
 * @export
 * @class LayerOption
 */
export class LayerOption {
  id: number;

  data: LayerSpecification;

  isAvailableInFilters?: boolean;

  isAvailableInPanel?: boolean;

  constructor(data: ILayerOption) {
    this.id = data.id;
    this.data = data.data;
    this.isAvailableInFilters = data.isAvailableInFilters;
    this.isAvailableInPanel = data.isAvailableInPanel;
  }

  static getDefaultLayerOption(): LayerOption[] {
    const data: LayerSpecification[] = [
      {
        type: 'fill',
        paint: {
          'fill-color': [
            'case',
            ['boolean', ['feature-state', 'selected'], false],
            '#ff0000',
            [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              '#d3d3d3',
              '#fff',
            ],
          ],
          'fill-outline-color': 'red',
          'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            0.5,
            0.3,
          ],
        },
      },
    ] as unknown as LayerSpecification[];
    return data.map((d, i) => new LayerOption({ id: 100000000 + i, data: d }));
  }
}
