import { Layer } from '@smack/core/api/models/maps/Layer';
import { Switch } from '@smack/core/components/Actions/Switch/Switch';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import type { AppState } from '@smack/core/store';
import { setLayerError } from '@smack/core/store/maps/actions';
import { LayerErrorModal } from '@smack/core/views/oldViewsToSort/Layouts/RightPanel/MapConfigurator/Components/LayerErrorModal/LayerErrorModal';
import type { Map as MapLibreMap } from 'maplibre-gl';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**
 * interface of the OptionLayer compoents
 *
 * @interface IProps
 */
interface IProps {
  layer: Layer;
  activeLayers: Layer[];
  onChangeLayers: (layers: Layer[]) => void;
  map?: MapLibreMap;
}

export const OptionLayer = (props: IProps): JSX.Element => {
  const { layer, activeLayers, onChangeLayers, map } = props;
  const [collapse, setCollapse] = React.useState(true);
  const [isManualyCollapse, setIsManualyCollapse] = React.useState(false);
  const { layersError } = useSelector((app: AppState) => app.Maps);
  const dispatch = useDispatch();
  /**
   * check if the layer is active
   *
   * @return {*}  {boolean}
   */
  const isActive = (): boolean => {
    const active = !!activeLayers.find((l) => l.id === layer.id);
    if (active === collapse && !isManualyCollapse) setCollapse(!active);
    return active;
  };

  /**
   * set the layer active
   *
   */
  const setActive = (): void => {
    const maptoprint = map ?? window.Hyvilo.Utils.MainMap;
    const layerFlatChildren = layer.getFlatFullChildren();
    if (!isActive()) {
      onChangeLayers([...activeLayers, ...layerFlatChildren]);

      layerFlatChildren.forEach((lay) => {
        if (maptoprint) {
          dispatch(
            setLayerError({
              [lay.id]: null,
            }),
          );
          lay.buildFeature(maptoprint);
        }
      });
    } else {
      onChangeLayers([
        ...activeLayers.filter(
          (la) => !layerFlatChildren.find((l) => l.id === la.id),
        ),
      ]);

      layerFlatChildren.forEach((lay) => {
        if (maptoprint) {
          Layer.disableLayer(lay.id?.toString(), maptoprint);
        }
      });
    }
  };

  /**
   * onClick on switch
   *
   */
  const handlesetActive = (): void => {
    setActive();
    setCollapse(isActive());
  };

  /**
   * on click on the arrow
   *
   */
  const handleSetCollapse = (): void => {
    setCollapse(!collapse);
    setIsManualyCollapse(true);
  };

  const layerError = layersError[layer.id];

  return (
    <div
      className={`cursor-pointer bg-neutral-100 border border-border dark:bg-neutral-600 ${
        collapse ? 'h-10' : ''
      } rounded-lg  shadow  flex flex-col overflow-hidden `}
    >
      <div
        className={
          'flex items-center justify-between text-sm px-3 py-1.5 h-10 font-medium text-gray-600 dark:text-gray-300'
        }
      >
        <div className="h-10 flex items-center">
          {layer.isToggleable && (
            <Switch
              active={isActive()}
              setActive={handlesetActive}
              className="mr-2"
            />
          )}

          {layer.label}
        </div>
        <div>
          {layerError && <LayerErrorModal layer={layer} error={layerError} />}
          {layer.childrenLayers?.length ? (
            <button
              type="button"
              onClick={handleSetCollapse}
              className="bg-gray-200 dark:bg-neutral-500 h-5 w-5 rounded-lg flex items-center justify-center "
            >
              <Icon icon={{ name: collapse ? 'chevron-down' : 'chevron-up' }} />
            </button>
          ) : null}
        </div>
      </div>
      {layer.layerLegends.length > 0 && (
        <div className="grid grid-cols-2 gap-1 p-3 ">
          {layer.layerLegends?.map((legend) => {
            return (
              <div
                key={legend.id}
                className="flex items-center  rounded py-1 px-2 border border-border"
              >
                <div
                  style={{ backgroundColor: legend.color }}
                  className="h-3 w-3 rounded-full mr-2"
                />
                <p className="text-sm text-text">{legend.label}</p>
              </div>
            );
          })}
        </div>
      )}
      {layer.childrenLayers?.length > 0 && (
        <div className="grid grid-cols-1 gap-1 p-3 ">
          {layer.childrenLayers.map((chilren) => (
            <OptionLayer
              map={map}
              activeLayers={activeLayers}
              onChangeLayers={onChangeLayers}
              key={chilren.id}
              layer={chilren}
            />
          ))}
        </div>
      )}
    </div>
  );
};
