import type { Checklist } from '@smack/core/api/models/tasks/Checklist';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { CheckboxInput } from '@smack/core/components/DataInput/CheckboxInput';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  checklist: Checklist;
  reloadProject: () => Promise<void>;
}

export const ChecklistItemCreate: React.FC<IProps> = ({
  checklist,
  reloadProject,
}) => {
  const [t] = useTranslation();

  const [isCreating, setIsCreating] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const switchIsCreating = React.useCallback(() => {
    setIsCreating((isCreatingState) => !isCreatingState);
  }, []);

  const createItem = React.useCallback(async () => {
    if (inputRef.current?.value) {
      const value = inputRef.current.value;
      await checklist.addItem(value);
      await reloadProject();
    }
  }, [checklist, reloadProject]);

  const createItemAndCloseCreation = React.useCallback<React.MouseEventHandler>(
    async (e) => {
      e.stopPropagation();
      await createItem();
      setIsCreating(false);
    },
    [createItem],
  );

  const createItemAndRestartCreation =
    React.useCallback<React.KeyboardEventHandler>(
      async (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          await createItem();
          if (inputRef.current) {
            inputRef.current.value = '';
            inputRef.current.focus();
          }
        }
      },
      [createItem],
    );

  React.useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus({
        preventScroll: true,
      });
    }
  }, [isCreating]);

  return (
    <li
      className="flex gap-1.5 items-center text-text"
      onClick={isCreating ? undefined : switchIsCreating}
    >
      <CheckboxInput value={false} disabled />{' '}
      {isCreating ? (
        <>
          <input
            type="text"
            ref={inputRef}
            className="border-none p-0 w-full focus:outline-none bg-inherit"
            onKeyUp={createItemAndRestartCreation}
            placeholder={t('tasks.checklist.addElement')}
          />
          <button
            type="button"
            onClick={createItemAndCloseCreation}
            aria-label={t('create.checklistItem')}
          >
            <Icon icon={{ name: 'check' }} />
          </button>
          <button type="button" onClick={switchIsCreating}>
            <Icon icon={{ name: 'x' }} />
          </button>
        </>
      ) : (
        <span className="text-gray-500">{t('tasks.checklist.addElement')}</span>
      )}
    </li>
  );
};
