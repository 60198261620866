import React from 'react';

import { useMapContext } from '@smack/core/views/oldViewsToSort/Views/Map/Context/MapContext';
import type { ControlPosition, IControl } from 'maplibre-gl';
import { createPortal } from 'react-dom';

interface IMapControlProps {
  children: React.ReactNode;
  placement?: ControlPosition;
  className?: string;
}

export const MapControl: React.FC<IMapControlProps> = ({
  children,
  placement,
  className,
}) => {
  const mapContext = useMapContext();
  const controlEl = React.useMemo<HTMLDivElement>(() => {
    const divElement = document.createElement('div');
    divElement.className = `maplibregl-ctrl ${className ?? ''}`;
    return divElement;
  }, [className]);

  React.useLayoutEffect(() => {
    if (!mapContext.mapLibreHandle) return;
    const control: IControl = {
      onAdd: () => controlEl,
      onRemove() {
        controlEl.remove();
      },
    };
    mapContext.mapLibreHandle.addControl(control, placement);
    return () => {
      mapContext.mapLibreHandle?.removeControl(control);
    };
  }, [mapContext.mapLibreHandle, placement, controlEl]);

  return createPortal(children, controlEl);
};
