import { createContext } from 'react';
import type { NodeApi } from 'react-arborist';
import type { INode } from '../IMediaNode';

/**
 * Context used to pass additionnal callbacks to deeply rooted
 * children (MediasTreeNode)
 */
export interface IMediasTreeContext {
  onChildCreateOpen?: (node: NodeApi<INode>) => void;
  onChildUpdateOpen?: (node: NodeApi<INode>) => void;
  onChildDetailOpen?: (node: NodeApi<INode>) => void;
  onFavorite?: (node: NodeApi<INode>) => void;
  canEdit?: boolean;
}
export const MediasTreeContext = createContext<IMediasTreeContext>({});
