import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { formatLongString } from '@smack/core/utils';
import type { IFilters } from '@smack/core/utils/Filters';
import type { IMenuItem } from '@smack/core/views/oldViewsToSort/Layouts/Objects/MenuFilter/Components/Contextual/index';
import {
  isActive,
  toggleFilter,
} from '@smack/core/views/oldViewsToSort/Layouts/Objects/MenuFilter/Utils';
import React from 'react';

interface IItemProps {
  item: IMenuItem;
  hovered?: boolean;
  filters: IFilters;
  setFilters: (filters: IFilters) => void;
}

export const Item = React.forwardRef<
  HTMLButtonElement,
  IItemProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(function Item(
  { item, hovered, filters, setFilters, ...htmlProps },
  ref,
): JSX.Element {
  const active = isActive(item.id.toString(), filters);

  const shouldToggle = item.id?.includes('=');

  const clickHandler = (): void => {
    if (item.onClick) item.onClick();
    if (!filters) return;
    if (shouldToggle && item.id) {
      toggleFilter(item, filters, setFilters);
    }
  };

  return (
    <button
      type="button"
      ref={ref}
      onClick={clickHandler}
      className={`h-8 w-full rounded-md p-2 flex items-center justify-between cursor-pointer hover:bg-secondary ${
        hovered ? 'bg-secondary' : ''
      }`}
      {...htmlProps}
    >
      <div className="flex items-center w-fit">
        {(item.iconFile || item.icon) && (
          <Icon
            icon={item.icon}
            iconFile={item.iconFile}
            className="fa-fw text-text mr-2 text-lg leading-none"
          />
        )}
        {item.color && (
          <div
            className="mr-3 h-4 w-4 rounded-full"
            style={{ backgroundColor: item.color }}
          />
        )}
        <div className="text-text whitespace-nowrap">
          {formatLongString(item.label, 24)}
        </div>
      </div>
      <div className="flex items-center ml-3">
        {shouldToggle &&
          (active ? (
            <Icon
              icon={{ name: 'circle-check' }}
              color="var(--menu-active-color)"
            />
          ) : (
            <Icon icon={{ name: 'circle', familyStyle: 'far' }} />
          ))}
        {item.subMenu && (
          <Icon className="icon" icon={{ name: 'chevron-right' }} />
        )}
      </div>
    </button>
  );
});
