import type { BaseObjectView } from '@smack/core/api/models/views/BaseObjectView/BaseObjectView';
import type {
  BaseObjectFormOutput,
  BaseObjectFormOutputValues,
} from '@smack/core/components/ViewRenderer/interfaces';

export const getValuesFromViewAttributes = (
  view: BaseObjectView,
): BaseObjectFormOutput => {
  const output: BaseObjectFormOutput = {};
  for (const viewSection of view.viewSections) {
    for (const viewElement of viewSection.viewElements) {
      if (viewElement.attribute) {
        if (viewElement.fieldTypeIsFormField()) {
          output[viewElement.attribute.id.toString()] =
            viewElement.getValueForForm() as BaseObjectFormOutputValues;
        }
      }
    }
  }
  return output;
};
