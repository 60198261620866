import {
  type IMarkerObject,
  type ISpiderParams,
  type ISpiderUserOptions,
  defaultSpiderUserOptions,
} from '@smack/core/views/oldViewsToSort/Views/Objects/ObjectMap/Components/MapClusters/utils';
import { Marker, type Offset } from 'maplibre-gl';

import '@smack/core/views/oldViewsToSort/Views/Map/Components/SpiderfyCluster/index.scss';

export class MapSpiderfier {
  map: maplibregl.Map | null = null;

  userOptions: ISpiderUserOptions = { ...defaultSpiderUserOptions };

  twoPi = Math.PI * 2;

  previousSpiderLegs: IMarkerObject[] = [];

  constructor(
    map: maplibregl.Map | null,
    onClick: (e: MouseEvent, obj: IMarkerObject) => void,
    initializeLeg: (markerobj: IMarkerObject) => void,
  ) {
    this.map = map;
    this.userOptions.onClick = onClick;
    this.userOptions.initializeLeg = initializeLeg;
  }

  spiderfy(latLng: [number, number], markers: GeoJSON.Feature[]): void {
    const spiderParams = this.generateSpiderParams(markers.length);

    this.unspiderfy();
    let markerObjects = markers.map((feature, index) => {
      const spiderParam = spiderParams[index];
      const elements = this.createMarkerElements(spiderParam);
      const mapboxMarker = new Marker(elements.container).setLngLat(latLng);

      const markerObject: IMarkerObject = {
        feature,
        elements,
        mapboxMarker,
        spiderParam,
      };

      if (this.userOptions.initializeLeg)
        this.userOptions.initializeLeg(markerObject);

      elements.container.onclick = (e): void => {
        if (this.userOptions.onClick) this.userOptions.onClick(e, markerObject);
      };

      return markerObject;
    });
    markerObjects = markerObjects.reverse();
    markerObjects.forEach((markerObject: IMarkerObject) => {
      if (this.map) markerObject.mapboxMarker.addTo(this.map);
    });

    if (this.userOptions.animate) {
      setTimeout(() => {
        markerObjects = markerObjects.reverse();
        markerObjects = markerObjects.map((markerObject, index) => {
          const mo = markerObject;
          mo.elements.container.className = (
            markerObject.elements.container.className || ''
          ).replace('initial', '');
          mo.elements.container.style.transitionDelay = `${
            (this.userOptions.animationSpeed / 1000 / markerObjects.length) *
            index
          }s`;
          return mo;
        });
      });
    }

    this.previousSpiderLegs = markerObjects;
  }

  unspiderfy(): void {
    let prev = this.previousSpiderLegs;
    prev = prev.reverse();
    prev.forEach((element, index) => {
      if (this.userOptions.animate) {
        element.elements.container.style.transitionDelay = `${
          (this.userOptions.animationSpeed /
            1000 /
            this.previousSpiderLegs.length) *
          index
        }s`;

        element.elements.container.className += ' exit';
      }

      setTimeout(() => {
        element.mapboxMarker.remove();
      }, this.userOptions.animationSpeed + 100);
    });
    this.previousSpiderLegs = [];
  }

  generateSpiderParams(count: number): ISpiderParams[] {
    if (count >= this.userOptions.circleSpiralSwitchover) {
      return this.generateSpiralParams(count);
    }
    return this.generateCircleParams(count);
  }

  generateSpiralParams(count: number): ISpiderParams[] {
    let legLength = this.userOptions.spiralLengthStart;
    let angle = 0;
    const mapti: ISpiderParams[] = [];
    for (let index = 0; index < count + 1; index += 1) {
      angle +=
        this.userOptions.spiralFootSeparation / legLength + index * 0.0005;
      const pt = {
        x: legLength * Math.cos(angle),
        y: legLength * Math.sin(angle),
        angle,
        legLength,
        index,
      };
      legLength += (this.twoPi * this.userOptions.spiralLengthFactor) / angle;
      mapti.push(pt);
    }
    return mapti;
  }

  generateCircleParams(count: number): ISpiderParams[] {
    const circumference = this.userOptions.circleFootSeparation * (2 + count);
    const legLength = circumference / this.twoPi;
    const angleStep = this.twoPi / count;
    const mapti: ISpiderParams[] = [];
    for (let index = 0; index < count + 1; index += 1) {
      const angle = index * angleStep;
      mapti.push({
        x: legLength * Math.cos(angle),
        y: legLength * Math.sin(angle),
        angle,
        legLength,
        index,
      });
    }
    return mapti;
  }

  createMarkerElements(spiderParam: ISpiderParams): {
    container: HTMLDivElement;
    line: HTMLDivElement;
    pin: HTMLDivElement;
  } {
    const containerElem = document.createElement('div');
    const pinElem = document.createElement('div');
    const lineElem = document.createElement('div');

    containerElem.className = `spider-leg-container${
      this.userOptions.animate ? ' animate initial ' : ''
    }`;
    lineElem.className = 'spider-leg-line';
    pinElem.className = 'spider-leg-pin';

    containerElem.appendChild(lineElem);
    containerElem.appendChild(pinElem);

    containerElem.style['margin-left'] = `${spiderParam.x}px`;
    containerElem.style['margin-top'] = `${spiderParam.y}px`;

    lineElem.style.height = `${spiderParam.legLength}px`;
    lineElem.style.transform = `rotate(${spiderParam.angle - Math.PI / 2}rad)`;

    return { container: containerElem, line: lineElem, pin: pinElem };
  }

  static popupOffsetForSpiderLeg(spiderLeg: IMarkerObject, off = 0): Offset {
    const pinOffsetX = spiderLeg.spiderParam.x;
    const pinOffsetY = spiderLeg.spiderParam.y;

    const offset = off || 0;
    return {
      top: MapSpiderfier.offsetVariant([0, offset], pinOffsetX, pinOffsetY),
      'top-left': MapSpiderfier.offsetVariant(
        [offset, offset],
        pinOffsetX,
        pinOffsetY,
      ),
      'top-right': MapSpiderfier.offsetVariant(
        [-offset, offset],
        pinOffsetX,
        pinOffsetY,
      ),
      bottom: MapSpiderfier.offsetVariant([0, -offset], pinOffsetX, pinOffsetY),
      'bottom-left': MapSpiderfier.offsetVariant(
        [offset, -offset],
        pinOffsetX,
        pinOffsetY,
      ),
      'bottom-right': MapSpiderfier.offsetVariant(
        [-offset, -offset],
        pinOffsetX,
        pinOffsetY,
      ),
      left: MapSpiderfier.offsetVariant(
        [offset, -offset],
        pinOffsetX,
        pinOffsetY,
      ),
      right: MapSpiderfier.offsetVariant(
        [-offset, -offset],
        pinOffsetX,
        pinOffsetY,
      ),
      center: [0, 0],
    };
  }

  static offsetVariant(
    offset: number[],
    variantX: number,
    variantY: number,
  ): [number, number] {
    return [offset[0] + (variantX || 0), offset[1] + (variantY || 0)];
  }
}
