import type { MediasManager } from '@smack/core/api/models/medias';
import { File } from '@smack/core/api/models/medias/File';
import { Folder } from '@smack/core/api/models/medias/Folder';
import type { MediaCategory } from '@smack/core/api/models/medias/MediaCategory';
import type { Mimetype } from '@smack/core/api/models/medias/Mimetype';
import { TabController } from '@smack/core/components/Actions/TabController/TabController';
import { SearchLabel } from '@smack/core/utils/SearchUtils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseListElement } from '../../../Lists/ListElements/BaseListElement';
import { ListElementContent } from '../../../Lists/ListElements/Components/ListElementContent/ListElementContent';
import { NoContentMessage } from '../../../NoContentMessage';

export interface IProps {
  parentObject: MediasManager;
  search?: string;
  favoritesOnly?: boolean;
  categories?: MediaCategory[];
  mimetypes?: Mimetype[];
  onClickFile: (fileId: number) => void;
  onClickFolder: (folderId: number) => void;
}

export const SearchList = ({
  parentObject,
  search,
  categories,
  mimetypes,
  onClickFile,
  onClickFolder,
}: IProps): JSX.Element => {
  const [t] = useTranslation();
  const [activeSearchType, setActiveSearchType] = React.useState<
    'files' | 'folders'
  >('files');
  const [isFilesLoading, setIsFilesLoading] = React.useState<boolean>(false);
  const [isFoldersLoading, setIsFoldersLoading] =
    React.useState<boolean>(false);

  const [fileResults, setFileResults] = React.useState<File[]>([]);
  const [folderResults, setFolderResults] = React.useState<Folder[]>([]);

  React.useEffect(() => {
    setIsFilesLoading(true);
    parentObject
      .listFiles(search, undefined, undefined, categories, mimetypes)
      .then((response) => {
        setFileResults(response.data.results.map((f) => new File(f)));
      })
      .finally(() => setIsFilesLoading(false));
  }, [search, categories, mimetypes]);

  React.useEffect(() => {
    setIsFoldersLoading(true);
    parentObject
      .listFolders(search)
      .then((response) => {
        setFolderResults(response.data.results.map((f) => new Folder(f)));
      })
      .finally(() => setIsFoldersLoading(false));
  }, [search]);

  const renderListElement = React.useCallback(
    (item: File | Folder): JSX.Element => {
      const extractPath = (virtualPath?: string) => {
        return virtualPath?.split('/').slice(2, -1).join('/');
      };

      if (item instanceof File) {
        return (
          <BaseListElement key={item.id} onClick={() => onClickFile(item.id)}>
            <ListElementContent
              title={item.label ?? ''}
              subtitle={extractPath(item.virtualFilepath)}
              color={item.mimetype?.color}
              icon={item.mimetype?.icon ?? { name: 'file' }}
              badge={{
                value: item.category?.label,
                icon: item.category?.icon,
                color: item.category?.color,
              }}
            />
          </BaseListElement>
        );
      }
      return (
        <BaseListElement key={item.id} onClick={() => onClickFolder(item.id)}>
          <ListElementContent
            title={item.label ?? ''}
            subtitle={extractPath(item.virtualPath)}
            icon={{ name: 'folder' }}
          />
        </BaseListElement>
      );
    },
    [onClickFile, onClickFolder],
  );

  const results = activeSearchType === 'files' ? fileResults : folderResults;
  const isLoading =
    activeSearchType === 'files' ? isFilesLoading : isFoldersLoading;

  return (
    <div className="flex flex-col min-h-0">
      <TabController
        tabs={[
          {
            id: 'files',
            label: (
              <SearchLabel
                i18nKey="medias.searchList.files"
                resultsCount={fileResults.length}
                isLoading={isFilesLoading}
                isFailing={false}
              />
            ),
            icon: { name: 'files' },
          },
          {
            id: 'folders',
            label: (
              <SearchLabel
                i18nKey="medias.searchList.folders"
                resultsCount={folderResults.length}
                isLoading={isFoldersLoading}
                isFailing={false}
              />
            ),
            icon: { name: 'folder' },
          },
        ]}
        activeTabId={activeSearchType}
        onChangeTab={setActiveSearchType}
      />
      {results.length ? (
        <div className="overflow-y-auto">{results.map(renderListElement)}</div>
      ) : !isLoading ? (
        <NoContentMessage
          label={t('medias.searchList.noSearchResults')}
          icon={{ name: 'face-monocle' }}
          className="mt-11"
        />
      ) : null}
    </div>
  );
};
