import { getDefaultSort } from '@smack/core/store/app/actions';
import type { IMenuItem } from '@smack/core/views/oldViewsToSort/Layouts/Objects/MenuFilter/Components/Contextual';
import { t } from 'i18next';
export type Sort =
  | 'category__label'
  | '-created_at'
  | '-modified_at'
  | '-start_at'
  | '-end_at'
  | 'label'
  | 'code';

export const getSortOptions = (onReset: () => void): IMenuItem[] => {
  const defaultSortForCategory = getDefaultSort().sort;
  const sort: IMenuItem[] = [
    {
      id: 'sort=category__label',
      label: t('filtersList.category'),
      isDefault: defaultSortForCategory === 'category__label',
      type: 'sort',
      onReset,
    },
    {
      id: 'sort=-created_at',
      label: t('filtersList.creationDate'),
      isDefault: defaultSortForCategory === '-created_at',
      type: 'sort',
      onReset,
    },
    {
      id: 'sort=-modified_at',
      label: t('filtersList.modificationDate'),
      isDefault: defaultSortForCategory === '-modified_at',
      type: 'sort',
      onReset,
    },
    {
      id: 'sort=label',
      label: t('formInputLabels.name'),
      isDefault: defaultSortForCategory === 'label',
      type: 'sort',
      onReset,
    },
    {
      id: 'sort=code',
      label: t('formInputLabels.reference'),
      isDefault: defaultSortForCategory === 'code',
      type: 'sort',
      onReset,
    },
    {
      id: 'sort=-start_at',
      label: t('formInputLabels.startDate'),
      isDefault: defaultSortForCategory === '-start_at',
      type: 'sort',
      onReset,
    },
    {
      id: 'sort=-end_at',
      label: t('formInputLabels.endDate'),
      isDefault: defaultSortForCategory === '-end_at',
      type: 'sort',
      onReset,
    },
  ];

  return sort;
};
