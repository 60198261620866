/**
 *
 * LayerLegend Model
 */

export interface ILayerLegend {
  id: number;
  label: string;
  color: string;
}

/**
 * LayerLegend class model
 *
 * @export
 * @class LayerLegend
 */
export class LayerLegend {
  id: number;

  label: string;

  color: string;

  constructor(data: ILayerLegend) {
    this.id = data.id;
    this.label = data.label;
    this.color = data.color;
  }
}
