import { ListSkeleton, LoaderSkeleton } from '@smack/core/utils/Loader';
import { FormFieldSectionSkeleton } from '@smack/core/utils/Loader/Skeletons/FormFieldSectionSkeleton';
import { FormNavigationSkeleton } from '@smack/core/utils/Loader/Skeletons/FormNavigationSkeleton';

export const BaseObjectFormViewSkeleton = (
  <div className="h-full w-full">
    <LoaderSkeleton height="100%" width="100%">
      <ListSkeleton iterations={5} component={FormNavigationSkeleton} />

      <rect x="300" y="0" height="25" width="25" />
      <rect x="350" y="0" height="25" width="150" />

      <ListSkeleton
        iterations={15}
        offset={45}
        component={FormFieldSectionSkeleton}
      />
    </LoaderSkeleton>
  </div>
);
