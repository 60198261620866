import type { AppState } from '@smack/core/store';
import Color from 'color';
import { useSelector } from 'react-redux';

import type {
  CalendarOptions,
  DatesSetArg,
  EventChangeArg,
  EventClickArg,
} from '@fullcalendar/core';
import enLocale from '@fullcalendar/core/locales/en-gb';
import frLocale from '@fullcalendar/core/locales/fr';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import timeGridPlugin from '@fullcalendar/timegrid';
import { env } from '@smack/core/env';
import { useNavigation } from '@smack/core/hooks/useNavigation/useNavigation';
import React, { type RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import '@smack/core/components/DataDisplay/CalendarTimeLine/CalendarTimeLine.scss';
import adaptivePlugin from '@fullcalendar/adaptive';
import {
  BaseObjectSchedule,
  type IBaseObjectSchedule,
} from '@smack/core/api/models/objects/BaseObjectSchedule';
import { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { ListBaseObject } from '@smack/core/api/models/objects/NewBaseObject/ListBaseObject';
import type { CalendarPreferences } from '@smack/core/api/models/users/User/UserPreferences/UserPreferences';
import { onSaveRecurentObject } from '@smack/core/components/DataDisplay/Alerts/RecurenceAlert';
import {
  type ViewZoom,
  getCalendarViews,
  viewZoomByViews,
} from '@smack/core/components/DataDisplay/CalendarTimeLine/calendarViews/calendarViews';
import { AvailableView } from '@smack/core/components/DataDisplay/CalendarTimeLine/enums';
import { ModalPrintCalendar } from '@smack/core/components/DataDisplay/Modals/ModalPrintCalendar/ModalPrintCalendar';
import type { IViewProps } from '@smack/core/hooks/views/types';
import moment from 'moment';
import { toast } from 'react-hot-toast';

type CalendarType = 'timeline' | 'calendar';

interface IProps extends IViewProps, Partial<CalendarOptions> {
  initialViewType?: AvailableView;
  onViewChange?: (view: AvailableView) => void;
  availableViews?: AvailableView[];
  isPrintable?: boolean;
  onDateChange?: (arg: DatesSetArg) => void;
  preferences?: CalendarPreferences;
  setPreferences?: (preferences: CalendarPreferences) => void;
}

const CalendarTimelineRender: React.ForwardRefRenderFunction<
  FullCalendar,
  IProps
> = (
  {
    isPrintable,
    availableViews = [AvailableView.TIMELINE, AvailableView.CALENDAR],
    mobile,
    onDateChange,
    onViewChange,
    initialViewType,
    datesSet,
    preferences = {},
    setPreferences,
    ...calendarProps
  },
  ref,
) => {
  const { t } = useTranslation();
  const { settings } = useSelector((state: AppState) => state.App);
  const [calendarType, setCalendarType] = React.useState<CalendarType>(
    initialViewType ?? availableViews?.[0] ?? 'timeline',
  );
  const [zoom, setZoom] = React.useState(1);
  const [currentView, setCurrentView] = React.useState<string>();
  const [dateSetArg, setDateSetArg] = React.useState<DatesSetArg>();
  const navigate = useNavigation();
  const calendar = React.useRef<FullCalendar>(null);
  const refToPrint = React.useRef<HTMLDivElement>(null);
  const [isPrint, setIsPrint] = React.useState(false);
  const [printOpen, setPrintOpen] = React.useState(false);
  const getRef = (): RefObject<FullCalendar> => {
    if (ref) return ref as RefObject<FullCalendar>;
    return calendar;
  };

  const [todayBgColor] = React.useState(
    settings?.calendarCurrentDayColor ?? settings?.color,
  ); // Default color

  /***
   * This method dynamically updates the background color of the 'current day header cell'
   * and apply the same lighter color on the column's cells
   */
  const applyTodayBackgroundColor = (): void => {
    const todayElementHeader = document.querySelector('th.fc-day-today');
    const todayElements = document.querySelectorAll('td.fc-day-today');
    let color = todayBgColor ?? '#ffb039'; // default full calendar color
    color = Color(color).lighten(0.5).hex();

    if (todayElementHeader) {
      const cell = todayElementHeader as HTMLTableCellElement;
      cell.style.backgroundColor = color;
    }

    if (todayElements) {
      todayElements.forEach((element) => {
        const cell = element as HTMLTableCellElement;
        cell.style.backgroundColor = color;
      });
    }
  };

  React.useEffect(() => {
    applyTodayBackgroundColor();
  }, [todayBgColor]);

  React.useEffect(() => {
    const type = preferences?.view;
    if (type && type !== calendarType) {
      const viewType = preferences?.viewType?.[type];
      setCalendarType(type);
      getRef()
        ?.current?.getApi()
        .changeView(viewType ?? getDefaultViewFromType(type));
    }
  }, [preferences]);

  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => {
      return new Promise((resolve): void => {
        setIsPrint(true);
        getRef().current?.getApi().trigger('_beforeprint');
        setTimeout(() => {
          resolve(true);
        }, 200);
      });
    },
    onAfterPrint: () => {
      setIsPrint(false);
      getRef().current?.getApi().trigger('_afterprint');
    },
    onPrintError: () => {
      setIsPrint(false);
      getRef().current?.getApi().trigger('_afterprint');
    },
    content: () => refToPrint.current,
  });

  const setDateArg = (arg: DatesSetArg): void => {
    datesSet?.(arg);

    applyTodayBackgroundColor();

    if (
      currentView !== arg.view.type &&
      getViewFromType().includes(arg.view.type)
    ) {
      setCurrentView(arg.view.type);
      const preferencesZoom = preferences?.zoom?.[arg.view.type ?? ''];
      const zoomValue = preferencesZoom ?? getCurrentViewZoom()?.initialZoom;
      if (zoomValue) {
        setZoom(zoomValue);
      }
      setPreferences?.({
        ...preferences,
        viewType: {
          ...preferences?.viewType,
          [calendarType]: arg.view.type,
        },
      });
    }

    if (!arg) return;
    if (!dateSetArg) setDateSetArg(arg);
    if (
      arg.startStr !== dateSetArg?.startStr ||
      arg.endStr !== dateSetArg?.endStr
    ) {
      setDateSetArg(arg);
      onDateChange?.(arg);
    }
  };
  const getCurrentViewZoom = (): ViewZoom | undefined => {
    const view = getRef().current?.getApi().view.type;
    if (!view) return;
    return viewZoomByViews[view];
  };
  const onZoomMore = () => {
    const viewZoom = getCurrentViewZoom();
    if (!viewZoom) return;
    const newValue = zoom + viewZoom.stepZoom;
    if (newValue > viewZoom.maxZoom) {
      setZoom(viewZoom.maxZoom);
      return;
    }
    setZoom(newValue);
    if (!currentView) return;
    setPreferences?.({
      ...preferences,
      zoom: {
        ...preferences?.zoom,
        [currentView]: newValue,
      },
    });
  };

  const onZoomLess = () => {
    const viewZoom = getCurrentViewZoom();
    if (!viewZoom) return;
    const newValue = zoom - viewZoom.stepZoom;
    if (newValue < viewZoom.minZoom) {
      setZoom(viewZoom.minZoom);
      return;
    }
    setZoom(newValue);
    if (!currentView) return;
    setPreferences?.({
      ...preferences,
      zoom: {
        ...preferences?.zoom,
        [currentView]: newValue,
      },
    });
  };

  const getViewFromType = (): string => {
    let views = '';
    if (getCurrentViewZoom()) {
      views += 'zoom-,zoom+ ';
    }
    if (calendarType === AvailableView.TIMELINE) {
      views +=
        'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth,resourceTimelineYear';
    } else {
      views += 'timeGridDay,timeGridWeek,dayGridMonth,dayGridYear';
    }
    return views;
  };

  const getDefaultViewFromType = (type: CalendarType): string => {
    if (type === AvailableView.TIMELINE) {
      return preferences?.viewType?.[type] ?? 'resourceTimelineDay';
    }
    return preferences?.viewType?.[type] ?? 'timeGridWeek';
  };

  const HandleCalendarEventClick = (arg: EventClickArg): void => {
    navigate(arg.event.extendedProps.link as string);
  };

  const onCalendarChangeDate = (
    event: EventChangeArg,
    errorMessage: string,
  ): void => {
    const obj = event.event.extendedProps.object as ListBaseObject;
    if (event.event.start) {
      BaseObject.getBaseObject(obj.id, obj.scheduleId)
        .then((baseobject) => {
          if (!baseobject.scheduleId) return;
          if (baseobject.rrule) {
            onSaveRecurentObject(
              (value) => {
                const data: IBaseObjectSchedule = {
                  dtstart: moment(event.event.start),
                  dtend: moment(event.event.end || event.event.start),
                  update: value,
                  updateFromSchedule: baseobject.scheduleId,
                };
                BaseObjectSchedule.patchBaseObjectSchedule(obj?.id, data);
              },
              (): void => {
                toast.error(errorMessage);
                event.revert();
              },
            );
          } else {
            const data: IBaseObjectSchedule = {
              dtstart: moment(event.event.start),
              dtend: moment(event.event.end || event.event.start),
              update: 'REPLACE_ONE',
              updateFromSchedule: baseobject.scheduleId,
            };
            BaseObjectSchedule.patchBaseObjectSchedule(
              baseobject.id,
              data,
            ).catch((): void => {
              toast.error(errorMessage);
              event.revert();
            });
          }
        })
        .catch((): void => {
          toast.error(errorMessage);
          event.revert();
        });
    }
  };

  return (
    <div
      data-testid="calendar-timeline"
      ref={isPrint ? refToPrint : null}
      className="calendartimeline-print w-full h-full"
    >
      <ModalPrintCalendar open={printOpen} onClose={setPrintOpen} />
      <FullCalendar
        ref={ref ?? calendar}
        height={isPrint ? 'auto' : '100%'}
        plugins={[
          interactionPlugin,
          resourceTimelinePlugin,
          dayGridPlugin,
          timeGridPlugin,
          adaptivePlugin,
        ]}
        allDaySlot={false}
        resourceGroupLabelClassNames={(arg): string =>
          arg.groupValue ? '' : 'hidden'
        }
        schedulerLicenseKey={env.VITE_FULL_CALENDAR_LICENSE_KEY}
        initialView={getDefaultViewFromType(calendarType)}
        headerToolbar={
          mobile
            ? {
                left: `prev,next${
                  availableViews?.length > 1 ? ' calendarView ' : ''
                }${getViewFromType()}`,
                center: 'title',
                right: '',
              }
            : {
                left: `prev,next,today${
                  availableViews?.length > 1 ? ' calendarView' : ''
                }${isPrintable ? ' print' : ''}`,
                center: 'title',
                right: getViewFromType(),
              }
        }
        editable
        resourceGroupField={'group'}
        resourceAreaHeaderContent={() => (
          <p className="font-[500]">{t('calendarTimeline.resourcesTitle')}</p>
        )}
        locales={[frLocale, enLocale]}
        locale={navigator.language}
        views={getCalendarViews(zoom)}
        customButtons={{
          'zoom+': {
            text: 'zoom +',
            click: onZoomMore,
          },
          'zoom-': {
            text: 'zoom -',
            click: onZoomLess,
          },
          calendarView: {
            text:
              calendarType === AvailableView.CALENDAR
                ? t('calendarTimeline.timelineView')
                : t('calendarTimeline.calendarView'),
            click: (): void => {
              const newType =
                calendarType === AvailableView.CALENDAR
                  ? AvailableView.TIMELINE
                  : AvailableView.CALENDAR;
              setPreferences?.({
                ...preferences,
                view: newType,
              });
              setCalendarType(newType);
              onViewChange?.(newType);
              getRef()
                ?.current?.getApi()
                .changeView(getDefaultViewFromType(newType));
            },
          },
          print: {
            text: t('calendarTimeline.print'),
            click: (): void => {
              if (calendarType === 'calendar') {
                setPrintOpen(true);
              } else {
                handlePrint();
              }
            },
          },
        }}
        selectable
        eventChange={(arg): void =>
          onCalendarChangeDate(arg, t('calendarTimeline.errors.changeDate'))
        }
        aspectRatio={1}
        eventClick={HandleCalendarEventClick}
        weekends
        resourceAreaWidth={'200px'}
        datesSet={setDateArg}
        {...calendarProps}
      />
    </div>
  );
};

export const CalendarTimeline = React.forwardRef(CalendarTimelineRender);
