import { DropDown } from '@smack/core/components/DataDisplay/DropDowns/DropDown';
import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import type React from 'react';

interface IProps {
  icon: IconField;
  secondaryIcon?: IconField;
  children: React.ReactNode;
  roundColor: string;
  iconColor?: string;
  hideLine?: boolean;
  iconDropDown?: React.ReactNode[];
  onDropDownClick?: () => void;
  hideLineBefore?: boolean;
  hideLineAfter?: boolean;
  largeIcon?: boolean;
  leftGapSize?: 'small' | 'large';
  isLoading?: boolean;
}

/**
 * IconFeed Comonents
 * @param props IProps
 * @returns JSX.Element
 */
export const IconFeed: React.FC<IProps> = ({
  icon,
  secondaryIcon,
  children,
  roundColor,
  iconDropDown,
  iconColor,
  onDropDownClick,
  hideLineBefore,
  hideLineAfter,
  hideLine,
  largeIcon,
  leftGapSize = 'small',
  isLoading,
}) => {
  const getSecondaryIcon = (): JSX.Element => {
    return (
      <div className="absolute bottom-[-2px] bg-white right-[-5px] w-4 h-4 rounded-full flex justify-center items-center">
        <Icon
          icon={secondaryIcon || { name: 'question' }}
          className={'text-[10px]'}
          color={iconColor || '#fff'}
        />
      </div>
    );
  };

  const iconNode = (
    <div
      onClick={onDropDownClick}
      onKeyDown={onDropDownClick}
      role={iconDropDown?.length ? 'menu' : ''}
      tabIndex={iconDropDown?.length ? 0 : undefined}
      style={{ backgroundColor: !isLoading ? roundColor : undefined }}
      className={`relative rounded-full flex items-center justify-center aspect-square transition-colors ${
        largeIcon ? 'w-11' : 'w-8'
      } ${iconDropDown?.length ? 'cursor-pointer' : ''} ${
        isLoading ? 'bg-gray-600' : ''
      }`}
    >
      <Icon
        color={iconColor || '#fff'}
        className={`${largeIcon ? 'text-2xl' : 'text-md'} -ml-p`}
        icon={isLoading ? { name: 'spinner-third', familyStyle: 'fad' } : icon}
        animation={isLoading ? 'spin' : undefined}
      />
      {secondaryIcon && getSecondaryIcon()}
    </div>
  );

  return (
    <div
      className={`relative flex w-full h-full ${
        largeIcon || leftGapSize === 'large' ? 'pl-14' : 'pl-10'
      }`}
    >
      {!hideLine && (
        <span
          className={`absolute ${
            hideLineBefore ? 'bottom-0 h-[max(calc(100%-22px),22px)]' : ''
          } ${hideLineAfter ? 'top-0 h-[min(calc(50%-16px),22px)]' : ''} ${
            !hideLineAfter && !hideLineBefore ? 'top-0 h-full' : ''
          } left-6 -ml-[0.5px] w-px bg-gray-300 dark:bg-gray-600`}
        />
      )}
      <div
        className={`absolute top-[min(calc(50%-16px),22px)] rounded-full aspect-square ${
          leftGapSize === 'large' ? '!left-[1.125rem]' : ''
        } ${largeIcon ? 'w-11 left-3' : 'w-8 left-2'}`}
      >
        {iconDropDown ? (
          <DropDown placement="bottom-start" menuItems={iconDropDown}>
            {iconNode}
          </DropDown>
        ) : (
          iconNode
        )}
      </div>
      {children}
    </div>
  );
};
