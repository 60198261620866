import { Tooltip } from '@smack/core/components/DataDisplay/Tooltip';
import type { GeoJSON } from 'geojson';
import type React from 'react';
import { useSyncExternalStore } from 'react';

export const formatLongString = (
  input?: string,
  nbCaracteres = Number.NEGATIVE_INFINITY,
): React.ReactNode => {
  if (!input) return null;
  if (input.length < nbCaracteres)
    return <span className="inline-block truncate">{input}</span>;
  return (
    <Tooltip title={input}>
      <span
        className="inline-block truncate"
        style={{ maxWidth: `${nbCaracteres}ch` }}
      >
        {input}
      </span>
    </Tooltip>
  );
};

export const GetEmptyCollection = (): GeoJSON.FeatureCollection => {
  return {
    type: 'FeatureCollection',
    features: [],
  };
};

function subscribeToScreenSize(updateScreenSize) {
  window.addEventListener('resize', updateScreenSize);
  return () => window.removeEventListener('resize', updateScreenSize);
}

let lastKnownScreenSize: [number, number] = [
  window.innerWidth,
  window.innerHeight,
];

function getScreenSize() {
  if (
    lastKnownScreenSize[0] !== window.innerWidth ||
    lastKnownScreenSize[1] !== window.innerHeight
  ) {
    lastKnownScreenSize = [window.innerWidth, window.innerHeight];
  }
  return lastKnownScreenSize;
}

export const useWindowSize = (): [number, number] => {
  return useSyncExternalStore(subscribeToScreenSize, getScreenSize);
};

const mobileWidth = 800;
export const useIsMobile = (): boolean => {
  return useWindowSize()[0] < mobileWidth;
};

/**
 * Generate and update the title of the page.
 */
export const setFullTitlePage = (
  listParamsTitlePage: (string | undefined)[],
): void => {
  let title = '';
  listParamsTitlePage.forEach((elem) => {
    if (elem) title += `${elem} - `;
  });
  title += 'Hyvilo';
  document.title = title;
};

/**
 * return true if param is an object
 * @param object
 * @returns boolean
 */
export const isObject = (object: unknown): object is object => {
  if (typeof object !== 'object') return false;
  return object?.constructor === Object || !object?.constructor;
};

/**
 * Utility function to retrieve the keys of an object with type inference
 * Ref: https://github.com/Microsoft/TypeScript/issues/12870
 * @param o The object you want to retrieve the keys of.
 */
export function keysOf<T extends object>(o: T): (keyof T)[] {
  return Object.keys(o) as (keyof T)[];
}

export function assignRef<T>(ref: React.ForwardedRef<T>, o: T): void {
  if (typeof ref === 'function') {
    ref(o);
  } else if (ref) {
    ref.current = o;
  }
}
