import type { Category } from '@smack/core/api/models/categories/Category';
import { DisplayedButton } from '@smack/core/api/models/categories/DisplayedButton';
import type { IDisplayedButtonComponentProps } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/type';
import type React from 'react';

export enum ActionsButtonViewType {
  Form = 'Form',
  Detail = 'Detail',
}

export const getActionsButtons = (
  category: Category,
  buttonsProps: IDisplayedButtonComponentProps,
  type: ActionsButtonViewType = ActionsButtonViewType.Form,
): React.ReactNode[] => {
  const buttons = category.buttons?.filter((b) => {
    if (type === ActionsButtonViewType.Form) {
      return b.isInForm;
    }
    if (type === ActionsButtonViewType.Detail) {
      return b.isInSheet;
    }
  });

  return (
    buttons?.map((button) =>
      new DisplayedButton(button).getButtonComponent(buttonsProps),
    ) ?? []
  );
};
