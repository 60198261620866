import { IconButton } from '@smack/core/components/Actions/Buttons/Button';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { resetServiceWorker } from '@smack/core/utils/ServiceWorkerUtils';
import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ServiceWorkerReloadModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const ServiceWorkerReloadModal: FC<ServiceWorkerReloadModalProps> = ({
  open,
  setOpen,
}) => {
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setIsLoading(true);
    resetServiceWorker();
  };

  return (
    <Modal
      open={open}
      onClose={setOpen}
      color={'#fa3015'}
      iconColor={'#fefce8'}
      icon={{ name: 'refresh' }}
      title={t('reloadModal.title')}
      customClassWrapper="max-w-xl"
    >
      <p className="pb-4">
        {t('reloadModal.explanation')}{' '}
        <strong className="font-bold">{t('reloadModal.disclaimer')}</strong>
      </p>
      <IconButton
        isLoading={isLoading}
        disabled={isLoading}
        icon={{ name: 'refresh' }}
        className="ml-auto"
        onClick={handleClick}
      >
        {t('reloadModal.action')}
      </IconButton>
    </Modal>
  );
};
