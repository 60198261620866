import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import type { AppState } from '@smack/core/store';
import type React from 'react';
import { useSelector } from 'react-redux';

interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  icon: IconField;
  label: React.ReactNode;
  badge?: string;
  isDragging?: boolean;
  isActive?: boolean;
}

export const ListHeader: React.FC<IProps> = ({
  icon,
  label,
  badge,
  isDragging = false,
  isActive = false,
  children,
  className,
  ...htmlProps
}) => {
  const theme = useSelector((state: AppState) => state.App.theme);

  return (
    <div
      className={`h-12 flex items-center px-4 justify-between ${
        isDragging ? '' : 'cursor-pointer'
      } ${className || ''}`}
      {...htmlProps}
    >
      <div className="flex items-center select-none dark:text-white">
        <Icon
          icon={icon}
          className="fa-fw text-lg text-gray-600 dark:text-gray-300"
        />
        {typeof label === 'string' ? (
          <p className="pl-3 mr-2 flex-grow text-gray-600 dark:text-gray-300">
            {label}
          </p>
        ) : (
          label
        )}
        {badge ? (
          <div
            style={{
              backgroundColor:
                theme === 'light' ? '#f6f6f6' : 'rgb(60, 60, 60)',
            }}
            className="flex items-center justify-center w-5 h-5 rounded-full text-xs text-gray-600"
          >
            {badge}
          </div>
        ) : null}
      </div>
      <div className="flex items-center">
        {children}
        {isDragging ? (
          <Icon
            icon={{ name: 'up-down' }}
            className="fa-fw text-gray-600 dark:text-gray-300"
          />
        ) : (
          <Icon
            icon={{ name: 'chevron-down' }}
            className={`fa-fw transition-transform ease-linear text-gray-600 dark:text-gray-300 ${
              isActive ? 'rotate-180' : ''
            }`}
          />
        )}
      </div>
    </div>
  );
};
