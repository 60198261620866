import React from 'react';

import type { Category } from '@smack/core/api/models/categories/Category';
import type { LinkGroup } from '@smack/core/api/models/categories/LinkGroup';
import { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { DisplayedFieldType } from '@smack/core/api/models/views/ViewElement/enums';
import { DataFieldWrapper } from '@smack/core/components/DataDisplay/DataFieldWrapper';
import { CategoriesChoicesSelect } from '@smack/core/components/DataInput/CategoriesChoicesSelect/CategoriesChoicesSelect';
import type { ILocationInputValue } from '@smack/core/components/DataInput/LocationInput/LocationInput';
import type { RRuleInputValue } from '@smack/core/components/DataInput/RRuleInput';
import type { BaseObjectFormOutputValues } from '@smack/core/components/ViewRenderer/interfaces';
import { LinkableCategoriesChoicesSelect } from '@smack/core/views/oldViewsToSort/Layouts/Forms/FormEventCalendar/components/LinkabkeCategoriesChoicesSelect';
import { QuickForm } from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/QuickForm/QuickForm';
import { useTranslation } from 'react-i18next';

interface IProps {
  onClose: () => void;
  initialRRule?: RRuleInputValue;
  category?: Category;
  onObjectCreated?: (data: { id: number; linkGroup?: LinkGroup }) => void;
  defaultValues?: Record<string, number>;
  parentCategoryId?: number;
  linkId?: number;
}

export const FormEventCalendar: React.FC<IProps> = ({
  category,
  onObjectCreated,
  onClose,
  linkId,
  defaultValues,
  initialRRule,
  parentCategoryId,
}) => {
  const [t] = useTranslation();
  const [openCategory, setOpenCategory] = React.useState<
    Category | null | undefined
  >(category);
  const [linkBaseObject, setLinkBaseObject] = React.useState<BaseObject>();
  const [linkGroup, setLinkGroup] = React.useState<LinkGroup>();
  React.useEffect(() => {
    if (linkId) {
      BaseObject.getBaseObject(linkId).then(setLinkBaseObject);
    }
  }, [linkId]);

  const getInitialLocation = (): ILocationInputValue => {
    return {
      address: linkBaseObject?.address,
      location: linkBaseObject?.location,
      geometry: linkBaseObject?.geometry,
    };
  };

  const handleOnObjectCreated = (data?: { id: number }): void => {
    if (onObjectCreated && data?.id)
      onObjectCreated({ id: data.id, linkGroup: linkGroup });
  };

  const getCategoryInput = (): React.ReactNode => {
    if (category && openCategory) return;
    return linkId ? (
      <LinkableCategoriesChoicesSelect
        linkId={linkId}
        onLinkGroupClicked={setLinkGroup}
        onCategoryClicked={setOpenCategory}
      />
    ) : (
      <DataFieldWrapper label={t('categoriesChoicesSelect.placeholder')}>
        <CategoriesChoicesSelect
          onChange={setOpenCategory}
          value={openCategory}
          parentCategoryId={parentCategoryId}
        />
      </DataFieldWrapper>
    );
  };

  return (
    <div
      data-testid="baseobjet-quick-form"
      className={'flex flex-col flex-grow'}
    >
      <div className="px-3">{getCategoryInput()}</div>
      {openCategory ? (
        <div
          className={
            'w-[90vw] md:w-[70vw] lg:w-[50vw] max-h-[80vh] flex flex-grow'
          }
        >
          <QuickForm
            key={openCategory.id}
            onClose={onClose}
            defaultValues={defaultValues}
            initialCategoryId={openCategory?.id}
            defaultValuesFormFieldType={{
              [DisplayedFieldType.RECURRENCE]:
                initialRRule as BaseObjectFormOutputValues,
              [DisplayedFieldType.RECURRENCE_SIMPLIFIED]:
                initialRRule as BaseObjectFormOutputValues,
              [DisplayedFieldType.LOCATION_INPUT]:
                getInitialLocation() as BaseObjectFormOutputValues,
            }}
            onSuccessfulFormSubmission={handleOnObjectCreated}
          />
        </div>
      ) : null}
    </div>
  );
};
