import { captureMessage } from '@sentry/react';
import { env } from '@smack/core/env';

export interface IBaseSocketError {
  error: 'Unauthorized';
}

export interface IBaseSocketArgs {
  type: string;
}

export abstract class BaseSocketManager<T extends object = IBaseSocketArgs> {
  websocket: WebSocket;

  channel: string;

  onEventReceived: (args: T) => void;

  private failures: number;

  createWebSocket(): WebSocket {
    return new WebSocket(
      `${env.VITE_WS_URL}/ws/${this.channel}?token=${
        localStorage.getItem('access') ?? ''
      }`,
    );
  }

  constructor(channel: string, callback: (args: T) => unknown) {
    this.channel = channel;
    this.onEventReceived = callback;
    this.websocket = this.createWebSocket();
    this.failures = 0;
    this.connect();
  }

  private onWebsocketClose(ev: CloseEvent): void {
    if (ev.wasClean) return;
    if (ev.code === 3000) return; // Unauthorized
    this.failures++;
    if (this.failures > 3) return;
    this.reconnect();
  }

  private isError(data: IBaseSocketError | T): data is IBaseSocketError {
    return 'error' in data && data.error === 'Unauthorized';
  }

  private onWebsocketMessage(ev: MessageEvent<string>): void {
    this.failures = 0;
    const data = JSON.parse(ev.data) as IBaseSocketError | T;
    if (this.isError(data)) {
      this.disconnect();
      captureMessage('Access to a WebSocket channel was rejected', {
        level: 'warning',
        extra: {
          channel_name: this.channel,
        },
      });
    } else {
      this.onEventReceived(data);
    }
  }

  connect(): void {
    // Websocket is closing or closed
    if (this.websocket.readyState > 1) {
      this.websocket = this.createWebSocket();
    }
    this.websocket.addEventListener('close', this.onWebsocketClose.bind(this));
    this.websocket.addEventListener(
      'message',
      this.onWebsocketMessage.bind(this),
    );
  }

  disconnect(): void {
    this.websocket.close(1000);
  }

  reconnect(): void {
    this.disconnect();
    this.connect();
  }
}
