import type React from 'react';

import Item from './Item';
import { useSelectContext } from './SelectProvider';
import type { GroupOption } from './type';

interface GroupItemProps {
  item: GroupOption;
  primaryColor: string;
}

const GroupItem: React.FC<GroupItemProps> = ({ item, primaryColor }) => {
  const { classNames, formatGroupLabel } = useSelectContext();

  return (
    <>
      {item.options.length > 0 && (
        <>
          {formatGroupLabel ? (
            <>{formatGroupLabel(item)}</>
          ) : (
            <div
              className={
                classNames?.listGroupLabel
                  ? classNames.listGroupLabel
                  : 'pr-2 py-2 cursor-default select-none truncate font-bold text-gray-700'
              }
            >
              {item.label}
            </div>
          )}

          {item.options.map((element, index) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: FIXME
            <Item primaryColor={primaryColor} key={index} item={element} />
          ))}
        </>
      )}
    </>
  );
};

export default GroupItem;
