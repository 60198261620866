import type { Reducer } from 'redux';
import {
  type ProjectsActionsType,
  type ProjectsState,
  REFETCH_PROJECTS_STATUSES,
} from './types';

// Initial state
const initialState: ProjectsState = {
  refetchProjectsStatuses: false,
};

// Modal reducer
export const projectsReducer: Reducer<ProjectsState, ProjectsActionsType> = (
  state: ProjectsState | undefined,
  action: ProjectsActionsType,
): ProjectsState => {
  if (!state) return initialState;

  switch (action.type) {
    case REFETCH_PROJECTS_STATUSES: {
      return {
        ...state,
        refetchProjectsStatuses: action.payload.refetch,
      };
    }
    default:
      return state;
  }
};
