import { MediasManager } from '@smack/core/api/models/medias';
import { File as MediaFile } from '@smack/core/api/models/medias/File';
import type { Folder } from '@smack/core/api/models/medias/Folder';
import { Button } from '@smack/core/components/Actions/Buttons/Button';
import { IconLabel } from '@smack/core/components/DataDisplay/Labels/IconLabel';
import type { INode } from '@smack/core/components/DataDisplay/Medias/IMediaNode';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { TextAreaInput } from '@smack/core/components/DataInput/TextAreaInput';
import { useNavigation } from '@smack/core/hooks/useNavigation/useNavigation';
import { t } from 'i18next';
import React from 'react';
import toast from 'react-hot-toast';
import { MediaProperties } from './MediaProperties';

interface IProps {
  target?: INode;
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
}

export const MediaDetailModal: React.FC<IProps> = ({
  target,
  open,
  setOpen,
}) => {
  const [media, setMedia] = React.useState<MediaFile | Folder>();
  const navigation = useNavigation();
  React.useLayoutEffect(() => {
    if (!open) return;
    setMedia(undefined);
  }, [open]);

  React.useEffect(() => {
    if (!open || !target?.id) return;
    if (target.isFolder) {
      MediasManager.getFolder(target.id).then(setMedia);
    } else {
      MediasManager.getFile(target.id).then(setMedia);
    }
  }, [open, target]);

  const shareUrl = React.useMemo((): string | undefined => {
    if (!media) return;
    let url: URL;
    if (media instanceof MediaFile) {
      url = new URL(`/open-file/${media.uuid}`, window.origin);
    } else {
      url = new URL(`/open-folder/${media.uuid}`, window.origin);
    }
    return url.href;
  }, [media]);

  return (
    <Modal
      icon={{ name: 'paperclip' }}
      title={t('medias.detailModalTitle', {
        type:
          media instanceof MediaFile
            ? t('medias.file', { count: 1 })
            : t('medias.folder', { count: 1 }),
      })}
      color={'#EFF6FF'}
      iconColor={'#3886F1'}
      open={open}
      onClose={setOpen}
    >
      <div className="flex flex-col">
        {media && (media instanceof MediaFile || media.externalFilesystemId) ? (
          <div className="py-2 pb-5 flex flex-col gap-3.5 min-w-[300px] max-w-[500px] border-b">
            <TextAreaInput
              id="label"
              label={
                t('medias.pathToMedia', {
                  type:
                    media instanceof MediaFile
                      ? t('medias.file', { count: 1 })
                      : t('medias.folder', { count: 1 }),
                }) ?? ''
              }
              rows={3}
              cols={25}
              value={shareUrl ?? ''}
              readOnly={true}
            />
            <div className="flex items-center gap-3">
              <Button
                className="w-fit"
                onClick={(): void => {
                  navigator.clipboard
                    .writeText(shareUrl ?? '')
                    .then((): void => {
                      toast.success(t('medias.clipboardWriteSuccess'));
                    })
                    .catch((): void => {
                      toast.error(t('medias.clipboardWriteFailure'));
                    });
                }}
              >
                <IconLabel
                  icon={{ name: 'clipboard' }}
                  label={t('medias.copyPath') ?? ''}
                />
              </Button>
              <Button
                className="w-fit"
                onClick={(): void => {
                  if (!shareUrl) return;
                  navigation('../../follows/comments', {
                    addParams: {
                      comment: `<a href="${shareUrl}" target="_blank">${shareUrl}</a>`,
                    },
                  });
                }}
              >
                <IconLabel
                  icon={{ name: 'share' }}
                  label={t('medias.sharePath') ?? ''}
                />
              </Button>
            </div>
          </div>
        ) : null}

        {media ? <MediaProperties media={media} /> : null}
      </div>
    </Modal>
  );
};
