import { NumberInput } from '@smack/core/components/DataInput/NumberInput/NumberInput';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { type Options, RRule } from 'rrule';
import { getRecurenceTypeDays } from '../../Utils';

interface IProps {
  value?: RRule;
  onChange: (value: RRule) => void;
}

export const WeeklyRRule = (props: IProps): JSX.Element => {
  const [t] = useTranslation();
  const { value, onChange } = props;

  const handleChange = (val: Partial<Options>): void => {
    onChange(new RRule({ ...value?.origOptions, ...val }));
  };

  const defaultValue: Partial<Options> = {
    freq: RRule.WEEKLY,
    interval: 1,
    byweekday: [],
  };

  React.useEffect(() => {
    if (value?.options.freq !== RRule.WEEKLY) {
      onChange(new RRule(defaultValue));
    }
  }, [value]);

  const getnumber = (
    num: number[][] | number | number[] | string[] | undefined | null,
  ): number | string | undefined => {
    if (num && typeof num === 'number') {
      return num;
    }
    if (typeof num === 'string') {
      return num;
    }
    if (num && typeof num === 'object') {
      try {
        if (typeof num[0] === 'number') {
          return num[0];
        }
        return num[0][0];
      } catch {
        return undefined;
      }
    }
  };

  const getListNumber = (
    num: number[] | number | undefined,
  ): number[] | undefined => {
    if (num && typeof num === 'number') {
      return [num];
    }
    if (typeof num === 'string') {
      return num;
    }
    if (num && typeof num === 'object') {
      try {
        if (typeof num[0] === 'number') {
          return num;
        }
        return num[0];
      } catch {
        return undefined;
      }
    }
  };

  const getValue = (): Option[] => {
    const output: Option[] = [];
    getListNumber(value?.options.byweekday)?.forEach((v) => {
      const toadd = getRecurenceTypeDays().find((r) => r.value === v);
      if (toadd) output.push(toadd);
    });
    return output;
  };

  return (
    <div>
      <div className="flex items-center mb-3">
        <p className="mr-3 dark:text-text">{t('recurrence.everyWeek')}</p>
        <NumberInput
          size="small"
          value={getnumber(value?.options?.interval)}
          onChange={(val): void => handleChange({ interval: val as number })}
          min={1}
        />
        <p className="ml-3 dark:text-text">{t('recurrence.week')}</p>
      </div>
      <SelectInput
        multiple
        options={getRecurenceTypeDays()}
        value={getValue()}
        label={t('recurrence.days')}
        onChange={(val): void =>
          handleChange({ byweekday: val?.map((v) => v.value) as number[] })
        }
      />
    </div>
  );
};
