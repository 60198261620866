import type React from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncMemo } from 'use-async-memo';

import type { Task } from '@smack/core/api/models/tasks/Task';
import { Rules } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/Rules';
import { TableBlock } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/TaskTable/Components/TableBlock';

interface IProps {
  task: Task;
}

export const TaskRulesBlock: React.FC<IProps> = ({ task }) => {
  const rules = useAsyncMemo(() => task.getRules(), [], []);
  const [t] = useTranslation();

  if (!rules.length) return null;

  return (
    <TableBlock
      title={t('tasks.rules')}
      icon={{ name: 'bolt-auto' }}
      collapsable
    >
      <Rules rules={rules} borderless />
    </TableBlock>
  );
};
