import { type ReactNode, useMemo, useState } from 'react';

import type { Status } from '@smack/core/api/models/tasks/Status';
import type { Task } from '@smack/core/api/models/tasks/Task';
import { useShowIfAllowedContext } from '@smack/core/components/DataDisplay/ShowIfAllowed/Context';
import { useTasksContext } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Context';
import { getStatusesDropDownItems } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/utils';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

/**
 * Create ready-to-use components to handle status changes in dropdowns
 * @param task The task object to patch
 * @param reloadProject The callback on successful status change
 */
export const useChangeStatus = (
  task: Task,
  reloadProject: () => Promise<void>,
): [ReactNode[] | undefined, (sta: Status) => Promise<void>, boolean] => {
  const [t] = useTranslation();

  const { statuses = [] } = useTasksContext();
  const filteredStatuses = useMemo(
    () =>
      statuses.filter((status) => {
        if (task.isBinaryChoice) {
          return status.isBinaryOption;
        }
        return !status.isHidden;
      }),
    [statuses],
  );

  const [isChangingStatus, setIsChangingStatus] = useState(false);

  const onChangeStatus = (sta: Status): Promise<void> => {
    setIsChangingStatus(true);
    return task
      .patch({ status: sta.id })
      .then(
        () => reloadProject(),
        () => {
          toast.error(t('tasks.errors.cannotChangeStatus'));
        },
      )
      .finally(() => setIsChangingStatus(false));
  };

  const menuItems = useMemo(
    () => getStatusesDropDownItems(filteredStatuses, onChangeStatus),
    [filteredStatuses, task],
  );

  const { isWritable } = useShowIfAllowedContext();
  if (!isWritable)
    return [
      undefined,
      (): Promise<void> =>
        Promise.reject('User is lacking permissions to change status'),
      false,
    ];

  return [menuItems, onChangeStatus, isChangingStatus];
};
