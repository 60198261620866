import { NumberInput } from '@smack/core/components/DataInput/NumberInput/NumberInput';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { type Options, RRule } from 'rrule';
import {
  getDaysNumber,
  getRecurenceNumberStringOptions,
  getRecurenceTypeDays,
} from '../../Utils';

interface IProps {
  value?: RRule;
  onChange: (value: RRule) => void;
}

export const MonthlyRRule = (props: IProps): JSX.Element => {
  const [t] = useTranslation();
  const { value, onChange } = props;
  const [choices, setChoices] = React.useState(
    value?.options.bysetpos?.length ? 2 : 1,
  );

  const handleChange = (val: Partial<Options>): void => {
    onChange(new RRule({ ...value?.origOptions, ...val }));
  };

  const defaultFirstValue: Partial<Options> = {
    freq: RRule.MONTHLY,
    bymonthday: 1,
  };

  const defaultSecondValue: Partial<Options> = {
    freq: RRule.MONTHLY,
    bysetpos: 1,
    byweekday: 0,
  };

  const onChangeChoice = (val: number): void => {
    setChoices(val);
    onChange(
      val === 1 ? new RRule(defaultFirstValue) : new RRule(defaultSecondValue),
    );
  };

  React.useEffect(() => {
    if (value?.options.freq !== RRule.MONTHLY) {
      onChangeChoice(1);
    }
  }, [value]);

  const getnumber = (
    num: number[][] | number | number[] | string[] | undefined | null,
  ): number | string | undefined => {
    if (num && typeof num === 'number') {
      return num;
    }
    if (typeof num === 'string') {
      return num;
    }
    if (num && typeof num === 'object') {
      try {
        if (typeof num[0] === 'number') {
          return num[0];
        }
        return num[0][0];
      } catch {
        return undefined;
      }
    }
  };

  return (
    <div className="">
      <div className="flex items-center">
        <p className="mr-3 dark:text-text">{t('recurrence.every')}</p>
        <NumberInput
          size="small"
          value={getnumber(value?.options?.interval)}
          onChange={(val): void => handleChange({ interval: val as number })}
          min={1}
        />
        <p className="ml-3 dark:text-text">{t('recurrence.month')}</p>
      </div>
      <div className="flex items-center mt-3">
        <input
          type="radio"
          onChange={(): void => onChangeChoice(1)}
          checked={choices === 1}
          className="h-4 w-4 mr-3 border-gray-300 text-blue-500 focus:ring-blue-500"
        />
        <div
          className={`flex items-center ${
            choices === 1 ? 'opacity-100' : 'opacity-20'
          }`}
        >
          <p className="mr-3 dark:text-text">{t('recurrence.on')}</p>
          <SelectInput
            className="min-w-[150px]"
            value={getDaysNumber().find(
              (v) => v.value === getnumber(value?.options?.bymonthday),
            )}
            options={getDaysNumber()}
            onChange={(val): void =>
              handleChange({ bymonthday: val?.value as number })
            }
          />
          <p className="ml-3 dark:text-text">{t('recurrence.fromMonth')}</p>
        </div>
      </div>
      <div className="flex items-center">
        <input
          type="radio"
          onChange={(): void => onChangeChoice(2)}
          checked={choices === 2}
          className="h-4 w-4 mr-3 border-gray-300 text-blue-500 focus:ring-blue-500"
        />
        <div
          className={`flex flex-wrap items-center ${
            choices === 2 ? 'opacity-100' : 'opacity-20'
          }`}
        >
          <p className="mr-3 dark:text-text">{t('recurrence.on')}</p>
          <div className="mr-3">
            <SelectInput
              className="min-w-[150px]"
              options={getRecurenceNumberStringOptions()}
              value={getRecurenceNumberStringOptions().find(
                (v) => v.value === getnumber(value?.options?.bysetpos),
              )}
              onChange={(val): void =>
                handleChange({ bysetpos: val?.value as number })
              }
            />
          </div>
          <SelectInput
            className="min-w-[150px]"
            options={getRecurenceTypeDays()}
            value={getRecurenceTypeDays().find(
              (v) => v.value === getnumber(value?.options?.byweekday),
            )}
            onChange={(val): void =>
              handleChange({ byweekday: val?.value as number })
            }
          />
        </div>
      </div>
    </div>
  );
};
