import type { LngLatBounds, Map as MapLibreMap } from 'maplibre-gl';
import React from 'react';

interface UseMapBoundsProps {
  map?: MapLibreMap | null;
}

export const useMapBounds = ({
  map,
}: UseMapBoundsProps): LngLatBounds | undefined => {
  const [bounds, setBounds] = React.useState<LngLatBounds>();

  React.useEffect(() => {
    const handleMove = (): void => {
      if (map) {
        const newBounds = map.getBounds();
        setBounds(newBounds);
      }
    };

    if (map) {
      // Add the event listener for the 'move' event
      map.on('dragend', handleMove);
      map.on('zoomend', handleMove);

      // Initial bounds
      setBounds(map.getBounds());

      // Clean up the event listener when the component is unmounted
      return (): void => {
        map.off('dragend', handleMove);
        map.off('zoomend', handleMove);
      };
    }
  }, [map]);

  return bounds;
};
