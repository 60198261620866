import { geojsonToWKT, wktToGeoJSON } from '@terraformer/wkt';
import { parse, stringify } from 'qs';
import React from 'react';
import {
  ArrayParam,
  BooleanParam,
  type DecodedValueMap,
  NumberParam,
  ObjectParam,
  type QueryParamConfig,
  StringParam,
  useQueryParams,
} from 'use-query-params';

export type ISearchParams = DecodedValueMap<typeof QueryParamsMode>;

const NestedObjectParam: QueryParamConfig<object> = {
  encode: (value): string => stringify(value, { encode: false }),
  decode: (value): object =>
    value && typeof value === 'string' ? parse(value) : {},
};

const DrawParam: QueryParamConfig<boolean | GeoJSON.MultiPolygon> = {
  encode: (value): string | undefined | null | (string | null)[] => {
    if (typeof value === 'boolean') {
      return BooleanParam.encode(value);
    }
    if (typeof value === 'object') {
      return geojsonToWKT(value);
    }
  },
  decode: (value): boolean | GeoJSON.MultiPolygon => {
    try {
      if (value === '0' || value === '1') {
        return !!BooleanParam.decode(value as string);
      }
      return wktToGeoJSON(value as string) as GeoJSON.MultiPolygon;
    } catch (e) {
      return false;
    }
  },
};
const QueryParamsMode = {
  category: StringParam,
  comment: StringParam,
  file: NumberParam,
  folder: NumberParam,
  task: NumberParam,
  filtersSearch: StringParam,
  filtersCategoryIn: ArrayParam,
  filtersCurrentCategoryId: NumberParam,
  filtersShowGeom: BooleanParam,
  filtersReportState: BooleanParam,
  filtersViewedNotifications: BooleanParam,
  filtersPeriod: StringParam,
  filtersDraw: DrawParam,
  filtersContainingFeatures: ArrayParam,
  filtersScreen: BooleanParam,
  filtersFilteringLayer: StringParam,
  filtersVisualizedAttributes: ObjectParam,
  filtersFilteredAttributes: NestedObjectParam,
  filtersSort: StringParam,
  filtersAttributeSort: ObjectParam,
  filtersAttributes: NestedObjectParam,
  filtersDateRange: ObjectParam,
  schedule: NumberParam,
};

export const useSearchParams = (): [
  ISearchParams,
  (params: Partial<ISearchParams>) => void,
] => {
  const [query, setQuery] =
    useQueryParams<typeof QueryParamsMode>(QueryParamsMode);

  const setSearch = React.useCallback(
    (params: Partial<ISearchParams>) => {
      setQuery({ ...query, ...params }, 'replace');
    },
    [query, setQuery],
  );

  return [query, setSearch];
};
