import type { Category } from '@smack/core/api/models/categories/Category';
import type { ParentCategory } from '@smack/core/api/models/categories/Category/ParentCategory';
import type { SubCategory } from '@smack/core/api/models/categories/Category/SubCategory';
import { useSearchParams } from '@smack/core/hooks/useSearchParams';
import type { AppState } from '@smack/core/store';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export type UseActiveCategoriesReturn = [
  ParentCategory | undefined,
  ParentCategory | undefined,
  SubCategory | undefined,
  boolean,
];

export const useActiveCategories = (): UseActiveCategoriesReturn => {
  const { module, set } = useParams();
  const [searchparams] = useSearchParams();
  const {
    rootCategories,
    setCategories,
    subSetCategories,
    subSetCategoriesFetched,
  } = useSelector((store: AppState) => store.Categories);

  const rootCategory = rootCategories.find((c) => c.id.toString() === module);
  const setCategory = setCategories.find((c) => c.id.toString() === set);
  const subSetCategory = subSetCategories.find(
    (c) => c.id.toString() === searchparams.category?.toString(),
  );
  const isSubSetCategorySpecified = subSetCategoriesFetched
    ? !!subSetCategory
    : !!searchparams.category;
  return [rootCategory, setCategory, subSetCategory, isSubSetCategorySpecified];
};

export function isBaseobjectCreationAvailable(
  subCategory?: Category,
  subSetCategories?: Category[],
): boolean {
  if (subCategory?.canAnyChildrenBeCreatedInFront) return true;
  return !(
    subSetCategories?.length &&
    subSetCategories.every((s) => !s.canBeCreatedInFront)
  );
}
