import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { User } from '@smack/core/api/models/users/User';
import { useNavigation } from '@smack/core/hooks/useNavigation/useNavigation';
import type { AppState } from '@smack/core/store';
import { setOnline } from '@smack/core/store/app/actions';
import { setCurrentUser } from '@smack/core/store/users/actions';
import { setOnlineInterval } from '@smack/core/utils/onlineInterval';
import axios from 'axios';
import React, { type ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Strip elements to ease the analytics
function Matomification(pathname: string): string {
  const replacements: [string | RegExp, string][] = [
    [/\/objects\/\d+/, '/object'], // Do not log each object opened, just log "an object opened"
    [/\/details\/\d+/, '/details'], // Do not log each details panel
    [/\/links\/\d+/, '/links'], // Do not log each links panel
    // Add others filters in the future
  ];
  let ret = pathname;
  for (const replacement of replacements) {
    ret = ret.replace(...replacement);
  }
  return ret;
}

// Private route to redirect to login if no token

export const PrivateRoute: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [mount, setMount] = React.useState(false);
  const [authError, setAuthError] = React.useState<Error>();
  const history = useNavigation();
  const matomo = useMatomo();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isOnline = useSelector((state: AppState) => state.App.appOnline);

  React.useEffect(() => {
    // Offline fallback is assured by <NoInternet />
    if (!isOnline) return;

    const next = pathname === '/' ? '' : `?next=${pathname}`;

    const redirectToLogin = (): void => {
      dispatch(setCurrentUser());
      history(`/login${next}`);
    };

    const interval = setOnlineInterval(
      () => {
        User.refreshToken(
          () => {},
          (err) => {
            if (axios.isAxiosError(err) && err.code === '401') {
              return redirectToLogin();
            }
            dispatch(setOnline(false));
          },
        );
      },
      60 * 60 * 1000,
    );

    if (!localStorage.getItem('refresh')) {
      return redirectToLogin();
    }
    User.verifyToken(
      () => {
        User.getMe()
          .then((user) => {
            dispatch(setCurrentUser(user));
            setMount(true);
          })
          .catch(() => {
            return redirectToLogin();
          });
      },
      (err) => {
        if (axios.isAxiosError(err)) {
          return redirectToLogin();
        }
        setAuthError(
          new Error('Cannot verify auth and/or refresh tokens', {
            cause: err,
          }),
        );
      },
    );

    return () => {
      clearInterval(interval);
    };
  }, [isOnline]);

  React.useEffect(() => {
    matomo.trackPageView({ href: Matomification(pathname) });
  }, [pathname]);

  if (authError) throw authError;

  return mount ? <>{children}</> : null;
};
