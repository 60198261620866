import type { LinkGroup } from '@smack/core/api/models/categories/LinkGroup';
import { DataFieldWrapper } from '@smack/core/components/DataDisplay/DataFieldWrapper';
import { NoContentMessage } from '@smack/core/components/DataDisplay/NoContentMessage';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  onLinkGroupClicked: (linkgroup: LinkGroup) => void;
  linkGroups: LinkGroup[];
}

const linkGroupToOption = (linkgroup: LinkGroup): Option => ({
  label: linkgroup.label || linkgroup.id.toString(),
  color: linkgroup.color,
  icon: linkgroup.icon,
  value: linkgroup.id,
});

export const LinkGroupSelector: React.FC<IProps> = ({
  onLinkGroupClicked,
  linkGroups,
}) => {
  const [t] = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [linkGroupValue, setLinkGroupValue] = React.useState<Option | null>(
    null,
  );

  const onLinkGroupSelected = (option?: Option | null): void => {
    setLinkGroupValue(option ?? null);
    if (!option) return;
    const linkSelected = linkGroups.find(
      (s) => s.id.toString() === option.value.toString(),
    );
    if (!linkSelected) return;
    onLinkGroupClicked(linkSelected);
  };

  const optionLinkGroups = React.useMemo(
    () => linkGroups.map(linkGroupToOption),
    [linkGroups],
  );

  if (!linkGroups?.length) {
    return (
      <div className={'w-56 h-52'}>
        <NoContentMessage
          label={t('addLinkForm.noLinkGroups')}
          icon={{ name: 'link' }}
        />
      </div>
    );
  }

  return (
    <DataFieldWrapper label={t('addLinkForm.linkGroupSelector.label')}>
      <SelectInput
        width={'fluid'}
        value={linkGroupValue}
        isClearable
        onChange={onLinkGroupSelected}
        placeholder={t('addLinkForm.linkGroupSelector.placeholder')}
        options={optionLinkGroups}
      />
    </DataFieldWrapper>
  );
};
