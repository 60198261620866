import type { ITemplateSkeletonComponent } from '.';

export const ReminderSkeleton: ITemplateSkeletonComponent = ({
  offset = 0,
}) => (
  <>
    <rect
      x={30}
      y={offset + 15}
      width="calc(100% - 100px)"
      height={15}
      rx={7}
    />
    <rect
      x={30}
      y={offset + 40}
      width="calc(100% - 100px)"
      height={10}
      rx={5}
    />
    <rect x={0} y={offset + 64} width="100%" height={1} />
  </>
);

ReminderSkeleton.itemOffset = 64;
