import { Category } from '@smack/core/api/models/categories/Category';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import React from 'react';

interface IProps {
  onCategoryClicked: (category: Category) => void;
  startCategoryId?: number;
  isCategoryDisabled?: (category: Category) => boolean;
  categoryDisabledReason?: (category: Category) => React.ReactNode;
}

export const CategorySelector: React.FC<IProps> = ({
  startCategoryId,
  isCategoryDisabled,
  categoryDisabledReason,
  onCategoryClicked,
}) => {
  const [categories, setCategories] = React.useState<Category[]>([]);

  React.useEffect(() => {
    if (startCategoryId) {
      Category.getChildCategories(startCategoryId).then(setCategories);
    } else {
      Category.getRootCategories().then(setCategories);
    }
  }, [startCategoryId]);

  const onSelectedCategory = (category: Category): void => {
    Category.getChildCategories(category.id).then((res) => {
      if (res.length) {
        setCategories(res);
      } else {
        onCategoryClicked(category);
      }
    });
  };

  return (
    <div
      data-testid="category-selector"
      className="w-full grid grid-cols-1 sm:grid-cols-fluid	gap-3  "
    >
      {categories?.map((category) => {
        const isDisabled = isCategoryDisabled?.(category);
        return (
          <button
            type="button"
            key={category.id}
            disabled={isDisabled}
            onClick={(): void => onSelectedCategory(category)}
            className="col-span-1 flex-grow  flex flex-col rounded-lg bg-white dark:bg-gray-600 disabled:dark:bg-gray-500 disabled:bg-gray-200 text-center disabled:text-gray-600 dark:text-gray-100 disabled:dark:text-gray-200 shadow-md hover:shadow-lg transition-shadow cursor-pointer disabled:cursor-not-allowed ease-linear p-8 items-center"
          >
            <div
              style={{ backgroundColor: category.color }}
              className="mx-auto h-16 w-16 flex-shrink-0 rounded-full bg-gray-200 flex items-center justify-center"
            >
              <Icon icon={category.icon} className={'text-3xl text-white'} />
            </div>
            <h3 className="mt-6 text-sm font-medium">{category.label}</h3>
            {isDisabled ? (
              <p className="text-xs italic">
                {categoryDisabledReason?.(category)}
              </p>
            ) : null}
          </button>
        );
      })}
    </div>
  );
};
