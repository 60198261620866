import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import type { IDisplayedButton } from '../DisplayedButton';

export interface IPermissions {
  isPreviewable?: boolean;
  isReadable?: boolean;
  isWritable?: boolean;
  isProcessable?: boolean;
  isShareable?: boolean;
  isManageable?: boolean;
}

export enum DefaultGeoJSON {
  Geometry = 'GEOMETRY',
  Point = 'POINT',
}

export enum CategoryLevel {
  Module = 'MODULE',
  Set = 'SET',
  Category = 'CATEGORY',
}

/**
 * Interface for the Category Model
 *
 * @export
 * @interface ICategory
 */
export interface ICategory extends IPermissions {
  id: number;
  label: string;
  icon?: IconField;
  color?: string;
  breadcrumbs?: string;
  canBeCreatedInFront?: boolean;
  canAnyChildrenBeCreatedInFront?: boolean;
  parentCategoryId?: number;
  isNotification?: boolean;
  buttons?: IDisplayedButton[];
  isPeriodFilterAvailable?: boolean;
  isChildrenFilterAvailable?: boolean;
  isAreaFilterAvailable?: boolean;
  notificationSupportsDescription?: boolean;
  frontEndpoint?: string;
  isEmptyLinkGroupHidden?: boolean;
  isDisplayingCommentsByDefault?: boolean;
  isDisplayingLogsByDefault?: boolean;
  isModule: boolean;
  isSet: boolean;
  isCategory: boolean;
}

/**
 * Category Model class
 *
 * @export
 * @class Category
 */
export class Category implements ICategory {
  id: number;

  label: string;

  icon?: IconField;

  color?: string;

  breadcrumbs?: string;

  canBeCreatedInFront?: boolean;

  canAnyChildrenBeCreatedInFront?: boolean;

  isPreviewable?: boolean;

  isReadable?: boolean;

  isWritable?: boolean;

  isManageable?: boolean;

  isProcessable?: boolean;

  parentCategoryId?: number;

  isNotification?: boolean;

  buttons?: IDisplayedButton[];

  notificationSupportsDescription?: boolean;

  frontEndpoint?: string;

  isEmptyLinkGroupHidden?: boolean;

  isDisplayingCommentsByDefault?: boolean;

  isDisplayingLogsByDefault?: boolean;

  isModule: boolean;

  isSet: boolean;

  isCategory: boolean;

  constructor(data: ICategory) {
    this.id = data.id;
    this.label = data.label;
    this.icon = data.icon;
    this.color = data.color;
    this.breadcrumbs = data.breadcrumbs;
    this.canBeCreatedInFront = data.canBeCreatedInFront;
    this.parentCategoryId = data.parentCategoryId;
    this.isNotification = data.isNotification;
    this.buttons = data.buttons;
    this.isPreviewable = data.isPreviewable;
    this.isWritable = data.isWritable;
    this.isReadable = data.isReadable;
    this.isProcessable = data.isProcessable;
    this.isManageable = data.isManageable;
    this.canAnyChildrenBeCreatedInFront = data.canAnyChildrenBeCreatedInFront;
    this.notificationSupportsDescription = data.notificationSupportsDescription;
    this.frontEndpoint = data.frontEndpoint;
    this.isEmptyLinkGroupHidden = data.isEmptyLinkGroupHidden;
    this.isDisplayingCommentsByDefault = data.isDisplayingCommentsByDefault;
    this.isDisplayingLogsByDefault = data.isDisplayingLogsByDefault;
    this.isModule = data.isModule;
    this.isSet = data.isSet;
    this.isCategory = data.isCategory;
  }

  static getRootCategories(): Promise<Category[]> {
    return RESTClient.get<{ data: { results: ICategory[] } }>(
      `/categories/categories?level=${CategoryLevel.Module}`,
      {
        'with-buttons': true,
      },
    ).then((res) => {
      return res.data.results.map((cat) => new Category(cat));
    });
  }

  static getChildCategories(
    parentCategoryId: number | string,
  ): Promise<Category[]> {
    return RESTClient.get<{ data: { results: ICategory[] } }>(
      `/categories/categories?parent-category=${parentCategoryId}`,
      {
        'with-buttons': true,
      },
    ).then((res) => {
      return res.data.results.map((cat) => new Category(cat));
    });
  }

  static getFinalChildCategories(): Promise<Category[]> {
    return RESTClient.get<{ data: { results: ICategory[] } }>(
      `/categories/categories?level=${CategoryLevel.Category}`,
      {
        'with-buttons': true,
      },
    ).then((res) => {
      return res.data.results.map((cat) => new Category(cat));
    });
  }

  static getCategory(id: number | string): Promise<Category> {
    return RESTClient.get<{ data: { results: ICategory } }>(
      `/categories/categories/${id}`,
      {
        'with-buttons': true,
      },
    ).then((res) => {
      return new Category(res.data.results);
    });
  }
}
