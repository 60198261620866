import type React from 'react';
import {
  DragDropContext,
  Draggable,
  type DraggableProvided,
  Droppable,
  type OnDragEndResponder,
} from 'react-beautiful-dnd';

export interface DnDElementProps {
  drag?: DraggableProvided;
}

export type DnDComponent<T extends DnDElementProps> =
  React.JSXElementConstructor<T>;

interface DnDListProps<T extends DnDElementProps> {
  listElements: {
    key: string;
    Element: DnDComponent<T>;
    props: T;
  }[];
  onDrag: OnDragEndResponder;
}

export const DnDList = <T extends DnDElementProps>({
  listElements,
  onDrag,
}: DnDListProps<T>): JSX.Element => {
  return (
    <DragDropContext onDragEnd={onDrag}>
      <Droppable droppableId="list">
        {(provided): JSX.Element => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {listElements.map(
              ({ key, Element, props }, i): JSX.Element => (
                <Draggable index={i} key={key} draggableId={key}>
                  {(draggable): JSX.Element => (
                    <Element {...props} drag={draggable} />
                  )}
                </Draggable>
              ),
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
