import { DateInput } from '@smack/core/components/DataInput/DateInput';
import { NumberInput } from '@smack/core/components/DataInput/NumberInput/NumberInput';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export enum EndType {
  NextYear = 'NEXT_YEAR',
  OnDate = 'ON_DATE',
  NumberOfExecution = 'NUMBER_OF_EXECUTION',
  Never = 'NEVER',
}

export interface EndRecurrenceOutput {
  type: EndType;
  value?: string | number;
}

interface IProps {
  value?: EndRecurrenceOutput;
  onChange: (val: EndRecurrenceOutput) => void;
}

export const SelectEndRecurrenceInput: React.FC<IProps> = ({
  value,
  onChange,
}) => {
  const [t] = useTranslation();
  const options: Option<EndType>[] = React.useMemo(() => {
    return [
      {
        label: t('recurrence.forAYear'),
        value: EndType.NextYear,
      },
      {
        label: t('recurrence.preciseDate'),
        value: EndType.OnDate,
      },
      {
        label: t('recurrence.numberOfExecution'),
        value: EndType.NumberOfExecution,
      },
      {
        label: t('recurrence.ever'),
        value: EndType.Never,
      },
    ];
  }, []);

  const handleChange = useCallback((val: Option<EndType>) => {
    if (val.value === EndType.OnDate) {
      onChange({
        type: val.value,
        value: new Date().toISOString(),
      });
    } else if (val.value === EndType.NumberOfExecution) {
      onChange({
        type: val.value,
        value: 1,
      });
    } else {
      onChange({
        type: val.value,
      });
    }
  }, []);

  const handleChangeDate = (val?: string): void => {
    if (val && value?.type === EndType.OnDate) {
      onChange({
        value: val,
        type: EndType.OnDate,
      });
    }
  };

  const handleChangeExecution = (val?: string | number): void => {
    if (value?.type === EndType.NumberOfExecution) {
      onChange({
        value: val,
        type: EndType.NumberOfExecution,
      });
    }
  };

  return (
    <div className={'flex items-center gap-3'}>
      <SelectInput
        width={'fluid'}
        options={options}
        value={options.find((v) => v.value === value?.type) ?? options[0]}
        onChange={(val): void => handleChange(val as Option<EndType>)}
        isMultiple={false}
        isClearable={false}
      />
      {value?.type === EndType.OnDate && (
        <DateInput
          hidetime
          value={value.value ? value.value.toString() : undefined}
          onChange={handleChangeDate}
        />
      )}
      {value?.type === EndType.NumberOfExecution && (
        <NumberInput
          value={value.value ?? ''}
          onChange={handleChangeExecution}
        />
      )}
    </div>
  );
};
