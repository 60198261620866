import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import { cva } from 'class-variance-authority';
import type React from 'react';

interface IDataSectionNavigationItem {
  id: number | string;
  label: string;
  icon?: IconField;
}

export interface IDataSectionNavigationProps {
  navigationItems: IDataSectionNavigationItem[];
  optionalText?: string;
  currentId?: string | number;
  hiddenItems?: (string | number)[];
  onClickHide?: (id: string | number) => void;
}

export const DataSectionNavigation: React.FC<IDataSectionNavigationProps> = (
  props: IDataSectionNavigationProps,
) => {
  const { navigationItems, currentId, optionalText, onClickHide, hiddenItems } =
    props;

  const hideIconStyles = cva('ml-1', {
    variants: {
      state: {
        hidden: 'text-gray-300',
        active: 'text-blue-300',
        default: 'text-gray-400',
      },
    },
    defaultVariants: {
      state: 'default',
    },
  });

  const getHideIconClassName = (itemId: IDataSectionNavigationItem['id']) => {
    if (hiddenItems?.includes(itemId)) {
      return hideIconStyles({ state: 'hidden' });
    }

    if (itemId === currentId) {
      return hideIconStyles({ state: 'active' });
    }

    return hideIconStyles();
  };

  return (
    <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-2 hidden md:block">
      <nav className="space-y-1">
        {navigationItems.map((item) => (
          <a
            key={item.label}
            href={`#${item.id || ''}`}
            className={`${
              item.id === currentId
                ? 'bg-white text-blue-500 hover:text-blue-500 hover:bg-white dark:bg-neutral-600'
                : 'text-gray-900 hover:text-gray-900 hover:bg-white hover:dark:bg-neutral-600 dark:text-gray-200'
            }
              group rounded-md px-3 py-2 flex items-center text-sm font-medium relative
          `}
          >
            {item.icon && (
              <Icon
                className={`${
                  item.id === currentId
                    ? 'text-blue-500 group-hover:text-blue-500'
                    : 'text-gray-400 group-hover:text-gray-500'
                }
                flex-shrink-0 -ml-1 mr-3 h-6 w-6
              `}
                icon={item.icon}
              />
            )}

            <span className="truncate uppercase">{item.label}</span>
            {onClickHide && (
              <Icon
                onClick={(e): void => {
                  e.preventDefault();
                  if (onClickHide) onClickHide(item.id);
                }}
                className={getHideIconClassName(item.id)}
                icon={{
                  name: hiddenItems?.includes(item.id) ? 'eye-slash' : 'eye',
                }}
              />
            )}
          </a>
        ))}
        {optionalText && (
          <div className="pt-6 px-3">
            <p className="text-xs text-gray-400">{optionalText} </p>
          </div>
        )}
      </nav>
    </aside>
  );
};
