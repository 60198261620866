/* eslint-disable no-console */
import { useRegisterSW } from 'virtual:pwa-register/react';
import { captureException } from '@sentry/react';

const refreshInterval = 60 * 1000;

export const useSW = (): [
  boolean,
  () => Promise<void>,
  (value: boolean) => void,
] => {
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(scriptUrl, registration) {
      console.info(`SW Registered: ${scriptUrl}`);
      if (registration) {
        // Periodically check for service worker update
        setInterval(() => {
          void registration.update();
        }, refreshInterval);
        // Update registration immediately
        void registration.update();
      }
    },
    onRegisterError(error) {
      console.error(error);
      captureException(error, {
        tags: {
          'service-worker': 'registration',
        },
      });
      // doomed
    },
  });

  return [needRefresh, updateServiceWorker, setNeedRefresh];
};
