import {
  CancelButton,
  SaveButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { InfoAlert } from '@smack/core/components/DataDisplay/Alerts/Alerts';
import {
  type IModalProps,
  Modal,
} from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { RadioInput } from '@smack/core/components/DataInput/RadioInput';
import { spawnModal } from '@smack/core/utils/modal';
import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

export enum TypeRecurrenceUpdateChoice {
  ALL = 'ALL',
  NEXT = 'NEXT',
  THIS = 'THIS',
}
interface IProps extends Omit<IModalProps, 'children'> {
  description?: string;
  onSave: (value: TypeRecurrenceUpdateChoice) => void;
  customButtons?: (handleCancel, handleSave) => React.ReactNode;
}

export const RecurrenceUpdateChoiceObjectModal: React.FC<IProps> = ({
  description,
  onSave,
  customButtons,
  ...modalProps
}) => {
  const [value, setValue] = React.useState<TypeRecurrenceUpdateChoice>(
    TypeRecurrenceUpdateChoice.THIS,
  );
  const [t] = useTranslation();
  return (
    <Modal {...modalProps}>
      <div className="min-w-1/2 max-w-[500px] flex flex-col gap-3">
        {description && (
          <InfoAlert>
            <p className={'text-[12px]'}>{description}</p>
          </InfoAlert>
        )}
        <div className="flex flex-col gap-3">
          <RadioInput
            name={TypeRecurrenceUpdateChoice.THIS}
            label={t('recurrenceChoiceObjectModal.this')}
            checked={value === TypeRecurrenceUpdateChoice.THIS}
            onChange={(): void => setValue(TypeRecurrenceUpdateChoice.THIS)}
          />
          <RadioInput
            name={TypeRecurrenceUpdateChoice.NEXT}
            label={t('recurrenceChoiceObjectModal.next')}
            checked={value === TypeRecurrenceUpdateChoice.NEXT}
            onChange={(): void => setValue(TypeRecurrenceUpdateChoice.NEXT)}
          />
          <RadioInput
            name={TypeRecurrenceUpdateChoice.ALL}
            label={t('recurrenceChoiceObjectModal.all')}
            checked={value === TypeRecurrenceUpdateChoice.ALL}
            onChange={(): void => setValue(TypeRecurrenceUpdateChoice.ALL)}
          />
        </div>

        <div className="flex items-center justify-end gap-3 mt-3">
          {customButtons ? (
            customButtons(
              () => modalProps?.onClose(false),
              () => onSave(value),
            )
          ) : (
            <>
              <CancelButton
                onClick={(): void => {
                  modalProps?.onClose(false);
                }}
                data-testid="recurrenceUpdateChoiceCancelButton"
              />
              {value && (
                <SaveButton
                  onClick={(): void => {
                    onSave(value);
                  }}
                  data-testid="recurrenceUpdateChoiceSubmitButton"
                />
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export const spawnRecurrenceUpdateChoiceObjectModal = (
  onSave: (value: TypeRecurrenceUpdateChoice, closeModal: () => void) => void,
) => {
  spawnModal({
    render: ({ onClose }) => {
      return (
        <RecurrenceUpdateChoiceObjectModal
          icon={{ name: 'pen' }}
          title={i18next.t('recurrenceChoiceObjectModal.updateObjectTitle')}
          description={i18next.t(
            'recurrenceChoiceObjectModal.ObjectDescription',
            {
              action: i18next.t(
                'recurrenceChoiceObjectModal.ObjectDescriptionUpdateAction',
              ),
            },
          )}
          onSave={(type) => onSave(type, onClose)}
          open={true}
          onClose={onClose}
        />
      );
    },
  });
};
