import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import { generateDuotone } from '@smack/core/utils/ColorUtils';
import type React from 'react';

interface LeftIconContainerProps {
  icon?: IconField;
  color?: string;
}

export const LeftIconContainer: React.FC<LeftIconContainerProps> = ({
  color,
  icon,
}): JSX.Element => {
  const [iconColor, backgroundColor] = generateDuotone(color);

  return (
    <div
      style={{ backgroundColor: backgroundColor }}
      className="w-12 h-full flex flex-shrink-0 items-center mr-4 relative"
    >
      <div
        style={{ borderLeftColor: backgroundColor }}
        className={`
          absolute
          left-full
          w-0
          bg-transparent
          h-0
          border-l-[15px]
          border-t-transparent
          border-t-[40px]
          border-b-transparent
          border-b-[40px]`}
      />
      <Icon icon={icon} color={iconColor} className="h-7 w-7 text-xl ml-3" />
    </div>
  );
};

LeftIconContainer.defaultProps = {
  color: 'var(--secondary-color)',
};
