import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { AppState } from '@smack/core/store';

// import scss
import '@smack/core/views/oldViewsToSort/Layouts/RightPanel/MapConfigurator/Components/ConfiguratorPanel/index.scss';
import type { Layer } from '@smack/core/api/models/maps/Layer';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { env } from '@smack/core/env';
import { useMapsPreferences } from '@smack/core/hooks/preferences/useMapsPreferences/useMapsPreferences';
import {
  setActiveBasemap,
  setActiveLayers,
  setPointMode,
} from '@smack/core/store/maps/actions';
import type { MapMode } from '@smack/core/store/maps/types';
import { OptionCard } from '@smack/core/views/oldViewsToSort/Layouts/RightPanel/MapConfigurator/Components/OptionCard';
import { OptionLayer } from '@smack/core/views/oldViewsToSort/Layouts/RightPanel/MapConfigurator/Components/OptionLayer';
import { updateMapStyle } from '@smack/core/views/oldViewsToSort/Views/Map/Utils/mapinit';
import type { Map as MapLibreMap } from 'maplibre-gl';
import { useTranslation } from 'react-i18next';

/**
 *  interface of the configuration panel components
 */
interface IProps {
  onClose?: () => void; // handle close panel
  height?: number; // custom height
  width?: number; // custom width
  map?: MapLibreMap; // map to show the configuration
  initialbasemap?: number; // initial basemap
  hidePointMode?: boolean; // hide the point mode
  usecustomBasemapAndLayer?: boolean; // if true the config no save the configuration in store
}

export const ConfigurationPanel = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    onClose,
    height,
    width,
    initialbasemap,
    usecustomBasemapAndLayer,
    map,
    hidePointMode,
  } = props;
  const dispatch = useDispatch();
  const { basemaps, activeBasemap, pointMode, layers, activeLayers } =
    useSelector((app: AppState) => app.Maps);
  const [custombasemap, setCustombasemap] = React.useState(initialbasemap);
  const [customlayers, setCustomLayers] = React.useState<Layer[]>([]);
  const [preferences, setPreferences] = useMapsPreferences();
  /**
   * save basemap in user preferences
   *
   * @param {number} layerId
   */
  const savebasemapPreferences = (layerId: number): void => {
    setPreferences({
      ...preferences,
      basemap: layerId,
    });
  };

  /**
   * handle the change of basemap
   *
   * @param {number} basemapId
   * @return {*}  {void}
   */
  const handleClickBasemap = (basemapId: number): void => {
    if (usecustomBasemapAndLayer) {
      setCustombasemap(basemapId);
      updateMapStyle(basemaps, basemapId, map);
      return;
    }
    dispatch(setActiveBasemap(basemapId));
    savebasemapPreferences(basemapId);
  };

  /**
   * handle the change of point mode
   *
   * @param {string} pointModeValue
   */
  const handleClickPointMode = (pointModeValue: MapMode): void => {
    dispatch(setPointMode(pointModeValue));
  };

  /**
   * return list of point mode
   *
   * @return {*}  {{
   *     value: string;
   *     label: string;
   *     picture: string;
   *   }[]}
   */
  const GetPointMode = (): {
    value: MapMode;
    label: string;
    picture: string;
  }[] => {
    if (import.meta.env.DEV) {
      return [
        {
          value: 'cluster',
          label: t('mapConfigurator.cluster'),
          picture: '/images/Map/map_cluster.png',
        },
        {
          value: 'point',
          label: t('mapConfigurator.point'),
          picture: '/images/Map/map_normal.png',
        },
        {
          value: 'heatmap',
          label: t('mapConfigurator.density'),
          picture: '/images/Map/map_heat.png',
        },
        {
          value: 'legacyCluster',
          label: t('mapConfigurator.legacyCluster'),
          picture: '/images/Map/map_cluster.png',
        },
        {
          value: 'legacyPoint',
          label: t('mapConfigurator.legacyPoint'),
          picture: '/images/Map/map_normal.png',
        },
        {
          value: 'legacyHeatmap',
          label: t('mapConfigurator.legacyHeatmap'),
          picture: '/images/Map/map_heat.png',
        },
      ];
    }
    return [
      {
        value: env.VITE_ENABLE_SERVER_CLUSTERING ? 'cluster' : 'legacyCluster',
        label: 'Cluster',
        picture: '/images/Map/map_cluster.png',
      },
      {
        value: env.VITE_ENABLE_SERVER_CLUSTERING ? 'point' : 'legacyPoint',
        label: 'Point',
        picture: '/images/Map/map_normal.png',
      },
      {
        value: env.VITE_ENABLE_SERVER_CLUSTERING ? 'heatmap' : 'legacyHeatmap',
        label: 'Densité',
        picture: '/images/Map/map_heat.png',
      },
    ];
  };

  /**
   * handle thed change of layers
   *
   * @param {Layer[]} layer
   * @return {*}  {void}
   */
  const handleChangeLayers = (layer: Layer[]): void => {
    if (usecustomBasemapAndLayer) {
      setCustomLayers(layer);
      return;
    }
    dispatch(setActiveLayers(layer));
  };

  return (
    <div
      style={{
        width: width ?? '100%',
        height: height ?? '100%',
      }}
      className={'bg-primary  overflow-y-auto'}
    >
      <div className="flex items-center justify-between p-3 relative">
        <h2 className="text-lg text-text">{t('mapConfigurator.mapType')}</h2>
        <button
          type="button"
          onClick={onClose}
          className="bg-gray-300 dark:bg-gray-600 text-text h-5 w-5 rounded-full flex items-center justify-center"
        >
          <Icon icon={{ name: 'times' }} />
        </button>
      </div>
      <div className="px-3 grid gap-3 grid-cols-2">
        {basemaps.map((basemap) => (
          <OptionCard
            key={basemap.id}
            title={basemap.label}
            active={
              (usecustomBasemapAndLayer ? custombasemap : activeBasemap) ===
              basemap.id
            }
            onClick={(): void => handleClickBasemap(basemap.id)}
            picture={basemap.picture}
          />
        ))}
      </div>
      {!hidePointMode && (
        <div className="flex items-center  p-3">
          <h2 className="text-lg text-text">
            {t('mapConfigurator.displayMode')}
          </h2>
        </div>
      )}

      {!hidePointMode && (
        <div className="px-3 grid gap-3 grid-cols-3">
          {GetPointMode().map((point) => (
            <OptionCard
              key={point.value}
              title={point.label}
              active={pointMode === point.value}
              onClick={(): void => handleClickPointMode(point.value)}
              picture={point.picture}
            />
          ))}
        </div>
      )}
      <div className="flex items-center  p-3">
        <h2 className="text-lg text-text">
          {t('mapConfigurator.thematicData')}
        </h2>
      </div>
      <div className="px-3 grid gap-3 grid-cols-1">
        {layers.map((layer) => (
          <OptionLayer
            onChangeLayers={handleChangeLayers}
            activeLayers={
              usecustomBasemapAndLayer ? customlayers : activeLayers
            }
            key={layer.id}
            map={map}
            layer={layer}
          />
        ))}
      </div>
    </div>
  );
};
