import type {
  IMountedFilesystem,
  User,
} from '@smack/core/api/models/users/User';
import type { IPreferences } from '@smack/core/api/models/users/User/UserPreferences/UserPreferences';

export interface UserState {
  current: User | undefined;
  users: IUser[];
}

export interface IUser {
  id: number;
  uuid?: string;
  pk?: number;

  email: string;
  mobile: string;

  firstName: string;
  lastName: string;

  isCustomerAdmin: boolean;
  isCustomerSuperadmin: boolean;
  hasDeskAccess: boolean;
  isSuperuser: boolean;
  isAdmin?: boolean;
  hasImportApiRights: boolean;
  avatar: string;

  lastLogin: Date;
  createdAt: string;

  group?: { edges: { node: IGroups }[] };
  preferences?: IPreferences;

  mountedFilesystems?: IMountedFilesystem[];
}

export interface IGroups {
  id: string;
  pk: number;
  label: string;
}

// Actions Constants
export const FETCH_CURRENTUSER = 'USERS::FETCH_CURRENTUSER';
export const FETCH_USERS = 'USERS::FETCH_USERS';

// Actions Définition

export interface ISetCurrentUser {
  type: typeof FETCH_CURRENTUSER;
  payload?: User;
}

export interface ISetUsers {
  type: typeof FETCH_USERS;
  payload: IUser[];
}

export type UserActionsType = ISetCurrentUser | ISetUsers;
