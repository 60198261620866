import type { Group } from '@smack/core/api/models/tasks/Group';
import type { Project } from '@smack/core/api/models/tasks/Project/Project';
import { IconRoundedButton } from '@smack/core/components/Actions/Buttons/IconRoundedButton/IconRoundedButton';
import { IconFeed } from '@smack/core/components/DataDisplay/Feeds/IconFeed';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { IconLabel } from '@smack/core/components/DataDisplay/Labels/IconLabel';
import { ShowIfAllowed } from '@smack/core/components/DataDisplay/ShowIfAllowed';
import { toggleRefetchProjectStatuses } from '@smack/core/store/projects/actions';
import { GroupCreateEditModal } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/GroupCreateEditModal/GroupCreateEditModal';
import { MiniInlineProgressBar } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/MiniInlineProgressBar';
import { TaskCreateEditModal } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/TaskCreateEditModal/TaskCreateEditModal';
import { getHeaderLabels } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface IProps {
  project: Project;
  group: Group;
  open: boolean;
  onOpen: () => void;
  reloadProject: () => void;
}

/**
 * Task ViewName Title
 */
export const GroupTitle: React.FC<IProps> = ({
  project,
  group,
  open,
  onOpen,
  reloadProject,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = React.useState(false);

  const switchIsEditing = React.useCallback<React.MouseEventHandler>((e) => {
    e.stopPropagation();
    setIsEditing((isEditingState) => !isEditingState);
  }, []);

  const [isCreatingTask, setIsCreatingTask] = React.useState(false);

  const switchIsCreatingTask = React.useCallback<React.MouseEventHandler>(
    (e) => {
      e.stopPropagation();
      setIsCreatingTask((isCreatingTaskState) => !isCreatingTaskState);
    },
    [],
  );

  const [completedTasks, totalTasks] = React.useMemo<[number, number]>(() => {
    const tasks = group.tasks || [];
    if (!tasks.length) return [0, 0];
    const completedTasksCount = tasks.filter(
      (task) => task.status?.isCompleted,
    ).length;
    const totalTasksCount = tasks.filter(
      (task) => !task.status?.isHidden,
    ).length;
    return [completedTasksCount, totalTasksCount];
  }, [group.tasks]);

  const hasMounted = React.useRef(false);

  React.useEffect(() => {
    if (hasMounted.current) {
      dispatch(toggleRefetchProjectStatuses(true));
    } else {
      hasMounted.current = true;
    }
  }, [completedTasks, totalTasks, dispatch]);

  return (
    <>
      <GroupCreateEditModal
        isOpen={isEditing}
        setIsOpen={setIsEditing}
        group={group}
        project={project}
        reloadProject={reloadProject}
      />
      <TaskCreateEditModal
        isOpen={isCreatingTask}
        setIsOpen={setIsCreatingTask}
        group={group}
        reloadProject={reloadProject}
      />
      <div
        className={`flex bg-slate-100 dark:bg-slate-900 rounded-sm border-border bg-primary select-none${
          open ? ' border-b' : ''
        }`}
        onClick={onOpen}
      >
        <IconFeed
          roundColor={group.status?.color || 'black'}
          icon={group.status?.icon || { name: 'play' }}
          hideLine
          largeIcon
        >
          <div className="cursor-pointer flex-1 flex flex-col items-start md:items-center md:flex-row px-4 py-5 gap-4 text-sm text-gray-500 dark:text-gray-400">
            <div className="grow">
              <p className="text-xl text-text font-medium my-1">
                {group.label}
              </p>
              <div className="flex flex-wrap">
                {getHeaderLabels(undefined, group)?.map((label) => {
                  return (
                    <IconLabel
                      className="mr-3 !text-sm"
                      key={label.label}
                      icon={label.icon}
                      label={label.label}
                    />
                  );
                })}
              </div>
            </div>
            <div className="flex gap-2 items-center flex-row-reverse md:flex-row">
              <MiniInlineProgressBar
                progress={Math.floor((completedTasks / totalTasks) * 100)}
              >
                {t('tasks.completed_tasks_meter', {
                  count: completedTasks,
                  total: totalTasks,
                })}
              </MiniInlineProgressBar>
              <ShowIfAllowed>
                <IconRoundedButton
                  color="transparent"
                  onClick={switchIsEditing}
                  icon={{ name: 'pen' }}
                  title={t('edit.group')}
                  position="top"
                />
                <IconRoundedButton
                  color="transparent"
                  onClick={switchIsCreatingTask}
                  icon={{ name: 'plus' }}
                  title={t('create.task')}
                  position="top"
                />
              </ShowIfAllowed>
              <div className="h-7 w-7 rounded-full bg-gray-500 dark:bg-gray-400 flex items-center justify-center">
                <Icon
                  color="#fff"
                  icon={{ name: open ? 'chevron-down' : 'chevron-right' }}
                />
              </div>
            </div>
          </div>
        </IconFeed>
      </div>
    </>
  );
};
