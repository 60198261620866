import type { ReactNode } from 'react';
import {
  CLEAR_MODAL,
  type IClearModal,
  type ISetModal,
  SET_MODAL,
} from './types';

// Modal Actions

export const setModal = (modal: ReactNode): ISetModal => ({
  type: SET_MODAL,
  payload: modal,
});

export const clearModal = (): IClearModal => ({
  type: CLEAR_MODAL,
  payload: undefined,
});
