import {
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react';
import type { EventHoveringArg } from '@fullcalendar/core';
import type { EventImpl } from '@fullcalendar/core/internal';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import type { IExtraProps } from '@smack/core/views/oldViewsToSort/Views/Objects/Calendar/Utils';
import { format } from 'date-fns';
import React from 'react';

export interface IEventHoveringArgWithExtraProps extends EventHoveringArg {
  event: Omit<EventImpl, 'extendedProps'> & {
    get extendedProps(): IExtraProps;
  };
}

export type EventSetter = (ev: IEventHoveringArgWithExtraProps | null) => void;

interface ICalendarTooltipProps {
  setEventRef: React.MutableRefObject<EventSetter | undefined>;
  children?: never;
}

export const CalendarTooltip: React.FC<ICalendarTooltipProps> = ({
  setEventRef,
}) => {
  // Why we are using ref+state instead of a prop?
  // Because using the state (then passing it here as a prop) at the level of CalendarView
  // triggers a rerender of fullcalendar thus recreating the DOM Elements
  // and causing a loop of mouse enter/leave events

  const [hoveredEvent, setHoveredEvent] =
    React.useState<IEventHoveringArgWithExtraProps | null>(null);

  setEventRef.current = React.useCallback<EventSetter>((e) => {
    setHoveredEvent(e);
  }, []);

  const { refs, x, y, strategy } = useFloating({
    strategy: 'fixed',
    middleware: [
      flip({
        boundary:
          document.querySelector(
            '.fc-scrollgrid-section.fc-scrollgrid-section-body',
          ) || 'clippingAncestors',
      }),
      shift({
        boundary:
          document.querySelector(
            '.fc-scrollgrid-section.fc-scrollgrid-section-body',
          ) || 'clippingAncestors',
      }),
      offset(10),
    ],
    whileElementsMounted: autoUpdate,
  });

  React.useLayoutEffect(() => {
    refs.setReference(hoveredEvent?.el ?? null);
  }, [hoveredEvent]);

  return (
    <FloatingPortal>
      {hoveredEvent && (
        <div
          data-testid="calendar-tooltip"
          style={{
            position: strategy,
            top: y || 0,
            left: x || 0,
            width: 'max-content',
          }}
          ref={refs.setFloating}
          className="text-primary bg-text rounded-md py-1 px-2 z-[90]"
        >
          {hoveredEvent.event.start ? (
            <p>
              {format(hoveredEvent.event.start, 'dd/MM/yyyy HH:mm')}
              &nbsp;
              {hoveredEvent.event.end ? (
                <>
                  →&nbsp;
                  {format(hoveredEvent.event.end, 'dd/MM/yyyy HH:mm')}
                </>
              ) : null}
            </p>
          ) : null}
          <p className="font-bold">
            {hoveredEvent.event.extendedProps.object.title}
          </p>
          <p className="italic flex items-center">
            {hoveredEvent.event.extendedProps.object.category?.icon && (
              <Icon
                icon={hoveredEvent.event.extendedProps.object.category?.icon}
                containerClassName="inline-flex px-1"
              />
            )}
            {hoveredEvent.event.extendedProps.object.category?.label}
          </p>
        </div>
      )}
    </FloatingPortal>
  );
};
