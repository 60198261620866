/**
 * MIMETYPE MODEL
 */
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';

export interface IMimetype {
  id: number;
  contentType: string;
  extension: string;
  isImage: boolean;
  icon?: IconField;
  color?: string;
}

export class Mimetype implements IMimetype {
  id: number;

  contentType: string;

  extension: string;

  isImage: boolean;

  icon?: IconField;

  color?: string;

  constructor(data: IMimetype) {
    this.id = data.id;
    this.contentType = data.contentType;
    this.extension = data.extension;
    this.isImage = data.isImage;
    this.icon = data.icon;
    this.color = data.color;
  }
}
