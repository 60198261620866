import { MediasEventSocketManager } from '@smack/core/api/clients/websocket/medias/MediasEventSocketManager';
import { MediasManager } from '@smack/core/api/models/medias';
import type {
  FileApiInput,
  File as FileModel,
} from '@smack/core/api/models/medias/File';
import {
  type FolderApiInput,
  Folder as FolderModel,
} from '@smack/core/api/models/medias/Folder';
import { MediaCategory } from '@smack/core/api/models/medias/MediaCategory';
import {
  ExportAvailabilityStatus,
  ExportCreationStatus,
  MediaExport,
} from '@smack/core/api/models/medias/MediaExport/MediaExport';
import { IconButton } from '@smack/core/components/Actions/Buttons/Button';
import { Loader } from '@smack/core/components/Actions/Loader';
import { DangerAlert } from '@smack/core/components/DataDisplay/Alerts/Alerts';
import { DropDown } from '@smack/core/components/DataDisplay/DropDowns/DropDown';
import {
  type INode,
  throttledOpenFileNode,
  throttledOpenFolderNode,
} from '@smack/core/components/DataDisplay/Medias/IMediaNode';
import { NoContentMessage } from '@smack/core/components/DataDisplay/NoContentMessage';
import { CheckboxInput } from '@smack/core/components/DataInput/CheckboxInput';
import { SearchBar } from '@smack/core/components/DataInput/SearchBar';
import { useElementSize } from '@smack/core/hooks/useElementSize/useElementSize';
import { useSearchParams } from '@smack/core/hooks/useSearchParams';
import type { AppState } from '@smack/core/store';
import { setIsAutoOpeningFile } from '@smack/core/store/app/actions';
import { useActiveCategories } from '@smack/core/utils/ActiveCategories';
import {
  checkPathExists,
  isElectronAvailable,
  openFolderInExplorer,
} from '@smack/core/utils/ElectronUtils';
import { spawnModal } from '@smack/core/utils/modal';
import { BaseObjectPanelContext } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/BaseObjectPanel/Context';
import {
  type IPageHeaderPropsAction,
  PageHeader,
} from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/PageHeader';
import { TableHeader } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/TaskTable/Components/TableHeader';
import { TableHeaderAction } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/TaskTable/Components/TableHeaderAction';
import type { AxiosError } from 'axios';
import { cx } from 'class-variance-authority';
import React, { useContext, useEffect } from 'react';
import {
  type MoveHandler,
  type NodeApi,
  Tree,
  type TreeApi,
} from 'react-arborist';
import { type Renderable, toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteConfirmAlert } from '../../Alerts/ConfirmAlert';
import { ModalProgressBar } from '../../Modals/ModalProgressBar/ModalProgressBar';
import { MediasTreeContext } from '../MediasTreeContext';
import { MediaDetailModal } from './DetailModal';
import { SearchList } from './SearchList/SearchList';
import { TreeNode, TreeRow } from './TreeNode/TreeNode';
import { MediaUpdateModal, type UpdateFormOutput } from './UpdateModal';
import { MediaUploadModal } from './UploadModal';

interface IProps {
  parentObject: MediasManager;
  canEdit?: boolean;
  title?: string;
  legacyHeader?: true; // if true, use the header originally implemented for the BaseObjectPanel
  containerClassName?: string;
  className?: string;
  hideIfEmpty?: boolean;
  emptySignal?: (isEmpty: boolean) => void;
  onClose?: Parameters<typeof TableHeader>[0]['onClose'];
}

/**
 * Return MediasTree Components
 * @param props IProps
 * @returns JSX.Elements
 */
export const MediasTree: React.FC<IProps> = ({
  parentObject,
  containerClassName,
  className = 'min-h-[200px]',
  title,
  legacyHeader = false,
  hideIfEmpty = false,
  canEdit = false,
  emptySignal,
  onClose,
}) => {
  const { t } = useTranslation();
  const tree = React.useRef<TreeApi<INode>>(null);
  const [data, setData] = React.useState<INode>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [searchFilter, setSearchFilter] = React.useState<string>('');
  const [, , category] = useActiveCategories();
  const [{ file, folder }] = useSearchParams();
  const [initialFocus, setInitialFocus] = React.useState<string | undefined>(
    folder ? `folder-${folder}` : file ? `file-${file}` : undefined,
  );
  const [hasSelection, setHasSelection] = React.useState<boolean>(false);
  const shouldAutoOpenFile = useSelector<AppState>(
    (state) => state.App.isAutoOpeningFile,
  );
  const dispatch = useDispatch();

  const [mediaCategories, setMediaCategories] = React.useState<MediaCategory[]>(
    [],
  );
  const [filteredCategories, setFilteredCategories] = React.useState<
    MediaCategory[]
  >([]);

  const [createFromFolder, setCreateFromFolder] = React.useState<INode>();
  const [createModal, setCreateModal] = React.useState<boolean>(false);

  const [modalTarget, setModalTarget] = React.useState<INode>();
  const [detailModal, setDetailModal] = React.useState<boolean>(false);
  const [updateModal, setUpdateModal] = React.useState<boolean>(false);
  const [runningExport, setRunningExport] = React.useState<
    MediaExport | undefined
  >();
  const exportRefreshTimeoutRef = React.useRef<NodeJS.Timeout>();

  const baseObjectPanelContext = useContext(BaseObjectPanelContext);
  const [target, size] = useElementSize<HTMLDivElement>();

  const mediasEventWebsocket = React.useRef<MediasEventSocketManager>();
  let eventReloadTimeout: NodeJS.Timeout | undefined = undefined;
  const [eventReloadFlag, setEventReloadFlag] = React.useState<boolean>(true);
  const [carousselReloadFlag, setCarousselReloadFlag] =
    React.useState<boolean>(true);
  const [
    isExternalFilesystemInaccessible,
    setIsExternalFilesystemInaccessible,
  ] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!category) return;
    MediaCategory.getMediaCategories(category?.id).then(setMediaCategories);
  }, [category]);

  // helpers to convert File and Folder to a unified type Node
  const convertFileToNode = (file: FileModel): INode => {
    return {
      id: file.id,
      uuid: file.uuid,
      label: file.label ?? '',
      isFile: true,
      isFolder: false,
      href: file.filepath,
      mimetype: file.mimetype,
      category: file.category,
      isFavorite: file.isFavorite,
      isExportable: file.isExportable,
      isThumbnail: file.isThumbnail,
      externalFilesystemId: file.externalFilesystemId,
    };
  };
  const convertFolderToNode = (folder: FolderModel): INode => {
    const files: INode[] = folder.files
      ? folder.files
          .map(convertFileToNode)
          .sort((a, b) => (a.label > b.label ? 1 : -1))
      : [];
    // recursive folder convertion.
    const folders: INode[] = folder.folders
      ? folder.folders
          .map(convertFolderToNode)
          .sort((a, b) => (a.label > b.label ? 1 : -1))
      : [];
    const children: INode[] = folders.concat(files);
    if (!children.length) {
      children.push({
        id: folder.id,
        label: t('medias.emptyFolderItemLabel'),
        isFile: false,
        isFolder: false,
        children: [],
      });
    }
    // force folder open if searching, to show all results by default
    return {
      id: folder.id,
      uuid: folder.uuid,
      label: folder.label ?? '',
      isFile: false,
      isFolder: true,
      children: children,
      href: folder.path,
      externalFilesystemId: folder.externalFilesystemId,
    };
  };

  const triggerFocus = (targetId: string): Promise<void> | undefined => {
    tree.current?.focus(targetId, { scroll: false });
    return tree.current?.scrollTo(targetId, 'center');
  };

  React.useEffect(() => {
    if (!tree.current || !initialFocus) return;

    const targetId = initialFocus;
    const promise = triggerFocus(targetId);
    setInitialFocus(undefined);

    // node must be visible to be retrievable, therefor auto-open must
    // be done after focus
    promise?.then(() => {
      if (!shouldAutoOpenFile) return;
      const node = tree.current?.get(targetId);
      if (node?.data.isFile) {
        throttledOpenFileNode(node.data);
      } else if (node?.data.isFolder) {
        throttledOpenFolderNode(node.data);
      }
      dispatch(setIsAutoOpeningFile(false));
    });
  }, [data, initialFocus]);

  const update = (): void => {
    setLoading(true);
    parentObject
      .getMediasTreeRepresentation(
        searchFilter || undefined,
        false,
        filteredCategories,
      )
      .then((res) => {
        setData(convertFolderToNode(new FolderModel(res.data.results)));
        setLoading(false);
      })
      .catch(() => {
        toast.error('medias.getMediasError');
        setLoading(false);
      });
  };

  const scheduleUpdate = (): void => {
    setLoading(true);
    if (eventReloadTimeout) clearTimeout(eventReloadTimeout);
    eventReloadTimeout = setTimeout(() => {
      setEventReloadFlag(true);
    }, 250);
  };

  /**
   * Refetch tree on edit/move/delete/add or filter
   */
  React.useEffect(() => {
    if (!eventReloadFlag) return;
    setEventReloadFlag(false);
    update();
    if (carousselReloadFlag && baseObjectPanelContext) {
      setCarousselReloadFlag(false);
      baseObjectPanelContext.reloadCarousel();
    }
  }, [parentObject, filteredCategories.length, eventReloadFlag]);

  React.useEffect(() => {
    if (mediasEventWebsocket.current || !data?.id) return;
    mediasEventWebsocket.current = new MediasEventSocketManager(
      data.id.toString(),
      (): void => {
        setCarousselReloadFlag(true);
        scheduleUpdate();
      },
    );
  }, [data]);

  React.useEffect(() => {
    return () => {
      mediasEventWebsocket.current?.disconnect();
    };
  }, []);

  const onFolderCreate = (name: string | undefined): void => {
    if (!name) return;

    setLoading(true);
    parentObject
      .createFolder(name, createFromFolder?.id)
      .then(() => {
        setCreateModal(false);
        toast.success(t('medias.folderCreatedSuccessfully'));
        scheduleUpdate();
      })
      .catch(() => {
        setLoading(false);
        toast.error(t('medias.unableToCreateFolder'));
      });
  };

  const onUploadSubmit = (): void => {
    setCreateModal(false);
    scheduleUpdate();
  };

  const onMove: MoveHandler<INode> = (args): void => {
    const srcNode = args.dragNodes[0].data;
    const dstNode = args.parentNode?.data ?? data;
    if (!srcNode.id || !dstNode?.id) return;
    if (dstNode.children?.includes(srcNode)) return;

    if (srcNode.isFolder) {
      const updateData: FolderApiInput = {
        parentFolderId: dstNode.id,
      };
      setLoading(true);
      MediasManager.partialUpdateFolder(srcNode.id, updateData)
        .then(() => {
          scheduleUpdate();
        })
        .catch(() => {
          setLoading(false);
          toast.error(t('medias.unableToMoveFolder'));
        });
    } else if (srcNode.isFile) {
      const updateData: FileApiInput = { folderId: dstNode.id };
      setLoading(true);
      MediasManager.partialUpdateFile(srcNode.id, updateData)
        .then(() => {
          scheduleUpdate();
        })
        .catch(() => {
          setLoading(false);
          toast.error(t('medias.unableToMoveFile'));
        });
    }
  };

  const onUpdate = (data: UpdateFormOutput) => {
    if (!modalTarget || !modalTarget.id) return;

    const partialUpdateMethod = modalTarget.isFile
      ? MediasManager.partialUpdateFile
      : MediasManager.partialUpdateFolder;

    const submitData: FileApiInput | FolderApiInput = {
      ...data,
      categoryId: (data.category?.value as string | number) || null,
    };

    partialUpdateMethod(modalTarget.id, submitData)
      .then(() => {
        setUpdateModal(false);
      })
      .catch((err: AxiosError<{ errors: { detail: string }[] }>) => {
        let message: Renderable;
        if (err.response?.data) {
          message = err.response.data.errors?.[0].detail;
        } else {
          message = t('medias.patchError', {
            type: modalTarget.isFile
              ? t('medias.file', { count: 1 })
              : t('medias.folder', { count: 1 }),
          });
        }
        toast.error(message);
      });
  };

  const onSelect = (nodes: NodeApi<INode>[]): void => {
    setHasSelection(nodes.length > 0);
  };

  const onFavorite = (node: NodeApi<INode>): void => {
    if (!node.data.id) return;

    setLoading(true);
    const updateData: FileApiInput = { isFavorite: !node.data.isFavorite };
    MediasManager.partialUpdateFile(node.data.id, updateData)
      .then(() => {
        setCarousselReloadFlag(true);
        scheduleUpdate();
      })
      .catch(() => {
        setLoading(false);
        toast.error(t('medias.unableToPutFileInFavorite'));
      });
  };

  /**
   * When the the user clicks on "add" for a rooted node
   * @param id
   */
  const onChildCreateOpen = (node: NodeApi<INode>): void => {
    setCreateFromFolder(node.data);
    setCreateModal(true);
  };

  const onChildDetailOpen = (node: NodeApi<INode>): void => {
    setModalTarget(node.data);
    setDetailModal(true);
  };

  const onChildUpdateOpen = (node: NodeApi<INode>): void => {
    setModalTarget(node.data);
    setUpdateModal(true);
  };

  const selectionDeleteConfirmAlert = (): void => {
    spawnModal({
      render: ({ onClose: onAlertClose }) => {
        return (
          <DeleteConfirmAlert
            onCloseModal={onAlertClose}
            onCloseButton={onAlertClose}
            securityText={t('delete.delete')}
            onOk={(): void => {
              const uuids: string[] = [];
              for (const node of tree.current?.selectedNodes ?? []) {
                if (node.data.uuid) uuids.push(node.data.uuid);
              }
              MediasManager.deleteBatch(uuids)
                .then(() => {
                  toast.success(t('medias.batch.deleteSuccess'));
                  scheduleUpdate();
                })
                .catch(() => toast.error(t('medias.batch.deleteError')));
            }}
          />
        );
      },
    });
  };

  const handleExport = (_export: MediaExport) => {
    setRunningExport(_export);

    if (
      _export.creationStatus === ExportCreationStatus.FINISHED &&
      _export.availabilityStatus === ExportAvailabilityStatus.AVAILABLE
    ) {
      _export.stream();
      setTimeout(() => setRunningExport(undefined), 1000);
      return;
    }

    if (
      _export.creationStatus === ExportCreationStatus.ERRORED ||
      _export.availabilityStatus === ExportAvailabilityStatus.EXPIRED ||
      _export.availabilityStatus === ExportAvailabilityStatus.RETRIEVED
    ) {
      toast.error(t('medias.batch.exportError'));
      setTimeout(() => setRunningExport(undefined), 1000);
      return;
    }

    // IExportAvailabilityStatus = UNAVAILABLE
    // IExportCreationStatus = PENDING | PROCESSING
    exportRefreshTimeoutRef.current = setTimeout(() => {
      MediaExport.get(_export.id).then((response) => {
        handleExport(new MediaExport(response.data.results));
      });
    }, 1000);
  };

  const startExport = () => {
    const uuids: string[] = [];
    for (const node of tree.current?.selectedNodes ?? []) {
      if (node.data.uuid) uuids.push(node.data.uuid);
    }
    MediaExport.create(uuids)
      .then((response) => {
        handleExport(new MediaExport(response.data.results));
      })
      .catch((error: AxiosError<{ errors: { code: string }[] }>) => {
        if (error.response?.data?.errors?.[0]?.code === 'too-large') {
          toast.error(t('medias.batch.exportTooLargeError'));
        } else {
          toast.error(t('medias.batch.exportError'));
        }
      });
  };

  const externalFilesystemActions: IPageHeaderPropsAction[] = [
    {
      label: t('medias.actions.reindex'),
      onClick: (): void => {
        const rootFolderId = data?.id;
        if (!rootFolderId) return;
        setLoading(true);
        MediasManager.reindex(rootFolderId)
          .then(() => {
            toast.success(t('medias.reindex_success'));
            update();
          })
          .catch(() => {
            toast.error(t('medias.reindex_error'));
          })
          .finally(() => setLoading(false));
      },
      icon: { name: 'folder-magnifying-glass', familyStyle: 'fas' },
    },
    {
      label: t('medias.actions.openInExplorer') ?? '',
      onClick: (): void => {
        if (!data?.href) return;
        if (!isElectronAvailable()) {
          toast.error(t('medias.unableToOpenFolderFromBrowser'));
          return;
        }
        openFolderInExplorer(data.href);
      },
      icon: { name: 'folders', familyStyle: 'fas' },
    },
  ];

  const actions = React.useMemo(
    (node?: INode): IPageHeaderPropsAction[] => {
      if (isExternalFilesystemInaccessible) return [];

      const _actions: IPageHeaderPropsAction[] = [];
      if (data?.externalFilesystemId) {
        _actions.push(...externalFilesystemActions);
      }
      if (!data?.externalFilesystemId && hasSelection) {
        _actions.push(
          {
            label: t('medias.actions.delete'),
            onClick: selectionDeleteConfirmAlert,
            icon: { name: 'trash', familyStyle: 'fas' },
          },
          {
            label: t('medias.actions.download'),
            onClick: startExport,
            icon: { name: 'download', familyStyle: 'fas' },
          },
        );
      }
      if (canEdit) {
        _actions.push({
          label: t('medias.actions.addFileOrFolder'),
          onClick: (): void => {
            setCreateFromFolder(node);
            setCreateModal(true);
          },
          icon: { name: 'plus', familyStyle: 'fas' },
        });
      }
      return _actions;
    },
    [data, hasSelection],
  );

  React.useEffect(() => {
    if (!data?.href || !data.externalFilesystemId) return;
    const existsOrPromise = checkPathExists(data.href);
    if (existsOrPromise instanceof Promise) {
      existsOrPromise
        .then((exists) => setIsExternalFilesystemInaccessible(!exists))
        .catch((error: Error) => {
          if (
            'message' in error &&
            error.message.includes('No handler registered')
          ) {
            // Electron app not up to date, cannot check for accessibility
            return;
          }
          setIsExternalFilesystemInaccessible(true);
        });
    } else {
      setIsExternalFilesystemInaccessible(!existsOrPromise);
    }
  }, [data]);

  const childContext = React.useMemo(() => {
    return {
      onChildCreateOpen,
      onChildUpdateOpen,
      onChildDetailOpen,
      onFavorite,
      canEdit,
    };
  }, [data]);

  const isEmpty = !data?.children?.filter((c) => c.isFile || c.isFolder).length;

  useEffect(() => {
    if (emptySignal) emptySignal(isEmpty);
  }, [emptySignal, isEmpty]);

  if (hideIfEmpty && isEmpty) return null;

  return (
    <div className={cx('flex flex-col', containerClassName)}>
      <MediaUploadModal
        parentObject={parentObject}
        parentFolder={createFromFolder}
        mediaCategories={mediaCategories}
        open={createModal}
        setOpen={setCreateModal}
        onUploadSubmit={onUploadSubmit}
        onFolderCreate={onFolderCreate}
      />
      <MediaUpdateModal
        target={modalTarget}
        open={updateModal}
        setOpen={setUpdateModal}
        canEdit={canEdit}
        onSubmit={(data?: UpdateFormOutput): void => {
          if (data) onUpdate(data);
          scheduleUpdate();
        }}
      />
      <MediaDetailModal
        target={modalTarget}
        open={detailModal}
        setOpen={setDetailModal}
      />
      <ModalProgressBar
        icon={{ name: 'download' }}
        title={t('medias.batch.exportTitle')}
        text={
          runningExport?.creationStatus
            ? t(`medias.batch.exportStatus.${runningExport.creationStatus}`)
            : ''
        }
        progressBarProps={{
          progress:
            runningExport?.filesExported && runningExport?.filesTotal
              ? (100 * runningExport.filesExported) / runningExport.filesTotal
              : 0,
          animated: true,
        }}
        open={!!runningExport}
        onClose={() => {
          clearTimeout(exportRefreshTimeoutRef.current);
          setRunningExport(undefined);
        }}
      />

      {!legacyHeader ? (
        <TableHeader
          icon={{ name: 'paperclip' }}
          label={title ?? t('medias.tree.title')}
          onClose={onClose}
        >
          {actions.map((action) => (
            <TableHeaderAction
              key={action.label}
              icon={action.icon}
              onClick={action.onClick}
              title={action.label}
              disabled={action.disabled}
            />
          ))}
        </TableHeader>
      ) : (
        <PageHeader actions={actions} title={title ?? t('medias.tree.title')} />
      )}
      {isExternalFilesystemInaccessible ? (
        <DangerAlert>
          <div className="flex flex-col gap-2 items-center">
            <p>{t('medias.external_filesystem_inaccessible')}</p>
            <IconButton
              icon={{ name: 'arrow-rotate-left' }}
              onClick={(): void => {
                setData(undefined);
                setIsExternalFilesystemInaccessible(false);
                scheduleUpdate();
              }}
            >
              {t('medias.retry')}
            </IconButton>
          </div>
        </DangerAlert>
      ) : (
        <>
          {!!(!isEmpty || searchFilter || filteredCategories.length) && (
            <div className="flex flex-row">
              <SearchBar
                onClear={(): void => {
                  setSearchFilter('');
                }}
                onSearch={(value): void => {
                  setSearchFilter(value);
                }}
                value={searchFilter}
                className={{
                  container:
                    '!border-gray-200 rounded-none border-0 border-b-[1px]',
                }}
              />
              <DropDown
                menuItems={
                  mediaCategories.length
                    ? mediaCategories.map((category) => (
                        <CheckboxInput
                          key={category.id}
                          label={category.label}
                          name={category.id.toString()}
                          className={{ container: 'px-2 py-1' }}
                          value={filteredCategories.includes(category)}
                          onChange={(): void => {
                            if (filteredCategories.includes(category)) {
                              setFilteredCategories(
                                filteredCategories.filter(
                                  (c) => c.id !== category.id,
                                ),
                              );
                              scheduleUpdate();
                            } else {
                              setFilteredCategories([
                                ...filteredCategories,
                                category,
                              ]);
                              scheduleUpdate();
                            }
                          }}
                        />
                      ))
                    : [
                        <div
                          key={'categoryNoOptionMessage'}
                          className="py-1 px-2 text-sm text-gray-800 dark:text-gray-200"
                        >
                          {t('medias.categoryNoOptionMessage')}
                        </div>,
                      ]
                }
              >
                <IconButton
                  title={t('medias.filterButtonLabel')}
                  className="w-12 h-11 rounded-none border-t-0 border-r-0 flex items-center justify-center"
                  iconClassName="text-xl mr-[2px]"
                  icon={{ name: 'filters', familyStyle: 'fal' }}
                />
              </DropDown>
            </div>
          )}
          <Loader ref={target} isDataLoaded={!loading} className={className}>
            {searchFilter ? (
              <SearchList
                parentObject={parentObject}
                search={searchFilter}
                categories={filteredCategories}
                onClickFile={(fileId) => {
                  setInitialFocus(`file-${fileId}`);
                  setSearchFilter('');
                }}
                onClickFolder={(folderId) => {
                  setInitialFocus(`folder-${folderId}`);
                  setSearchFilter('');
                }}
              />
            ) : !isEmpty ? (
              <MediasTreeContext.Provider value={childContext}>
                <Tree
                  ref={tree}
                  data={data.children}
                  openByDefault={false}
                  selectionFollowsFocus
                  idAccessor={(node: INode) =>
                    node.isFile
                      ? `file-${node.id}`
                      : node.isFolder
                        ? `folder-${node.id}`
                        : `fake-child-${node.id}`
                  }
                  renderRow={TreeRow}
                  renderDragPreview={() => null}
                  onMove={onMove}
                  onSelect={onSelect}
                  width={size.width}
                  height={size.height}
                  rowHeight={42}
                  className="overflow-scroll"
                >
                  {TreeNode}
                </Tree>
              </MediasTreeContext.Provider>
            ) : !loading ? (
              <NoContentMessage
                icon={{ name: 'file' }}
                label={t('medias.noContent')}
                className="top-11 flex-grow"
              />
            ) : null}
          </Loader>
        </>
      )}
    </div>
  );
};
