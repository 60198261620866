export const resizeImage = async (
  blob: Blob,
  currentSize: {
    width: number;
    height: number;
  },
  resizeImageTargetSize: {
    width: number;
    height: number;
  },
): Promise<Blob | null> => {
  let resizeWidth: number;
  let resizeHeight: number;
  if (currentSize.width > currentSize.height) {
    resizeWidth = resizeImageTargetSize.width;
    resizeHeight = Math.ceil(
      (resizeImageTargetSize.width * currentSize.height) / currentSize.width,
    );
  } else {
    resizeWidth = Math.ceil(
      (resizeImageTargetSize.height * currentSize.width) / currentSize.height,
    );
    resizeHeight = resizeImageTargetSize.height;
  }

  const resizedBitmap = await createImageBitmap(blob, {
    resizeWidth,
    resizeHeight,
    resizeQuality: 'medium',
  });

  const canvas = document.createElement('canvas');
  canvas.getContext('bitmaprenderer')?.transferFromImageBitmap(resizedBitmap);
  return new Promise((resolve, reject) => {
    canvas.toBlob((resizedBlob) => {
      if (!resizedBlob) return reject();
      return resolve(resizedBlob);
    }, blob.type);
  });
};
