import type { IconName } from '@fortawesome/fontawesome-common-types';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { Project } from '@smack/core/api/models/tasks/Project/Project';
import { TemplateProject } from '@smack/core/api/models/tasks/TemplateProject/TemplateProject';
import { ElementListCard } from '@smack/core/components/DataDisplay/ElementListCard/ElementListCard';
import { BaseListElement } from '@smack/core/components/DataDisplay/Lists/ListElements/BaseListElement';
import { ListElementContent } from '@smack/core/components/DataDisplay/Lists/ListElements/Components/ListElementContent/ListElementContent';
import { NoContentMessage } from '@smack/core/components/DataDisplay/NoContentMessage';
import { ViewName } from '@smack/core/hooks/views/types';
import { useActiveView } from '@smack/core/hooks/views/useActiveView/useActiveView';
import {
  BaseObjectSkeleton,
  ListSkeleton,
  LoaderSkeleton,
} from '@smack/core/utils/Loader';

import type { AppState } from '@smack/core/store';
import { toggleRefetchProjectStatuses } from '@smack/core/store/projects/actions';
import { ProjectCompletionStatus } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Tasks/Tasks';
import moment from 'moment';
import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface ObjectProjectsCardProps {
  baseObject: BaseObject;
  tplProjectId: number;
}

export const ObjectProjectsCard: FC<ObjectProjectsCardProps> = ({
  baseObject,
  tplProjectId,
}) => {
  const [templateProject, setTemplateProject] = useState<TemplateProject>();
  const [projects, setProjects] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { refetchProjectsStatuses } = useSelector(
    (state: AppState) => state.Projects,
  );

  const [_, setActiveView] = useActiveView();
  const { t } = useTranslation();

  const loadData = () => {
    Promise.allSettled([
      TemplateProject.getTemplateProject(tplProjectId).then(setTemplateProject),
      Project.getFromBaseObject(baseObject.id, tplProjectId).then(setProjects),
    ]).finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setIsLoading(true);
    loadData();
  }, [tplProjectId]);

  useEffect(() => {
    if (refetchProjectsStatuses) {
      loadData();
      dispatch(toggleRefetchProjectStatuses(false));
    }
  }, [refetchProjectsStatuses]);

  if (isLoading)
    return (
      <LoaderSkeleton height={240} width="100%">
        <ListSkeleton iterations={3} component={BaseObjectSkeleton} />
      </LoaderSkeleton>
    );

  return (
    <ElementListCard
      title={templateProject?.label ?? ''}
      icon={{ name: 'diagram-subtask' }}
    >
      <div className="h-full overflow-y-scroll no-scrollbar">
        {projects.length === 0 ? (
          <NoContentMessage icon={{ name: 'tasks' }} label={t('tasks.none')} />
        ) : (
          projects.map((project, index) => (
            <BaseListElement key={project.id}>
              <ListElementContent
                title={project.label || ''}
                subtitle={
                  <ProjectCompletionStatus
                    activeTasks={project.activeTasks ?? 0}
                    completedTasks={project.completedTasks ?? 0}
                    projectId={project.id}
                  />
                }
                bottomRight={{
                  value: t('tasks.projectCreationDate', {
                    creationDate: project.createdAt?.toLocaleDateString(),
                  }),
                  tooltip: t('tasks.createdOnAndAt', {
                    date: moment(project.createdAt).local().format('dddd LL'),
                    time: moment(project.createdAt).local().format('LTS'),
                  }),
                  rightIcon: { name: 'circle-question', familyStyle: 'far' },
                }}
                color={project.status?.color || 'gray'}
                icon={project.status?.icon || { name: 'question' }}
                overlayIcons={[
                  {
                    icon: { name: 'arrow-up-right-from-square' as IconName },
                    label: t('baseObjectPanel.mainMenu.access'),
                    onClick: (e) =>
                      setActiveView({
                        view: ViewName.ObjectTask,
                        params: {
                          taskId: project.id,
                          baseObjectId: baseObject.id,
                        },
                      }),
                  },
                ]}
              />
            </BaseListElement>
          ))
        )}
      </div>
    </ElementListCard>
  );
};
