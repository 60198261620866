interface EnvType extends ImportMetaEnv {
  readonly VITE_PUBLIC_URL: string;
  readonly VITE_API_URL: string;
  readonly VITE_WS_URL: string;
  readonly VITE_TEST_API_URL: string;
  readonly VITE_MEDIA_URL: string;
  readonly VITE_SENTRY_DNS: string;
  readonly VITE_DESK_URL: string;
  readonly VITE_RELEASE_TAG: string;
  readonly VITE_API_PUBLIC_KEY: string;
  readonly VITE_NAVIGATION_URL: string;
  readonly VITE_MATOMO_URL: string;
  readonly VITE_MATOMO_SITEID: number;
  readonly VITE_FULL_CALENDAR_LICENSE_KEY: string;
  readonly VITE_SHOW_RECOMMENDATION: boolean;
  readonly VITE_FONT_CARTO_URL: string;
  readonly VITE_ENABLE_ELECTRON: boolean;
  readonly VITE_ENABLE_SERVER_CLUSTERING: boolean;
  readonly VITE_MINIMUM_SEARCH_CHARACTERS: number;
  readonly VITE_DISPLAY_WEIGHT_ON_SCHEDULE_SLOT: boolean;
  readonly VITE_DISPLAY_OVERLAY_ICONS_ON_LINKS: boolean;
}

const parseBoolean = (
  value: string | undefined,
): boolean | string | undefined => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return value;
};

// Import local variables injected by the production Docker container
await import('/env.js?url&external').catch((err) => {
  reportError(err);
});

const defaultEnv: Partial<EnvType> = {
  VITE_FONT_CARTO_URL: 'https://fonts.openmaptiles.org/{fontstack}/{range}.pbf',
  VITE_MINIMUM_SEARCH_CHARACTERS: 3,
};

const parsedEnv = Object.fromEntries(
  Object.entries({ ...defaultEnv, ...import.meta.env, ...globalThis.env }).map(
    ([key, value]) => [key, parseBoolean(value as string | undefined)],
  ),
);

export const env = parsedEnv as EnvType;
