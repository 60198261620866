import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { type Frequency, RRule, RRuleSet, rrulestr } from 'rrule';
import { getRecurenceTypeOptions } from '../../Utils';
import { DailyRRule } from '../DailyRRule';
import { EndRRule } from '../EndRRule';
import { MonthlyRRule } from '../MonthlyRRule';
import { RRuleSelect, staticQuickRRules } from '../RRuleSelect';
import { WeeklyRRule } from '../WeeklyRRule';
import { YearlyRRule } from '../YearlyRRule';

interface IProps {
  value?: string;
  dtStart?: Date;
  onChange?: (val?: string) => void;
}

function determineQuickRRule(value?: string): Option {
  if (!value) return staticQuickRRules[0];
  const parsedRRule = /RRULE:.+/m.exec(value);
  if (!parsedRRule) return staticQuickRRules[0];
  const foundOption = staticQuickRRules.find(
    (opt) => parsedRRule[0] === opt.value,
  );
  if (foundOption) {
    return foundOption;
  }
  return staticQuickRRules.slice(-1)[0];
}

/**
 * RRule generator input
 * @param props
 * @returns
 */
export const RRuleGenerator = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const { value, onChange, dtStart } = props;
  const [quickRRule, setQuickRRule] = React.useState(
    determineQuickRRule(value),
  );
  const [customRRule, setCustomRRule] = React.useState(
    quickRRule.value === 'CUSTOM',
  );

  const computedRRuleSetValue = React.useMemo<RRuleSet | undefined>(() => {
    if (!value) return undefined;
    try {
      return rrulestr(value, {
        forceset: true,
      }) as RRuleSet;
    } catch {
      return undefined;
    }
  }, [value]);

  const [recurenceType, setRecurenceType] = React.useState<
    string | number | undefined
  >(computedRRuleSetValue?.rrules().at(0)?.options?.freq ?? undefined);

  /**
   * handle input change
   *
   * @param {RRule} val
   */
  const handleChange = (val?: RRule): void => {
    if (onChange && val) {
      if (dtStart) {
        val.origOptions.dtstart = dtStart;
      }
      if (val?.options?.freq) setRecurenceType(val.options.freq);
      const set = new RRuleSet();
      set.rrule(val);
      computedRRuleSetValue?.exdates()?.forEach((exDate) => {
        set.exdate(exDate);
      });
      onChange(set.toString());
    } else if (onChange && typeof val === 'undefined') {
      onChange(undefined);
    }
  };

  /**
   * get the components from the repeat value
   *
   * @return {*}  {(JSX.Element | undefined)}
   */
  const switchRecurenceType = (): JSX.Element | undefined => {
    switch (recurenceType) {
      case RRule.YEARLY:
        return (
          <YearlyRRule
            value={computedRRuleSetValue?.rrules().at(0)}
            onChange={handleChange}
          />
        );
      case RRule.MONTHLY:
        return (
          <MonthlyRRule
            value={computedRRuleSetValue?.rrules().at(0)}
            onChange={handleChange}
          />
        );
      case RRule.WEEKLY:
        return (
          <WeeklyRRule
            value={computedRRuleSetValue?.rrules().at(0)}
            onChange={handleChange}
          />
        );
      case RRule.DAILY:
        return (
          <DailyRRule
            value={computedRRuleSetValue?.rrules().at(0)}
            onChange={handleChange}
          />
        );
    }
  };

  const onQuickRRuleChange = (newRRule?: Option | null): void => {
    const newValue = newRRule || staticQuickRRules[0];
    setQuickRRule(newValue);
    if (typeof newValue.value === 'string') {
      if (newValue.value === 'NORRULE') {
        handleChange(); // undefined
      } else if (newValue.value !== 'CUSTOM') {
        handleChange(rrulestr(newValue.value));
      }
    }
  };

  return (
    <>
      <RRuleSelect
        setRRuleGenerator={setCustomRRule}
        onChange={onQuickRRuleChange}
        value={quickRRule}
      />
      {customRRule ? (
        <>
          <div className="my-4">
            <SelectInput
              label={t('recurrence.repeat')}
              value={getRecurenceTypeOptions().find(
                (v) => v.value === recurenceType,
              )}
              onChange={(data): void =>
                setRecurenceType(data?.value as Frequency)
              }
              options={getRecurenceTypeOptions()}
            />
          </div>
          {switchRecurenceType()}
          <EndRRule
            value={computedRRuleSetValue?.rrules().at(0)}
            onChange={handleChange}
          />
        </>
      ) : null}
    </>
  );
};
