export const COLORS = [
  'blue',
  'orange',
  'yellow',
  'red',
  'purple',
  'amber',
  'lime',
  'green',
  'emerald',
  'teal',
  'cyan',
  'sky',
  'violet',
  'indigo',
  'purple',
  'fuchsia',
  'pink',
  'rose',
];

export const DEFAULT_THEME = 'blue';

export const THEME_DATA = {
  bg: {
    blue: 'bg-blue-500',
    orange: 'bg-orange-500',
    yellow: 'bg-yellow-500',
    red: 'bg-red-500',
    purple: 'bg-purple-500',
    amber: 'bg-amber-500',
    lime: 'bg-lime-500',
    green: 'bg-green-500',
    emerald: 'bg-emerald-500',
    teal: 'bg-teal-500',
    cyan: 'bg-cyan-500',
    sky: 'bg-sky-500',
    indigo: 'bg-indigo-500',
    violet: 'bg-violet-500',
    fuchsia: 'bg-fuchsia-500',
    pink: 'bg-pink-500',
    rose: 'bg-rose-500',
  },
  bgHover: {
    blue: 'hover:bg-blue-100',
    orange: 'hover:bg-orange-100',
    yellow: 'hover:bg-yellow-100',
    red: 'hover:bg-red-100',
    purple: 'hover:bg-purple-100',
    amber: 'hover:bg-amber-100',
    lime: 'hover:bg-lime-100',
    green: 'hover:bg-green-100',
    emerald: 'hover:bg-emerald-100',
    teal: 'hover:bg-teal-100',
    cyan: 'hover:bg-cyan-100',
    sky: 'hover:bg-sky-100',
    indigo: 'hover:bg-indigo-100',
    violet: 'hover:bg-violet-100',
    fuchsia: 'hover:bg-fuchsia-100',
    pink: 'hover:bg-pink-100',
    rose: 'hover:bg-rose-100',
  },
  ring: {
    blue: 'focus:ring-blue-500/20',
    orange: 'focus:ring-orange-500/20',
    yellow: 'focus:ring-yellow-500/20',
    red: 'focus:ring-red-500/20',
    purple: 'focus:ring-purple-500/20',
    amber: 'focus:ring-amber-500/20',
    lime: 'focus:ring-lime-500/20',
    green: 'focus:ring-green-500/20',
    emerald: 'focus:ring-emerald-500/20',
    teal: 'focus:ring-teal-500/20',
    cyan: 'focus:ring-cyan-500/20',
    sky: 'focus:ring-sky-500/20',
    indigo: 'focus:ring-indigo-500/20',
    violet: 'focus:ring-violet-500/20',
    fuchsia: 'focus:ring-fuchsia-500/20',
    pink: 'focus:ring-pink-500/20',
    rose: 'focus:ring-rose-500/20',
  },
  borderFocus: {
    blue: 'focus:border-blue-500',
    orange: 'focus:border-orange-500',
    yellow: 'focus:border-yellow-500',
    red: 'focus:border-red-500',
    purple: 'focus:border-purple-500',
    amber: 'focus:border-amber-500',
    lime: 'focus:border-lime-500',
    green: 'focus:border-green-500',
    emerald: 'focus:border-emerald-500',
    teal: 'focus:border-teal-500',
    cyan: 'focus:border-cyan-500',
    sky: 'focus:border-sky-500',
    indigo: 'focus:border-indigo-500',
    violet: 'focus:border-violet-500',
    fuchsia: 'focus:border-fuchsia-500',
    pink: 'focus:border-pink-500',
    rose: 'focus:border-rose-500',
  },
  text: {
    blue: 'text-blue-500',
    orange: 'text-orange-500',
    yellow: 'text-yellow-500',
    red: 'text-red-500',
    purple: 'text-purple-500',
    amber: 'text-amber-500',
    lime: 'text-lime-500',
    green: 'text-green-500',
    emerald: 'text-emerald-500',
    teal: 'text-teal-500',
    cyan: 'text-cyan-500',
    sky: 'text-sky-500',
    indigo: 'text-indigo-500',
    violet: 'text-violet-500',
    fuchsia: 'text-fuchsia-500',
    pink: 'text-pink-500',
    rose: 'text-rose-500',
  },
  textHover: {
    blue: 'hover:text-blue-500',
    orange: 'hover:text-orange-500',
    yellow: 'hover:text-yellow-500',
    red: 'hover:text-red-500',
    purple: 'hover:text-purple-500',
    amber: 'hover:text-amber-500',
    lime: 'hover:text-lime-500',
    green: 'hover:text-green-500',
    emerald: 'hover:text-emerald-500',
    teal: 'hover:text-teal-500',
    cyan: 'hover:text-cyan-500',
    sky: 'hover:text-sky-500',
    indigo: 'hover:text-indigo-500',
    violet: 'hover:text-violet-500',
    fuchsia: 'hover:text-fuchsia-500',
    pink: 'hover:text-pink-500',
    rose: 'hover:text-rose-500',
  },
};
