import React from 'react';

import { DeleteButton } from '@smack/core/components/Actions/Buttons/Button';
import type { Feature } from 'geojson';
import {
  FeaturePopup,
  type FeaturePopupProps,
} from '../../../../../../DataDisplay/MapPopup/FeaturePopup';
import { getMetricForGeometry } from '../../Utils';

interface PolygonInputPopupProps extends Omit<FeaturePopupProps, 'children'> {
  onDelete?: (feat: Feature) => void;
}

export const PolygonInputPopup: React.FC<PolygonInputPopupProps> = ({
  map,
  feat,
  onDelete,
}) => {
  const [metric, type] = React.useMemo(
    () => getMetricForGeometry(feat),
    [feat.geometry],
  );

  const onClick = React.useCallback(() => {
    if (onDelete) onDelete(feat);
  }, [onDelete, feat.id]);

  return metric > 0 ? (
    <FeaturePopup map={map} feat={feat}>
      <div className="p-2">
        <p className="text-center">
          {`${metric.toLocaleString(undefined, {
            unit: 'meter',
            style: 'unit',
          })}${
            // Include the square character when talking about surfaces
            // else it will show the length of a line
            type === 'surface' ? '²' : ''
          }`}
        </p>
        {onDelete ? <DeleteButton type="button" onClick={onClick} /> : null}
      </div>
    </FeaturePopup>
  ) : null;
};
