export enum ViewUsage {
  FORM = 'FORM',
  QUICK_FORM = 'QUICK_FORM',
  DETAILS = 'DETAILS',
  PREVIEW = 'PREVIEW',
  MAP_TOOLTIP = 'MAP_TOOLTIP',
  SHEET = 'SHEET',
  TABLE = 'TABLE',
  TASK_FORM = 'TASK_FORM',
  MOBILE = 'MOBILE',
  OTHER = 'OTHER',
  LIST_BLOCK = 'LIST_BLOCK',
  TIMELINE = 'TIMELINE',
  CALENDAR = 'CALENDAR',
  FULLSCREEN = 'FULLSCREEN',
}
