import { LoaderSkeleton } from '@smack/core/utils/Loader';

export const BaseObjectDetailsViewSkeleton = (
  <div className="h-full w-full">
    <LoaderSkeleton height={90} width="100%">
      <rect x="0" y="0" height="45" width="45" />
      {[0, 45, 90].map(
        (
          offset, // Icons
        ) => (
          <circle key={offset} cx={68 + offset} cy="21" r="12" />
        ),
      )}
      <rect x="0" y="44" height="45" width="100%" />
    </LoaderSkeleton>
  </div>
);
