import type React from 'react';
import './TimeSelector.scss';
import { format } from 'date-fns';

interface TimeSelectorProps {
  value?: Date;
  onChange?: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
}

export const TimeSelector: React.FC<TimeSelectorProps> = ({
  value,
  onChange,
  minDate,
  maxDate,
}) => {
  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = e.target.value;
    const [hours, minutes] = newTime.split(':');
    const date = value ? new Date(value) : new Date();
    date.setHours(Number.parseInt(hours));
    date.setMinutes(Number.parseInt(minutes));
    onChange?.(date);
  };

  return (
    <input
      data-testid={'timepicker-input'}
      type="time"
      max={maxDate ? format(maxDate, 'HH:mm') : undefined}
      min={minDate ? format(minDate, 'HH:mm') : undefined}
      value={value ? format(value, 'HH:mm') : undefined}
      onChange={handleTimeChange}
      className="time-picker-input relative p-1  text-sm text-text hover:bg-gray-100 cursor-pointer focus:outline-none border-0 focus:ring-0 focus:bg-gray-100 focus:border-blue-300 focus:!border-O focus:border-b-[3px]"
    />
  );
};
