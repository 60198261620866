import type { Action } from '@smack/core/api/models/actions/Action/Action';
import { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { CloseButton } from '@smack/core/components/Actions/Buttons/Button';
import { Loader } from '@smack/core/components/Actions/Loader';
import { DataDisplayHeader } from '@smack/core/components/DataDisplay/Headers/DataDisplayHeader';
import { SupersetDashboard } from '@smack/core/components/DataDisplay/SupersetDashboard/SupersetDashboard';
import { useActiveView } from '@smack/core/hooks/views/useActiveView/useActiveView';
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

export interface DisplayDashboardProps {
  dashboardDisplayAction: Action;
  baseobjectId: number;
}

export const DisplayDashboard: React.FC<DisplayDashboardProps> = ({
  dashboardDisplayAction,
  baseobjectId,
}) => {
  const { object } = useParams();
  const [searchParams] = useSearchParams();
  const [, , setLastView] = useActiveView();
  const [isLoading, setIsLoading] = React.useState(true);

  const [baseObject, setBaseObject] = React.useState<BaseObject>();

  const fetchBaseObject = (): void => {
    if (object) {
      BaseObject.getBaseObject(
        object,
        searchParams.get('schedule') || undefined,
      )
        .then(setBaseObject)
        .then(() => setIsLoading(false));
    }
  };

  React.useEffect(() => {
    fetchBaseObject();
  }, [object, searchParams]);

  const onQuit = (): void => {
    setLastView();
  };

  return (
    <div className="flex flex-col flex-grow bg-view h-full mx-auto relative z-10">
      <DataDisplayHeader
        color={baseObject?.color ?? ''}
        title={baseObject?.title}
        icon={baseObject?.category?.icon}
        actions={[<CloseButton key={'quit'} onClick={onQuit} />]}
      />
      <Loader isDataLoaded={!isLoading} className="flex flex-grow">
        <SupersetDashboard
          dashboardDisplayAction={dashboardDisplayAction}
          baseobjectId={baseobjectId}
        />
      </Loader>
    </div>
  );
};
