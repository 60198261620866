import { env } from '@smack/core/env';
import { isObject } from '@smack/core/utils';
import type { Sort } from '@smack/core/utils/config/SortOptions';
import { z } from 'zod';

export interface IFilterFunctionChoice {
  id: string;
  value: string;
  requiredValue?: boolean;
  largeInput?: boolean;
  multiple?: boolean;
}

export interface IAvailableFilter {
  isAvailableDtStart?: string;
  isAvailableDtEnd?: string;
  isAvailableLinkGroup?: number;
}

export const FilterFunctionChoice: IFilterFunctionChoice[] = [
  { id: 'EQUAL', value: 'eq', requiredValue: true },
  { id: 'DATE_EQUAL', value: 'date_eq', requiredValue: true },
  { id: 'NOT_EQUAL', value: 'neq', requiredValue: true },
  { id: 'IN', value: 'in', requiredValue: true, multiple: true },
  { id: 'NOT_IN', value: 'not_in', requiredValue: true, multiple: true },
  { id: 'GREATER_THAN', value: 'gt', requiredValue: true },
  { id: 'GREATER_THAN_OR_EQUAL', value: 'gte', requiredValue: true },
  { id: 'LESS_THAN', value: 'lt', requiredValue: true },
  { id: 'LESS_THAN_OR_EQUAL', value: 'lte', requiredValue: true },
  {
    id: 'BETWEEN',
    value: 'between',
    requiredValue: true,
    largeInput: true,
    multiple: true,
  },
  { id: 'STARTSWITH', value: 'startswith', requiredValue: true },
  { id: 'ENDSWITH', value: 'endswith', requiredValue: true },
  { id: 'CONTAINS', value: 'contains', requiredValue: true },
  { id: 'NOT_CONTAINS', value: 'not_contains', requiredValue: true },
  { id: 'IS_PAST', value: 'is_past' },
  { id: 'IS_FUTURE', value: 'is_future' },
  { id: 'TODAY', value: 'today' },
  { id: 'THIS_WEEK', value: 'this_week' },
  { id: 'THIS_MONTH', value: 'this_month' },
  { id: 'THIS_YEAR', value: 'this_year' },
  { id: 'LAST_YEAR', value: 'last_year' },
  { id: 'IS_TRUE', value: 'is_true' },
  { id: 'IS_FALSE', value: 'is_false' },
  { id: 'IS_NULL', value: 'is_null' },
  { id: 'IS_NOT_NULL', value: 'is_not_null' },
];

export const filterFunctionsMap = new Map(
  FilterFunctionChoice.map((filterFunctionChoice) => [
    filterFunctionChoice.id,
    filterFunctionChoice,
  ]),
);

export interface IAttributeFilter {
  values?: (number | string)[];
  valuesAllSelected?: string;
  filterFunction?: string;
  filterFunctionValue?: string;
}

export type IAttributeSort = 'asc' | 'desc' | undefined;

const transformMinSearchLength = (search?: string) => {
  if (search && search?.length >= env.VITE_MINIMUM_SEARCH_CHARACTERS)
    return search;
};

export const FiltersSerializer = z.object({
  currentCategoryId: z.number().optional(),
  search: z.string().transform(transformMinSearchLength).nullable().optional(),
  categories: z.array(z.string()).optional(),
  status: z.array(z.string()).optional(),
  reportState: z.boolean().optional(),
  viewedNotifications: z.boolean().optional(),
  period: z.string().optional(),
  priority: z.array(z.string()).optional(),
  draw: z.union([z.boolean(), z.any()]).optional(),
  containingFeatures: z.array(z.union([z.string(), z.number()])).optional(),
  screen: z.boolean().optional(),
  filteringLayer: z.string().optional(),
  showGeom: z.boolean().optional(),
  visualizedAttributes: z.record(z.any()).optional(),
  filteredAttributes: z
    .record(z.record(z.array(z.union([z.string(), z.number()]))))
    .optional(),
  sort: z.string().optional(),
  attributeSort: z.record(z.string().optional()).optional(),
  attributes: z
    .record(
      z.object({
        filterFunction: z.string().optional(),
        filterFunctionValue: z.string().optional(),
        valuesAllSelected: z.string().optional(),
        values: z.array(z.union([z.string(), z.number()])).optional(),
      }),
    )
    .optional(),
  daterange: z
    .object({
      start: z.string(),
      stop: z.string(),
    })
    .optional(),
});

export interface IFilters {
  currentCategoryId?: number;
  search?: string | null;
  categories?: string[];
  reportState?: boolean;
  viewedNotifications?: boolean;
  period?: 'TODAY' | 'THIS_WEEK' | 'THIS_MONTH' | 'THIS_WEEKEND';
  draw?: GeoJSON.MultiPolygon | boolean;
  /* Id of features to search objects inside */
  containingFeatures?: string[];
  screen?: boolean;
  /* Layer and source prefix (e.g. source name if '${filteringLayer}-source') */
  filteringLayer?: string;
  showGeom?: boolean;
  visualizedAttributes?: Record<number, string>;
  filteredAttributes?: Record<number, Record<number, string[]>>;
  sort?: Sort;
  attributeSort?: Record<number, IAttributeSort | undefined>;
  attributes?: Record<number, IAttributeFilter | undefined>;
  available?: IAvailableFilter;
  daterange?: {
    start: string;
    stop: string;
  };
}

const DEFAULT_PAGINATION_LIMIT = 20;

export enum FiltersQueryParams {
  PAGINATION = 'pagination',
  ORDER_BY = 'order-by',
  WITH_SCHEDULE = 'with-schedule',
  PARENT_CATEGORY = 'parent-category',
  WITHOUT_NOTIFICATION_STATE = 'without-notification-state',
  WITH_READ_NOTIFICATIONS = 'with-read-notifications',
  CATEGORY_IN = 'category-in',
  LINKS = 'links',
  SEARCH = 'q',
  PERIOD = 'period',
  DATE_RANGE = 'date-range',
  GEOMETRY = 'geometry',
  CONTAINING_FEATURE = 'containing-feature',
  ORDER_BY_ATTRIBUTE = 'order-by-attribute',
  ATTRIBUTE_FILTER = 'attribute-filter',
  FILTERED_ATTRIBUTES = 'filtered-attributes',
  HAVING_NO_GEOMETRY = 'having-no-geometry',
  VISUALIZED_ATTRIBUTE = 'visualized-attribute',
  AVAILABLE = 'available',
}

export type QueryParamsMode =
  | 'standard'
  | 'geometriesVector'
  | 'map'
  | 'calendar'
  | 'tabular';

export const queryParamsByMode: {
  [key in QueryParamsMode]: FiltersQueryParams[];
} = {
  standard: [
    FiltersQueryParams.PAGINATION,
    FiltersQueryParams.ORDER_BY,
    FiltersQueryParams.WITH_SCHEDULE,
    FiltersQueryParams.PARENT_CATEGORY,
    FiltersQueryParams.CATEGORY_IN,
    FiltersQueryParams.SEARCH,
    FiltersQueryParams.PERIOD,
    FiltersQueryParams.DATE_RANGE,
    FiltersQueryParams.GEOMETRY,
    FiltersQueryParams.CONTAINING_FEATURE,
    FiltersQueryParams.VISUALIZED_ATTRIBUTE,
    FiltersQueryParams.WITHOUT_NOTIFICATION_STATE,
    FiltersQueryParams.WITH_READ_NOTIFICATIONS,
    FiltersQueryParams.FILTERED_ATTRIBUTES,
    FiltersQueryParams.LINKS,
    FiltersQueryParams.AVAILABLE,
  ],
  geometriesVector: [
    FiltersQueryParams.ORDER_BY,
    FiltersQueryParams.WITH_SCHEDULE,
    FiltersQueryParams.PARENT_CATEGORY,
    FiltersQueryParams.CATEGORY_IN,
    FiltersQueryParams.SEARCH,
    FiltersQueryParams.PERIOD,
    FiltersQueryParams.DATE_RANGE,
    FiltersQueryParams.GEOMETRY,
    FiltersQueryParams.CONTAINING_FEATURE,
    FiltersQueryParams.VISUALIZED_ATTRIBUTE,
    FiltersQueryParams.FILTERED_ATTRIBUTES,
    FiltersQueryParams.LINKS,
  ],
  map: [
    FiltersQueryParams.HAVING_NO_GEOMETRY,
    FiltersQueryParams.PAGINATION,
    FiltersQueryParams.ORDER_BY,
    FiltersQueryParams.LINKS,
    FiltersQueryParams.WITH_SCHEDULE,
    FiltersQueryParams.PARENT_CATEGORY,
    FiltersQueryParams.CATEGORY_IN,
    FiltersQueryParams.SEARCH,
    FiltersQueryParams.PERIOD,
    FiltersQueryParams.DATE_RANGE,
    FiltersQueryParams.GEOMETRY,
    FiltersQueryParams.CONTAINING_FEATURE,
    FiltersQueryParams.VISUALIZED_ATTRIBUTE,
    FiltersQueryParams.FILTERED_ATTRIBUTES,
  ],
  calendar: [
    FiltersQueryParams.PAGINATION,
    FiltersQueryParams.ORDER_BY,
    FiltersQueryParams.WITH_SCHEDULE,
    FiltersQueryParams.PARENT_CATEGORY,
    FiltersQueryParams.CATEGORY_IN,
    FiltersQueryParams.SEARCH,
    FiltersQueryParams.PERIOD,
    FiltersQueryParams.DATE_RANGE,
    FiltersQueryParams.VISUALIZED_ATTRIBUTE,
    FiltersQueryParams.WITHOUT_NOTIFICATION_STATE,
    FiltersQueryParams.WITH_READ_NOTIFICATIONS,
    FiltersQueryParams.FILTERED_ATTRIBUTES,
  ],
  tabular: [
    FiltersQueryParams.PAGINATION,
    FiltersQueryParams.ORDER_BY_ATTRIBUTE,
    FiltersQueryParams.ATTRIBUTE_FILTER,
    FiltersQueryParams.WITH_SCHEDULE,
    FiltersQueryParams.PARENT_CATEGORY,
    FiltersQueryParams.SEARCH,
    FiltersQueryParams.DATE_RANGE,
    FiltersQueryParams.WITHOUT_NOTIFICATION_STATE,
    FiltersQueryParams.WITH_READ_NOTIFICATIONS,
  ],
};

type IFiltersParamsOptions = {
  additionalQueryParam?: Record<string, unknown>;
  parentCategory?: number;
  limit?: number;
  offset?: number;
  links?: {
    linkGroupId: number;
    sourceBaseObjectId?: number;
    includeLinkables?: boolean;
  };
};

type IFiltersFunctionParams = (
  filters: IFilters,
  options?: IFiltersParamsOptions,
) => Record<string, unknown> | undefined;

const getPagination: IFiltersFunctionParams = (_filters, options) => {
  const output: Record<string, unknown> = {
    limit: options?.limit ?? DEFAULT_PAGINATION_LIMIT,
  };
  if (options?.offset) {
    output.offset = options.offset;
  }
  return output;
};

const getOrder: IFiltersFunctionParams = (filters) => {
  if (filters.sort) {
    return { 'order-by': filters.sort };
  }
};

const getSearch: IFiltersFunctionParams = (filters) => {
  if (
    filters?.search &&
    filters?.search.length >= env.VITE_MINIMUM_SEARCH_CHARACTERS
  ) {
    return { [FiltersQueryParams.SEARCH]: filters.search };
  }
};

const getParentCategory: IFiltersFunctionParams = (_filters, options) => {
  if (options?.parentCategory) {
    return { [FiltersQueryParams.PARENT_CATEGORY]: options.parentCategory };
  }
};

const getCategoryIn: IFiltersFunctionParams = (filters) => {
  if (filters?.categories) {
    return { [FiltersQueryParams.CATEGORY_IN]: filters.categories };
  }
};

const getWithoutNotificationState: IFiltersFunctionParams = (filters) => {
  return {
    [FiltersQueryParams.WITHOUT_NOTIFICATION_STATE]: !filters.reportState,
  };
};

const getWithReadNotification: IFiltersFunctionParams = (filters) => {
  return {
    [FiltersQueryParams.WITH_READ_NOTIFICATIONS]: filters.viewedNotifications,
  };
};

const getLinks: IFiltersFunctionParams = (_filters, options) => {
  if (options?.links) {
    const output = {
      'link-group': options.links.linkGroupId,
    };
    if (options.links.includeLinkables) {
      output['include-linkables'] = true;
    }
    if (options.links.sourceBaseObjectId) {
      output['source-baseobject'] = options.links.sourceBaseObjectId;
    }
    return output;
  }
};

const getWithSchedule: IFiltersFunctionParams = (_filters) => {
  return {
    [FiltersQueryParams.WITH_SCHEDULE]: true,
  };
};

const getPeriod: IFiltersFunctionParams = (filters) => {
  if (filters?.period) {
    return { [FiltersQueryParams.PERIOD]: filters.period };
  }
};

const getDateRange: IFiltersFunctionParams = (filters) => {
  if (filters?.daterange) {
    return {
      'date-start': filters.daterange.start,
      'date-end': filters.daterange.stop,
    };
  }
};

const getGeometry: IFiltersFunctionParams = (filters) => {
  const currentMap = window?.Hyvilo?.Utils?.MainMap;

  const variables: Record<string, unknown> = {};

  if (isObject(filters.draw)) {
    variables.geometry = JSON.stringify(filters?.draw);
  } else if (filters?.screen && currentMap) {
    const coordinates = [
      [
        [
          currentMap?.getBounds().getNorthEast().lng,
          currentMap?.getBounds().getNorthEast().lat,
        ],
        [
          currentMap?.getBounds().getSouthEast().lng,
          currentMap?.getBounds().getSouthEast().lat,
        ],
        [
          currentMap?.getBounds().getSouthWest().lng,
          currentMap?.getBounds().getSouthWest().lat,
        ],
        [
          currentMap?.getBounds().getNorthWest().lng,
          currentMap?.getBounds().getNorthWest().lat,
        ],
        [
          currentMap?.getBounds().getNorthEast().lng,
          currentMap?.getBounds().getNorthEast().lat,
        ],
      ],
    ];
    variables.geometry = JSON.stringify({
      type: 'Polygon',
      coordinates,
    });
  }
  return variables;
};

const getContainingFeature: IFiltersFunctionParams = (filters) => {
  if (filters?.containingFeatures?.length && filters.filteringLayer) {
    return { 'containing-feature': filters.containingFeatures };
  }
};

const getFilterByAttribute: IFiltersFunctionParams = (filters) => {
  if (filters?.attributes) {
    const filtersList: string[] = [];
    Object.entries(filters.attributes).forEach(([attribute, choice]) => {
      const filterFunction = FilterFunctionChoice.find(
        (f) => f.value === choice?.filterFunction,
      );
      if (filterFunction?.requiredValue && choice?.filterFunctionValue) {
        filtersList.push(
          `${attribute}:${filterFunction.value}:${choice.filterFunctionValue}`,
        );
      } else if (filterFunction && !filterFunction?.requiredValue) {
        filtersList.push(`${attribute}:${filterFunction.value}`);
      }
      if (choice?.valuesAllSelected === '1') {
        if (choice?.values?.length) {
          filtersList.push(`${attribute}:not_in:${choice.values.join('|')}`);
        }
      } else if (choice?.valuesAllSelected === '0') {
        filtersList.push(
          `${attribute}:in:${(
            choice?.values?.length ? choice.values : ['0']
          ).join('|')}`,
        );
      }
    });
    if (filtersList.length) return { filters: filtersList };
  }
  return {};
};

const getOrderByAttribute: IFiltersFunctionParams = (filters) => {
  if (!filters.attributeSort) return;
  let sortString = '';
  Object.entries(filters.attributeSort).forEach(([attribute, sort]) => {
    if (sort) {
      sortString += `${sortString ? ',' : ''}${
        sort === 'asc' ? '' : '-'
      }${attribute}`;
    }
  });
  return { 'order-by': sortString };
};

const getHavingNoGeometry: IFiltersFunctionParams = (filters) => {
  if (filters.showGeom) {
    return { 'having-no-geometry': true };
  }
};

const getVisualizedAttribute: IFiltersFunctionParams = (filters) => {
  if (filters?.visualizedAttributes) {
    const variables: Record<string, unknown> = {};
    Object.entries(filters.visualizedAttributes).forEach(
      ([attribute, choice]) => {
        variables[`visualized-attribute[${attribute}]`] = choice;
      },
    );
    return variables;
  }
};

const getFilteredAttributes: IFiltersFunctionParams = (filters) => {
  if (filters?.filteredAttributes) {
    const variables: Record<string, unknown> = {};
    Object.entries(filters.filteredAttributes).forEach(([category, value]) => {
      Object.entries(value).forEach(([attribute, choices]) => {
        if (category && attribute && choices)
          variables.filters = [`${attribute}:in:${choices.join('|')}`];
      });
    });
    return variables;
  }
};

const getAvailable: IFiltersFunctionParams = (filters) => {
  const variables: Record<string, unknown> = {};
  const available = filters.available;
  if (!available?.isAvailableDtStart || !available.isAvailableDtEnd)
    return variables;
  variables['is-available-dtstart'] = available.isAvailableDtStart;
  variables['is-available-dtend'] = available.isAvailableDtEnd;
  if (available.isAvailableLinkGroup) {
    variables['is-available-link-group'] = available.isAvailableLinkGroup;
  }
  return variables;
};

export const getFunctionParams: { [key: string]: IFiltersFunctionParams } = {
  [FiltersQueryParams.PAGINATION]: getPagination,
  [FiltersQueryParams.ORDER_BY]: getOrder,
  [FiltersQueryParams.SEARCH]: getSearch,
  [FiltersQueryParams.PARENT_CATEGORY]: getParentCategory,
  [FiltersQueryParams.CATEGORY_IN]: getCategoryIn,
  [FiltersQueryParams.WITHOUT_NOTIFICATION_STATE]: getWithoutNotificationState,
  [FiltersQueryParams.WITH_READ_NOTIFICATIONS]: getWithReadNotification,
  [FiltersQueryParams.LINKS]: getLinks,
  [FiltersQueryParams.WITH_SCHEDULE]: getWithSchedule,
  [FiltersQueryParams.PERIOD]: getPeriod,
  [FiltersQueryParams.DATE_RANGE]: getDateRange,
  [FiltersQueryParams.GEOMETRY]: getGeometry,
  [FiltersQueryParams.CONTAINING_FEATURE]: getContainingFeature,
  [FiltersQueryParams.ATTRIBUTE_FILTER]: getFilterByAttribute,
  [FiltersQueryParams.ORDER_BY_ATTRIBUTE]: getOrderByAttribute,
  [FiltersQueryParams.HAVING_NO_GEOMETRY]: getHavingNoGeometry,
  [FiltersQueryParams.VISUALIZED_ATTRIBUTE]: getVisualizedAttribute,
  [FiltersQueryParams.FILTERED_ATTRIBUTES]: getFilteredAttributes,
  [FiltersQueryParams.AVAILABLE]: getAvailable,
};

export const getParamsFromFilters = (
  filters: IFilters,
  mode: FiltersQueryParams[] = queryParamsByMode.standard,
  options: IFiltersParamsOptions = {},
): Record<string, unknown> => {
  const params: Record<string, unknown> = {
    ...(options.additionalQueryParam ?? {}),
  };
  Object.keys(getFunctionParams).forEach((key) => {
    if (mode.includes(key as FiltersQueryParams)) {
      const result = getFunctionParams[key](filters, options);
      if (result) {
        Object.assign(params, result);
      }
    }
  });
  return params;
};

export function getDateTimeRangeFromFilterFunctionValue(
  filterFunctionValue: undefined | string | number,
) {
  const [startDateString, endDateString] =
    typeof filterFunctionValue === 'string'
      ? filterFunctionValue.split('|')
      : [];
  const startDate = startDateString ? new Date(startDateString) : undefined;
  const endDate = endDateString ? new Date(endDateString) : undefined;
  return { startDate, endDate };
}

export function getDateRangeFromFilterFunctionValue(
  filterFunctionValue: undefined | string | number,
) {
  const [startDateString, endDateString] =
    typeof filterFunctionValue === 'string'
      ? filterFunctionValue.split('|')
      : [];
  const startDate = startDateString;
  const endDate = endDateString;
  return { startDate, endDate };
}
