import type { EventObject } from '@smack/toast-ui__calendar';

export type PrintDisplay = 'auto' | 'week' | number;

export enum PrintFontSize {
  Littler = 0,
  Little = 1,
  Medium = 2,
  Big = 3,
  Bigger = 4,
}

export enum PrintOrientation {
  Portrait = 0,
  Landscape = 1,
}

export enum PrintStyle {
  Outline = 0,
  ColoredBackground = 1,
  GrayScale = 2,
}

export interface DetailedEventObject extends Partial<EventObject> {
  raw: {
    dateFnsInterval: {
      start: Date;
      end: Date;
    };
  };
}
