import type { Offset } from 'maplibre-gl';

export interface IMarkerObject {
  feature: GeoJSON.Feature;
  elements: {
    container: HTMLDivElement;
    line: HTMLDivElement;
    pin: HTMLDivElement;
  };
  mapboxMarker: maplibregl.Marker;
  spiderParam: ISpiderParams;
}

export interface ISpiderUserOptions {
  circleSpiralSwitchover: number;
  circleFootSeparation: number;
  spiralFootSeparation: number;
  spiralLengthStart: number;
  spiralLengthFactor: number;
  onClick?: (e: MouseEvent, markerobj: IMarkerObject) => void;
  initializeLeg?: (markerobj: IMarkerObject) => void;
  animate: boolean;
  animationSpeed: number;
  customPin: null;
}

export interface ISpiderParams {
  x: number;
  y: number;
  angle: number;
  legLength: number;
  index: number;
}

export const defaultSpiderUserOptions = {
  circleSpiralSwitchover: 9, // show spiral instead of circle from this marker count upwards
  // 0 -> always spiral; Infinity -> always circle
  circleFootSeparation: 40, // related to circumference of circle
  spiralFootSeparation: 34, // related to size of spiral (experiment!)
  spiralLengthStart: 20, // ditto
  spiralLengthFactor: 4, // ditto
  animate: true,
  animationSpeed: 0,
  customPin: null,
} as Readonly<ISpiderUserOptions>;

const twoTimesPi = Math.PI * 2;

function generateSpiralParams(
  count: number,
  options = defaultSpiderUserOptions,
): ISpiderParams[] {
  let legLength = options.spiralLengthStart;
  let angle = 0;
  const mapti: ISpiderParams[] = [];
  for (let index = 0; index < count; index++) {
    angle += options.spiralFootSeparation / legLength + index * 0.00015;
    const pt = {
      x: legLength * Math.cos(angle),
      y: legLength * Math.sin(angle),
      angle,
      legLength,
      index,
    };
    legLength += (twoTimesPi * options.spiralLengthFactor) / angle;
    mapti.push(pt);
  }
  return mapti;
}

function generateCircleParams(
  count: number,
  options = defaultSpiderUserOptions,
): ISpiderParams[] {
  const circumference = options.circleFootSeparation * (2 + count);
  const legLength = circumference / twoTimesPi;
  const angleStep = twoTimesPi / count;
  const mapti: ISpiderParams[] = [];
  for (let index = 0; index < count; index++) {
    const angle = index * angleStep;
    mapti.push({
      x: legLength * Math.cos(angle),
      y: legLength * Math.sin(angle),
      angle,
      legLength,
      index,
    });
  }
  return mapti;
}

export function generateSpiderParams(
  count: number,
  options = defaultSpiderUserOptions,
): ISpiderParams[] {
  if (count >= options.circleSpiralSwitchover) {
    return generateSpiralParams(count, options);
  }
  return generateCircleParams(count, options);
}

export function generateSpiderPointClassNames(params: ISpiderParams): string {
  let className = 'absolute';
  const x = Math.round(params.x);
  const y = Math.round(params.y);
  let xTransform = x <= 0 ? 'translate-x-1/2' : '-translate-x-1/2';
  let yTransform = y <= 0 ? 'translate-y-1/2' : '-translate-y-1/2';
  if (y === 0 && x < 0) {
    yTransform = '-translate-y-1/2';
  }
  if (x === 0 && y > 0) {
    xTransform = '-translate-x-1/2';
  }
  className += ` ${xTransform} ${yTransform}`;
  return className;
}

export function computeClusterRadiusAndOffsets(
  total: number,
  colors: Record<string, number>,
): { radius: number; innerRadius: number; offsets: number[] } {
  let radius: number;
  if (total >= 1000) {
    radius = 45;
  } else if (total >= 100) {
    radius = 32;
  } else if (total >= 10) {
    radius = 24;
  } else {
    radius = 18;
  }

  const innerRadius = Math.round(radius * 0.6);

  const offsets = Object.entries(colors).map((_, i, counts) => {
    if (i === 0) return 0;
    return counts.slice(0, i).reduce((a, b) => a + b[1], 0) / total;
  });

  return { radius, innerRadius, offsets };
}

export function computeOuterCircleSVGPath(
  {
    radius,
    innerRadius,
    offsets,
  }: ReturnType<typeof computeClusterRadiusAndOffsets>,
  i: number,
): string {
  const start = offsets[i];
  let end = offsets[i + 1] ?? 1;
  if (end - start === 1) end -= 0.00001;
  const a0 = 2 * Math.PI * (start - 0.25);
  const a1 = 2 * Math.PI * (end - 0.25);
  const x0 = Math.cos(a0);
  const y0 = Math.sin(a0);
  const x1 = Math.cos(a1);
  const y1 = Math.sin(a1);
  const largeArc = end - start > 0.5 ? 1 : 0;

  const path = [
    'M',
    radius + innerRadius * x0,
    radius + innerRadius * y0,
    'L',
    radius + radius * x0,
    radius + radius * y0,
    'A',
    radius,
    radius,
    0,
    largeArc,
    1,
    radius + radius * x1,
    radius + radius * y1,
    'L',
    radius + innerRadius * x1,
    radius + innerRadius * y1,
    'A',
    innerRadius,
    innerRadius,
    0,
    largeArc,
    0,
    radius + innerRadius * x0,
    radius + innerRadius * y0,
  ].join(' ');

  return path;
}

function offsetVariant(
  offset: number[],
  variantX: number,
  variantY: number,
): [number, number] {
  return [offset[0] + (variantX || 0), offset[1] + (variantY || 0)];
}

export function popupOffsetForSpiderLeg(
  spiderLeg: ISpiderParams,
  off = 0,
): Offset {
  const pinOffsetX = spiderLeg.x;
  const pinOffsetY = spiderLeg.y;

  const offset = off || 0;
  return {
    top: offsetVariant([0, offset], pinOffsetX, pinOffsetY),
    'top-left': offsetVariant([offset, offset], pinOffsetX, pinOffsetY),
    'top-right': offsetVariant([-offset, offset], pinOffsetX, pinOffsetY),
    bottom: offsetVariant([0, -offset], pinOffsetX, pinOffsetY),
    'bottom-left': offsetVariant([offset, -offset], pinOffsetX, pinOffsetY),
    'bottom-right': offsetVariant([-offset, -offset], pinOffsetX, pinOffsetY),
    left: offsetVariant([offset, -offset], pinOffsetX, pinOffsetY),
    right: offsetVariant([-offset, -offset], pinOffsetX, pinOffsetY),
    center: [0, 0],
  };
}
