import type { ValidationRules } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeFieldsByType/type';
import i18n from '@smack/core/utils/i18n/i18n';

const checkIsRequired: ValidationRules = (value, viewElement) => {
  if (viewElement.attribute?.isRequired) {
    if ([undefined, null, ''].includes(value)) {
      return i18n.t('formValidation.required');
    }
  }
};

export const defaultValidationRules: ValidationRules = (
  value: string,
  viewElement,
) => {
  const requiredCheck = checkIsRequired(value, viewElement);
  if (requiredCheck) return requiredCheck;

  return true;
};

export const phoneValidateRules: ValidationRules = (
  value: string,
  viewElement,
) => {
  const requiredCheck = checkIsRequired(value, viewElement);
  if (requiredCheck) return requiredCheck;

  const phonePattern =
    /^(\+?\d{1,3}[-.\s]?)?(\(?\d{1,4}\)?[-.\s]?)?(\d{1,4}[-.\s]?){1,3}\d{1,4}$/;

  if (value && !phonePattern.test(value)) {
    return i18n.t('formValidation.phoneInputIsInvalid');
  }
  return true;
};
