import React from 'react';

interface IRadioInputProps {
  label?: React.ReactNode;
  name?: string;
  checked?: boolean;
  value?: unknown;
  onChange?: (checked: boolean, value?: unknown) => void;
  className?: string;
  children?: JSX.Element;
}

/**
 * Return CheckBoxInputComponents
 * @param props ICheckboxInputProps
 * @returns JSX.Element
 */
const RadioInputRender: React.ForwardRefRenderFunction<
  HTMLInputElement,
  IRadioInputProps
> = ({ label, onChange, checked, value, name, className, children }, ref) => {
  return (
    <div className={`relative flex items-start ${className || ''}`}>
      <div className="flex items-center h-5">
        <input
          id={`name-${String(name)}-${String(value)}`}
          name={name}
          checked={!!checked}
          onChange={(e): void => {
            if (onChange) onChange(e.target.checked, value);
          }}
          type="radio"
          className="focus:ring-blue-500 h-4 w-4 text-blue-500 border-gray-300 rounded-full"
          ref={ref}
        />
      </div>
      <label
        htmlFor={`name-${String(name)}-${String(value)}`}
        className="pl-2 text-sm font-medium text-text"
      >
        {label}
      </label>
      {children}
    </div>
  );
};

RadioInputRender.displayName = 'RadioInput';

export const RadioInput = React.forwardRef(RadioInputRender);
