import type { Components } from 'react-markdown';

// HeadingProps does pass a level prop
// However that's not the case in future versions of react-markdown
// So to future-proof that, already use components for each level

export const FirstHeading: Components['h1'] = ({ ...props }) => (
  <h1 {...props} className="font-bold mb-1 text-3xl">
    {props.children}
  </h1>
);

export const SecondHeading: Components['h2'] = ({ ...props }) => (
  <h2 {...props} className="font-bold mb-1 text-2xl">
    {props.children}
  </h2>
);

export const ThirdHeading: Components['h3'] = ({ ...props }) => (
  <h3 {...props} className="font-bold mb-1 text-xl">
    {props.children}
  </h3>
);

export const FourthHeading: Components['h4'] = ({ ...props }) => (
  <h4 {...props} className="font-bold mb-1 text-lg">
    {props.children}
  </h4>
);

export const FifthHeading: Components['h5'] = ({ ...props }) => (
  <h5 {...props} className="font-bold mb-1">
    {props.children}
  </h5>
);

export const SixthHeading: Components['h6'] = ({ ...props }) => (
  <h6 {...props} className="mb-1">
    {props.children}
  </h6>
);
