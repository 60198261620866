import type { Reducer } from 'redux';
import {
  type ObjectsActionsType,
  type ObjectsState,
  SET_CALENDAR_LINK_GROUPS,
  TOGGLE_CALENDAR_LINK_GROUPS,
} from './types';

// Initial state
const initialState: ObjectsState = {};

// Modal reducer
export const objectsReducer: Reducer<ObjectsState, ObjectsActionsType> = (
  state: ObjectsState | undefined,
  action: ObjectsActionsType,
): ObjectsState => {
  if (!state) return initialState;

  switch (action.type) {
    case TOGGLE_CALENDAR_LINK_GROUPS: {
      const linkGroupIds =
        state.selectedCalendarLinkGroups?.[action.payload.baseobjectId] || [];
      const index = linkGroupIds.indexOf(action.payload.linkGroupId);
      if (index > -1) {
        linkGroupIds.splice(index, 1);
      } else {
        linkGroupIds.push(action.payload.linkGroupId);
      }
      return {
        ...state,
        selectedCalendarLinkGroups: {
          ...state.selectedCalendarLinkGroups,
          [action.payload.baseobjectId]: linkGroupIds,
        },
      };
    }
    case SET_CALENDAR_LINK_GROUPS:
      return {
        ...state,
        selectedCalendarLinkGroups: {
          ...state.selectedCalendarLinkGroups,
          [action.payload.baseobjectId]: action.payload.linkGroupIds,
        },
      };
    default:
      return state;
  }
};
