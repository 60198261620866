import { Project } from '@smack/core/api/models/tasks/Project/Project';
import type { ITemplateProject } from '@smack/core/api/models/tasks/TemplateProject/TemplateProject';
import { CreateButton } from '@smack/core/components/Actions/Buttons/Button';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import { useNonInitialEffect } from '@smack/core/utils/NonInitialEffect';
import React from 'react';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

interface IProps {
  baseobjectId: number;
  isOpen: boolean;
  setIsOpen: React.Dispatch<boolean>;
  templates: ITemplateProject[];
  reload: () => void;
}

interface ICreateProjectModalForm {
  template: Option;
}

export const CreateProjectModal: React.FC<IProps> = ({
  baseobjectId,
  isOpen,
  setIsOpen,
  templates,
  reload,
}) => {
  const { control, handleSubmit, reset } = useForm<ICreateProjectModalForm>();

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(false);

  const options = React.useMemo(
    () =>
      templates.map<Option>((template) => ({
        label: template.label || '',
        value: template.id,
      })),
    [templates],
  );

  useNonInitialEffect(() => {
    if (!isOpen) reset();
  }, [isOpen]);

  const onSubmit: SubmitHandler<ICreateProjectModalForm> = React.useCallback(
    (values) => {
      setIsLoading(true);
      Project.createProjectFromTemplateId(
        baseobjectId,
        Number(values.template.value),
      )
        .then(() => {
          reset();
          setIsOpen(false);
        })
        .catch(() => {
          toast.error(t('tasks.errors.cannotCreateProject'));
        })
        .finally(() => {
          setIsLoading(false);
          reload();
        });
    },
    [baseobjectId],
  );

  return (
    <Modal
      open={isOpen}
      onClose={setIsOpen}
      icon={{ name: 'plus' }}
      title={t('create.procedure')}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }): JSX.Element => (
            <SelectInput
              {...field}
              options={options}
              label={t('formInputLabels.template')}
              placeholder={t('formInputPlaceholders.template')}
              error={
                fieldState.error?.type === 'required'
                  ? t('formValidation.required')
                  : undefined
              }
            />
          )}
          name="template"
        />
        <div className="mt-5 flex items-center justify-end">
          <CreateButton
            isLoading={isLoading}
            disabled={isLoading}
            type="submit"
            data-testid="reminderFormCreateButton"
          />
        </div>
      </form>
    </Modal>
  );
};
