import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import type React from 'react';
import { forwardRef } from 'react';

interface IProps {
  isDataLoaded: boolean;
  loader?: JSX.Element;
  className?: string;
  children?: React.ReactNode;
}

const LoaderRender: React.ForwardRefRenderFunction<HTMLDivElement, IProps> = (
  props,
  ref,
): JSX.Element => {
  const {
    isDataLoaded,
    loader = (
      <Icon
        className="h-[90%] max-h-[100px] w-[90%] max-w-[100px]"
        icon={{ name: 'loader', familyStyle: 'fad' }}
        animation={'pulse'}
      />
    ),
    children,
    className,
  } = props;

  return (
    /* Relative div in order to place the loader in the center */
    <div
      ref={ref}
      aria-label={'loader'}
      className={`relative h-full ${className || ''}`}
    >
      {!isDataLoaded && (
        /* High Z index to capture inputs */
        <div className="top-0 bottom-0 left-0 right-0 absolute z-40 flex justify-center items-center bg-black bg-opacity-10">
          {loader}
        </div>
      )}
      {children}
    </div>
  );
};

export const Loader = forwardRef(LoaderRender);
