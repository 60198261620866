import {
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IDateRangeInputProps {
  value?: Date;
  onChange?: (data: Date) => void;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  children?: React.ReactNode;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
}

export const DaySelector: React.FC<IDateRangeInputProps> = ({
  value,
  onChange,
  open,
  setOpen,
  children,
  minDate,
  maxDate,
}) => {
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date()); // Current displayed month
  const [t] = useTranslation();
  const nodeId = useFloatingNodeId();

  const { x, y, refs, strategy, context } = useFloating({
    strategy: 'absolute',
    open: open,
    onOpenChange: setOpen,
    nodeId,
    placement: 'right-start',
    middleware: [flip(), shift(), offset({ mainAxis: 10, alignmentAxis: 0 })],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, {
      event: 'mousedown',
      toggle: true,
    }),
    useRole(context, { role: 'menu' }),
    useDismiss(context),
  ]);
  // Function to get the first day of a given month
  const getFirstDayOfMonth = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  };

  React.useEffect(() => {
    if (value) {
      setCurrentMonth(value);
    }
  }, [value]);

  // Function to get the last day of a given month
  const getLastDayOfMonth = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  };

  const getFirstDayOfWeek = (): number => {
    return Number(t('dateTime.firstDayOfWeek'));
  };
  // Function to generate an array of date cells for the current month
  const generateMonthCells = (monthDate: Date): (Date | null)[] => {
    const firstDay = getFirstDayOfMonth(monthDate);
    const lastDay = getLastDayOfMonth(monthDate);
    const daysInMonth = lastDay.getDate();
    const monthCells: (Date | null)[] = [];

    // Retrieve the firstDayOfWeek value based on the current locale
    const firstDayOfWeek = getFirstDayOfWeek() || 0;
    // Calculate the number of empty cells needed before the first of the month

    const emptyCellsBefore = (firstDay.getDay() - firstDayOfWeek + 7) % 7;

    // Fill in leading empty cells
    for (let i = 0; i < emptyCellsBefore; i++) {
      monthCells.push(null);
    }

    // Fill in date cells for the month
    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(monthDate.getFullYear(), monthDate.getMonth(), i);
      monthCells.push(date);
    }

    return monthCells;
  };

  const monthCells = generateMonthCells(currentMonth);

  const isDateDisabled = (date: Date): boolean => {
    if (date.getDate() === minDate?.getDate()) return false;
    if (date.getDate() === maxDate?.getDate()) return false;
    return !!((minDate && date < minDate) || (maxDate && date > maxDate));
  };

  const handleDateClick = (date: Date | null): void => {
    if (onChange && date && !isDateDisabled(date)) {
      if (value) {
        date.setHours(value.getHours());
        date.setMinutes(value.getMinutes());
      }
      onChange(date);
    }
  };

  const goToPreviousMonth = (): void => {
    const previousMonth = new Date(currentMonth);
    previousMonth.setMonth(previousMonth.getMonth() - 1);
    setCurrentMonth(previousMonth);
  };

  const goToNextMonth = (): void => {
    const nextMonth = new Date(currentMonth);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    setCurrentMonth(nextMonth);
  };

  const getCurrentMonth = (): string => {
    return t(`dateTime.months.${currentMonth.getMonth()}`);
  };

  const getDays = (): string[] => {
    return t('dateTime.halfDaysOfWeek', { returnObjects: true });
  };

  return (
    <FloatingTree>
      <FloatingNode id={nodeId}>
        <div
          key={nodeId}
          ref={refs.setReference}
          {...getReferenceProps({
            onClick(e) {
              e.stopPropagation();
            },
          })}
          className={' inline'}
        >
          {children}
        </div>
        <FloatingPortal>
          {open && (
            <div
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                width: 'max-content',
              }}
              {...getFloatingProps()}
              className="z-[100] w-64 border border-gray-100 rounded p-2 shadow bg-primary"
            >
              {/* Navigation Buttons */}
              <div className="flex justify-between mb-4">
                <button
                  type={'button'}
                  onClick={goToPreviousMonth}
                  className="bg-gray-200 w-5 h-5 rounded-full flex items-center justify-center"
                >
                  <Icon icon={{ name: 'chevron-left' }} className="text-xs" />
                </button>
                <h2>
                  {getCurrentMonth()} {currentMonth.getFullYear()}
                </h2>
                <button
                  type={'button'}
                  onClick={goToNextMonth}
                  className="bg-gray-200 w-5 h-5 rounded-full flex items-center justify-center"
                >
                  <Icon icon={{ name: 'chevron-right' }} className="text-xs" />
                </button>
              </div>

              {/* Calendar Grid */}
              <div className="grid grid-cols-7 gap-2">
                {/* Render the days of the week */}
                {getDays().map((day) => (
                  <div key={day} className="text-center font-semibold">
                    {day}
                  </div>
                ))}

                {/* Render date cells */}
                {monthCells.map((date, index) => (
                  <div
                    key={date?.toDateString() || index}
                    className={`flex h-6 w-6 items-center justify-center text-center cursor-pointer rounded-full ${
                      date ? 'hover:bg-blue-200' : 'bg-gray-100'
                    } ${
                      date && date.toDateString() === value?.toDateString()
                        ? 'bg-blue-500 text-white'
                        : ''
                    } ${
                      date && isDateDisabled(date)
                        ? 'opacity-40 cursor-not-allowed'
                        : ''
                    }`}
                    onClick={(): void => handleDateClick(date)}
                  >
                    {date ? date.getDate() : ''}
                  </div>
                ))}
              </div>
            </div>
          )}
        </FloatingPortal>
      </FloatingNode>
    </FloatingTree>
  );
};
