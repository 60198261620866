import type { Reducer } from 'redux';
import {
  type CategoriesActionType,
  FETCH_NOTIFICATION_COUNTERS,
  FETCH_ROOT_CATEGORIES,
  FETCH_SET_CATEGORIES,
  FETCH_SUB_SET_CATEGORIES,
  type ICategoriesState,
  RESET_SET_CATEGORIES,
  RESET_SUB_SET_CATEGORIES,
} from './types';

const initialState: ICategoriesState = {
  rootCategories: [],
  rootCategoriesFetched: false,
  setCategories: [],
  setCategoriesFetched: false,
  subSetCategories: [],
  subSetCategoriesFetched: false,
  notificationCounters: [],
};

export const categoriesReducer: Reducer<
  ICategoriesState,
  CategoriesActionType
> = (
  state: ICategoriesState | undefined,
  action: CategoriesActionType,
): ICategoriesState => {
  if (!state) return initialState;
  switch (action.type) {
    case FETCH_ROOT_CATEGORIES:
      return {
        ...state,
        rootCategories: action.payload,
        rootCategoriesFetched: true,
      };
    case FETCH_SET_CATEGORIES:
      return {
        ...state,
        setCategories: action.payload,
        setCategoriesFetched: true,
      };
    case RESET_SET_CATEGORIES:
      return {
        ...state,
        setCategories: [],
        setCategoriesFetched: false,
      };
    case FETCH_SUB_SET_CATEGORIES:
      return {
        ...state,
        subSetCategories: action.payload,
        subSetCategoriesFetched: true,
      };
    case RESET_SUB_SET_CATEGORIES:
      return {
        ...state,
        subSetCategories: [],
        subSetCategoriesFetched: false,
      };
    case FETCH_NOTIFICATION_COUNTERS:
      return {
        ...state,
        notificationCounters: action.payload,
      };
    default:
      return state;
  }
};
