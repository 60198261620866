import type { ITemplateSkeletonComponent } from '.';

export const FormNavigationSkeleton: ITemplateSkeletonComponent = ({
  offset = 0,
}) => (
  <>
    <rect x="0" y={offset} height="25" width="25" />
    <rect x="50" y={offset} height="25" width="150" />
  </>
);

FormNavigationSkeleton.itemOffset = 40;
