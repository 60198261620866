import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import {
  Category,
  type ICategory,
} from '@smack/core/api/models/categories/Category';

export interface ITemplateProject {
  id: number;
  label?: string;
  category?: ICategory;
  autocreateFromCategory?: boolean;
}

export class TemplateProject {
  id: number;

  label?: string;

  category?: Category;

  autocreateFromCategory?: boolean;

  constructor(data: ITemplateProject) {
    this.id = data.id;
    this.label = data.label;
    this.category = data.category ? new Category(data.category) : undefined;
    this.autocreateFromCategory = data.autocreateFromCategory;
  }

  static getTemplateProject(
    templateProjectId: number,
  ): Promise<TemplateProject> {
    return RESTClient.get<{ data: { results: ITemplateProject } }>(
      `/tasks/tpl-projects/${templateProjectId}`,
    ).then((res) => {
      return new TemplateProject(res.data?.results);
    });
  }
}
