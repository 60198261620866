import type React from 'react';

interface IProps extends React.HTMLProps<HTMLDivElement> {
  progress: number;
}

export const MiniInlineProgressBar: React.FC<IProps> = ({
  children,
  progress,
  ...htmlProps
}) => {
  return (
    <div {...htmlProps} className={`text-xs ${htmlProps?.className || ''}`}>
      {children}
      <div className="bg-slate-300 h-1 rounded-sm">
        <div
          className="h-1 bg-lime-600 rounded-sm"
          data-testid="mini-inline-progress-bar-completed"
          style={{
            width: `${progress}%`,
          }}
        />
      </div>
    </div>
  );
};
