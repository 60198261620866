import { File as MediaFile } from '@smack/core/api/models/medias/File';
import type { Folder } from '@smack/core/api/models/medias/Folder';
import { PropertyListElement } from '@smack/core/components/DataDisplay/PropertyListElement/PropertyListElement';
import { formatLongString } from '@smack/core/utils';
import { t } from 'i18next';
import type React from 'react';

interface IProps {
  media: MediaFile | Folder;
  className?: string;
}

export const MediaProperties: React.FC<IProps> = ({ media, className }) => {
  const humanFileSize = (size: number): string => {
    const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return `${(size / 1024 ** i).toFixed(2)} ${
      ['B', 'kB', 'MB', 'GB', 'TB'][i]
    }`;
  };

  return (
    <div className={className ?? ''}>
      <p className="block text-sm font-medium text-gray-700 dark:text-gray-200 pb-1 pt-5">
        {t('medias.properties')}
      </p>
      <div className="flex flex-col gap-1 text-xs">
        {media?.label && (
          <PropertyListElement title={t('model.label')} value={media.label} />
        )}
        {media instanceof MediaFile && (
          <>
            <PropertyListElement
              title={t('medias.mimetype')}
              value={formatLongString(media.mimetype?.contentType, 20)}
            />
            {Boolean(media.size) && (
              <PropertyListElement
                title={t('medias.size')}
                value={humanFileSize(media.size ?? 0)}
              />
            )}
            {media?.createdBy && (
              <PropertyListElement
                title={t('medias.createdBy')}
                value={media?.createdBy.getFullName()}
              />
            )}
          </>
        )}
        {media?.createdAt && (
          <PropertyListElement
            title={t('model.createdAt')}
            value={media.createdAt.toLocaleString()}
          />
        )}
        {media?.modifiedAt && (
          <PropertyListElement
            title={t('model.modifiedAt')}
            value={media.modifiedAt.toLocaleString()}
          />
        )}
      </div>
    </div>
  );
};
