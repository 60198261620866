import {
  CancelButton,
  PrintButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { Loader } from '@smack/core/components/Actions/Loader';
import {
  type IModalProps,
  Modal,
} from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import type {
  PrintCalendarForwardRef,
  PrintCalendarProps,
} from '@smack/core/components/DataDisplay/PrintCalendar/PrintCalendar';
import {
  type PrintDisplay,
  PrintFontSize,
  PrintOrientation,
  PrintStyle,
} from '@smack/core/components/DataDisplay/PrintCalendar/types';
import { CheckboxInput } from '@smack/core/components/DataInput/CheckboxInput';
import {
  DateTimeRangeInput,
  type DateTimeRangeInputValue,
} from '@smack/core/components/DataInput/DateRangeInput/DateTimeRangeInput';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import { useActiveFilters } from '@smack/core/hooks/useActiveFilters';
import {
  addDays,
  endOfDay,
  endOfWeek,
  startOfDay,
  startOfWeek,
} from 'date-fns';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// 1.2MB saved on main bundle! phew.
const PrintCalendar = React.lazy(
  () =>
    import('@smack/core/components/DataDisplay/PrintCalendar/PrintCalendar'),
);

type IProps = Omit<IModalProps, 'children'>;

interface IFormOutput {
  range: DateTimeRangeInputValue;
  display: Option<PrintDisplay>;
  fontsize: Option<PrintFontSize>;
  layout: Option<PrintOrientation>;
  color: Option<PrintStyle>;
  showWeekends: boolean;
}

export const ModalPrintCalendar: React.FC<IProps> = (modalProps) => {
  const [t] = useTranslation();

  const layoutOptions: Option<PrintOrientation>[] = [
    {
      label: t('printModal.layoutOptions.landscape'),
      value: PrintOrientation.Landscape,
    },
    {
      label: t('printModal.layoutOptions.portrait'),
      value: PrintOrientation.Portrait,
    },
  ];

  const displayOptions: Option<PrintDisplay>[] = [
    { label: t('printModal.displayOptions.auto'), value: 'auto' },
    { label: t('printModal.displayOptions.week'), value: 'week' },
    { label: t('printModal.displayOptions.day'), value: 1 },
  ];
  const fontSizeOptions: Option<PrintFontSize>[] = [
    {
      label: t('printModal.fontSizeOptions.littler'),
      value: PrintFontSize.Littler,
    },
    {
      label: t('printModal.fontSizeOptions.little'),
      value: PrintFontSize.Little,
    },
    {
      label: t('printModal.fontSizeOptions.normal'),
      value: PrintFontSize.Medium,
    },
    { label: t('printModal.fontSizeOptions.big'), value: PrintFontSize.Big },
    {
      label: t('printModal.fontSizeOptions.bigger'),
      value: PrintFontSize.Bigger,
    },
  ];

  const colorOptions: Option<PrintStyle>[] = [
    { label: t('printModal.colorOptions.border'), value: PrintStyle.Outline },
    {
      label: t('printModal.colorOptions.color'),
      value: PrintStyle.ColoredBackground,
    },
    {
      label: t('printModal.colorOptions.blackwhite'),
      value: PrintStyle.GrayScale,
    },
  ];

  const { filters } = useActiveFilters();

  const defaultDateRange = filters?.daterange
    ? {
        startDate: new Date(filters.daterange.start),
        // We have to offset the end date to show the correct end date in front-facing components
        endDate: endOfDay(addDays(new Date(filters.daterange.stop), -1)),
      }
    : {
        startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
        endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
      };

  const { reset, control, handleSubmit, watch } = useForm<IFormOutput>({
    values: {
      range: defaultDateRange,
      color: colorOptions[0],
      fontsize: fontSizeOptions[2],
      display: displayOptions[0],
      layout: layoutOptions[0],
      showWeekends: true,
    },
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  const formOutput = watch();

  const dateRange = React.useMemo<Required<DateTimeRangeInputValue>>(
    () => ({
      startDate: startOfDay(
        formOutput.range.startDate ??
          startOfWeek(new Date(), { weekStartsOn: 1 }),
      ),
      endDate: startOfDay(
        addDays(
          formOutput.range.endDate ??
            endOfWeek(new Date(), { weekStartsOn: 1 }),
          1,
        ),
      ),
    }),
    [formOutput.range],
  );

  const [isFetchingEvents, setIsFetchingEvents] = React.useState(true);

  const printCalendarProps = React.useMemo<PrintCalendarProps>(
    () => ({
      ...dateRange,
      display: formOutput.display.value,
      fontSize: formOutput.fontsize.value,
      style: formOutput.color.value,
      orientation: formOutput.layout.value,
      weekends: formOutput.showWeekends,
      onLoading(): void {
        setIsFetchingEvents(true);
      },
      onLoaded(): void {
        setIsFetchingEvents(false);
      },
    }),
    [
      dateRange,
      formOutput.display,
      formOutput.fontsize,
      formOutput.color,
      formOutput.layout,
      formOutput.showWeekends,
    ],
  );

  const printRef = React.useRef<PrintCalendarForwardRef>(null);

  const onSubmit = (): void => {
    printRef.current?.print();
  };

  return (
    <Modal
      icon={{ name: 'print' }}
      title={t('printModal.title')}
      {...modalProps}
    >
      <div className="flex gap-3">
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={'flex flex-col gap-3 justify-between h-full p-2'}
          >
            <div className={'flex flex-col gap-3'}>
              <Controller
                control={control}
                render={({ field }): React.ReactElement => (
                  <DateTimeRangeInput
                    value={field.value}
                    onChange={field.onChange}
                    label={t('printModal.range')}
                  />
                )}
                name={'range'}
              />
              <Controller
                control={control}
                render={({ field }): React.ReactElement => (
                  <SelectInput
                    {...field}
                    isClearable={false}
                    isSearchable={false}
                    label={t('printModal.display')}
                    options={displayOptions}
                  />
                )}
                name={'display'}
              />
              {formOutput.display.value === 'week' ? (
                <Controller
                  control={control}
                  render={({ field }): React.ReactElement => (
                    <CheckboxInput
                      {...field}
                      label={t('printModal.showWeekend')}
                    />
                  )}
                  name={'showWeekends'}
                />
              ) : null}
              <Controller
                control={control}
                render={({ field }): React.ReactElement => (
                  <SelectInput
                    {...field}
                    isClearable={false}
                    isSearchable={false}
                    label={t('printModal.fontsize')}
                    options={fontSizeOptions}
                  />
                )}
                name={'fontsize'}
              />
              <Controller
                control={control}
                render={({ field }): React.ReactElement => (
                  <SelectInput
                    {...field}
                    isClearable={false}
                    isSearchable={false}
                    label={t('printModal.layout')}
                    options={layoutOptions}
                  />
                )}
                name={'layout'}
              />
              <Controller
                control={control}
                render={({ field }): React.ReactElement => (
                  <SelectInput
                    {...field}
                    isClearable={false}
                    isSearchable={false}
                    label={t('printModal.color')}
                    options={colorOptions}
                  />
                )}
                name={'color'}
              />
            </div>
            <div className={'flex items-center justify-end  gap-3 '}>
              <CancelButton
                onClick={(): void => {
                  reset();
                  modalProps.onClose(false);
                }}
                data-testid="ModalPrintCalendarCancelButton"
              />
              <PrintButton disabled={isFetchingEvents} type={'submit'} />
            </div>
          </form>
        </div>
        <React.Suspense
          fallback={
            <Loader isDataLoaded={false}>
              <div className="w-[700px] h-[600px]" />
            </Loader>
          }
        >
          <Loader isDataLoaded={!isFetchingEvents}>
            <div className="w-[700px] h-[600px] overflow-hidden bg-white border border-border">
              <PrintCalendar ref={printRef} {...printCalendarProps} />
            </div>
          </Loader>
        </React.Suspense>
      </div>
    </Modal>
  );
};
