import type React from 'react';
import type { ITemplateSkeletonComponent } from '.';

interface IListSkeletonProps {
  iterations: number;
  offset?: number;
  component: ITemplateSkeletonComponent;
}

export const ListSkeleton: React.FC<IListSkeletonProps> = ({
  iterations,
  offset = 0,
  component: ListComponent,
}) => {
  return (
    <>
      {Array(iterations)
        .fill(0)
        .map((_v, i) => {
          const offsetForIteration = offset + i * ListComponent.itemOffset;
          return (
            <ListComponent
              key={offsetForIteration}
              index={i}
              iterations={iterations}
              offset={offsetForIteration}
            />
          );
        })}
    </>
  );
};
