/**
 *
 * LayerConfiguration Model
 */

export enum LayerConfigurationDataType {
  GEOJSON = 'geojson',
  RASTER = 'raster',
  RASTERDEM = 'raster-dem',
  VECTOR = 'vector',
  IMAGE = 'image',
  VIDEO = 'video',
}

/**
 * layerConfiguration interface
 *
 * @export
 * @interface ILayerConfiguration
 */
export interface ILayerConfiguration {
  id: number;
  dataType: LayerConfigurationDataType;
  wsUrl: string;
  wsCustomAction: string;
  tileServer: string;
  propertiesUrl?: string;
  propertiesCustomAction?: string;
}

/**
 * Layer Configuration class model
 *
 * @export
 * @class LayerConfiguration
 */
export class LayerConfiguration {
  id: number;

  dataType: LayerConfigurationDataType;

  wsUrl?: string;

  wsCustomAction?: string;

  tileServer?: string;

  propertiesUrl?: string;

  propertiesCustomAction?: string;

  constructor(data: ILayerConfiguration) {
    this.id = data.id;
    this.dataType = data.dataType;
    this.wsUrl = data.wsUrl;
    this.wsCustomAction = data.wsCustomAction;
    this.tileServer = data.tileServer;
    this.propertiesCustomAction = data.propertiesCustomAction;
    this.propertiesUrl = data.propertiesUrl;
  }
}
