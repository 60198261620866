import {
  FloatingPortal,
  type Placement,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import React from 'react';

interface IProps {
  title: React.ReactNode;
  children?: React.ReactNode;
  position?: Placement;
  offset?: number;
  containerProps?: React.HTMLProps<HTMLDivElement>;
}

export const Tooltip: React.FC<IProps> = ({
  title,
  children,
  position = 'top-start',
  offset: offsetValue = 0,
  containerProps = {
    className: 'flex',
  },
}) => {
  const [active, setActive] = React.useState<boolean>(false);
  const { x, y, refs, strategy, context } = useFloating({
    strategy: 'fixed',
    open: active,
    onOpenChange: setActive,
    placement: position,
    middleware: [shift(), offset(offsetValue), flip()],
  });

  const hover = useHover(context);
  const dismiss = useDismiss(context, {
    ancestorScroll: true,
  });
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    dismiss,
    role,
  ]);

  return (
    <>
      {title ? (
        <FloatingPortal>
          {active && (
            <div
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                width: 'max-content',
              }}
              className="text-primary bg-text rounded-md py-1 px-2 z-[1500]"
              {...getFloatingProps()}
            >
              {title}
            </div>
          )}
        </FloatingPortal>
      ) : null}
      <div ref={refs.setReference} {...getReferenceProps(containerProps)}>
        {children}
      </div>
    </>
  );
};
