import './MomentHack';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEN from './translation/en.json';
import translationFR from './translation/fr.json';

export enum Language {
  FR = 'fr',
  EN = 'en',
}

const defaultLanguage = navigator.language.substring(0, 2)
  ? navigator.language.substring(0, 2)
  : Language.FR;
// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLanguage,
    keySeparator: '.', // to support nested translations
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
export default i18n;
