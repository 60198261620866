import ContentLoader from 'react-content-loader';

// Some default settings for react-content-loader

export const LoaderSkeleton: typeof ContentLoader = (props) => (
  <ContentLoader
    data-testid={'loader-skeleton'}
    backgroundColor="#deddda"
    foregroundColor="#9a9996"
    title="Chargement..."
    {...props}
  />
);

export * from './Skeletons';
