import { IconRoundedButton } from '@smack/core/components/Actions/Buttons/IconRoundedButton/IconRoundedButton';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import type React from 'react';

interface IProps extends React.HTMLProps<HTMLDivElement> {
  icon: IconField;
  title?: string;
}

const noOp = (): void => {
  /* no-op */
};

/**
 * table header action components
 * @param props IProps
 * @returns JSX.ELEMENT
 */
export const TableHeaderAction: React.FC<IProps> = ({
  className,
  icon,
  title,
  ...htmlProps
}): JSX.Element => {
  return (
    <div
      className={`ml-2 text-right text-xs font-normal text-gray-500 text-border-500 dark:bg-neutral-800 uppercase ${
        className || ''
      }`}
      {...htmlProps}
    >
      <IconRoundedButton icon={icon} onClick={noOp} title={title} />
    </div>
  );
};
