import { t } from 'i18next';
import { Button } from './Components/Button';
import { IconButton, createAliasIconButton } from './Components/IconButton';

export { Button, IconButton };

export const AcceptButton = createAliasIconButton(
  { name: 'check' },
  t('buttons.accept'),
);
export const AddButton = createAliasIconButton(
  { name: 'plus' },
  t('buttons.add'),
);
export const CancelButton = createAliasIconButton(
  { name: 'times' },
  t('buttons.cancel'),
);
export const CloseButton = createAliasIconButton(
  { name: 'times' },
  t('buttons.close'),
);
export const EditButton = createAliasIconButton(
  { name: 'pencil' },
  t('buttons.edit'),
);
export const ModifyButton = createAliasIconButton(
  { name: 'pencil' },
  t('buttons.edit'),
);
export const CreateButton = createAliasIconButton(
  { name: 'plus' },
  t('buttons.create'),
);
export const DeleteButton = createAliasIconButton(
  { name: 'trash-can' },
  t('buttons.delete'),
);
export const PrintButton = createAliasIconButton(
  { name: 'print' },
  t('buttons.print'),
);
export const RefuseButton = createAliasIconButton(
  { name: 'times' },
  t('buttons.refuse'),
);
export const SaveButton = createAliasIconButton(
  { name: 'check' },
  t('buttons.save'),
);
export const ValidateButton = createAliasIconButton(
  { name: 'check' },
  t('buttons.validate'),
);
export const ContinueButton = createAliasIconButton(
  { name: 'arrow-right' },
  t('buttons.continue'),
);
export const AccessButton = createAliasIconButton(
  { name: 'arrow-up-right-from-square' },
  t('buttons.access'),
);

export const StatsButton = createAliasIconButton(
  { name: 'chart-mixed' },
  t('buttons.stats'),
);
