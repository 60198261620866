import type { Category } from '@smack/core/api/models/categories/Category';
import { DisplayedAttributeFilter } from '@smack/core/api/models/categories/DisplayedAttributeFilter';
import {
  AvailabilityFilter,
  type LinkGroup,
} from '@smack/core/api/models/categories/LinkGroup';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { ListBaseObject } from '@smack/core/api/models/objects/NewBaseObject/ListBaseObject';
import { ListElementBaseObject } from '@smack/core/api/models/objects/NewBaseObject/ListElementBaseObject';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import type { IListElementAction } from '@smack/core/components/DataDisplay/Lists/ListElements/BaseListElement';
import { BaseObjectListElement } from '@smack/core/components/DataDisplay/Lists/ListElements/BaseObjectListElement';
import { NoContentMessage } from '@smack/core/components/DataDisplay/NoContentMessage';
import { Tooltip } from '@smack/core/components/DataDisplay/Tooltip';
import {
  DateTimeRangeInput,
  type DateTimeRangeInputValue,
} from '@smack/core/components/DataInput/DateRangeInput/DateTimeRangeInput';
import { SearchBar } from '@smack/core/components/DataInput/SearchBar';
import type { IFilters } from '@smack/core/utils/Filters';
import {
  BaseObjectSkeleton,
  ListSkeleton,
  LoaderSkeleton,
} from '@smack/core/utils/Loader';
import { getFiltersList } from '@smack/core/utils/config/FiltersList';
import { InfiniteVirtualizedList } from '@smack/core/views/oldViewsToSort/Layouts/Objects/InfiniteVirtualizedList';
import { ActiveFiltersDisplay } from '@smack/core/views/oldViewsToSort/Layouts/Objects/MenuFilter/Components/ActiveFiltersDisplay/ActiveFiltersDisplay';
import {
  ContextualMenu,
  type IContextualProps,
} from '@smack/core/views/oldViewsToSort/Layouts/Objects/MenuFilter/Components/Contextual';
import { QuickFilters } from '@smack/core/views/oldViewsToSort/Layouts/Objects/MenuFilter/Components/QuickFilters';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface BaseBaseObjectSelectorProps {
  linkGroup: LinkGroup;
  dateRangeValue?: DateTimeRangeInputValue;
  sourceBaseObjectId?: number;
  filterParameters?: Record<string, unknown>;
  category: Category | number;
}

interface SimpleBaseObjectSelectorProps extends BaseBaseObjectSelectorProps {
  values: BaseObject | undefined;
  onChange: (values: BaseObject) => void;
  multiple?: false;
}

interface MultipleBaseObjectSelectorProps extends BaseBaseObjectSelectorProps {
  values: BaseObject[] | undefined;
  onChange: (values: BaseObject[]) => void;
  multiple: true;
}

export type BaseObjectSelectorProps =
  | SimpleBaseObjectSelectorProps
  | MultipleBaseObjectSelectorProps;

export const LinkGroupBaseObjectSelector: React.FC<BaseObjectSelectorProps> = ({
  linkGroup,
  dateRangeValue,
  category,
  values = [],
  onChange,
  sourceBaseObjectId,
  filterParameters,
  multiple,
}) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [filtersAttributes, setFiltersAttributes] =
    useState<DisplayedAttributeFilter[]>();
  const [objects, setObjects] = useState<ListBaseObject[]>([]);
  const [objectTotal, setObjectTotal] = useState<number>(0);
  const [filters, setFilters] = useState<IFilters>({});
  const [t] = useTranslation();
  const valueList = Array.isArray(values) ? values : [values];
  const onSearch = (value: string): void => {
    setFilters({ ...filters, search: value });
  };

  const getCategoryId = (): number => {
    if (typeof category === 'number') return category;
    return category.id;
  };

  useEffect(() => {
    if (typeof category === 'number') return;
    if (category?.parentCategoryId) {
      DisplayedAttributeFilter.getDisplayedAttributeFilter(
        category.parentCategoryId,
      ).then((attres) => {
        setFiltersAttributes(attres.filter((a) => a.quickFilters?.length));
      });
    }
  }, [category]);

  const loadMoreRows = (indicesData?: {
    startIndex: number;
    stopIndex: number;
  }): void => {
    if (!indicesData) {
      setIsLoaded(false);
    }
    ListElementBaseObject.getBaseObjectForListRepresentation(
      undefined,
      { categories: [getCategoryId().toString()], ...filters },
      indicesData?.stopIndex ?? 0,
      undefined,
      linkGroup
        ? {
            linkGroupId: linkGroup?.id,
            sourceBaseObjectId: sourceBaseObjectId,
            includeLinkables: true,
          }
        : undefined,
      filterParameters,
    )
      .then((res) => {
        setObjects(
          indicesData
            ? [
                ...objects,
                ...res.data.results.map((r) => new ListElementBaseObject(r)),
              ]
            : res.data.results.map((r) => new ListElementBaseObject(r)),
        );
        setObjectTotal(res.data.count);
      })
      .finally((): void => {
        setIsLoaded(true);
      });
  };

  useEffect(() => {
    loadMoreRows();
  }, [filters, category]);

  const getFiltersItems = (isQuick = false): IContextualProps => {
    const output = {
      ...getFiltersList({
        filters,
        setFilters,
        displayedAttributeFilters: filtersAttributes,
        filterOptions: {
          isPeriodFilterAvailable: linkGroup.linkableObjectsTimeFilters,
        },
      }),
    };
    if (!isQuick) {
      output.menuItems.items = output.menuItems.items.filter((i) => !i.isQuick);
    }
    return output;
  };

  const setAvailableFilter = (): void => {
    if (!linkGroup) return;
    if (filters.available) {
      setFilters({ ...filters, available: undefined });
      return;
    }
    setFilters({
      ...filters,
      available: {
        isAvailableLinkGroup:
          linkGroup.linkableObjectsAvailabilityFilters ===
          AvailabilityFilter.THIS
            ? linkGroup.id
            : undefined,
        isAvailableDtEnd: dateRangeValue?.endDate?.toISOString(),
        isAvailableDtStart: dateRangeValue?.startDate?.toISOString(),
      },
    });
  };

  const isAlreadyLinked = (object: ListBaseObject) =>
    !linkGroup.isTimeSensitive && object.linkId;

  const getListElementAction = (
    object: ListBaseObject,
  ): IListElementAction | undefined => {
    if (isAlreadyLinked(object)) {
      return {
        icon: { name: 'link' },
        label: t('links.alreadyLinked'),
      };
    }
  };

  const getObjectList = (): React.ReactNode => {
    if (!linkGroup) return null;
    if (isLoaded && !objects.length && !valueList?.length) {
      return (
        <NoContentMessage
          label={t('addLinkForm.noContent')}
          icon={{ name: 'search' }}
        />
      );
    }

    const handleClick = (object: ListBaseObject) => {
      if (isAlreadyLinked(object)) return;
      if (!multiple) {
        onChange(object as BaseObject);
        return;
      }
      if (valueList.find((v) => v.id === object.id)) {
        onChange([...valueList.filter((v) => v.id !== object.id)]);
      } else {
        onChange([...valueList, object as BaseObject]);
      }
    };

    const renderButton = (object: ListBaseObject): React.ReactElement => {
      if (!linkGroup) return <></>;
      const props = object.getListElement();

      return (
        <BaseObjectListElement
          {...props}
          active={!!valueList.find((v) => v.id === object.id)}
          onClick={(): void => {
            handleClick(object);
          }}
          action={getListElementAction(object)}
          link={undefined}
          noOverlayActions={true}
        />
      );
    };

    const getObjectsList = () => {
      return [
        ...valueList.filter((v) => !objects.some((val) => val.id === v.id)),
        ...objects,
      ];
    };

    return (
      <InfiniteVirtualizedList
        objects={getObjectsList()}
        renderListItem={renderButton}
        startIndex={0}
        loadMoreRows={loadMoreRows}
        rowCount={objectTotal}
      />
    );
  };

  return (
    <div data-testid="LinkGroupBaseObjectSelector">
      <div className={'flex items-center '}>
        <SearchBar
          onClear={(): void => onSearch('')}
          onSearch={onSearch}
          value={filters.search ?? ''}
          placeholder={t('addLinkForm.searchObject')}
          className={{
            container: `bg-white dark:bg-neutral-800 text-sm !border !border-border !rounded-none ${
              linkGroup.linkableObjectsFullFilters ? '!border-r-0' : ''
            } `,
          }}
        />

        {linkGroup.linkableObjectsFullFilters && (
          <ContextualMenu {...getFiltersItems()}>
            {({ htmlRef, onClick }): React.ReactElement => (
              <button
                type={'button'}
                tabIndex={-1}
                onClick={onClick}
                ref={htmlRef}
                data-testid="contextual-menu"
                className="w-[44px] h-[44px] border border-border  flex items-center justify-center cursor-pointer"
              >
                <Icon
                  icon={{
                    name: 'sliders',
                  }}
                />
              </button>
            )}
          </ContextualMenu>
        )}
        {linkGroup.linkableObjectsAvailabilityFilters !==
          AvailabilityFilter.NONE && (
          <Tooltip title={t('addLinkForm.isObjectAvailable')}>
            <button
              data-testid={'availabilityFilters'}
              type="button"
              className={`w-[44px] h-[44px] flex items-center justify-center border border-border border-l-0 cursor-pointer ${
                filters?.available ? 'bg-blue-200' : ''
              }`}
              onClick={(): void => setAvailableFilter()}
            >
              <Icon
                className={'text-lg text-gray-700'}
                icon={{
                  name: 'calendar-check',
                }}
              />
            </button>
          </Tooltip>
        )}
      </div>
      {linkGroup.linkableObjectsQuickFilters && (
        <QuickFilters
          className={'border-t-0'}
          filterOptions={getFiltersItems(true)}
        />
      )}
      {linkGroup.linkableObjectsTimeFilters && (
        <div data-testid="time-filter" className="border border-border px-2 ">
          <DateTimeRangeInput
            value={
              filters.daterange
                ? {
                    startDate: new Date(filters.daterange.start),
                    endDate: new Date(filters.daterange.stop),
                  }
                : undefined
            }
            onChange={(val): void => {
              if (val.startDate && val.endDate) {
                setFilters({
                  ...filters,
                  daterange: {
                    start: val.startDate?.toISOString(),
                    stop: val.endDate?.toISOString(),
                  },
                });
              } else {
                setFilters({
                  ...filters,
                  daterange: undefined,
                });
              }
            }}
          />
        </div>
      )}

      <ActiveFiltersDisplay
        className={'border-t-0 border border-border px-2'}
        filterItems={getFiltersItems().menuItems.items}
        filters={filters}
        setFilters={setFilters}
        hideIfEmpty
      />
      <div className="my-0 mx-auto  h-72 border border-t-0 rounded-b dark:border-neutral-500">
        {isLoaded ? (
          getObjectList()
        ) : (
          <LoaderSkeleton height={240} width={400} className="overflow-clip">
            <ListSkeleton component={BaseObjectSkeleton} iterations={5} />
          </LoaderSkeleton>
        )}
      </div>
    </div>
  );
};
