import type { ITemplateSkeletonComponent } from '.';

export const ModuleSkeleton: ITemplateSkeletonComponent = ({ offset = 0 }) => (
  <>
    <circle cx={40} cy={offset + 24} r={24} />
    <rect x={80} y={offset + 14} height={20} width={150} rx={10} />
  </>
);

ModuleSkeleton.itemOffset = 64;
