import type { ITemplateSkeletonComponent } from '.';

export const FormFieldSectionSkeleton: ITemplateSkeletonComponent = ({
  offset = 0,
}) => (
  <>
    <rect x="300" y={offset} height="0.5" width="100%" />
    <rect x="300" y={offset + 25} height="25" width="150" />
    <rect x="700" y={offset + 20} height="35" width="300" />
  </>
);

FormFieldSectionSkeleton.itemOffset = 70;
