/**
 * MEDIA CATEGORY MODEL
 */
import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';

export interface IMediaCategory {
  id: number;
  label?: string;
  icon?: IconField;
  color?: string;
}

export class MediaCategory implements IMediaCategory {
  id: number;

  label?: string;

  icon?: IconField;

  color?: string;

  constructor(data: IMediaCategory) {
    this.id = data.id;
    this.label = data.label;
    this.icon = data.icon;
    this.color = data.color;
  }

  toOption(): Option<number> {
    return {
      label: this.label ?? '',
      value: this.id,
      icon: this.icon,
      color: this.color,
    };
  }

  static getMediaCategories(
    categoryId?: number | string,
  ): Promise<MediaCategory[]> {
    const url = categoryId
      ? `/categories/categories/${categoryId}/media-categories`
      : '/medias/categories';
    return RESTClient.get<{ data: { results: IMediaCategory[] } }>(url).then(
      (res) => res.data?.results.map((l) => new MediaCategory(l)),
    );
  }
}
