import { Category } from '@smack/core/api/models/categories/Category';
import { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import { CloseButton } from '@smack/core/components/Actions/Buttons/Button';
import { DataDisplayHeader } from '@smack/core/components/DataDisplay/Headers/DataDisplayHeader';
import type { RRuleInputValue } from '@smack/core/components/DataInput/RRuleInput';
import { AutoBaseObjectView } from '@smack/core/components/ViewRenderer/AutoBaseObjectView';
import type {
  BaseObjectFormOnSubmit,
  BaseObjectFormOutput,
  BaseObjectFormViewRenderForwardedRef,
} from '@smack/core/components/ViewRenderer/interfaces';
import { assignRef } from '@smack/core/utils';
import { CategorySelector } from '@smack/core/views/oldViewsToSort/Layouts/Forms/CategorySelector';
import {
  type DefaultValuesFromFieldType,
  assignDefaultValueFormBaseobjectView,
} from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/common/assignDefaultValueFormBaseobjectView';
import {
  BaseObjectViewContext,
  BaseObjectViewContextProvider,
} from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/common/context/BaseObjectViewContext';
import { getActionsButtons } from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/common/getActionsButtons';
import { onQuit } from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/common/onQuit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncMemo } from 'use-async-memo';

export interface CreationFormProps {
  initialCategoryId?: number;
  onSuccessfulFormSubmission?: (output?: { id: number }) => void;
  defaultValuesFormFieldType?: DefaultValuesFromFieldType;
  defaultValues?: BaseObjectFormOutput;
  onClose?: () => void;
}

export const CreationForm: React.FC<CreationFormProps> = ({
  initialCategoryId,
  onClose,
  onSuccessfulFormSubmission,
  defaultValuesFormFieldType = {},
  defaultValues = {},
}) => {
  const [category, setCategory] = React.useState<Category>();
  const formRef = React.useRef<BaseObjectFormViewRenderForwardedRef>(null);
  const [formRendered, setFormRendered] = React.useState<boolean>(false);
  const [t] = useTranslation();

  const initialCategory = useAsyncMemo(() => {
    if (initialCategoryId) {
      return Category.getCategory(initialCategoryId).then((cat) => {
        if (cat.isCategory) setCategory(cat);
        return cat;
      });
    }
  }, [initialCategoryId]);

  React.useEffect(() => {
    if (formRef?.current?.useForm) {
      const newValues = {
        ...assignDefaultValueFormBaseobjectView(
          defaultValuesFormFieldType,
          formRef.current.view,
        ),
        ...defaultValues,
      };
      for (const [key, value] of Object.entries(newValues)) {
        formRef.current.useForm.setValue(key, value);
      }
    }
  }, [formRendered]);

  const isCategoryLoaded = category || (initialCategoryId && initialCategory);

  if (!isCategoryLoaded && initialCategoryId) return null;

  if (!category)
    return (
      <div className="flex flex-col flex-grow">
        <DataDisplayHeader
          icon={initialCategory?.icon}
          color={initialCategory?.color ?? ''}
          title={initialCategory?.label}
          actions={[<CloseButton key="quit" onClick={onClose} />]}
        />
        <div className="flex flex-col flex-grow gap-10 p-3 sm:p-16 bg-view overflow-y-auto">
          <h2 className="text-xl md:text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
            {t('formLabel.selectACategory')}
          </h2>
          <div className="flex flex-col flex-grow">
            <CategorySelector
              onCategoryClicked={setCategory}
              startCategoryId={initialCategoryId}
              isCategoryDisabled={(cat) => {
                if (!cat.isWritable) return true;
                return cat.isCategory && !cat.canBeCreatedInFront;
              }}
              categoryDisabledReason={(cat) => {
                if (cat.isCategory && !cat.canBeCreatedInFront) {
                  return t('categorySelector.categoryIsNotCreatable');
                }
                return t('categorySelector.notAllowedToCreate');
              }}
            />
          </div>
        </div>
      </div>
    );
  const onClickSubmit = (): void => {
    formRef.current?.formRef?.requestSubmit();
  };

  const handleQuit = () => {
    onQuit(formRef.current, onClose, onClickSubmit);
  };

  const handleOnSuccessfulFormSubmission = (data?: {
    id: number;
    rrule?: RRuleInputValue;
  }) => {
    onSuccessfulFormSubmission?.(data);
    onClose?.();
  };

  const callBackRef = (ref: BaseObjectFormViewRenderForwardedRef) => {
    setFormRendered(true);
    assignRef(formRef, ref);
  };

  const onSubmit: BaseObjectFormOnSubmit = (
    attributes,
    _recurrenceType,
    rrule,
  ) => {
    return BaseObject.createBaseObject({
      categoryId: category.id,
      attributes,
    }).then((data) => handleOnSuccessfulFormSubmission({ id: data.id, rrule }));
  };

  return (
    <BaseObjectViewContextProvider>
      <BaseObjectViewContext.Consumer>
        {({ areActionButtonsReady }) => (
          <div
            data-testid={'creation-form'}
            className="flex flex-col flex-grow bg-view"
          >
            <DataDisplayHeader
              icon={category.icon}
              color={category.color ?? ''}
              title={category.label}
              actions={[
                ...getActionsButtons(category, {
                  onClickSubmit,
                  isLoading: !areActionButtonsReady,
                }),
                <CloseButton key="quit" onClick={handleQuit} />,
              ]}
            />
            <div className={'flex flex-grow p-6 overflow-y-auto'}>
              <AutoBaseObjectView
                ref={callBackRef}
                viewUsage={ViewUsage.FORM}
                categoryId={category.id}
                onSubmit={onSubmit}
              />
            </div>
          </div>
        )}
      </BaseObjectViewContext.Consumer>
    </BaseObjectViewContextProvider>
  );
};
