import { useUploady } from '@rpldy/uploady';
import Signature, {
  type SignatureCanvasRef,
  type SignatureProps,
} from '@uiw/react-signature/canvas';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '../Buttons/Button';

/**
 * Input properties of the drawing pad component.
 */
export interface IProps extends Omit<SignatureProps, 'onPointer' | 'ref'> {
  /**
   * The name of the file
   * (typically the label of the form field)
   * (must NOT include the file extension).
   */
  filename: string;
}

/**
 * Creates a `DrawingPad` component
 * to draw something in a zone (typically a signature).
 *
 * @param props - The input properties of the component.
 * @returns The created `DrawingPad` component.
 */
export const DrawingPad = ({ filename, ...padProps }: IProps): JSX.Element => {
  const [t] = useTranslation();
  const uploady = useUploady();

  const [isPadEmpty, setIsPadEmpty] = React.useState(true);

  const padRef: React.MutableRefObject<SignatureCanvasRef | null> =
    React.useRef(null);

  const generateFile = (): Promise<File | null> => {
    const mimetype = 'image/png';
    return new Promise((resolve) =>
      padRef.current?.canvas?.toBlob((blob) => {
        if (!blob) return;
        const currentDate: string = format(new Date(), 'dd-MM-yy-HHmmss');
        const name = `${filename}_${currentDate}.png`;
        return resolve(new File([blob], name, { type: mimetype }));
      }, mimetype),
    );
  };

  const onTouchPad = (points: number[][]): void => {
    /* NOTE: the points represent the coordinates of the lines made by the user
     * -> No points means that the user has not touched the pad zone
     */
    if (!points.length) return;
    setIsPadEmpty(false);
  };

  const onClearPad = (): void => {
    padRef.current?.clear();
    setIsPadEmpty(true);
  };

  const onHandleConfirm = (): void => {
    if (isPadEmpty) return;
    generateFile().then((file) => {
      if (!file) return;
      uploady.upload(file);
    });
  };

  return (
    <div className="w-fit flex flex-col">
      <div className="w-fit p-1 border border-gray-300 rounded-md">
        <Signature {...padProps} onPointer={onTouchPad} ref={padRef} />
      </div>
      <div className="mt-2 flex flex-wrap gap-2">
        <IconButton
          icon={{ name: 'broom-wide' }}
          disabled={isPadEmpty}
          onClick={onClearPad}
        >
          {t('medias.signature.clear')}
        </IconButton>
        <IconButton
          icon={{ name: 'check' }}
          disabled={isPadEmpty}
          onClick={onHandleConfirm}
        >
          {t('medias.signature.confirm')}
        </IconButton>
      </div>
    </div>
  );
};
