import { LoaderSkeleton } from '@smack/core/utils/Loader';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Buttons/Button';

/**
 * Input properties of the camera list component.
 */
interface IProps {
  /** OPTIONAL - The event callback function fired when the suer selects a camera. */
  onSelect?: (deviceId: string) => void;
}

/**
 * Creates a `CameraList` component
 * that lists the available webcams on the user's device.
 *
 * @param props - The input properties of the component.
 * @returns The created `CameraList` component.
 */
export const CameraList = ({ onSelect }: IProps): JSX.Element => {
  const [t] = useTranslation();

  const [cameras, setCameras] = React.useState<MediaDeviceInfo[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<unknown>(null);

  // Init
  React.useEffect(() => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices: MediaDeviceInfo[]) => {
        const cameraDevices: MediaDeviceInfo[] = devices.filter(
          (device: MediaDeviceInfo) => device.kind === 'videoinput',
        );
        setCameras(cameraDevices);
      })
      .catch((e: unknown) => setError(e))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div className="mt-2">
      {isLoading && (
        <LoaderSkeleton height={15} width={300}>
          <rect x="0" y="0" height={15} width={300} />
        </LoaderSkeleton>
      )}
      {!isLoading && (
        <>
          {error && <strong>{t('medias.webcam.retrieveError')}</strong>}
          {!error && (
            <>
              {!cameras?.length && (
                <strong>{t('medias.webcam.noWebcams')}</strong>
              )}
              {cameras?.length > 0 && (
                <ul className="flex flex-col gap-2">
                  {cameras.map((camera) => (
                    <li key={camera.deviceId}>
                      <Button
                        className="w-full"
                        onClick={
                          onSelect
                            ? (): void => onSelect(camera.deviceId)
                            : undefined
                        }
                      >
                        {camera.label}
                      </Button>
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
