import type {
  Attribute,
  IAttributeApiOutput,
} from '@smack/core/api/models/categories/Attribute/Attribute';
import { BaseObjectGroup } from '@smack/core/api/models/objects/BaseObjectGroup';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { RRuleInputValue } from '@smack/core/components/DataInput/RRuleInput';
import type {
  BaseObjectFormOutput,
  BaseObjectFormOutputValues,
} from '@smack/core/components/ViewRenderer/interfaces';
import { spawnModal } from '@smack/core/utils/modal';
import {
  RecurrenceUpdateChoiceObjectModal,
  TypeRecurrenceUpdateChoice,
} from '@smack/core/views/oldViewsToSort/Layouts/Modal/RecurrenceUpdateChoiceObjectModal';
import i18next from 'i18next';

export const submitFormWithLegacyRecurrence = (
  data: BaseObjectFormOutput,
  recurrenceUpdateChoice: TypeRecurrenceUpdateChoice,
  scheduleId?: string,
  attribute?: IAttributeApiOutput,
): BaseObjectFormOutput => {
  const recurrenceValue = data[attribute?.id ?? ''] as RRuleInputValue;
  if (attribute && scheduleId && recurrenceValue) {
    if (recurrenceUpdateChoice === TypeRecurrenceUpdateChoice.ALL) {
      recurrenceValue.update = 'REPLACE_ALL';
    } else if (recurrenceUpdateChoice === TypeRecurrenceUpdateChoice.NEXT) {
      recurrenceValue.update = 'REPLACE_FUTURE';
    } else {
      recurrenceValue.update = 'REPLACE_ONE';
    }
    recurrenceValue.updateFromSchedule = scheduleId;
    if (!recurrenceValue.rrule) {
      recurrenceValue.update = recurrenceValue.update.replace(
        'REPLACE',
        'REMOVE',
      );
    }
    data[attribute.id] = recurrenceValue as BaseObjectFormOutputValues;
  }
  return data;
};

export const submitFormBaseObjectGroup = (
  data: BaseObjectFormOutput,
  val: TypeRecurrenceUpdateChoice,
  attribute?: IAttributeApiOutput,
): BaseObjectFormOutput => {
  const recurrenceValue = data[attribute?.id ?? ''] as RRuleInputValue;
  if (val !== TypeRecurrenceUpdateChoice.THIS) {
    if (recurrenceValue?.dtstart && recurrenceValue?.dtend) {
      const recurrenceField = BaseObjectGroup.getPatchRecurrenceField(
        recurrenceValue?.dtstart,
        recurrenceValue?.dtend,
      );
      data[attribute?.id ?? ''] = recurrenceField as BaseObjectFormOutputValues;
    }
  }
  return data;
};

export const handleSubmitCheckRecurrence = (
  data: BaseObjectFormOutput,
  baseObject: BaseObject,
  recurrenceAttribute: Attribute | undefined,
  submitForm: (
    data: BaseObjectFormOutput,
    recurrenceType?: TypeRecurrenceUpdateChoice,
  ) => void,
): void => {
  if (!recurrenceAttribute) {
    submitForm(data);
    return;
  }
  if (baseObject?.rrule || baseObject?.baseobjectGroupId) {
    spawnModal({
      render: ({ onClose }) => {
        return (
          <RecurrenceUpdateChoiceObjectModal
            icon={{ name: 'pen' }}
            title={i18next.t('recurrenceChoiceObjectModal.updateObjectTitle')}
            description={i18next.t(
              'recurrenceChoiceObjectModal.ObjectDescription',
              {
                action: i18next.t(
                  'recurrenceChoiceObjectModal.ObjectDescriptionUpdateAction',
                ),
              },
            )}
            onSave={(recurrenceType): void => {
              if (baseObject?.baseobjectGroupId) {
                data = submitFormBaseObjectGroup(
                  data,
                  recurrenceType,
                  recurrenceAttribute,
                );
              } else {
                data = submitFormWithLegacyRecurrence(
                  data,
                  recurrenceType,
                  baseObject.scheduleId?.toString(),
                  recurrenceAttribute,
                );
              }
              submitForm(data, recurrenceType);
              onClose();
            }}
            open={true}
            onClose={onClose}
          />
        );
      },
    });
  } else {
    submitForm(data);
  }
};
