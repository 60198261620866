import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import { Tooltip } from '@smack/core/components/DataDisplay/Tooltip';
import type React from 'react';
import type { DraggableProvided } from 'react-beautiful-dnd';

interface ListingHeaderProps extends React.ComponentProps<'div'> {
  icon?: IconField;
  headerTitle: React.ReactNode;
  count?: number;
  children?: React.ReactNode;
  onClick?: () => void;
  dragProvider?: DraggableProvided;
}

export const ListingHeader: React.FC<ListingHeaderProps> = ({
  icon,
  headerTitle,
  count,
  children,
  onClick,
  dragProvider,
}) => {
  const headerTitleComp = (
    <p className="truncate pl-3 mr-2 text-gray-600 dark:text-gray-300">
      {headerTitle}
    </p>
  );

  return (
    <div
      ref={dragProvider?.innerRef}
      {...dragProvider?.dragHandleProps}
      {...dragProvider?.draggableProps}
      className="h-12 border-b border-border flex"
    >
      <button
        onClick={onClick}
        type={'button'}
        className={`h-12 w-full flex-grow flex items-center px-4 justify-between ${
          onClick ? 'cursor-pointer' : ''
        }`}
      >
        <div className="flex items-center select-none dark:text-white overflow-hidden">
          {icon && (
            <Icon
              icon={icon}
              className="fa-fw text-lg text-gray-600 dark:text-gray-300"
            />
          )}
          <div className="max-w-[250px]">
            {typeof headerTitle === 'string' ? (
              <Tooltip title={headerTitle}>{headerTitleComp}</Tooltip>
            ) : (
              headerTitleComp
            )}
          </div>
          <div className="mx-2 flex shrink-0 items-center justify-center w-5 h-5 rounded-full bg-[#f6f6f6] dark:bg-[#3c3c3c] text-xs text-gray-600">
            {count}
          </div>
        </div>
        <div className="flex items-center">{children}</div>
      </button>
    </div>
  );
};
