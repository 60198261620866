import type React from 'react';

export interface CardTitleProps {
  children: React.ReactNode;
}

export const CardTitle: React.FC<CardTitleProps> = ({ children }) => {
  return (
    <div className="w-full h-fit relative">
      <div className="absolute  inset-0 flex items-center">
        <h3 className="text-lg leading-6 font-medium small-caps text-gray-900 dark:text-gray-200 truncate">
          {children}
        </h3>
      </div>
    </div>
  );
};
