import { Switch as HeadlessSwitch } from '@headlessui/react';
import type { JSX } from 'react';

export interface IProps {
  active: boolean;
  disabled?: boolean;
  className?: string;
  setActive: () => void;
}

export const Switch = ({
  active,
  disabled,
  className,
  setActive,
}: IProps): JSX.Element => {
  const onHandleChange = (): void => {
    if (disabled) return;
    setActive();
  };

  return (
    // TODO: use 'tabindex' attribute to prevent selecting with keyboard navigation if disabled (not available yet with Headless UI)
    <HeadlessSwitch
      as="div"
      checked={active}
      aria-disabled={disabled}
      className={`
        ${active ? 'bg-blue-500' : 'bg-gray-200 dark:bg-neutral-500'}
        ${
          disabled
            ? 'opacity-50 cursor-default'
            : 'focus:ring-2 focus:ring-blue-500 focus:ring-offset-0'
        }
        relative inline-flex h-6 w-11 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out outline-none
        ${className || ''}`}
      onChange={onHandleChange}
    >
      <span
        aria-hidden="true"
        className={`${active ? 'translate-x-5' : 'translate-x-0'}
        pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
      />
    </HeadlessSwitch>
  );
};
