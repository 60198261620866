import React from 'react';

import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { BaseObjectView } from '@smack/core/api/models/views/BaseObjectView/BaseObjectView';
import type {
  BaseObjectFormOnSubmit,
  BaseObjectFormViewRenderForwardedRef,
  ViewLayoutComponent,
} from '@smack/core/components/ViewRenderer/interfaces';
import { BaseObjectDisplayViewRenderer } from '@smack/core/components/ViewRenderer/renderers/BaseObjectDisplayViewRenderer';
import { BaseObjectFormViewRenderer } from '@smack/core/components/ViewRenderer/renderers/BaseObjectFormViewRenderer/BaseObjectFormViewRenderer';
import { ProvideViewRules } from '@smack/core/components/ViewRenderer/rules/ViewRulesContext/ViewRulesContext';
import type { IFilters } from '@smack/core/utils/Filters';

interface BaseObjectViewRendererProps {
  view: BaseObjectView;
  baseObject?: BaseObject;
  categoryId?: number;
  scheduleId?: number;
  filters?: IFilters;
  LayoutComponent?: ViewLayoutComponent;
  toggleableFields?: boolean;
  onSubmit?: BaseObjectFormOnSubmit;
}

export const BaseObjectViewRendererRender: React.ForwardRefRenderFunction<
  BaseObjectFormViewRenderForwardedRef,
  BaseObjectViewRendererProps
> = (
  {
    view,
    baseObject,
    scheduleId,
    categoryId,
    toggleableFields,
    LayoutComponent,
    filters,
    onSubmit,
  },
  ref,
) => {
  return (
    <ProvideViewRules viewRules={view.viewRules}>
      {view.isFormOrQuickForm ? (
        <BaseObjectFormViewRenderer
          ref={ref}
          view={view}
          baseObject={baseObject}
          scheduleId={scheduleId}
          LayoutComponent={LayoutComponent}
          toggleableFields={toggleableFields}
          filters={filters}
          categoryId={categoryId}
          onSubmit={onSubmit}
        />
      ) : (
        <BaseObjectDisplayViewRenderer
          view={view}
          baseObject={baseObject}
          LayoutComponent={LayoutComponent}
        />
      )}
    </ProvideViewRules>
  );
};

export const BaseObjectViewRenderer = React.forwardRef(
  BaseObjectViewRendererRender,
);
