import { DangerAlert } from '@smack/core/components/DataDisplay/Alerts/Alerts';
import type React from 'react';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export interface ErrorsAlertProps {
  errors: Record<string, string[]>;
}

export const ErrorsAlert: React.FC<ErrorsAlertProps> = ({ errors }) => {
  const [t] = useTranslation();
  const hasErrors = Object.keys(errors).length;

  const getErrorContent = (): ReactNode => {
    const output: ReactNode[] = [];
    for (const errorsLabel of Object.keys(errors)) {
      const errorsByAttribute: string[] = errors[errorsLabel];
      output.push(
        <li key={errorsLabel}>
          {errorsLabel}:
          <ul>
            {errorsByAttribute.map((err) => (
              <li key={err} className="list-disc ml-8">
                {err}
              </li>
            ))}
          </ul>
        </li>,
      );
    }
    return output;
  };

  return (
    <DangerAlert title={t('formErrors.errorTitle')}>
      <div id="baseobject-alert-danger" className="info">
        {hasErrors ? (
          <ul className="list-disc pl-5 space-y-1">{getErrorContent()}</ul>
        ) : (
          <p>{t('formErrors.unknownError')}</p>
        )}
      </div>
    </DangerAlert>
  );
};
