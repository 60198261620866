import { Category } from '@smack/core/api/models/categories/Category';
import { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import {
  CloseButton,
  IconButton,
  SaveButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { AutoBaseObjectView } from '@smack/core/components/ViewRenderer/AutoBaseObjectView';
import type {
  BaseObjectFormOnSubmit,
  BaseObjectFormOutput,
  BaseObjectFormViewRenderForwardedRef,
} from '@smack/core/components/ViewRenderer/interfaces';
import { ViewName } from '@smack/core/hooks/views/types';
import { useActiveView } from '@smack/core/hooks/views/useActiveView/useActiveView';
import { assignRef } from '@smack/core/utils';
import { CategorySelector } from '@smack/core/views/oldViewsToSort/Layouts/Forms/CategorySelector';
import {
  type DefaultValuesFromFieldType,
  assignDefaultValueFormBaseobjectView,
} from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/common/assignDefaultValueFormBaseobjectView';
import { BaseObjectViewContextProvider } from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/common/context/BaseObjectViewContext';
import { onQuit } from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/common/onQuit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncMemo } from 'use-async-memo';

export interface QuickFormProps {
  initialCategoryId?: number;
  onSuccessfulFormSubmission?: (output?: { id: number }) => void;
  defaultValuesFormFieldType?: DefaultValuesFromFieldType;
  defaultValues?: BaseObjectFormOutput;
  onClose?: () => void;
}

export const QuickForm: React.FC<QuickFormProps> = ({
  initialCategoryId,
  onClose,
  onSuccessfulFormSubmission,
  defaultValuesFormFieldType = {},
  defaultValues = {},
}) => {
  const [category, setCategory] = React.useState<Category>();
  const formRef = React.useRef<BaseObjectFormViewRenderForwardedRef>(null);
  const [formRendered, setFormRendered] = React.useState<boolean>(false);
  const [t] = useTranslation();
  const [, setActiveView, setLastView] = useActiveView();

  const initialCategory = useAsyncMemo(() => {
    if (initialCategoryId) {
      return Category.getCategory(initialCategoryId).then((cat) => {
        if (cat.isCategory) setCategory(cat);
        return cat;
      });
    }
  }, [initialCategoryId]);

  React.useEffect(() => {
    if (formRef?.current?.useForm) {
      formRef?.current?.useForm.reset({
        ...assignDefaultValueFormBaseobjectView(
          defaultValuesFormFieldType,
          formRef.current.view,
        ),
        ...defaultValues,
      });
    }
  }, [formRendered]);

  const isCategoryLoaded = category || (initialCategoryId && initialCategory);

  if (!isCategoryLoaded && initialCategoryId) return null;

  if (!category)
    return (
      <div className={'flex flex-col flex-grow gap-3 overflow-y-auto'}>
        <h2 className="text-xl md:text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
          {t('formLabel.selectACategory')}
        </h2>
        <div className="flex flex-col flex-grow">
          <CategorySelector
            onCategoryClicked={setCategory}
            startCategoryId={initialCategoryId}
            isCategoryDisabled={(cat) => {
              if (!cat.isWritable) return true;
              return cat.isCategory && !cat.canBeCreatedInFront;
            }}
            categoryDisabledReason={(cat) => {
              if (cat.isCategory && !cat.canBeCreatedInFront) {
                return t('categorySelector.categoryIsNotCreatable');
              }
              return t('categorySelector.notAllowedToCreate');
            }}
          />
        </div>
        <div className={'flex justify-end'}>
          <CloseButton onClick={onClose} />
        </div>
      </div>
    );
  const onClickSubmit = (): void => {
    formRef.current?.formRef?.requestSubmit();
  };

  const handleQuit = () => {
    onQuit(formRef.current, onClose, onClickSubmit);
  };

  const handleOnSuccessfulFormSubmission = (data?: { id: number }) => {
    onSuccessfulFormSubmission?.(data);
    onClose?.();
  };

  const callBackRef = (ref: BaseObjectFormViewRenderForwardedRef) => {
    setFormRendered(true);
    assignRef(formRef, ref);
  };

  const onSubmit: BaseObjectFormOnSubmit = (attributes) => {
    return BaseObject.createBaseObject({
      categoryId: category.id,
      attributes,
    }).then(handleOnSuccessfulFormSubmission);
  };

  const onExpand = (): void => {
    setActiveView({
      view: ViewName.CreationForm,
      params: {
        visible: true,
        initialCategoryId: initialCategoryId,
        defaultValues,
        defaultValuesFormFieldType,
        onClose: () => {
          setLastView();
        },
      },
    });
  };

  return (
    <BaseObjectViewContextProvider>
      <div data-testid={'quick-form'} className="flex flex-col flex-grow">
        <div className={'flex flex-grow overflow-y-auto'}>
          <AutoBaseObjectView
            ref={callBackRef}
            viewUsage={ViewUsage.QUICK_FORM}
            categoryId={category.id}
            onSubmit={onSubmit}
          />
        </div>
        <div className={'flex justify-end gap-3'}>
          <CloseButton onClick={handleQuit} />
          <IconButton icon={{ name: 'list-dropdown' }} onClick={onExpand}>
            {t('baseobjectQuickForm.openFullForm')}
          </IconButton>
          {formRef.current?.formRef ? (
            <SaveButton onClick={onClickSubmit} />
          ) : null}
        </div>
      </div>
    </BaseObjectViewContextProvider>
  );
};
