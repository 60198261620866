import { BaseSocketManager } from '@smack/core/api/clients/websocket/index';

export interface IProjectsEventSocketArgs {
  type: 'status_changed';
}

export class ProjectsEventSocketManager extends BaseSocketManager<IProjectsEventSocketArgs> {
  constructor(
    channel: string,
    callback: (args: IProjectsEventSocketArgs) => unknown,
  ) {
    super(`tasks/projects/${channel}/`, callback);
  }
}
