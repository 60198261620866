import { InfoAlert } from '@smack/core/components/DataDisplay/Alerts/AlertModal/InfoAlert';
import { copyVersionTag, getVersionTag } from '@smack/core/utils/VersionUtils';
import { useSW } from '@smack/core/utils/useSW';
import React from 'react';
import { useTranslation } from 'react-i18next';

// /!\ This component is instanced before any context providers
// So there is no access for context-driven features (Redux, Router, etc.)
// However, i18next is available since it's initialized without provider

export const FrontCrash: React.FC = () => {
  const [needRefresh, goToNextServiceWorker, setNeedRefresh] = useSW();
  const [showContinueButton, setShowContinueButton] = React.useState(false);

  const { t } = useTranslation();

  const footer = (
    <button
      type={'button'}
      className="text-end mb-[-1em] p-2 opacity-25 hover:opacity-100 transition-opacity text-xs text-text"
      onClick={copyVersionTag}
    >
      {getVersionTag()}
    </button>
  );

  if (needRefresh) {
    goToNextServiceWorker()
      .then(() => setShowContinueButton(true))
      .catch(() => setNeedRefresh(false));
    return (
      <InfoAlert
        onClick={showContinueButton ? window.location.reload : undefined}
        icon={{ name: 'download' }}
        title={t('alerts.frontUpdating.title')}
        text={t('alerts.frontUpdating.description')}
        buttonText={
          showContinueButton ? t('alerts.frontUpdating.buttonText') : undefined
        }
        isClosable={false}
        footer={footer}
      />
    );
  }

  return (
    <InfoAlert
      onClick={(): void => {
        window.location.assign('/');
      }}
      icon={{ name: 'face-scream' }}
      title={t('alerts.frontCrash.title')}
      text={t('alerts.frontCrash.description')}
      buttonText={t('alerts.frontCrash.buttonText')}
      isClosable={false}
      footer={footer}
    />
  );
};
