import {
  type IInputProps,
  type INativeInputProps,
  Input,
} from '@smack/core/components/DataInput/Input/Input';
import React, { type ForwardRefRenderFunction, forwardRef } from 'react';

export interface INumberInputProps extends Omit<IInputProps, 'onChange'> {
  onChange?: (data: number) => void;
}

export const NumberInputRender: ForwardRefRenderFunction<
  HTMLInputElement,
  INumberInputProps | INativeInputProps
> = ({ min, max, step, native, onChange, children, ...inputProps }, ref) => {
  const handleChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (e) => {
      if (onChange) {
        if (native) onChange(e);
        else onChange(e.target.valueAsNumber);
      }
    },
    [onChange, native],
  );

  return (
    <Input
      min={min}
      max={max}
      step={step}
      type="number"
      ref={ref}
      native
      onChange={handleChange}
      {...inputProps}
    >
      {children}
    </Input>
  );
};

export const NumberInput = forwardRef(NumberInputRender);
