import { User } from '@smack/core/api/models/users/User';
import type { IUser } from '@smack/core/store/users/types';
import { Group, type IGroup } from '../../users/Group';

export enum MessageRecipientIndication {
  TO = 'TO',
  CC = 'CC',
  BCC = 'BCC',
}

export interface IMessageRecipient {
  indication?: MessageRecipientIndication;
  user?: IUser;
  group?: IGroup;
  email?: string;
  phone?: string;
}

export class MessageRecipient {
  indication?: MessageRecipientIndication;

  user?: User;

  group?: Group;

  email?: string;

  phone?: string;

  constructor(data: IMessageRecipient) {
    this.indication = data.indication;
    if (data.user) {
      this.user = new User(data.user);
    }
    if (data.group) {
      this.group = new Group(data.group);
    }
    this.email = data.email;
    this.phone = data.phone;
  }
}
