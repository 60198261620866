import type { ViewElementRendererProps } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/type';
import { type FC, useMemo } from 'react';

export const DisplayedButtonViewElementRenderer: FC<
  ViewElementRendererProps
> = ({ viewElement, props }) => {
  const button = useMemo(() => viewElement.button, [viewElement]);

  if (!button) return null;

  return button.getButtonComponent(props);
};
