import type {
  ILinkDateRange,
  InputLink,
} from '@smack/core/api/models/objects/Link/Link';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { DateTimeRangeInput } from '@smack/core/components/DataInput/DateRangeInput/DateTimeRangeInput';
import { type FC, useCallback, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

interface LinkDateRangeInputProps {
  isWritable?: boolean;
  link: InputLink;
  onChange: (inputLink: Partial<InputLink>) => Promise<void>;
}

export const LinkDateRangeInput: FC<LinkDateRangeInputProps> = ({
  link,
  onChange,
  isWritable = true,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [value, setValue] = useState<ILinkDateRange | undefined>(
    link.datetimeRange,
  );
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();

  const onClickSubmit = useCallback(() => {
    setLoading(true);
    onChange({
      datetimeRange: value ?? undefined,
    })
      .then(() => {
        link.datetimeRange = value;
        setIsUpdating(false);
        setLoading(false);
      })
      .catch(() => {
        setValue(link.datetimeRange);
        toast.error(t('linkModal.errors.updateField'));
      });
  }, [value, link]);

  if (isUpdating) {
    return (
      <div className={'flex items-center '}>
        <DateTimeRangeInput
          value={{
            startDate: value?.dtstart ? new Date(value?.dtstart) : undefined,
            endDate: value?.dtend ? new Date(value?.dtend) : undefined,
          }}
          onChange={(newValue) => {
            setValue({
              dtstart: newValue.startDate?.toISOString() ?? '',
              dtend: newValue.endDate?.toISOString() ?? '',
            });
          }}
        />
        <button
          data-testid={'LinkDateRangeInput.submitButton'}
          onClick={onClickSubmit}
          type={'button'}
          className={
            'text-blue-500 cursor-pointer h-full flex items-center justify-center ml-3'
          }
        >
          <Icon
            icon={{ name: loading ? 'spinner-third' : 'check' }}
            animation={loading ? 'spin' : undefined}
          />
        </button>
      </div>
    );
  }

  const getDateString = (date?: string): string => {
    if (!date) return '-';
    return new Date(date).toLocaleDateString(t('dateTime.dateLocale'), {
      hour: 'numeric',
      minute: 'numeric',
    });
  };
  return (
    <div className={'flex items-center justify-center gap-3 '}>
      <p className="">
        {`${getDateString(link.datetimeRange?.dtstart)} - ${getDateString(
          link.datetimeRange?.dtend,
        )}`}
      </p>
      {isWritable ? (
        <Icon
          onClick={() => setIsUpdating(true)}
          icon={{ name: 'pen', familyStyle: 'fal' }}
          className={'text-xs text-gray-500 hover:text-gray-600 cursor-pointer'}
        />
      ) : null}
    </div>
  );
};
