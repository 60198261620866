import { NumberInput } from '@smack/core/components/DataInput/NumberInput/NumberInput';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { type Options, RRule } from 'rrule';

interface IProps {
  value?: RRule;
  onChange: (value: RRule) => void;
}

export const DailyRRule = (props: IProps): JSX.Element => {
  const [t] = useTranslation();
  const { value, onChange } = props;

  const handleChange = (val: Partial<Options>): void => {
    onChange(new RRule({ ...value?.origOptions, ...val }));
  };

  const defaultValue: Partial<Options> = {
    freq: RRule.DAILY,
    interval: 1,
    byweekday: [],
  };

  React.useEffect(() => {
    if (value?.options.freq !== RRule.DAILY) {
      onChange(new RRule(defaultValue));
    }
  }, [value]);

  const getnumber = (
    num: number[][] | number | number[] | string[] | undefined | null,
  ): number | string | undefined => {
    if (num && typeof num === 'number') {
      return num;
    }
    if (typeof num === 'string') {
      return num;
    }
    if (num && typeof num === 'object') {
      try {
        if (typeof num[0] === 'number') {
          return num[0];
        }
        return num[0][0];
      } catch {
        return undefined;
      }
    }
  };

  return (
    <div>
      <div className="flex items-center">
        <p className="mr-3 dark:text-text">{t('recurrence.every')}</p>
        <NumberInput
          size="small"
          value={getnumber(value?.options?.interval)}
          onChange={(val): void => handleChange({ interval: val as number })}
          min={1}
        />
        <p className="ml-3 dark:text-text">{t('recurrence.days')}</p>
      </div>
    </div>
  );
};
