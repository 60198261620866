import {
  type IToggleRefetchProjectsStatuses,
  REFETCH_PROJECTS_STATUSES,
} from './types';

export const toggleRefetchProjectStatuses = (
  refetch: boolean,
): IToggleRefetchProjectsStatuses => ({
  type: REFETCH_PROJECTS_STATUSES,
  payload: { refetch },
});
