import { createContext } from 'react';

export interface IBaseObjectPanelContext {
  carouselReloadIncrement: number;
  reloadCarousel: () => void;
}

/**
 * Context used to update deeply nested components
 */
export const BaseObjectPanelContext = createContext<IBaseObjectPanelContext>({
  // reload carousel through a callback coming from medias views
  carouselReloadIncrement: 0,
  reloadCarousel: () => {},
});
