import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';

interface IProps {
  icon: IconField;
  iconCircleColor?: string;
  iconColor?: string;
  label?: string;
  labelColor?: string;
  onClick?: () => void;
  className?: string;
}

/**
 * function to render dropdown items, (the dropdown can't accept the React component)
 * @param props IProps
 * @returns JSX.ELEMENT
 */
export const iconDropDownItem = (props: IProps): JSX.Element => {
  const {
    icon,
    iconColor,
    iconCircleColor,
    label,
    labelColor,
    onClick,
    className,
  } = props;

  return (
    <div
      key={label}
      onClick={(): void => {
        if (onClick) onClick();
      }}
      className={`py-3 px-3 flex items-center ${className || ''}
      ${onClick ? ' hover:bg-primary cursor-pointer' : ''}
      `}
    >
      <div
        style={iconCircleColor ? { backgroundColor: iconCircleColor } : {}}
        className=" w-5 h-5 rounded-full flex items-center justify-center mr-3"
      >
        <Icon color={iconColor || 'white'} className=" text-xs" icon={icon} />
      </div>
      <p style={labelColor ? { color: labelColor } : {}} className="text-sm">
        {label}
      </p>
    </div>
  );
};
