import type { MediaCategory } from '@smack/core/api/models/medias/MediaCategory';
import type { IMimetype } from '@smack/core/api/models/medias/Mimetype';
import {
  isElectronAvailable,
  openFile,
  openFolderInExplorer,
} from '@smack/core/utils/ElectronUtils';
import { t } from 'i18next';
import { toast } from 'react-hot-toast';

export interface INode {
  id?: number;
  uuid?: string;
  label: string;
  isFile: boolean;
  isFolder: boolean;
  children?: INode[];
  href?: string;
  mimetype?: IMimetype;
  category?: MediaCategory;
  isFavorite?: boolean;
  isExportable?: boolean;
  isThumbnail?: boolean;
  externalFilesystemId?: string | number;
}

export function openFileNode(data: INode) {
  if (!data.href) return;
  if (data.externalFilesystemId) {
    if (!isElectronAvailable()) {
      toast.error(t('medias.openLocalMediasError'));
      return;
    }
    openFile(data.href);
  } else {
    const handle = window.open(data.href);
    handle?.blur();
    window.focus();
  }
}

let throttled = false;

export function throttledOpenFileNode(data: INode) {
  if (throttled) return;
  throttled = true;
  setTimeout(() => {
    throttled = false;
  }, 1500);
  return openFileNode(data);
}

export function throttledOpenFolderNode(data: INode) {
  if (throttled) return;
  throttled = true;
  setTimeout(() => {
    throttled = false;
  }, 1500);
  if (!data.href) return;
  return openFolderInExplorer(data.href);
}
