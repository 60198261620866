import { Banner } from '@smack/core/components/DataDisplay/Banner';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { useSW } from '@smack/core/utils/useSW';
import type React from 'react';
import { useTranslation } from 'react-i18next';

export const ServiceWorkerReloader: React.FC = () => {
  const [needRefresh, updateServiceWorkerRegistration] = useSW();

  const { t } = useTranslation();

  if (!needRefresh) return null;

  return (
    <Banner>
      {t('serviceWorkerRegistration.updateIsNeeded')}
      <button
        type="button"
        onClick={updateServiceWorkerRegistration}
        className="font-bold flex items-center"
      >
        <Icon icon={{ name: 'arrow-rotate-right' }} />
        &nbsp;
        <span className="underline">
          {t('serviceWorkerRegistration.clickToUpgrade')}
        </span>
      </button>
    </Banner>
  );
};
