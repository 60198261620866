import { BaseAlert } from './Components/BaseAlert';
import { DangerAlert } from './Components/DangerAlert/DangerAlert';
import { InfoAlert } from './Components/Info';
import { SuccessAlert } from './Components/Success';
import { WarningAlert } from './Components/Warning';

export const SeverityAlertComponent = Object.freeze({
  info: InfoAlert,
  success: SuccessAlert,
  warning: WarningAlert,
  danger: DangerAlert,
});

export { BaseAlert, DangerAlert, InfoAlert, WarningAlert, SuccessAlert };
