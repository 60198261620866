import type { CalendarOptions } from '@fullcalendar/core';
import { getI18n } from 'react-i18next';

export interface ViewZoom {
  initialZoom: number;
  stepZoom: number;
  maxZoom: number;
  minZoom: number;
}

export const viewZoomByViews: Record<string, ViewZoom> = {
  timeGridDay: {
    initialZoom: 0.5,
    stepZoom: 0.25,
    maxZoom: 24,
    minZoom: 0.25,
  },
  timeGridWeek: {
    initialZoom: 0.5,
    stepZoom: 0.25,
    maxZoom: 24,
    minZoom: 0.25,
  },

  resourceTimelineDay: {
    initialZoom: 0.5,
    stepZoom: 0.25,
    maxZoom: 24,
    minZoom: 0.25,
  },
  resourceTimelineWeek: {
    initialZoom: 12,
    stepZoom: 6,
    maxZoom: 24,
    minZoom: 0,
  },
};

export const getCalendarViews = (zoom: number): CalendarOptions['views'] => {
  return {
    timeGridDay: {
      type: 'dayGrid',
      buttonText: getI18n().t('calendarTimeline.views.day'),

      duration: { days: 1 },
      slotDuration: { hours: zoom },
    },
    timeGridWeek: {
      type: 'dayGrid',
      duration: { weeks: 1 },
      slotDuration: { hours: zoom },
    },
    dayGridMonth: {
      type: 'dayGrid',
      duration: { months: 1 },
      fixedWeekCount: true,
    },
    dayGridYear: {
      type: 'dayGrid',
      duration: { years: 1 },
      buttonText: getI18n().t('calendarTimeline.views.year'),
    },
    resourceTimelineDay: {
      type: 'resourceTimeline',
      buttonText: getI18n().t('calendarTimeline.views.day'),
      duration: { days: 1 },
      slotDuration: { hours: zoom },
    },
    resourceTimelineWeek: {
      type: 'resourceTimeline',
      duration: { weeks: 1 },
      slotDuration: { hours: zoom },
    },
    resourceTimelineMonth: {
      type: 'resourceTimeline',
      weekNumbers: true,
      slotDuration: { days: 1 },
      duration: { months: 1 },
    },
    resourceTimelineYear: {
      type: 'resourceTimeline',
      buttonText: getI18n().t('calendarTimeline.views.year'),
      dateIncrement: { years: 1 },
      slotDuration: { months: 1 },
      visibleRange: (currentDate) => {
        return {
          start: new Date(currentDate.getFullYear(), 0, 1),
          end: new Date(currentDate.getFullYear() + 1, 0, 1),
        };
      },
    },
  };
};
