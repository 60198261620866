import type { ISelectOption } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import { t } from 'i18next';
import { RRule } from 'rrule';

export enum RecurenceMonths {
  JANUARY = 1,
  FEBRUARY = 2,
  MARCH = 3,
  APRIL = 4,
  MAY = 5,
  JUNE = 6,
  JULY = 7,
  AUGUST = 8,
  SEPTEMBER = 9,
  OCTOBER = 10,
  NOVEMBER = 11,
  DECEMBER = 12,
}

export enum RecurenceNumber {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FOURTH = 4,
  LAST = 5,
}

export enum RecurenceEnd {
  NEVER = 0,
  AFTER = 1,
  ONDATE = 2,
}

export const getRecurenceEndOptions = (): ISelectOption[] => {
  return [
    {
      label: t('recurrence.never'),
      value: RecurenceEnd.NEVER,
    },
    {
      label: t('recurrence.after'),
      value: RecurenceEnd.AFTER,
    },
    {
      label: t('recurrence.toDate'),
      value: RecurenceEnd.ONDATE,
    },
  ];
};

export const getRecurenceTypeOptions = (): ISelectOption[] => {
  return [
    {
      label: t('recurrence.annual'),
      value: RRule.YEARLY,
    },
    {
      label: t('recurrence.annual'),
      value: RRule.MONTHLY,
    },
    {
      label: t('recurrence.weekly'),
      value: RRule.WEEKLY,
    },
    {
      label: t('recurrence.daily'),
      value: RRule.DAILY,
    },
  ];
};

export const getRecurenceNumberStringOptions = (): ISelectOption[] => {
  return [
    {
      label: t('recurrence.first') ?? '',
      value: RecurenceNumber.FIRST,
    },
    {
      label: t('recurrence.second') ?? '',
      value: RecurenceNumber.SECOND,
    },
    {
      label: t('recurrence.third') ?? '',
      value: RecurenceNumber.THIRD,
    },
    {
      label: t('recurrence.fourth') ?? '',
      value: RecurenceNumber.FOURTH,
    },
    {
      label: t('recurrence.last') ?? '',
      value: RecurenceNumber.LAST,
    },
  ];
};

export const getRecurenceTypeMonth = (): ISelectOption[] => {
  return [
    {
      label: t('recurrence.monthOfYear.january') ?? '',
      value: RecurenceMonths.JANUARY,
    },
    {
      label: t('recurrence.monthOfYear.february') ?? '',
      value: RecurenceMonths.FEBRUARY,
    },
    {
      label: t('recurrence.monthOfYear.march') ?? '',
      value: RecurenceMonths.MARCH,
    },
    {
      label: t('recurrence.monthOfYear.april') ?? '',
      value: RecurenceMonths.APRIL,
    },
    {
      label: t('recurrence.monthOfYear.may') ?? '',
      value: RecurenceMonths.MAY,
    },
    {
      label: t('recurrence.monthOfYear.june') ?? '',
      value: RecurenceMonths.JUNE,
    },
    {
      label: t('recurrence.monthOfYear.july') ?? '',
      value: RecurenceMonths.JULY,
    },
    {
      label: t('recurrence.monthOfYear.august') ?? '',
      value: RecurenceMonths.AUGUST,
    },
    {
      label: t('recurrence.monthOfYear.september') ?? '',
      value: RecurenceMonths.SEPTEMBER,
    },
    {
      label: t('recurrence.monthOfYear.october') ?? '',
      value: RecurenceMonths.OCTOBER,
    },
    {
      label: t('recurrence.monthOfYear.november') ?? '',
      value: RecurenceMonths.NOVEMBER,
    },
    {
      label: t('recurrence.monthOfYear.december') ?? '',
      value: RecurenceMonths.DECEMBER,
    },
  ];
};

export const getRecurenceTypeDays = (): Option[] => {
  return [
    {
      label: t('recurrence.dayOfWeek.monday') ?? '',
      value: 0,
    },
    {
      label: t('recurrence.dayOfWeek.tuesday') ?? '',
      value: 1,
    },
    {
      label: t('recurrence.dayOfWeek.wednesday') ?? '',
      value: 2,
    },
    {
      label: t('recurrence.dayOfWeek.thursday') ?? '',
      value: 3,
    },
    {
      label: t('recurrence.dayOfWeek.friday') ?? '',
      value: 4,
    },
    {
      label: t('recurrence.dayOfWeek.saturday') ?? '',
      value: 5,
    },
    {
      label: t('recurrence.dayOfWeek.sunday') ?? '',
      value: 6,
    },
  ];
};

export const getDaysNumber = (): ISelectOption[] => {
  const output: ISelectOption[] = [];

  for (let i = 1; i < 32; i++) {
    output.push({
      label: i.toString(),
      value: i,
    });
  }
  return output;
};
