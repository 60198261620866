import type { ISelectOption } from '@smack/core/components/DataInput/SelectInput/SelectInput';

export const clamp = (num: number, min: number, max: number): number =>
  Math.min(Math.max(num, min), max);

export const generateSteps = (
  min: number,
  max: number,
  step: number,
): ISelectOption[] => {
  const options: ISelectOption[] = [];
  // Generate array of steps
  let i: number = min;
  while (i <= max) {
    options.push({
      label: i.toLocaleString(undefined, {
        minimumIntegerDigits: 2,
      }),
      value: i,
    });
    i += step;
  }
  return options;
};

export const generateTime = (hour?: number, minute = 0): string =>
  typeof hour === 'number'
    ? `${hour.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
      })}:${minute.toLocaleString('en-US', { minimumIntegerDigits: 2 })}`
    : '';
