import type {
  RecurrenceDelete,
  RecurrenceSave,
} from '@smack/core/api/models/objects/BaseObjectSchedule';
import {
  CancelButton,
  SaveButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { CheckboxInput } from '@smack/core/components/DataInput/CheckboxInput';
import { spawnModal } from '@smack/core/utils/modal';
import React, { useId } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  isDelete?: boolean;
  onClose: (skipCustom?: boolean) => void;
  onSave?: (type: RecurrenceSave | RecurrenceDelete) => void;
  actionPrompt?: string;
}

/**
 * Recurence Pop Up Components For choose beetween 'all' | 'next' | 'this'
 * @param props IProps
 * @returns JSX.Elements
 */
const RecurenceAlert = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const { onClose, onSave, actionPrompt, isDelete } = props;
  const [recsave, setrecsave] = React.useState<
    RecurrenceSave | RecurrenceDelete
  >(isDelete ? 'REMOVE_ONE' : 'REPLACE_ALL');
  const modalTitleId = useId();

  return (
    <div
      className="relative z-[1000]"
      aria-labelledby={modalTitleId}
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />

      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div className="relative bg-view rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
            <div className="bg-view px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                  <Icon icon={{ name: 'clock' }} className="text-blue-400" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <div className="flex justify-between">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200 small-caps"
                      id={modalTitleId}
                    >
                      {actionPrompt ? actionPrompt : `${t('edit.edit')} `}{' '}
                      {t('recurrence.periodicalEvent')}
                    </h3>
                  </div>
                  {!isDelete && (
                    <div className="my-5">
                      <CheckboxInput
                        className={{ container: 'mb-2' }}
                        label={t('recurrence.thisEvent')}
                        value={recsave === 'REPLACE_ONE'}
                        onChange={(): void => setrecsave('REPLACE_ONE')}
                      />
                      <CheckboxInput
                        className={{ container: 'mb-2' }}
                        label={t('recurrence.thisEventAndTheNextOnes')}
                        value={recsave === 'REPLACE_FUTURE'}
                        onChange={(): void => setrecsave('REPLACE_FUTURE')}
                      />
                      <CheckboxInput
                        label={t('recurrence.allTheEvents')}
                        value={recsave === 'REPLACE_ALL'}
                        onChange={(): void => setrecsave('REPLACE_ALL')}
                      />
                    </div>
                  )}
                  {isDelete && (
                    <div className="my-5">
                      <CheckboxInput
                        className={{ container: 'mb-2' }}
                        label={t('recurrence.thisEvent')}
                        value={recsave === 'REMOVE_ONE'}
                        onChange={(): void => setrecsave('REMOVE_ONE')}
                      />
                      <CheckboxInput
                        className={{ container: 'mb-2' }}
                        label={t('recurrence.thisEventAndTheNextOnes')}
                        value={recsave === 'REMOVE_FUTURE'}
                        onChange={(): void => setrecsave('REMOVE_FUTURE')}
                      />
                      <CheckboxInput
                        label={t('recurrence.allTheEvents')}
                        value={recsave === 'REMOVE_ALL'}
                        onChange={(): void => setrecsave('REMOVE_ALL')}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="bg-gray-50 dark:bg-neutral-700  px-4 py-3 sm:px-6 sm:flex justify-end">
              <SaveButton
                onClick={(): void => {
                  if (onSave) onSave(recsave);
                  onClose(true);
                }}
                className="mr-2"
              />

              <CancelButton onClick={(): void => onClose()} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const onSaveRecurentObject = (
  onSave: (type: RecurrenceSave | RecurrenceDelete) => void,
  handleCancel?: () => void,
  actionPrompt?: string,
  isDelete?: boolean,
): void => {
  spawnModal({
    render: ({ onClose }: IProps): JSX.Element => {
      return (
        <RecurenceAlert
          onClose={(notCanceled?: boolean): void => {
            if (handleCancel && !notCanceled) {
              handleCancel();
            }
            onClose();
          }}
          onSave={onSave}
          isDelete={isDelete}
          actionPrompt={actionPrompt}
        />
      );
    },
    // Escape does not trigger the custom on close methods, so disable it for now
  });
};
