import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import {
  CancelButton,
  ValidateButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { CheckboxInput } from '@smack/core/components/DataInput/CheckboxInput';
import { useNavigation } from '@smack/core/hooks/useNavigation/useNavigation';
import { toast } from 'react-hot-toast';

export interface IDuplicateBaseObjectProps {
  open: boolean;
  baseObject?: BaseObject;
  setOpen: (open: boolean) => void;
}

export const DuplicateBaseObjectModal: React.FC<IDuplicateBaseObjectProps> = ({
  open,
  setOpen,
  baseObject,
}) => {
  const [t] = useTranslation();
  const [copyAttributes, setCopyAttributes] = React.useState<boolean>(false);
  const [copyMedias, setCopyMedias] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const navigate = useNavigation();

  return (
    <Modal
      icon={{ name: 'copy' }}
      title={t('duplicateModal.title')}
      open={open}
      onClose={setOpen}
    >
      <p>{t('duplicateModal.elementsToDuplicate')}</p>
      <div className="ml-3 my-3">
        <CheckboxInput
          label={t('duplicateModal.elements.attributes')}
          id="duplicate-attributes-checkbox"
          value={copyAttributes}
          onChange={setCopyAttributes}
        />
        <CheckboxInput
          label={t('duplicateModal.elements.medias')}
          id="duplicate-medias-checkbox"
          value={copyMedias}
          onChange={setCopyMedias}
          className={{ container: 'mt-1' }}
        />
      </div>
      <div className="flex items-center justify-end">
        <CancelButton onClick={() => setOpen(false)} className="mr-2" />
        <ValidateButton
          onClick={async () => {
            baseObject
              ?.duplicate(copyAttributes, copyMedias)
              .then((results) => {
                toast.success(t('duplicateModal.successMessage'));
                BaseObject.getBaseObject(results.id).then((object) =>
                  navigate(object.frontEndpoint || '.', {
                    removeParams: ['schedule'],
                  }),
                );
              })
              .catch((res) => {
                setIsLoading(true);
                const error = res.response.data.errors[0];
                if (error?.detail?.includes('required')) {
                  toast.error(
                    t('duplicateModal.requiredAttributeErrorMessage'),
                  );
                } else {
                  toast.error(t('duplicateModal.errorMessage'));
                }
              })
              .finally(() => {
                setIsLoading(false);
                setOpen(false);
              });
          }}
          isLoading={isLoading}
          data-testid="DuplicateBaseObjectModalSaveButton"
        />
      </div>
    </Modal>
  );
};
