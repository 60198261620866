import { IconRoundedButton } from '@smack/core/components/Actions/Buttons/IconRoundedButton/IconRoundedButton';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface TextPreviewProps {
  text: string;
  maxLength: number;
  onClick: () => void;
  renderShortText: (text: string) => React.ReactNode;
}

const TextPreview: React.FC<TextPreviewProps> = ({
  text,
  maxLength,
  onClick,
  renderShortText,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center gap-1">
      {renderShortText(text)}
      {text.length > maxLength && (
        <IconRoundedButton
          title={t('textPreview.read')}
          icon={{ name: 'book-open', familyStyle: 'far' }}
          onClick={onClick}
        />
      )}
    </div>
  );
};

interface ModalLongTextProps {
  text: string;
  maxLength: number;
  renderShortText: (text: string) => React.ReactNode;
  renderLongText: (text: string) => React.ReactNode;
}

export const ModalLongText: React.FC<ModalLongTextProps> = ({
  text,
  maxLength,
  renderShortText,
  renderLongText,
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Modal open={open} onClose={setOpen}>
        {renderLongText(text)}
      </Modal>
      <TextPreview
        text={text}
        maxLength={maxLength}
        onClick={(): void => setOpen(true)}
        renderShortText={renderShortText}
      />
    </>
  );
};
