import type { LinkGroup } from '@smack/core/api/models/categories/LinkGroup';
import type { InputLink } from '@smack/core/api/models/objects/Link/Link';
import { IconButton } from '@smack/core/components/Actions/Buttons/Button';
import { LinkSelect } from '@smack/core/components/DataInput/LinkSelect/LinkSelect';
import type React from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface MultipleLinkSelectProps {
  linkGroup: LinkGroup;
  value?: (InputLink | null)[];
  filterAvailable?: boolean;
  onChange?: (value: (InputLink | null)[]) => void;
}

export const MultipleLinkSelect: React.FC<MultipleLinkSelectProps> = ({
  linkGroup,
  value,
  filterAvailable,
  onChange,
}) => {
  const [t] = useTranslation();
  const valueOrDefault = useMemo(
    () => (value?.length ? value : [null]),
    [value],
  );

  const onAdd = useCallback(
    (index: number) => {
      const newValue = [...valueOrDefault];
      newValue.splice(index, 0, null);
      onChange?.(newValue);
    },
    [valueOrDefault, onChange],
  );

  const onRemove = useCallback(
    (index: number) => {
      const newValue = [...valueOrDefault];
      newValue.splice(index, 1);
      onChange?.(newValue);
    },
    [valueOrDefault, onChange],
  );

  const handleChange = useCallback(
    (newValue: InputLink | null, index: number) => {
      const updatedValues = [...valueOrDefault];
      updatedValues[index] = newValue;
      onChange?.(updatedValues);
    },
    [valueOrDefault, onChange],
  );

  if (!linkGroup.isTimeSensitive && !linkGroup.isWeighted) {
    return (
      <LinkSelect
        linkGroup={linkGroup}
        multiple={true}
        filterAvailable={filterAvailable}
        value={value as InputLink[]}
        onChange={onChange as (value: InputLink[] | null) => void}
      />
    );
  }

  return (
    <div className="flex flex-col  gap-3">
      {valueOrDefault.map((val, i) => (
        <div
          className="flex gap-5 justify-between"
          key={`value-${val?.targetBaseobjectId}-${i}`}
        >
          <LinkSelect
            linkGroup={linkGroup}
            value={val}
            filterAvailable={filterAvailable}
            onChange={(value) => handleChange(value, i)}
          />
          <div className={'flex gap-1'}>
            <IconButton
              data-testid={'MultipleLinkSelect.unlink'}
              onClick={() => onRemove(i)}
              icon={{ name: 'unlink' }}
              title={t('baseObjectPanel.mainMenu.unlink')}
            />
            <IconButton
              data-testid={'MultipleLinkSelect.plus'}
              onClick={() => onAdd(i + 1)}
              icon={{ name: 'plus' }}
              title={t('buttons.add')}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
