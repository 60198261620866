import { FILE_STATES, useBatchProgressListener } from '@rpldy/uploady';
import {
  type IProgressBarProps,
  ProgressBar,
} from '@smack/core/components/DataDisplay/ProgressBar/ProgressBar';
import React from 'react';

export const UPLOAD_MAX_NUMBER_FILES = 100;

interface IProps extends Omit<IProgressBarProps, 'progress'> {
  unmountOnFinished?: boolean;
}

export const UploadProgressBar = ({
  color = 'green',
  unmountOnFinished,
  ...progressBarProps
}: IProps): JSX.Element | null => {
  const progressData = useBatchProgressListener();

  const uploadFinished = React.useMemo(() => {
    return (
      progressData?.items.every(
        (item) => item.state === FILE_STATES.FINISHED,
      ) || progressData?.items.some((item) => item.state === FILE_STATES.ERROR)
    );
  }, [progressData]);

  if (!progressData || (unmountOnFinished && uploadFinished)) return null;

  return (
    <ProgressBar
      {...progressBarProps}
      progress={Math.min(100, 100 * progressData.completed)}
      color={color}
    />
  );
};
