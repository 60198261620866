import type { User } from '@smack/core/api/models/users/User';
import {
  FETCH_CURRENTUSER,
  FETCH_USERS,
  type ISetCurrentUser,
  type ISetUsers,
  type IUser,
} from './types';

// Actions Creators

// set the current user
export const setCurrentUser = (user?: User): ISetCurrentUser => {
  return {
    type: FETCH_CURRENTUSER,
    payload: user,
  };
};

// set all users
export const setUsers = (users: IUser[]): ISetUsers => {
  return {
    type: FETCH_USERS,
    payload: users,
  };
};
