import type { ViewSection } from '@smack/core/api/models/views/ViewSection/ViewSection';
import { ElementListCard } from '@smack/core/components/DataDisplay/ElementListCard/ElementListCard';
import type { RenderedBaseObjectViewElement } from '@smack/core/components/ViewRenderer/interfaces';
import { PreviewElementRow } from '@smack/core/components/ViewRenderer/layouts/preview/PreviewElementRow';

interface PreviewSectionProps {
  section: ViewSection;
  elements: RenderedBaseObjectViewElement[];
}

export const PreviewSection: React.FC<PreviewSectionProps> = ({
  section,
  elements,
}) => {
  const rows = elements.map(([element, node]) => (
    <PreviewElementRow key={element.id} viewElement={element} value={node} />
  ));

  if (section.isHeadless) return <>{rows}</>;

  return (
    <ElementListCard title={section.label} icon={section.icon}>
      {rows}
    </ElementListCard>
  );
};
