import { RoomLink } from '@smack/core/views/oldViewsToSort/Views/Objects/ObjectLinkCalendar/ModeManagers/RoomLink';
import { TemporalLink } from '@smack/core/views/oldViewsToSort/Views/Objects/ObjectLinkCalendar/ModeManagers/TemporalLink';
import type { LinkCalendarModeManager } from '@smack/core/views/oldViewsToSort/Views/Objects/ObjectLinkCalendar/ModeManagers/type';

export enum LinkCalendarType {
  Room = 'Room',
  Temporal = 'Temporal',
}

export const getManagerByType = (
  type: LinkCalendarType,
): LinkCalendarModeManager => {
  if (type === LinkCalendarType.Room) return new RoomLink();

  return new TemporalLink();
};
