import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { Tooltip } from '@smack/core/components/DataDisplay/Tooltip';
import { focusIn } from '@smack/core/utils/FocusUtils';
import { type VariantProps, cva } from 'class-variance-authority';
import { type FC, type ReactNode, useEffect, useRef } from 'react';

interface IProps {
  children: ReactNode;
  label: ReactNode;
  helpTooltip?: string;
  error?: boolean;
  htmlFor?: string;
  muted?: boolean;
  autoFocus?: boolean;
}

const labelVariants = cva(
  [
    'flex gap-2 items-center sm:w-[35%] min-w-[35%] print:w-full',
    'text-sm print:text-base sm:mt-px select-none',
  ],
  {
    variants: {
      text: {
        normal: 'font-medium text-gray-700 dark:text-gray-200',
        error: 'font-medium text-red-600 dark:text-red-200',
        muted: 'font-light text-gray-500',
      },
    },
    defaultVariants: { text: 'normal' },
  },
);

export const DataFieldWrapper: FC<IProps> = ({
  children,
  label,
  helpTooltip,
  htmlFor,
  error = false,
  muted = false,
  autoFocus = false,
}): JSX.Element => {
  const fieldContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!autoFocus) return;
    if (!fieldContainer.current) return;
    const child = fieldContainer.current.children[1];
    if (!child) return;
    focusIn(child as HTMLElement);
  }, [autoFocus]);

  let labelTextVariant: VariantProps<typeof labelVariants>['text'] = 'normal';

  if (muted) {
    labelTextVariant = 'muted';
  }
  if (error) {
    labelTextVariant = 'error';
  }

  return (
    <div className="my-6 first:mt-0 print:my-0 sm:mt-5 space-y-6 sm:space-y-5 print:space-y-0 print:break-inside-avoid">
      <div
        className="flex md:items-center flex-col md:flex-row sm:border-t sm:border-gray-200 sm:pt-5 print:!pt-2 print:border-none dark:border-neutral-500"
        ref={fieldContainer}
      >
        <label
          htmlFor={htmlFor}
          className={labelVariants({ text: labelTextVariant })}
        >
          {label}
          {helpTooltip ? (
            <Tooltip title={helpTooltip} position="top">
              <span className="sr-only">{helpTooltip}</span>
              <Icon
                icon={{ name: 'info-circle', familyStyle: 'far' }}
                className="text-gray-400"
              />
            </Tooltip>
          ) : null}
        </label>
        {children}
      </div>
    </div>
  );
};
