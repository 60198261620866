import {
  CancelButton,
  ValidateButton,
} from '@smack/core/components/Actions/Buttons/Button';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import Color from 'color';
import { t } from 'i18next';
import React from 'react';

export interface IConfirmBaseProps {
  icon?: IconField;
  color?: string;
  title?: React.ReactNode;
  text?: React.ReactNode;
  securityText?: string;
  onCloseModal?: () => void;
  onCloseButton: () => void;
  onOk: () => void;
  okButton?: JSX.Element;
  closeButton?: JSX.Element;
  isLoading?: boolean;
}

export const ConfirmBaseAlert = (props: IConfirmBaseProps): JSX.Element => {
  const {
    title = '',
    text,
    onCloseButton,
    onOk,
    securityText,
    icon = { name: 'warning' },
    color = '#fee2e2',
    closeButton,
    okButton,
    onCloseModal,
    isLoading = false,
  } = props;

  const [securityInput, setSecurityInput] = React.useState('');
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    // Fire the animation
    setOpen(true);
  }, []);

  /**
   * on validate handler
   *
   * @return {*}  {void}
   */
  const onValidate = (e: React.MouseEvent): void => {
    e.preventDefault();
    if (securityText) {
      if (securityInput.toLowerCase() === securityText.toLowerCase()) {
        onOk();
      } else {
        return;
      }
    } else {
      onOk();
    }
    if (onCloseModal) {
      onCloseModal();
    }
  };

  const closeModal = (shouldOpen = false): void => {
    if (onCloseModal && !shouldOpen) {
      setOpen(shouldOpen);
      onCloseModal();
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      icon={icon}
      iconColor={Color(color).darken(0.5).hex()}
      color={color}
      title={title}
      customClassWrapper="md:max-w-lg"
    >
      <div className="text-sm text-gray-500 dark:text-gray-300">
        {text || ''}
      </div>
      {securityText && (
        <div className="mt-2">
          <p className="text-sm text-gray-500 dark:text-gray-200">
            {t('dataDisplayActions.confirmSecurityAction')}
            <span className="font-bold text-base">{securityText}</span>
          </p>
          <input
            type={'text'}
            value={securityInput}
            onChange={(e): void => setSecurityInput(e.target.value)}
            className={
              'shadow-sm border bg-view text-text focus:outline-none focus:border-blue-300 block w-full sm:text-sm border-gray-300 rounded-md py-1 px-2'
            }
            data-testid="confirmBaseAlertSecurityInput"
          />
        </div>
      )}
      <div className="py-3 sm:px-2 flex justify-end">
        {(!securityText ||
          (securityText &&
            securityInput.toLowerCase() === securityText.toLowerCase())) &&
          (okButton ? (
            <div onClick={onValidate} className="mr-2">
              {okButton}
            </div>
          ) : (
            <ValidateButton
              onClick={onValidate}
              className="mr-2"
              isLoading={isLoading}
              data-testid="confirmBaseAlertValidateButton"
            />
          ))}
        {closeButton ? (
          <div onClick={onCloseButton}>{closeButton}</div>
        ) : (
          <CancelButton onClick={onCloseButton} isLoading={isLoading} />
        )}
      </div>
    </Modal>
  );
};
