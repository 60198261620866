export interface ITemporaryFile {
  id: number;
  filename?: string;
  filepath?: string;
  content_type?: string;
}

export class TemporaryFile implements ITemporaryFile {
  id: number;

  filename?: string;

  filepath?: string;

  content_type?: string;

  constructor(data: ITemporaryFile) {
    this.id = data.id;
    this.filename = data.filename;
    this.filepath = data.filepath;
    this.content_type = data.content_type;
  }

  static uploadEndpoint = '/medias/temporary-files';
}
