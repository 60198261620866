import { BaseAlert, type IBaseAlertProps } from '../BaseAlert';

/**
 * Success Alert, for all succesfull event
 * @param props IBaseAlertProps
 * @returns JSX.Element
 */
export const SuccessAlert = (props: IBaseAlertProps): JSX.Element => {
  const { children, title, className } = props;
  return (
    <BaseAlert
      className={className}
      icon={{ name: 'check-circle' }}
      title={title || 'Succès'}
      color={'#D1E7DD'}
      textColor={'#109e43'}
    >
      {children}
    </BaseAlert>
  );
};
