import React, { type UIEvent, useState } from 'react';

type ScrollNavigationSectionOutput = [
  sectionActive?: string,
  onScroll?: (event: React.UIEvent<HTMLElement>) => void,
];

export const useScrollNavigationSection = (
  querySelector: string,
  container?: HTMLElement,
): ScrollNavigationSectionOutput => {
  const [sectionActive, setSectionActive] = useState<string>();

  const getElements = (): NodeListOf<HTMLElement> =>
    (container ?? document)?.querySelectorAll(querySelector);

  React.useLayoutEffect(() => {
    const firstElement = getElements()?.item(0)?.id;
    if (!sectionActive && firstElement) setSectionActive(firstElement);
  }, []);

  const handleScroll = React.useCallback(
    (event: UIEvent): void => {
      const elements = getElements();
      const scrollPosition = event.currentTarget.scrollTop;
      let newSection = '';

      elements.forEach((element, index) => {
        const elementTop = element.offsetTop - (container?.offsetTop || 0);
        const elementBottom = elementTop + element.offsetHeight;

        if (
          event.currentTarget.scrollHeight ===
          event.currentTarget.scrollTop + event.currentTarget.clientHeight
        ) {
          if (index === elements.length - 1) {
            newSection = element.id;
            setSectionActive(newSection);
          }
        }

        if (scrollPosition >= elementTop && scrollPosition < elementBottom) {
          newSection = element.id;
        }
      });

      if (newSection && newSection !== sectionActive) {
        setSectionActive(newSection);
      }
    },
    [container, sectionActive],
  );

  return [sectionActive, handleScroll];
};
