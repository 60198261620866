import type { ChecklistItem } from '@smack/core/api/models/tasks/ChecklistItem';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { MarkdownBlock } from '@smack/core/components/DataDisplay/MarkdownBlock';
import { ShowIfAllowed } from '@smack/core/components/DataDisplay/ShowIfAllowed';
import { useShowIfAllowedContext } from '@smack/core/components/DataDisplay/ShowIfAllowed/Context';
import { CheckboxInput } from '@smack/core/components/DataInput/CheckboxInput';
import { LoaderSkeleton } from '@smack/core/utils/Loader';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

interface IProps {
  checklistItem: ChecklistItem;
  updateProgress: () => void;
  reloadProject: () => Promise<void>;
  index: number;
}

export const ChecklistItemLine: React.FC<IProps> = ({
  checklistItem,
  updateProgress,
  reloadProject,
  index,
}) => {
  const [t] = useTranslation();

  const [isPending, setIsPending] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const { isWritable } = useShowIfAllowedContext();

  const isChecked = isPending
    ? !checklistItem.isChecked
    : checklistItem.isChecked;

  const toggleIsChecked = React.useCallback(() => {
    setIsPending(true);
    const promise = checklistItem.isChecked
      ? checklistItem.uncheck()
      : checklistItem.check();
    promise.finally(() => {
      updateProgress();
      setIsPending(false);
    });
  }, [isChecked, checklistItem]);

  const deleteChecklistItem = React.useCallback(() => {
    setIsLoading(true);
    checklistItem
      .delete()
      .then(reloadProject)
      .finally(() => {
        updateProgress();
        setIsLoading(false);
      });
  }, [checklistItem]);

  if (isLoading)
    return (
      <li className="py-1">
        <LoaderSkeleton height={20} width="100%">
          <rect x={0} y={0} rx={2.5} width={16} height={16} />
          <rect x={20} y={3} rx={1} width="calc(100% - 40px)" height={10} />
        </LoaderSkeleton>
      </li>
    );

  return (
    <Draggable draggableId={checklistItem.id.toString()} index={index}>
      {(provided): React.ReactElement => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="flex group gap-1.5 items-center relative text-text"
        >
          <ShowIfAllowed>
            <div
              {...provided.dragHandleProps}
              className="absolute -left-3 opacity-0 group-hover:opacity-70 transition-opacity"
            >
              <Icon icon={{ name: 'grip-dots-vertical' }} />
            </div>
          </ShowIfAllowed>
          <CheckboxInput
            value={isChecked}
            name={`checklist-item-${checklistItem.id}`}
            onChange={toggleIsChecked}
            disabled={!isWritable || isPending}
          />
          <div className="w-full">
            <label htmlFor={`checklist-item-${checklistItem.id}`}>
              <MarkdownBlock>{checklistItem.label ?? ''}</MarkdownBlock>
            </label>
          </div>
          <ShowIfAllowed>
            <button
              type="button"
              className="pl-2 invisible group-hover:visible"
              onClick={deleteChecklistItem}
              aria-label={t('delete.checklistItem')}
            >
              <Icon icon={{ name: 'trash-can' }} className="text-red-500" />
            </button>
          </ShowIfAllowed>
        </li>
      )}
    </Draggable>
  );
};
