import type { FC, ReactNode } from 'react';

export interface PropertyListElementProps {
  title: ReactNode;
  value: ReactNode;
}

export const PropertyListElement: FC<PropertyListElementProps> = ({
  title,
  value,
}) => {
  return (
    <div className="flex items-center justify-between gap-4">
      <p className="text-gray-500 font-medium">{title}</p>
      <div className="flex text-end flex-wrap">{value}</div>
    </div>
  );
};
