import React, {
  type FocusEvent,
  type KeyboardEvent,
  type MouseEvent,
  type ReactNode,
} from 'react';

import {
  type LinkGroup,
  PopulationType,
} from '@smack/core/api/models/categories/LinkGroup';
import { BaseObjectGroup } from '@smack/core/api/models/objects/BaseObjectGroup';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { DeleteConfirmAlert } from '@smack/core/components/DataDisplay/Alerts/ConfirmAlert';
import { ListingHeader } from '@smack/core/components/DataDisplay/Headers/ListingHeader/ListingHeader';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import type { DnDElementProps } from '@smack/core/components/DataDisplay/Lists/DnDList';
import { Tooltip } from '@smack/core/components/DataDisplay/Tooltip';
import { ViewName } from '@smack/core/hooks/views/types';
import { useActiveView } from '@smack/core/hooks/views/useActiveView/useActiveView';
import type { AppState } from '@smack/core/store';
import { toggleCalendarLinkGroup } from '@smack/core/store/objects/actions';
import { spawnModal } from '@smack/core/utils/modal';
import type { IAddLinkFormOutput } from '@smack/core/views/oldViewsToSort/Layouts/Forms/AddLinks/AddLinkForm/AddLinkForm';
import { AddLinks } from '@smack/core/views/oldViewsToSort/Layouts/Forms/AddLinks/AddLinks';
import type { LinkGroupWithCount } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Links/Components/LinksGroupContent/LinksGroupContent';
import { TypeRecurrenceUpdateChoice } from '@smack/core/views/oldViewsToSort/Layouts/Modal/RecurrenceUpdateChoiceObjectModal';
import { toast } from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface RenameFormProps {
  group: LinkGroup;
  onClose(newName?: string): void;
}

/**
 * Form replacing the label display on edit trigger
 */
const RenameForm = ({ group, onClose }: RenameFormProps): JSX.Element => {
  const cancelRename = (): void => {
    onClose();
  };

  const submitRename = (value: string): void => {
    if (value)
      group.patchLinkGroup(value).then((): void => {
        onClose(value);
      });
    else cancelRename();
  };

  const inputProps = {
    defaultValue: group.label,
    autoFocus: true,
    onBlur: (e: FocusEvent<HTMLInputElement>): void => {
      const value = e.currentTarget.value;
      if (value === '') cancelRename();
      else submitRename(value);
    },
    onKeyDown: (e: KeyboardEvent<HTMLInputElement>): void => {
      const value = e.currentTarget.value;
      switch (e.key) {
        case 'Enter':
          if (value === '') cancelRename();
          else submitRename(value);
          break;
        case 'Escape':
          cancelRename();
          break;
      }
    },
    onClick: (e: MouseEvent<HTMLInputElement>): void => {
      e.preventDefault();
      e.stopPropagation();
    },
  };
  return (
    <input
      type="text"
      {...inputProps}
      className="w-full h-6 mx-1 rounded focus:outline-none focus:border bg-primary focus:border-gray-400"
    />
  );
};

interface GroupHeaderProps extends DnDElementProps {
  group: LinkGroupWithCount;
  baseObject: BaseObject;
  active: boolean;
  onClick(): void;
  refetchBaseObjects(): void;
  reload?: () => void;
}

export const GroupHeader = ({
  drag,
  group: { linkGroup: group, count },
  baseObject,
  active,
  onClick,
  refetchBaseObjects,
  reload,
}: GroupHeaderProps): JSX.Element => {
  const dispatch = useDispatch();
  const { theme, selectedCalendarLinkGroups } = useSelector(
    (state: AppState) => ({
      theme: state.App.theme,
      selectedCalendarLinkGroups: state.Objects.selectedCalendarLinkGroups,
    }),
  );
  const [activeView] = useActiveView();
  const [isRenaming, setIsRenaming] = React.useState(false);
  const [isAdding, setIsAdding] = React.useState(false);
  const [t] = useTranslation();
  const onRename = (value: string): void => {
    if (value) {
      if (reload) reload();
      setIsRenaming(false);
    }
  };

  const getRenameButton = (): JSX.Element => {
    if (group.populationType === PopulationType.ROOM && !drag) {
      const title = t('links.renameFloor');
      return (
        <Tooltip title={title} offset={2}>
          <Icon
            icon={{ name: 'pen-circle' }}
            className={'text-gray-400 mr-1 text-xl hover:text-gray-500 '}
            onClick={(e): void => {
              e.stopPropagation();
              setIsRenaming(true);
            }}
          />
        </Tooltip>
      );
    }
    return <></>;
  };

  const linkGroupDeleteConfirmAlert = (): void => {
    const modalTitle: JSX.Element = <>{t('links.deleteLinkGroupandContent')}</>;
    const modalText: JSX.Element = (
      <p>
        <Trans t={t} i18nKey="links.deleteLinkGroupAndAssociatedLinks" />
      </p>
    );
    spawnModal({
      render: ({ onClose }) => {
        return (
          <DeleteConfirmAlert
            title={modalTitle}
            text={modalText}
            onCloseButton={onClose}
            securityText={t('delete.delete')}
            onOk={(): void => {
              group
                .deleteLinkGroup()
                .then(() => {
                  if (reload) reload();
                  toast.success(t('links.linkGroupSuccessfullyDeleted'));
                })
                .catch(() => toast.error(t('links.unableToDeleteTheLinkGroup')))
                .finally(onClose);
            }}
          />
        );
      },
    });
  };

  const getDeleteButton = (): JSX.Element => {
    if (group.populationType === PopulationType.ROOM && !drag) {
      const title = t('links.removeFloor');
      return (
        <Tooltip title={title} offset={2}>
          <div
            onClick={(e): void => {
              e.stopPropagation();
              linkGroupDeleteConfirmAlert();
            }}
            className="flex items-center justify-center bg-gray-400 text-white mr-1 text-[0.65rem] rounded-full w-5 aspect-square hover:bg-gray-500"
          >
            <Icon icon={{ name: 'trash-can' }} />
          </div>
        </Tooltip>
      );
    }
    return <></>;
  };

  const getToggleLinkGroupCalendarButton = (): ReactNode => {
    if (!group.isTimeSensitive) return null;

    const selectedGroups = selectedCalendarLinkGroups?.[baseObject.id];
    let title = '';
    if (!selectedGroups?.length) {
      title = t('links.displayCalendar');
    } else if (!selectedGroups.includes(group.id)) {
      title = t('links.addToCalendar');
    } else if (selectedGroups.length === 1) {
      title = t('links.displayFullCalendar');
    } else {
      title = t('links.removeFromCalendar');
    }

    return (
      <Tooltip key={title} title={title} offset={2}>
        <div
          onClick={(e): void => {
            e.stopPropagation();
            dispatch(toggleCalendarLinkGroup(baseObject.id, group.id));
          }}
          className="flex items-center justify-center bg-gray-400 text-white mr-1 text-[0.65rem] rounded-full w-5 aspect-square hover:bg-gray-500"
        >
          <Icon icon={{ name: 'calendar' }} />
        </div>
      </Tooltip>
    );
  };

  const getAddButton = (): JSX.Element => {
    if (
      (group.populationType === PopulationType.EXPLICIT_LINK ||
        group.populationType === PopulationType.ROOM) &&
      !drag
    ) {
      let title = t('links.createLink');
      if (group.populationType === PopulationType.ROOM) {
        title = t('links.createRoom');
      }
      return (
        <Tooltip title={title} offset={2}>
          <Icon
            icon={{ name: 'plus-circle' }}
            className={'text-gray-400 mr-3 text-xl hover:text-gray-500 '}
            onClick={(e): void => {
              if (active) {
                e.stopPropagation();
              }
              setIsAdding(true);
            }}
          />
        </Tooltip>
      );
    }
    return <></>;
  };

  const handleValidate = (data: IAddLinkFormOutput): void => {
    if (
      baseObject.baseobjectGroupId &&
      data.baseObjectGroupChoice !== TypeRecurrenceUpdateChoice.THIS
    ) {
      BaseObjectGroup.addLink(baseObject, group.id, data)
        .then(() => refetchBaseObjects())
        .catch(() => {
          toast('errorMessage.linkCreationFail');
        })
        .finally(() => setIsAdding(false));
    } else {
      baseObject
        .addLink(data)
        .catch(() => {
          toast('errorMessage.linkCreationFail');
        })
        .then(() => refetchBaseObjects())
        .finally(() => setIsAdding(false));
    }
  };

  return (
    <>
      {isAdding && (
        <AddLinks
          onQuit={(): void => setIsAdding(false)}
          sourceBaseObjectId={baseObject.id}
          scheduleId={baseObject.scheduleId}
          linkGroup={group}
          onSubmit={handleValidate}
          title={`${t('addLinkForm.title')} - ${baseObject?.title ?? ''}`}
          noClickOutside={true}
          open={isAdding}
          onClose={setIsAdding}
        />
      )}
      <ListingHeader
        dragProvider={drag}
        onClick={!drag ? onClick : undefined}
        icon={group.icon}
        count={count}
        headerTitle={
          isRenaming ? (
            <RenameForm group={group} onClose={onRename} />
          ) : (
            group.label
          )
        }
      >
        {activeView?.id === ViewName.ObjectCalendar &&
          getToggleLinkGroupCalendarButton()}
        {baseObject?.isWritable && getRenameButton()}
        {baseObject?.isWritable && getDeleteButton()}
        {baseObject?.isWritable && getAddButton()}
        {drag ? (
          <Icon
            icon={{ name: 'up-down' }}
            className="fa-fw text-gray-600 dark:text-gray-300"
          />
        ) : (
          <Icon
            icon={{ name: 'chevron-down' }}
            className={`fa-fw transition-transform ease-linear text-gray-600 dark:text-gray-300 ${
              active ? 'rotate-180' : ''
            }`}
          />
        )}
      </ListingHeader>
    </>
  );
};
