import { LinkGroup } from '@smack/core/api/models/categories/LinkGroup';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import {
  CancelButton,
  ValidateButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { NoContentMessage } from '@smack/core/components/DataDisplay/NoContentMessage';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import { LoaderSkeleton } from '@smack/core/utils/Loader';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  onSubmit: (data: number) => void;
  onCancel: () => void;
  parentObject: BaseObject;
  linkGroup: LinkGroup;
}

export const FloorForm: React.FC<IProps> = ({
  onSubmit,
  parentObject,
  linkGroup,
  onCancel,
}) => {
  const [isLoadingFloors, setIsLoadingFloors] = React.useState<boolean>(true);
  const [floors, setFloors] = React.useState<LinkGroup[]>([]);
  const [t] = useTranslation();
  const [floor, setFloor] = React.useState<Option | null>();
  const [hasInteracted, setHasInteracted] = React.useState<boolean>(false);

  const formattedFloors =
    floors?.map((f) => {
      return { label: f.label, value: f.id } as Option;
    }) || [];

  const floorSelect = formattedFloors.length ? (
    <SelectInput
      label={t('links.floor')}
      options={formattedFloors}
      value={floor ?? undefined}
      onChange={(e): void => {
        setFloor(e);
        setHasInteracted(true);
      }}
      error={hasInteracted && !floor ? t('addLinkForm.addFloor') : undefined}
    />
  ) : (
    <>
      <NoContentMessage
        icon={{ name: 'circle-exclamation' }}
        label={t('links.noFloors')}
      />
      <p className="text-gray-500 text-xs text-center">
        {t('links.createFloorBeforeAddingLink')}
      </p>
    </>
  );

  React.useEffect(() => {
    if (parentObject?.id && linkGroup?.sourceLinkGroup) {
      setIsLoadingFloors(true);
      LinkGroup.getLinkGroupsForFloor(
        parentObject?.id,
        linkGroup?.sourceLinkGroup,
      )
        .then(setFloors)
        .finally((): void => setIsLoadingFloors(false));
    }
  }, []);

  return (
    <div data-testid="floor-form">
      {isLoadingFloors ? (
        <LoaderSkeleton height={60} width={250}>
          <text x={0} y={14} fontSize={14} fontWeight={900}>
            {t('links.floor')}
          </text>
          <rect x={0} y={20} width={250} height={30} rx={4} />
        </LoaderSkeleton>
      ) : (
        floorSelect
      )}
      <div className="flex items-center justify-end mt-5 gap-3">
        <CancelButton onClick={onCancel} />
        <ValidateButton
          onClick={(): void => {
            if (!floor) return;
            onSubmit(floor.value as number);
          }}
          disabled={!floor}
        />
      </div>
    </div>
  );
};
