import { generateColors } from '@smack/core/utils/ColorUtils';
import type React from 'react';

export interface BarLoaderProps {
  animated?: boolean;
  color?: string;
}

export const BarLoader: React.FC<BarLoaderProps> = ({
  animated,
  color = '#7c7c7c',
}) => {
  return (
    <div
      data-testid={'loader-progress-bar'}
      style={{
        backgroundColor: color,
      }}
      className={'h-3 opacity-20 overflow-hidden rounded-lg  relative w-full'}
    >
      {animated ? (
        <div
          style={{
            backgroundColor: generateColors(color).secondary,
          }}
          data-testid={'loader-progress-bar-animated-bar'}
          className={'absolute left-0 top-0 bottom-0 animate-infinite-fill'}
        />
      ) : null}
    </div>
  );
};
