import { Basemap } from '@smack/core/api/models/maps/Basemap';
import { Layer } from '@smack/core/api/models/maps/Layer';
import type { AppDispatch } from '..';
import {
  FETCH_BASEMAPS,
  FETCH_LAYERS,
  type MapMode,
  type MapsActionType,
  type MapsState,
  SET_ACTIVE_BASEMAP,
  SET_ACTIVE_LAYERS,
  SET_LAYERS_ERROR,
  SET_POINTMODE,
} from './types';

// Actions Creators

export const setBasemap = (basemaps: Basemap[]): MapsActionType => {
  return {
    type: FETCH_BASEMAPS,
    payload: basemaps,
  };
};

export const setLayers = (layers: Layer[]): MapsActionType => {
  return {
    type: FETCH_LAYERS,
    payload: layers,
  };
};

export const setActiveBasemap = (basemapId: number): MapsActionType => {
  return {
    type: SET_ACTIVE_BASEMAP,
    payload: basemapId,
  };
};

export const setActiveLayers = (layers: Layer[]): MapsActionType => {
  return {
    type: SET_ACTIVE_LAYERS,
    payload: layers,
  };
};

export const setPointMode = (pointMode: MapMode): MapsActionType => {
  return {
    type: SET_POINTMODE,
    payload: pointMode,
  };
};

export const setLayerError = (
  error: MapsState['layersError'],
): MapsActionType => {
  return {
    type: SET_LAYERS_ERROR,
    payload: error,
  };
};

export const fetchBaseMap =
  () =>
  (dispatch: AppDispatch): void => {
    Basemap.getBasemap().then((result) => {
      dispatch(setBasemap(result));
    });
  };

export const fetchLayers =
  () =>
  (dispatch: AppDispatch): void => {
    Layer.getLayers().then((result) => {
      dispatch(setLayers(result));
    });
  };
