import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import {
  Category,
  type ICategory,
} from '@smack/core/api/models/categories/Category';
import {
  BaseObjectView,
  type IObjectViewListElement,
} from '@smack/core/api/models/views/BaseObjectView/BaseObjectView';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import type { AxiosResponse } from 'axios';

/**
 * interface for the population category
 *
 * @interface IPopulationCategory
 */
interface IPopulationCategory {
  id: number;
  isCategory: boolean;
  isModule: boolean;
  isSet: boolean;
}

export enum PopulationType {
  CALCULATED = 'CALCULATED',
  EXPLICIT_LINK = 'EXPLICIT_LINK',
  FLOOR = 'FLOOR',
  ROOM = 'ROOM',
}

export enum LinkableObjectsDisplay {
  LIST = 'LIST',
  LIST_WITH_CALENDAR = 'LIST_WITH_CALENDAR',
  TABLE = 'TABLE',
}

export enum AvailabilityFilter {
  NONE = 'NONE',
  THIS = 'THIS',
  ALL = 'ALL',
}

/**
 * interface for LinkGroup model
 *
 * @export
 * @interface ILinkGroup
 */
export interface ILinkGroup {
  id: number;
  label?: string;
  icon?: IconField;
  color?: string;
  populationCategory?: IPopulationCategory;
  populationType?: PopulationType;
  sourcePopulationType?: PopulationType;
  sourceLinkGroup?: number;
  isTimeSensitive?: boolean;
  isWeighted?: boolean;
  datetimeRangeLabel?: string;
  weightLabel?: string;
  linkableObjectsDisplay?: LinkableObjectsDisplay;
  linkableObjectsFullFilters?: boolean;
  linkableObjectsTextSearch?: boolean;
  linkableObjectsQuickFilters?: boolean;
  linkableObjectsTimeFilters?: boolean;
  linkableObjectsAvailabilityFilters?: AvailabilityFilter;
  isEmptyLinkGroupHidden?: boolean;
  isDirectObjectCreatable?: boolean;
  descriptorCategory?: ICategory;
}

/**
 * interface for the Root Group model
 *
 * @export
 * @interface ILinkRootGroup
 * @extends {ILinkGroup}
 */
export interface ILinkRootGroup extends ILinkGroup {
  children?: ILinkGroup[];
}

/**
 * class for the LinkGroup Model
 *
 * @export
 * @class LinkGroup
 */
export class LinkGroup {
  id: number;

  label?: string;

  icon?: IconField;

  color?: string;

  children?: LinkGroup[];

  populationCategory?: IPopulationCategory;

  populationType?: PopulationType;

  sourcePopulationType?: PopulationType;

  sourceLinkGroup?: number;

  isTimeSensitive?: boolean;

  isWeighted?: boolean;

  datetimeRangeLabel?: string;

  weightLabel?: string;

  linkableObjectsDisplay?: LinkableObjectsDisplay;

  linkableObjectsFullFilters?: boolean;

  linkableObjectsTextSearch?: boolean;

  linkableObjectsQuickFilters?: boolean;

  linkableObjectsTimeFilters?: boolean;

  linkableObjectsAvailabilityFilters?: AvailabilityFilter;

  isDirectObjectCreatable?: boolean;

  descriptorCategory?: Category;

  constructor(data: ILinkRootGroup) {
    this.id = data.id;
    this.label = data.label;
    this.icon = data.icon;
    this.color = data.color;
    this.populationCategory = data.populationCategory;
    this.populationType = data.populationType;
    this.sourcePopulationType = data.sourcePopulationType;
    this.sourceLinkGroup = data.sourceLinkGroup;
    this.isTimeSensitive = data.isTimeSensitive;
    this.isWeighted = data.isWeighted;
    this.datetimeRangeLabel = data.datetimeRangeLabel;
    this.weightLabel = data.weightLabel;
    this.linkableObjectsDisplay = data.linkableObjectsDisplay;
    this.linkableObjectsFullFilters = data.linkableObjectsFullFilters;
    this.linkableObjectsTextSearch = data.linkableObjectsTextSearch;
    this.linkableObjectsQuickFilters = data.linkableObjectsQuickFilters;
    this.linkableObjectsTimeFilters = data.linkableObjectsTimeFilters;
    this.isDirectObjectCreatable = data.isDirectObjectCreatable;
    this.linkableObjectsAvailabilityFilters =
      data.linkableObjectsAvailabilityFilters;
    if (data.children) {
      this.children = data.children.map((group) => new LinkGroup(group));
    }
    if (data.descriptorCategory) {
      this.descriptorCategory = new Category(data.descriptorCategory);
    }
  }

  static getLinkGroup(id: number): Promise<LinkGroup> {
    return RESTClient.get<{ data: { results: ILinkGroup } }>(
      `/categories/link-groups/${id}`,
    ).then((res) => new LinkGroup(res.data.results));
  }

  /**
   * getLinksGroup From baseObject Id
   *
   * @static
   * @param {number} categoryId
   * @return {*}  {Promise<LinkGroup[]>}
   * @memberof LinkGroup
   */
  static getLinkGroupsFromBaseObjectId(
    baseObjectId: number,
  ): Promise<LinkGroup[]> {
    return RESTClient.get<{ data: { results: ILinkRootGroup[] } }>(
      `/categories/link-groups?is-root-group=true&is-available-from-links-panel=true&baseobject=${baseObjectId}`,
    ).then((res) => res.data?.results.map((l) => new LinkGroup(l)));
  }

  /**
   * getLinksGroup From baseObject Id
   * @param baseObjectId
   * @return {Promise<LinkGroup[]>}
   */
  static getFloorLinkGroupsFromObjectId(
    baseObjectId: number,
  ): Promise<LinkGroup[]> {
    return RESTClient.get<{ data: { results: ILinkRootGroup[] } }>(
      `/categories/link-groups?is-root-group=true&baseobject=${baseObjectId}`,
    ).then((res) =>
      res.data?.results
        ?.filter((l) => l.populationType === PopulationType.FLOOR)
        .map((l) => new LinkGroup(l)),
    );
  }

  static getChildrenLinkGroupsFromLinkGroupIdAndBaseObjectId(
    baseObjectId: number,
    linkGroupId: number,
  ) {
    return RESTClient.get<{ data: { results: ILinkGroup[] } }>(
      '/categories/link-groups',
      {
        baseobject: baseObjectId,
        'parent-link-group': linkGroupId,
      },
    ).then((res) =>
      res.data?.results.map((linkGroup) => new LinkGroup(linkGroup)),
    );
  }

  static getChildrenExplicitLinkGroupsFromObjectId(
    baseObjectId: number,
  ): Promise<LinkGroup[]> {
    return RESTClient.get<{ data: { results: ILinkRootGroup[] } }>(
      `/categories/link-groups?is-root-group=false&baseobject=${baseObjectId}&explicit-link-only=true`,
    ).then((res) => res.data?.results?.map((l) => new LinkGroup(l)));
  }

  /**
   * getLinksGroup From Categorie Id
   *
   * @static
   * @param {number} categoryId
   * @return {*}  {Promise<LinkGroup[]>}
   * @memberof LinkGroup
   */
  static getLinkGroupsFromCategoryId(categoryId: number): Promise<LinkGroup[]> {
    return RESTClient.get<{ data: { results: ILinkRootGroup[] } }>(
      `/categories/link-groups?is-root-group=true&is-available-from-links-panel=true&category=${categoryId}`,
    ).then((res) => res.data?.results.map((l) => new LinkGroup(l)));
  }

  /**
   * getLinksGroup From Categorie Id only Explicit
   *
   * @static
   * @param {number} categoryId
   * @return {*}  {Promise<LinkGroup[]>}
   * @memberof LinkGroup
   */
  static getLinkGroupsFromCategoryIdOnlyExplicit(
    categoryId: number,
  ): Promise<LinkGroup[]> {
    return RESTClient.get<{ data: { results: ILinkRootGroup[] } }>(
      `/categories/link-groups?is-root-group=true&is-available-from-links-panel=true&category=${categoryId}&explicit-link-only=true`,
    ).then((res) => res.data?.results.map((l) => new LinkGroup(l)));
  }

  /**
   * getLinksGroup From Categorie Id only Explicit for form
   *
   * @static
   * @param {number} categoryId
   * @return {*}  {Promise<LinkGroup[]>}
   * @memberof LinkGroup
   */
  static getLinkGroupsFromCategoryIdOnlyExplicitForForm(
    categoryId: number,
  ): Promise<LinkGroup[]> {
    return RESTClient.get<{ data: { results: ILinkRootGroup[] } }>(
      `/categories/link-groups?is-root-group=true&is-available-from-location-form=true&category=${categoryId}&explicit-link-only=true`,
    ).then((res) => res.data?.results.map((l) => new LinkGroup(l)));
  }

  /**
   * getLinksGroup From BaseObject Id onlychild
   *
   * @static
   * @param {number} categoryId
   * @return {*}  {Promise<LinkGroup[]>}
   * @memberof LinkGroup
   */
  static getLinkGroupsFromBaseObjectIdForPanel(
    baseObjectId: number,
  ): Promise<LinkGroup[]> {
    return RESTClient.get<{ data: { results: ILinkRootGroup[] } }>(
      `/categories/link-groups?is-root-group=false&is-available-from-details=true&baseobject=${baseObjectId}`,
    ).then((res) => res.data?.results.map((l) => new LinkGroup(l)));
  }

  /**
   * getLinksGroup From BaseObject Id onlychild
   *
   * @static
   * @param {number} categoryId
   * @return {*}  {Promise<LinkGroup[]>}
   * @memberof LinkGroup
   */
  static getLinkGroupsFromBaseObjectIdForTask(
    baseObjectId: number,
  ): Promise<LinkGroup[]> {
    return RESTClient.get<{ data: { results: ILinkRootGroup[] } }>(
      `/categories/link-groups?is-root-group=false&is-available-from-task=true&baseobject=${baseObjectId}`,
    ).then((res) => res.data?.results.map((l) => new LinkGroup(l)));
  }

  static getChildLinkGroupFromBaseObjectId(
    baseObjectId: number,
  ): Promise<LinkGroup[]> {
    return RESTClient.get<{ data: { results: ILinkRootGroup[] } }>(
      '/categories/link-groups',
      {
        'is-root-group': false,
        baseobject: baseObjectId,
      },
    ).then((res) => res.data?.results.map((l) => new LinkGroup(l)));
  }

  /**
   * create floor link group
   *
   * @static
   * @param {number} baseobject
   * @param {string} label
   * @param {number} parentLinkGroup
   * @return {*}  {Promise<void>}
   * @memberof LinkGroup
   */
  static createFloorLinkGroup(
    baseobject: number,
    label: string,
    parentLinkGroup: number,
  ): Promise<AxiosResponse<void>> {
    return RESTClient.post(
      {
        icon: { name: 'stairs' },
        label: label,
        baseobject,
        parentLinkGroup,
        populationType: 'ROOM',
      },
      '/categories/link-groups',
    );
  }

  /**
   * Order link groups (=> set several link groups positions)
   * @static
   * @param newOrder Sorted array of links to order
   */
  static orderLinkGroups(newOrder: number[]): Promise<AxiosResponse<void>> {
    return RESTClient.post(
      {
        positions: Object.fromEntries(
          newOrder.map((linkGroupId, i) => [i, linkGroupId]),
        ),
      },
      '/categories/link-groups/order',
    );
  }

  /**
   * Get number of links of a given link group (and a source baseobject)
   * @param baseObjectId Source baseobject the link group should refer to
   */
  async getCountForBaseObject(baseObjectId: number): Promise<number> {
    const result = await RESTClient.get<{ data: { count: number } }>(
      `/objects/baseobjects/${baseObjectId}/link-groups/${this.id}/links?limit=1`,
    );
    return result.data.count;
  }

  addLink(
    sourceBaseobjectId: number,
    targetBaseobjectId: number,
  ): Promise<AxiosResponse<void>> {
    return RESTClient.post(
      {
        sourceBaseobjectId: sourceBaseobjectId,
        targetBaseobjectId: targetBaseobjectId,
        linkGroupId: this.id,
      },
      '/objects/links',
    );
  }

  /**
   * delete link group from id
   * @returns
   */
  deleteLinkGroup(): Promise<void> {
    return RESTClient.delete(`/categories/link-groups/${this.id}`);
  }

  /**
   * patch link group from id
   * @returns
   */
  patchLinkGroup(label: string): Promise<AxiosResponse> {
    return RESTClient.patch({ label }, `/categories/link-groups/${this.id}`);
  }

  /**
   * get link groups for floor
   * @param baseobjectId
   * @param parentLinkGroup
   * @returns
   */
  static getLinkGroupsForFloor(
    baseobjectId: number,
    parentLinkGroup: number,
  ): Promise<LinkGroup[]> {
    return RESTClient.get<{ data: { results: ILinkRootGroup[] } }>(
      `/categories/link-groups?is-root-group=false&parent-link-group=${parentLinkGroup}&baseobject=${baseobjectId}`,
    ).then((res) => res.data?.results.map((l) => new LinkGroup(l)));
  }

  /**
   * get full screen sections views link-groups definition
   * @param linkGroupId
   * @param viewId
   * @returns
   */
  static getLinkGroupViewDefinitionById(
    linkGroupId: number,
    viewId: number,
  ): Promise<BaseObjectView> {
    return RESTClient.get<{ data: { results: IObjectViewListElement } }>(
      `/categories/link-groups/${linkGroupId}/views/${viewId}`,
    ).then((res) => new BaseObjectView(res.data.results));
  }
}
