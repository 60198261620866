import { DangerAlert } from '@smack/core/components/DataDisplay/Alerts/Alerts';
import type { IBaseAlertProps } from '@smack/core/components/DataDisplay/Alerts/Alerts/Components/BaseAlert';

export const DevAlert = ({ children, ...alertProps }: IBaseAlertProps) => {
  if (!import.meta.env.DEV) return null;

  return (
    <DangerAlert {...alertProps}>
      {children}{' '}
      <span className="text-xs">
        (This alert shows only in dev environments.)
      </span>
    </DangerAlert>
  );
};
