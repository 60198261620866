import {
  type IReminderRule,
  ReminderRule,
} from '@smack/core/api/models/reminders/ReminderRule';

export interface ITaskReminderRule {
  label: string;
  reminderRule: IReminderRule;
  isDelayFromDeadline?: boolean;
}

export class TaskReminderRule extends ReminderRule {
  taskReminderLabel: string;

  isDelayFromDeadline?: boolean;

  constructor(data: ITaskReminderRule) {
    super(data.reminderRule);
    this.taskReminderLabel = data.label;
    this.isDelayFromDeadline = data.isDelayFromDeadline;
  }
}
