import { captureException } from '@sentry/react';
import Color from 'color';

type BlackNWhite = 'black' | 'white';

export interface IFamilyColor {
  primary: string;
  secondary: string;
  third: string;
  text: BlackNWhite;
}

/**
 * Create a color instance with a safe fallback to avoid uncatched exceptions
 * @param color Color string to parse
 * @returns A tuple with the color instance and a boolean indicating if a parsing error occured
 */
function safeColorInstance(color: string): [Color, boolean] {
  try {
    return [new Color(color), false];
  } catch (err) {
    // Send error to Sentry
    captureException(err, {
      level: 'warning',
      extra: {
        faultyColor: color,
      },
    });
    // Return black
    return [new Color(), true];
  }
}

export function determineTextColor(background: string): BlackNWhite {
  const [color] = safeColorInstance(background);
  if (color.alpha() !== 1.0) {
    const mixinColor =
      document.documentElement.dataset.theme === 'theme-light'
        ? 'white'
        : 'black';
    return color.mix(new Color(mixinColor)).isLight() ? 'black' : 'white';
  }
  return color.isLight() ? 'black' : 'white';
}

/**
 * Generate a color palette, for using it in sets display.
 *
 * @param initialColor RGB color code
 * @param fallback FamilyColor to fallback to if the color code can't be parsed
 */
export function generateColors(
  initialColor?: string,
  fallback?: string,
): IFamilyColor {
  // If no color is provided, try to provide fallback color
  // A color instance with no initial color will resolve to black
  // We want the fallback to be considered even if initialColor is not provided.
  const colorToUse = initialColor ?? fallback ?? 'black';
  const [color, error] = safeColorInstance(colorToUse);
  if (error) {
    return generateColors('black');
  }
  const secondary = color.desaturate(0.1).lighten(0.25);
  const third = color.darken(0.25);
  return {
    primary: color.hex(),
    secondary: secondary.hex(),
    third: third.hex(),
    text: determineTextColor(color.hex()),
  };
}

export function generateDuotone(colorInput?: string): [string, string] {
  const [color] = safeColorInstance(colorInput || 'black');
  const background = color.fade(0.85);
  return [color.hexa(), background.hexa()];
}
