import { Dialog } from '@headlessui/react';
import { CardIcon } from '@smack/core/components/DataDisplay/Card/CardIcon';
import { CardTitle } from '@smack/core/components/DataDisplay/Card/CardTitle';
import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import { ModalWrapper } from '@smack/core/components/DataDisplay/Modals/ModalWrapper/ModalWrapper';
import type React from 'react';

export interface IModalProps {
  open: boolean;
  onClose: (open: boolean) => void;
  children: React.ReactNode;
  icon?: IconField;
  color?: string;
  iconColor?: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  closeButton?: boolean;
  customClassWrapper?: string;
  customClassContent?: string;
  customClassTitle?: string;
  noClickOutside?: boolean;
}

export const Modal: React.FC<IModalProps> = ({
  open,
  onClose,
  icon,
  color,
  title,
  description,
  iconColor,
  children,
  closeButton = true,
  customClassWrapper,
  customClassContent,
  customClassTitle,
  noClickOutside = false,
}) => {
  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      customClassWrapper={customClassWrapper}
      noClickOutside={noClickOutside}
    >
      {(title || icon) && (
        <div
          className={`sm:flex sm:items-center gap-3   ${customClassTitle || ''}`}
        >
          {icon && (
            <CardIcon
              icon={icon}
              backGroundColor={color}
              iconColor={iconColor}
            />
          )}{' '}
          <CardTitle>{title}</CardTitle>
        </div>
      )}
      {description && (
        <div className="ml-[52px]">
          <Dialog.Description
            as="p"
            className="text-sm text-gray-700 dark:text-gray-200"
          >
            {description}
          </Dialog.Description>
        </div>
      )}
      <div className={`mt-4 ${customClassContent ?? 'px-3'}`}>{children}</div>
      {closeButton ? (
        <div className=" absolute top-0 right-0 pt-4 pr-4">
          <button
            data-testid={'Modal.closeButton'}
            type="button"
            className=" rounded-md text-gray-400 hover:text-gray-500 "
            onClick={(): void => onClose(false)}
          >
            <span className="sr-only">Close</span>
            <Icon
              icon={{ name: 'times' }}
              className="text-lg dark:text-gray-300"
            />
          </button>
        </div>
      ) : null}
    </ModalWrapper>
  );
};
