import type React from 'react';

interface ArticleSkeletonProps {
  lineNumber?: number;
}

export const ArticleSkeleton: React.FC<ArticleSkeletonProps> = ({
  lineNumber = 10,
}) => (
  <>
    <rect x={0} y={0} height={20} width={'30%'} rx={5} />
    {[...Array(10).keys()].map((n) => {
      const offset = (n + 1) * 25;
      return (
        <rect
          key={offset}
          x={0}
          y={offset + 20}
          height={12}
          width={'100%'}
          rx={5}
        />
      );
    })}
  </>
);
