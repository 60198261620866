import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import type React from 'react';

interface IProps extends Omit<React.HTMLProps<HTMLDivElement>, 'children'> {
  title: string;
  description?: string;
  icon?: IconField;
}

export const DataSectionTitle: React.FC<IProps> = ({
  title,
  description,
  icon,
  ...htmlProps
}) => {
  return (
    <>
      <div
        {...htmlProps}
        className={`text-lg py-6 text-gray-600 dark:text-gray-200 flex items-center ${
          htmlProps?.className ?? ''
        }`}
      >
        {icon && <Icon icon={icon} className="fa-fw mr-2" />}
        <h3 className="font-normal uppercase leading-6">{title}</h3>
      </div>
      {description && (
        <p className="mt-1 max-w-2xl text-sm text-gray-500 dark:text-gray-300">
          {description}
        </p>
      )}
    </>
  );
};
