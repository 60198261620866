import { DangerAlert } from '@smack/core/components/DataDisplay/Alerts/Alerts';
import type { ViewLayoutComponent } from '@smack/core/components/ViewRenderer/interfaces';
import { PreviewSection } from '@smack/core/components/ViewRenderer/layouts/preview/PreviewSection';
import { useTranslation } from 'react-i18next';

export const BaseObjectMapTooltipLayout: ViewLayoutComponent = ({
  renderedSections,
}) => {
  return (
    <div className="flex-grow flex w-full flex-col p-2 overflow-y-auto">
      {renderedSections.map(([section, elements], index) => {
        if (index === 0 && section.viewElements.length === 1) {
          const [, node] = elements[0];
          return (
            <div
              key={section.id}
              className="flex text-text text-md gap-1 py-2 pl-3"
            >
              {node}
            </div>
          );
        }
        return (
          <PreviewSection
            key={section.id}
            section={section}
            elements={elements}
          />
        );
      })}
    </div>
  );
};

BaseObjectMapTooltipLayout.Error = () => {
  const [t] = useTranslation();

  return (
    <DangerAlert title={t('viewErrors.viewNotAvailable')}>
      {t('viewErrors.viewNotAvailableDescription')}
    </DangerAlert>
  );
};
