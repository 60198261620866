import { useMergeRefs } from '@floating-ui/react';
import { Input } from '@smack/core/components/DataInput/Input/Input';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import {
  getPhoneCountriesAsOptions,
  phoneCountryToOption,
} from '@smack/core/utils/PhoneUtils';
import {
  AsYouType,
  getCountryCallingCode,
  parsePhoneNumberFromString,
} from 'libphonenumber-js';
import type { CountryCode } from 'libphonenumber-js/types';
import React, {
  type FormEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

export interface IPhoneInputProps {
  value?: string;
  initialValue?: string;
  onChange?: (value: string) => unknown;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  showMainCountriesOnly?: boolean;
  defaultCountry?: string;
  error?: string;
}

const PhoneInputRender: React.ForwardRefRenderFunction<
  HTMLInputElement,
  IPhoneInputProps
> = (
  {
    value,
    initialValue,
    onChange,
    onBlur,
    // Place France as the first option by default 🥖
    defaultCountry = 'FR',
    showMainCountriesOnly = true,
    error,
  },
  ref,
) => {
  const { t } = useTranslation();

  const parsedPhoneNumberFromValue = parsePhoneNumberFromString(
    value ?? initialValue ?? '',
  );

  const [countryOption, setCountryOption] = useState<Option<string>>(() =>
    phoneCountryToOption(
      parsedPhoneNumberFromValue?.country ?? (defaultCountry as CountryCode),
    ),
  );

  const asYouTypeFormatter = useMemo(
    () => new AsYouType(countryOption.value as CountryCode),
    [countryOption],
  );
  const [inputValue, setInputValue] = useState(() => {
    if (parsedPhoneNumberFromValue?.country === defaultCountry) {
      return parsedPhoneNumberFromValue.formatNational();
    }
    return asYouTypeFormatter.input(value ?? '');
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const setPhoneValue = (phoneNumber: string) => {
    asYouTypeFormatter.reset();
    const newInputValue = asYouTypeFormatter.input(phoneNumber);
    setInputValue(newInputValue);
    const phoneDetectedCountry = asYouTypeFormatter.getCountry();
    if (
      asYouTypeFormatter.isInternational() &&
      phoneDetectedCountry &&
      phoneDetectedCountry !== countryOption.value
    ) {
      // Set country code if user is inputting international number
      setCountryOption(phoneCountryToOption(phoneDetectedCountry));
    }
    return asYouTypeFormatter.getNumberValue() ?? '';
  };

  const handlePhoneValueInput = (
    event: FormEvent<HTMLInputElement> & { nativeEvent: InputEvent },
  ) => {
    onChange?.(setPhoneValue(event.currentTarget.value));
  };

  const handleCountryChange = useCallback(
    (option?: Option | Option[] | null) => {
      if (Array.isArray(option) || !option) return;
      setCountryOption(option as Option<CountryCode>);
      const countryCallingCode = getCountryCallingCode(
        (option as Option<CountryCode>).value,
      );
      setInputValue(`+${countryCallingCode}`);
      inputRef.current?.focus();
      inputRef.current?.setSelectionRange(
        Number.POSITIVE_INFINITY,
        Number.POSITIVE_INFINITY,
      );
    },
    [setCountryOption],
  );

  const availableOptions = useMemo(
    (): Option[] =>
      getPhoneCountriesAsOptions(defaultCountry, !showMainCountriesOnly),
    [defaultCountry, showMainCountriesOnly],
  );

  const mergedRefs = useMergeRefs([ref, inputRef]);

  if (
    typeof value === 'string' &&
    value !== (asYouTypeFormatter.getNumberValue() ?? '')
  ) {
    // Handle value changes
    const tester = new AsYouType();
    tester.input(value ?? '');
    if (asYouTypeFormatter.getNumberValue() !== tester.getNumberValue()) {
      setPhoneValue(value ?? '');
      const parsedPhoneNumber = parsePhoneNumberFromString(value);
      if (parsedPhoneNumber?.country === defaultCountry) {
        setInputValue(parsedPhoneNumber.formatNational() ?? '');
      }
    }
  }

  return (
    <div className="flex flex-col md:flex-row gap-1">
      <SelectInput
        width={'small'}
        isClearable={false}
        primaryColor="blue"
        options={availableOptions}
        value={countryOption}
        onChange={handleCountryChange}
        isMultiple={false}
        isSearchable={true}
        placeholder={t('country')}
        classNames={{
          container: 'relative w-full md:w-auto md:min-w-[20ch]',
        }}
      />
      <Input
        native
        type="tel"
        onInput={handlePhoneValueInput}
        onBlur={onBlur}
        value={inputValue}
        ref={mergedRefs}
        icon={{ icon: { name: 'phone' } }}
        iconSide="right"
        error={error}
      />
    </div>
  );
};

export const PhoneInput = React.forwardRef(PhoneInputRender);
