import { Combobox } from '@headlessui/react';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { Input } from '@smack/core/components/DataInput/Input/Input';
import type { ISelectOption } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import { useAutocompleteSearch } from '@smack/core/hooks/useAutocompleteSearch/useAutocompleteSearch';
import type { AppState } from '@smack/core/store';
import AddressService from '@smack/core/utils/Address';
import React, { type FC, type ReactNode } from 'react';
import { useSelector } from 'react-redux';

/**
 * Interface for the props for the addressinput components
 *
 * @export
 * @interface IAddressInputInterface
 */
export interface IAddressInputInterface {
  value?: string;
  disabled?: boolean;
  label?: string;
  error?: string;
  placeholder?: string;
  onChange?: (value: string | undefined) => void;
  prefix?: ReactNode;
  suffix?: ReactNode;
}

/**
 *  addressinput input components
 *
 * @export
 * @param {IAddressInputInterface} props
 * @return {*}  {JSX.Element}
 */
export const AddressInput: FC<IAddressInputInterface> = ({
  value,
  onChange,
  label,
  error,
  disabled,
  prefix,
  suffix,
}): JSX.Element => {
  const [searchString, setSearchString] = React.useState('');
  const { settings } = useSelector((app: AppState) => app.App);

  const [latitude, longitude] = settings?.mapLocation?.coordinates ?? [];

  const [options, isLoading] = useAutocompleteSearch(
    searchString,
    (search, abort) => {
      if (!settings?.addressDatabaseUrl) return Promise.resolve([]);

      const service = new AddressService(settings.addressDatabaseUrl, abort);
      if (latitude && longitude)
        return service.searchAddressAroundLatLong(search, latitude, longitude);

      return service.searchAddress(search);
    },
    (item) => ({ value: item.label, label: item.label }),
    250,
  );

  const handleChange = (val: string | undefined): void => {
    if (onChange && !disabled) onChange(val);
  };

  const getOptionComponent = (option: ISelectOption): JSX.Element => {
    /**
     * Return the className for text options
     *
     * @param {boolean} active
     * @return {*}  {string}
     */

    return (
      <Combobox.Option
        key={option.value}
        className={({ active }): string =>
          `${
            active ? 'text-white bg-blue-400' : 'text-gray-900'
          } cursor-default select-none relative py-2 pl-3 pr-9`
        }
        value={option.value}
      >
        {({ selected, active }): JSX.Element => {
          const textColor = `${
            active ? 'text-white' : 'text-indigo-600'
          } absolute inset-y-0 right-0 flex items-center pr-4`;
          return (
            <div className="flex items-center">
              {/* if option have an image */}
              {option.image && (
                <img
                  src={option.image}
                  alt=""
                  className="flex-shrink-0 h-6 w-6 rounded-full mr-3"
                />
              )}
              {/* if option have an icon */}
              {option.icon && (
                <Icon
                  color={option.color}
                  className="mr-3"
                  icon={option?.icon || 'question'}
                />
              )}
              {/* if option have an icon but no color */}
              {!option.icon && option.color && (
                <span
                  style={{ backgroundColor: option.color }}
                  className=" mr-3 flex-shrink-0 inline-block h-2 w-2 rounded-full"
                />
              )}
              <span
                className={`
                          ${selected ? 'font-semibold' : 'font-normal'} block`}
              >
                {option?.label}
              </span>
              {/* if selected show an check icon */}
              {selected ? (
                <span className={textColor}>
                  <Icon icon={{ name: 'check' }} />
                </span>
              ) : null}
            </div>
          );
        }}
      </Combobox.Option>
    );
  };

  return (
    <Combobox value={value} onChange={handleChange} nullable>
      <Combobox.Input
        as={Input}
        native
        label={label}
        error={error}
        icon={{ icon: { name: 'location-dot' } }}
        prefix={prefix}
        suffix={suffix}
        onChange={(e): void => setSearchString(e.currentTarget.value)}
        onInput={(e): void => setSearchString(e.currentTarget.value)}
      >
        {!disabled && (
          <Combobox.Options className="absolute z-10 mt-1 w-full max-w-lg sm:max-w-xs bg-view shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
            {searchString.length > 0
              ? getOptionComponent({
                  value: searchString,
                  label: searchString,
                })
              : null}
            {options?.map(getOptionComponent)}
          </Combobox.Options>
        )}
      </Combobox.Input>
    </Combobox>
  );
};
