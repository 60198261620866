import type React from 'react';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

/**
 * wrap the task table content
 * @param props IPRops
 * @returns JSX.ELEMENT
 */
export const TableContent = (props: IProps): JSX.Element => {
  const { children, className } = props;

  return (
    <div
      className={`relative w-full bg-white dark:bg-neutral-800 rounded-b-lg ${
        className || ''
      }`}
    >
      {children}
    </div>
  );
};
