import type { Task } from '@smack/core/api/models/tasks/Task';
import { ActionDisplay } from '@smack/core/components/DataDisplay/Actions';
import { NoContentMessage } from '@smack/core/components/DataDisplay/NoContentMessage';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

interface IProps {
  task: Task;
  reloadProject: () => void;
}

/**
 * Actions Component for Task ViewName
 * @returns JSX.Element
 */
export const Actions: React.FC<IProps> = ({ task, reloadProject }) => {
  const { t } = useTranslation();
  const { object } = useParams();

  return (
    <div className="flex w-full flex-col items-center justify-center text-center p-3">
      {task.taskActions?.map((action) => (
        <ActionDisplay
          key={action.id}
          objectId={object || ''}
          taskId={task.id || ''}
          action={action}
          reloadCallback={reloadProject}
          hideLastLine
        />
      ))}
      {!task.taskActions?.length && (
        <NoContentMessage
          className="my-5"
          icon={{ name: 'bolt' }}
          label={t('actions.none')}
        />
      )}
    </div>
  );
};
