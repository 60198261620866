import { IconRoundedButton } from '@smack/core/components/Actions/Buttons/IconRoundedButton/IconRoundedButton';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import type { IListElementAction } from '@smack/core/components/DataDisplay/Lists/ListElements/BaseListElement';
import { env } from '@smack/core/env';
import type { ReactNode } from 'react';
import { useState } from 'react';
import type React from 'react';
import { LeftIconContainer } from '../LeftIconContainer';

export interface ListElementLayoutProps {
  arrowColor?: string;
  arrowIcon?: IconField;
  title: ReactNode;
  subtitle: ReactNode;
  badge: ReactNode;
  bottomLeft: ReactNode;
  bottomRight: ReactNode;
  overlayIcons?: IListElementAction[];
}

export const ListElementLayout: React.FC<ListElementLayoutProps> = ({
  arrowColor,
  arrowIcon,
  title,
  subtitle,
  badge,
  bottomLeft,
  bottomRight,
  overlayIcons,
}) => {
  const [showIcons, setShowIcons] = useState(false);
  // @TODO upon VITE_DISPLAY_OVERLAY_ICONS_ON_LINKS removal make sure to correctly handle the cases
  const conditionForRenderingOverlayIcons =
    env.VITE_DISPLAY_OVERLAY_ICONS_ON_LINKS && overlayIcons?.length;

  const overlayActions = conditionForRenderingOverlayIcons
    ? {
        onMouseEnter: () => setShowIcons(true),
        onMouseLeave: () => setShowIcons(false),
      }
    : {};

  return (
    <>
      <div className={'z-20'} {...overlayActions}>
        <LeftIconContainer color={arrowColor} icon={arrowIcon} />
      </div>
      <div className="w-full flex flex-col justify-center gap-2 break-all text-text">
        <div className="text-m grid grid-cols-6 justify-between w-full">
          <div
            className={`font-medium ${conditionForRenderingOverlayIcons ? 'cursor-pointer' : ''} ${
              badge ? 'col-span-4' : 'col-span-6'
            } truncate`}
            {...overlayActions}
          >
            {title}
          </div>
          {badge && (
            <div className="font-medium col-span-2 justify-self-end">
              {badge}
            </div>
          )}
          {subtitle && (
            <div
              className={`flex text-sm font-normal col-span-6 truncate ${conditionForRenderingOverlayIcons ? 'cursor-pointer' : ''}`}
              {...overlayActions}
            >
              {subtitle}
            </div>
          )}
          <div className="mt-1 h-full col-span-6 flex items-center justify-between gap-3">
            <div className="text-gray-500 dark:text-gray-300 truncate ">
              {bottomLeft}
            </div>
            <div className="mt-1 text-gray-500 dark:text-gray-300 truncate min-w-fit max-w-[50%]">
              {bottomRight}
            </div>
          </div>
        </div>
      </div>
      {conditionForRenderingOverlayIcons ? (
        <div
          data-testid="ListElementActionsOverlayContent"
          className={`[&&]:cursor-default w-full right-0 flex gap-6 h-full absolute items-center justify-center bg-gray-100 dark:bg-gray-700 opacity-0 bg-opacity-70 transition-opacity duration-100 [&]:invisible ${
            showIcons
              ? 'bg-opacity-90 opacity-100 [&&]:visible dark:bg-gray-700'
              : ''
          }`}
          {...overlayActions}
        >
          {Array.isArray(overlayIcons) &&
            overlayIcons.map((iconAction, index) => (
              <IconRoundedButton
                key={iconAction.label}
                label={iconAction.label}
                icon={iconAction.icon}
                onClick={iconAction.onClick}
                className="z-20"
              />
            ))}
        </div>
      ) : null}
    </>
  );
};
