import { cx } from 'class-variance-authority';
import type { ComponentProps, FC } from 'react';

export const MutedInput: FC<ComponentProps<'button'>> = ({
  children,
  ...props
}) => (
  <button
    className={cx(
      'block max-w-lg sm:max-w-xs bg-gray-50 dark:bg-view',
      'dark:bg-gray-700 text-gray-500 sm:text-sm cursor-text border rounded-md p-2',
      'focus:ring-blue-400 focus:border-blue-400 shadow-sm flex-1',
    )}
    type="button"
    {...props}
  >
    {children}
  </button>
);
