import type { Project } from '@smack/core/api/models/tasks/Project/Project';
import type { Status } from '@smack/core/api/models/tasks/Status';
import type { TaskRule } from '@smack/core/api/models/tasks/TaskRule';
import { createContext, useContext } from 'react';

export interface ITasksContext {
  project: Project;
  statuses: Status[];
  taskRules: TaskRule[];
}

export const TasksContext = createContext<Partial<ITasksContext>>({});

export const useTasksContext = (): Partial<ITasksContext> =>
  useContext(TasksContext);
