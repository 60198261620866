import type { Components } from 'react-markdown';

export const Anchor: Components['a'] = ({ children, ...props }) => (
  <a
    {...props}
    className="text-blue-500 hover:text-blue-600"
    // biome-ignore lint/a11y/useValidAnchor: Anchor will be valid when parsed by Markdown.
    onClick={(e): void => e.stopPropagation()}
    target="_blank"
  >
    {children}
  </a>
);
