import { viewList } from '@smack/core/hooks/views/ViewList/ViewList';
import type { View } from '@smack/core/hooks/views/types';
import type { AppState } from '@smack/core/store';
import {
  setCurrentView,
  setLastViewAsCurrent,
} from '@smack/core/store/app/actions';
import type { ViewActive } from '@smack/core/store/app/types';
import { useDispatch, useSelector } from 'react-redux';

type UseActiveViewOutput = [
  currentView: View | undefined,
  setCurrentView: (view: ViewActive<unknown>) => void,
  setLastViewAsCurrent: () => void,
  viewParams: ViewActive<unknown>['params'],
  isLastViewAvailable: boolean,
];

export const useActiveView = (): UseActiveViewOutput => {
  const { current, viewHistory } = useSelector(
    (app: AppState) => app.App.currentView,
  );
  const dispatch = useDispatch();

  const currentView = viewList?.find((item) => item.id === current?.view);

  const setView = (view: ViewActive<unknown>): void => {
    dispatch(setCurrentView(view));
  };

  const setLastView = (): void => {
    dispatch(setLastViewAsCurrent());
  };

  return [
    currentView,
    setView,
    setLastView,
    current?.params,
    !!viewHistory?.length,
  ];
};
