import { IconButton } from '@smack/core/components/Actions/Buttons/Button';
import {
  Dropzone,
  type IDropzoneProps,
} from '@smack/core/components/Actions/Dropzone';
import {
  type ISnapshotCameraPropsProps,
  SnapshotCamera,
} from '@smack/core/components/Actions/SnapshotCamera';
import { t } from 'i18next';
import React from 'react';

/**
 * Input properties of the media source component.
 */
interface IProps {
  source?: Source;
  showSwitchButton?: boolean;
  dropzoneProps?: IDropzoneProps;
  snapshotCameraProps?: ISnapshotCameraPropsProps;
}

/**
 * Creates a `MediaSource` component
 * to switch between webcam and file drop.
 *
 * @param props - The input properties of the component. Default values:
 * - `source`: `'drop'`
 * @returns The created `MediaSource` component.
 */
export const MediaSource = ({
  source = 'drop',
  showSwitchButton,
  dropzoneProps,
  snapshotCameraProps,
}: IProps): JSX.Element => {
  const [mediaSource, setMediaSource] = React.useState<Source>(source);

  const onToggleMediaSource = (): void => {
    setMediaSource((value: Source) => (value === 'drop' ? 'webcam' : 'drop'));
  };

  return (
    <div className="flex flex-col gap-2">
      {mediaSource === 'drop' ? (
        <Dropzone {...dropzoneProps} />
      ) : (
        <SnapshotCamera {...snapshotCameraProps} />
      )}
      {showSwitchButton ? (
        <IconButton
          className="w-fit"
          icon={{ name: 'repeat' }}
          onClick={onToggleMediaSource}
        >
          {mediaSource === 'drop'
            ? t('medias.fileSourceSwitch_webcam')
            : t('medias.fileSourceSwitch_drop')}
        </IconButton>
      ) : null}
    </div>
  );
};

/**
 * The media sources.
 */
export type Source = 'drop' | 'webcam';
