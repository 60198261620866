import type { Reducer } from 'redux';
import {
  CLEAR_MODAL,
  type ModalActionsType,
  type ModalState,
  SET_MODAL,
} from './types';

// Initial state
const initialState: ModalState = {
  current: undefined,
};

// Modal reducer
export const modalReducer: Reducer<ModalState, ModalActionsType> = (
  state: ModalState | undefined,
  action: ModalActionsType,
): ModalState => {
  if (!state) return initialState;

  if (action.type === SET_MODAL || action.type === CLEAR_MODAL) {
    return {
      current: action.payload,
    };
  }
  return state;
};
