import { Group } from '@smack/core/api/models/tasks/Group';
import { Task } from '@smack/core/api/models/tasks/Task';
import { Gantt, type Task as GanttTask, ViewMode } from 'gantt-task-react';
import React, { useEffect } from 'react';
import 'gantt-task-react/dist/index.css';
import type { Checklist } from '@smack/core/api/models/tasks/Checklist';
import { IconButton } from '@smack/core/components/Actions/Buttons/Button';
import { WarningAlert } from '@smack/core/components/DataDisplay/Alerts/Alerts';
import { CheckboxInput } from '@smack/core/components/DataInput/CheckboxInput';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import { DateUtils } from '@smack/core/utils/DateUtils';
import { TaskListHeader } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/GanttProject/components/TaskListHeader';
import { TaskListTable } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/GanttProject/components/TaskListTable';
import { TooltipContent } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/GanttProject/components/TooltipContent';
import { useTranslation } from 'react-i18next';
import '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/GanttProject/GanttProject.scss';
import type { Project } from '@smack/core/api/models/tasks/Project/Project';
import { GanttEditModal } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/GanttProject/components/GanttEditModal';

interface IProps {
  project: Project;
  reloadProject: () => Promise<void>;
}

export const GanttProject: React.FC<IProps> = ({ project, reloadProject }) => {
  const { t } = useTranslation();

  const getTaskProgress = (task: Task): number => {
    if (task.status?.isCompleted) return 100;
    const checklists: Checklist[] = task.checklists || [];
    if (checklists.length === 0) return 0;
    let taskProgress = 0;
    for (const checklist of checklists) {
      if (checklist.items.length === 0) taskProgress += 100;
      else {
        taskProgress +=
          (100 *
            checklist.items.filter((item) => item.isChecked === true).length) /
          checklist.items.length;
      }
    }
    return taskProgress / checklists.length;
  };

  const getColumnWidth = (timeScale: ViewMode) => {
    if (timeScale === ViewMode.Year) return 400;
    if (timeScale === ViewMode.Month) return 300;
    if (timeScale === ViewMode.Week) return 250;
    return 75;
  };

  const getTaskDates = (
    task: Task,
    previousEndDate: Date,
    defaultStartDate: Date,
    defaultTaskDuration: number = 60 * 60 * 1000 * 24,
    defaultDateDelta = 1000 * 60 * 60 * 12,
  ): [Date, Date] => {
    const startDate =
      task.startAt?.toDate() ||
      task.estimatedStartAt?.toDate() ||
      (previousEndDate === defaultStartDate
        ? defaultStartDate
        : new Date(previousEndDate.getTime() + defaultDateDelta));
    const endDate = task.isMilestone
      ? startDate
      : task.endAt?.toDate() ||
        new Date(
          startDate.getTime() +
            (DateUtils.parseDuration(task.duration)?.asMilliseconds() ||
              defaultTaskDuration),
        );
    return [startDate, endDate];
  };

  const updateTaskTemporality = async (ganttTask: GanttTask) => {
    const taskId = Number.parseInt(ganttTask.id.split(' ')[1]);
    const task = new Task({ id: taskId });
    await task.patch({
      startAt: ganttTask.start.toISOString(),
      endAt: ganttTask.end.toISOString(),
    });
    let newGanttTasks = ganttTasks.map((task) =>
      task.id === ganttTask.id ? ganttTask : task,
    );

    const groupGanttTask = ganttTasks.find(
      (task) => task.id === ganttTask.project,
    );
    if (groupGanttTask) {
      const [start, end] = getGanttTasksTemporality(
        newGanttTasks.filter((task) => task.project === groupGanttTask.id),
      );
      if (
        start &&
        end &&
        (groupGanttTask.start.getTime() !== start.getTime() ||
          groupGanttTask.end.getTime() !== end.getTime())
      ) {
        const groupId = Number.parseInt(groupGanttTask.id.split(' ')[1]);
        const group = new Group({ id: groupId });
        await group.patch({
          startAt: start.toISOString(),
          endAt: end.toISOString(),
        });
        groupGanttTask.start = start;
        groupGanttTask.end = end;
        newGanttTasks = newGanttTasks.map((task) =>
          task.id === groupGanttTask.id ? groupGanttTask : task,
        );
      }
    }

    setGanttTasks(newGanttTasks);

    const [start, end] = getGanttTasksTemporality(
      ganttTasks.filter((task) => task.type === 'project'),
    );
    if (
      start &&
      end &&
      (!project.startAt ||
        !project.endAt ||
        project.startAt.toDate().getTime() !== start.getTime() ||
        project.endAt.toDate().getTime() !== end.getTime())
    ) {
      await project.patch({
        startAt: start.toISOString(),
        endAt: end.toISOString(),
      });
    }
    await reloadProject();
  };

  const getGanttTasksTemporality = (
    ganttTasks: GanttTask[],
  ): [Date, Date] | [undefined, undefined] => {
    if (ganttTasks.length === 0) return [undefined, undefined];
    let startTime = ganttTasks[0].start.getTime();
    let endTime = ganttTasks[0].end.getTime();
    for (const task of ganttTasks) {
      startTime = Math.min(startTime, task.start.getTime());
      endTime = Math.max(endTime, task.end.getTime());
    }
    return [new Date(startTime), new Date(endTime)];
  };

  const projectToGanttTasks = (): GanttTask[] => {
    const tasks: GanttTask[] = [];
    const projectStartDate = project.startAt?.toDate() || new Date();
    for (const iGroup of project.groups || []) {
      const group = new Group(iGroup);
      let groupProgress = 0;
      const groupGanttTasks: GanttTask[] = [];
      const groupTasks: Task[] = (group.tasks || [])
        .sort((a, b) => (a.position || 0) - (b.position || 0))
        .filter((task) => !task.status || !task.status.isHidden);
      const groupStartDate = group.startAt?.toDate() || projectStartDate;
      let previousEndDate = groupStartDate;
      let maxEndDate = groupStartDate;

      for (const task of groupTasks) {
        const taskProgress = getTaskProgress(task);
        groupProgress += taskProgress;
        const [startDate, endDate] = getTaskDates(
          task,
          previousEndDate,
          groupStartDate,
        );
        groupGanttTasks.push({
          start: startDate,
          end: endDate,
          name: task.label || '',
          id: `Task ${task.id}`,
          type: task.isMilestone ? 'milestone' : 'task',
          styles: {
            progressColor: task.status?.color,
            backgroundColor: task.status?.color,
            backgroundSelectedColor: task.status?.color,
            progressSelectedColor: task.status?.color,
          },
          project: `Group ${group.id}`,
          progress: taskProgress,
          isDisabled: true,
        });
        if (endDate.getTime() > maxEndDate.getTime()) {
          maxEndDate = endDate;
        }
        previousEndDate = endDate;
      }
      tasks.push({
        start: groupStartDate,
        end: maxEndDate,
        name: group.label || '',
        id: `Group ${group.id}`,
        type: 'project',
        styles: {
          progressColor: group.status?.color,
          backgroundColor: group.status?.color,
          backgroundSelectedColor: group.status?.color,
          progressSelectedColor: group.status?.color,
        },
        progress: groupProgress / (group.tasks?.length || groupProgress),
        isDisabled: true,
        hideChildren: false,
      });
      tasks.push(...groupGanttTasks);
    }
    return addDependencies(tasks);
  };

  const addDependencies = (tasks: GanttTask[]): GanttTask[] => {
    let previousTaskId = '';
    for (const task of tasks) {
      if (task.type !== 'project') {
        if (previousTaskId) task.dependencies = [previousTaskId];
        previousTaskId = task.id;
      } else {
        previousTaskId = '';
      }
    }
    return tasks;
  };

  const removeDependencies = (tasks: GanttTask[]): GanttTask[] => {
    return tasks.map((task) => {
      task.dependencies = [];
      return task;
    });
  };

  const isTemporalitySet = (): boolean => {
    if (!project.startAt) return false;
    for (const iGroup of project.groups || []) {
      const group = new Group(iGroup);
      if (!group.startAt) return false;
      for (const task of group.tasks || []) {
        if (
          (!task.startAt && !task.estimatedStartAt) ||
          (!task.endAt && !task.duration)
        )
          return false;
      }
    }
    return true;
  };

  const setDiagramIsDisabled = (isDisabled: boolean) => {
    setGanttTasks(
      ganttTasks.map((task) => {
        if (task.type !== 'project') task.isDisabled = isDisabled;
        return task;
      }),
    );
  };

  const viewModeOptions: Option<ViewMode>[] = [
    {
      label: t('tasks.gantt.viewMode.hour'),
      value: ViewMode.Hour,
    },
    {
      label: t('tasks.gantt.viewMode.quarterDay'),
      value: ViewMode.QuarterDay,
    },
    {
      label: t('tasks.gantt.viewMode.halfDay'),
      value: ViewMode.HalfDay,
    },
    {
      label: t('tasks.gantt.viewMode.day'),
      value: ViewMode.Day,
    },
    {
      label: t('tasks.gantt.viewMode.week'),
      value: ViewMode.Week,
    },
    {
      label: t('tasks.gantt.viewMode.month'),
      value: ViewMode.Month,
    },
    {
      label: t('tasks.gantt.viewMode.year'),
      value: ViewMode.Year,
    },
  ];

  const [activeViewModeOption, setActiveViewModeOption] = React.useState<
    Option<ViewMode>
  >({
    label: t('tasks.gantt.viewMode.day'),
    value: ViewMode.Day,
  });

  const [projectId, setProjectId] = React.useState<number>(project.id);
  const [ganttTasks, setGanttTasks] = React.useState<GanttTask[]>(
    projectToGanttTasks(),
  );
  const [showDependencies, setShowDependencies] = React.useState<boolean>(true);
  const [showTemporalityMessage, setShowTemporalityMessage] =
    React.useState<boolean>(!isTemporalitySet());
  const [columnWidth, setColumnWidth] = React.useState<number>(
    getColumnWidth(ViewMode.Day),
  );
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = React.useState<boolean>(false);
  const [isLoaded, setIsLoaded] = React.useState<boolean>(false);

  useEffect(() => {
    if (project.id !== projectId) {
      setProjectId(project.id);
      setEditMode(false);
      setGanttTasks(projectToGanttTasks());
      setShowTemporalityMessage(!isTemporalitySet());
    }
  }, [project]);

  useEffect(() => setIsLoaded(true), []);

  return (
    <div id="gantt-diagram" className="absolute right-4 left-4">
      {openEditModal && (
        <GanttEditModal
          open={openEditModal}
          setOpen={setOpenEditModal}
          onContinue={() => {
            setEditMode(true);
            setDiagramIsDisabled(false);
          }}
        />
      )}
      {showTemporalityMessage && (
        <WarningAlert>{t('tasks.gantt.temporalityMessage')}</WarningAlert>
      )}
      <div className="inline-flex items-center gap-5 mb-3 mt-3">
        <SelectInput
          options={viewModeOptions}
          isClearable={false}
          isSearchable={false}
          className="w-[200px]"
          onChange={(selected): void => {
            const viewModeSelected = selected as Option<ViewMode>;
            setActiveViewModeOption(viewModeSelected);
            setColumnWidth(getColumnWidth(viewModeSelected.value));
          }}
          value={activeViewModeOption}
        />
        <CheckboxInput
          label={t('tasks.gantt.dependencies')}
          onChange={(value) => {
            setShowDependencies(value);
            setGanttTasks(
              value
                ? addDependencies(ganttTasks)
                : removeDependencies(ganttTasks),
            );
          }}
          value={showDependencies}
        />
        <IconButton
          icon={{ name: editMode ? 'eye' : 'pen' }}
          className="whitespace-nowrap"
          onClick={() => {
            if (editMode) {
              setDiagramIsDisabled(true);
              setEditMode(false);
            } else setOpenEditModal(true);
          }}
        >
          {editMode ? t('tasks.gantt.lookMode') : t('edit.edit')}
        </IconButton>
      </div>
      <Gantt
        tasks={ganttTasks}
        viewMode={activeViewModeOption.value}
        viewDate={project.startAt?.toDate() || new Date()}
        locale={t('dateTime.dateLocale')}
        preStepsCount={1}
        TooltipContent={TooltipContent}
        TaskListTable={TaskListTable}
        TaskListHeader={TaskListHeader}
        arrowIndent={12}
        onExpanderClick={(task) =>
          setGanttTasks(ganttTasks.map((t) => (t.id === task.id ? task : t)))
        }
        fontFamily=""
        listCellWidth={isLoaded ? '250px' : ''}
        columnWidth={columnWidth}
        onDateChange={(task) => updateTaskTemporality(task)}
      />
    </div>
  );
};
