import type React from 'react';
import { useImperativeHandle, useRef } from 'react';
import { forwardRef } from 'react';

import type { AppState } from '@smack/core/store';
import { Editor } from '@tinymce/tinymce-react';
import type { IAllProps as IEditorProps } from '@tinymce/tinymce-react';
import { uuid } from '@tinymce/tinymce-react/lib/es2015/main/ts/Utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

type BaseButtonType =
  | 'aligncenter'
  | 'alignjustify'
  | 'alignleft'
  | 'alignnone'
  | 'alignright'
  | 'blockquote'
  | 'blocks'
  | 'bold'
  | 'copy'
  | 'cut'
  | 'fontfamily'
  | 'fontsize'
  | 'fontsizeinput'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'hr'
  | 'indent'
  | 'italic'
  | 'language'
  | 'lineheight'
  | 'newdocument'
  | 'outdent'
  | 'paste'
  | 'pastetext'
  | 'print'
  | 'redo'
  | 'remove'
  | 'removeformat'
  | 'selectall'
  | 'strikethrough'
  | 'styles'
  | 'subscript'
  | 'superscript'
  | 'underline'
  | 'undo'
  | 'visualaid';

type ListButtonType = 'bullist' | 'numlist';

type LinkButtonType = 'link';

type FullScreenButtonType = 'fullscreen';

type ButtonType =
  | BaseButtonType
  | ListButtonType
  | LinkButtonType
  | FullScreenButtonType;

export class SimpleButtonGroup {
  buttons: ButtonType[];

  constructor(buttons: ButtonType[]) {
    this.buttons = buttons;
  }

  toString(): string {
    return this.buttons.map((b) => b.toString()).join(' ');
  }
}

export class FloatingButtons {
  name: string;

  tooltip: string;

  icon: string;

  buttonGroups: SimpleButtonGroup[];

  constructor(
    tooltip: string,
    icon: string,
    buttonGroups: SimpleButtonGroup[],
  ) {
    this.name = uuid('');
    this.tooltip = tooltip;
    this.icon = icon;
    this.buttonGroups = buttonGroups;
  }

  toString(): string {
    return this.name;
  }
}

export class ButtonGroup {
  buttons: (ButtonType | FloatingButtons)[];

  constructor(buttons: (ButtonType | FloatingButtons)[]) {
    this.buttons = buttons;
  }

  toString(): string {
    return this.buttons.map((b) => b.toString()).join(' ');
  }
}
export class ToolBar {
  buttonGroups: (ButtonGroup | SimpleButtonGroup)[];

  constructor(buttonGroups: (ButtonGroup | SimpleButtonGroup)[]) {
    this.buttonGroups = buttonGroups;
  }

  toString(): string {
    return this.buttonGroups.map((b) => b.toString()).join(' | ');
  }
}

export type ToolBarMode = 'floating' | 'sliding' | 'scrolling' | 'wrap';

export interface IWysiwygInputProps {
  defaultValue?: string;
  value?: string;
  height?: number | string;
  width?: number | string;
  menubar?: boolean;
  name?: string;
  toolbar?: ToolBar;
  toolbarMode?: ToolBarMode;
  plugins?: IEditorProps['plugins'];
  id?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  disabled?: boolean;
  fullscreen?: boolean;
}

const getFloatingButtonGroups = (
  toolbar: ToolBar | undefined,
): Record<string, { icon: string; tooltip: string; items: string }> => {
  if (!toolbar) return {};

  const groups = toolbar.buttonGroups.filter(
    (group) => group instanceof ButtonGroup,
  ) as ButtonGroup[];

  const floatingGroups = groups.flatMap((group) =>
    group.buttons.filter((button) => button instanceof FloatingButtons),
  ) as FloatingButtons[];

  return Object.fromEntries<{ icon: string; tooltip: string; items: string }>(
    floatingGroups.map((floating) => [
      floating.name,
      {
        icon: floating.icon,
        tooltip: floating.tooltip,
        items: floating.buttonGroups
          .map((group) => group.toString())
          .join(' | '),
      },
    ]),
  );
};

const WysiwygInput: React.ForwardRefRenderFunction<
  { focus: () => void },
  IWysiwygInputProps
> = (
  {
    defaultValue,
    value,
    height = 500,
    width,
    menubar = false,
    toolbar: toolbarProp,
    toolbarMode = 'floating' as ToolBarMode,
    id,
    name,
    onChange,
    onBlur,
    fullscreen = false,
  },
  ref,
) => {
  const { t } = useTranslation();

  const theme = useSelector((state: AppState) => state.App.theme);

  const toolbar =
    toolbarProp ??
    new ToolBar([
      new ButtonGroup(['undo', 'redo']),
      new ButtonGroup(['blocks']),
      new ButtonGroup([
        'bold',
        'italic',
        new FloatingButtons(t('wysiwyg.moreFormattingOptions'), 'more-drawer', [
          new SimpleButtonGroup(['strikethrough', 'removeformat']),
        ]),
      ]),
      new ButtonGroup([
        'bullist',
        new FloatingButtons(t('wysiwyg.moreIndentOptions'), 'more-drawer', [
          new SimpleButtonGroup(['numlist', 'indent', 'outdent']),
        ]),
      ]),
      new ButtonGroup(fullscreen ? ['link', 'fullscreen'] : ['link']),
    ]);

  const editorRef = useRef<Editor>(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        focus() {
          try {
            editorRef.current?.editor?.focus();
          } catch (err) {}
        },
      };
    },
    [],
  );

  return (
    <Editor
      id={id}
      textareaName={name}
      initialValue={defaultValue}
      value={value}
      plugins="link lists autolink fullscreen"
      tinymceScriptSrc="/tinymce/tinymce.min.js"
      ref={editorRef}
      onClick={() => document.dispatchEvent(new CustomEvent('click'))}
      init={{
        height: height,
        width: width,
        menubar: menubar,
        toolbar: toolbar?.toString(),
        toolbar_groups: getFloatingButtonGroups(toolbar),
        toolbar_mode: toolbarMode,
        branding: false,
        language: 'fr_FR',
        language_url: '/lang/tinymce/fr_FR.js',
        skin: theme === 'dark' ? 'oxide-dark' : 'oxide',
        content_css: theme === 'dark' ? 'dark' : 'default',
        link_default_target: '_blank',
        link_target_list: false,
        relative_urls: false,
        convert_urls: false,
      }}
      onEditorChange={(content): void => {
        if (onChange) {
          onChange(content);
        }
      }}
      onBlur={onBlur}
    />
  );
};

const forwardedWysiwygInput = forwardRef(WysiwygInput);

export { forwardedWysiwygInput as WysiwygInput };
