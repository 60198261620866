import { UploadButton } from '@rpldy/upload-button';
import { UploadDropZone } from '@rpldy/upload-drop-zone';
import { useUploadyContext } from '@rpldy/uploady';
import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../DataDisplay/Tooltip';
import { type ITab, TabController } from '../TabController/TabController';
import { UPLOAD_MAX_NUMBER_FILES } from '../UploadProvider/UploadProvider';

export interface IDropzoneProps {
  icon?: IconField;
  className?: string;
  multiple?: boolean;
  allowedMimetypes?: string[];
}

export const Dropzone = (props: IDropzoneProps): JSX.Element => {
  const { className, icon, multiple, allowedMimetypes } = props;
  const { t } = useTranslation();
  const dragTargetsRef = React.useRef<EventTarget[]>([]);
  const [dragEventCounter, setDragEventCounter] = React.useState<number>(0);
  type selectorTypes = 'file' | 'directory';
  const [selectorType, setSelectorType] = React.useState<selectorTypes>('file');
  const { getInternalFileInput } = useUploadyContext();

  const internalFileInputRef = React.useMemo(getInternalFileInput, []);

  React.useEffect(() => {
    const input = internalFileInputRef?.current;
    if (selectorType === 'file') {
      input?.removeAttribute('directory');
      input?.removeAttribute('webkitdirectory');
    } else {
      input?.setAttribute('directory', '');
      input?.setAttribute('webkitdirectory', '');
    }
  }, [selectorType]);

  const tabs: ITab<selectorTypes>[] = [
    {
      id: 'file',
      label: t('medias.file', { count: multiple ? 2 : 1 }),
      icon: { name: 'files' },
    },
    {
      id: 'directory',
      label: t('medias.folder', { count: multiple ? 2 : 1 }),
      icon: { name: 'folder' },
    },
  ];

  const allowedMimetypesTooltip = allowedMimetypes?.length ? (
    <span>
      {t('actions.dropzone.allowedMimetypeLabel')}
      {allowedMimetypes?.join(', ')}
    </span>
  ) : null;

  return (
    <div
      className={`flex flex-col text-xs text-text shadow-sm rounded font-medium cursor-pointer border-dashed border-2 ${
        className ?? ''
      }`}
    >
      <TabController
        tabs={tabs}
        activeTabId={selectorType}
        onChangeTab={setSelectorType}
      />
      <UploadDropZone
        htmlDirContentParams={{ recursive: true, withFullPath: true }}
        enableOnContains
        className="h-full"
      >
        <UploadButton
          className="w-full h-full bg-white dark:bg-neutral-700 hover:bg-gray-50 hover:dark:bg-neutral-800"
          extraProps={{
            type: 'button',
            onDragEnter: (event: React.DragEvent) => {
              if (dragTargetsRef.current.includes(event.target)) return;
              dragTargetsRef.current = [
                ...dragTargetsRef.current,
                event.target,
              ];
              setDragEventCounter(dragEventCounter + 1);
            },
            onDragLeave: (event: React.DragEvent) => {
              dragTargetsRef.current = dragTargetsRef.current.filter(
                (target) => target !== event.target,
              );
              setDragEventCounter(dragEventCounter + 1);
            },
            onDrop: () => {
              dragTargetsRef.current = [];
              setDragEventCounter(dragEventCounter + 1);
            },
          }}
        >
          <div className="flex flex-col gap-4 py-2 px-4">
            <Icon
              icon={icon ?? { name: 'file-circle-plus' }}
              className="text-4xl text-gray-200"
            />
            <div className="inline-flex self-center items-center gap-1">
              {dragEventCounter > 0 && dragTargetsRef.current.length > 0
                ? t('actions.dropzone.dragMessage', {
                    count: multiple ? UPLOAD_MAX_NUMBER_FILES : 1,
                  })
                : t('actions.dropzone.baseMessage', {
                    count: multiple ? UPLOAD_MAX_NUMBER_FILES : 1,
                    selectType:
                      selectorType === 'file'
                        ? t('medias.file', { count: multiple ? 2 : 1 })
                        : t('medias.folder', { count: multiple ? 2 : 1 }),
                  })}
              {allowedMimetypesTooltip && (
                <Tooltip title={allowedMimetypesTooltip} position="top">
                  <Icon
                    icon={{ name: 'info-circle', familyStyle: 'far' }}
                    className="text-gray-400"
                  />
                </Tooltip>
              )}
            </div>
            {multiple && UPLOAD_MAX_NUMBER_FILES ? (
              <p className="text-secondary">
                {t('actions.dropzone.maxFilesDisclaimer', {
                  maxNumber: UPLOAD_MAX_NUMBER_FILES,
                })}
              </p>
            ) : null}
          </div>
        </UploadButton>
      </UploadDropZone>
    </div>
  );
};
