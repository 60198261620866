import {
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react';
import { ApiError } from '@smack/core/api/errors/ApiError/ApiError';
import type { ICommentDataInput } from '@smack/core/api/models/comments/Comment';
import { User } from '@smack/core/api/models/users/User';
import {
  CancelButton,
  IconButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { Tooltip } from '@smack/core/components/DataDisplay/Tooltip';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import {
  type IWysiwygInputProps,
  WysiwygInput,
} from '@smack/core/components/DataInput/WysiwygInput/WysiwygInput';
import type { IUser } from '@smack/core/store/users/types';
import { useWindowSize } from '@smack/core/utils';
import { TasksContext } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Context';
import { cva, cx } from 'class-variance-authority';
import { type FC, useContext, useEffect, useId, useState } from 'react';
import { type SubmitHandler, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const commentContainerVariations = cva(
  ['bg-primary border-border overflow-clip min-w-0 flex-1', 'transition-all'],
  {
    variants: {
      state: {
        open: '',
        closed: '',
      },
      style: {
        drawer: 'border-r border-t duration-700',
        float: 'border rounded duration-300',
      },
    },
    compoundVariants: [
      {
        state: 'closed',
        style: 'drawer',
        class: CSS.supports('clip-path', 'fill-box')
          ? '-translate-x-full'
          : 'hidden',
      },
      {
        state: 'closed',
        style: 'float',
        class: CSS.supports('clip-path', 'fill-box')
          ? '-translate-y-1/2 opacity-0'
          : 'hidden',
      },
    ],
  },
);

interface RealCommentFormProps {
  onSubmit: (data: ICommentDataInput) => Promise<void>;
  onQuit: () => void;
  open?: boolean;
  defaultValue?: string;
  usersNotify?: IUser[];
  editorProps?: IWysiwygInputProps;
  modal?: boolean;
}

export interface IProps extends RealCommentFormProps {
  floatingReference?: Element;
  style?: 'drawer' | 'float';
}

function RealCommentForm({
  open,
  onQuit,
  onSubmit,
  defaultValue,
  editorProps,
  usersNotify,
  modal,
}: RealCommentFormProps) {
  const [t] = useTranslation();
  const id = useId();

  const { project } = useContext(TasksContext);
  const { handleSubmit, formState, control, watch, setFocus, reset } = useForm<{
    content: string;
    users?: Option[];
  }>({
    defaultValues: {
      users:
        (usersNotify?.length ? usersNotify : project?.users)?.map((iUser) =>
          new User(iUser).toOption(),
        ) || [],
      content: defaultValue,
    },
  });
  const users = watch('users');

  const staticOptions: Option[] = (
    [
      users?.length === 0 || !users
        ? {
            label: t('comments.allUsers'),
            value: 'ALL',
            icon: { name: 'users' },
            closeDropdownOnClick: true,
          }
        : undefined,
      {
        label: t('comments.readableUsers'),
        value: 'READABLE_USERS',
        icon: { name: 'user-lock' },
      },
    ] as (Option | undefined)[]
  ).filter((x): x is Option => !!x);

  const allUsers = !!users?.find((option) => option.value === 'ALL');
  const readableUsers = !!users?.find(
    (option) => option.value === 'READABLE_USERS',
  );

  const { field: userSelectField } = useController({ name: 'users', control });
  const { field: richTextField } = useController({
    name: 'content',
    control,
    rules: { required: true },
  });

  const [searchUser, setSearchUser] = useState('');
  const [userSearchOptions, setUserSearchOptions] = useState<Option[]>([]);
  const [isInfiniteScrollOver, setIsInfiniteScrollOver] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (open) {
      setFocus('content');
    }
  }, [setFocus, open]);

  const closeForm = () => {
    reset({ content: '' });
    onQuit();
  };

  const handleSearch = (value: string) => {
    setSearchUser(value);
    User.getUsers(searchUser, 10).then((users) => {
      setUserSearchOptions(users.map((u) => u.toOption()));
      setIsInfiniteScrollOver(users.length === 0);
    });
  };

  const handleInfiniteScroll = () => {
    if (!isInfiniteScrollOver) {
      setIsLoading(true);
      User.getUsers(searchUser, 10, undefined, userSearchOptions.length).then(
        (users) => {
          setUserSearchOptions([
            ...userSearchOptions,
            ...users.map((u) => u.toOption()),
          ]);
          setIsInfiniteScrollOver(users.length === 0);
          setIsLoading(false);
        },
      );
    }
  };

  const transformAndSubmit: SubmitHandler<{
    content: string;
    users?: Option[];
  }> = (values) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const content = values.content;
    const obj: ICommentDataInput = { content };
    if (Array.isArray(values.users)) {
      obj.users = values.users
        .filter(
          (opt): opt is { value: number; label: string | number } =>
            typeof opt.value === 'number',
        )
        .map((opt) => opt.value);
      obj.mentionAllUsers = values.users.some((x) => x.value === 'ALL');
      obj.mentionAllUsersThatCanReadObject = values.users.some(
        (x) => x.value === 'READABLE_USERS',
      );
    }
    onSubmit(obj)
      .then(() => closeForm())
      .catch(ApiError.toastAllErrorsOnCatchAxiosErrors)
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(transformAndSubmit)} action="#">
      <div className={cx('flex flex-col gap-2', !modal && 'divide-y')}>
        <div>
          <label htmlFor="comment" className="sr-only">
            {t('formInputLabels.comment')}
          </label>
          <div className="p-3 flex flex-col items-start gap-4 overflow-auto">
            <WysiwygInput
              {...editorProps}
              height={250}
              width="100%"
              id={`${id}-comment`}
              fullscreen
              {...richTextField}
            />
          </div>
          {formState.errors.content ? (
            <p className="text-sm px-3 text-red-600">
              {t('formValidation.commentRequired')}
            </p>
          ) : null}
        </div>
        <div className="px-3 py-2 border-border">
          <SelectInput
            {...userSelectField}
            multiple
            needFilter={false}
            placeholder={t('comments.noUsersHelpText')}
            options={allUsers ? [] : [...staticOptions, ...userSearchOptions]}
            onSearch={handleSearch}
            onLastOptionVisible={handleInfiniteScroll}
            onMenuOpen={handleInfiniteScroll}
            isLoading={isLoading}
            label={
              <div className="flex items-center gap-2">
                <span>{t('formInputLabels.mention')}</span>
                <Tooltip
                  title={
                    ((users?.length === 0 || !users) &&
                      t('comments.noUsersHelpText')) ||
                    ((users?.length || 0) > (readableUsers ? 1 : 0) &&
                      t('comments.usersPermissionDisclaimer'))
                  }
                >
                  <Icon icon={{ name: 'circle-info' }} />
                </Tooltip>
              </div>
            }
          />
        </div>
      </div>

      <div
        className={cx(
          !modal && 'bg-view border-t border-border',
          'flex justify-end items-center px-3 py-2',
        )}
      >
        <CancelButton
          onClick={closeForm}
          className="mr-2"
          data-testid="CommentFormCancelButton"
        />
        <IconButton
          icon={{ name: (users?.length || 0) > 0 ? 'send' : 'comment' }}
          type="submit"
          isLoading={isSubmitting}
          disabled={isSubmitting}
          className="border-blue-400 [&&]:bg-blue-600 [&&]:dark:bg-blue-600 [&&]:hover:bg-blue-700 [&&]:dark:hover:bg-blue-700 text-white dark:text-white"
        >
          {t((users?.length || 0) > 0 ? 'comments.send' : 'comments.comment')}
        </IconButton>
      </div>
    </form>
  );
}

/**
 * Form for task/project comments
 * @param props IPRops
 * @returns JSX.Element
 */
export const CommentForm: FC<IProps> = ({
  onSubmit,
  onQuit,
  usersNotify,
  defaultValue,
  editorProps,
  floatingReference,
  open,
  style = 'drawer',
  modal: modalProp,
}) => {
  const [width] = useWindowSize();
  const [t] = useTranslation();

  const modal = modalProp ?? width < 800;

  const {
    refs: { setFloating: floatingRef },

    floatingStyles,
  } = useFloating({
    elements: { reference: floatingReference },
    placement: style === 'float' ? 'bottom' : 'right-end',
    middleware: style === 'float' ? [shift(), flip(), offset(5)] : [shift()],
    whileElementsMounted: autoUpdate,
    transform: false,
    strategy: 'fixed',
  });

  const [previousOpen, setPreviousOpen] = useState(open);
  const [isTransitioning, setIsTransitioning] = useState(false);
  if (previousOpen !== open) {
    setPreviousOpen(open);
    setIsTransitioning(true);
  }

  if (modal)
    return (
      <Modal
        icon={{ name: 'comment-plus' }}
        title={t('comments.add')}
        open={open ?? false}
        onClose={onQuit}
      >
        <RealCommentForm
          onSubmit={onSubmit}
          onQuit={onQuit}
          usersNotify={usersNotify}
          editorProps={editorProps}
          defaultValue={defaultValue}
          open={open}
          modal={modal}
        />
      </Modal>
    );

  return (
    <FloatingPortal>
      <div
        ref={floatingRef}
        style={{
          ...floatingStyles,
          clipPath: open && !isTransitioning ? undefined : 'fill-box',
        }}
        data-testid="comment-form"
        className={cx('w-[500px] z-[70]', !open && 'pointer-events-none')}
      >
        <div
          className={commentContainerVariations({
            state: open ? 'open' : 'closed',
            style,
          })}
          onTransitionEnd={() => setIsTransitioning(false)}
        >
          <RealCommentForm
            onSubmit={onSubmit}
            onQuit={onQuit}
            usersNotify={usersNotify}
            editorProps={editorProps}
            defaultValue={defaultValue}
            open={open}
          />
        </div>
      </div>
    </FloatingPortal>
  );
};
