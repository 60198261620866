import type { InputLink } from '@smack/core/api/models/objects/Link/Link';
import type { IViewRulesParams } from '@smack/core/components/ViewRenderer/rules/ViewRulesContext/ViewRulesContext';
import {
  FilterFunctionChoice,
  FiltersQueryParams,
  getParamsFromFilters,
} from '@smack/core/utils/Filters';
import { toast } from 'react-hot-toast';

export enum ViewRulesFunctionType {
  SelectRelated = 'SELECT_RELATED',
  ShowPopupAlert = 'SHOW_POPUP_ALERT',
}

type ViewRulesFunctionCallOutput = Record<string, unknown> | undefined;

export interface ViewRulesFunction {
  type: ViewRulesFunctionType;
  call: (data: IViewRulesParams) => ViewRulesFunctionCallOutput;
}

export const ViewRulesFunctions: ViewRulesFunction[] = [
  {
    type: ViewRulesFunctionType.SelectRelated,
    call: (data): ViewRulesFunctionCallOutput => {
      const { targetAttributeId, targetAttributeFilter, eventValue } =
        data as IViewRulesParams<InputLink>;

      if (targetAttributeId && targetAttributeFilter) {
        return getParamsFromFilters(
          {
            attributes: {
              [targetAttributeId]: {
                filterFunction: FilterFunctionChoice.find(
                  (f) => f.id === targetAttributeFilter,
                )?.value,
                filterFunctionValue:
                  eventValue?.targetBaseobjectId?.toString() ?? '',
                valuesAllSelected: 'O',
              },
            },
          },
          [FiltersQueryParams.ATTRIBUTE_FILTER],
        );
      }
    },
  },
  {
    type: ViewRulesFunctionType.ShowPopupAlert,
    call: (data): ViewRulesFunctionCallOutput => {
      const { message } = data;
      if (message) {
        toast(message);
      }
      return;
    },
  },
];
