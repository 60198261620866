import type { ITemplateSkeletonComponent } from '.';

export const LinkGroupSkeleton: ITemplateSkeletonComponent = ({
  offset = 0,
}) => (
  <>
    {/* Icon + title */}
    <circle cx="28" cy={24 + offset} r="12" />
    <rect x="50" y={19 + offset} rx="5" ry="5" width="100px" height="10" />
    <circle cx="170" cy={24 + offset} r="10" width="100" height="10" />
    {/* Right-side buttons */}
    <circle cx="calc(100% - 60px)" cy={24 + offset} r="8" />
    <circle cx="calc(100% - 80px)" cy={24 + offset} r="8" />
    <circle cx="calc(100% - 100px)" cy={24 + offset} r="8" />
    <rect
      x="calc(100% - 40px)"
      y={19 + offset}
      rx="5"
      ry="5"
      width="20"
      height="10"
    />
  </>
);

LinkGroupSkeleton.itemOffset = 48;
