import type { FieldProps } from '@smack/core/api/models/views/ViewElement/ViewElement';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { DetailSubmissionType } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeFieldsByType/type';
import { ViewRulesController } from '@smack/core/components/ViewRenderer/rules/ViewRulesController/ViewRulesController';
import React from 'react';
import {
  type ControllerRenderProps,
  type FieldValues,
  useForm,
} from 'react-hook-form';

interface IProps {
  type: DetailSubmissionType;
  children: (
    props: ControllerRenderProps<FieldValues, 'attribute-value'>,
  ) => React.ReactElement;
  value: unknown;
  fieldProps: FieldProps;
  defaultValue: unknown;
  onSubmit?: (val: unknown) => Promise<void>;
}

type FormOutput = { 'attribute-value'?: unknown };

export const FormFieldSubmission: React.FC<IProps> = ({
  children,
  value,
  type,
  onSubmit,
  defaultValue,
  fieldProps,
}) => {
  const [loading, setLoading] = React.useState(false);
  const { control, formState, reset, handleSubmit } = useForm<FormOutput>();

  React.useEffect(() => {
    if (value) {
      reset({ 'attribute-value': value });
    }
  }, [value]);

  const handleSubmission = (val: FormOutput): void => {
    setLoading(true);
    onSubmit?.(val['attribute-value']).finally(() => {
      setLoading(false);
    });
  };

  const showButton = (): boolean => {
    return !!(
      type === DetailSubmissionType.Button &&
      Object.keys(formState.dirtyFields).length
    );
  };

  return (
    <form
      onSubmit={handleSubmit(handleSubmission)}
      className="flex items-center flex-grow"
    >
      <ViewRulesController
        control={control}
        defaultValue={defaultValue}
        name={'attribute-value'}
        viewElement={fieldProps.viewElement}
        render={({ field }): React.ReactElement =>
          children({
            ...field,
            onChange: (val) => {
              if (type === DetailSubmissionType.OnChange) {
                handleSubmission({ 'attribute-value': val });
              }
              field.onChange(val);
            },
          })
        }
      />
      {showButton() ? (
        <button
          data-testid={'FormFieldSubmission.submit'}
          type={'submit'}
          className={
            'text-blue-500 cursor-pointer h-full flex items-center justify-center ml-3'
          }
        >
          <Icon
            icon={{ name: loading ? 'spinner-third' : 'check' }}
            animation={loading ? 'spin' : undefined}
          />
        </button>
      ) : null}
    </form>
  );
};
