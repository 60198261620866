import type { IconPrefix } from '@fortawesome/fontawesome-common-types';
import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import type { Category } from '@smack/core/api/models/categories/Category';
import {
  CommonBaseObject,
  type ICommonBaseObject,
} from '@smack/core/api/models/objects/NewBaseObject';
import {
  type IFilters,
  getParamsFromFilters,
  queryParamsByMode,
} from '@smack/core/utils/Filters';
import type { Feature, GeoJsonProperties, Point } from 'geojson';

export interface MapBaseObjectFeatureProperties {
  color?: string;
  description?: string;
  frontEndpoint: string;
  icon?: string;
  iconType?: IconPrefix;
}

export interface GeometryBaseObjectFeatureProperties {
  code?: string;
  color?: string;
  frontEndpoint: string;
  geometry_type?: string;
}

/**
 * MapBaseObject interface for BaseObject Model
 *
 * @export
 * @interface IBaseObject
 */
export interface IMapBaseObject extends ICommonBaseObject {
  location?: GeoJSON.Feature<Point>;
}

/**
 * MapBaseObject class for BaseObject Model.
 * Used for Map representation.
 *
 * @export
 * @class BaseObject
 */
export class MapBaseObject
  extends CommonBaseObject<IMapBaseObject>
  implements IMapBaseObject
{
  location?: Feature<Point, GeoJsonProperties>;

  constructor(data: IMapBaseObject) {
    super(data);

    this.location = data.location;
  }

  /**
   * Get BaseObject for map representation
   *
   * @static
   * @param {Category} rootCategory
   * @param {Category} parentCategory
   * @param {IFilters} [filters]
   * @param {number} [offset]
   * @return {*}  {Promise<{
   *     data: {
   *       count: number;
   *       next: string;
   *       previous: string;
   *       results: IMapBaseObject[];
   *     };
   *   }>}
   * @memberof MapBaseObject
   */
  static getBaseObjectForMapRepresentation(
    rootCategory: Category,
    parentCategory: Category,
    filters?: IFilters,
    first?: number,
    offset?: number,
  ): Promise<{
    data: {
      count: number;
      next: string;
      previous: string;
      results: IMapBaseObject[];
    };
  }> {
    return RESTClient.get<{
      data: {
        count: number;
        next: string;
        previous: string;
        results: IMapBaseObject[];
      };
    }>(
      '/objects/baseobjects?with-location=1',
      getParamsFromFilters(filters ?? {}, queryParamsByMode.map, {
        parentCategory: parentCategory?.id,
        offset,
      }),
    );
  }

  /**
   * Get concatenated BaseObject pages for complete map representation
   *
   * @static
   * @param {Category} rootCategory
   * @param {Category} parentCategory
   * @param {IFilters} [filters]
   * @return {*}  {Promise<{
   *     data: {
   *       count: number;
   *       next: string;
   *       previous: string;
   *       results: IListBaseObject[];
   *     };
   *   }>}
   * @memberof ListBaseObject
   */
  static async getBaseObjectForUnpaginatedMapRepresentation(
    rootCategory: Category,
    parentCategory: Category,
    filters?: IFilters,
    signal?: AbortSignal,
  ): Promise<MapBaseObject[]> {
    let response = await RESTClient.get<{
      data: {
        count: number;
        next: string;
        previous: string;
        results: IMapBaseObject[];
      };
    }>(
      '/objects/baseobjects?with-location=1',
      getParamsFromFilters(filters ?? {}, queryParamsByMode.map, {
        parentCategory: parentCategory?.id,
        limit: 1000,
      }),
      undefined,
      signal,
    );
    const results: MapBaseObject[] = response.data.results.map(
      (o) => new MapBaseObject(o),
    );
    let nextPage = response.data.next;
    while (nextPage && !signal?.aborted) {
      response = await RESTClient.get<{
        data: {
          count: number;
          next: string;
          previous: string;
          results: IMapBaseObject[];
        };
      }>(nextPage, undefined, true, signal);
      results.push(...response.data.results.map((o) => new MapBaseObject(o)));
      nextPage = response.data.next;
    }
    return results;
  }
}
