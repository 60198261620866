import type { Project } from '@smack/core/api/models/tasks/Project/Project';
import { SaveButton } from '@smack/core/components/Actions/Buttons/Button';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { Input } from '@smack/core/components/DataInput/Input/Input';
import { useNonInitialEffect } from '@smack/core/utils/NonInitialEffect';
import React from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<boolean>;
  project: Project;
  reloadProject: () => void;
}

interface IFormValues {
  label: string;
}

export const ProjectEditModal: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  project,
  reloadProject,
}) => {
  const { register, handleSubmit, formState, reset } = useForm<IFormValues>({
    defaultValues: {
      label: project.label,
    },
  });

  const { t } = useTranslation();

  const onSubmit = React.useCallback<SubmitHandler<IFormValues>>(
    async (data) => {
      await project.patch(data);
      reset({
        label: data.label,
      });
      setIsOpen(false);
      reloadProject();
    },
    [],
  );

  useNonInitialEffect(() => {
    if (!isOpen) reset();
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={setIsOpen}
      icon={{ name: 'pen' }}
      title={t('edit.project')}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          native
          id={`project-${project.id}`}
          label={t('formInputLabels.name')}
          error={formState.errors.label?.message}
          {...register('label', {
            required: {
              value: true,
              message: t('formValidation.required'),
            },
          })}
        />
        <div className="mt-5 flex items-center justify-end">
          <SaveButton
            type="submit"
            data-testid="ProjectEditModelCreateButton"
          />
        </div>
      </form>
    </Modal>
  );
};
