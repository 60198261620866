import React from 'react';

// s/o to https://github.com/thivi/use-non-initial-effect-hook
// See https://www.thearmchaircritic.org/tech-journal/prevent-useeffects-callback-firing-during-initial-render
/**
 * This hook gets called only when the dependencies change but not during initial render.
 *
 * @param effect The `useEffect` callback function.
 * @param deps An array of dependencies.
 *
 * @example
 * ```
 *  useNonInitialEffect(()=>{
 *      alert("Dependency changed!");
 * },[dependency]);
 * ```
 */
export const useNonInitialEffect = (
  effect: React.EffectCallback,
  deps?: React.DependencyList,
): void => {
  const initialRender = React.useRef(true);

  React.useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      const destructor = effect();
      if (destructor) {
        return destructor;
      }
    }
  }, deps);
};
