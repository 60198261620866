import {
  CloseButton,
  SaveButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { NumberInput } from '@smack/core/components/DataInput/NumberInput/NumberInput';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import { SelectRecurrenceInput } from '@smack/core/components/DataInput/SelectRecurrenceInput';
import { RRuleUtils } from '@smack/core/utils/RRule';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { type Frequency, RRule, RRuleSet, rrulestr } from 'rrule';
import { getRRuleNumber, getRecurenceTypeOptions } from '../../Utils';
import { EndRRule } from '../EndRRule';
import { MonthlyRRule } from '../MonthlyRRule';
import { WeeklyRRule } from '../WeeklyRRule';

interface IProps {
  value?: string;
  dtStart?: Date;
  onChange?: (val?: string) => void;
  includeDtStart?: boolean;
  allowEmpty?: boolean;
}

export const RRuleGenerator: React.FC<IProps> = ({
  value,
  onChange,
  dtStart,
  includeDtStart = true,
  allowEmpty = true,
}) => {
  const [t] = useTranslation();
  const [modalOpen, setModalOpen] = React.useState(false);

  const computedRRuleSetValue = React.useMemo<RRuleSet | undefined>(() => {
    if (!value) return undefined;
    try {
      return rrulestr(value, {
        forceset: true,
      }) as RRuleSet;
    } catch {
      return undefined;
    }
  }, [value]);

  /**
   * handle input change
   *
   * @param {RRule} val
   */
  const handleChange = (val?: RRule): void => {
    if (onChange && val) {
      if (dtStart && includeDtStart) {
        val.origOptions.dtstart = dtStart;
      }
      const set = new RRuleSet();
      set.rrule(val);
      computedRRuleSetValue?.exdates()?.forEach((exDate) => {
        set.exdate(exDate);
      });
      onChange(set.toString());
    } else if (onChange && typeof val === 'undefined') {
      onChange(undefined);
    }
  };

  /**
   * get the components from the repeat value
   */
  const switchRecurenceType = (): React.ReactNode | undefined => {
    switch (computedRRuleSetValue?.rrules().at(0)?.options?.freq) {
      case RRule.YEARLY:
        return null;
      case RRule.MONTHLY:
        return (
          <MonthlyRRule
            value={computedRRuleSetValue?.rrules().at(0)}
            onChange={handleChange}
          />
        );
      case RRule.WEEKLY:
        return (
          <WeeklyRRule
            value={computedRRuleSetValue?.rrules().at(0)}
            onChange={handleChange}
          />
        );
      case RRule.DAILY:
        return null;
    }
  };

  function determineQuickRRule(val?: string): string {
    if (!val) return RRuleUtils.staticQuickRRules(dtStart)[0].value;
    const parsedRRule = /RRULE:.+/m.exec(val);
    if (!parsedRRule) return RRuleUtils.staticQuickRRules(dtStart)[0].value;
    const foundOption = RRuleUtils.staticQuickRRules(dtStart).find(
      (opt) => parsedRRule[0] === opt.value,
    );

    if (foundOption) {
      return foundOption.value;
    }
    return RRuleUtils.staticQuickRRules(dtStart).slice(-1)[0].value;
  }

  const onQuickRRuleChange = (newRRule?: string): void => {
    const newValue = newRRule || RRuleUtils.staticQuickRRules(dtStart)[0];
    if (typeof newValue === 'string') {
      if (newValue === 'NORRULE') {
        handleChange(); // undefined
      } else if (newValue !== 'CUSTOM') {
        handleChange(rrulestr(newValue));
      } else {
        setModalOpen(true);
      }
    }
  };

  React.useEffect(() => {
    if (modalOpen && !value) {
      handleChange(new RRule({ freq: RRule.DAILY }));
    }
  }, [modalOpen]);

  const getValue = (): string => {
    if (!value) return RRuleUtils.staticQuickRRules(dtStart)[0].value;
    const isCustom = determineQuickRRule(value) === 'CUSTOM';
    if (isCustom) {
      return value;
    }
    return determineQuickRRule(value);
  };

  return (
    <div className="flex items-center ">
      <Icon
        icon={{ name: 'repeat', familyStyle: 'fal' }}
        className={'text-gray-500 text-lg mr-2'}
      />
      <div className=" w-full max-w-[400px]">
        {dtStart && (
          <SelectRecurrenceInput
            value={getValue()}
            onChange={onQuickRRuleChange}
            dtStart={dtStart}
            allowEmpty={allowEmpty}
          />
        )}
      </div>
      <Modal
        icon={{ name: 'repeat' }}
        title={'Récurrence personnalisée'}
        open={modalOpen}
        onClose={setModalOpen}
      >
        <>
          <div className="my-4 min-w-[500px]">
            <div className="flex items-center gap-5 whitespace-nowrap ">
              <p className="text-sm">{t('recurrence.repeatEvery')}</p>
              <NumberInput
                size="xSmall"
                min={1}
                value={getRRuleNumber(
                  computedRRuleSetValue?.rrules().at(0)?.options?.interval,
                )}
                onChange={(val): void =>
                  handleChange(
                    new RRule({
                      ...computedRRuleSetValue?.rrules().at(0)?.origOptions,
                      interval: Number.parseInt(val.toString()),
                    }),
                  )
                }
              />
              <SelectInput
                className="w-32"
                isClearable={false}
                isSearchable={false}
                value={getRecurenceTypeOptions().find(
                  (v) =>
                    v.value ===
                    computedRRuleSetValue?.rrules().at(0)?.options?.freq,
                )}
                onChange={(data): void =>
                  handleChange(
                    new RRule({
                      freq: data?.value as Frequency,
                    }),
                  )
                }
                options={getRecurenceTypeOptions()}
              />
            </div>
          </div>
          {switchRecurenceType()}
          <EndRRule
            value={computedRRuleSetValue?.rrules().at(0)}
            onChange={handleChange}
          />
          <div className="flex items-center justify-end gap-3 mt-3">
            <CloseButton onClick={(): void => setModalOpen(false)} />
            <SaveButton onClick={(): void => setModalOpen(false)} />
          </div>
        </>
      </Modal>
    </div>
  );
};
