import type { Comment } from '@smack/core/api/models/comments/Comment';
import type { User } from '@smack/core/api/models/users/User';
import { IconButton } from '@smack/core/components/Actions/Buttons/Button';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { InfiniteVirtualizedList } from '@smack/core/views/oldViewsToSort/Layouts/Objects/InfiniteVirtualizedList';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { IndexRange } from 'react-virtualized';

/** The number of recipients to fetch by chunk. */
const RECIPIENTS_PER_CHUNK = 20;

/**
 * Input properties of the modal feed component.
 */
interface IProps {
  /** The comment. */
  comment: Comment;
  /** The number of recipients of the comment. */
  recipientCount: number;
  /** Whether the modal is opened. */
  open: boolean;
  /** The function to set the modal's open state. */
  onClose: (value: React.SetStateAction<boolean>) => void;
}

/**
 * Creates a `ModalFeed` component
 * That list the recipients of a comment.
 *
 * @param props - The input properties of the component.
 * @returns The created `ModalFeed` component.
 */

export const ModalFeed = ({
  comment,
  recipientCount,
  open,
  onClose,
}: IProps): JSX.Element => {
  const [t] = useTranslation();

  const [recipients, setRecipients] = React.useState<User[]>([]);

  const handleLoadMoreRows = React.useCallback((data: IndexRange): void => {
    comment
      .getPaginatedRecipients(RECIPIENTS_PER_CHUNK, data.startIndex)
      .then((response) => {
        setRecipients((previous) => [...previous, ...response]);
      });
  }, []);

  const renderListItem = React.useCallback((recipient: User): JSX.Element => {
    return (
      <li key={recipient.id} className="text-gray-700">
        {recipient.getFullName()}
      </li>
    );
  }, []);

  const onCloseDialog = (): void => {
    onClose(false);
  };

  // Init
  React.useEffect(() => {
    comment.getPaginatedRecipients(RECIPIENTS_PER_CHUNK, 0).then(setRecipients);
  }, []);

  return (
    <Modal
      icon={{ name: 'list' }}
      title={t('comments.recipients.recipient', { count: recipientCount })}
      open={open}
      onClose={onClose}
    >
      <p className="text-sm text-gray-400 dark:text-gray-100">
        {t('comments.recipients.recipientWithCount_other', {
          count: recipientCount,
        })}
      </p>
      <div className="my-4 w-72 h-80">
        <InfiniteVirtualizedList
          startIndex={0}
          loadMoreRows={handleLoadMoreRows}
          rowHeight={25}
          rowCount={recipientCount}
          objects={recipients}
          renderListItem={renderListItem}
        />
      </div>
      <IconButton
        onClick={onCloseDialog}
        icon={{ name: 'xmark' }}
        className="ml-auto"
      >
        {t('close')}
      </IconButton>
    </Modal>
  );
};
