import type { CategoryLevel } from '@smack/core/api/models/categories/Category';
import type { ParentCategory } from '@smack/core/api/models/categories/Category/ParentCategory';
import type { SubCategory } from '@smack/core/api/models/categories/Category/SubCategory';
import type { IDisplayedAttributeFilter } from '@smack/core/api/models/categories/DisplayedAttributeFilter';
import type { IDisplayedAttributeVisualization } from '@smack/core/api/models/categories/DisplayedAttributeVisualization';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import type { DetailsSheetProps } from '@smack/core/views/oldViewsToSort/Views/Objects/DetailsSheet/DetailsSheet';
import type { BatchUpdateFormProps } from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/BatchUpdateForm/BatchUpdateForm';
import type { CreationFormProps } from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/CreationForm/CreationForm';
import type { UpdateFormProps } from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/UpdateForm/UpdateForm';
import type { PropsWithChildren, ReactElement } from 'react';

export enum ViewName {
  Map = 'MAP',
  Table = 'TABLE',
  Kanban = 'KANBAN',
  Calendar = 'CALENDAR',
  ObjectFunctional = 'OBJECT_FUNCTIONAL',
  ObjectCalendar = 'OBJECT_CALENDAR',
  ObjectRoomCalendar = 'OBJECT_ROOM_CALENDAR',
  ObjectTask = 'OBJECT_TASK',
  CreationForm = 'CREATION_FORM',
  UpdateForm = 'UPDATE_FORM',
  BatchUpdateForm = 'BATCH_UPDATE_FORM',
  DetailsSheet = 'DETAILS_SHEET',
  Dashboard = 'DASHBOARD',
  FullScreen = 'FULL_SCREEN',
}

export enum RightPanelListIds {
  MapConfig = 'map-config',
  Stats = 'stats',
  ElementPreview = 'ElementPreview',
}

export interface ViewProperties {
  [ViewName.Map]: IViewProps;
  [ViewName.Table]: IViewProps;
  [ViewName.Kanban]: IViewProps;
  [ViewName.Calendar]: IViewProps;
  [ViewName.ObjectFunctional]: IViewProps;
  [ViewName.ObjectCalendar]: IViewProps;
  [ViewName.ObjectRoomCalendar]: IViewProps;
  [ViewName.ObjectTask]: IViewProps;
  [ViewName.CreationForm]: CreationFormProps;
  [ViewName.UpdateForm]: UpdateFormProps;
  [ViewName.BatchUpdateForm]: BatchUpdateFormProps;
  [ViewName.DetailsSheet]: DetailsSheetProps;
  [ViewName.Dashboard]: IViewProps;
  [ViewName.FullScreen]: IViewProps;
}

export interface View {
  id: ViewName;
  label: string;
  icon: IconField;
  mobile?: boolean;
  showTimeline?: boolean;
  isAvailable?: (
    category: ParentCategory | SubCategory,
    object?: boolean,
  ) => boolean;
  isSelectable: boolean;
  leftPanel?: string[];
  rightPanel?: string[];
  focusedObjectOnly?: boolean;
  categoryMode: CategoryLevel;
  // biome-ignore lint/suspicious/noExplicitAny: FIXME
  render: (props: any) => ReactElement;
}

export interface IViewProps extends PropsWithChildren<object> {
  displayedAttributeVisualizations?: IDisplayedAttributeVisualization[];
  displayedAttributeFilters?: IDisplayedAttributeFilter[];
  mobile?: boolean;
}

export interface ICalendarViewProps extends IViewProps {
  selectable?: boolean;
}
