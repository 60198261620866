import { BaseSocketManager } from '@smack/core/api/clients/websocket/index';

export interface IMediasEventSocketArgs {
  type: 'changes_detected';
}

export class MediasEventSocketManager extends BaseSocketManager<IMediasEventSocketArgs> {
  constructor(
    channel: string,
    callback: (args: IMediasEventSocketArgs) => unknown,
  ) {
    super(`medias/${channel}/`, callback);
  }
}
