export const isElectronAvailable = (): boolean => {
  return window.Electron !== undefined;
};

export const openFile = (path: string): Promise<void> => {
  if (!isElectronAvailable()) return Promise.resolve();
  return window.Electron.invoke<void>('openFile', path);
};

export const openFolderInExplorer = (path: string): Promise<void> => {
  if (!isElectronAvailable()) return Promise.resolve();
  return window.Electron.invoke<void>('openFolderInExplorer', path);
};

export const openFileInExplorer = (path: string): Promise<void> => {
  if (!isElectronAvailable()) return Promise.resolve();
  return window.Electron.invoke<void>('openFileInExplorer', path);
};

export const readFile = (path: string): Promise<ArrayBuffer> | undefined => {
  if (!isElectronAvailable()) return;
  return window.Electron.invoke<ArrayBuffer>('readFile', path);
};

export const checkPathExists = (path: string): Promise<boolean> | boolean => {
  if (!isElectronAvailable()) return false;
  return window.Electron.invoke<boolean>('pathExists', path);
};
