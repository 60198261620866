import type { ViewElement } from '@smack/core/api/models/views/ViewElement/ViewElement';
import { ViewElementType } from '@smack/core/api/models/views/ViewElement/ViewElement';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import type React from 'react';

interface IProps {
  viewElement: ViewElement;
  value: React.ReactNode;
}

export const PreviewElementRow: React.FC<IProps> = ({ viewElement, value }) => {
  if (viewElement.transparent) return <>{value}</>;

  return (
    <div className="flex min-h-[46px] items-center px-3 gap-4">
      <div className={'min-w-[20px]'}>
        <Icon
          icon={
            viewElement.icon?.name
              ? viewElement.icon
              : { name: 'info-circle', familyStyle: 'far' }
          }
          className="text-blue-500"
        />
      </div>
      <div className="flex-grow" key={viewElement.id}>
        {viewElement.type === ViewElementType.ATTRIBUTE ? (
          <p className="text-xs text-gray-600 font-light dark:text-gray-300">
            {viewElement.label}
          </p>
        ) : null}
        <div className="flex gap-1">{value}</div>
      </div>
    </div>
  );
};
