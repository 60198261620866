import { IconButton } from '@smack/core/components/Actions/Buttons/Button';
import { WarningAlert } from '@smack/core/components/DataDisplay/Alerts/Alerts';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { lazy } from 'react';
import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

const MiniMap = lazy(() =>
  import('@smack/core/components/DataDisplay/MiniMap').then((module) => ({
    default: module.MiniMap,
  })),
);

export interface ILocationDisplay {
  address?: string;
  location?: GeoJSON.Feature;
  geometry?: GeoJSON.FeatureCollection;
}

interface IProps {
  value?: ILocationDisplay;
}

export const LocationDisplay: FC<IProps> = ({ value }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div>
      <IconButton
        onClick={() => setOpen(true)}
        icon={{ name: 'map-location-dot', familyStyle: 'fas' }}
      >
        {t('formInputLabels.locate')}
      </IconButton>
      <Modal
        title={t('locationDisplayModal.title')}
        icon={{ name: 'map-location' }}
        open={open}
        onClose={setOpen}
      >
        <div className="mb-2">
          <p className="block text-sm font-medium text-gray-700 mb-1">
            {t('locationDisplayModal.address')}
          </p>
          <div className="text-sm text-text">{value?.address || '-'}</div>
        </div>

        <p className="block text-sm font-medium text-gray-700 mb-1">
          {t('locationDisplayModal.map')}
        </p>
        {value?.location || value?.geometry ? (
          <MiniMap
            location={value.location}
            geometry={value.geometry}
            controlButtons={{
              fullscreen: true,
              geolocate: true,
              configurationPanel: true,
            }}
            interactive={true}
            className="w-[80vw] max-w-[600px] !h-[300px] border border-gray-300"
          />
        ) : (
          <WarningAlert>
            {t('locationDisplayModal.noLocationMessage')}
          </WarningAlert>
        )}
      </Modal>
    </div>
  );
};
