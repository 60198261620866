import type React from 'react';

export const TaskListHeader: React.FC<{ headerHeight: number }> = ({
  headerHeight,
}) => {
  return (
    <div
      className="full border-b border-r"
      style={{ height: headerHeight - 1 }}
    />
  );
};
