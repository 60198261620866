import { type Status, StatusType } from '@smack/core/api/models/tasks/Status';
import { type RefObject, useEffect, useRef, useState } from 'react';

export const useAnimation = <T>(
  value: T,
  element: RefObject<HTMLElement>,
): T => {
  const [, setRerender] = useState<Record<never, never>>({});

  const previousValue = useRef<T>(value);
  useEffect(() => {
    previousValue.current = value;
  }, [value]);

  useEffect(() => {
    if (element.current) {
      const forceRerender = (): void => setRerender({});
      element.current.addEventListener('animationend', forceRerender);
      return () =>
        element.current?.removeEventListener('animationend', forceRerender);
    }
  }, [element.current]);

  return previousValue.current;
};

export const useStatefulAnimation = (
  status: Status | undefined,
  element: RefObject<HTMLElement>,
): [boolean, boolean] => {
  const previousValue = useAnimation(status?.statusType, element);

  const hasAppeared =
    previousValue === StatusType.HIDDEN &&
    status?.statusType !== StatusType.HIDDEN;

  const hasDisappeared =
    previousValue !== StatusType.HIDDEN &&
    status?.statusType === StatusType.HIDDEN;

  return [hasAppeared, hasDisappeared];
};
