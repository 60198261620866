import { NavigationContainer } from '@smack/core/components/Actions/Navigation/NavigationContainer';
import { NavigationIconButton } from '@smack/core/components/Actions/Navigation/NavigationIconButton';
import type { AppState } from '@smack/core/store';
import { addSearchParams } from '@smack/core/utils/URLs';
import type { INavigationTabs } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/Navigation/index';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  NavLink,
  Navigate,
  Route,
  Routes,
  useSearchParams,
} from 'react-router-dom';

interface IProps {
  tabs: INavigationTabs[];
}

export const SubNavigation = (props: IProps): JSX.Element => {
  const { tabs } = props;
  const { disableSubNavRouting } = useSelector(
    (app: AppState) => app.App.module,
  );

  const [active, setActive] = React.useState<number>(0); // Default to first tab
  const [searchParams] = useSearchParams();

  // Store the initial value of disableSubNavRouting in a ref
  const disableRoutingRef = React.useRef(disableSubNavRouting);

  // Update the ref only once when the component mounts
  // The following allows the component to not react whenever
  //the value of disableSubNavRouting changes (opening a preview in the right panel)
  React.useEffect(() => {
    disableRoutingRef.current = disableSubNavRouting;
  }, []); // Empty dependency array ensures it runs only once

  return (
    <>
      <NavigationContainer paginate indexactive={active}>
        {tabs.map((tab, i) => {
          return disableRoutingRef.current ? (
            <NavigationIconButton
              key={tab.id}
              icon={tab.icon}
              label={tab.label}
              active={active === i}
              onClick={() => setActive(i)}
              activeColor="#3ea6ed"
            />
          ) : (
            <NavLink key={tab.id} to={addSearchParams(tab.id, searchParams)}>
              {({ isActive }): JSX.Element => {
                return (
                  <NavigationIconButton
                    icon={tab.icon}
                    label={tab.label}
                    activeColor="#3ea6ed"
                    active={isActive}
                  />
                );
              }}
            </NavLink>
          );
        })}
      </NavigationContainer>

      <div className="relative w-full h-full">
        {disableRoutingRef.current ? (
          // Manually render the content of the active tab when routing is disabled.
          tabs?.[active]?.render()
        ) : (
          <Routes>
            {tabs?.[0] && (
              <Route
                index
                key={tabs[0].id}
                element={
                  <Navigate
                    replace
                    to={addSearchParams(tabs[0].id, searchParams)}
                  />
                }
              />
            )}
            {tabs?.map((tab) => (
              <Route
                key={`${tab.id}`}
                path={`${tab.id}/*`}
                element={tab.render()}
              />
            ))}
            {tabs?.[0]?.id && (
              <Route
                path="*"
                element={
                  <Navigate
                    replace
                    to={addSearchParams(tabs[0]?.id, searchParams)}
                  />
                }
              />
            )}
          </Routes>
        )}
      </div>
    </>
  );
};
