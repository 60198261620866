import type {
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-common-types';
import type { FlipProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FontAwesomeFamilyLoader } from '@smack/core/components/DataDisplay/Icon/FontAwesomeFamilyLoader';
import type React from 'react';

export interface IconField {
  name?: IconName;
  familyStyle?: IconPrefix;
}

type FontAwesomeAnimation =
  | 'spin'
  | 'spinPulse'
  | 'spinReverse'
  | 'pulse'
  | 'beat'
  | 'fade'
  | 'beatFade'
  | 'bounce'
  | 'shake';

export interface IIconProps {
  icon?: IconField;
  iconFile?: string;
  color?: string;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  className?: string;
  containerClassName?: string;
  style?: React.CSSProperties;
  animation?: FontAwesomeAnimation;
  flip?: FlipProp;
  fixedWidth?: boolean;
  title?: string;
  inline?: boolean;
}

export const Icon: React.FC<IIconProps> = ({
  icon,
  iconFile,
  className,
  containerClassName,
  color,
  onClick,
  style,
  fixedWidth,
  title,
  animation,
  inline,
  flip,
}) => {
  const iconName = icon?.name;
  const iconPrefix = icon?.familyStyle || 'fas';
  const iconAnimationProps: {
    [animationKey in FontAwesomeAnimation]?: boolean;
  } = animation ? { [animation]: true } : {};
  if (iconName) {
    return (
      <span
        title={title}
        className={`h-fit ${
          inline ? 'inline-flex' : 'flex'
        } items-center justify-center ${containerClassName || ''}`}
        onClick={onClick}
        data-testid={`icon-${iconPrefix}-${iconName}`}
      >
        <FontAwesomeFamilyLoader iconPrefix={iconPrefix}>
          <FontAwesomeIcon
            icon={{ prefix: iconPrefix, iconName }}
            className={className}
            fixedWidth={fixedWidth}
            style={color ? { ...style, color } : { ...style }}
            flip={flip}
            {...iconAnimationProps}
          />
        </FontAwesomeFamilyLoader>
      </span>
    );
  }
  return <img src={iconFile} className={className} alt="" />;
};
