import type { IInputProps } from '@smack/core/components/DataInput/Input/Input';
import type { IMediaInputParameters } from '@smack/core/components/DataInput/MediaInput';
import type { INumberInputProps } from '@smack/core/components/DataInput/NumberInput/NumberInput';
import type { IPhoneInputProps } from '@smack/core/components/DataInput/PhoneInput/PhoneInput';
import type { ITextAreaInputProps } from '@smack/core/components/DataInput/TextAreaInput';
import type { ITimeInputProps } from '@smack/core/components/DataInput/TimeInput/TimeInput';
import type { ITimeSelectInputProps } from '@smack/core/components/DataInput/TimeSelectInput';
import type { ReactDatePickerProps } from 'react-datepicker';
type ValidationTypes = 'boolean' | 'number' | 'string';
export type ParametersValidationSchema<T> = Readonly<{
  [key in keyof T]?: ValidationTypes;
}>;

export const NumberParametersValidationSchema: ParametersValidationSchema<INumberInputProps> =
  Object.freeze({
    min: 'number',
    max: 'number',
    step: 'number',
  });

export const TimeParametersValidationSchema: ParametersValidationSchema<ITimeInputProps> =
  Object.freeze({
    min: 'string',
    max: 'string',
    step: 'number',
  });

export const TimeSelectParametersValidationSchema: ParametersValidationSchema<ITimeSelectInputProps> =
  Object.freeze({
    minHour: 'number',
    maxHour: 'number',
    stepHour: 'number',
    minMinute: 'number',
    maxMinute: 'number',
    stepMinute: 'number',
  });

export const TextParametersValidationSchema: ParametersValidationSchema<IInputProps> =
  Object.freeze({
    minLength: 'number',
    maxLength: 'number',
    placeholder: 'string',
    spellCheck: 'string',
    pattern: 'string',
  });

export const TextAreaParametersValidationSchema: ParametersValidationSchema<ITextAreaInputProps> =
  Object.freeze({
    ...TextParametersValidationSchema,
    pattern: undefined,
    rows: 'number',
    cols: 'number',
  });

export const DateTimeParametersValidationSchema: ParametersValidationSchema<ReactDatePickerProps> =
  Object.freeze({
    timeIntervals: 'number',
  });

export const PhoneParametersValidationSchema: ParametersValidationSchema<IPhoneInputProps> =
  Object.freeze({
    showMainCountriesOnly: 'boolean',
    defaultCountry: 'string',
  });

export const MediaInputParametersValidationSchema: ParametersValidationSchema<IMediaInputParameters> =
  Object.freeze({
    resizeImage: 'boolean',
    resizeImageSizeThreshold: 'string',
    selectImageResolution: 'boolean',
    minImageResolution: 'string',
    midImageResolution: 'string',
    maxImageResolution: 'string',
  });
