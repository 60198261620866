import { MapSpiderfier } from '@smack/core/views/oldViewsToSort/Views/Map/Components/SpiderfyCluster';
import type { IMarkerObject } from '@smack/core/views/oldViewsToSort/Views/Objects/ObjectMap/Components/MapClusters/utils';
import type { Feature, Point } from 'geojson';
import {
  type GeoJSONSource,
  type MapMouseEvent,
  type Map as MaplibreMap,
  Marker,
  Popup,
} from 'maplibre-gl';

interface IProps {
  source_name: string;
  spiderfy_layer?: string;
  onSpiderifyObjectClicked: (id: string) => void;
  onSpiderLegHover: (feat: Feature, originalEvent: MouseEvent) => void;
  colorList: string[];
  colorPath?: string;
}

export class MapCluster {
  Map: MaplibreMap | null = null;

  markersOnScreen: { [key: string]: Marker } = {};

  markers: { [key: string]: Marker } = {};

  constructorprops: IProps | null = null;

  spiderifier: MapSpiderfier | null = null;

  constructor(props: IProps) {
    this.Map = window.Hyvilo.Utils.MainMap;
    this.constructorprops = props;

    this.ClusterDataCallback = this.ClusterDataCallback.bind(this);
    this.onZoomCbk = this.onZoomCbk.bind(this);
    this.StylingPopupLegSpiderfier = this.StylingPopupLegSpiderfier.bind(this);
    this.SpiderifyCbk = this.SpiderifyCbk.bind(this);
    this.updateMarkers = this.updateMarkers.bind(this);

    if (this.Map) {
      this.spiderifier = new MapSpiderfier(
        this.Map,
        (_e, spiderLeg) => {
          props.onSpiderifyObjectClicked(
            spiderLeg?.feature?.properties?.frontEndpoint as string,
          );
        },
        this.StylingPopupLegSpiderfier,
      );
    }
  }

  init(): void {
    this.Map?.on('data', this.ClusterDataCallback);
    this.Map?.on('zoom', this.onZoomCbk);
    this.Map?.on('click', this.SpiderifyCbk);
    this.Map?.on('touchstart', this.SpiderifyCbk);
    this.Map?.on('move', this.updateMarkers);
  }

  onZoomCbk(): void {
    this.spiderifier?.unspiderfy();
  }

  StylingPopupLegSpiderfier(spiderLeg: IMarkerObject): void {
    if (!this.Map) return;

    const cTooltip: HTMLDivElement = document.createElement('div');
    const offset = MapSpiderfier.popupOffsetForSpiderLeg(spiderLeg);
    const featureWithOffset = {
      ...spiderLeg.feature,
      properties: spiderLeg.feature.properties
        ? {
            ...spiderLeg.feature.properties,
            offset,
          }
        : { offset },
    };
    cTooltip.addEventListener('mouseenter', (e) =>
      this.constructorprops?.onSpiderLegHover(featureWithOffset, e),
    );
    cTooltip.addEventListener('mouseleave', (e) =>
      this.constructorprops?.onSpiderLegHover(featureWithOffset, e),
    );

    cTooltip.innerHTML = `<img title="${spiderLeg?.feature?.properties?.description}" src="${spiderLeg?.feature?.properties?.icon}" />`;
    cTooltip.className = 'spider-point-circle';
    if (spiderLeg?.feature?.properties?.color) {
      cTooltip.style.backgroundColor = spiderLeg?.feature?.properties?.color;
      if (
        window.location.pathname.startsWith(
          spiderLeg?.feature?.properties?.frontEndpoint,
        )
      ) {
        cTooltip.style.backgroundColor = 'red';
      }
    }

    spiderLeg.elements.pin.append(cTooltip);

    const popup = new Popup({
      closeButton: true,
      closeOnClick: false,
      offset,
    });

    spiderLeg.mapboxMarker.setPopup(popup);
    spiderLeg.elements.container.onmouseenter = (): void => {
      popup.addTo(this.Map as MaplibreMap);
    };
    spiderLeg.elements.container.onmouseleave = (): void => {
      popup.remove();
    };
  }

  SpiderifyCbk(e: MapMouseEvent): void {
    if (
      !this.Map ||
      !this.constructorprops?.spiderfy_layer ||
      !this.Map.getLayer(this.constructorprops.spiderfy_layer)
    )
      return;
    const features = this.Map.queryRenderedFeatures(e.point, {
      layers: [this.constructorprops.spiderfy_layer],
    });
    this.spiderifier?.unspiderfy();
    if (!features.length) return;

    const source: GeoJSONSource = this.Map.getSource(
      this.constructorprops.source_name,
    ) as GeoJSONSource;
    if (source) {
      if (
        this.Map.getZoom() === this.Map.getMaxZoom() &&
        features[0]?.properties?.cluster_id
      ) {
        source.getClusterLeaves(
          features[0].properties.cluster_id as number,
          100,
          0,

          (_err, leafFeatures) => {
            const markers =
              leafFeatures?.map((leafFeature) => leafFeature) || [];

            const geom = features[0].geometry as Point;

            this.spiderifier?.spiderfy(
              geom.coordinates as [number, number],
              markers,
            );
          },
        );
      } else if (features[0]?.properties?.cluster_id) {
        source.getClusterExpansionZoom(
          features[0].properties.cluster_id as number,
          (err, zoom) => {
            if (err) {
              return;
            }

            const geom = features[0].geometry as Point;
            const center = geom.coordinates as [number, number];
            this.Map?.easeTo({
              center,
              zoom: zoom || 15,
              duration: 200,
            });
            setTimeout(() => {
              this.Map?.resize();
            }, 300);
          },
        );
      }
    }
  }

  disable(): void {
    this.Map?.off('data', this.ClusterDataCallback);
    this.Map?.off('zoom', this.onZoomCbk);
    this.Map?.off('click', this.SpiderifyCbk);
    this.Map?.off('move', this.updateMarkers);

    Object.keys(this.markers).forEach((m: string) => this.markers[m]?.remove());
    this.markers = {};
    this.markersOnScreen = {};
  }

  reinit(): void {
    this.disable();
    this.init();
  }

  updateMarkers(): void {
    // Stolen from https://docs.mapbox.com/mapbox-gl-js/example/cluster-html/
    if (!this.constructorprops) return;
    const { colorList } = this.constructorprops;

    const newMarkers = {};

    const features = this.Map?.querySourceFeatures(
      this.constructorprops.source_name,
    );

    if (!features) return;
    features.forEach((f) => {
      const coords = (f.geometry as Point).coordinates as [number, number];
      const props = f.properties;
      const id = props?.cluster_id;

      let marker = this.markers[id];
      if (!marker && this.constructorprops) {
        const el = MapCluster.createDonutChart(props, colorList);
        if (el)
          this.markers[id] = new Marker({
            element: el,
          }).setLngLat(coords);
        marker = this.markers[id];
      }
      newMarkers[id] = marker;

      if (!this.markersOnScreen[id] && this.Map) marker.addTo(this.Map);
    });

    Object.keys(this.markersOnScreen).forEach((id) => {
      if (!newMarkers[id]) this.markersOnScreen[id].remove();
    });

    this.markersOnScreen = newMarkers;
  }

  /* MapDataEvent export by maplibre is incomplete for some reason but it would be the appropriate type */
  ClusterDataCallback(e: { sourceId: string; isSourceLoaded: boolean }): void {
    if (e.sourceId !== this.constructorprops?.source_name) return;
    this.updateMarkers();
  }

  // HTML BUILDER
  static createDonutChart(
    props: { [key: string]: number },
    colorList: string[],
  ): HTMLElement | null {
    const offsets: number[] = [];
    const counts: number[] = [];
    colorList.forEach((color) => {
      counts.push(props[color]);
    });
    let total = 0;
    counts.forEach((_c, i) => {
      offsets.push(total);
      if (counts[i]) total += counts[i];
    });
    const totalop = total >= 1000 ? 22 : total;
    const totaop2 = totalop >= 100 ? 20 : total;
    const fontSize = totaop2 >= 10 ? 18 : 16;
    let r = 18;
    if (total >= 1000) {
      r = 45;
    } else if (total >= 100) {
      r = 32;
    } else if (total >= 10) {
      r = 24;
    }
    const r0 = Math.round(r * 0.6);
    const w = r * 2;
    let html = `<div><svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif">`;
    if (!total) return document.createElement('div');
    counts.forEach((_c, i) => {
      html += MapCluster.donutSegment(
        offsets[i] / total,
        (offsets[i] + counts[i]) / total,
        r,
        r0,
        colorList[i],
      );
    });
    html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" /><text dominant-baseline="central" transform="translate(${r}, ${r})">${total.toLocaleString()}</text></svg></div>`;

    const el = document.createElement('div');
    el.innerHTML = html;
    return el.firstChild as HTMLElement;
  }

  // HTML BUILDER
  static donutSegment(
    start: number,
    ends: number,
    r: number,
    r0: number,
    color: string,
  ): string {
    let end = ends;
    if (end - start === 1) end -= 0.00001;
    const a0 = 2 * Math.PI * (start - 0.25);
    const a1 = 2 * Math.PI * (end - 0.25);
    const x0 = Math.cos(a0);
    const y0 = Math.sin(a0);
    const x1 = Math.cos(a1);
    const y1 = Math.sin(a1);
    const largeArc = end - start > 0.5 ? 1 : 0;

    return [
      '<path d="M',
      r + r0 * x0,
      r + r0 * y0,
      'L',
      r + r * x0,
      r + r * y0,
      'A',
      r,
      r,
      0,
      largeArc,
      1,
      r + r * x1,
      r + r * y1,
      'L',
      r + r0 * x1,
      r + r0 * y1,
      'A',
      r0,
      r0,
      0,
      largeArc,
      0,
      r + r0 * x0,
      r + r0 * y0,
      `" fill="${color}" />`,
    ].join(' ');
  }
}
