import type { Reducer } from 'redux';
import {
  FETCH_CURRENTUSER,
  FETCH_USERS,
  type UserActionsType,
  type UserState,
} from './types';

// initial state
const initialState: UserState = {
  current: undefined,
  users: [],
};

// User Reducer

export const userReducer: Reducer<UserState, UserActionsType> = (
  state: UserState | undefined,
  action: UserActionsType,
): UserState => {
  if (!state) return initialState;
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case FETCH_CURRENTUSER:
      return {
        ...state,
        current: action.payload,
      };
    default:
      return state;
  }
};
