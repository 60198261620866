import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import type { IChecklist } from '@smack/core/api/models/tasks/Checklist';
import type { AxiosResponse } from 'axios';

export interface IChecklistItem {
  id: number;
  label?: string;
  isChecked?: boolean;
  position?: number;
}

export interface IChecklistItemWithChecklist extends IChecklistItem {
  checklist?: IChecklist;
}

type IChecklistItemPatchData = Omit<IChecklistItem, 'id'>;

export class ChecklistItem implements IChecklistItem {
  id: number;

  label?: string;

  isChecked?: boolean;

  position?: number;

  constructor(data: IChecklistItem) {
    this.id = data.id;
    this.label = data.label;
    this.isChecked = data.isChecked;
    this.position = data.position;
  }

  check(): Promise<void> {
    return RESTClient.put(
      undefined,
      `/tasks/checklist-items/${this.id}/check`,
    ).then(() => {
      this.isChecked = true;
    });
  }

  uncheck(): Promise<void> {
    return RESTClient.put(
      undefined,
      `/tasks/checklist-items/${this.id}/uncheck`,
    ).then(() => {
      this.isChecked = false;
    });
  }

  patch(data: IChecklistItemPatchData): Promise<AxiosResponse> {
    return RESTClient.patch(data, `/tasks/checklist-items/${this.id}`);
  }

  delete(): Promise<void> {
    return RESTClient.delete(`/tasks/checklist-items/${this.id}`);
  }
}
