import {
  type IPagination,
  RESTClient,
} from '@smack/core/api/clients/rest/RESTClient';
import type { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';

interface UsePaginationOutput<T, D = T> {
  count: number;
  next: () => void;
  previous: () => void;
  results: D[];
  isLoading: boolean;
  loadInitial: () => void;
}

export interface UsePaginationProps<T, D = T> {
  query: () => Promise<IPagination<T>>;
  transformer?: (data: T) => D;
}

export const usePagination = <T, D = T>({
  query,
  transformer,
}: UsePaginationProps<T, D>): UsePaginationOutput<T, D> => {
  const [results, setResults] = useState<D[]>([]);
  const [previousUrl, setPreviousUrl] = useState<string>();
  const [nextUrl, setNextUrl] = useState<string>();
  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const transformResult = (items: T[]): D[] => {
    return transformer
      ? items.map(transformer)
      : ((items as unknown as D[]) ?? []);
  };

  const loadInitial = async () => {
    setIsLoading(true);
    try {
      const paginationResult = await query();
      setCount(paginationResult.count ?? 0);
      setNextUrl(paginationResult.next);
      setPreviousUrl(paginationResult.previous);
      setResults(transformResult(paginationResult.results));
    } finally {
      setIsLoading(false);
    }
  };

  const next = useCallback(() => {
    if (!nextUrl) return;
    setIsLoading(true);
    RESTClient.get<AxiosResponse<IPagination<T>>>(nextUrl, undefined, true)
      .then((paginationResult) => {
        setResults((prevResults) => [
          ...prevResults,
          ...transformResult(paginationResult.data.results),
        ]);
        setNextUrl(paginationResult.data.next);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [nextUrl, transformer]);

  const previous = useCallback(() => {
    if (!previousUrl) return;
    setIsLoading(true);
    RESTClient.get<AxiosResponse<IPagination<T>>>(previousUrl, undefined, true)
      .then((paginationResult) => {
        setResults((prevResults) => [
          ...transformResult(paginationResult.data.results),
          ...prevResults,
        ]);
        setPreviousUrl(paginationResult.data.previous);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [previousUrl, transformer]);

  return {
    results,
    next,
    previous,
    count,
    isLoading,
    loadInitial,
  };
};
