import { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import { BaseObjectDetailsViewSkeleton } from '@smack/core/components/ViewRenderer/loaders/skeletons/BaseObjectDetailsViewSkeleton';
import { BaseObjectFormViewSkeleton } from '@smack/core/components/ViewRenderer/loaders/skeletons/BaseObjectFormViewSkeleton';
import { BaseObjectPreviewViewSkeleton } from '@smack/core/components/ViewRenderer/loaders/skeletons/BaseObjectPreviewViewSkeleton';

interface BaseObjectViewLoaderProps {
  viewUsage: ViewUsage;
}

const skeletonsMap = {
  [ViewUsage.DETAILS]: BaseObjectDetailsViewSkeleton,
  [ViewUsage.FORM]: BaseObjectFormViewSkeleton,
  [ViewUsage.SHEET]: BaseObjectFormViewSkeleton,
  [ViewUsage.PREVIEW]: BaseObjectPreviewViewSkeleton,
} as const;

export const BaseObjectViewLoader: React.FC<BaseObjectViewLoaderProps> = ({
  viewUsage,
}) => {
  return skeletonsMap[viewUsage] ?? null;
};
