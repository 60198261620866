import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import type { IUser } from '@smack/core/store/users/types';
import type { AxiosResponse } from 'axios';
import moment, { type Moment } from 'moment';
import type { IGroup } from '../Group';
import { type IStatus, Status } from '../Status';
import { SubscribeManager } from '../SubscribeManager';
import { type ITaskRule, TaskRule } from '../TaskRule';
import {
  type ITemplateProject,
  TemplateProject,
} from '../TemplateProject/TemplateProject';
export interface IProject {
  id: number;
  label?: string;
  description?: string;
  icon?: IconField;
  color?: string;
  createdAt?: string;
  startAt?: string;
  deadlineAt?: string;
  endAt?: string;
  status?: IStatus;
  groups?: IGroup[];
  taskRule?: ITaskRule[];
  duration?: string;
  users?: IUser[];
  baseobjectId?: number;
  tplProject?: ITemplateProject;
  isSubscribed?: boolean;
  isDisplayingCommentsByDefault?: boolean;
  isDisplayingLogsByDefault?: boolean;
  activeTasks?: number;
  completedTasks?: number;
}

type IProjectPatchData = Omit<
  Partial<IProject>,
  'id' | 'user' | 'taskRule' | 'status' | 'isSubscribed' | 'baseobjectId'
>;

export class Project {
  subscribeManager: SubscribeManager;

  id: number;

  label?: string;

  description?: string;

  icon?: IconField;

  color?: string;

  createdAt?: Date;

  startAt?: Moment;

  deadlineAt?: Moment;

  endAt?: Moment;

  status?: Status;

  groups?: IGroup[];

  taskRule?: ITaskRule[];

  duration?: string;

  users?: IUser[];

  baseobjectId?: number;

  tplProject?: TemplateProject;

  isDisplayingCommentsByDefault?: boolean;

  isDisplayingLogsByDefault?: boolean;

  activeTasks?: number;

  completedTasks?: number;

  constructor(data: IProject) {
    this.subscribeManager = new SubscribeManager(
      `/tasks/projects/${data.id}`,
      data.isSubscribed,
    );

    this.id = data.id;
    this.label = data.label;
    this.description = data.description;
    this.icon = data.icon;
    this.color = data.color;
    this.groups = data.groups;
    this.duration = data.duration;
    this.taskRule = data.taskRule;
    this.users = data.users;
    this.baseobjectId = data.baseobjectId;
    this.tplProject = data.tplProject
      ? new TemplateProject(data.tplProject)
      : undefined;
    this.isDisplayingCommentsByDefault = data.isDisplayingCommentsByDefault;
    this.isDisplayingLogsByDefault = data.isDisplayingLogsByDefault;
    this.activeTasks = data.activeTasks;
    this.completedTasks = data.completedTasks;

    if (data.status) {
      this.status = new Status(data.status);
    }
    if (data.startAt) {
      this.startAt = moment(data.startAt);
    }
    if (data.deadlineAt) {
      this.deadlineAt = moment(data.deadlineAt);
    }
    if (data.endAt) {
      this.endAt = moment(data.endAt);
    }
    if (data.createdAt) {
      this.createdAt = new Date(data.createdAt);
    }
  }

  get isSubscribed(): boolean {
    return this.subscribeManager.isSubscribed || false;
  }

  static createProjectFromTemplateId(
    baseobjectId: number | string,
    templateId: number | string,
  ): Promise<AxiosResponse<void>> {
    return RESTClient.post({}, `/tasks/projects/from-template/${templateId}`, {
      baseobject: baseobjectId,
    });
  }

  /**
   * Get the list of project from a baseObject
   * @param baseObjectId number
   * @returns Promise<Project[]>
   */
  static getFromBaseObject(
    baseObjectId?: number | string,
    tplProjectId?: number | string,
  ): Promise<Project[]> {
    const queryParams: {
      baseobject: number | string;
      tplProject?: number | string;
    } = { baseobject: baseObjectId || 0 };
    if (tplProjectId) {
      queryParams['tpl-project'] = tplProjectId;
    }

    return RESTClient.get<{ data: { results: IProject[] } }>(
      '/tasks/projects',
      queryParams,
    ).then((res) => {
      return res.data?.results?.map((proj) => new Project(proj));
    });
  }

  /**
   * From a given category, retrieve all available templates for project creation.
   */
  static getTemplatesFromCategoryId(
    categoryId: number,
  ): Promise<ITemplateProject[]> {
    return RESTClient.get<{ data: { results: ITemplateProject[] } }>(
      `/tasks/tpl-projects?category=${categoryId}`,
    ).then((res) => res.data.results);
  }

  /**
   * Get Detailed Project from project Id
   * @param projectId number
   * @returns Promise<Project>
   */
  static getProject(projectId: number): Promise<Project> {
    return RESTClient.get<{ data: { results: IProject } }>(
      `/tasks/projects/${projectId}`,
    ).then((res) => {
      return new Project(res.data?.results);
    });
  }

  /**
   * get Tasks Rules from the project
   *
   * @return {*}  {TaskRule[]}
   * @memberof Project
   */
  getTaskRules(): TaskRule[] {
    return this.taskRule?.map((rule) => new TaskRule(rule)) || [];
  }

  patch(data: IProjectPatchData): Promise<AxiosResponse> {
    return RESTClient.patch(data, `/tasks/projects/${this.id}`);
  }

  delete(): Promise<AxiosResponse> {
    return RESTClient.delete(`/tasks/projects/${this.id}`);
  }
}
