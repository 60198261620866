import type { AppState } from '@smack/core/store';
import AddressService from '@smack/core/utils/Address';
import { useSelector } from 'react-redux';

export const useAddressService = (): AddressService | undefined => {
  const addressDatabaseUrl = useSelector(
    (state: AppState) => state.App.settings?.addressDatabaseUrl,
  );
  if (!addressDatabaseUrl) return undefined;

  return new AddressService(addressDatabaseUrl);
};
