/**
 * FILE MODEL
 */

import {
  type IPagination,
  RESTClient,
} from '@smack/core/api/clients/rest/RESTClient';
import type { ParentObjectDisplayCardApiOutput } from '@smack/core/api/models/medias/types';
import { User } from '@smack/core/api/models/users/User';
import type { IUser } from '@smack/core/store/users/types';
import {
  isElectronAvailable,
  openFile,
  readFile,
} from '@smack/core/utils/ElectronUtils';
import { t } from 'i18next';
import toast from 'react-hot-toast';
import { type IMediaCategory, MediaCategory } from '../MediaCategory';
import { type IMimetype, Mimetype } from '../Mimetype';

export interface FileApiOutput {
  id: number;
  uuid: string;
  label?: string;
  description?: string;
  filepath?: string;
  virtualFilepath?: string;
  externalFilesystemId?: number;
  category?: IMediaCategory;
  mimetype?: IMimetype;
  isFavorite?: boolean;
  isExportable?: boolean;
  isThumbnail?: boolean;
  size?: number;
  createdAt?: string;
  modifiedAt?: string;
  createdBy?: IUser;
  parentObject?: ParentObjectDisplayCardApiOutput;
  frontEndpoint?: string;
}

export interface FileApiInput
  extends Pick<FileApiOutput, 'label' | 'isFavorite' | 'isExportable'> {
  folderId?: string | number;
  categoryId?: string | number | null;
}

export class File {
  id: number;

  uuid: string;

  label?: string;

  description?: string;

  filepath?: string;

  virtualFilepath?: string;

  externalFilesystemId?: number;

  mimetype?: IMimetype;

  category?: MediaCategory;

  isFavorite?: boolean;

  isExportable?: boolean;

  isThumbnail?: boolean;

  size?: number;

  createdAt?: Date;

  modifiedAt?: Date;

  createdBy?: User;

  parentObject?: ParentObjectDisplayCardApiOutput;

  frontEndpoint?: string;

  constructor(data: FileApiOutput) {
    this.id = data.id;
    this.uuid = data.uuid;
    this.label = data.label;
    this.description = data.description;
    this.filepath = data.filepath;
    this.virtualFilepath = data.virtualFilepath;
    this.externalFilesystemId = data.externalFilesystemId;
    if (data.mimetype) {
      this.mimetype = new Mimetype(data.mimetype);
    }
    if (data.category) {
      this.category = new MediaCategory(data.category);
    }
    this.isFavorite = data.isFavorite;
    this.isExportable = data.isExportable;
    this.isThumbnail = data.isThumbnail;
    this.size = data.size;
    this.createdAt = data.createdAt ? new Date(data.createdAt) : undefined;
    this.modifiedAt = data.modifiedAt ? new Date(data.modifiedAt) : undefined;
    if (data.createdBy) {
      this.createdBy = new User(data.createdBy);
    }
    this.parentObject = data.parentObject;
    this.frontEndpoint = data.frontEndpoint;
  }

  static async searchFiles(
    search: string,
    abortSignal?: AbortSignal,
    limit?: number,
    offset?: number,
  ): Promise<IPagination<File>> {
    const res = await RESTClient.post<IPagination<FileApiOutput>>(
      {
        limit,
        offset,
        q: search,
      },
      '/medias/files/search',
      undefined,
      undefined,
      abortSignal,
    );
    return {
      ...res.data,
      results: res.data.results.map((r) => new File(r)),
    };
  }

  open(): void {
    if (!this.filepath) return;
    if (this.externalFilesystemId) {
      if (!isElectronAvailable()) {
        toast.error(t('medias.openLocalMediasError'));
        return;
      }
      openFile(this.filepath);
    } else {
      window.open(this.filepath, '_blank');
    }
  }

  getSource(): Promise<string> {
    let source: string | Promise<string> = this.filepath ?? '';
    if (this.externalFilesystemId && this.filepath) {
      const bufferPromise = readFile(this.filepath);
      if (bufferPromise)
        source = bufferPromise.then((res: ArrayBuffer) => {
          const blob = new Blob([res]);
          return window.URL.createObjectURL(blob);
        });
    }
    return Promise.resolve(source);
  }
}
