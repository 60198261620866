import { BaseAlert, type IBaseAlert } from '../BaseAlert';

/**
 * InfoAlert Modal base to BaseAlert in AlertModal
 * @param props IBaseAlert
 * @returns JSX.ELEMENT
 */
export const InfoAlert = (props: IBaseAlert): JSX.Element => {
  return (
    <BaseAlert
      color={'#facc15'}
      iconColor={'#fefce8'}
      icon={{ name: 'exclamation' }}
      {...props}
    />
  );
};
