import {
  type IInputProps,
  type INativeInputProps,
  Input,
} from '@smack/core/components/DataInput/Input/Input';
import React from 'react';

export interface ITimeInputProps extends Omit<IInputProps, 'onChange'> {
  min?: number;
  max?: number;
  step?: number;
  onChange?: (data: string) => void;
}

interface INativeTimeInputProps extends INativeInputProps {
  min?: number;
  max?: number;
  step?: number;
}

const TimeInputRender: React.ForwardRefRenderFunction<
  HTMLInputElement,
  ITimeInputProps | INativeTimeInputProps
> = (
  { min, max, step, native, onChange, children, ...inputProps },
  ref,
): JSX.Element => {
  const handleChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (e) => {
      if (onChange) {
        if (native) onChange(e);
        else onChange(e.target.value);
      }
    },
    [onChange, native],
  );

  return (
    <Input
      min={min}
      max={max}
      step={step}
      type="time"
      ref={ref}
      native
      onChange={handleChange}
      {...inputProps}
    >
      {children}
    </Input>
  );
};

TimeInputRender.displayName = 'TimeInput';

export const TimeInput = React.forwardRef(TimeInputRender);
