import type { ReactNode } from 'react';

export interface ModalState {
  current?: ReactNode;
}

// Actions Constants
export const SET_MODAL = 'MODAL::SET_MODAL';
export const CLEAR_MODAL = 'MODAL::CLEAR_MODAL';

// Actions Definitions

export interface ISetModal {
  type: typeof SET_MODAL;
  payload: ReactNode;
}

export interface IClearModal {
  type: typeof CLEAR_MODAL;
  payload?: never;
}

export type ModalActionsType = ISetModal | IClearModal;
