import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import { Tooltip } from '@smack/core/components/DataDisplay/Tooltip';

interface IProps {
  label?: string;
  onClick?: () => void;
  className?: string;
  active?: boolean;
  activeColor?: string;
  activeClassName?: string;
  icon?: IconField;
}

export const NavigationIconButton = (props: IProps): JSX.Element => {
  const {
    label,
    onClick,
    className,
    icon,
    active,
    activeColor,
    activeClassName,
  } = props;

  return (
    <Tooltip key={label} title={label || ''}>
      <div
        onClick={(): void => {
          if (onClick) onClick();
        }}
        style={active && activeColor ? { backgroundColor: activeColor } : {}}
        className={`h-11 w-11 flex items-center justify-center bg-primary ${
          onClick ? 'cursor-pointer' : ''
        } ${className || ''}`}
      >
        <Icon
          icon={icon}
          className={`text-lg h-6 w-6 ${
            active ? activeClassName || 'invert' : 'opacity-60 dark:invert'
          }`}
        />
      </div>
    </Tooltip>
  );
};
