import {
  renderLoginMessages,
  useLoginMessagesContext,
} from '@smack/core/utils/LoginMessagesStorage';
import { Splash } from '@smack/core/views/oldViewsToSort/Layouts/Splash';
import type React from 'react';
import { Outlet } from 'react-router-dom';

export const AuthLayout: React.FC = () => {
  const [loginMessagesStorage] = useLoginMessagesContext();

  return (
    <Splash>
      <div className="max-w-md p-4 space-y-1">
        {renderLoginMessages(loginMessagesStorage)}
      </div>
      <Outlet />
    </Splash>
  );
};
