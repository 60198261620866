/**
 * ReminderRule Model
 */

import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import {
  Attribute,
  type IAttributeApiOutput,
} from '@smack/core/api/models/categories/Attribute/Attribute';
import {
  type IMessageTemplate,
  MessageTemplate,
} from '@smack/core/api/models/notifications/MessageTemplate';
import type { IGroup } from '@smack/core/api/models/users/Group';
import type { IUser } from '@smack/core/store/users/types';

export enum MessageType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  WEBAPP_PUSH = 'WEBAPP_PUSH',
}

export enum MessageSendStatus {
  UNSENT = 'UNSENT',
  SENT_OK = 'SENT_OK',
  ATTEMPT_FAILED = 'ATTEMPT_FAILED',
  ATTEMPTS_EXCEEDED = 'ATTEMPTS_EXCEEDED',
}

export interface IMessage<T = IUser, V = IGroup> {
  type: MessageType;
  recipients: {
    user?: T;
    group?: V;
  }[];
  status?: MessageSendStatus;
}

export interface IReminderRule {
  id: number;
  subject?: string;
  content?: string;
  delay?: string;
  recurrence?: string;
  categoryId?: number;
  baseobjectId?: number;
  messageTemplates?: IMessageTemplate[];
  delayFromAttribute?: IAttributeApiOutput;
  delayFromValue?: string;
  messages?: IMessage[];
  isEditable?: boolean;
}

export interface IReminderRulePost
  extends Pick<
    IReminderRule,
    'subject' | 'content' | 'delay' | 'categoryId' | 'baseobjectId'
  > {
  messages?: IMessage<number, number>[];
  delayFromAttributeId?: number;
  delayFromValue?: string;
  recurrence?: string;
}

/**
 * reminder rule model class
 *
 * @export
 * @class ReminderRule
 */
export class ReminderRule {
  id: number;

  subject?: string;

  content?: string;

  delay?: string;

  delayFromAttribute?: Attribute;

  delayFromValue?: Date;

  recurrence?: string;

  categoryId?: number;

  messages?: IMessage[];

  baseobjectId?: number;

  messageTemplates: MessageTemplate[];

  isEditable = true;

  constructor(data: IReminderRule) {
    this.id = data.id;
    this.subject = data.subject;
    this.content = data.content;
    this.delay = data.delay;
    this.recurrence = data.recurrence;
    this.categoryId = data.categoryId;
    this.baseobjectId = data.baseobjectId;
    this.isEditable = data.isEditable ?? true;
    this.messages = data.messages;
    this.messageTemplates =
      data.messageTemplates?.map(
        (messageTemplate) => new MessageTemplate(messageTemplate),
      ) || [];
    if (data.delayFromAttribute) {
      this.delayFromAttribute = new Attribute(data.delayFromAttribute);
    }
    if (data.delayFromValue) {
      this.delayFromValue = new Date(data.delayFromValue);
    }
  }

  /**
   * get reminder rule from object id
   *
   * @static
   * @param {number} id
   * @return {*}  {Promise<ReminderRule[]>}
   * @memberof ReminderRule
   */
  static getFromBaseObjectId(id: number): Promise<ReminderRule[]> {
    return RESTClient.get<{ data: { results: IReminderRule[] } }>(
      `/reminders/reminder-rules?baseobject=${id}`,
    ).then((res) => res.data.results.map((r) => new ReminderRule(r)));
  }

  /**
   * create reminder rule
   *
   * @static
   * @param {({
   *     [key: string]: string | number;
   *   })} data
   * @return {*}  {Promise<ReminderRule>}
   * @memberof ReminderRule
   */
  static create(data: IReminderRulePost): Promise<ReminderRule> {
    return RESTClient.post<{ results: IReminderRule }>(
      data,
      '/reminders/reminder-rules',
    ).then((res) => new ReminderRule(res.data.results));
  }

  /**
   * patch reminder rule
   *
   * @static
   * @param {number} id
   * @param {({ [key: string]: string | number })} data
   * @return {*}  {Promise<ReminderRule>}
   * @memberof ReminderRule
   */
  static patch(id: number, data: IReminderRulePost): Promise<ReminderRule> {
    return RESTClient.patch<{ results: IReminderRule }>(
      data,
      `/reminders/reminder-rules/${id}`,
    ).then((res) => new ReminderRule(res.data.results));
  }

  /**
   * delete reminder rule
   *
   * @static
   * @param {number} id
   * @return {*}  {Promise<void>}
   * @memberof ReminderRule
   */
  static delete(id: number): Promise<void> {
    return RESTClient.delete<void>(`/reminders/reminder-rules/${id}`);
  }
}
