import type {
  CalendarOptions,
  DateSelectArg,
  EventChangeArg,
  EventClickArg,
  EventInput,
} from '@fullcalendar/core';
import type {
  ColCellContentArg,
  ResourceApi,
  ResourceInput,
  ResourceLabelContentArg,
} from '@fullcalendar/resource';
import { LinkGroup } from '@smack/core/api/models/categories/LinkGroup';
import { BaseObjectGroup } from '@smack/core/api/models/objects/BaseObjectGroup';
import {
  type ILinkDateRange,
  type InputPatchLink,
  Link,
} from '@smack/core/api/models/objects/Link/Link';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import { AvailableView } from '@smack/core/components/DataDisplay/CalendarTimeLine/enums';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import type { DateTimeRangeInputValue } from '@smack/core/components/DataInput/DateRangeInput/DateTimeRangeInput';
import { eqSet } from '@smack/core/utils/equality';
import { spawnModal } from '@smack/core/utils/modal';
import type { IAddLinkFormOutput } from '@smack/core/views/oldViewsToSort/Layouts/Forms/AddLinks/AddLinkForm/AddLinkForm';
import { AddLinks } from '@smack/core/views/oldViewsToSort/Layouts/Forms/AddLinks/AddLinks';
import { TypeRecurrenceUpdateChoice } from '@smack/core/views/oldViewsToSort/Layouts/Modal/RecurrenceUpdateChoiceObjectModal';
import { LinkModal } from '@smack/core/views/oldViewsToSort/Views/Objects/ObjectLinkCalendar/Components/LinkModal/LinkModal';
import type { LinkCalendarModeManager } from '@smack/core/views/oldViewsToSort/Views/Objects/ObjectLinkCalendar/ModeManagers/type';
import i18next from 'i18next';
import type React from 'react';
import { toast } from 'react-hot-toast';

export class TemporalLink implements LinkCalendarModeManager {
  initialView = AvailableView.CALENDAR;

  availableViews = [AvailableView.CALENDAR, AvailableView.TIMELINE];

  private events: EventInput[];
  private groupsMap: { [linkGroupId: number]: LinkGroup };
  private lastFetchProps?: {
    baseobject: number;
    dateRange: ILinkDateRange;
    linkGroups: Set<number>;
  };

  constructor() {
    this.events = [];
    this.groupsMap = {};
    this.lastFetchProps = undefined;
  }

  private resourceIdFromObject(object) {
    if (object.baseobjectGroupId) {
      return `group-${object.baseobjectGroupId}`;
    }

    return `object-${object.id}`;
  }

  private getEventTitle(link: Link): string {
    const { titleViewElement, subtitleViewElement } = link.display;

    const title = titleViewElement?.getValueIfExist() ?? link.baseobject.title;
    const subtitle = subtitleViewElement?.getValueIfExist();

    return subtitle ? `${title} ${subtitle}` : title;
  }

  private async fetchEvents(
    baseObject: BaseObject,
    dateRange: ILinkDateRange | undefined,
    selectedCalendarLinkGroups: number[] | undefined,
    slotColor?: string,
  ) {
    if (!baseObject || !dateRange || !dateRange.dtstart || !dateRange.dtend) {
      return;
    }
    const linkGroups = new Set(selectedCalendarLinkGroups || []);
    if (
      this.lastFetchProps &&
      this.lastFetchProps.baseobject === baseObject.id &&
      this.lastFetchProps.dateRange.dtstart === dateRange.dtstart &&
      this.lastFetchProps.dateRange.dtend === dateRange.dtend &&
      eqSet(this.lastFetchProps.linkGroups, linkGroups)
    ) {
      // Stored data is still relevant
      return;
    }

    try {
      const res = await Link.getLinksFromBaseObjectId(
        baseObject.id,
        dateRange,
        selectedCalendarLinkGroups,
        ViewUsage.TIMELINE,
      );
      this.events = res
        .filter((obj) => obj.datetimeRange?.dtend && obj.datetimeRange.dtstart)
        .map((obj) => ({
          id: obj.id.toString(),
          end: obj.datetimeRange?.dtend,
          start: obj.datetimeRange?.dtstart,
          object: obj.baseobject,
          title: this.getEventTitle(obj),
          frontEndpoint: obj.baseobject?.frontEndpoint,
          link: obj,
          resourceId: `${obj.linkGroup.id}-${this.resourceIdFromObject(
            obj.baseobject,
          )}`,
          backgroundColor: slotColor,
          borderColor: slotColor,
          linkGroupId: obj.linkGroup.id,
        }));

      const groups = await LinkGroup.getChildLinkGroupFromBaseObjectId(
        baseObject.id,
      );
      this.groupsMap = groups
        .filter((group) => group.isTimeSensitive)
        .reduce((acc, group) => {
          acc[group.sourceLinkGroup || group.id] = group;
          return acc;
        }, {});

      for (const e of this.events) {
        e.linkGroup = this.groupsMap?.[e.linkGroupId];
      }

      this.lastFetchProps = {
        baseobject: baseObject.id,
        dateRange,
        linkGroups,
      };
    } catch (error) {
      return;
    }
  }

  async getEvents(
    baseObject: BaseObject,
    dateRange: ILinkDateRange,
    calendarView?: AvailableView,
    resources?: ResourceInput[],
    selectedCalendarLinkGroups?: number[],
    slotColor?: string,
  ): Promise<EventInput[]> {
    await this.fetchEvents(
      baseObject,
      dateRange,
      selectedCalendarLinkGroups,
      slotColor,
    );

    return this.events;
  }

  async getResources(
    baseObject: BaseObject,
    dateRange?: ILinkDateRange,
    selectedCalendarLinkGroups?: number[],
    slotColor?: string,
  ): Promise<ResourceInput[]> {
    await this.fetchEvents(
      baseObject,
      dateRange,
      selectedCalendarLinkGroups,
      slotColor,
    );

    const usedGroups = new Set<number>();
    const resourceMap = {};
    for (const e of this.events) {
      const resourceId = e.resourceId || '';
      resourceMap[resourceId] = {
        id: e.resourceId,
        title: e.object.title,
        baseobject: e.object,
        linkGroup: e.linkGroup,
        group: e.linkGroup.label,
      };
      usedGroups.add(e.linkGroup.id);
    }

    for (const linkGroup of Object.values(this.groupsMap)) {
      if (usedGroups.has(linkGroup.id)) continue;
      if (
        selectedCalendarLinkGroups?.length &&
        !selectedCalendarLinkGroups.includes(linkGroup.id)
      )
        continue;

      resourceMap[`nocontent-${linkGroup.id}`] = {
        id: `nocontent-${linkGroup.id}`,
        title: i18next.t('TemporalLink.noContent'),
        linkGroup: linkGroup,
        group: linkGroup.label,
      };
    }

    return Object.values(resourceMap);
  }

  async onCalendarChangeDate(
    event: EventChangeArg,
    callback?: () => void,
  ): Promise<void> {
    if (!event.event.end || !event.event.start) {
      toast(i18next.t('calendarTimeline.errors.changeDate'));
      event.revert();
      return Promise.resolve();
    }

    const data: InputPatchLink = {
      datetimeRange: {
        dtend: event.event.end?.toISOString(),
        dtstart: event.event.start?.toISOString(),
      },
    };

    // get the resource
    const resource: ResourceApi | undefined = event.event.getResources().at(0);
    if (resource) {
      const linkGroup: LinkGroup = resource.extendedProps.linkGroup;
      const baseObject: BaseObject = resource.extendedProps.baseobject;
      if (linkGroup && baseObject) {
        data.linkGroupId = linkGroup.id;
        data.targetBaseobjectId = baseObject.id;
      }
    }

    return Link.patchLink(Number.parseInt(event.event.id), data)
      .then(() => {
        callback?.();
      })
      .catch(() => {
        toast(i18next.t('calendarTimeline.errors.changeDate'));
        event.revert();
      });
  }

  handleValidate(
    baseObject: BaseObject,
    data: IAddLinkFormOutput,
    callBack?: () => void,
  ): void {
    if (
      baseObject.baseobjectGroupId &&
      data.baseObjectGroupChoice !== TypeRecurrenceUpdateChoice.THIS
    ) {
      BaseObjectGroup.addLink(baseObject, data.linkGroup.id, data)
        .then(() => callBack?.())
        .catch(() => {
          toast('errorMessage.linkCreationFail');
        });
    } else {
      baseObject
        .addLink(data)
        .catch(() => {
          toast('errorMessage.linkCreationFail');
        })
        .then(() => callBack?.());
    }
  }

  onCalendarEmptyClick(
    event: DateSelectArg | undefined,
    baseObject: BaseObject,
    callback?: () => void,
  ): void {
    const initialBaseobject: BaseObject | undefined =
      event?.resource?.extendedProps?.baseobject;

    // All objects from a group are rendered on the same line, creating a new
    // link requires a way to choose which object especially in a time based
    // context.
    if (initialBaseobject?.baseobjectGroupId) return;

    const linkGroup: LinkGroup | undefined =
      event?.resource?.extendedProps?.linkGroup;

    const initialRange: DateTimeRangeInputValue = {
      endDate: event?.end,
      startDate: event?.start,
    };
    spawnModal({
      render: ({ onClose }) => {
        return (
          <AddLinks
            linkGroup={linkGroup}
            sourceBaseObjectId={baseObject.id}
            onlyTemporalLinkGroup
            onQuit={onClose}
            noClickOutside
            onSubmit={(data) => {
              this.handleValidate(baseObject, data, () => {
                callback?.();
                onClose();
              });
            }}
            initialDateRange={initialRange}
            initialBaseObjectsValue={
              initialBaseobject ? [initialBaseobject] : []
            }
            open={true}
            onClose={onClose}
          />
        );
      },
    });
  }

  getResourceLabelContent(
    el: ResourceLabelContentArg,
    baseObject?: BaseObject,
    callback?: () => void,
  ): React.ReactElement {
    const resource = el.resource;
    if (!baseObject) return <span>{resource.title}</span>;
    const onClick = () =>
      this.onCalendarEmptyClick(el as DateSelectArg, baseObject, callback);
    return (
      <span onClick={onClick} className={'cursor-pointer'}>
        {resource.title}
      </span>
    );
  }

  getResourceGroupLabelContent(el: ColCellContentArg): React.ReactElement {
    let linkGroup: LinkGroup | undefined = undefined;

    for (const [, val] of Object.entries(this.groupsMap ?? {})) {
      if (val.label === el.groupValue) {
        linkGroup = val;
      }
    }

    return (
      <span className={'inline-block ml-2'}>
        <div className="flex items-center gap-2">
          {linkGroup?.icon && <Icon icon={linkGroup.icon} />}
          {el.groupValue}
        </div>
      </span>
    );
  }

  onClickEvent(arg: EventClickArg, reloadCalendar?: () => void) {
    const linkGroup = this.groupsMap?.[arg.event.extendedProps.linkGroupId];
    if (!linkGroup) return;
    const link: Link = arg.event.extendedProps.link;
    spawnModal({
      render: ({ onClose }) => {
        return (
          <LinkModal
            linkGroup={linkGroup}
            icon={{ name: 'link' }}
            open={true}
            onUpdateLink={(inputLink) => {
              return Link.patchLink(link.id, {
                targetBaseobjectId: inputLink.targetBaseobjectId,
                linkGroupId: inputLink.linkGroupId,
                datetimeRange: inputLink.datetimeRange,
                weight: inputLink.weight,
              }).then(reloadCalendar);
            }}
            onDeleteLink={() =>
              Link.removeLink(link.id).then(() => {
                reloadCalendar?.();
                onClose();
              })
            }
            onClose={() => onClose()}
            link={{
              id: link.id,
              targetBaseobjectId: link.baseobject.id,
              targetBaseObjectTitle: link.baseobject.title,
              weight: link.weight ?? undefined,
              datetimeRange: link.datetimeRange ?? undefined,
              linkGroupId: link.linkGroup.id,
            }}
          />
        );
      },
    });
  }

  getCalendarOptions(
    baseObject?: BaseObject,
    callback?: () => void,
  ): CalendarOptions {
    return {
      resourceLabelContent: (el) =>
        this.getResourceLabelContent(el, baseObject, callback),
      resourceGroupLabelContent: (el) => this.getResourceGroupLabelContent(el),
    };
  }
}
