import type React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ConfirmBaseAlert, type IConfirmBaseProps } from '../ConfirmBaseAlert';

export const DeleteConfirmAlert: React.FC<IConfirmBaseProps> = ({
  text,
  title,
  ...props
}): JSX.Element => {
  const [t] = useTranslation();
  const defaultText = (
    <p>
      <Trans t={t} i18nKey="dataDisplayActions.confirmDeleteAction" />
    </p>
  );

  return (
    <ConfirmBaseAlert
      {...props}
      icon={{ name: 'trash-can' }}
      title={title || t('dataDisplayActions.aboutToDelete')}
      text={text || defaultText}
    />
  );
};
