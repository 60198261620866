import { ErrorBoundary } from '@sentry/react';
import { DangerAlert } from '@smack/core/components/DataDisplay/Alerts/Alerts';
import type React from 'react';

interface IErrorHandlerForInputProps {
  children: React.ReactNode;
  title: string;
  text: string;
}

export const ErrorHandlerForInput: React.FC<IErrorHandlerForInputProps> = ({
  title,
  text,
  children,
}) => (
  <ErrorBoundary
    fallback={
      <DangerAlert title={title}>
        <p>{text}</p>
      </DangerAlert>
    }
  >
    {children}
  </ErrorBoundary>
);
