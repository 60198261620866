import type { FieldProps } from '@smack/core/api/models/views/ViewElement/ViewElement';
import { DisplayedFieldType } from '@smack/core/api/models/views/ViewElement/enums';
import {
  type INumberIconInputProps,
  NumberIconInput,
} from '@smack/core/components/DataInput/NumberIconInput/NumberIconInput';
import {
  type IPlusMinusInputProps,
  PlusMinusInput,
} from '@smack/core/components/DataInput/PlusMinusInput/PlusMinusInput';
import { NumberParametersValidationSchema } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeFieldsByType/ParametersValidationSchema/ParametersValidationSchema';
import {
  getAdditionalParametersForAttribute,
  getErrorMessageFromErrorField,
} from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeFieldsByType/common';
import type { ReactElement } from 'react';

export interface LinkFields {
  type: DisplayedFieldType;
  render: (props: FieldProps) => ReactElement;
}

export const LinkFormFields: LinkFields[] = [
  {
    type: DisplayedFieldType.PLUS_MINUS,
    render: (props) => {
      return (
        <div className="h-6 w-28">
          <PlusMinusInput
            {...getAdditionalParametersForAttribute<IPlusMinusInputProps>(
              props?.parameters,
              NumberParametersValidationSchema,
            )}
            {...props?.controllerProps}
            error={getErrorMessageFromErrorField(props?.error)}
          />
        </div>
      );
    },
  },
  {
    type: DisplayedFieldType.NUMBER_INPUT_DISPLAY,
    render: (props) => {
      return (
        <div className="max-h-[28px] max-w-[100px] ">
          <NumberIconInput
            {...getAdditionalParametersForAttribute<INumberIconInputProps>(
              props?.parameters,
              NumberParametersValidationSchema,
            )}
            {...props?.controllerProps}
            spinners={false}
            contentTextSize="xs"
            affixPadding="small"
          />
        </div>
      );
    },
  },
];

export const LinkReadOnlyFields: LinkFields[] = [
  {
    type: DisplayedFieldType.TEXT_DISPLAY,
    render: (props: FieldProps) => {
      return <>{props.viewElement?.getValueIfExist()}</>;
    },
  },
];
