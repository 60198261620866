import React from 'react';

import { MessageRecipientIndication } from '@smack/core/api/models/notifications/MessageRecipient';
import { MessageTemplateType } from '@smack/core/api/models/notifications/MessageTemplate';
import type { TaskReminderRule } from '@smack/core/api/models/tasks/TaskReminderRule';
import { Tooltip } from '@smack/core/components/DataDisplay/Tooltip';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

interface IProps {
  taskReminderRule: TaskReminderRule;
}

const typeToKey = Object.freeze({
  [MessageTemplateType.EMAIL]: 'email',
  [MessageTemplateType.SMS]: 'sms',
  [MessageTemplateType.APP_NOTIFICATION]: 'appNotification',
  [MessageTemplateType.WEBAPP_PUSH]: 'webappPush',
});

export const ActivateReminder: React.FC<IProps> = ({ taskReminderRule }) => {
  const scheduleNodes: React.ReactNode[] = [];
  const sendNodes: React.ReactNode[] = [];

  const { t } = useTranslation();

  scheduleNodes.push(
    <React.Fragment key={taskReminderRule.id}>
      {t('reminders.schedule', {
        context: taskReminderRule?.delay?.startsWith('-') ? 'before' : 'after',
        duration: moment.duration(taskReminderRule.delay).humanize(),
        event:
          taskReminderRule.delayFromAttribute?.label ||
          taskReminderRule.isDelayFromDeadline
            ? t('reminders.event.delayFromDeadline')
            : t('reminders.event.arbitraryDate', {
                date: new Date().toLocaleDateString(),
              }),
      })}
    </React.Fragment>,
  );

  for (const messageTemplate of taskReminderRule.messageTemplates) {
    const transKey = typeToKey[messageTemplate.type] || 'generic';
    const recipients: [string, string[]][] = [
      ['toPhone', messageTemplate.getAllRecipientsPhoneNumbers()],
      [
        'to',
        messageTemplate.getAllRecipientsEmailByIndication(
          MessageRecipientIndication.TO,
        ),
      ],
      [
        'cc',
        messageTemplate.getAllRecipientsEmailByIndication(
          MessageRecipientIndication.CC,
        ),
      ],
      [
        'bcc',
        messageTemplate.getAllRecipientsEmailByIndication(
          MessageRecipientIndication.BCC,
        ),
      ],
    ];
    sendNodes.push(
      <Tooltip
        key={messageTemplate.id}
        title={
          <>
            <p className="w-[30ch] truncate">
              {taskReminderRule.subject ||
                taskReminderRule.messageTemplates?.at(0)?.subject ||
                taskReminderRule.messageTemplates?.at(0)?.body}
            </p>
            {recipients
              .filter(([, recipientList]) => recipientList.length)
              .map(([key, recipientList]) => (
                <p key={key}>
                  {t(`tasks.rulesDescription.indication.${key}`)}{' '}
                  {recipientList.join(', ')}
                </p>
              ))}
          </>
        }
        containerProps={{ className: 'inline' }}
      >
        <span
          className="text-blue-500 font-medium hover:text-blue-600"
          key={messageTemplate.id}
        >
          {t(`tasks.rulesDescription.reminderAction.${transKey}`)}
        </span>
      </Tooltip>,
    );
  }

  return (
    <Trans
      t={t}
      i18nKey="tasks.rulesDescription.activateReminder"
      components={{
        schedule: <>{scheduleNodes}</>,
        reminder: (
          <>
            {sendNodes.length > 1
              ? sendNodes.flatMap((node, i, array) => [
                  node,
                  <>
                    {i === array.length - 1
                      ? ` ${t('tasks.rulesDescription.reminderAction.and')}`
                      : ','}{' '}
                  </>,
                ])
              : sendNodes}
          </>
        ),
      }}
    />
  );
};
