import {
  type IViewElement,
  ViewElement,
} from '@smack/core/api/models/views/ViewElement/ViewElement';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';

export interface IViewSection {
  id: number;
  label: string;
  icon?: IconField;
  color?: string;
  position?: number;
  isHeadless?: boolean;
  viewElements?: IViewElement[];
}

export class ViewSection {
  id: number;

  label: string;

  icon?: IconField;

  color?: string;

  position?: number;

  isHeadless?: boolean;

  viewElements: ViewElement[];

  constructor(data: IViewSection) {
    this.id = data.id;
    this.label = data.label;
    this.label = data.label;
    this.icon = data.icon;
    this.color = data.color;
    this.position = data.position;
    this.isHeadless = data.isHeadless;
    this.viewElements = (data.viewElements ?? []).map(
      (viewElementApiOutput) => new ViewElement(viewElementApiOutput),
    );
  }
}
