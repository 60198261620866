import { useUploady } from '@rpldy/uploady';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Webcam, { type WebcamProps } from 'react-webcam';
import { IconButton } from '../Buttons/Button';
import { CameraList } from '../CameraList';

/** The default video constraints. */
const DEFAULT_VIDEO_CONSTRAINTS: MediaTrackConstraints = {
  facingMode: 'environment',
};

/**
 * Input properties of the snapshot camera component.
 */
export interface ISnapshotCameraPropsProps
  extends Omit<Partial<WebcamProps>, 'videoConstraints'> {
  /**
   * The name of the file
   * (typically the label of the form field)
   * (must NOT include the file extension).
   */
  filename?: string;
  /** OPTIONAL - The initial constraints of the video. */
  initialVideoConstraints?: MediaTrackConstraints;
}

/**
 * Creates a `SnapshotCamera` component
 * to take pictures from the user's device's webcam.
 *
 * @param props - The input properties of the component. Default values:
 * - `width`: `1280`px
 * - `height`: `720`px
 * - `screenshotFormat`: `'image/png'`
 * - `videoConstraints`: `{ facingMode: 'environment' }`
 * @returns The created `SnapshotCamera` component.
 */
export const SnapshotCamera = ({
  width = 1280,
  height = 720,
  screenshotFormat = 'image/png',
  initialVideoConstraints = DEFAULT_VIDEO_CONSTRAINTS,
  filename = 'snapshot',
  ...webcamProps
}: ISnapshotCameraPropsProps): JSX.Element => {
  const [t] = useTranslation();

  const [webcamStatus, setWebcamStatus] =
    React.useState<PermissionState>('prompt');
  const [webcamConstraints, setWebcamConstraints] =
    React.useState<MediaTrackConstraints>(initialVideoConstraints);
  const [imgSrc, setImgSrc] = React.useState<string | null>();
  const [imgCanvas, setImgCanvas] = React.useState<HTMLCanvasElement | null>(
    null,
  );
  const [openCameraListModal, setOpenCameraListModal] = React.useState(false);
  const uploady = useUploady();

  const webcamRef: React.MutableRefObject<Webcam | null> = React.useRef(null);

  const generateFile = (): Promise<File | null> => {
    return new Promise<File | null>((resolve) => {
      imgCanvas?.toBlob((blob: Blob | null) => {
        if (!blob) return resolve(null);
        const currentDate: string = format(new Date(), 'dd-MM-yy-HHmmss');
        // TODO: improve way to get file extension (mime-types or mime-db NPM packages?)
        const extension: string | null = blob.type.split('/').at(1) ?? null;
        const name = `${filename}_${currentDate}${
          extension ? `.${extension}` : ''
        }`;
        resolve(new File([blob], name, { type: blob.type }));
      });
    });
  };

  const onSelectCamera = (deviceId: string): void => {
    setWebcamConstraints((value: MediaTrackConstraints | null) => ({
      ...value,
      deviceId,
    }));
    setOpenCameraListModal(false);
  };

  const onOpenCameraListModal = (): void => {
    setOpenCameraListModal(true);
  };

  const onUpdateWebcamStatus = (status: PermissionState): void => {
    setWebcamStatus(status);
  };

  /* NOTE: simultaneous use both source and canvas:
   * canvas: allows to create a 'File' instance without using 'URL.createObjectURL()'
   * source: direct use in <img> HTML element without more boilerplate
   */
  const onCapture = React.useCallback(() => {
    setImgSrc(webcamRef?.current?.getScreenshot() ?? null);
    setImgCanvas(webcamRef?.current?.getCanvas() ?? null);
  }, [webcamRef]);

  const onRetake = (): void => {
    setImgSrc(null);
    setImgCanvas(null);
  };

  const onHandleConfirm = async (): Promise<void> => {
    const file: File | null = await generateFile();
    if (!file) return;
    uploady.upload(file);
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="relative">
          {imgSrc ? (
            <img
              width={width}
              height={height}
              src={imgSrc}
              alt={t('medias.webcam.imgAltText')}
            />
          ) : webcamStatus === 'denied' ? (
            <div
              className="flex flex-col items-center gap-2 py-4"
              data-testid="error-part"
            >
              <strong>{t('medias.webcam.noPermission')}</strong>
              <small>{t('medias.webcam.hintNoPermission')}</small>
            </div>
          ) : (
            <Webcam
              {...webcamProps}
              width={width}
              height={height}
              // Prevent to have bad resolution (without, based on the element size in the DOM)
              forceScreenshotSourceSize
              screenshotFormat={screenshotFormat}
              videoConstraints={webcamConstraints}
              onUserMedia={(): void => onUpdateWebcamStatus('granted')}
              onUserMediaError={(): void => onUpdateWebcamStatus('denied')}
              ref={webcamRef}
            />
          )}
          {webcamStatus === 'granted' && (
            <>
              <div className="flex gap-2 absolute right-2 top-2">
                <IconButton
                  icon={{ name: 'camera-rotate' }}
                  onClick={onOpenCameraListModal}
                  data-testid="camera-list-btn"
                  title={t('medias.webcam.listModalTitle')}
                />
              </div>
              <div className="flex gap-2 absolute right-2 bottom-2">
                {imgCanvas ? (
                  <>
                    <IconButton
                      icon={{ name: 'repeat' }}
                      onClick={onRetake}
                      data-testid="recapture-btn"
                    >
                      {t('medias.webcam.recapturePicture')}
                    </IconButton>
                    <IconButton
                      icon={{ name: 'check' }}
                      onClick={onHandleConfirm}
                      data-testid="confirm-btn"
                    >
                      {t('medias.webcam.confirmPicture')}
                    </IconButton>
                  </>
                ) : (
                  <IconButton
                    icon={{ name: 'camera' }}
                    onClick={onCapture}
                    data-testid="capture-btn"
                  >
                    {t('medias.webcam.capturePicture')}
                  </IconButton>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <Modal
        open={openCameraListModal}
        onClose={setOpenCameraListModal}
        icon={{ name: 'camera' }}
        title={t('medias.webcam.listModalTitle')}
      >
        <p className="text-sm text-gray-500">
          {t('medias.webcam.listModalHint')}
        </p>
        <CameraList onSelect={onSelectCamera} />
      </Modal>
    </>
  );
};
