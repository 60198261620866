import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import {
  Category,
  type DefaultGeoJSON,
  type ICategory,
} from '@smack/core/api/models/categories/Category/index';
import type { ViewName } from '@smack/core/hooks/views/types';

export interface ISubCategory extends ICategory {
  isMobileAvailable?: boolean;
  isMapFilterAvailable?: boolean;
  isCalendarFilterAvailable?: boolean;
  hasDisplayMap?: boolean;
  hasDisplayTable?: boolean;
  hasDisplayCalendar?: boolean;
  hasDisplayKanban?: boolean;
  hasDisplayObjectCalendar?: boolean;
  hasDisplayObjectRoomCalendar?: boolean;
  hasDisplayObjectFunctional?: boolean;
  displayViewDefault?: ViewName;
  displayMapShapeDefault?: DefaultGeoJSON;
  displayObjectCalendarTitle?: string;
}

export class SubCategory extends Category {
  isMobileAvailable?: boolean;

  isMapFilterAvailable?: boolean;

  isCalendarFilterAvailable?: boolean;

  hasDisplayMap?: boolean;

  hasDisplayTable?: boolean;

  hasDisplayCalendar?: boolean;

  hasDisplayKanban?: boolean;

  hasDisplayObjectRoomCalendar?: boolean;

  hasDisplayObjectCalendar?: boolean;

  hasDisplayObjectFunctional?: boolean;

  displayViewDefault?: ViewName;

  displayMapShapeDefault?: DefaultGeoJSON;

  displayObjectCalendarTitle?: string;

  constructor(data: ISubCategory) {
    super(data);
    this.isMobileAvailable = data.isMobileAvailable;
    this.isMapFilterAvailable = data.isMapFilterAvailable;
    this.isCalendarFilterAvailable = data.isCalendarFilterAvailable;
    this.hasDisplayMap = data.hasDisplayMap;
    this.hasDisplayTable = data.hasDisplayTable;
    this.hasDisplayCalendar = data.hasDisplayCalendar;
    this.hasDisplayKanban = data.hasDisplayKanban;
    this.hasDisplayObjectRoomCalendar = data.hasDisplayObjectRoomCalendar;
    this.hasDisplayObjectCalendar = data.hasDisplayObjectCalendar;
    this.hasDisplayObjectFunctional = data.hasDisplayObjectFunctional;
    this.displayViewDefault = data.displayViewDefault;
    this.displayMapShapeDefault = data.displayMapShapeDefault;
    this.displayObjectCalendarTitle = data.displayObjectCalendarTitle;
  }

  static getChildCategories(
    parentCategoryId: number | string,
  ): Promise<SubCategory[]> {
    return RESTClient.get<{ data: { results: ISubCategory[] } }>(
      `/categories/categories?parent-category=${parentCategoryId}`,
    ).then((res) => {
      return res.data.results.map((cat) => new SubCategory(cat));
    });
  }
}
