export interface IAttributeModification {
  id: number;
  label?: string;
}

export interface ITaskAttributeModification {
  attributeModification: IAttributeModification;
}

export class TaskAttributeModification {
  id?: number;

  label?: string;

  constructor(data: ITaskAttributeModification) {
    this.id = data.attributeModification.id;
    this.label = data.attributeModification?.label;
  }
}
