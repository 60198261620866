export enum DisplayedFieldType {
  // FormFields
  ADDRESS = 'ADDRESS',
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  DATE_RANGE = 'DATE_RANGE',
  LOCATION_INPUT = 'LOCATION_INPUT',
  GEOMETRIES = 'GEOMETRIES',
  MULTISELECT = 'MULTISELECT',
  NUMBER = 'NUMBER',
  RADIO = 'RADIO',
  RECURRENCE = 'RECURRENCE',
  RECURRENCE_SIMPLIFIED = 'RECURRENCE_SIMPLIFIED',
  SELECT = 'SELECT',
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
  MEDIA = 'MEDIA',
  MEDIA_MULTISELECT = 'MEDIA_MULTISELECT',
  TIME = 'TIME',
  TIMESELECT = 'TIMESELECT',
  PHONE = 'PHONE',
  WYSIWYG = 'WYSIWYG',
  LINK_SELECT = 'LINK_SELECT',
  LINK_BLOCK = 'LINK_BLOCK',
  MEDIA_WITH_CAMERA = 'MEDIA_WITH_CAMERA',
  PLUS_MINUS = 'PLUS_MINUS',
  NUMBER_INPUT_DISPLAY = 'NUMBER_INPUT_DISPLAY',
  SIGNATURE = 'SIGNATURE',
  // Readonly Fields
  RICH_TEXT = 'RICH_TEXT',
  TEXT_DISPLAY = 'TEXT_DISPLAY',
  LOCATION_DISPLAY = 'LOCATION_DISPLAY',
  PROGRESS_BAR = 'PROGRESS_BAR',
  BADGE = 'BADGE',
}
