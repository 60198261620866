import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import type React from 'react';

export interface CardIconProps {
  icon: IconField;
  iconColor?: string;
  backGroundColor?: string;
}

export const CardIcon: React.FC<CardIconProps> = ({
  icon,
  backGroundColor = '#3EA6ED',
  iconColor = '#fff',
}) => {
  return (
    <div
      style={{ backgroundColor: backGroundColor }}
      className=" mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10"
    >
      {icon && <Icon color={iconColor} icon={icon} />}
    </div>
  );
};
