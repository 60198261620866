import { BaseAlert, type IBaseAlertProps } from '../BaseAlert';

/**
 * Info Alerts from BaseAlert to use for show an information
 * @param props IBaseAlertProps
 * @returns JSX.Element
 */
export const InfoAlert = (props: IBaseAlertProps): JSX.Element => {
  const { children, title, className } = props;
  return (
    <BaseAlert
      className={className}
      icon={{ name: 'info-circle' }}
      title={title || 'Information'}
      color={'#EFF6FF'}
      textColor={'#3886F1'}
    >
      {children}
    </BaseAlert>
  );
};
