import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import type { Category } from '@smack/core/api/models/categories/Category';
import { ListElementBaseObject } from '@smack/core/api/models/objects/NewBaseObject/ListElementBaseObject';
import {
  type FiltersQueryParams,
  type IFilters,
  getParamsFromFilters,
  queryParamsByMode,
} from '@smack/core/utils/Filters';
import { CommonBaseObject, type ICommonBaseObject } from '..';

export interface IListBaseObject extends ICommonBaseObject {
  scheduleId?: number;
}

export class ListBaseObject
  extends CommonBaseObject<IListBaseObject>
  implements IListBaseObject
{
  scheduleId?: number;

  constructor(data: IListBaseObject) {
    super(data);

    this.scheduleId = data.scheduleId;
  }

  static getBaseObjectForListRepresentation(
    parentCategory: Category,
    filters?: IFilters,
    offset?: number,
    limit?: number,
    queryParamsMode: FiltersQueryParams[] = queryParamsByMode.standard,
  ): Promise<{
    data: {
      count: number;
      next: string;
      previous: string;
      results: IListBaseObject[];
    };
  }> {
    return RESTClient.get<{
      data: {
        count: number;
        next: string;
        previous: string;
        results: IListBaseObject[];
      };
    }>(
      '/objects/baseobjects?',
      getParamsFromFilters(filters ?? {}, queryParamsMode, {
        parentCategory: parentCategory?.id,
        offset,
        limit,
      }),
    );
  }

  static async getBaseObjectForUnpaginatedListRepresentation(
    parentCategory?: Category,
    filters?: IFilters,
    signal?: AbortSignal,
    customParams: Record<string, unknown> = {},
  ): Promise<ListBaseObject[]> {
    return RESTClient.getIteratePages<{
      data: {
        results: IListBaseObject[];
      };
    }>(
      '/objects/baseobjects',
      {
        ...getParamsFromFilters(filters ?? {}, queryParamsByMode.calendar, {
          parentCategory: parentCategory?.id,
          additionalQueryParam: customParams ?? {},
          limit: 100,
        }),
        'with-schedule': 1,
      },
      signal,
    ).then((res) =>
      res.data.results.map((obj) => new ListElementBaseObject(obj)),
    );
  }
}
