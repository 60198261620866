import { SeverityAlertComponent } from '@smack/core/components/DataDisplay/Alerts/Alerts';
import { loginMessagesStorageReducer } from '@smack/core/utils/LoginMessagesStorage/reducer';
import type {
  LoginMessagesStorage,
  LoginMessagesStorageAction,
} from '@smack/core/utils/LoginMessagesStorage/types';
import React from 'react';

export const renderLoginMessages = (
  storage: LoginMessagesStorage,
): JSX.Element[] => {
  return storage.map((message) => {
    const AlertComponent = SeverityAlertComponent[message.severity];
    return (
      <AlertComponent key={message.id} title={message.title}>
        <p>{message.message}</p>
      </AlertComponent>
    );
  });
};

function alertAboutMissingContextProvider(): unknown {
  if (import.meta.env.PROD) return;
  console.warn('<LoginMessagesProvider> is missing.');
  console.trace();
}
/* eslint-enable no-console */

type LoginMessagesContextType = [
  LoginMessagesStorage,
  React.Dispatch<LoginMessagesStorageAction>,
];

export const LoginMessagesContext =
  React.createContext<LoginMessagesContextType>([
    [],
    alertAboutMissingContextProvider,
  ]);

export const useLoginMessagesContext = (): LoginMessagesContextType =>
  React.useContext(LoginMessagesContext);

export const LoginMessagesProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = React.useReducer(loginMessagesStorageReducer, []);

  return (
    <LoginMessagesContext.Provider value={value}>
      {children}
    </LoginMessagesContext.Provider>
  );
};
