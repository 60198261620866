import type { Basemap } from '@smack/core/api/models/maps/Basemap';
import type { Layer } from '@smack/core/api/models/maps/Layer';
import { env } from '@smack/core/env';
import {
  type Map as MaplibreMap,
  NavigationControl,
  type StyleSpecification,
} from 'maplibre-gl';

export const buildMapStyle = (
  basemaps: Basemap[],
  activeBasemap?: number,
): StyleSpecification => {
  const output: StyleSpecification = {
    version: 8,
    glyphs: env.VITE_FONT_CARTO_URL,
    sources: {},
    layers: [],
  };
  let basemap = basemaps.find((b) => b.id === activeBasemap);
  if (!basemap) basemap = basemaps.find((b) => b.isDefault);
  if (!basemap && basemaps.length) basemap = basemaps[0];
  if (!basemap) return output;
  // BASEMAP
  if (output.sources)
    output.sources[`root-basemap-${basemap.label}`] = {
      type: 'raster',
      tiles: [basemap.basemap],
      tileSize: 256,
      attribution: '',
    };
  if (output.layers)
    output.layers.push({
      id: `root-basemap-layer-${basemap.label}`,
      type: 'raster',
      source: `root-basemap-${basemap.label}`,
      minzoom: 1,
      maxzoom: 23,
      paint: {
        'raster-opacity': 1,
      },
    });

  if (basemap.overlays?.length) {
    const overlayid = `root-overlay-${basemap.label}`;
    if (output.sources)
      output.sources[overlayid] = {
        type: 'raster',
        tiles: basemap.overlays,
        tileSize: 256,
        attribution: '',
      };
    if (output.layers)
      output.layers.push({
        id: `root-overlay-layer-${basemap.label}`,
        type: 'raster',
        source: overlayid,
        minzoom: 1,
        maxzoom: 23,
        paint: {
          'raster-opacity': 1,
        },
      });
  }
  const overlayid = 'root-layer-source';
  if (output.sources)
    output.sources[overlayid] = {
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    };
  if (output.layers)
    output.layers.push({
      id: 'root-layers',
      type: 'fill',
      source: overlayid,
    });

  return output;
};

export const updateMapStyle = (
  layers: Basemap[],
  activeLayer: number | null,
  defaultMap?: maplibregl.Map,
): void => {
  const { MainMap } = window.Hyvilo.Utils;
  const map = defaultMap || MainMap;
  const getBaseStyle = (): undefined | string => {
    let output: undefined | string;
    map?.getStyle()?.layers?.forEach((l, i) => {
      if (i === 0) {
        output = l.id;
      }
    });

    return output;
  };

  layers.forEach((layer: Basemap) => {
    if (map?.getLayer(`root-basemap-layer-${layer.label}`))
      map?.removeLayer(`root-basemap-layer-${layer.label}`);
    if (map?.getLayer(`root-overlay-layer-${layer.label}`))
      map?.removeLayer(`root-overlay-layer-${layer.label}`);
    if (map?.getSource(`root-overlay-${layer.label}`))
      map?.removeSource(`root-overlay-${layer.label}`);
    if (map?.getSource(`root-basemap-${layer.label}`))
      map?.removeSource(`root-basemap-${layer.label}`);
    if (layer.id === activeLayer) {
      // BASEMAP
      map?.addSource(`root-basemap-${layer.label}`, {
        type: 'raster',
        tiles: [layer.basemap],
        tileSize: 256,
        attribution: '',
      });

      map?.addLayer(
        {
          id: `root-basemap-layer-${layer.label}`,
          type: 'raster',
          source: `root-basemap-${layer.label}`,
          minzoom: 1,
          maxzoom: 23,
          paint: {
            'raster-opacity': 1,
          },
        },
        getBaseStyle(),
      );

      if (layer.overlays?.length) {
        const overlayid = `root-overlay-${layer.label}`;
        map?.addSource(overlayid, {
          type: 'raster',
          tiles: layer.overlays,
          tileSize: 256,
          attribution: '',
        });

        map?.addLayer(
          {
            id: `root-overlay-layer-${layer.label}`,
            type: 'raster',
            source: overlayid,
            minzoom: 1,
            maxzoom: 23,
            paint: {
              'raster-opacity': 1,
            },
          },
          'root-layers',
        );
      }
    }
  });
};

export const addNavigationControl = (): void => {
  if (window.Hyvilo.Utils.MainMap) {
    window.Hyvilo.Utils.MainMap.addControl(
      new NavigationControl({}),
      'bottom-right',
    );
  }
};

export const activeLayerOnLoad = (
  layers: Layer[],
  defaultMap?: maplibregl.Map,
): void => {
  const { MainMap } = window.Hyvilo.Utils;
  const map = defaultMap || MainMap;
  if (map) {
    map.on('load', () => {
      layers.forEach((layer) => {
        layer.buildFeature(map);
      });
    });
  }
};

export const addImages = (imagesURI: string[], map?: MaplibreMap): void => {
  const { MainMap } = window.Hyvilo.Utils;
  const newMap = map || MainMap;
  imagesURI.forEach((url) => {
    const img = new Image(32, 32);
    img.crossOrigin = 'Anonymous';
    img.style.filter = 'invert(100%)';
    img.src = url;
    img.onload = (): void => {
      if (!newMap) return;
      if (!newMap.hasImage(url)) {
        newMap.addImage(url, img, { sdf: true });
      }
    };
  });
};
