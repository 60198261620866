import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import type { Task } from '@smack/core/api/models/tasks/Task';

import type { HeaderLabel } from '@smack/core/components/DataDisplay/Labels/IconLabel';
import { IconLabel } from '@smack/core/components/DataDisplay/Labels/IconLabel';

import { isEqual } from 'date-fns';
import fr from 'date-fns/locale/fr';

interface IProps {
  headerLabel: HeaderLabel;
  task: Task;
  reloadProject?: () => void;
}

export const EditDeadlineDatePicker: React.FC<IProps> = ({
  headerLabel,
  task,
  reloadProject,
}) => {
  const [t] = useTranslation();

  const [stagingDeadline, setStagingDeadline] = useState<Date>();

  const updateDeadline = React.useCallback(async () => {
    if (!stagingDeadline) return;
    await task.patch({ deadlineAt: stagingDeadline.toISOString() });
    if (reloadProject) {
      reloadProject();
    }
  }, [task, stagingDeadline]);

  const handleChange = React.useCallback((newDate: Date) => {
    setStagingDeadline((oldStagingDate) => {
      if (isEqual(newDate, oldStagingDate || 0)) return oldStagingDate;
      if (isEqual(newDate, task.deadlineAt?.toDate() || 0)) {
        return undefined;
      }
      return newDate;
    });
  }, []);

  if (!task.deadlineAt) return null;

  return (
    <div
      className="mr-3 !text-sm text-gray-500 dark:text-gray-400"
      onClick={(e): void => e.stopPropagation()}
    >
      <DatePicker
        onChange={handleChange}
        onCalendarClose={updateDeadline}
        selected={stagingDeadline || task.deadlineAt.toDate()}
        showTimeSelect
        timeIntervals={15}
        locale={fr}
        timeCaption={t('datepicker.timeCaption')}
        customInput={<IconLabel className="!text-sm" {...headerLabel} />}
        showPopperArrow={false}
      >
        {t('datepicker.closeCalendarToApply')}
      </DatePicker>
    </div>
  );
};
