import { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import type { ListBlockDisplay } from '@smack/core/api/models/views/ListBlockDisplay/ListBlockDisplay';
import type { ViewElement } from '@smack/core/api/models/views/ViewElement/ViewElement';
import type { FieldProps } from '@smack/core/api/models/views/ViewElement/ViewElement';
import { DisplayedFieldType } from '@smack/core/api/models/views/ViewElement/enums';
import { IconLabel } from '@smack/core/components/DataDisplay/Labels/IconLabel';
import {
  BaseListElement,
  type IBaseListElementProps,
  type IListElementAction,
} from '@smack/core/components/DataDisplay/Lists/ListElements/BaseListElement';
import {
  ListElementLayout,
  type ListElementLayoutProps,
} from '@smack/core/components/DataDisplay/Lists/ListElements/Components/ListElementLayout/ListElementLayout';
import { ViewElementRenderer } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRenderer';
import type { FC, ReactNode } from 'react';

interface ListBlockElementLayoutProps extends IBaseListElementProps {
  parameters?: Partial<FieldProps>;
  listBlock: ListBlockDisplay;
  arrowColor: ListElementLayoutProps['arrowColor'];
  arrowIcon: ListElementLayoutProps['arrowIcon'];
  overlayIcons: IListElementAction[];
}

export const ListBlockElementLayout: FC<ListBlockElementLayoutProps> = ({
  listBlock,
  arrowColor,
  arrowIcon,
  parameters,
  overlayIcons,
  ...baseListElementProps
}) => {
  const getStopPropagationWhenFormField = (
    viewElement?: ViewElement,
    children?: ReactNode,
  ) => {
    if (viewElement?.fieldTypeIsFormField()) {
      return (
        <div
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
        >
          {children}
        </div>
      );
    }
    return children;
  };

  const renderField = (viewElement?: ViewElement): ReactNode => {
    if (!viewElement) return null;
    return getStopPropagationWhenFormField(
      viewElement,
      <ViewElementRenderer
        viewElement={viewElement}
        props={{
          viewElement: viewElement,
          viewUsage: ViewUsage.LIST_BLOCK,
          ...parameters,
        }}
      />,
    );
  };

  const viewElementToIconLabel = (viewElement?: ViewElement): ReactNode => {
    if (!viewElement) return null;
    if (
      !viewElement?.fieldType ||
      viewElement?.fieldType === DisplayedFieldType.TEXT_DISPLAY
    ) {
      return (
        <IconLabel
          icon={viewElement.prefixIcon ? undefined : viewElement?.icon}
          maxLengthLabel={20}
        >
          {renderField(viewElement)}
        </IconLabel>
      );
    }
    return renderField(viewElement);
  };

  return (
    <BaseListElement {...baseListElementProps}>
      <ListElementLayout
        arrowColor={arrowColor}
        arrowIcon={arrowIcon}
        title={renderField(listBlock.titleViewElement)}
        subtitle={renderField(listBlock.subtitleViewElement)}
        badge={renderField(listBlock.badgeViewElement)}
        bottomLeft={viewElementToIconLabel(listBlock.bottomLeftViewElement)}
        bottomRight={viewElementToIconLabel(listBlock.bottomRightViewElement)}
        overlayIcons={overlayIcons}
      />
    </BaseListElement>
  );
};
