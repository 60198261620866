import { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import { BaseObjectDetailsLayout } from '@smack/core/components/ViewRenderer/layouts/BaseObjectDetailsLayout';
import { BaseObjectDetailsWithNavigationLayout } from '@smack/core/components/ViewRenderer/layouts/BaseObjectDetailsWithNavigationLayout';
import { BaseObjectFormLayout } from '@smack/core/components/ViewRenderer/layouts/BaseObjectFormLayout';
import { BaseObjectFormWithNavigationLayout } from '@smack/core/components/ViewRenderer/layouts/BaseObjectFormWithNavigationLayout';
import { BaseObjectMapTooltipLayout } from '@smack/core/components/ViewRenderer/layouts/BaseObjectMapTooltipLayout';
import { BaseObjectPreviewLayout } from '@smack/core/components/ViewRenderer/layouts/BaseObjectPreviewLayout';

export const layoutTypeMap = {
  [ViewUsage.DETAILS]: BaseObjectDetailsLayout,
  [ViewUsage.TASK_FORM]: BaseObjectFormLayout,
  [ViewUsage.FORM]: BaseObjectFormWithNavigationLayout,
  [ViewUsage.QUICK_FORM]: BaseObjectFormLayout,
  [ViewUsage.PREVIEW]: BaseObjectPreviewLayout,
  [ViewUsage.MAP_TOOLTIP]: BaseObjectMapTooltipLayout,
  [ViewUsage.SHEET]: BaseObjectDetailsWithNavigationLayout,
  // TODO: add other views as we migrate them
} as const;
