import { Button } from '@smack/core/components/Actions/Buttons/Button';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { useTranslation } from 'react-i18next';

export enum ResolutionChoice {
  MIN = 'MIN',
  MID = 'MID',
  MAX = 'MAX',
}

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSelect: (resolution: ResolutionChoice) => void;
}

export const ResolutionModal = (props: IProps): JSX.Element => {
  const { open, setOpen, onSelect } = props;
  const { t } = useTranslation();

  return (
    <Modal
      icon={{ name: 'crop-simple' }}
      open={open}
      onClose={setOpen}
      title={t('medias.resolutionModal.title')}
    >
      <div className="flex flex-col gap-2 max-w-[400px]">
        <p>{t('medias.resolutionModal.content')}</p>
        <Button
          onClick={() => {
            onSelect(ResolutionChoice.MIN);
            setOpen(false);
          }}
          className="py-3"
        >
          {t('medias.resolutionModal.min')}
        </Button>
        <Button
          onClick={() => {
            onSelect(ResolutionChoice.MID);
            setOpen(false);
          }}
          className="py-3"
        >
          {t('medias.resolutionModal.mid')}
        </Button>
        <Button
          onClick={() => {
            onSelect(ResolutionChoice.MAX);
            setOpen(false);
          }}
          className="py-3"
        >
          {t('medias.resolutionModal.max')}
        </Button>
      </div>
    </Modal>
  );
};
