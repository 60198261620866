import { useTranslation } from 'react-i18next';
import { type ILogsFeedProps, LogFeed } from '../../LogsFeed';

export const TaskLogCreationFeed = (props: ILogsFeedProps): JSX.Element => {
  const [t] = useTranslation();

  return (
    <LogFeed {...props}>
      <span className="text-sm font-normal">
        {' '}
        {t('logs.action.createdTask')}
      </span>
    </LogFeed>
  );
};
