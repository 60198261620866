import { DangerAlert } from '@smack/core/components/DataDisplay/Alerts/Alerts';
import type { IBaseObjectDashboardDisplayProps } from '@smack/core/store/app/types';
import { LoaderSkeleton } from '@smack/core/utils/Loader';
import { Switchboard } from '@superset-ui/switchboard';
import React from 'react';
import { useTranslation } from 'react-i18next';

type IProps = IBaseObjectDashboardDisplayProps;

function buildDashboardURL(dashboardUrl: string): URL {
  const url = new URL(dashboardUrl);
  url.searchParams.set('uiConfig', '1');
  url.searchParams.set('expand_filters', 'false');
  url.searchParams.set('show_filters', 'true');
  return url;
}

interface SupersetDashboardInfo {
  success: true;
  dashboardUrl: string;
  guestToken: string;
}

interface SupersetDashboardError {
  success: false;
  noDashboardAvailable: boolean;
}

export const SupersetDashboard: React.FC<IProps> = ({
  baseobjectId,
  dashboardDisplayAction,
}) => {
  const messageSwitchboard = React.useRef<Switchboard>();
  const { t } = useTranslation();
  const [infoResponse, setInfoResponse] =
    React.useState<SupersetDashboardInfo>();

  const [error, setError] = React.useState<string>();

  const dashboardUrlWithQueryParams = infoResponse
    ? buildDashboardURL(infoResponse.dashboardUrl)
    : null;

  const getDashboardInfo = async (): Promise<
    SupersetDashboardInfo | undefined
  > => {
    try {
      const dashboardAction =
        (await dashboardDisplayAction.triggerActionFromBaseObject(
          baseobjectId,
          false,
        )) as {
          results?: SupersetDashboardInfo | SupersetDashboardError;
        };

      if (!dashboardAction.results?.success) {
        setError(
          t(
            dashboardAction.results?.noDashboardAvailable
              ? 'dashboard.noDashboardAvailable'
              : 'dashboard.cannotShowDashboard',
          ),
        );
        return;
      }
      return dashboardAction.results;
    } catch (err) {
      setError(t('dashboard.cannotShowDashboard'));
    }
  };

  React.useEffect(() => {
    getDashboardInfo().then(setInfoResponse);
  }, [dashboardDisplayAction]);

  React.useEffect(() => {
    // Superset guest tokens are valid for 5 minutes only;
    // we have to renew them every 5 minutes.
    const refreshToken = async (): Promise<void> => {
      if (!messageSwitchboard.current) return;
      const dashboardInfo = await getDashboardInfo();
      if (!dashboardInfo) return;
      messageSwitchboard.current.emit('guestToken', {
        guestToken: dashboardInfo.guestToken,
      });
    };
    const interval = setInterval(refreshToken, 270 * 1000);
    return (): void => {
      clearInterval(interval);
    };
  }, [baseobjectId]);

  const onDashboardLoaded: React.ReactEventHandler<HTMLIFrameElement> = (
    event,
  ) => {
    const IFrame = event.currentTarget;
    const messageChannel = new MessageChannel();
    const hostPort = messageChannel.port1;
    messageSwitchboard.current = new Switchboard({
      port: hostPort,
      name: 'superset-embedded-sdk',
      debug: import.meta.env.DEV,
    });
    const framePort = messageChannel.port2;

    if (
      !IFrame.contentWindow ||
      !infoResponse ||
      !dashboardUrlWithQueryParams
    ) {
      return;
    }

    IFrame.contentWindow.postMessage(
      {
        type: '__embedded_comms__',
        handshake: 'port transfer',
      },
      {
        transfer: [framePort],
        targetOrigin: dashboardUrlWithQueryParams.toString(),
      },
    );

    messageSwitchboard.current?.emit('guestToken', {
      guestToken: infoResponse.guestToken,
    });
  };

  if (error) {
    return (
      <div className={'w-full p-5'}>
        <DangerAlert>{error}</DangerAlert>
      </div>
    );
  }

  if (!dashboardUrlWithQueryParams)
    return (
      <LoaderSkeleton className="grow">
        <rect x="0" y="0" width="100%" height="50" />
        <rect x="30" y="70" width="calc(100% - 60px)" height="600" />
      </LoaderSkeleton>
    );

  return (
    <iframe
      data-testid={'SupersetDashboard'}
      title={t('dashboard.iFrameTitle')}
      className="grow"
      sandbox="allow-same-origin allow-scripts allow-presentation allow-downloads allow-forms allow-popups"
      onLoad={onDashboardLoaded}
      src={dashboardUrlWithQueryParams.toString()}
    />
  );
};
