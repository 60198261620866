import type React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { Status } from '@smack/core/api/models/tasks/Status';
import type { Task } from '@smack/core/api/models/tasks/Task';

import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';

interface IProps {
  task?: Task;
  status?: Status;
}

export const StatusUpdate: React.FC<IProps> = ({ task, status }) => {
  const { t } = useTranslation();
  return (
    <Trans
      t={t}
      key="from"
      i18nKey="tasks.rulesDescription.status"
      values={{
        taskName: task?.label,
      }}
      components={{
        italicbold: <i className="font-medium" />,
        status: (
          <>
            {/* biome-ignore lint/complexity/noUselessFragments: This is necessary for i18next to not clone the span element */}
            <></>
            <span className=" border rounded-xl w-fit px-2 py-1 inline-flex mx-1">
              <span className="text-sm mr-3">{status?.label}</span>
              <span
                style={{
                  backgroundColor: status?.color || 'gray',
                }}
                className="h-5 w-5 flex items-center justify-center rounded-full  "
              >
                {
                  <Icon
                    className="text-xs text-white"
                    icon={status?.icon || { name: 'question' }}
                  />
                }
              </span>
            </span>
          </>
        ),
      }}
    />
  );
};
