import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCustomerPreferences } from '@smack/core/hooks/preferences/useCustomerPreferences/useCustomerPreferences';
import { useMapsPreferences } from '@smack/core/hooks/preferences/useMapsPreferences/useMapsPreferences';
import type { AppState } from '@smack/core/store';
import { useWindowSize } from '@smack/core/utils';
import { setOnlineInterval } from '@smack/core/utils/onlineInterval';
import { fetchAppSettings, setTheme } from './store/app/actions';
import { fetchBaseMap, setActiveBasemap } from './store/maps/actions';

export interface IAppProps {
  width: number;
}

interface IProps {
  App: React.FC<IAppProps>;
}

export const AppContainer = ({ App }: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const { theme } = useSelector((state: AppState) => state.App);
  const { basemaps } = useSelector((state: AppState) => state.Maps);
  const { current } = useSelector((state: AppState) => state.User);
  const { current: currentModal } = useSelector(
    (state: AppState) => state.Modal,
  );
  const [width] = useWindowSize();
  const [customerPreferences] = useCustomerPreferences();
  const [mapPreferences] = useMapsPreferences();
  React.useEffect(() => {
    const html = document.querySelector('html');
    if (html) html.dataset.theme = `theme-${theme}`;
  }, []);

  React.useEffect(() => {
    if (!basemaps.length && current) {
      dispatch(fetchBaseMap());
      // refetch basemap every half an hour,
      // mainly for the layers' icons served through S3
      // whose authentication expires after an hour
      setOnlineInterval(
        (): void => {
          dispatch(fetchBaseMap());
        },
        30 * 60 * 1000,
      );
    }
    // Fetch customer and app settings
    // Refetch it on login
    dispatch(fetchAppSettings(!current));
  }, [current]);

  // whene the user will change the theme if the basemaps in the user preference has no theme in the
  // data, thebasemaps will no change.
  React.useEffect(() => {
    const root = window.document.documentElement;
    if (root) root.dataset.theme = `theme-${theme}`;
    root.classList.remove(theme === 'dark' ? 'light' : 'dark');
    root.classList.add(`${theme}`);
    const userbase = mapPreferences?.basemap;
    if (basemaps?.length) {
      if (userbase) {
        const userbasemaps = basemaps.find((a) => a.id === userbase);
        if (userbasemaps?.defaultOfTheme) {
          const newbase = basemaps.find((a) => a.defaultOfTheme === theme);
          if (newbase) dispatch(setActiveBasemap(newbase.id));
        }
      } else {
        const newbase = basemaps.find((a) => a.defaultOfTheme === theme);
        if (newbase) dispatch(setActiveBasemap(newbase.id));
      }
    }
  }, [theme, basemaps]);

  React.useEffect(() => {
    const usertheme = customerPreferences?.theme;
    if (usertheme) {
      dispatch(setTheme(usertheme));
    }
  }, [current]);

  return (
    <>
      <App width={width} />
      {currentModal}
    </>
  );
};
