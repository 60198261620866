import type { LinkGroup } from '@smack/core/api/models/categories/LinkGroup';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { ListElementBaseObject } from '@smack/core/api/models/objects/NewBaseObject/ListElementBaseObject';
import {
  CancelButton,
  SaveButton,
} from '@smack/core/components/Actions/Buttons/Button';
import {
  BaseObjectListElement,
  type BaseObjectListElementProps,
} from '@smack/core/components/DataDisplay/Lists/ListElements/BaseObjectListElement';
import { NoContentMessage } from '@smack/core/components/DataDisplay/NoContentMessage';
import type { IFilters } from '@smack/core/utils/Filters';
import {
  BaseObjectSkeleton,
  ListSkeleton,
  LoaderSkeleton,
} from '@smack/core/utils/Loader';
import { InfiniteVirtualizedList } from '@smack/core/views/oldViewsToSort/Layouts/Objects/InfiniteVirtualizedList';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface UnlinkBaseObjectListProps {
  categoryId: number;
  filters?: IFilters;
  linkGroup: LinkGroup;
  onQuit?: () => void;
  onSubmit?: (object: BaseObject) => void;
}

export const UnlinkBaseObjectList: React.FC<UnlinkBaseObjectListProps> = ({
  categoryId,
  linkGroup,
  onSubmit,
  onQuit,
  filters,
}) => {
  const [baseObjectValue, setBaseObjectValue] = React.useState<BaseObject>();
  const [baseObjects, setBaseObjects] = React.useState<ListElementBaseObject[]>(
    [],
  );
  const [t] = useTranslation();
  const [objectTotal, setObjectTotal] = React.useState<number>();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const loadMoreRows = (indicesData?: {
    startIndex: number;
    stopIndex: number;
  }): void => {
    if (!categoryId) return;
    if (!indicesData) {
      setIsLoaded(false);
    }
    ListElementBaseObject.getBaseObjectForListRepresentation(
      undefined,
      { categories: [categoryId.toString()], ...filters },
      indicesData?.startIndex ?? 0,
      undefined,
      linkGroup
        ? {
            linkGroupId: linkGroup?.id,
          }
        : undefined,
    )
      .then((res) => {
        setBaseObjects(
          indicesData
            ? [
                ...baseObjects,
                ...res.data.results.map((r) => new ListElementBaseObject(r)),
              ]
            : res.data.results.map((r) => new ListElementBaseObject(r)),
        );
        setObjectTotal(res.data.count);
      })
      .finally((): void => {
        setIsLoaded(true);
      });
  };

  React.useEffect(() => {
    if (categoryId) {
      loadMoreRows();
    }
  }, [filters, categoryId]);

  const renderButton = (
    listElementProps: BaseObjectListElementProps,
  ): JSX.Element => {
    const object = baseObjects.find((o) => o.id === listElementProps.id);
    if (!object) return <></>;
    return (
      <BaseObjectListElement
        {...listElementProps}
        active={listElementProps.id === baseObjectValue?.id}
        onClick={(): void => {
          setBaseObjectValue(object as BaseObject);
        }}
        link={undefined}
      />
    );
  };

  const getObjectList = (): React.ReactNode => {
    if (isLoaded && !baseObjects.length) {
      return (
        <NoContentMessage
          label={t('batchLinkForm.removeLink.noLinkToUnlink')}
          icon={{ name: 'search' }}
        />
      );
    }
    return (
      <InfiniteVirtualizedList
        objects={baseObjects.map((o) => o.getListElement())}
        renderListItem={renderButton}
        startIndex={0}
        loadMoreRows={loadMoreRows}
        rowCount={objectTotal}
      />
    );
  };

  const handleOnQuit = () => {
    onQuit?.();
  };
  const handleOnSubmit = () => {
    if (!baseObjectValue) return;
    onSubmit?.(baseObjectValue);
  };

  return (
    <div className="my-0 mx-auto  h-96 flex flex-col gap-3">
      <div className={'min-w-[400px] flex-grow flex flex-col '}>
        <h3 className="block  font-medium text-gray-800 dark:text-gray-200 mb-4">
          {t('batchLinkForm.removeLink.whatIstheObject')}
        </h3>
        <div className={'flex  flex-grow'}>
          {isLoaded ? (
            getObjectList()
          ) : (
            <LoaderSkeleton height={240} width={400} className="overflow-clip">
              <ListSkeleton component={BaseObjectSkeleton} iterations={5} />
            </LoaderSkeleton>
          )}
        </div>
      </div>
      <div className="flex items-center justify-end gap-3">
        <CancelButton
          onClick={handleOnQuit}
          data-testid="UnlinkBaseObjectCancelButton"
        />
        <SaveButton
          onClick={handleOnSubmit}
          data-testid="unlinkBaseObjectSaveButton"
        />
      </div>
    </div>
  );
};
