import { env } from '@smack/core/env';
import type React from 'react';

export function getVersionTag(): string {
  return `front-${env.VITE_RELEASE_TAG}`;
}

export const copyVersionTag: React.MouseEventHandler<HTMLElement> = (e) => {
  window.getSelection()?.selectAllChildren(e.currentTarget);
  navigator.clipboard.writeText(getVersionTag());
};
