import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import React from 'react';

/**
 * Interface for the BaseAlert Component
 *
 * @export
 * @interface IBaseAlert
 */
export interface IBaseAlert {
  icon?: IconField;
  iconColor?: string;
  color?: string;
  title: string;
  text: React.ReactNode;
  buttonText?: string;
  onClick?: () => void;
  isClosable?: boolean;
  footer?: React.ReactNode;
}

/**
 *  Component for BaseAlert (Modal)
 * @param props
 * @returns JSX.ELEMENT
 */
export const BaseAlert = (props: IBaseAlert): JSX.Element => {
  const {
    buttonText,
    color,
    icon,
    iconColor,
    text,
    title,
    onClick,
    isClosable = true,
    footer,
  } = props;
  const [open, realSetOpen] = React.useState(true);
  const setOpen = React.useCallback(
    (newOpen: boolean) => {
      if (isClosable) realSetOpen(newOpen);
    },
    [isClosable],
  );
  return (
    <Modal
      open={open}
      onClose={setOpen}
      color={color}
      icon={icon}
      iconColor={iconColor}
      title={title}
      closeButton={isClosable}
    >
      <div className="text-sm text-gray-500">{text}</div>
      {onClick ? (
        <div className="mt-5 sm:mt-6">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
            onClick={(): void => {
              setOpen(false);
              onClick();
            }}
          >
            {buttonText}
          </button>
        </div>
      ) : null}
      {footer ?? null}
    </Modal>
  );
};
