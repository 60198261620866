import type { AxiosResponse } from 'axios';

export function downloadResponseBlob(
  response: AxiosResponse<Blob>,
  filename?: string,
) {
  if (filename === undefined) {
    const disposition = response.headers['content-disposition'] ?? '';
    filename =
      /filename[^;=\n]*=(["']?)([^"';\n]+)\1/.exec(disposition)?.[2] || '';
  }
  const filetype = response.headers['content-type']?.toString() ?? '';

  const blob = response.data;
  if (!blob.size) return;
  const url = window.URL.createObjectURL(new Blob([blob], { type: filetype }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
}
