import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { setModuleStore } from '@smack/core/store/app/actions';
import {
  type OptionalMapLibreId,
  useMapHoverHandler,
} from '@smack/core/utils/MapEventUtils';
import {
  addLinkPointsLayer,
  addVectorTilesSource,
  buildTilesSource,
  getFeaturesCollection,
  linkLineLayerLabel,
  linkPointLayerLabels,
  linkPolygonLayerLabel,
  linkSourceGeoJSONLabel,
  linkSourceLabel,
  linkSourceRegexp,
} from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Links/Utils';
import { setFeatureState } from '@smack/core/views/oldViewsToSort/Views/Map/Utils/Objectlayers';
import {
  addVectorTilesLineLayer,
  addVectorTilesPolygonLayer,
} from '@smack/core/views/oldViewsToSort/Views/Objects/ObjectMap/utils';
import type {
  GeoJSONSource,
  MapMouseEvent,
  VectorTileSource,
} from 'maplibre-gl';
import React from 'react';
import { useDispatch } from 'react-redux';

/**
 * Refresh a giver link group layers. Use when a link is added or removed.
 * @param linkGroupId ID of the link group
 */
type RefreshFunction = (linkGroupId: number) => void;

export const useLinkLayerManager = (
  active: boolean,
  object: BaseObject,
  activeGroups: number[],
  initPhase: boolean,
): [RefreshFunction, OptionalMapLibreId] => {
  const dispatch = useDispatch();

  const [mapEvents, hoveredId] = useMapHoverHandler();

  const linksHoverFeature = (sourceId: string, e: MapMouseEvent): void => {
    mapEvents.hoverFeature(e, sourceId, 'default');
  };

  const linksLeaveHoveringLayer = (sourceId: string): void => {
    mapEvents.leaveHoveringLayer(sourceId, 'default');
  };

  // Trigger refresh of the link layers
  const refreshVectorLayer: RefreshFunction = (linkGroupId: number): void => {
    const map = window.Hyvilo.Utils.MainMap;
    if (!map) return;
    const geojsonSourceName = linkSourceGeoJSONLabel(linkGroupId);
    const sourceName = linkSourceLabel(linkGroupId);
    if (map.getSource(sourceName)) {
      const source = map.getSource(sourceName) as VectorTileSource;
      // Setting the tiles clears the maplibre tile cache
      source.setTiles(buildTilesSource(linkGroupId, object.id));
    }
    if (map.getSource(geojsonSourceName)) {
      const source = map.getSource(geojsonSourceName) as GeoJSONSource;
      object
        .getBaseObjectForMapRepresentationByLinksGroup(linkGroupId)
        .then((res) => {
          source.setData(getFeaturesCollection(res));
        });
    }
  };

  /**
   * Add link layers for a given linkGroup.
   * *This function is safe to execute when the layer have already been added.*
   * @param linkGroupId The ID of the linkgroup to show
   * @returns Dismount functions for the layers hover events (useful for React effects)
   */
  const addLinkLayers = (linkGroupId: number): (() => void)[] => {
    const map = window.Hyvilo.Utils.MainMap;
    if (!map) return [];
    const geojsonSourceName = linkSourceGeoJSONLabel(linkGroupId);
    const sourceName = linkSourceLabel(linkGroupId);
    if (!map.getSource(sourceName))
      addVectorTilesSource(linkGroupId, object.id);
    if (!map.getSource(geojsonSourceName)) {
      map.addSource(geojsonSourceName, {
        type: 'geojson',
        data: getFeaturesCollection([]),
      });
      object
        .getBaseObjectForMapRepresentationByLinksGroup(linkGroupId)
        .then((res) => {
          (map.getSource(geojsonSourceName) as GeoJSONSource).setData(
            getFeaturesCollection(res),
          );
        });
    }
    const labels: string[] = [
      linkLineLayerLabel(linkGroupId),
      linkPolygonLayerLabel(linkGroupId),
      ...linkPointLayerLabels(linkGroupId),
    ];
    if (!map.getLayer(labels[0]))
      addVectorTilesLineLayer(map, labels[0], sourceName, 'default');
    if (!map.getLayer(labels[1]))
      addVectorTilesPolygonLayer(map, labels[1], sourceName, 'default');
    if (!map.getLayer(labels[2]))
      addLinkPointsLayer(linkGroupId, geojsonSourceName);
    return labels.map((layer) => {
      const mouseMoveListener = linksHoverFeature.bind(undefined, sourceName);
      map?.on('mousemove', layer, mouseMoveListener);
      const mouseLeaveListener = linksLeaveHoveringLayer.bind(
        undefined,
        sourceName,
      );
      map?.on('mouseleave', layer, mouseLeaveListener);
      return () => {
        map?.off('mousemove', layer, mouseMoveListener);
        map?.off('mouseleave', layer, mouseLeaveListener);
      };
    });
  };

  /**
   * Destroy link layers for a given link group
   * @param source Name of the tile source associated to the link group that needs to be removed.
   * The link group ID will be automatically derived using `linkSourceRegexp`.
   */
  const removeLinkLayers = (source: string): void => {
    const regexpResult = linkSourceRegexp.exec(source);
    const map = window.Hyvilo.Utils.MainMap;
    if (!regexpResult || !map) return; // should never happen
    const linkGroupId = Number.parseInt(regexpResult[1]);
    for (const layer of [
      linkLineLayerLabel(linkGroupId),
      linkPolygonLayerLabel(linkGroupId),
      ...linkPointLayerLabels(linkGroupId),
    ]) {
      if (map.getLayer(layer)) map.removeLayer(layer);
    }
    if (map.getSource(source)) map.removeSource(source);
    if (map.getSource(linkSourceGeoJSONLabel(linkGroupId)))
      map.removeSource(linkSourceGeoJSONLabel(linkGroupId));
  };

  /**
   * Destroy layers for inactive groups if they exists and creates layers for active groups if necessary.
   * Usable as a React Effect.
   * @param empty Force cleanup, set to true on component dismount
   * @returns Array of dismount functions for effect cleanup
   */
  const flipLinkGroupsOnMap = (empty = false): (() => void)[] => {
    // Retrieve existing sources
    const map = window.Hyvilo.Utils.MainMap;
    if (!map || !map.isStyleLoaded()) return [];
    const linkSources = Object.keys(map.getStyle().sources).filter((source) =>
      linkSourceRegexp.test(source),
    );
    if (activeGroups.length === 0 || empty) {
      dispatch(setModuleStore({ hideSetOnMap: false }));
      linkSources.forEach(removeLinkLayers);
      // Set object as focused on map again
      map.once('idle', () => setFeatureState(object.id));
      return [];
    }
    dispatch(setModuleStore({ hideSetOnMap: true }));
    // Find linkSources to remove
    linkSources
      .filter((ls) => {
        const regexpResult = linkSourceRegexp.exec(ls);
        if (!regexpResult) return; // Should never happen
        const linkGroupId = Number.parseInt(regexpResult[1]);
        return !activeGroups.includes(linkGroupId);
      })
      .forEach(removeLinkLayers);
    // Return an array of dismount functions for React effect.
    return activeGroups.flatMap(addLinkLayers);
  };

  React.useEffect(() => {
    if (!active) return;
    if (initPhase) return;
    const dismount = flipLinkGroupsOnMap();

    return () => {
      // To avoid redundant hover handlers, we remove all of thems to recreate only the necessary ones
      // in the next render phase.
      dismount.forEach((off) => off());
    };
  }, [activeGroups, initPhase]);

  React.useEffect(() => {
    return () => {
      // Clean up all link layers on component dismount
      flipLinkGroupsOnMap(true);
    };
  }, []);

  return [refreshVectorLayer, hoveredId];
};
