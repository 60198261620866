import type { InputLink } from '@smack/core/api/models/objects/Link/Link';
import { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import {
  CancelButton,
  IconButton,
  SaveButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { ConfirmationChip } from '@smack/core/components/DataDisplay/ConfirmationChip';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { AddressInput } from '@smack/core/components/DataInput/AddressInput/AddressInput';
import { LinksInput } from '@smack/core/components/DataInput/LocationInput/Components/Links/LinksInput';
import { useAddressService } from '@smack/core/hooks/useAddressService/useAddressService';
import type { AppState } from '@smack/core/store';
import type { Point } from 'geojson';
import React, { lazy } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { type IMapOutput, MapInput } from './Components/Map';

const MiniMap = lazy(() =>
  import('@smack/core/components/DataDisplay/MiniMap').then((module) => ({
    default: module.MiniMap,
  })),
);

export interface ILocationInputValue extends IMapOutput {
  address?: string | null;
  links?: InputLink[];
}

interface ILocationInputFormFields {
  map?: IMapOutput;
  address?: string;
  links?: InputLink[];
}

interface IProps {
  value?: ILocationInputValue;
  categoryId?: number;
  baseobjectId?: number;
  onChange?: (props: ILocationInputValue) => void;
}

export const LocationInput = (props: IProps): JSX.Element => {
  const { onChange, value, categoryId, baseobjectId } = props;
  const [open, setOpen] = React.useState(false);
  const { settings } = useSelector((app: AppState) => app.App);
  const { control, watch, getValues, setValue, reset } =
    useForm<ILocationInputFormFields>();
  const addressService = useAddressService();

  const { t } = useTranslation();

  /**
   * if the user click on the address set point
   *
   */
  const handleAddressChangeLocation = (): void => {
    if (!onChange || !addressService) return;

    const q = getValues('address') ?? '';
    const latLong = settings?.mapLocation?.coordinates;
    const search = latLong
      ? addressService.searchAddressAroundLatLong(q, latLong[1], latLong[0])
      : addressService.searchAddress(q);

    search.then((res) => {
      if (res.length) {
        setValue('map', {
          ...getValues('map'),
          location: {
            type: 'Feature',
            geometry: res[0].point,
            properties: {},
          },
        });
      }
    });
  };

  /**
   * if the user click on the set address from point   *
   */
  const handleLocationChangeAddress = (): void => {
    if (!addressService) return;

    const coordinates = getValues('map')?.location?.geometry?.coordinates;
    if (!coordinates) return;

    addressService
      .getAddressFromLatLng(coordinates[1], coordinates[0])
      .then((res) => setValue('address', res));
  };

  const handleFormSubmit = (): void => {
    const formValues = getValues();
    const output: ILocationInputValue = {
      address: formValues.address,
      geometry: formValues.map?.geometry ?? null,
      location: formValues.map?.location ?? null,
      links: formValues.links,
    };
    if (onChange) onChange(output);
    setOpen(false);
  };

  const onOpen = (): void => {
    const output: ILocationInputFormFields = {
      address: value?.address ?? '',
      links: value?.links,
      map: {
        location: value?.location,
        geometry: value?.geometry,
      },
    };

    if (output.address) setValue('address', output.address);
    if (output.map?.geometry || output.map?.location)
      setValue('map', output.map);
    if (output.links) setValue('links', output.links);
    setOpen(true);
  };

  const handleMapChange = (out: IMapOutput): IMapOutput => {
    return { ...getValues('map'), ...out };
  };

  /**
   * get geometries / location / address from othereEE
   *
   * @param {BaseObject} obj
   */
  const addGeometriesFromOtherBaseObject = (obj: BaseObject): void => {
    let geometry: GeoJSON.FeatureCollection | undefined;
    let location: GeoJSON.Feature<Point> | undefined;
    let address: string = getValues('address') as string;
    const mapval = getValues('map') as IMapOutput;
    if (obj.geometry) {
      const features = obj.geometry?.features;
      if (features?.length) {
        if (mapval?.geometry) {
          const collection = mapval.geometry;
          collection.features = [...collection.features, ...features];
          geometry = collection;
        } else {
          geometry = obj.geometry;
        }
      }
    }
    if (obj.location) {
      location = obj.location;
    }
    if (obj.address && !address) {
      address = obj.address;
      setValue('address', address);
    }
    setValue('map', {
      location: mapval?.location ?? location,
      geometry,
    });
  };

  const onLinksChange = (data: InputLink[]): void => {
    data.forEach((val) =>
      BaseObject.getBaseObject(val.targetBaseobjectId).then(
        addGeometriesFromOtherBaseObject,
      ),
    );
  };

  const clearInput = (): void => {
    reset();
    onChange?.({
      address: null,
      location: null,
      geometry: null,
      links: [],
    });
  };

  return (
    <div className="w-full">
      <div className="max-w-lg sm:max-w-xs w-full flex flex-col items-start md:items-center flex-wrap">
        <MiniMap
          className={
            'w-full rounded-b-none dark:border-neutral-500 border border-b-0'
          }
          location={value?.location}
          geometry={value?.geometry}
        />
        <div className="flex w-full">
          <IconButton
            onClick={(): void => onOpen()}
            icon={{ name: 'map-location-dot', familyStyle: 'fas' }}
            iconClassName="text-xl"
            tooltipClass="flex-col grow"
            className="justify-center rounded-t-none rounded-br-none border-r-0 w-full"
          >
            {t('formInputLabels.locate')}
          </IconButton>
          <ConfirmationChip<HTMLButtonElement>
            renderConfirmNode={(closeChip): React.ReactElement => (
              <IconButton
                icon={{ name: 'warning' }}
                iconClassName="text-lg"
                onClick={(): void => {
                  clearInput();
                  closeChip();
                }}
              >
                {t('delete.location')}
              </IconButton>
            )}
            placement="bottom-end"
          >
            {(ref, providedProps): React.ReactNode => (
              <IconButton
                ref={ref}
                icon={{ name: 'trash-can', familyStyle: 'fas' }}
                disabled={!value?.location}
                iconClassName="text-lg"
                className="justify-center rounded-t-none rounded-bl-none"
                title={t('delete.location')}
                {...providedProps}
              />
            )}
          </ConfirmationChip>
        </div>
      </div>
      <Modal
        title={t('locationInputModal.title')}
        color={'#EFF6FF'}
        iconColor={'#3886F1'}
        icon={{ name: 'map-location' }}
        open={open}
        onClose={(): void => {
          setOpen(false);
          reset();
        }}
      >
        <>
          <Controller
            name="address"
            defaultValue={''}
            control={control}
            render={({ field }): JSX.Element => (
              <AddressInput
                label={t('locationInputModal.address')}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          {watch('address') && (
            <div
              onClick={handleAddressChangeLocation}
              className=" w-fit text-sm text-blue-500 cursor-pointer hover:text-blue-600"
            >
              {t('locationInputModal.pointOnMapFromAddress')}
            </div>
          )}

          <p className=" mt-3 block text-sm font-medium text-gray-700 mb-1">
            {t('locationInputModal.map')}
          </p>
          <Controller
            name="map"
            control={control}
            render={({ field }): JSX.Element => (
              <MapInput
                value={field.value}
                onChange={(val): void => field.onChange(handleMapChange(val))}
              />
            )}
          />
          {(watch('map') as IMapOutput)?.location && (
            <div className="mb-5 mt-2">
              <div
                onClick={handleLocationChangeAddress}
                className=" w-fit text-sm text-blue-500 cursor-pointer hover:text-blue-600"
              >
                {t('locationInputModal.findAddressFromLocation')}
              </div>
            </div>
          )}
          <div className="mt-3">
            <Controller
              name="links"
              control={control}
              render={({ field }): JSX.Element => (
                <LinksInput
                  value={field.value}
                  categoryId={categoryId}
                  objectId={baseobjectId}
                  onChange={(val): void => {
                    onLinksChange(val);
                    field.onChange(val);
                  }}
                />
              )}
            />
          </div>
          <div className="mt-3 flex justify-end">
            <CancelButton
              className="mr-2"
              onClick={(): void => {
                setOpen(false);
                reset();
              }}
            />
            <SaveButton
              onClick={handleFormSubmit}
              data-testid="LocationInputSaveButton"
            />
          </div>
        </>
      </Modal>
    </div>
  );
};
