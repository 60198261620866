import { useOnScreen } from '@smack/core/hooks/useOnScreen';
import { LoaderSkeleton } from '@smack/core/utils/Loader';
import React from 'react';

interface IProps {
  onScreen: () => void;
  show?: boolean;
}

export const EmitterLoading: React.FC<IProps> = ({
  onScreen,
  show,
}): JSX.Element => {
  const ref = React.useRef<HTMLTableRowElement | null>(null);
  const isOnScreen = useOnScreen(ref);
  React.useEffect(() => {
    if (isOnScreen) {
      onScreen();
    }
  }, [isOnScreen]);
  if (show) {
    return (
      <div>
        <LoaderSkeleton height={'40px'} width={'100%'}>
          <rect x="0" y="0" height={'100%'} width={'100%'} />
        </LoaderSkeleton>
      </div>
    );
  }
  return <div data-testid={'emitter'} style={{ height: '10px' }} ref={ref} />;
};
