import {
  CancelButton,
  ContinueButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { t } from 'i18next';

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onContinue: () => void;
}

export const GanttEditModal = (props: IProps): JSX.Element => {
  const { open, setOpen, onContinue } = props;

  return (
    <Modal
      title={t('tasks.gantt.editModalTitle')}
      icon={{ name: 'timeline-arrow' }}
      open={open}
      customClassContent="w-[400px]"
      onClose={setOpen}
    >
      <p className="text-sm text-gray-500 dark:text-gray-300">
        {t('tasks.gantt.editModalContent')}
      </p>
      <img
        src="/images/Gantt/tutorial.gif"
        alt={t('tasks.gantt.editModalImgAltText')}
        className="mt-4 mb-4"
      />
      <div className="flex items-center justify-end">
        <CancelButton onClick={() => setOpen(false)} className="mr-2" />
        <ContinueButton
          onClick={() => {
            onContinue();
            setOpen(false);
          }}
          data-testid="GanttEditModalContinueButton"
        />
      </div>
    </Modal>
  );
};
