import { DateUtils } from '@smack/core/utils/DateUtils';
import type { Task } from 'gantt-task-react';
import { useTranslation } from 'react-i18next';

export const TooltipContent: React.FC<{ task: Task }> = ({ task }) => {
  const { t } = useTranslation();

  return (
    <div className="bg-white p-3 border shadow-2xl rounded-lg">
      <b>{task.name}</b>
      <p>{DateUtils.getDateRangeLocalString(task.start, task.end)}</p>
      <p>{`${t('tasks.gantt.tooltip.progress')}: ${task.progress} %`}</p>
    </div>
  );
};
