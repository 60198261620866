import { getHumanReadableRecurence } from '@smack/core/components/DataInput/RecurrenceInput/Utils';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import { RRuleUtils } from '@smack/core/utils/RRule';
import type React from 'react';
import { type RRule, type RRuleSet, rrulestr } from 'rrule';

interface IProps {
  value: string;
  dtStart: Date;
  onChange: (data: string) => void;
  allowEmpty?: boolean;
  allowCustom?: boolean;
}

export const SelectRecurrenceInput: React.FC<IProps> = ({
  value,
  dtStart,
  allowEmpty = true,
  allowCustom = true,
  onChange,
}) => {
  function determineQuickRRule(val?: string): Option {
    if (!val)
      return RRuleUtils.staticQuickRRules(dtStart, allowEmpty, allowCustom)[0];
    const parsedRRule = /RRULE:.+/m.exec(val);
    if (!parsedRRule)
      return RRuleUtils.staticQuickRRules(dtStart, allowEmpty, allowCustom)[0];
    const foundOption = RRuleUtils.staticQuickRRules(
      dtStart,
      allowEmpty,
      allowCustom,
    ).find((opt) => parsedRRule[0] === opt.value);

    if (foundOption) {
      return foundOption;
    }
    return RRuleUtils.staticQuickRRules(dtStart, allowEmpty, allowCustom).slice(
      -1,
    )[0];
  }

  const getTextFromValue = (string: string): string => {
    let rrule: RRule | undefined = undefined;
    try {
      rrule = (rrulestr(string, { forceset: true }) as RRuleSet).rrules().at(0);
    } catch {
      rrule = undefined;
    }
    return rrule ? getHumanReadableRecurence(rrule) : '';
  };

  const getOptions = (): Option[] => {
    const output = [
      ...RRuleUtils.staticQuickRRules(dtStart, allowEmpty, allowCustom),
    ];
    if (value) {
      const isCustom = determineQuickRRule(value).value === 'CUSTOM';
      if (isCustom) {
        output.unshift({ label: getTextFromValue(value), value: value });
      }
    }
    return output;
  };

  const getValue = (): Option => {
    if (!value)
      return RRuleUtils.staticQuickRRules(dtStart, allowEmpty, allowCustom)[0];
    const isCustom = determineQuickRRule(value).value === 'CUSTOM';
    if (isCustom) {
      return { label: getTextFromValue(value), value: value || '' };
    }
    return determineQuickRRule(value);
  };

  return (
    <SelectInput
      width={'fluid'}
      options={getOptions()}
      value={getValue()}
      onChange={(val): void => onChange((val as Option)?.value as string)}
      isMultiple={false}
      isClearable={false}
    />
  );
};
