import { Disclosure } from '@headlessui/react';
import type { Task } from '@smack/core/api/models/tasks/Task';
import { IconFeed } from '@smack/core/components/DataDisplay/Feeds/IconFeed';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { IconLabel } from '@smack/core/components/DataDisplay/Labels/IconLabel';
import { getHeaderLabels } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/utils';
import React from 'react';

import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { Group } from '@smack/core/api/models/tasks/Group';
import {
  Button,
  IconButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { IconRoundedButton } from '@smack/core/components/Actions/Buttons/IconRoundedButton/IconRoundedButton';
import { ShowIfAllowed } from '@smack/core/components/DataDisplay/ShowIfAllowed';
import { useSearchParams } from '@smack/core/hooks/useSearchParams';
import { determineTextColor } from '@smack/core/utils/ColorUtils';
import { TaskCreateEditModal } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/TaskCreateEditModal/TaskCreateEditModal';
import { EditDeadlineDatePicker } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/TaskFeed/Components/EditDeadlineDatePicker';
import { TaskTable } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/TaskTable';
import { useTasksContext } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Context';
import { useStatefulAnimation } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Hooks/useAnimation';
import { useChangeStatus } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Hooks/useChangeStatus';
import { useTranslation } from 'react-i18next';

interface IProps {
  task: Task;
  group: Group;
  projectId: number;
  baseObject: BaseObject;
  reloadProject: () => Promise<void>;
}

/**
 * TaskFeed Components is the task element in the list
 * @param props IProps
 * @returns JSX.ELEMENT
 */
export const TaskFeed: React.FC<IProps> = (props) => {
  const { task, reloadProject, baseObject } = props;
  const { project, statuses = [] } = useTasksContext();
  const { t } = useTranslation();
  const [{ task: autoOpenTaskId }] = useSearchParams();

  const [open, setOpen] = React.useState(
    task.id === autoOpenTaskId ||
      task.status?.shouldUnfoldAutomatically ||
      false,
  );

  const [isEditing, setIsEditing] = React.useState(false);

  const [isMakingChoice, setIsMakingChoice] = React.useState(
    !task.status?.isBinaryOption,
  );

  const showChoices: React.MouseEventHandler = React.useCallback((e): void => {
    e.stopPropagation();
    setIsMakingChoice(true);
  }, []);

  const switchIsEditing = React.useCallback<React.MouseEventHandler>((e) => {
    e.stopPropagation();
    setIsEditing((isEditingState) => !isEditingState);
  }, []);

  const disclosureRef = React.useRef<HTMLDivElement>(null);

  const [hasAppeared, hasDisappeared] = useStatefulAnimation(
    task.status,
    disclosureRef,
  );

  /**
   * get the status list in JSX.ELEMENT[]
   * @returns JSX.ELEMENT[]
   */
  const [DropDownItems, changeStatus, isChangingStatus] = useChangeStatus(
    task,
    reloadProject,
  );

  const isSubscibedToProject = Boolean(project?.isSubscribed);
  let subscribeLabel: string;
  if (isSubscibedToProject) {
    subscribeLabel = 'tasks.errors.userAlreadySubscribedToProject';
  } else if (task.isSubscribed) {
    subscribeLabel = 'tasks.unsubscribe';
  } else {
    subscribeLabel = 'tasks.subscribe';
  }

  const [isSubscriptionLoading, setIsSubscriptionLoading] =
    React.useState(false);

  const onSubscribeToggle = React.useCallback<React.MouseEventHandler>(
    (event) => {
      event.stopPropagation();
      setIsSubscriptionLoading(true);
      if (task) {
        task.subscribeManager
          .toggleIsSubscribed()
          .then(reloadProject)
          .finally(() => setIsSubscriptionLoading(false));
      }
    },
    [task],
  );

  const binaryChooser = isMakingChoice ? (
    statuses
      .filter((status) => status.isBinaryOption)
      .map((status) => (
        <IconButton
          key={status.id}
          icon={status.icon}
          style={{
            backgroundColor: status.color,
            color: determineTextColor(status.color || 'black'),
          }}
          isLoading={isChangingStatus}
          disabled={isChangingStatus}
          onClick={(e): void => {
            e.stopPropagation();
            changeStatus(status)
              .then(() => setIsMakingChoice(false))
              .finally(() => setOpen(false));
          }}
        >
          {status.label}
        </IconButton>
      ))
  ) : (
    <Button onClick={showChoices}>{t('tasks.changeAnswer')}</Button>
  );

  if (!hasDisappeared && task.status?.isHidden) return null;

  return (
    <>
      <TaskCreateEditModal
        isOpen={isEditing}
        setIsOpen={setIsEditing}
        task={task}
        group={props.group}
        reloadProject={reloadProject}
      />
      <Disclosure
        as="div"
        className={`w-full border-b dark:border-gray-600 last:border-b-0 ${
          hasAppeared ? 'animate-enter-fade' : ''
        } ${hasDisappeared ? 'animate-exit-fade' : ''}`}
        ref={disclosureRef}
      >
        <IconFeed
          icon={task?.status?.icon ? task?.status?.icon : { name: 'question' }}
          roundColor={task?.status?.color ? task?.status?.color : 'gray'}
          iconDropDown={DropDownItems}
          hideLine
          leftGapSize="large"
          isLoading={isChangingStatus}
        >
          <div className="w-full px-4 mb-2 ">
            <div
              onClick={(): void => setOpen(!open)}
              className="w-full min-h-[70px] flex flex-col md:flex-row py-3 gap-1 md:gap-4 items-start md:items-center cursor-pointer"
            >
              <div className="flex-grow flex items-center gap-3">
                <div className="min-w-[200px]">
                  <p className="text-sm font-medium text-menu-active truncate">
                    {task?.label}
                  </p>
                  <div className="flex flex-wrap">
                    {getHeaderLabels(undefined, task)?.map((label) => {
                      if (label.trigger === 'changeDeadline') {
                        return (
                          <EditDeadlineDatePicker
                            key={label.label}
                            headerLabel={label}
                            task={task}
                            reloadProject={reloadProject}
                          />
                        );
                      }
                      return (
                        <IconLabel
                          className="mr-3 !text-sm text-gray-500 dark:text-gray-400"
                          key={label.label}
                          {...label}
                        />
                      );
                    })}
                  </div>
                </div>
                <ShowIfAllowed>
                  {task.isBinaryChoice ? binaryChooser : null}
                </ShowIfAllowed>
              </div>
              <div className="flex items-center gap-2 flex-row-reverse md:flex-row">
                <IconRoundedButton
                  onClick={onSubscribeToggle}
                  disabled={isSubscibedToProject}
                  isLoading={isSubscriptionLoading}
                  icon={{ name: task.isSubscribed ? 'eye-slash' : 'eye' }}
                  title={t(subscribeLabel)}
                  position="top"
                />
                <ShowIfAllowed>
                  <IconRoundedButton
                    onClick={switchIsEditing}
                    icon={{ name: 'pen' }}
                    title={t('edit.edit')}
                    position="top"
                  />
                </ShowIfAllowed>
                <Icon
                  icon={{ name: open ? 'chevron-down' : 'chevron-right' }}
                  className="h-4 w-4 text-gray-500 dark:text-gray-400"
                />
              </div>
            </div>

            {open && (
              <Disclosure.Panel static>
                <TaskTable
                  task={task}
                  baseObject={baseObject}
                  reloadProject={reloadProject}
                  switchIsEditing={switchIsEditing}
                />
              </Disclosure.Panel>
            )}
          </div>
        </IconFeed>
      </Disclosure>
    </>
  );
};
