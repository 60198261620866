import type { BaseObjectView } from '@smack/core/api/models/views/BaseObjectView/BaseObjectView';
import type { DisplayedFieldType } from '@smack/core/api/models/views/ViewElement/enums';
import type {
  BaseObjectFormOutput,
  BaseObjectFormOutputValues,
} from '@smack/core/components/ViewRenderer/interfaces';

export type DefaultValuesFromFieldType = Partial<{
  [key in DisplayedFieldType]: BaseObjectFormOutputValues;
}>;

export const assignDefaultValueFormBaseobjectView = (
  defaultValues: DefaultValuesFromFieldType,
  view?: BaseObjectView,
) => {
  const output: BaseObjectFormOutput = {};
  if (!view) return output;
  for (const section of view.viewSections) {
    for (const element of section.viewElements) {
      if (element.fieldType in defaultValues && element.attribute?.id) {
        output[element.attribute?.id] = defaultValues[element.fieldType];
      }
    }
  }
  return output;
};
