import type {
  LoginMessagesStorage,
  LoginMessagesStorageAction,
} from '@smack/core/utils/LoginMessagesStorage/types';

export function loginMessagesStorageReducer(
  state: LoginMessagesStorage,
  action: LoginMessagesStorageAction,
): LoginMessagesStorage {
  if (action.type === 'add') {
    return [
      ...state.filter((m) => m.id !== action.payload.message.id),
      action.payload.message,
    ];
  }
  // clear
  return [];
}
