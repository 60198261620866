import {
  getWeekdayByNumber,
  getWeekdayOfMonth,
} from '@smack/core/components/DataInput/RecurrenceInput/Utils';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import { getI18n } from 'react-i18next';
import { type RRule, rrulestr } from 'rrule';

export class RRuleUtils {
  static getRRuleSetFromString(value?: string): RRule | undefined {
    if (!value) return undefined;
    try {
      return rrulestr(value) as RRule;
    } catch {
      return undefined;
    }
  }

  static staticQuickRRules(
    dtStart?: Date,
    allowEmpty = true,
    allowCustom = true,
  ): Option<string>[] {
    if (!dtStart) return [];
    const { t } = getI18n();
    const dtStartWeekday = dtStart?.toLocaleDateString(navigator.language, {
      weekday: 'long',
    });
    const dayOfMonth = getWeekdayOfMonth(dtStart);
    const output = [
      {
        label: t('recurrence.everyWeekDays', {
          day: dtStartWeekday,
        }),
        value: 'RRULE:FREQ=WEEKLY',
      },
      {
        label: t('recurrence.everyYearsOn', {
          date: dtStart?.toLocaleDateString(navigator.language, {
            month: 'long',
            day: 'numeric',
          }),
        }),
        value: `RRULE:FREQ=YEARLY;BYMONTH=${
          dtStart ? dtStart.getMonth() + 1 : ''
        };BYMONTHDAY=${dtStart ? dtStart.getDate() : ''}`,
      },
      {
        label: t('recurrence.everyWorkDay'),
        value: 'RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR',
      },
      {
        label: t('recurrence.everyWeekDay'),
        value: 'RRULE:FREQ=DAILY',
      },
      {
        label: t('recurrence.everyDateOfTheMonth', {
          number: t(`numbers.${dayOfMonth}`),
          weekday: dtStartWeekday,
        }),
        value: `RRULE:FREQ=MONTHLY;${
          dtStartWeekday
            ? `BYDAY=+${dayOfMonth}${getWeekdayByNumber(dtStart.getDay())}`
            : ''
        }`,
      },
    ];
    if (allowCustom) {
      output.push({ label: t('recurrence.custom'), value: 'CUSTOM' });
    }
    if (allowEmpty)
      output.unshift({ label: t('recurrence.once'), value: 'NORRULE' });
    return output;
  }
}
