import { Category } from '@smack/core/api/models/categories/Category';
import { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { ListBaseObject } from '@smack/core/api/models/objects/NewBaseObject/ListBaseObject';
import { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import {
  CloseButton,
  IconButton,
  SaveButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { ConfirmBaseAlert } from '@smack/core/components/DataDisplay/Alerts/ConfirmAlert';
import { DataDisplayHeader } from '@smack/core/components/DataDisplay/Headers/DataDisplayHeader';
import { ModalBarLoader } from '@smack/core/components/DataDisplay/Modals/ModalBarLoader/ModalBarLoader';
import { AutoBaseObjectView } from '@smack/core/components/ViewRenderer/AutoBaseObjectView';
import type {
  BaseObjectFormOnSubmit,
  BaseObjectFormOutput,
  BaseObjectFormViewRenderForwardedRef,
} from '@smack/core/components/ViewRenderer/interfaces';
import { assignRef } from '@smack/core/utils';
import { FiltersQueryParams, type IFilters } from '@smack/core/utils/Filters';
import { spawnModal } from '@smack/core/utils/modal';
import { BatchLinkForm } from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/BatchLinkForm/BatchLinkForm';
import {
  type DefaultValuesFromFieldType,
  assignDefaultValueFormBaseobjectView,
} from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/common/assignDefaultValueFormBaseobjectView';
import { BaseObjectViewContextProvider } from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/common/context/BaseObjectViewContext';
import { onQuit } from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/common/onQuit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncMemo } from 'use-async-memo';

export interface BatchUpdateFormProps {
  categoryId: number;
  filters?: IFilters;
  onSuccessfulFormSubmission?: (output?: { id: number }) => void;
  defaultValuesFormFieldType?: DefaultValuesFromFieldType;
  defaultValues?: BaseObjectFormOutput;
  onClose?: () => void;
}

export const BatchUpdateForm: React.FC<BatchUpdateFormProps> = ({
  categoryId,
  onClose,
  filters,
  onSuccessfulFormSubmission,
  defaultValuesFormFieldType = {},
  defaultValues = {},
}) => {
  const formRef = React.useRef<BaseObjectFormViewRenderForwardedRef>(null);
  const [formRendered, setFormRendered] = React.useState<boolean>(false);
  const [isSubmissionLoading, setSubmissionLoading] = React.useState(false);
  const [isProgressOpen, setProgressOpen] = React.useState(false);
  const [linkForm, setLinkForm] = React.useState<boolean>(false);
  const [t] = useTranslation();

  const category = useAsyncMemo(() => {
    return Category.getCategory(categoryId);
  }, [categoryId]);

  const countBaseObjectsToUpdate = useAsyncMemo(() => {
    if (!category) return;
    return ListBaseObject.getBaseObjectForListRepresentation(
      category,
      { ...filters, categories: [categoryId.toString()] },
      0,
      1,
      [
        FiltersQueryParams.ATTRIBUTE_FILTER,
        FiltersQueryParams.SEARCH,
        FiltersQueryParams.CATEGORY_IN,
        FiltersQueryParams.DATE_RANGE,
      ],
    ).then((res) => res.data.count);
  }, [category, filters]);

  React.useEffect(() => {
    if (formRef?.current?.useForm) {
      formRef?.current?.useForm.reset({
        ...assignDefaultValueFormBaseobjectView(
          defaultValuesFormFieldType,
          formRef.current.view,
        ),
        ...defaultValues,
      });
    }
  }, [formRendered]);

  const onClickSubmit = (): void => {
    spawnModal({
      render: ({ onClose: onCloseModal }): JSX.Element => {
        return (
          <ConfirmBaseAlert
            icon={{ name: 'warning' }}
            title={t('batchUpdateForm.confirmTitle', {
              count: countBaseObjectsToUpdate ?? 0,
            })}
            text={t('batchUpdateForm.confirmText', {
              count: countBaseObjectsToUpdate ?? 0,
            })}
            onCloseModal={onCloseModal}
            onCloseButton={onCloseModal}
            onOk={(): void => {
              setSubmissionLoading(true);
              setProgressOpen(true);
              formRef.current?.formRef?.requestSubmit();
            }}
          />
        );
      },
    });
  };

  const handleQuit = () => {
    onQuit(formRef.current, onClose, onClickSubmit);
  };

  const handleOnSuccessfulFormSubmission = (data?: { id: number }) => {
    onSuccessfulFormSubmission?.(data);
    setSubmissionLoading(false);
    setTimeout(() => {
      onClose?.();
    }, 3000);
  };

  const callBackRef = (ref: BaseObjectFormViewRenderForwardedRef) => {
    setFormRendered(true);
    assignRef(formRef, ref);
  };

  if (!category) return null;

  const onSubmit: BaseObjectFormOnSubmit = (attributes, recurrenceType) => {
    return BaseObject.patchBaseObjectBatch(
      categoryId,
      { attributes },
      filters,
    ).then(() => handleOnSuccessfulFormSubmission());
  };

  return (
    <BaseObjectViewContextProvider>
      <div
        data-testid={'batch-update-form'}
        className="flex flex-col flex-grow bg-view"
      >
        <ModalBarLoader
          icon={{ name: 'check' }}
          title={t('batchUpdateForm.progressTitle')}
          text={
            isSubmissionLoading
              ? t('batchUpdateForm.loading')
              : t('batchUpdateForm.successfulSubmission')
          }
          progressBarProps={{
            color: isSubmissionLoading ? undefined : 'green',
            animated: isSubmissionLoading,
          }}
          open={isProgressOpen}
          onClose={setProgressOpen}
        />
        <DataDisplayHeader
          icon={category.icon}
          color={category.color ?? ''}
          title={t('batchUpdateForm.headerTitle', {
            name: category.label ?? '',
            count: countBaseObjectsToUpdate ?? 0,
          })}
          actions={[
            <IconButton
              onClick={() => setLinkForm(!linkForm)}
              key="mode"
              icon={{
                name: !linkForm ? 'link' : 'list-dots',
              }}
            >
              {!linkForm
                ? t('batchUpdateForm.linkUpdate')
                : t('batchUpdateForm.attributesUpdate')}
            </IconButton>,
            !linkForm ? (
              <SaveButton
                key="save"
                onClick={onClickSubmit}
                data-testid="BatchUpdateFormSaveButton"
              />
            ) : null,
            <CloseButton
              key="quit"
              onClick={handleQuit}
              data-testid="BatchUpdateFormCloseButton"
            />,
          ]}
        />
        <div className={'flex flex-grow p-6 overflow-y-auto'}>
          {linkForm ? (
            <BatchLinkForm categoryId={categoryId} filters={filters} />
          ) : (
            <AutoBaseObjectView
              ref={callBackRef}
              filters={{ ...filters, categories: [categoryId.toString()] }}
              viewUsage={ViewUsage.FORM}
              toggleableFields={true}
              categoryId={category.id}
              onSubmit={onSubmit}
            />
          )}
        </div>
      </div>
    </BaseObjectViewContextProvider>
  );
};
