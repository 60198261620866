import type { IPagination } from '@smack/core/api/clients/rest/RESTClient';
import {
  SelectInput,
  type SelectInputProps,
} from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import { useDebounce } from '@smack/core/hooks/useDebounce/useDebounce';
import {
  type UsePaginationProps,
  usePagination,
} from '@smack/core/hooks/usePagination/usePagination';
import React from 'react';

interface AutoSelectPrivateProps<T, D = T>
  extends Omit<UsePaginationProps<T, D>, 'query'> {
  query: (search: string) => Promise<IPagination<T>>;
  searchParams?: (searchValue: string) => Record<string, unknown>;
  mapToOption: (value: D) => Option;
}

export type AutoSelectProps<T, D> = SelectInputProps &
  AutoSelectPrivateProps<T, D>;

export const AutoSelectWithPagination = <T, D>({
  query,
  transformer,
  mapToOption,
  searchParams,
  ...selectProps
}: AutoSelectProps<T, D>): React.ReactElement => {
  const [search, setSearch] = React.useState<string>();
  const [hasOpened, setHasOpened] = React.useState(false);
  const debouncedSearch = useDebounce(search, 500);

  const { results, isLoading, next, loadInitial, count } = usePagination<T, D>({
    query: () => query(search ?? ''),
    transformer,
  });

  React.useEffect(() => {
    if (debouncedSearch || debouncedSearch === '') {
      loadInitial();
    }
  }, [debouncedSearch]);

  const handleClick = () => {
    if (hasOpened) return;
    setHasOpened(true);
    loadInitial();
  };

  return (
    <SelectInput
      {...selectProps}
      onSearch={setSearch}
      options={results?.map(mapToOption) ?? []}
      onLastOptionVisible={next}
      onMenuOpen={handleClick}
      count={count}
      needFilter={false}
      isLoading={isLoading}
    />
  );
};
