import { IconButton } from '@smack/core/components/Actions/Buttons/Button';
import type React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ConfirmBaseAlert, type IConfirmBaseProps } from '../ConfirmBaseAlert';

export const QuitOrSaveConfirmAlert: React.FC<IConfirmBaseProps> = ({
  text,
  title,
  ...props
}): JSX.Element => {
  const [t] = useTranslation();

  return (
    <ConfirmBaseAlert
      {...props}
      title={title || t('confirmModal.savedModifications')}
      text={text || <Trans t={t} i18nKey="confirmModal.confirmSave" />}
      okButton={
        <IconButton icon={{ name: 'save' }}>
          {t('confirmModal.saveAndClose')}
        </IconButton>
      }
      closeButton={
        <IconButton icon={{ name: 'times' }}>
          {t('confirmModal.closeWithoutSaving')}
        </IconButton>
      }
    />
  );
};
