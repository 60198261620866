import type { MapsPreferences } from '@smack/core/api/models/users/User/UserPreferences/UserPreferences';
import { usePreferencesManager } from '@smack/core/hooks/preferences/usePreferencesManager/usePreferencesManager';
import React, { useCallback } from 'react';

type OutputUseMapsPreferences = [
  preferences: MapsPreferences | undefined,
  setPreferences: (pref: MapsPreferences) => void,
];

export const useMapsPreferences = (): OutputUseMapsPreferences => {
  const preferencesManager = usePreferencesManager();
  const [, rerender] = React.useState({});

  const mapsPreferences = preferencesManager?.getMapsPreferences();

  const setPreferences = useCallback(
    (pref: MapsPreferences) => {
      preferencesManager?.setMapsPreferences(pref);
      rerender({});
    },
    [preferencesManager],
  );

  return [mapsPreferences, setPreferences];
};
