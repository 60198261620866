import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import { TableContent } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/TaskTable/Components/TableContent';
import { TableHeader } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/TaskTable/Components/TableHeader';
import React from 'react';

interface TableBlockProps {
  actionButton?: React.ReactNode;
  children?: React.ReactNode;
  title: string;
  icon: IconField;
  contentClassName?: string;
  onClose?: Parameters<typeof TableHeader>[0]['onClose'];
  collapsable?: boolean;
  defaultOpenState?: boolean;
}

export const TableBlock: React.FC<TableBlockProps> = ({
  actionButton,
  title,
  icon,
  children,
  contentClassName,
  onClose,
  collapsable = false,
  defaultOpenState = !collapsable,
}) => {
  const openState = React.useState(defaultOpenState);

  return (
    <div className="rounded-lg border">
      <TableHeader
        label={title}
        icon={icon}
        onClose={onClose}
        collapsable={collapsable}
        openState={openState}
      >
        {actionButton}
      </TableHeader>
      {openState[0] ? (
        <TableContent className={contentClassName}>{children}</TableContent>
      ) : null}
    </div>
  );
};
