import {
  type IModalProps,
  Modal,
} from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import type React from 'react';

import {
  type IProgressBarProps,
  ProgressBar,
} from '../../ProgressBar/ProgressBar';

export interface IModalProgressBar extends Omit<IModalProps, 'children'> {
  text: string;
  progressBarProps: IProgressBarProps;
}

export const ModalProgressBar: React.FC<IModalProgressBar> = ({
  open,
  onClose,
  text,
  progressBarProps,
  ...modalProps
}): JSX.Element => {
  return (
    <Modal open={open} onClose={onClose} {...modalProps}>
      <div className={'flex flex-col gap-3 w-[300px] justify-center p-4'}>
        <p className={'text-center  text-gray-500'}>{text}</p>
        <ProgressBar {...progressBarProps} />
      </div>
    </Modal>
  );
};
