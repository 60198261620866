import React, { type RefObject } from 'react';

export const useOnScreen = (ref: RefObject<HTMLDivElement | null>): boolean => {
  const [isIntersecting, setIntersecting] = React.useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting),
  );

  React.useEffect(() => {
    if (ref?.current) {
      observer.observe(ref?.current);
    }
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
};
