import React, { useRef } from 'react';

import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { BaseObjectView } from '@smack/core/api/models/views/BaseObjectView/BaseObjectView';
import type { ViewLayoutComponent } from '@smack/core/components/ViewRenderer/interfaces';
import { layoutTypeMap } from '@smack/core/components/ViewRenderer/layoutTypeMap';
import { BaseObjectFallbackLayout } from '@smack/core/components/ViewRenderer/layouts/BaseObjectFallbackLayout';
import { renderSections } from '@smack/core/components/ViewRenderer/renderSections';

interface BaseObjectDisplayViewRendererProps {
  view: BaseObjectView;
  baseObject?: BaseObject;
  LayoutComponent?: ViewLayoutComponent;
}

export const BaseObjectDisplayViewRenderer: React.FC<
  BaseObjectDisplayViewRendererProps
> = ({
  view,
  baseObject,
  LayoutComponent = layoutTypeMap[view.usage] ?? BaseObjectFallbackLayout,
}) => {
  const printRef = useRef<HTMLDivElement>(null);

  const sections = React.useMemo(
    () =>
      renderSections({
        view,
        baseObject,
        categoryId: baseObject?.category?.id,
        additionalProps: {
          printRef,
        },
      }),
    [view],
  );

  return (
    <LayoutComponent
      renderedSections={sections}
      view={view}
      printRef={printRef}
    />
  );
};
