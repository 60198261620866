import {
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import React from 'react';

interface IProps {
  children: React.ReactNode;
  className?: string;
  minBadgeCount?: number;
}

export const BadgesOverflowWrapper: React.FC<IProps> = ({
  children,
  className,
  minBadgeCount = 1,
}) => {
  const [open, setOpen] = React.useState(false);
  const nodeId = useFloatingNodeId();

  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [maxBadgeCount, setMaxBadgeCount] = React.useState(0);

  const { x, y, refs, strategy, context } = useFloating({
    strategy: 'absolute',
    open: open,
    onOpenChange: setOpen,
    nodeId,
    placement: 'right-start',
    middleware: [flip(), shift(), offset({ mainAxis: 10, alignmentAxis: 0 })],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, {
      event: 'mousedown',
      toggle: true,
    }),
    useRole(context, { role: 'menu' }),
    useDismiss(context),
  ]);

  const getBadges = (): React.ReactNode[] => {
    let badges: React.ReactNode[] = [];
    if (Array.isArray(children)) {
      badges = children as React.ReactNode[];
    } else {
      badges = [children];
    }
    return badges;
  };

  React.useEffect(() => {
    const updateBadgeCount = (): void => {
      if (wrapperRef.current) {
        const wrapperWidth = wrapperRef.current.offsetWidth;

        const badgeWidth = 90 + 24;
        const newMaxBadgeCount = Math.floor(wrapperWidth / badgeWidth);
        if (newMaxBadgeCount <= minBadgeCount) {
          setMaxBadgeCount(minBadgeCount);
          return;
        }
        setMaxBadgeCount(newMaxBadgeCount === 0 ? 1 : newMaxBadgeCount);
      }
    };

    window.addEventListener('resize', updateBadgeCount);
    updateBadgeCount();

    return () => {
      window.removeEventListener('resize', updateBadgeCount);
    };
  }, [children, wrapperRef]);

  return (
    <FloatingTree>
      <div
        className={`w-full h-full min-w-[90px]  flex items-center gap-2 ${
          className ?? ''
        }`}
        ref={wrapperRef}
      >
        {getBadges()
          .slice(0, maxBadgeCount)
          .map((badge) => badge)}
        {getBadges().length > maxBadgeCount && (
          <FloatingNode id={nodeId}>
            <span
              key={nodeId}
              ref={refs.setReference}
              {...getReferenceProps({
                onClick(e) {
                  e.stopPropagation();
                },
              })}
              className="cursor-pointer min-w-[24px] min-h-[24px] bg-gray-200 flex items-center justify-center rounded-full text-gray-500 text-sm"
            >
              +{getBadges().length - maxBadgeCount}
            </span>
          </FloatingNode>
        )}
      </div>
      <FloatingPortal>
        {open && (
          <div
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              width: 'max-content',
            }}
            {...getFloatingProps()}
            className="bg-primary z-[1000]  select-none p-3  rounded-md shadow-[#63636333_0px_2px_8px_0px] flex flex-col gap-2"
          >
            {getBadges()
              .slice(maxBadgeCount)
              .map((badge) => badge)}
          </div>
        )}
      </FloatingPortal>
    </FloatingTree>
  );
};
