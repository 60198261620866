export interface NotificationCounterApiOutput {
  categoryId: number;
  unreadNotifications: number;
}

export class NotificationCounter {
  categoryId: number;

  unreadNotifications: number;

  constructor(data: NotificationCounterApiOutput) {
    this.categoryId = data.categoryId;
    this.unreadNotifications = data.unreadNotifications;
  }

  get displayLabel() {
    return this.unreadNotifications > 9 ? '9+' : this.unreadNotifications;
  }
}
