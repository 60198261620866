import type { ParentCategory } from '@smack/core/api/models/categories/Category/ParentCategory';
import type { SubCategory } from '@smack/core/api/models/categories/Category/SubCategory';
import type { NotificationCounter } from '@smack/core/api/models/objects/NotificationCounter/NotificationCounter';

/**
 * Interface for the Categories store
 *
 * @export
 * @interface ICategoriesState
 */
export interface ICategoriesState {
  rootCategories: ParentCategory[];
  rootCategoriesFetched: boolean;
  setCategories: ParentCategory[];
  setCategoriesFetched: boolean;
  subSetCategories: SubCategory[];
  subSetCategoriesFetched: boolean;
  notificationCounters: NotificationCounter[];
}

// Actions Constants
export const FETCH_ROOT_CATEGORIES = 'CATEGORIES::FETCH_ROOT_CATEGORIES';
export const FETCH_SET_CATEGORIES = 'CATEGORIES::FETCH_SET_CATEGORIES';
export const RESET_SET_CATEGORIES = 'CATEGORIES::RESET_SET_CATEGORIES';
export const FETCH_SUB_SET_CATEGORIES = 'CATEGORIES::FETCH_SUB_SET_CATEGORIES';
export const RESET_SUB_SET_CATEGORIES = 'CATEGORIES::RESET_SUB_SET_CATEGORIES';
export const FETCH_NOTIFICATION_COUNTERS =
  'CATEGORIES::FETCH_NOTIFICATION_COUNTERS';

export interface IFetchRootCategories {
  type: typeof FETCH_ROOT_CATEGORIES;
  payload: ParentCategory[];
}

export interface IFetchSetCategories {
  type: typeof FETCH_SET_CATEGORIES;
  payload: ParentCategory[];
}

export interface IResetSetCategories {
  type: typeof RESET_SET_CATEGORIES;
}

export interface IFetchSubSetCategories {
  type: typeof FETCH_SUB_SET_CATEGORIES;
  payload: SubCategory[];
}

export interface IResetSubSetCategories {
  type: typeof RESET_SUB_SET_CATEGORIES;
}

export interface IFetchNotificationCounters {
  type: typeof FETCH_NOTIFICATION_COUNTERS;
  payload: NotificationCounter[];
}

export type CategoriesActionType =
  | IFetchRootCategories
  | IFetchSetCategories
  | IResetSetCategories
  | IFetchSubSetCategories
  | IResetSubSetCategories
  | IFetchNotificationCounters;
