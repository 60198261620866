import { VirtualizedList } from '@smack/core/components/DataDisplay/Lists/VirtualizedList';
import { type Index, type IndexRange, InfiniteLoader } from 'react-virtualized';

/**
 * @deprecated Use IndexRange from react-virtualized directly, it's the same interface
 */
export interface IloadMoreRowsOutput {
  startIndex: number;
  stopIndex: number;
}

interface InfiniteVirtualizedListPropsWithoutSkeletonsForUnloadedItems<
  T = unknown,
> {
  objects: T[];
  startIndex: number;
  renderListItem: (object: T, index?: number) => JSX.Element;
  rowHeight?: number | ((object: T, index?: number) => number);
  // biome-ignore lint/suspicious/noExplicitAny: FIXME
  loadMoreRows?: (indicesData: IndexRange) => any;
  rowCount?: number;
  showSkeletonsForUnloadedItems?: false;
}

interface InfiniteVirtualizedListPropsWithSkeletonsForUnloadedItems<
  T = unknown,
> {
  objects: T[];
  startIndex: number;
  renderListItem: (object: T | undefined, index?: number) => JSX.Element;
  rowHeight?: number | ((object: T | undefined, index?: number) => number);
  // biome-ignore lint/suspicious/noExplicitAny: FIXME
  loadMoreRows?: (indicesData: IndexRange) => any;
  rowCount?: number;
  showSkeletonsForUnloadedItems: true;
}

type InfiniteVirtualizedListProps<T> =
  | InfiniteVirtualizedListPropsWithoutSkeletonsForUnloadedItems<T>
  | InfiniteVirtualizedListPropsWithSkeletonsForUnloadedItems<T>;

// TODO: Skeletons for unloaded items should be the norm going forward, convert legacy usages
export const InfiniteVirtualizedList = <T,>({
  startIndex,
  renderListItem,
  objects,
  loadMoreRows,
  rowHeight = 81,
  rowCount = Number.POSITIVE_INFINITY,
  showSkeletonsForUnloadedItems,
}: InfiniteVirtualizedListProps<T>): JSX.Element => {
  const isRowLoaded = ({ index }: Index): boolean => {
    return !!objects[index];
  };

  const renderListItemProxy = (object: T | undefined, index?: number) => {
    if (typeof object !== 'undefined') return renderListItem(object, index);
    return showSkeletonsForUnloadedItems ? renderListItem(object, index) : null;
  };

  const rowHeightProxy = (
    object: T | undefined,
    index?: number | undefined,
  ) => {
    if (typeof rowHeight === 'number') return rowHeight;
    if (object || showSkeletonsForUnloadedItems)
      // @ts-expect-error TS2345: Type inference is incorrect
      return rowHeight(object, index);
    return 81;
  };

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={async (indices) => loadMoreRows?.(indices)}
      rowCount={rowCount}
      threshold={1}
    >
      {({ onRowsRendered, registerChild }): JSX.Element => (
        <VirtualizedList
          objects={objects}
          startIndex={startIndex}
          rowHeight={rowHeightProxy}
          rowCount={showSkeletonsForUnloadedItems ? rowCount : undefined}
          renderListItem={renderListItemProxy}
          onRowsRendered={onRowsRendered}
          ref={registerChild}
        />
      )}
    </InfiniteLoader>
  );
};
