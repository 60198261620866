import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import { type JSX, useState } from 'react';

interface IFilterBadgeClassNames {
  container?: string;
  label?: string;
  icon?: string;
}

interface Action {
  icon?: IconField;
  onClick?: () => void;
}

export interface IProps {
  label: string;
  labelColor?: string;
  onClick?: () => void;
  actions?: Action[];
  className?: IFilterBadgeClassNames;
  children?: (isActive: boolean) => JSX.Element | undefined;
}

/**
 * Generic Badge Element
 * @param props
 * @returns JSX.Elements
 */
export const FiltersBadge = (props: IProps): JSX.Element => {
  const { label, onClick, actions, className, children } = props;

  const [isActionActive, setIsActionActive] = useState(false);

  const onHandleActionClic = (action: Action): void => {
    setIsActionActive((previous) => !previous);
    if (action.onClick) action?.onClick();
  };

  return (
    <div
      key={label}
      onClick={onClick}
      className={`inline-flex items-center rounded-full bg-blue-100 dark:bg-blue-500 dark:bg-opacity-20 py-0.5 p-2.5 max-w-[350px]
        ${onClick ? 'cursor-pointer pr-0' : ''}
        ${actions?.length ? 'pr-0' : ''}
        ${className?.container ?? ''}`}
    >
      <span
        title={label}
        className={`text-xs text-blue-500 truncate ${className?.label ?? ''}`}
      >
        {label}
      </span>
      {actions?.map((action, index) => (
        <button
          // biome-ignore lint/suspicious/noArrayIndexKey: FIXME
          key={index}
          onClick={(): void => onHandleActionClic(action)}
          type="button"
          className="mx-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-500 hover:bg-gray-300 hover:text-gray-600 focus:outline-none"
        >
          <span className="sr-only">Supprimer</span>
          <Icon
            icon={action.icon ?? { name: 'times' }}
            className={`text-xs text-blue-500 ${className?.icon ?? ''}`}
          />
        </button>
      ))}
      {children?.(isActionActive)}
    </div>
  );
};
