import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import { ParentCategory } from '@smack/core/api/models/categories/Category/ParentCategory';
import { SubCategory } from '@smack/core/api/models/categories/Category/SubCategory';
import { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { env } from '@smack/core/env';
import type { IFilters } from '@smack/core/utils/Filters';
import type { AxiosError } from 'axios';
import { addDays } from 'date-fns';
import type { AppDispatch } from '..';
import {
  type BaseAppActionType,
  FETCH_LEGEND,
  FETCH_LOADING,
  FETCH_MENUFILTEREXTEND,
  FETCH_SETTINGS,
  FETCH_THEME,
  type IAppSettings,
  type ILegend,
  type IModuleStore,
  type ISetCurrentView,
  type ISetIsAutoOpeningFile,
  type ISetLastViewAsCurrent,
  type ITheme,
  SET_CURRENT_VIEW,
  SET_FOCUSED_BASEOBJECT,
  SET_IS_AUTO_OPENING_FILE,
  SET_IS_MENU_COLLAPSED,
  SET_LAST_VIEW_AS_CURRENT,
  SET_MODULE_STORE,
  SET_ONLINE,
  type ViewActive,
} from './types';

// Actions Creators

export const setIsMenuCollapsed = (
  isMenuCollapsed: boolean,
): BaseAppActionType => {
  return {
    type: SET_IS_MENU_COLLAPSED,
    payload: { isMenuCollapsed },
  };
};

export const toggleVisualizationFilter = (
  visualizedAttributes: Record<number, string> | undefined,
  categoryId: number,
  attributeId: number,
): Record<number, string> => {
  if (!visualizedAttributes)
    return {
      [categoryId]: attributeId.toString(),
    };
  // if not in record, add it
  if (
    !visualizedAttributes[categoryId] ||
    visualizedAttributes[categoryId] !== attributeId.toString()
  ) {
    visualizedAttributes[categoryId] = attributeId.toString();
    return visualizedAttributes;
  }
  // else, because records are imutable,
  // create a copy without the existing record
  const newVisualizedAttributes = { ...visualizedAttributes };
  delete newVisualizedAttributes[categoryId];
  return newVisualizedAttributes;
};

export const toggleAttributeFilter = (
  filteredAttributes: Record<number, Record<number, string[]>> | undefined,
  categoryId: number,
  attributeId: number,
  choiceId: string,
): Record<number, Record<number, string[]>> => {
  if (!filteredAttributes)
    return {
      [categoryId]: {
        [attributeId]: [choiceId.toString()],
      },
    };
  // because records are imutable create a copy
  // to trigger react state effects
  const newFilteredAttributes = { ...filteredAttributes };
  // if not category recorded, add it
  if (!newFilteredAttributes[categoryId]) {
    newFilteredAttributes[categoryId] = {
      [attributeId]: [choiceId.toString()],
    };
    return newFilteredAttributes;
  }
  // if attribute not recorded, add it
  if (!newFilteredAttributes[categoryId][attributeId]) {
    newFilteredAttributes[categoryId][attributeId] = [choiceId.toString()];
    return newFilteredAttributes;
  }
  // if not in existing record, add it
  if (
    !newFilteredAttributes[categoryId][attributeId].includes(
      choiceId.toString(),
    )
  ) {
    newFilteredAttributes[categoryId][attributeId].push(choiceId.toString());
    return newFilteredAttributes;
  }
  // if not only choice in attribute, remove selected
  if (newFilteredAttributes[categoryId][attributeId].length > 1) {
    newFilteredAttributes[categoryId][attributeId] = newFilteredAttributes[
      categoryId
    ][attributeId].filter(
      (savedChoice) => savedChoice.toString() !== choiceId.toString(),
    );
    return newFilteredAttributes;
  }
  // else delete attribute record
  delete newFilteredAttributes[categoryId][attributeId];
  // if category record is empty, remove it too
  if (!Object.keys(newFilteredAttributes[categoryId]).length)
    delete newFilteredAttributes[categoryId];
  return newFilteredAttributes;
};

export const setLegend = (legend?: ILegend[]): BaseAppActionType => {
  return {
    type: FETCH_LEGEND,
    payload: legend,
  };
};

export const setLoading = (active: boolean): BaseAppActionType => {
  return {
    type: FETCH_LOADING,
    payload: active,
  };
};

export const setOnline = (online: boolean): BaseAppActionType => {
  return {
    type: SET_ONLINE,
    payload: online,
  };
};

export const setMenuFilterExtend = (extend: boolean): BaseAppActionType => {
  return {
    type: FETCH_MENUFILTEREXTEND,
    payload: extend,
  };
};

export const setTheme = (theme: ITheme): BaseAppActionType => {
  return {
    type: FETCH_THEME,
    payload: theme,
  };
};

export const setAppSettings = (settings?: IAppSettings): BaseAppActionType => {
  return {
    type: FETCH_SETTINGS,
    payload: settings,
  };
};

export const setModuleStore = (
  store?: Partial<IModuleStore>,
): BaseAppActionType => {
  return {
    type: SET_MODULE_STORE,
    payload: store,
  };
};

export const setFocusedBaseObject = (
  baseObject?: BaseObject,
): BaseAppActionType => {
  return {
    type: SET_FOCUSED_BASEOBJECT,
    payload: baseObject,
  };
};

export const fetchFocusedBaseObject =
  (
    baseobjectId: number,
    scheduleId?: number,
    onError?: (err: AxiosError) => void,
  ) =>
  (dispatch: AppDispatch): void => {
    BaseObject.getBaseObject(baseobjectId, scheduleId)
      .then((obj) => {
        dispatch(setFocusedBaseObject(obj));
      })
      .catch(onError);
  };

export const fetchAppSettings =
  (fetchPublic: boolean) =>
  (dispatch: AppDispatch): void => {
    RESTClient.get<{ data: { results: IAppSettings } }>(
      `/customer/customers/${env.VITE_API_PUBLIC_KEY}${
        fetchPublic ? '/public' : ''
      }`,
    )
      .then((res: { data: { results: IAppSettings } | undefined }) => {
        dispatch(setAppSettings(res.data?.results ?? {}));
      })
      .catch(() => {
        dispatch(setAppSettings({}));
      });
  };

export const getDefaultDaterange = (): IFilters => {
  return {
    daterange: {
      start: new Date().toISOString(),
      stop: addDays(new Date(), 7).toISOString(),
    },
  };
};

export const getDefaultSort = (): IFilters => {
  return {
    sort: '-modified_at',
  };
};

export const setDefaultFilters = (
  category?: SubCategory | ParentCategory,
): IFilters => {
  const output: IFilters = {
    currentCategoryId: category?.id,
    search: undefined,
    categories: [],
    period: undefined,
    reportState: false,
    viewedNotifications: false,
    draw: false,
    screen: false,
    showGeom: true,
    filteringLayer: undefined,
    containingFeatures: [],
    visualizedAttributes: {},
    filteredAttributes: {},
    ...getDefaultSort(),
  };

  if (
    category instanceof ParentCategory &&
    category.hasSubcategoryCalendarFilterAvailable
  ) {
    Object.assign(output, getDefaultDaterange());
  } else if (
    category instanceof SubCategory &&
    category.isCalendarFilterAvailable
  ) {
    Object.assign(output, getDefaultDaterange());
  }
  return output;
};

export const setCurrentView = <T>(view: ViewActive<T>): ISetCurrentView => {
  return {
    type: SET_CURRENT_VIEW,
    payload: view,
  };
};

export const setLastViewAsCurrent = (): ISetLastViewAsCurrent => {
  return {
    type: SET_LAST_VIEW_AS_CURRENT,
  };
};

export const setIsAutoOpeningFile = (
  isAutoOpeningFile: boolean,
): ISetIsAutoOpeningFile => {
  return { type: SET_IS_AUTO_OPENING_FILE, payload: isAutoOpeningFile };
};
