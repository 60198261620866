import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { useActiveFilters } from '@smack/core/hooks/useActiveFilters';
import type { IMenuItem } from '@smack/core/views/oldViewsToSort/Layouts/Objects/MenuFilter/Components/Contextual';
import { isActive } from '@smack/core/views/oldViewsToSort/Layouts/Objects/MenuFilter/Utils';
import React from 'react';

interface IQuickFilterTriggerProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  item: IMenuItem;
  isOpen: boolean;
}

export const QuickFilterTrigger = React.forwardRef<
  HTMLDivElement,
  IQuickFilterTriggerProps
>(function QuickFilterTrigger({ item, isOpen = false, ...htmlProps }, ref) {
  const { filters } = useActiveFilters();
  return (
    <div
      {...htmlProps}
      ref={ref}
      style={
        item.subMenu?.items.some((subitem) => {
          return isActive(subitem.id, filters);
        })
          ? { backgroundColor: '#FFC000' }
          : {}
      }
      className={`flex items-center my-0 mx-1 cursor-pointer justify-center border border-border h-fit py-2 px-1 rounded ${
        isOpen ? 'bg-secondary' : ''
      }`}
    >
      <Icon
        icon={item.icon}
        iconFile={item.iconFile}
        className="mr-1 text-sm text-text dark:text-gray-200"
      />
      <p className="text-sm text-text whitespace-nowrap">{item?.label}</p>
    </div>
  );
});
