//! moment.js locale configuration
//! locale : French [fr]
//! author : John Fischer : https://github.com/jfroffice

/**
 * Copied from moment.js package folder, Rollup doesn't want to
 * import, it seems?
 */

import { t } from 'i18next';
import moment from 'moment';

const monthsStrictRegex =
  /^(janvier|février|mars|avril|mai|juin|juillet|août|septembre|octobre|novembre|décembre)/i;
const monthsShortStrictRegex =
  /(janv\.?|févr\.?|mars|avr\.?|mai|juin|juil\.?|août|sept\.?|oct\.?|nov\.?|déc\.?)/i;
const monthsRegex =
  /(janv\.?|févr\.?|mars|avr\.?|mai|juin|juil\.?|août|sept\.?|oct\.?|nov\.?|déc\.?|janvier|février|mars|avril|mai|juin|juillet|août|septembre|octobre|novembre|décembre)/i;
const monthsParse = [
  /^janv/i,
  /^févr/i,
  /^mars/i,
  /^avr/i,
  /^mai/i,
  /^juin/i,
  /^juil/i,
  /^août/i,
  /^sept/i,
  /^oct/i,
  /^nov/i,
  /^déc/i,
];

moment.defineLocale('fr', {
  months:
    'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split(
      '_',
    ),
  monthsShort:
    'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
  monthsRegex: monthsRegex,
  monthsShortRegex: monthsRegex,
  monthsStrictRegex: monthsStrictRegex,
  monthsShortStrictRegex: monthsShortStrictRegex,
  monthsParse: monthsParse,
  longMonthsParse: monthsParse,
  shortMonthsParse: monthsParse,
  weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
  weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
  weekdaysMin: 'di_lu_ma_me_je_ve_sa'.split('_'),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'dddd D MMMM YYYY HH:mm',
  },
  calendar: {
    sameDay: '[Aujourd’hui à] LT',
    nextDay: '[Demain à] LT',
    nextWeek: 'dddd [à] LT',
    lastDay: '[Hier à] LT',
    lastWeek: 'dddd [dernier à] LT',
    sameElse: 'L',
  },
  relativeTime: {
    // Translation hack. This will disappear when we remove moment js
    future: (s) => t('momentHacksTranslation.in', { time: s }),
    past: (s) => t('momentHacksTranslation.ago', { time: s }),
    s: () => t('momentHacksTranslation.fewSeconds'),
    ss: (s) => t('momentHacksTranslation.seconds', { time: s }),
    m: () => t('momentHacksTranslation.oneMinute'),
    mm: (m) => t('momentHacksTranslation.minutes', { time: m }),
    h: () => t('momentHacksTranslation.oneHour'),
    hh: (h) => t('momentHacksTranslation.hours', { time: h }),
    d: () => t('momentHacksTranslation.oneDay'),
    dd: (d) => t('momentHacksTranslation.days', { time: d }),
    w: () => t('momentHacksTranslation.oneWeek'),
    ww: (w) => t('momentHacksTranslation.weeks', { time: w }),
    M: () => t('momentHacksTranslation.oneMonth'),
    MM: (M) => t('momentHacksTranslation.months', { time: M }),
    y: () => t('momentHacksTranslation.oneYear'),
    yy: (y) => t('momentHacksTranslation.years', { time: y }),
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|)/,
  ordinal: (
    number: number,
    period?: 'D' | 'M' | 'Q' | 'DDD' | 'd' | 'w' | 'W',
  ) => {
    switch (period) {
      // TODO: Return 'e' when day of month > 1. Move this case inside
      // block for masculine words below.
      // See https://github.com/moment/moment/issues/3375
      case 'D':
        return `${number}${number === 1 ? 'er' : ''}`;
      // Words with feminine grammatical gender: semaine
      case 'w':
      case 'W':
        return `${number}${number === 1 ? 're' : 'e'}`;
      // Words with masculine grammatical gender: mois, trimestre, jour
      default:
        return `${number}${number === 1 ? 'er' : 'e'}`;
    }
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
});
