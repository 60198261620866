import type { TaskRule } from '@smack/core/api/models/tasks/TaskRule';
import { RulesFeed } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/Rules/RulesFeed';
import type React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  rules: TaskRule[];
  loading?: boolean;
  borderless?: boolean;
}

export const Rules: React.FC<IProps> = ({ rules, loading, borderless }) => {
  const [t] = useTranslation();

  if (!rules.length && !loading) return null;

  return (
    <>
      <div
        className={`${!borderless ? 'border bg-primary' : ''} rounded-sm mb-2`}
      >
        {rules?.map((r) => (
          <RulesFeed rule={r} key={r.id} />
        ))}
      </div>
      <div
        className={`${
          !borderless ? 'border bg-primary' : ''
        } p-2 text-xs text-gray-500 rounded-sm`}
      >
        {t('tasks.rulesDisclaimer')}
      </div>
    </>
  );
};
