import { WarningAlert } from '@smack/core/components/DataDisplay/Alerts/Alerts';
import { SupersetDashboard } from '@smack/core/components/DataDisplay/SupersetDashboard/SupersetDashboard';
import type { ViewElementRendererProps } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/type';
import { type FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const SupersetDashboardViewElementRenderer: FC<
  ViewElementRendererProps
> = ({ viewElement, props }) => {
  const [t] = useTranslation();
  const baseobject = useMemo(() => props.baseObject, [props]);
  const action = useMemo(() => viewElement.action, [viewElement]);

  if (!action || !baseobject) {
    return (
      <WarningAlert>{t('viewElementRenderer.noActionConfigured')}</WarningAlert>
    );
  }

  return (
    <SupersetDashboard
      baseobjectId={baseobject.id}
      dashboardDisplayAction={action}
    />
  );
};
