import type { Layer } from '@smack/core/api/models/maps/Layer';
import { CloseButton } from '@smack/core/components/Actions/Buttons/Button';
import { DangerAlert } from '@smack/core/components/DataDisplay/Alerts/Alerts';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface LayerErrorModalProps {
  error: Error;
  layer: Layer;
}

export const LayerErrorModal: React.FC<LayerErrorModalProps> = ({
  error,
  layer,
}) => {
  const [open, setOpen] = React.useState(false);
  const [moreInfo, setMoreInfo] = React.useState(false);
  const [t] = useTranslation();

  return (
    <div>
      <Modal
        color={'#F87171'}
        icon={{ name: 'warning' }}
        open={open}
        title={t('mapConfigurator.modalTitle', { layer: layer.label })}
        onClose={setOpen}
      >
        <div className={' max-w-xl'}>
          <DangerAlert>
            <p>{t('mapConfigurator.anErrorOccur')}</p>
            <button
              type={'button'}
              onClick={() => setMoreInfo(!moreInfo)}
              className="flex items-center font-medium gap-2 text-xs mt-5 cursor-pointer"
            >
              {t('mapConfigurator.moreInformation')}
              <Icon icon={{ name: moreInfo ? 'chevron-up' : 'chevron-down' }} />
            </button>
            {moreInfo && (
              <div className={'mt-3'}>
                <p className=" text-xs">
                  <span className={'font-medium'}>{error.name}</span>:{' '}
                  <span>{error.message}</span>
                </p>
                <p>{error.stack}</p>
              </div>
            )}
          </DangerAlert>
        </div>
        <div className={'flex justify-end mt-5'}>
          <CloseButton onClick={() => setOpen(false)} />
        </div>
      </Modal>
      <button
        type={'button'}
        onClick={() => setOpen(true)}
        className={'flex  gap-2 text-red-400 cursor-pointer hover:text-red-500'}
      >
        <Icon icon={{ name: 'warning' }} />
        <p className={'text-xs '}>{t('mapConfigurator.informations')}</p>
      </button>
    </div>
  );
};
