import {
  type ISetCalendarLinkGroups,
  type IToggleCalendarLinkGroups,
  SET_CALENDAR_LINK_GROUPS,
  TOGGLE_CALENDAR_LINK_GROUPS,
} from './types';

export const toggleCalendarLinkGroup = (
  baseobjectId: number,
  linkGroupId: number,
): IToggleCalendarLinkGroups => ({
  type: TOGGLE_CALENDAR_LINK_GROUPS,
  payload: { baseobjectId: baseobjectId, linkGroupId: linkGroupId },
});

export const setCalendarLinkGroups = (
  baseobjectId: number,
  linkGroupIds: number[],
): ISetCalendarLinkGroups => ({
  type: SET_CALENDAR_LINK_GROUPS,
  payload: { baseobjectId: baseobjectId, linkGroupIds: linkGroupIds },
});
