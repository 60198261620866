import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { type Options, RRule } from 'rrule';
import {
  getDaysNumber,
  getRRuleNumber,
  getRecurenceNumberStringOptions,
  getRecurenceTypeDays,
} from '../../Utils';

interface IProps {
  value?: RRule;
  onChange: (value: RRule) => void;
}

export const MonthlyRRule = (props: IProps): JSX.Element => {
  const [t] = useTranslation();
  const { value, onChange } = props;
  const [choices, setChoices] = React.useState(
    value?.options.bysetpos?.length ? 2 : 1,
  );

  const handleChange = (val: Partial<Options>): void => {
    onChange(new RRule({ ...value?.origOptions, ...val }));
  };

  const defaultFirstValue: Partial<Options> = {
    freq: RRule.MONTHLY,
    bymonthday: 1,
  };

  const defaultSecondValue: Partial<Options> = {
    freq: RRule.MONTHLY,
    byweekday: 0,
    bysetpos: 1,
  };

  const onChangeChoice = (val: number): void => {
    setChoices(val);
    onChange(
      val === 1 ? new RRule(defaultFirstValue) : new RRule(defaultSecondValue),
    );
  };

  React.useEffect(() => {
    if (value?.options.freq !== RRule.MONTHLY) {
      onChangeChoice(1);
    }
  }, [value]);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center mt-3 whitespace-nowrap ">
        <input
          type="radio"
          onChange={(): void => onChangeChoice(1)}
          checked={choices === 1}
          className="h-5 w-5 mr-3 border-gray-500 border-[2px] text-blue-500 focus:ring-blue-500"
        />
        <div
          className={`flex items-center gap-3 ${
            choices === 1 ? '' : 'opacity-20'
          }`}
        >
          <p className="text-sm text-gray-600">{t('recurrence.on')}</p>
          <SelectInput
            isClearable={false}
            isSearchable={false}
            placeholder={getDaysNumber()[0].label.toString()}
            className="min-w-[150px]"
            value={getDaysNumber().find(
              (v) => v.value === getRRuleNumber(value?.options?.bymonthday),
            )}
            options={getDaysNumber()}
            onChange={(val): void =>
              handleChange({ bymonthday: val?.value as number })
            }
          />
          <p className="text-sm text-gray-600">{t('recurrence.fromMonth')}</p>
        </div>
      </div>
      <div className="flex items-center ">
        <input
          type="radio"
          onChange={(): void => onChangeChoice(2)}
          checked={choices === 2}
          className="h-5 w-5 mr-3 border-gray-500 border-[2px] text-blue-500 focus:ring-blue-500"
        />
        <div
          className={`flex  items-center gap-3 ${
            choices === 2 ? 'opacity-100' : 'opacity-20'
          }`}
        >
          <p className="text-sm text-gray-600">{t('recurrence.on')}</p>
          <div className="">
            <SelectInput
              className="min-w-[150px]"
              isClearable={false}
              isSearchable={false}
              placeholder={getRecurenceNumberStringOptions()[0].label.toString()}
              options={getRecurenceNumberStringOptions()}
              value={getRecurenceNumberStringOptions().find(
                (v) => v.value === getRRuleNumber(value?.options?.bysetpos),
              )}
              onChange={(val): void =>
                handleChange({ bysetpos: val?.value as number })
              }
            />
          </div>
          <SelectInput
            className="min-w-[150px]"
            isClearable={false}
            isSearchable={false}
            placeholder={getRecurenceTypeDays()[0].label.toString()}
            options={getRecurenceTypeDays()}
            value={getRecurenceTypeDays().find(
              (v) => v.value === getRRuleNumber(value?.options?.byweekday),
            )}
            onChange={(val): void =>
              handleChange({ byweekday: val?.value as number })
            }
          />
        </div>
      </div>
    </div>
  );
};
