import type { IconName } from '@fortawesome/fontawesome-common-types';
import { LinkGroup } from '@smack/core/api/models/categories/LinkGroup';
import type { InputLink } from '@smack/core/api/models/objects/Link/Link';
import {
  Button,
  CancelButton,
  SaveButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { NoContentMessage } from '@smack/core/components/DataDisplay/NoContentMessage';
import { Tooltip } from '@smack/core/components/DataDisplay/Tooltip';
import { LinkListInput } from '@smack/core/components/DataInput/LinkListInput/LinkListInput';
import type { IAddLinkFormOutput } from '@smack/core/views/oldViewsToSort/Layouts/Forms/AddLinks/AddLinkForm/AddLinkForm';
import { AddLinks } from '@smack/core/views/oldViewsToSort/Layouts/Forms/AddLinks/AddLinks';
import React from 'react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';

interface IProps {
  categoryId?: number;
  value?: InputLink[];
  objectId?: number;
  onChange?: (data: InputLink[]) => void;
}
export const LinksInput = (props: IProps): JSX.Element => {
  const [t] = useTranslation();
  const { onChange, categoryId, value, objectId } = props;
  const [open, realSetOpen] = React.useState(false);
  const [linkGroupActive, setLinkGroupActive] = React.useState<LinkGroup>();
  const [linkGroups, setLinkGroups] = React.useState<LinkGroup[]>([]);
  const [addLinkGroup, setAddLinkGroup] = React.useState<LinkGroup>();
  const [values, setValues] = React.useState<InputLink[]>([]);

  const setOpen: React.Dispatch<boolean> = (newOpen) => {
    /*
     * To open automatically the AddLinksModal, we need to let headless-ui portal the
     * main modal first, we have then to flush the first setState.
     */
    flushSync(() => {
      realSetOpen(newOpen);
    });
    if (newOpen) {
      if (linkGroups.length === 1 && linkGroups[0].children?.length === 1) {
        setAddLinkGroup(linkGroups[0].children[0]);
      }
    }
  };

  const setGroup = async (): Promise<void> => {
    if (categoryId) {
      const groups =
        await LinkGroup.getLinkGroupsFromCategoryIdOnlyExplicitForForm(
          categoryId,
        );
      setLinkGroups(groups);
      if (groups.length === 1) {
        setLinkGroupActive(groups[0]);
      }
    }
  };

  React.useEffect(() => {
    if (value) setValues(value);
  }, [value]);

  React.useEffect(() => {
    if (categoryId) {
      setGroup();
    }
  }, [categoryId]);

  /**
   * get root link group
   *
   * @param {LinkGroup[]} linkGroup
   * @return {*}  {JSX.Element}
   */
  const getRootGroup = (linkGroup: LinkGroup[]): React.ReactNode => {
    if (linkGroup.length === 1) return null;
    return (
      <>
        <p className="text-xs dark:text-gray-300 text-center">
          {t('locationInput.chooseTheGroup')}
        </p>
        <div className="flex items-center justify-center my-2">
          {linkGroup?.map((li) => {
            return (
              <Tooltip title={li.label ?? ''} key={li.id}>
                <button
                  type="button"
                  onClick={(): void => setLinkGroupActive(li)}
                  className={`cursor-pointer w-7 h-7 rounded-full mx-1 flex items-center justify-center ${
                    li.id === linkGroupActive?.id
                      ? ' bg-blue-200'
                      : 'bg-gray-200'
                  }`}
                >
                  <Icon
                    icon={
                      li.icon || {
                        name:
                          (li.label?.toLowerCase()[0] as IconName) ||
                          'question',
                      }
                    }
                    className={'w-4 h-4'}
                  />
                </button>
              </Tooltip>
            );
          })}
        </div>
      </>
    );
  };

  /**
   * get child group
   *
   * @param {LinkGroup} group
   * @return {*}  {JSX.Element}
   */
  const getGroup = (group: LinkGroup): JSX.Element => {
    return (
      <LinkListInput
        key={group.id}
        linkGroupId={group.id}
        value={values.filter((v) => v.linkGroupId === group.id)}
        onChange={(val) => setValues(val as InputLink[])}
        multiple
      />
    );
  };

  /**
   * on click save button
   *
   */
  const handleSave = (): void => {
    if (onChange && categoryId) {
      onChange(values);
      setOpen(false);
    }
  };

  /**
   * on Click cancel button
   *
   */
  const handleCancel = (): void => {
    setOpen(false);
  };

  const addLink = (data: IAddLinkFormOutput): void => {
    const newValues: InputLink[] = [...values];
    for (const baseobject of data.baseObjects) {
      newValues.push({
        targetBaseobjectId: baseobject.id,
        linkGroupId: data.linkGroup.id,
        attributes: data.attributes,
        weight: data.weight,
        datetimeRange: data.datetimeRange,
        objectDependantLinkGroupId: data.objectDependantLinkGroup,
      });
    }

    setValues(newValues);
    setAddLinkGroup(undefined);
  };

  const groupsAvailable = !!linkGroups.filter((l) => l.children?.length).length;

  return (
    <>
      <Modal
        title={t('locationInput.associateAnElement')}
        open={open && !addLinkGroup}
        icon={{ name: 'link' }}
        onClose={(): void => {
          // WE DON'T WANT TO AUTO CLOSE THE MODAL
        }}
        closeButton={false}
      >
        <div>
          {groupsAvailable ? (
            <>
              {getRootGroup(linkGroups.filter((l) => l.children?.length))}
              {linkGroupActive?.children?.map((l) => getGroup(l))}
            </>
          ) : (
            <NoContentMessage
              icon={{ name: 'link' }}
              label={t('addLinkForm.noLinkGroups')}
              className="my-6"
            />
          )}
          <div className="mt-3 flex justify-end">
            <CancelButton className="mr-2" onClick={handleCancel} />
            {values?.length ? <SaveButton onClick={handleSave} /> : null}
          </div>
        </div>
      </Modal>

      <AddLinks
        linkGroup={addLinkGroup}
        sourceBaseObjectId={objectId}
        onSubmit={addLink}
        open={!!addLinkGroup}
        onQuit={() => setAddLinkGroup(undefined)}
        onClose={(): void => setAddLinkGroup(undefined)}
      />

      <Button onClick={(): void => setOpen(true)}>
        <Icon icon={{ name: 'link' }} />
        <p className="uppercase ml-2">{t('locationInput.addLinkToObject')}</p>
      </Button>
    </>
  );
};
