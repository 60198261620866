import type { ViewElementRendererProps } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/type';
import { ObjectLinkGroupsCard } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Links/Components/ObjectLinkGroupsCard/ObjectLinkGroupsCard';
import { type FC, useMemo } from 'react';

export const ObjectLinkGroupsViewElementRenderer: FC<
  ViewElementRendererProps
> = ({ viewElement, props }) => {
  const linkGroupId = useMemo(() => viewElement.linkGroupId, [viewElement]);
  const baseobject = useMemo(() => props.baseObject, [props]);

  if (!linkGroupId || !baseobject) return null;

  return (
    <ObjectLinkGroupsCard
      baseObject={baseobject}
      rootLinkGroupId={linkGroupId}
    />
  );
};
