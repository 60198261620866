import {
  AttributePreviewSkeleton,
  ListSkeleton,
  LoaderSkeleton,
} from '@smack/core/utils/Loader';

export const BaseObjectPreviewViewSkeleton = (
  <div className="h-full w-full">
    <LoaderSkeleton height={90} width="100%">
      <ListSkeleton
        offset={0}
        iterations={2}
        component={AttributePreviewSkeleton}
      />
    </LoaderSkeleton>
  </div>
);
